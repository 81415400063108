import SiteMapController from './sitemap.controller'
import {PERMISSIONS, PERMISSION_LEVELS} from '../../common'


export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {

	const $stateRegistry = $uiRouterProvider.stateRegistry;

	$stateRegistry.register({
		name: 'app.sitemap',
		title: 'Site Map',
		url: 'sitemap',
		// access: {[PERMISSIONS.READ_ACTIVITY_LOGS]: true},
		views: {
			'content@app': {
				template: require('./sitemap.html'),
				controller: SiteMapController,
				controllerAs: 'ctrl',
			},
		},
	});

}];
