<md-dialog aria-label="Enter Student ID">
	<form name="studentIDform" ng-cloak ng-submit="studentIDform.$valid && ctrl.$mdDialog.hide(studentID)">
		<md-dialog-content class="margin-4x no-scroll">
			<h3 class="md-title margin-1x">Add <span ng-show="ctrl.data.salesforce_tag" ng-cloak>Demo</span> Student ID</h3>
			<md-input-container class="md-block md-input-has-placeholder">
				<label>{{ ctrl.validation.error_message || 'Student ID' }}</label>
				<input name="value" md-autofocus
					ng-model="studentID"
					type="{{ ctrl.isEmail ? 'email' : 'text' }}" 
					autocomplete="{{ ctrl.isEmail ? 'email' : 'off' }}" 
					ng-pattern="ctrl.validation.regex_expression || '.*'">
				<div ng-messages="studentIDform.value.$error" role="alert">
					<div ng-message="required">Required</div>
					<div ng-message="pattern">{{ ctrl.validation.error_message || 'Must match student ID policy' }}</div>
				</div>
			</md-input-container>
		</md-dialog-content>
		<md-dialog-actions layout="row">
			<md-button type="reset" class="black-50" ng-click="ctrl.$mdDialog.cancel()">Cancel</md-button>
			<md-button type="submit" class="md-primary">Save</md-button>
		</md-dialog-actions>
	</form>
</md-dialog>