<header id="college-list-header" class="md-whiteframe-2dp flex-none flex-order--5">
	<form name="headerForm" novalidate ng-model-options="{updateOn:'default blur', debounce:{'default':500, 'blur':0}}">
		<div class="layout-row layout-align-space-between-stretch">
			<h1 id="pageTitle" class="md-headline flex">Activity Log</h1>

			<div class="flex-none compact">
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERBTN -->
			</div>
		</div>

		<div class="filter-group md-chips md-removable">
			<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERCHIPS -->

			<div class="filters-nav-wrap flex">
				<md-sidenav class="filters-nav md-sidenav-right flex layout-column layout-gt-sm-row layout-gt-sm-wrap layout-align-start-stretch" 
					md-component-id="filters-nav" md-is-locked-open="mainCtrl.$mdMedia('gt-sm')" ng-cloak>
					<b class="md-headline hide-gt-sm">Filters &amp; Sort</b>
					
					<div class="make-right layout-xs-column layout-sm-column">
						<span class="flex-gt-md"></span>
						
						<md-input-container class="md-input-has-placeholder">
              <label>Type</label>
							<md-select name="type" 
								ng-model="ctrl.filters._types" 
								md-selected-text="'Type'" ng-change="ctrl.onFiltersChange(true)"
								placeholder="Type" multiple>
								<md-option ng-repeat="item in ctrl.mapping.types" ng-value="item">{{ item.name }}</md-option>
							</md-select>
						</md-input-container>
						
						<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SORTS -->
					</div>
				</md-sidenav>
			</div>
		</div>

	</form>
</header>

<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_ERROR -->
<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

<footer class="md-whiteframe-2dp layout-align-start-center layout-row layout-wrap">
	<md-button class="md-icon-button md-mini md-primary" 
		aria-label="Refresh results" title="Refresh results"
		ng-click="ctrl.refresh(true)">
		<span class="fas fa-sync-alt"></span>
	</md-button>
	<div id="footerResults" class="md-body-1">
		{{ ctrl.query.total }} <span class="hide-xs">Results</span>
	</div>
	<span flex></span>
</footer>

<main class="main-list activitylog-list flex flex-order--1 layout-column"
	aria-labelledby="pageTitle footerResults">
	<md-virtual-repeat-container class="data-list flex no-animate" 
		md-top-index="ctrl.itemIndex" 
		ng-if="ctrl.dataList.length > 0">
		<div id="item-{{ item._id }}" class="data-item activitylog-item layout-column no-animate" ng-class="{loading: !item}" 
			md-virtual-repeat="item in ctrl" md-on-demand="true">
			<div class="layout-row layout-xs-column flex" 
				role="group" aria-label="log ID #{{item._id}}"
				ng-include="::'/main/activitylog/activitylog.item.tpl'"></div>
		</div>
	</md-virtual-repeat-container>

	<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_EMPTY_RESULTS -->

</main>


<script type="text/ng-template" id="/main/activitylog/activitylog.item.tpl">
	<div class="flex layout-gt-sm-row layout-gt-sm-wrap">
		<div class="data-id wider layout-row layout-align-space-between-end layout-wrap layout-gt-sm-column layout-align-gt-sm-space-between-end padding-2x">
			<span class="date md-body-1 black-75">
				<div title="{{ moment(item.date).utc() | amDateFormat:SETTINGS.datetimeISO }}">
					{{ item.date | amDateFormat:SETTINGS.dateTimeZoneFormat }}
				</div>
				<div class="md-truncate">by
					<a class="link"
						ui-sref="app.users.edit({id: item.user_id})"
						title="{{ item.user_name_full }}">
						{{ item.user_name || '#'+item.user._id }}
					</a>
				</div>
			</span>
			<span class="md-caption black-33">ID#{{ item._id }}</span>
		</div>
		<div class="data-content flex layout-column layout-align-space-between-stretch">
			<div class="flex">
				<p class="md-body-1 md-trucate" title="{{ item.event }}">
					{{ item.event_parsed.action || item.event }}
					<a class="link no-animate" ng-if="item.event_parsed.url" ng-href="{{item.event_parsed.url}}"
						>{{ item.event_parsed.object }}</a
					><span class="no-animate" ng-hide="item.event_parsed.url" ng-cloak
						>{{ item.event_parsed.object }}</span
					>{{ item.event_parsed.other }}
				</p>
			</div>
			<div class="md-chips md-readonly flex-nogrow">
				<md-chip class="flex-none ng-animate no-animate" ng-repeat="chip in item._chips track by $index">
					<a class="link" ng-show="chip.sref" ui-sref="{{ chip.sref }}">{{ chip.name }}</a>
					<span ng-hide="chip.sref">{{ chip.name }}</span>
				</md-chip>
			</div>
		</div>
	</div>
</script>
