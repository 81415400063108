
	<table class="table table-borders md-body-1 black-75" ng-show="table.length > 0">
		<tr ng-if="table.title"><th colspan="100"><h4 class="black-66 md-title margin-0x text-left">{{ table.title }}</h4></th></tr>
		<tr class="black-33" ng-if="table.headers"><th class="text-left" ng-repeat="value in table.headers track by $index">{{value}}</th></tr>
		<tr ng-if="!table.title && !table.headers"><th>Description</th><th>Value</th></tr>
		<tr ng-repeat="row in table track by $index">
			<td class="pre" ng-repeat="value in row track by $index">{{ value }}</td>
		</tr>
		<tr ng-show="table.length == 0">
			<td class="padding-4x black-25 md-caption text-center" colspan="100"><br><br><br><em>No data available</em><br><br><br></td>
		</tr>
	</table>
	<div class="padding-1x"></div>
	<table class="table table-borders md-body-1 black-75" ng-repeat="subtable in table.subtables track by $index">
		<tr ng-if="subtable.title"><th colspan="100"><h4 class="black-66 md-title margin-0x text-left">{{ subtable.title }}</h4></th></tr>
		<tr class="black-33" ng-if="subtable.headers"><th class="text-left" ng-repeat="value in subtable.headers track by $index">{{value}}</th></tr>
		<tr ng-if="!subtable.title && !subtable.headers"><th>Description</th><th>Value</th></tr>
		<tr ng-repeat="row in subtable track by $index">
			<td class="pre" ng-repeat="value in row track by $index">{{ value }}</td>
		</tr>
		<tr ng-show="subtable.length == 0">
			<td class="padding-4x black-25 md-caption text-center" colspan="100"><br><br><br><em>No data available</em><br><br><br></td>
		</tr>
	</table>
	<div class="padding-2x"></div>