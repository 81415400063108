import angular from 'angular'
import $ from 'jquery'
import BaseList from '../base.list'
import {ApiError, Helper, SETTINGS, OP_TO_WORD, PERMISSION_LEVELS} from '../../common'


let hasAllAccess = false;

export default class FlowsListController extends BaseList {
	static get $inject(){return [
		'api',
		'apiMap',
		'recipient',
		'toast',
		'MAPPINGS_JSON',
		'authentication',
		'$stateParams',
		'$window',
	].concat(BaseList.$inject)}

	init(){
		this.mapping = {
			publish: Helper.superMap({
				'true': 'Published',
				'false': 'Unpublished',
			}),
			date: Helper.superMap({date: 'anytime'}, {_$groupId:'date'}, (list)=>list.single=true),
			types: Helper.deepCopy(this.MAPPINGS_JSON.colleges.type_key),
			sortBy: Helper.superMap({
				id:'ID', 
				name:'Name',
				type:'Type', 
			}),
			sortOrder: Helper.superMap({asc:'Asc', desc:'Desc'}),
			myterms: Helper.superMap(this.MAPPINGS_JSON.colleges.terms),
		};

		
		this._destructors.push(
			this.$scope.$on('mapFilter.removed', ($e, item)=>{
				if ( item === this.query.date )
					this.query.dateFrom = this.query.dateTo = null;
			})
		);
		
		super.init();

		hasAllAccess = this.hasPermissionLevel(PERMISSION_LEVELS.ALL);


		this.filters._$keys = {
			'mycolleges': 'College',
			'mydistricts': 'District',
			'myterms': 'Term',
			'mylevels': 'Level',
			'date': 'Date Range',
		};
		this.query = angular.extend({date: this.mapping.date[0]}, this.query);
	}
	_loadDependencies(){
		return this.$q.all([
			this.apiMap.getColleges().then((data)=>this.mapping.mycolleges = data),
			this.apiMap.getDistricts().then((data)=>this.mapping.mydistricts = data),
			this.apiMap.getColleges().then((data)=>this.mapping.mycolleges = data),
			this.apiMap.getAllDistricts().then((data)=>this.mapping.districts = data),
			this.apiMap.getCohortLevels().then((data)=>this.mapping.mylevels = data),
			this.apiMap.getPublicUsers().then((data)=>this.mapping.users = data),
			this.apiMap.getFlowTypes().then(data=>{
				data.byType = {};
				data.byEditor = {};
				data.byFlow = {};
				data.forEach(flow=>data.byType[flow.type] = data.byEditor[flow.console_editor] = data.byFlow[flow.chat_flow] = flow);
				this.mapping.flowTypes = data;
			}),
		])
			.then(()=>this.recipient.prepare())
			.then(()=>{
				this.onUrlParamChange(this.$state.params);
				this.refresh(true)
					.then(()=>this._loadScrollState())
			})
	}


	getFilters(all){
		return angular.extend(super.getFilters(all), {
			colleges: (this.filters._mycolleges || []).map(item=>item._id),
			districts: (this.filters._mydistricts || []).map(item=>item._id),
			terms: (this.filters._myterms || []).map(item=>item._id),
			cohorts: (this.filters._mylevels || []).map(item=>item.name),
		});
	}
	onUrlParamChange(obj){
		if ( obj.hasOwnProperty('colleges') )
			this.filters._mycolleges = (obj.colleges || [])
				.map(id=>this.mapping.mycolleges.byId[id])
				.filter(Helper.trueishFilter);
		if ( obj.hasOwnProperty('districts') )
			this.filters._mydistricts = (obj.districts || [])
				.map(id=>this.mapping.mydistricts.byId[id])
				.filter(Helper.trueishFilter);
		if ( obj.hasOwnProperty('terms') )
			this.filters._myterms = (obj.terms || [])
				.map(id=>this.mapping.myterms.byId[id])
				.filter(Helper.trueishFilter);
		if ( obj.hasOwnProperty('cohorts') )
			this.filters._mylevels = (obj.cohorts || [])
				.map(name=>this.mapping.mylevels.byName[name])
				.filter(Helper.trueishFilter);

		const keys = ['colleges', 'districts', 'terms', 'cohorts'];
		return super.onUrlParamChange(obj) || !! Object.keys(obj).find(v=>keys.includes(v));
	}


	refresh(reset){
		if ( reset ) {
			this.dataList = [];
			this.itemIndex = 0;
			this._errors = [];
		}

		return this._refresh('flowConfigurations', this.getParams(reset));
	}

	process(list){
		let missingUsers = [];

		list.forEach(item=>{
			this._processItem(item);

			if ( item.created_by_user_id && ! this.mapping.users.byId[item.created_by_user_id] && ! missingUsers.includes(item.created_by_user_id) )
				missingUsers.push( item.created_by_user_id );
			if ( item.modified_by_user_id && ! this.mapping.users.byId[item.modified_by_user_id] && ! missingUsers.includes(item.modified_by_user_id) )
				missingUsers.push( item.modified_by_user_id );
		});

		missingUsers.forEach(id=>
			this.api.get('users/'+ id, null, {cache:true, level:ApiError.LEVEL.IGNORED})
				.then(res=>this.mapping.users.byId[id] || this.mapping.users.push(this.mapping.users.byId[id] = res.data))
		);

		return list;
	}
	_processItem(item){
		let groups = Object.keys(item.recipients),
			exprs = Object.values(item.recipients),
			itemChips = item._chips = [];
		exprs.forEach((expr, i)=>{
			const chips = this.recipient.enumerate(expr, {ignoreInvalids:true, ignoreErrors:true})
				.filter(item=>item.model);
				if ( this.mapping.mycolleges.length === 1 || !hasAllAccess ) {
					chips = chips.filter(chip=>chip.type!='college' || (!hasAllAccess && chip._id==item.owner_college_id));
				}
			chips.forEach(chip=>chip.opWord = OP_TO_WORD[chip.op]);
			Array.prototype.splice.apply(itemChips, [0,0].concat(chips));
		});

		item.name.en_US = this.toSafeLangHtml(item.name.en_US);

		if ( item.show_in_content_directory ) {
			const cd = item.show_in_content_directory?.content_directory;
			const schedule = item.show_in_content_directory?.schedule;
			if ( cd ) {
				let d = moment(cd.release_date);
				item.release_day = cd.release_date && d.isAfter(moment()) ? d.format(SETTINGS.dateFormat) : null;
				item.deadline_day = cd.deadline?.date ? moment(cd.deadline.date, 'YYYY-MM-DD').format(SETTINGS.dateFormat) : null;
			} else
			if ( schedule ) {
				if ( schedule?.start_date ) {
					let d = moment.tz(schedule.start_date +' '+ schedule.time, 'YYYY-MM-DD HH:mm', schedule.timezone);
					item.date_day = d.year()===moment().year() ? d.format(SETTINGS.dateFormat0) : d.format(SETTINGS.dateFormat);
					item.date_time = d.format(SETTINGS.timeZoneFormat);
					item.date_full = d.format(SETTINGS.dateTimeFormat) + ` (${schedule.timezone})`;
				} else
				{
					const triggers = [
						'fire_on_student_registration',
						'fire_on_student_registration_with_coach',
						'fire_on_student_registration_with_coach_with_partner_landing_page',
						'fire_on_student_registration_with_coach_without_partner_landing_page',
						'fire_on_student_registration_without_coach',
						'fire_on_student_registration_without_coach_with_partner_landing_page',
						'fire_on_student_registration_without_coach_without_partner_landing_page'
					];
					item.fire_on_registration = Helper.capitalFirstLetter((triggers.find(k=>schedule[k]) || '').replace(/_/g, ' '));
				}
			}
		}

		switch( item.type ){
			case 'greeting':
			case 'alert':
				item._description = this.toSafeLangHtml(item.flow?.message?.en_US);
				break;
			case 'quiz':
				item._description = item.flow?.intro?.en_US;
		}
		item._description = this.toSafeLangHtml(item._description || item.show_in_content_directory?.content_directory?.framing_message);
		
		return item;
	}


}