import angular from 'angular'
import $ from 'jquery'
import moxie from 'mOxie'
import BaseList from '../base.list'
import {Helper, ApiError, SETTINGS} from '../../common'
import {B12_COHORT, B12_COACH, B12_COACH_CORPS} from './cohort.single.controller'



let getFile = window.FileReader ? (file)=>file.getSource() : (file)=>file;
let FileReader = window.FileReader || moxie.file.FileReader;


export default class CohortStudentsController extends BaseList {
	static get $inject(){return [
		'$q',
		'$mdDialog',
		'$mdSidenav',
		'$timeout',
		'$httpParamSerializer',
		'authorization',
		'data',
		'currentUser',
		'apiMap',
		'promptExit',
		'errorPrompt',
		'MAPPINGS_JSON',
	].concat(BaseList.$inject)}

	init(){
		this.mapping = {
			sortBy: Helper.superMap({
				id:'Date Created', 
				external_student_id:'Student ID',
			}),
			sortOrder: Helper.superMap({asc:'Asc', desc:'Desc'}),
		};

		this._$allSelected = false;
		this.tracking = [];

		this.query = angular.extend({},
			this.query,
			{
				search: '',
				defaultSortBy: 'external_student_id',
				defaultSortOrder: 'asc',
			}
		);
		
		super.init();
	}
	_loadDependencies(){
		return this.$q.all([
			this.apiMap.getCohortTypes()
				.then(data=>{
					this.mapping.cohortTypes = data;
					this.dataCohortType = this.mapping.cohortTypes.byName[this.data.cohort_type];
					return this.api.get('colleges/'+ this.dataCohortType?.college_relation?.college_id, {}, {level: ApiError.LEVEL.DEPENDENCY}).then(res=>this.data_college=res.data)
				}),
			this.apiMap.getUsersNonStudents().then(data=>this.mapping.users=data.sort(Helper.sortByPath('user_profile.first_name'))),
			this.apiMap.getUsersCoach().then(data=>this.mapping.coaches=data.sort(Helper.sortByPath('user_profile.first_name'))),
		])
		.then(()=>{
			this._isSalesforceManaged = !!(this.data.cohort_type == B12_COACH || (this.data.cohort_type == B12_COHORT && this.data.salesforce_tag));
			this.data.description = this.data.description || this.MAPPINGS_JSON.cohorts.name_key[this.data.name] || this.data.name;

			this.$scope.$once('deps-loaded', ()=>{
				this.onUrlParamChange(this.$state.params);
				this.refresh()
					.then(()=>this._loadScrollState());
			});

			return super._loadDependencies();
		});
	}


	onUrlParamChange(obj){
		if ( obj.hasOwnProperty('action') && obj.action ) {
			if ( obj.action === 'addone' && ! this.addOne.visible ) {
				if ( this.batchOpen.visible )
					this.batchClose();
				this.addOne(null);
			} else
			if ( obj.action === 'addbatch' ) {
				if ( this.addOne.visible )
					this.$mdDialog.hide();
				this.batchOpen();
			}
		}
		const keys = ['action'];
		return super.onUrlParamChange(obj) || !! Object.keys(obj).find(v=>keys.includes(v));
	}

	refresh(reset){
		let params = angular.extend({
				searchString: this.query.search || undefined,
				sortBy: this.query.sortBy,
				sortOrder: this.query.sortOrder,
				limit: this.query.limit,
				offset: this.query.offset = (reset ? 0 : this.query.offset),
				showMatchedUser: this.isSalesforceManaged,
			}, {
				cohort: this.data.name,
				college: this.data_college._id,
			}, {
				source:'provided_list',
				withMetadata:true, 
				myTokenAdministers:true,
			});

		if ( reset ) {
			this.dataList.length = 0;
			this.tracking.length = 0;
			this.itemIndex = 0;
		}

		return this._refresh('cohortCollegeExternalIds', params)
			.then(res=>{
				if ( res?.data && res.data.totalCount == this.dataList.filter(v=>v).length ) {
					// selected ids should be filtered against complete data list
					this.tracking = (this.tracking || []).filter(id=>!!this.dataList.find(item=>item && item.external_student_id === id));
				}
				return res;
			});
	}

	process(list){
		if ( this.isSalesforceManaged ) {
			list.forEach(item=>{
				if ( !this.canDeleteItem(item) )
					item._$lock = true;
			});
		}

		if ( this._$allSelected ) {
			list.filter(item=>!(item._$selected = item._$lock || this.tracking?.includes(item.external_student_id) ? false : true))
				.forEach(item=>this.tracking.push(item.external_student_id));
		} else {
			list.forEach(item=>item._$selected = !item._$lock && this.tracking?.includes(item.external_student_id));
		}

		return list;
	}

	canDeleteItem(item){
		return !this.isSalesforceManaged || !item.transient || item.transient?.user?.non_student_status=='demo';
	}
	get isSalesforceManaged(){ return this._isSalesforceManaged; }


	selectOne(item, value){
		if ( ! item || item._$lock ) return;

		item._$selected = !! value;

		// we use _$allSelected as flag to determin if tracking behaves as inclusive (false) or exclusive (true)
		if ( value != this._$allSelected ) {
			if ( ! this.tracking.includes(item.external_student_id) )
				this.tracking.push(item.external_student_id);
		} else {
			let p = this.tracking.indexOf(item.external_student_id);
			if ( p > -1 )
				this.tracking.splice(p, 1);
		}
	}
	selectAll(value){
		this._$allSelected = !!value;
		
		if ( this._$allSelected && this.isSalesforceManaged ) {
			// track only non-selected items
			this.tracking = this.dataList.filter(item=>!(item._$selected = !item._$lock)).map(item=>item.external_student_id);
		} else {
			this.dataList.forEach(item=>item._$selected = this._$allSelected);
			this.tracking.length = 0;
		}
	}
	deleteSelected($ev){
		const reviewCount = 6;
		let count, text;
		if ( this._$allSelected ) {
			count = this.query.total - this.tracking.length;
			text = ' - '+ this.dataList.filter(item=>item._$selected).slice(0,reviewCount).map(item=>item.external_student_id).join('<br/> - ');
		} else {
			count = this.tracking.length;
			text = ' - '+ this.tracking.slice(0,reviewCount).join('<br/> - ');
		}

		if ( count > reviewCount )
			text += `<br/>(+${count-reviewCount} more)`; 

		this.$mdDialog.show(
			this.$mdDialog.confirm()
					.title('Confirm Delete')
					.htmlContent(`Are you sure you want to delete the (${count}) selected student IDs?<br><div class="indent-2x">${text}</div>`)
					.ariaLabel('confirm delete')
					.targetEvent($ev)
					.ok('Delete')
					.cancel('Cancel')
		).then(()=>{
			this.isBusy = true;

			if ( this._$allSelected && ! this.isSalesforceManaged ) {
				// do a batch replace, keep what is tracked
				let payload = this.tracking.map(id=>{
					let item = this.dataList.find(item=>id == item.external_student_id);
					if ( item ) {
						item = Helper.deepCopy(item);
						item._id = item._$selected = item.transient = undefined
						return item;
					}
				}).filter(v=>v);

				let params = {
					mode: 'replace',
					cohort_name: this.data.name,
					college_id: this.dataCohortType.college_relation.college_id,
				};
				return this.api.post('cohortCollegeExternalIds/bulk?'+ this.$httpParamSerializer(params), payload)
					.then(()=>count);
			}

			let deleteCount = 0;
			const deletePartial = list=>{
				// deletes by batches of 12
				let batch = list.splice(0,12)
						.map(item=>
							this.api.delete('cohortCollegeExternalIds/'+ item._id, null, {level: ApiError.LEVEL.MANUAL})
								.then(()=>deleteCount++)
						);
				return this.$q.all(batch)
					.then(()=>{
						if ( list.length > 0 )
							return deletePartial(list);
					});
			};

			return this.$q.when((()=>{
				if ( ! this._$allSelected ) {
					return this.$q.when(this.dataList.filter(item=>this.tracking.includes(item.external_student_id)));
				}

				// all data has been loaded
				if ( this.query.total === this.dataList.filter(v=>v).length ) {
					return this.dataList.filter(item=>this.canDeleteItem(item) && !this.tracking.includes(item.external_student_id));
				}

				return this.api.get('cohortCollegeExternalIds', angular.extend({}, this.lastApiParams, {
						limit: undefined,
						offset: undefined,
						withMetadata: false,
					}))
						.then(res=>{
							return res.data.filter(item=>this.canDeleteItem(item) && !this.tracking.includes(item.external_student_id));
						});
			})())
				.then(list=>deletePartial(list))
				.then(()=>deleteCount)
				.catch(err=>{
					if ( err?.response?.data?.errors ) {
						const errors = err.response.data.errors;
						const e = new Error();
							e.title = 'Invalid Action';
						if ( errors.salesforce_managed_cohort_only_allows_deletion_of_demo_users ) {
							e.message = errors.salesforce_managed_cohort_only_allows_deletion_of_demo_users;
							this.errorPrompt.show(e, null, {noDebug:true, noRefresh:true, expected:true});
						}
					}
					throw err;
				});
		})
		.then(count=>{
			this.toast.success(`Deleted ${count} student ID(s)`);
			this._$allSelected = false;
			this.tracking.length = 0;

			let scroll = $('.md-virtual-repeat-scroller').scrollTop();
			this.refresh(true)
				.then(()=>{
					$('.md-virtual-repeat-scroller').scrollTop(scroll);
				});
		})
		.catch(()=>this.isBusy = false);
	}


	_promptAddOne($ev){
		return this.$mdDialog.show({
				controller: function(){},
				controllerAs: 'ctrl',
				locals: {
					$mdDialog: this.$mdDialog,
					validation: this.data_college.student_id_policy !== 'disabled' && this.data_college.student_id_validation || null,
					isEmail: this.data_college.student_id_policy !== 'disabled' && /email/i.test(this.data_college.student_id_validation?.error_message ?? ''),
					data: this.data,
				},
				bindToController: true,
				template: require('./cohort.student.add.html'),
				parent: angular.element(document.body),
				targetEvent: $ev,
				clickOutsideToClose: false,
				fullscreen: true,
			}
		);
	}
	async addOne($ev){
		this.addOne.visible = true;

		try {
			let id = await this._promptAddOne($ev);
			this.isBusy = true;

			const param = {
				externalStudentId: id,
				cohort: this.data.name,
				college: this.data_college._id,
				source:'provided_list',
			};
			
			const key = this.data_college.student_id_policy !== 'disabled' && this.data_college.student_id_validation.error_message || 'ID';

			// check for duplicate
			let res = await this.api.get('cohortCollegeExternalIds', param);
			if ( res.data.find(item=>String(item.external_student_id).toLowerCase() == String(id).toLowerCase()) ) {
				let err = new Error(`Cannot add '${id}', ${key.toLowerCase()} already exist`);
				err.title = 'Unable to Add';
				this.errorPrompt.show(err, null, {noDebug:true, noRefresh:true, expected:true});
				// this.toast.warn(`Cannot add '${id}', ${key.toLowerCase()} already exist`);
				throw false;
			}
			
			try {
				const payload = {
					cohort: this.data.name,
					college_id: this.dataCohortType.college_relation.college_id,
					external_student_id: id,
					created_by_user_id: this.currentUser._id,
					created_date: Helper.toTimestamp(new Date()),
					ancestry: SETTINGS.apiAncestry,
				};
				await this.api.post('cohortCollegeExternalIds', payload, {level: ApiError.LEVEL.MANUAL});

				this.toast.success(`${key} added`);
				this.refresh(true);
			} catch(err) {
				if ( this.api.isApiError(err) && err.isManual() ) {
					err.title = 'Unable to Add';
					// this.toast.error(err);
					this.errorPrompt.show(err, null, {noDebug:true, noRefresh:true, expected:true});
				} else
				if ( err instanceof Error ) {
					this.errorPrompt.show(err);
					console.error(err);
				}
			}
		} catch(e) {}

		this.addOne.visible = false;
		this.$state.go(this.$state.current.name, angular.extend({}, this.$state.params, {action:undefined}), {reload:false, location:true})
			.finally(()=>this.isBusy = false);
	}



	batchOpen(){
		this.batchOpen.visible = true;
		this.$mdSidenav('batch-form').open();
		this.$mdSidenav('batch-form').onClose(()=>{
			this.batchOpen.visible = false;
			// this.updateFilters();
			this.$state.go(this.$state.current.name, angular.merge({}, this.$state.params, {action:undefined}), {reload:false, location:false})
				.finally(()=>this.isBusy = false);
		});
		this.batchClear();

		if ( ! this.batchFileInput ) {
			this.batchFileInput = new moxie.file.FileInput({
				browse_button: $('#fileInput').get(0),
				accept: [{title: 'CSV file', extensions: 'csv'}, {title: 'Plain Text file', extensions: 'txt'}]
			});
			this.batchFileInput.onchange = (e)=>this.$scope.$evalAsync(()=>{
				let file = e.target.files[0];
				this.batchClear();
				this.batchFile = file;
				this.isBusy = true;
				reader.readAsText(getFile(file));
			});

			let $zone = $('#cohort-students-batch .dropzone')
				.on('drag dragstart dragend dragover dragenter dragleave drop', (e)=>{e.stopPropagation();e.preventDefault();})
				.on('dragover dragenter', ()=>$zone.addClass('dragover'))
				.on('dragleave dragend drop', ()=>$zone.removeClass('dragover'))
				.on('drop', (e)=>{
					if ( this.isBusy ) return;
					let file = e.originalEvent.dataTransfer.files[0];
					if ( ! /\.(csv|txt)$/.test(file.name) ) {
						let err = new Error('Can only import CSV or TXT files.')
						err.name = 'Invalid file type';
						this.toast.error(err);
					} else {
						this.$scope.$evalAsync(()=>{
							this.batchClear();
							this.batchFile = file;
							this.isBusy = true;
							reader.readAsText(file);
						});
					}
				});

			let reader = new FileReader();
			reader.onload = (e)=>this.$scope.$evalAsync(()=>this.batchParse(e));
			this.batchFileInput.init();
		}
	}
	batchClose(){
		this.$mdSidenav('batch-form').close()
			.then(()=>this.batchClear());
	}

	batchClear(){
		this.batchFile = undefined;
		this.batchResults = null;
	}

	batchParse(e){
		let validation = this.data_college.student_id_policy !== 'disabled' && this.data_college.student_id_validation;
		let invalids = 0, byId = {};
		this.batchResults = e.target.result.split(/[\n\r]+/)
			.filter((id)=>id.length>0)
			.map((id)=>{
				let valid = !validation || !!id.match(validation.regex_expression);
				if ( ! valid ) invalids++;
				return byId[id] = {id, valid};
			});
		this.batchResults.byId = byId;
		this.batchResults.mode = 'merge';
		this.batchResults.invalids = invalids;
		
		if ( invalids > 0 )
			this.toast.warn('Found '+ invalids +' invalid IDs');
		if ( ! this.batchResults.length )
			this.toast.warn('No IDs found from file');

		this.isBusy = false;
	}


	_promptBatchSubmit($ev){
		return this.$mdDialog.show(
			this.$mdDialog.confirm()
					.title('Replace all students')
					.textContent('Are you sure you want to replace the all existing students in this cohort?')
					.ariaLabel('confirm replace')
					.targetEvent($ev)
					.ok('Replace All')
					.cancel('Cancel')
		)
	}

	async batchSubmit($ev){
		if ( ! this.batchResults || ! this.batchResults.length || this.batchResults.invalids || this.isBusy ) return;
		try {
			if ( this.batchResults.mode == 'replace' )
				await this._promptBatchSubmit($ev);
		} catch(e){return}
		this.isBusy = true;

		const params = {
			mode: this.batchResults.mode || 'merge',
			cohort_name: this.data.name,
			college_id: this.dataCohortType.college_relation.college_id,
		};
		const obj = {
			cohort: this.data.name,
			college_id: this.dataCohortType.college_relation.college_id,
			created_by_user_id: this.currentUser._id,
			created_date: Helper.toTimestamp(new Date()),
			ancestry: SETTINGS.apiAncestry,
		};
		const payload = this.batchResults.map((item)=>angular.merge({external_student_id: item.id}, obj));
		
		let res;
		try {
			res = await this.api.post('cohortCollegeExternalIds/bulk?'+ this.$httpParamSerializer(params), payload, {level: ApiError.LEVEL.MANUAL});
			this.toast.success(`${payload.length} student IDs ${this.batchResults.mode}d`);
			this.$timeout(()=>{
				this.refresh(true);
				this.batchClose();
			}, 0);

		} catch(err){
			console.error(err);
			if ( ApiError.isApiError(err) && err.response.status==400 && err.isManual() ) {
				let e = new Error(err.message);
				e.title = 'Unable to Submit';
				this.errorPrompt.show(e, null, {noDebug:true, noRefresh:true, expected:true});

				if ( err.response.data?.errors ) {
					Object.values(err.response.data.errors).forEach((err)=>{
						if ( err.value ) {
							let row = this.batchResults.byId[err.value];
							if ( row ) {
								row.valid = false;
								row.warn = err.message;
							}
						}
					});
				}
			} else {
				this.errorPrompt.show(err);
			}
		}
		this.isBusy = false;
	}


	openCoachDialog($ev){
		return;
		return this.$mdDialog.show({
			controller: function(){},
			controllerAs: 'ctrl',
			template: require('./cohort.associated.user.html'),
			targetEvent: $ev,
			locals: {
				$mdDialog: this.$mdDialog,
				mapping: this.mapping,
				user: this.data.owner_user_id || null,
			},
			bindToController: true,
			clickOutsideToClose: false,
			escapeToClose: true,
			focusOnOpen: true,
			multiple: false,
		})
		.then(id=>{
			this.isBusy = true;

			this.data.owner_user_id = id || undefined;

			return this.api.put(`cohorts/${this.data._id}`, this.data)
				.then(()=>this.toast.success('Associated user updated'));
		})
		.finally(()=>this.isBusy = false);
	}

}
