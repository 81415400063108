<header class="md-whiteframe-2dp flex-none">
	<h2 class="md-headline">
		Workspace Publish Preview — {{ ctrl.batch.file_name }}
	</h2>
</header>

<main id="notification-batch-preview" class="main-list flex-xs-noshrink flex-sm-noshrink flex-gt-sm layout-column">
	<div class="headers flex-none padding-2x md-whiteframe-2dp">
		<h3 class="md-headline layout-row layout-align-space-around-start text-center">
			<span class="flex-25 status approved">{{ ctrl.batchStatus.num_approved }}<br><small class="md-subhead publish-subhead">Approved</small></span>
			<span class="flex-25 status held">{{ ctrl.batchStatus.num_held }}<br><small class="md-subhead publish-subhead">Hold</small></span>
			<span class="flex-25 status unapproved">{{ ctrl.batchStatus.num_unapproved }}<br><small class="md-subhead publish-subhead">Unreviewed</small></span>
			<span class="flex-25 status total">{{ ctrl.batchStatus.total }}<br><small class="md-subhead publish-subhead">Total</small></span>
		</h3>
		<md-divider></md-divider>
		<form name="previewForm" class="form-inline no-error-space layout-row layout-align-space-between-end layout-wrap" novalidate 
			ng-submit="previewForm.$valid && ctrl.submit($event, previewForm)" 
			form-disabled="ctrl.isBusy || !ctrl.hasApprovedUnpublished"
			ng-hide="ctrl.errors.length">
			<md-input-container class="flex-none" md-no-float="true">
				<md-radio-group ng-model="ctrl._$view" ng-change="ctrl.changeView()">
					<div class="layout-row">
						<span>View:</span>
						<md-radio-button class="flex-none" value="date">per Date</md-radio-button>
						<md-radio-button class="flex-none" value="event">per Event</md-radio-button>
					</div>
				</md-radio-group>
			</md-input-container>
			<span flex></span>
			<md-input-container class="md-input-has-placeholder flex-10" style="min-width: 220px;">
				<label>Stacking Notification Weight</label>
				<input type="number" name="stackingNotificationWeight" ng-model="ctrl.query.stackingNotificationWeight" step="0.01" required md-no-asterisk>
			</md-input-container>
			<md-input-container class="md-input-has-placeholder flex-10" style="min-width: 220px;">
				<label>Date Distance Weight</label>
				<input type="number" name="dateDistanceWeight" ng-model="ctrl.query.dateDistanceWeight" step="0.01" required md-no-asterisk>
			</md-input-container>
			<md-input-container class="md-input-has-placeholder flex-10" style="min-width: 270px;">
				<label>Notification Day Before Event Weight</label>
				<input type="number" name="notificationDayBeforeEventWeight" ng-model="ctrl.query.notificationDayBeforeEventWeight" step="0.01" required md-no-asterisk>
			</md-input-container>
			<md-input-container class="flex-none">
				<md-button type="submit" class="md-primary md-raised margin-0x">Preview</md-button>
			</md-input-container>
		</form>
	</div>

	<div class="notifications-preview-list flex md-whiteframe-1dp can-scroll-x can-scroll-y">
		<table class="table preview-by-date-list compact width-100" ng-if="ctrl.results" ng-show="ctrl._$view=='date'">
			<thead>
				<tr class="sticky md-caption black-50">
					<th>Date</th>
					<th>Notifications</th>
					<th>&nbsp;</th>
				</tr>
			</thead>
			<tbody>
				<tr ng-repeat="list in ctrl.results.byDate track by $index">
					<td class="date">
						<div class="sticky md-title">
							{{:: list.date | amDateFormat:'MMM D' }}<br>
							<span class="md-subhead">{{ :: list.date | amDateFormat:'YYYY' }}</span>
						</div>
					</td>
					<td class="count">
						<div class="sticky md-title">{{:: list.length }}</div>
					</td>
					<td>
						<div class="data-list">
							<div class="notification-item data-item" ng-repeat="item in list track by $index">
								<div class="data-content" ng-include="::'/notifications.publish.tpl-'+ (item.notification_type=='reminder' ? 'reminder' : 'event')"></div>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<table class="table preview-by-event-list events compact width-100" ng-show="ctrl._$view=='event' && ctrl.results.events.length" ng-cloak>
			<thead>
				<tr class="sticky"><th colspan="100">
					<h4 class="md-title margin-0x padding-2x">Events <span class="normal-wg">({{ ctrl.results.events.length }})</span></h4>
				</th></tr>
				<tr class="sticky sub-sticky md-caption black-50">
					<th>Notification ID</th>
					<th>Date to Send</th>
					<th>Description</th>
					<th>Title</th>
					<th>Location</th>
					<th>Short Name</th>
					<th>Category</th>
					<th>Link Text</th>
					<th>Link URL</th>
				</tr>
			</thead>
			<tbody class="striped-2">
				<tr ng-repeat-start="item in ctrl.results.events track by $index">
					<td class="text-center">{{:: item._id }}</td>
					<td class="date text-center">{{:: item.date_to_send | amDateFormat:'MMM D' }}<br>{{:: item.date_to_send | amDateFormat:'YYYY' }}</td>
					<td class="text">{{:: item.text }}</td>
					<td class="name">{{:: item.flutter.name.en_US }}</td>
					<td>{{:: item.flutter.location.label.en_US }}</td>
					<td>{{:: item.flutter.short_name }}</td>
					<td class="text-center">{{:: item.flutter.category }}</td>
					<td class="name">{{:: item.link_text }}</td>
					<td class="url">{{:: item.link_url }}</td>
				</tr>
				<tr ng-repeat-end ng-show="item.reminders.length">
					<td></td><td class="text-left" colspan="100">
						<table class="table reminders">
							<tr class="md-caption">
								<th class="text-left text-uppercase">Reminders:</th>
								<th class="text-center">Date to Send</th>
								<th class="text-left">Text</th>
							</tr>
							<tbody>
								<tr ng-repeat="reminder in item.reminders track by $index">
									<td></td>
									<td class="date">{{:: reminder.date_to_send | amDateFormat:'MMM D YYYY' }}</td>
									<td class="text">{{:: reminder.text }}</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</tbody>
		</table>
		<br>

		<table class="table preview-by-event-list tips compact width-100" ng-show="ctrl._$view=='event' && ctrl.results.tips.length" ng-cloak>
			<thead class="sticky">
				<tr class="sticky"><th colspan="100">
					<h4 class="md-title margin-0x padding-2x">Tips <span class="normal-wg">({{ ctrl.results.tips.length }})</span></h4>
				</th></tr>
			</thead>
			<thead class="sticky" ng-repeat-start="theme in ctrl.results.themes track by $index">
				<tr class="tip_theme md-body-2">
					<td colspan="100">{{ theme.text }}</td>
				</tr>
				<tr class="sticky sub-sticky md-caption black-50">
					<th>Date To Send</th>
					<th>Call To Action</th>
					<th>Description</th>
					<th>Title</th>
					<th>Location</th>
					<th>Short Name</th>
					<th>Category</th>
					<th>Tags</th>
					<th>Link Text</th>
					<th>Link URL</th>
				</tr>
			</thead>
			<tbody ng-repeat-end class="striped">
				<tr class="tip" ng-repeat="item in theme.tips track by $index">
					<td class="date text-center">{{:: item.date_to_send | amDateFormat:'MMM D' }}<br>{{:: item.date_to_send | amDateFormat:'YYYY' }}</td>
					<td class="name">{{ item.call_to_action }}</td>
					<td class="text">{{ item.text.raw }}</td>
					<td class="name">{{ item.flutter.name.en_US }}</td>
					<td>{{ item.flutter.location.label.en_US }}</td>
					<td>{{ item.flutter.short_name }}</td>
					<td class="text-center">{{ item.flutter.category }}</td>
					<td class="text-center">{{ item.flutter.tags }}</td>
					<td class="name">{{ item.link_text }}</td>
					<td class="url">{{ item.link_url }}</td>
				</tr>
			</tbody>
		</table>

		<div class="flex-none text-center" ng-hide="ctrl.results.length || ctrl.isBusy">
			<em class="flex-none black-50 md-caption" ng-show="ctrl.hasApprovedUnpublished.length > 0">No results</em>
			<span class="flex-none" ng-show="ctrl.hasApprovedUnpublished == 0">
				To preview a publish, there must be atleast 1 event or tip theme that is both <b>approved</b> & still <b>unpublished</b>.
				<br><br>
				
				<md-button class="md-raised data-action black-75" ng-show="ctrl.batch.status != 'published'" ui-sref="app.workspaces.edit.calendar({id: ctrl.batch._id})"
						aria-label="Update in calendar">
						<i class="far fa-calendar-alt"></i>
						Update in Calendar &nbsp;&nbsp;
						<i class="fas fa-chevron-right"></i>
				</md-button>
			</span>
		</div>

		<div class="flex-none panel panel-small md-whiteframe-1dp" ng-if="ctrl.errors.length">
			<div class="panel-content">
				<md-card md-theme="::'danger'" class="margin-0x" style="border-bottom-left-radius: 0px">
					<md-card-content layout>
						<span><span class="fas fa-exclamation-triangle"></span>&nbsp;&nbsp;</span>
						<span flex>Validation Errors</span>
					</md-card-content>
				</md-card>
				<table class="table table-striped md-body-1 expandable text-left" style="width:100%; height:0xp; border-left:3px solid transparent;" 
					md-colors="::{'border-left-color': 'danger-background-800'}">
					<tr>
						<th>Field/Column</th>
						<th>Message</th>
					</tr>
					<tr ng-repeat-start="(id, errs) in ctrl.errors.byId">
						<th>{{ errs.type }} ID#{{ id }}</th>
						<th class="text-right">
							<md-button class="md-primary md-hue-2 compact" ui-sref="^.calendar({id: ctrl.batch._id, select: id})">
								<i class="fas fa-tools"></i>
								Fix in Calendar
							</md-button>
						</th>
					</tr>
					<tr ng-repeat="err in errs">
						<td>{{ err.field }}</td>
						<td>{{ err.message }}</td>
					</tr>
					<tr ng-repeat-end></tr>
				</table>
			</div>
		</div>

	</div>
</main>

<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

<footer class="text-right" md-whiteframe="2" form-disabled="ctrl.isBusy">
	<md-button class="data-action md-primary" ng-show="ctrl.batch.status != 'published'" ui-sref="app.workspaces.edit.calendar({id: ctrl.batch._id})"
		aria-label="Update in calendar">
		<span class="far fa-calendar-alt"></span>
		Update in Calendar &nbsp;&nbsp;
		<span class="fas fa-chevron-right"></span>
	</md-button>
	<md-button class="md-raised md-warn"
		ng-click="ctrl.publish($event)" ng-disabled="ctrl.isBusy || !ctrl.hasApprovedUnpublished"
		aria-label="Publish now">
		<span class="far fa-paper-plane"></span> Publish Now
	</md-button>
</footer>

<script type="text/ng-template" id="/notifications.publish.tpl-event">
	<small class="small-tag md-caption type" ng-class=":: item.notification_type">
		<i class="far fa-calendar"></i> {{:: item.call_to_action !== undefined ? 'Tip' : 'Event'}}
	</small>
	<small class="small-tag md-caption shortname" ng-show=":: !!item.flutter.short_name">
		{{:: item.flutter.short_name }}
	</small>
	<h5 class="md-subhead">
		<i class="fas fa-bullhorn" ng-show=":: !item.flutter.name.en_US && item.call_to_action"></i>
		{{:: item.flutter.name.en_US || item.call_to_action }}
	</h5>
	<p class="md-body-2" ng-show=":: item.flutter.name.en_US && item.call_to_action" title="Call to Action">
		<i class="fas fa-bullhorn"></i> {{:: item.call_to_action }}
	</p>
	<p class="md-body-1">{{:: item.text.raw }}</p>
	<p class="md-body-1">
		<span ng-show=":: item.flutter.location && item.flutter.location.label && item.flutter.location.label.en_US" title="Location">
			<i class="fas fa-map-pin"></i>
			{{:: item.flutter.location.label.en_US }}
			&nbsp;&nbsp;
		</span>
		<span ng-show=":: item.flutter.category" title="Category">
			<i class="far fa-folder"></i>
			{{:: item.flutter.category }}
			&nbsp;&nbsp;
		</span>
		<span ng-show=":: item.flutter.tags" title="Tags">
			<i class="fas fa-tags"></i>
			{{:: item.flutter.tags }}
			&nbsp;&nbsp;
		</span>
		<span ng-show=":: item.link_url" title="{{:: item.link_url }}">
			<i class="fas fa-external-link-alt"></i>
			<a ng-href="{{:: item.link_url }}" target="_blank">{{:: item.link.text }}</a>
			&nbsp;&nbsp;
		</span>
	</p>
</script>

<script type="text/ng-template" id="/notifications.publish.tpl-reminder">
	<small class="small-tag md-caption type" ng-class="item.notification_type">
		<i class="far fa-bell"></i> Reminder
	</small>
	<h5 class="md-subhead">{{ item.text }}</h5>
</script>