import angular from 'angular'
import {DependencyInjected, Helper, SETTINGS, ApiError} from '../../common'


export default class AnalyticsController extends DependencyInjected {
	static get $inject(){return [
		'$scope',
		'$q',
		'apiMap',
	]}

	init(){
		this.mapping = this.$scope.mapping = {
			date: Helper.superMap({date: 'anytime'}, {_$groupId:'date'}, (list)=>list.single=true),
		};
		this._pending = this.apiMap.getColleges()
			.then((data)=>{
				this.mapping.mycolleges = Helper.superList(Helper.deepCopy(data));
				this.mapping.mycolleges.single = true;
				if ( data.length <= 1 ) {
					this.hideFilters = true;
					// this.filters._colleges = data.concat();
				}
			})
			.then(()=>{
				this.$scope.$emit('deps-loaded');
				this.$scope.$once('data-ready', ()=>this.isBusy = false);
			});
		this.isBusy = true;

		this.dateRange = this.mapping.date[0];

		this.filters = [];
		this.filters._$keys = {
			'mycolleges': 'College',
			'date': 'Date Range',
		};
		this.filterRefresh = Helper.throttle(()=>{
			this.error = null;
			if ( this.$scope.headerForm.$valid && this.$scope.ctrl && this.$scope.ctrl.refresh )
				this.$scope.ctrl.refresh();
		}, 300, true);
	}

	updateDateRange(){
		console.log(this.dateRange.from);
		this.dateRange.name = [
			this.dateRange.from?.format(SETTINGS.dateFormat) ?? '',
			this.dateRange.to?.format(SETTINGS.dateFormat) ?? '',
		].join(' — ');
		if ( this.dateRange.from || this.dateRange.to ) {
			if ( this.filters._$keys.date )
				this.filters._date = this.dateRange;
		} else {
			this.filters._date = null;
		}
		if ( this.$scope.ctrl && this.$scope.ctrl.refresh )
			this.$scope.ctrl.refresh(true);
	}

}