import ActivityLogController from './activitylog.controller'
import SFPanelController from './sfpanel.controller'
import {PERMISSIONS, PERMISSION_LEVELS} from '../../common'
import './activitylog.scss'


export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {

	const $stateRegistry = $uiRouterProvider.stateRegistry;

	$stateRegistry.register({
		name: 'app.activitylog',
		title: 'Activity Log',
		url: 'activity-log?sortBy&sortOrder&types',
		access: {[PERMISSIONS.READ_ACTIVITY_LOGS]: true},
		views: {
			'content@app': {
				template: require('./activitylog.html'),
				controller: ActivityLogController,
				controllerAs: 'ctrl',
			},
		},
		dynamic: true,
		params: {
			nonce: 0,
			scroll:       {value: 0, inherit:false, dynamic:true},
			sortBy:       {type:'query', inherit:false},
			sortOrder:    {type:'query', inherit:false},
			types:        {type:'query', inherit:false, value:['system', 'user']},
		},
	});

	$stateRegistry.register({
		name: 'app.contentadminactivitylog',
		title: 'Activity Log',
		url: 'content-admin-activity-log?sortBy&sortOrder&types',
		access: {[PERMISSIONS.READ_NON_USER_ACTIVITY_LOGS]: true},
		views: {
			'content@app': {
				template: require('./activitylog.html'),
				controller: ActivityLogController,
				controllerAs: 'ctrl',
			},
		},
		dynamic: true,
		params: {
			nonce: 0,
			scroll:       {value: 0, inherit:false, dynamic:true},
			sortBy:       {type:'query', inherit:false},
			sortOrder:    {type:'query', inherit:false},
			// types:        {type:'query', inherit:false, value:['system', 'user']},
		},
	})


	$stateRegistry.register({
		name: 'app.activitylog.sfpanel',
		title: 'Salesforce Control Panel',
		url: '/sfpanel',
		access: {[PERMISSIONS.CONTROL_SALESFORCE_SYNC]: true},
		views: {
			'content@app': {
				template: require('./sfpanel.html'),
				controller: SFPanelController,
				controllerAs: 'ctrl',
			},
		},
	});

}];
