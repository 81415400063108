<header id="milestones-list-header" class="md-whiteframe-2dp flex-none flex-order--5">
	<form name="headerForm" novalidate ng-model-options="{updateOn:'default blur', debounce:{'default':250, 'blur':0}}">
		<div class="layout-row layout-align-start-center layout-align-gt-sm-space-between-stretch layout-wrap">
			<h2 id="pageTitle" class="md-headline flex">Tasks</h2>

			<div class="flex-100 flex-gt-sm-50 layout-row">
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SEARCH -->
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERBTN -->
			</div>
		</div>
		
		<div class="filter-group md-chips md-removable">
			<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERCHIPS -->
			
			<div class="filters-nav-wrap flex">
				<md-sidenav class="filters-nav md-sidenav-right flex layout-column layout-gt-sm-row layout-gt-sm-wrap layout-align-start-stretch" 
					role="group" aria-labelledby="rightSideNav"
					md-component-id="filters-nav" md-is-locked-open="mainCtrl.$mdMedia('gt-sm')" ng-cloak>
					<b id="rightSideNav" class="md-headline hide-gt-sm">Filters &amp; Sort</b>
					
					<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERS -->

					<div class="make-right layout-xs-column layout-sm-column layout-gt-sm-row layout-wrap">
						<span class="flex-gt-md"></span>
						<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERDATES -->
						<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SORTS -->
					</div>
				</md-sidenav>
			</div>
		</div>
	</form>
</header>

<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_ERROR -->
<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

<footer class="md-whiteframe-2dp layout-row layout-align-start-center layout-wrap">
	<md-button class="md-icon-button md-mini md-primary" 
		aria-label="Refresh results" title="Refresh results"
		ng-click="ctrl.refresh(true)">
		<span class="fas fa-sync-alt" ng-class="{'rotating': ctrl.isBusy || ctrl.partialBusy}"></span>
	</md-button>
	<div id="footerResults" class="md-body-1" ng-hide="ctrl.query.view=='calendar'">
		{{ ctrl.query.total }} <span class="hide-xs">Results</span>
	</div>
	<span flex></span>
	
	<md-button class="md-primary" aria-label="add new task" 
		ui-sref=".add"
		ng-if=":: mainCtrl.hasPermission(PERMISSIONS.CREATE_TASKS)">
		<span class="fas fa-plus"></span> 
		<span class="hide-xs">Add</span> New
	</md-button>
	<md-button class="md-primary" aria-label="add tasks by batch"
		ui-sref=".addbatch" 
		ng-if=":: mainCtrl.hasPermission(PERMISSIONS.CREATE_TASKS_BATCHES)">
		<span class="fas fa-plus-square"></span> 
		<span class="hide-xs">Add</span> Batch
	</md-button>
	
	<md-button id="footerExport" class="md-accent"
		aria-labelledby="footerExport footerResults"
		ng-click="ctrl.exportCSV('tasks.csv')">
		<i class="fas fa-file-export" aria-hidden="true"></i> 
		Export <span class="hide-xs">CSV</span>
	</md-button>
</footer>

<main class="main-list milestones-list flex flex-order--1 layout-column" aria-labelledby="pageTitle footerResults">
	<md-virtual-repeat-container class="data-list flex no-animate" 
		md-top-index="ctrl.itemIndex" 
		ng-if="ctrl.dataList.length > 0">
		<div id="item-{{ item._id }}" class="data-item milestone-item layout-column no-animate" ng-class="{loading: !item}" 
			md-virtual-repeat="item in ctrl" md-on-demand="true">
			<div class="layout-column flex" 
				role="group" aria-label="task id: #{{item._id}}"
				ui-sref="app.milestones.edit({id: item._id})" ng-include="'milestones.item.tpl'"></div>
		</div>
	</md-virtual-repeat-container>
	<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_EMPTY_RESULTS -->

	<section id="expanded-overlay" class="layout-column" 
		role="group" aria-label="{{ 'Task #'+ ctrl.selected._id +' showing steps' }}"
		ng-show="!!ctrl.selected" ng-cloak>
		<div class="data-list flex-nogrow md-whiteframe-1dp"
			ng-if="ctrl.selected" 
			ng-init="item = ctrl.selected">
			<div class="data-item milestone-item layout-column">
				<div class="layout-column flex" 
					role="group" aria-label="task id: #{{item._id}}"
					ui-sref="app.milestones.edit({id: item._id})" 
					ng-include="'milestones.item.tpl'"></div>
			</div>
			<md-toolbar class="md-info-theme md-primary md-hue-1 md-whiteframe-1dp">
				<div class="md-toolbar-tools layout">
					<h4 class="flex md-title padding-2x normal-wg">Steps</h4>
					<md-button class="md-icon-button black-66" aria-label="Hide steps" ng-click="ctrl.close()">
						<span class="far fa-times-circle md-title" aria-hidden="true"></span>
					</md-button>
				</div>
			</md-toolbar>
		</div>
		<div class="data-list flex-100 layout-column can-scroll-y indent-2x">
			<div class="data-item md-body-1 flex-none layout-row" 
				role="group" aria-label="step #{{$index+1}}"
				ng-repeat="step in ctrl.selected.steps track by $index">
				<div class="data-step-id layout-column padding-2x text-right">
					<span class="md-title normal-wg md-block">
						<small class="md-caption black-33 pull-left">#{{ $index+1 }}</small>
						<span class="md-body-1">{{ step.deadline.date | amDateFormat:SETTINGS.dateFormat }}</span>
					</span>
					<div>
						<strong class="pull-left points" ng-show="step.points != undefined">{{ (step.points || 0) +'pt'+ (step.points > 1 ? 's' : '') }}</strong>
						<span class="md-caption black-66 small-tag margin-0x" ng-hide="step.validation=='none' || step.validation=='qr'">
							{{ ctrl.mapping.validations.byId[step.validation].name || step.validation }}
						</span>
						<a class="md-caption black-66 small-tag margin-0x" 
							aria-label="Manage QR Codes" title="Manage QR Codes"
							ng-show="step.validation=='qr'"
							ui-sref="app.milestones.edit.stepqr({id: ctrl.selected._id, stepid: step._id})">
							{{ ctrl.mapping.validations.byId.qr.name }}
						</a>
					</div>
				</div>
				<div class="padding-2x" flex>
					<h4 class="md-subhead margin-0x md-truncate" ng-bind-html="step.title"></h4>
					<p class="md-body-1 black-66 margin-0x md-truncate">
						<ng-bind-html class="description" ng-bind-html="step.description.raw"></ng-bind-html>
					</p>
				</div>
			</div>

		</div>
	</section>
</main>
