import $ from 'jquery'
import {Helper} from '../../common'

const FLOW_TEST_CHATBUB_DELAY = 800;


export default class ChatSimulator {

	get isRunning(){return this._running}
	get wasInterupted(){return this._interupted}


	constructor($scope, $q, chatMatrix, toast){
		this.$scope = $scope;
		this.$q = $q;
		this.chatMatrix = chatMatrix;
		this.toast = toast;
	}

	_onChatMatrixError(err){
		// matrix failed to connect
		console.error(err);
		let cleanError = new Error('Unable to connect to chat server');
		cleanError.title = 'Test Flow Failed';
		cleanError.debug = String(err);
		throw cleanError;
	}

	async start(roomID){
		if ( this._running ) this.stop();

		this.messages = []; // view model, delayed
		this._messages = []; // duplicate, realtime

		await this.chatMatrix.start()
			.catch(err=>this._onChatMatrixError(err));

		this._running = true;		
		Helper.smoothScrollTo($('#flow-test-preview'));
		this.progressCount = 0;
		this._updateProgress();
		
		const client = this.chatMatrix.client;

		// listen to sync errors
		client.on('sync', (state)=>state=='ERROR' && this.$scope.$evalAsync(()=>this.interupt('Connection lost')));

		this._roomID = roomID;
		const room = client.getRoom(roomID);
		if ( ! room ) {
			throw new Error(`Room not found ${roomID}`);
		}

		if ( room.currentState.members[room.myUserId].membership == 'invite' ) {
			await client.joinRoom(room.roomId);

			const defer = this.$q.defer();
			client.once('sync', state=>{
				if ( state == 'SYNCING' ) defer.resolve(state);
			});
			await defer.promise;
		}

		// get existing room msgs first
		this._readMessages(room);

		// start listening to room msgs after
		client.on('Room.timeline', (event, room, toStartOfTimeline)=>{
			if ( !toStartOfTimeline && event.event.type == 'm.room.message' && event.event.room_id == this._roomID ) {
				this._processMessage(Helper.deepCopy(event.event));
					
				this.$scope.$applyAsync();
			} else {
				console.log('room.timeline', event);
			}
		});
	}
	stop(force=false){
		this._running = this._interupted = false;
		this.error = null;
		this.progress = undefined;
		if ( force || this.chatMatrix.hasStarted() ) {
			this.chatMatrix.stop(force);
			this.chatMatrix.client.removeAllListeners('Room.timeline');
			this.chatMatrix.client.removeAllListeners('sync'); // watch out
		}
	}

	interupt(err=null){
		if ( this._running && ! this._interupted ) {
			this.stop();
			this._running = this._interupted = true;
			this.error = err || 'Flow Stopped';
			err && this.toast.warn(`${err}; test has been stopped`);
			console.log('flow interupted');
		}
	}

	async sendReply(content, action){
		if ( ! this.chatMatrix.isConnected() ) return;

		if ( content.info.background ) 
			action.info = {background: content.info.background};

		// delete content.actions;
		content.actions._hide = true;

		try {
			this.chatMatrix.client.sendMessage(this._roomID, action);
			if ( action.body == 'want to start over?' ) {
				this.progressCount = 0;
				content.info.$lastnode = false;
			} else
			if ( action.body == 'go back' ) {
				this.progressCount--;
			} else {
				this.progressCount++;
			}
			this._updateProgress();
		} catch(err) {
			this.errorPrompt.show(err);
		}
		this.$scope.$applyAsync();
	}

	_readMessages(room){
		const rooms = room.timeline;
		let i = rooms.length;
		while( i-- ) {
			let evt = rooms[i].event, lastEvt;
			if ( evt.type == 'm.room.message' && evt.content.info.start_new_test_flow ) {
				do {
					evt = rooms[i].event;
					if ( evt.type == 'm.room.message' ) {
						this._processMessage(Helper.deepCopy(evt));
					}
				}	while( ++i < rooms.length );
				break;
			}
		}
		this.$scope.$applyAsync();
	}
	_processMessage(evt){
		if ( ! this.chatMatrix.isConnected() ) return;

		const element = $('#flow-test-preview .chat-msgs').get(0);
		const content = evt.content;

		if ( content?.info?.start_new_test_flow && this._messages.length > 0 ) {
			this.interupt('Flow Interupted');
			return this.toast.warn('Flow test got interupted by a request that didn\'t originate from here');
		}

		if ( content?.body ) {
			content.htmlBody = Helper.stripOtherTags(
				Helper.convertToLangSpan(Helper.newlinesToHtml(Helper.plainLinksToHtml(content.body))),
				'span,br,a',
				Helper.encodeHtml
			);
		}

		if ( Object.values(content?.['m.action_hints'] || {}).find(btn=>btn.body == 'want to start over?') ) {
			let last = this._messages[this._messages.length-1];
			let info = last?.info;
			if ( info ) info.$lastnode = true;
		}

		content.info = content.info || {};
		content.info.$fromUser = (evt.user_id == this.chatMatrix.client.getUserId());

		this._messages.push(content); // internal model
		Helper.throttledArrayPush(this.messages, content, FLOW_TEST_CHATBUB_DELAY, // view model
			(list, content)=>{
				console.log('msg', content);
				if ( content?.info?.lastnode ) {
					this.progress = '100%';
				}
				if ( content['m.action_hints'] ) { // is answer/button
					list.pop(); // remove it from list

					let actions = content['m.action_hints'];
				
					let actionList = list[list.length-1].actions = Object.keys(actions)
							.map(k=>+k.replace('button_', ''))
							.sort((a, b)=>a - b)
							.map(k=>actions['button_'+ k]);

						actionList.forEach(element => {
							element.body = Helper.stripOtherTags(Helper.convertToLangSpan(element.body), 'span', Helper.encodeHtml);
						});
				}
				element && this.$scope.$evalAsync(()=>$(element).animate({scrollTop: element.scrollHeight}, 300));
			}, true);
	}


	_updateProgress(){
		this.progress = Math.floor(Helper.calcTaskProgress(this.progressCount) * 100) + '%';
	}

}