<form id="notification-form" name="form" novalidate ng-class="{locked: ctrl.isLocked}" ng-submit="!ctrl.isBusy && !ctrl.isLocked && ctrl.submit($event, form)" form-disabled="ctrl.isBusy || ctrl.isLocked" ng-init="$parent.editCtrl = ctrl">	
	<div class="clearfix">
		<md-card md-theme="::'info'" ng-show="ctrl.isLocked && ctrl.lockReason">
			<md-card-content layout>
				<span><span class="fas fa-info-circle"></span>&nbsp;&nbsp;</span>
				<span flex ng-bind-html="ctrl.lockReason"></span>
			</md-card-content>
		</md-card>
	</div>

	<div class="panel" md-whiteframe="1" ng-include="'tip.form.detail.tpl'"></div>
	<br>
	<div class="panel" md-whiteframe="1" ng-include="'event.form.reminders.tpl'"></div>
</form>