<header class="md-whiteframe-2dp flex-none layout-row layout-align-space-between-start layout-wrap">
	<h2 id="pageTitle" class="md-headline flex-xs-100">
		<a ui-sref="^" ui-sref-opts="ctrl.parentStateParams">Users <span class="margin-1x" aria-hidden="true">&#9656;
		</span></a>{{ ctrl.data ? '' : 'Add New' }}
		<span id="docTitle" ng-show="ctrl.data">{{:: ctrl.data ? 'User #'+ ctrl.data._id : 'new User'}}</span>
		<span role="img" aria-label="changes not saved" title="changes not saved" ng-show="form.$dirty">*</span>
	</h2>
	<div class="top-buttons flex-xs-100" ng-show="ctrl.data" form-disabled="ctrl.isBusy">
		<md-button class="md-warn" id="deleteBtn" aria-labelledby="deleteBtn docTitle" 
			ng-click="ctrl.delete($event)"
			ng-if=":: mainCtrl.hasPermission(PERMISSIONS.DELETE_USERS)"
			ng-hide="ctrl.isLocked">
			<span class="far fa-trash-alt"></span> 
      Delete
		</md-button>
	</div>
</header>

<main flex>
	<form id="flow-form" name="form" novalidate 
		class="flex-gt-md" ng-class="{locked: ctrl.isLocked}" 
		form-disabled="ctrl.isBusy || ctrl.isLocked"
		ng-submit="!ctrl.isBusy && !ctrl.isLocked && ctrl.submit($event, form)" 
		ng-model-options="{updateOn:'default blur', debounce:{'default':250, 'blur':0}}">
		<input type="submit" class="hide" tabindex="-1">

		<div class="panel panel-small" md-whiteframe="1">
			<div class="panel-content padding-4x">
				<div class="layout-row">
					<md-input-container class="flex-100 flex-gt-xs-75 md-input-has-placeholder">
						<label>Roles</label>
						<md-select name="roles" ng-model="ctrl.model.roles" ng-model-options="{trackBy: '$value._id'}" multiple required>
							<md-option ng-repeat="item in ctrl.mapping.roles" ng-value="item">{{ item.name }}</md-option>
						</md-select>
						<div class="md-errors-spacer"></div>
						<div ng-messages="form.roles.$error" role="alert">
							<div ng-message="required">Required</div>
						</div>
					</md-input-container>

					<md-input-container class="flex-100 flex-gt-xs-25 md-input-has-placeholder">
						<label>Status</label>
						<md-select name="status" ng-model="ctrl.model.status" required>
							<md-option ng-repeat="item in ctrl.mapping.status" ng-value="item._id">{{ item.name }}</md-option>
						</md-select>
						<div class="md-errors-spacer"></div>
						<div ng-messages="form.status.$error" role="alert">
							<div ng-message="required">Required</div>
						</div>
					</md-input-container>
				</div>

				<div toggle-expand="_$studentOpen = ctrl.hasRole('student')">
					<div class="expandable layout" form-disabled="!_$studentOpen">
						<md-input-container class="md-block md-input-has-placeholder">
							<label>Non-Student Status</label>
							<md-select name="non_student_status" ng-model="ctrl.model.non_student_status" ng-model-options="{trackBy: '$value'}" ng-change="!ctrl.model.non_student_status ? (ctrl.model.non_student_status=undefined) : null">
								<md-option ng-repeat="(name, value) in ctrl.mapping.nonStudentStatuses" ng-value="value">{{ name }}</md-option>
							</md-select>
							<div class="md-errors-spacer"></div>
						</md-input-container>
	
						<md-input-container class="md-block md-input-has-placeholder">
							<label>Colleges Attending</label>
							<md-select name="attend" ng-model="ctrl.model._colleges_student" ng-model-options="{trackBy: '$value._id'}" ng-change="ctrl.change(form.attend)" ng-required="_$studentOpen" md-truncate multiple>
								<md-option ng-repeat="item in ctrl.mapping.mycolleges" ng-value="item">{{ item.name }}</md-option>
							</md-select>
							<div class="md-errors-spacer"></div>
							<div ng-messages="form.attend.$error" role="alert">
								<div ng-message="required">Required</div>
							</div>
						</md-input-container>
					</div>
				</div>
				<div toggle-expand="_$cadminOpen = ctrl.hasRole('college_admin')">
					<div class="expandable layout" form-disabled="!_$cadminOpen">
						<md-input-container class="md-block md-input-has-placeholder" flex="nogrow">
							<label>Colleges Administering</label>
							<md-select name="admin" ng-model="ctrl.model._colleges_college_admin" ng-model-options="{trackBy: '$value._id'}" ng-required="_$collegeAdminOpen" md-truncate multiple>
								<md-option ng-repeat="item in ctrl.mapping.mycolleges" ng-value="item">{{ item.name }}</md-option>
							</md-select>
							<div class="md-errors-spacer"></div>
							<div ng-messages="form.admin.$error" role="alert">
								<div ng-message="required">Required</div>
							</div>
						</md-input-container>
					</div>
				</div>
				
				<div class="layout-row layout-wrap">
					<md-input-container class="md-block flex-100 flex-gt-xs-50 md-input-has-placeholder">
						<label>First Name</label>
						<input type="text" name="first_name" autocomplete="given-name" ng-model="ctrl.model.user_profile.first_name" data-lpignore="true" required md-autofocus>
						<div ng-messages="form.first_name.$error" role="alert">
							<div ng-message="required">Required</div>
						</div>
					</md-input-container>
					<md-input-container class="md-block flex-100 flex-gt-xs-50 md-input-has-placeholder">
						<label>Last Name</label>
						<input type="text" name="last_name" autocomplete="family-name" ng-model="ctrl.model.user_profile.last_name" data-lpignore="true" required>
						<div ng-messages="form.last_name.$error" role="alert">
							<div ng-message="required">Required</div>
						</div>
					</md-input-container>
					<md-input-container class="md-block flex-100 flex-gt-sm-55 md-input-has-placeholder">
						<label>Chat Username</label>
						<input type="text" name="chat" ng-if="!ctrl.model.chat" 
							style="text-transform:lowercase"
							data-lpignore="true"
							ng-model="ctrl.model.chatname" ng-model-options="{updateOn: 'default blur', debounce: {'default': 500, 'blur': 0}}"
							ng-focus="form.chat.$setTouched()"
							ng-pattern="/^[a-z0-9_\\\-=/\\.]+$/i" maxlength="100"
							async-validate="ctrl.verifyChatname($view)">
							<input type="text" ng-if="ctrl.model.chat" ng-value="ctrl.model.chatname" ng-disabled="::true">
						<md-icon ng-show="form.chat.$viewValue" aria-label="{{ form.chat.$pending ? 'Validating' : form.chat.$valid ? 'Valid' : 'Invalid' }} Username">
							<i class="fas" ng-class="{'fa-sync-alt rotating':form.chat.$pending, 'fa-check green': !form.chat.$pending && form.chat.$valid}"></i>
						</md-icon>
						<div ng-messages="form.chat.$error" role="alert">
							<div ng-message="pattern">Special characters & spaces are not allowed</div>
							<div ng-message="maxlength">Cannot exceed 100 characters</div>
							<div ng-message="asyncValidate">{{:: MESSAGES.USERS.CODE[101] }}</div>
						</div>
					</md-input-container>
					
					<md-input-container class="md-block md-icon-float flex-100 md-input-has-placeholder">
						<label>Profile Photo URL</label>
						<md-icon><i class="fas fa-portrait"></i></md-icon>
						<input type="url" name="photo_url" autocomplete="url" ng-model="ctrl.model.user_profile.photo_url" data-lpignore="true">
						<div class="md-errors-spacer"></div>
						<div ng-messages="form.photo_url.$error" role="alert">
							<div ng-message="required">Required</div>
							<div ng-message="url">Use a valid url format, ex: <em>https://example.com/myphoto.jpg</em></div>
						</div>
					</md-input-container>
					
					<md-input-container class="md-block flex-100 md-input-has-placeholder">
						<label>Profile Photo Alt Text</label>
						<input type="text" class="md-input" name="profile_photo_alt_text" ng-model="ctrl.model.user_profile.photo_alt_text" ng-value="ctrl.model.user_profile.photo_alt_text">
					</md-input-container>
					
				</div>

			</div>
			<div class="panel-content" toggle-expand="_$coachOpen = ctrl.hasRole('coach') || ctrl.hasRole('coaching_corps_coach')" ng-if="::APP_BEYOND12_MODE">
				<div class="expandable">
					<fieldset class="compact padding-4x" form-disabled="!_$coachOpen">
						<md-input-container class="md-block flex-gt-sm-60 no-animate md-input-has-placeholder" ng-show="ctrl.hasRole('coach')">
							<label>Salesforce ID</label>
							<input type="text" name="salesforce_id"
								autocomplete="salesforce_id"
								ng-model="ctrl.model.salesforce.id"
								ng-change="form.salesforce_id.$error.duplicate ? form.salesforce_id.$setValidity('duplicate', true) : null; ">
							<div ng-messages="form.salesforce_id.$error" role="alert">
								<div ng-message="duplicate">{{:: MESSAGES.USERS.CODE[423] }}</div>
							</div>
						</md-input-container>

						<md-input-container class="md-block md-input-has-placeholder">
							<label>Icon URL</label>
							<input type="url" name="icon_url" autocomplete="url" ng-model="ctrl.model.user_profile.icon_url">
							<div class="md-errors-spacer"></div>
							<div ng-messages="form.icon_url.$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="url">Use a valid url format, ex: <em>https://example.com/myicon.png</em></div>
							</div>
						</md-input-container>

						<md-input-container class="md-block md-input-has-placeholder">
							<label>Icon Alt Text</label>
							<input type="text" ng-model="ctrl.model.user_profile.icon_alt_text" ng-value="ctrl.model.user_profile.icon_alt_text">
						</md-input-container>
		

						<md-input-container class="md-block md-input-has-placeholder">
							<label>Long Bio Text</label>
							<textarea class="black" name="profile" ng-model="ctrl.model.user_profile.bio" valid-lang rows="3" style="min-height:90px"></textarea>
							<div ng-messages="form.profile.$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="lang-missing">{{ form.profile.$error['lang-missing'] }}</div>
								<div ng-message="lang-mismatch">{{ form.profile.$error['lang-mismatch'] }}</div>
								<div ng-message="lang">Invalid language tag formatting</div>
							</div>
						</md-input-container>

						<div class="padding-2x">
							<legend>Available Hours</legend>
							<div class="layout-column indent">
								<div class="layout-row layout-align-start-start" ng-repeat="(day, label) in ctrl.mapping.weekDays" style="min-height:46px">
									<md-checkbox style="min-width:120px"
										ng-model="_$available_hours[day]" 
										ng-change="ctrl.checkToggled(day, _$available_hours[day])"
										ng-checked="!!ctrl.model.user_profile.available_hours[day]">
											{{ label }}
									</md-checkbox>
									<div class="layout-row layout-wrap">
										<div ng-repeat="timeRange in ctrl.model.user_profile.available_hours[day] track by $index" class="fade-in fade-out">
											<md-input-container class="picker-date no-label flex-none flex-offset-5 padding-0x all-day" ng-model-options="{updateOn:'default blur', debounce:{'default':1000,'blur':0}}">
												<input type="text" name="range_{{day}}_{{$index}}" autocomplete="off" 
													aria-label="{{label +' time range'}}"
													valid-date-range time-only="true"
													view-format="{datetime:'h:mmA', time:'h:mmA'}" model-format="{datetime:'HH:mm:ss'}" separator="' - '"
													validate="$modelValue.length == 2"
													ng-required="$index == 0"
													ng-model="ctrl.model.user_profile.available_hours[day][$index]" ng-model-options="{updateOn:'blur'}">
												<div ng-messages="form['range_'+ day +'_'+ $index].$error" role="alert">
													<div ng-message="required">Required</div>
													<div ng-message="format">Invalid Format (eg 8:15am - 2:30pm)</div>
													<div ng-message="parse">Invalid Time (eg 8:15am - 2:30pm)</div>
													<div ng-message="date">Invalid Time</div>
													<div ng-message="order">Invalid Time Order</div>
													<div ng-message="validate">Must be a time range (eg 8:15am - 2:30pm)</div>
												</div>
											</md-input-container>
										</div>
										<!-- <md-button class="md-icon-button md-accent" aria-label="Favorite" ng-click="ctrl.model.user_profile.available_hours[day].push(null)" ng-show="ctrl.model.user_profile.available_hours[day] && ctrl.model.user_profile.available_hours[day][ctrl.model.user_profile.available_hours[day].length-1]">
											<i class="fas fa-plus"></i>
										</md-button>
										<md-button class="md-icon-button md-accent" aria-label="Remove time" ng-click="ctrl.model.user_profile.available_hours[day].pop()" ng-show="ctrl.model.user_profile.available_hours[day].length > 1 && !ctrl.model.user_profile.available_hours[day][ctrl.model.user_profile.available_hours[day].length-1]">
											<i class="fas fa-times"></i>
										</md-button> -->
										<md-button class="md-icon-button md-accent" 
											aria-label="{{'add time range for '+ label}}" 
											ng-show="ctrl.model.user_profile.available_hours[day][0]"
											ng-click="ctrl.model.user_profile.available_hours[day].length > 1 && !ctrl.model.user_profile.available_hours[day][ctrl.model.user_profile.available_hours[day].length-1] ? ctrl.model.user_profile.available_hours[day].pop() : ctrl.model.user_profile.available_hours[day].push(null)">
											<i class="fas fa-plus rotate-animate" ng-class="{'rotate-45': ctrl.model.user_profile.available_hours[day].length > 1 && !ctrl.model.user_profile.available_hours[day][ctrl.model.user_profile.available_hours[day].length-1]}"></i>
										</md-button>
									</div>
								</div>
								
							</div>
						</div>

						<!-- <md-autocomplete class="md-block" md-input-name="timezone" md-selected-item="ctrl.model.user_profile.timezone"
							timezone-field
							md-floating-label="Timezone" md-require-match md-min-length="0" md-no-cache="true"
							placeholder="Ex. America/Los_Angeles" ng-disabled="ctrl.isBusy" ng-required="_$coachOpen">
							<div ng-messages="form.timezone.$error" ng-if="form.timezone.$touched">
								<div ng-message="required">Required</div>
								<div ng-message="md-require-match">Invalid timezone</div>
							</div>
						</md-autocomplete> -->

						<md-input-container class="md-block md-input-has-placeholder" 
							timezone-select="ctrl.model.user_profile.timezone" 
							ng-required="_$coachOpen">
							<div class="md-errors-spacer"></div>
							<div ng-messages="form.timezone.$error" ng-if="form.timezone.$touched">
								<div ng-message="required">Required</div>
								<div ng-message="md-require-match">Invalid time zone</div>
							</div>
						</md-input-container>

						<md-input-container class="md-block flex-gt-sm-60 md-icon-float md-input-has-placeholder" ng-model-options="{updateOn:'default blur', debounce:{'default':200,'blur':0}}">
							<label>Mobile Number</label>
							<md-icon><i class="fas fa-mobile-alt"></i></md-icon>
							<input type="text" class="black" name="mobile_number" 
								autocomplete="tel"
								ng-model="ctrl.model.user_profile.mobile_number" ng-pattern="ctrl._$mobileRegex"
								ng-keyup="ctrl.cleanMobileNumber(form.mobile_number)"
								ng-paste="ctrl.cleanMobileNumber(form.mobile_number)">
							<div ng-messages="form.mobile_number.$error" role="alert">
								<div ng-message="pattern">Invalid format (e.g. 1234567890 or +52123456789)</div>
							</div>
						</md-input-container>

						<md-input-container class="md-block md-icon-float md-input-has-placeholder">
							<label>Calendly Link</label>
							<md-icon><i class="far fa-calendar"></i></md-icon>
							<input type="url" name="calendly_link" autocomplete="url" ng-model="ctrl.model.user_profile.calendly_link">
							<div class="md-errors-spacer"></div>
							<div ng-messages="form.calendly_link.$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="url">Use a valid url format, ex: <em>https://calend.ly</em></div>
							</div>
						</md-input-container>
						<md-input-container class="md-block md-icon-float md-input-has-placeholder">
							<label>Instagram Profile</label>
							<md-icon><i class="fab fa-instagram"></i></md-icon>
							<input type="text" name="instagram_url" autocomplete="url" ng-model="ctrl.model.user_profile.instagram_url" ng-pattern="/^https:\/\/(www.)?instagram.com\/[^/]+?\b/">
							<div class="md-errors-spacer"></div>
							<div ng-messages="form.instagram_url.$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="pattern">Use a valid instagram link, ex: <em>https://www.instagram.com/<b>johndoe</b></em></div>
							</div>
						</md-input-container>
						<md-input-container class="md-block md-icon-float md-input-has-placeholder">
							<label>Facebook Profile</label>
							<md-icon><i class="fab fa-facebook"></i></md-icon>
							<input type="text" name="facebook_url" autocomplete="url" ng-model="ctrl.model.user_profile.facebook_url" ng-pattern="/^https:\/\/(www.)?facebook.com\/[^/]+?\b/">
							<div class="md-errors-spacer"></div>
							<div ng-messages="form.facebook_url.$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="pattern">Use a valid facebook link, ex: <em>https://www.facebook.com/<b>johndoe</b></em></div>
							</div>
						</md-input-container>
						<md-input-container class="md-block md-icon-float md-input-has-placeholder">
							<label>Twitter Profile</label>
							<md-icon><i class="fab fa-twitter"></i></md-icon>
							<input type="text" name="twitter_url" autocomplete="url" ng-model="ctrl.model.user_profile.twitter_url" ng-pattern="/^https:\/\/(www.)?twitter.com\/[^/]+?\b/">
							<div class="md-errors-spacer"></div>
							<div ng-messages="form.twitter_url.$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="pattern">Use a valid twitter link, ex: <em>https://www.twitter.com/<b>johndoe</b></em></div>
							</div>
						</md-input-container>
					</fieldset>
				</div>
			</div>
			<div class="panel-content padding-4x">
				<md-input-container class="md-block md-input-has-placeholder">
					<label>Email</label>
					<input type="text" name="email" ng-model="ctrl.model.email"
						valid-email
						autocomplete="email"
						data-lpignore="true"
						ng-change="
							form.email.$error.duplicateEmail ? form.email.$setValidity('duplicateEmail', true) : null; 
							form.email.$error.invalidEmail ? form.email.$setValidity('invalidEmail', true) : null;
						"
						required>
					<div ng-messages="form.email.$error" role="alert">
						<div ng-message="required">Required</div>
						<div ng-message="email">Invalid email format</div>
						<div ng-message="duplicateEmail">{{:: MESSAGES.USERS.CODE[102] }}</div>
						<div ng-message="invalidEmail">{{:: MESSAGES.USERS.CODE[106] }}</div>
					</div>
				</md-input-container>
				<md-button class="md-primary" ng-show="ctrl.data && !ctrl._$updatePW && !ctrl.isLocked" ng-click="ctrl._$updatePW=true">Update Password</md-button>
				<md-input-container class="md-block md-input-has-placeholder" ng-if="!ctrl.data || ctrl._$updatePW">
					<label>{{ctrl.data && 'Update'}} Password</label>
					<input type="password" name="password" autocomplete="new-password" ng-model="ctrl.model.password" ng-required="!ctrl.data" autocomplete="new-password">
					<div ng-messages="form.password.$error" role="alert">
						<div ng-message="required">Required</div>
					</div>
				</md-input-container>
				<md-input-container class="md-block md-input-has-placeholder" ng-if="!ctrl.data || ctrl._$updatePW">
					<label>Confirm Password</label>
					<input type="password" name="password2" autcomplete="new-password" ng-model="ctrl.model.password2" is-equal="{{ctrl.model.password}}" ng-required="ctrl.model.password || !ctrl.data" autocomplete="off">
					<div ng-messages="form.password2.$error" role="alert">
						<div ng-message="required">Required</div>
						<div ng-message="isEqual">Passwords must match</div>
					</div>
				</md-input-container>
			</div>
		</div>

		<div class="panel panel-small" md-whiteframe="1" ng-if="ctrl.hasRole('coach') || ctrl.hasRole('coaching_corps_coach')">
			<div class="panel-content padding-4x" ng-if="!ctrl.data">
				<md-input-container class="md-block" ng-if="ctrl.hasRole('coach')">
					<md-checkbox ng-model="ctrl._$createCoachCohort">Also create Coach Cohort</md-checkbox>
				</md-input-container>
				<md-input-container class="md-block" ng-if="ctrl.hasRole('coaching_corps_coach')">
					<md-checkbox ng-model="ctrl._$createCorpsCohort">Also create Coaching Corps Coach Cohort</md-checkbox>
				</md-input-container>
			</div>
			<div class="panel-title" ng-show="ctrl.data && (ctrl.mapping.coachCohorts.length > 0 || ctrl.mapping.corpsCoachCohorts.length > 0)">
				<div class="padding-4x md-title normal-wg">Associated Cohorts</div>
			</div>
			<div class="panel-content padding-4x" ng-show="ctrl.data && (ctrl.mapping.coachCohorts.length > 0 || ctrl.mapping.corpsCoachCohorts.length > 0)">
				<div class="md-chips md-readonly">
					<a ng-repeat="cohort in ctrl.mapping.coachCohorts" ui-sref="app.cohorts.edit({id: cohort._id})">
						<md-chip class="cohort flex-none">
							{{ cohort.title || cohort.description }}
						</md-chip>
					</a>
					<a ng-repeat="cohort in ctrl.mapping.corpsCoachCohorts" ui-sref="app.cohorts.edit({id: cohort._id})">
						<md-chip class="cohort flex-none">
							{{ cohort.title || cohort.description }}
						</md-chip>
					</a>
				</div>
			</div>
		</div>
	</form>
</main>

<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

<footer class="text-right" md-whiteframe="2" ng-hide="ctrl.isLocked">
	<md-button type="button" class="md-raised md-warn" ng-disabled="ctrl.isBusy || form.$pending" ng-click="ctrl.submitForm('form')">
		<span class="far fa-save"></span> Save 
	</md-button>
</footer>
