import UsersListController from './users.list.controller'
import UserSingleController from './user.single.controller'
import ProfileController from './profile.controller'
import {ApiError, PERMISSIONS, PERMISSION_LEVELS} from '../../common'
import './users.scss'


export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {

	const $stateRegistry = $uiRouterProvider.stateRegistry;

	$stateRegistry.register({
		name: 'app.users',
		title: 'Users',
		url: 'users?searchString&sortBy&sortOrder&status&roles&studentColleges&adminColleges',
		access: {[PERMISSIONS.READ_USERS]: [PERMISSION_LEVELS.ALL]},
		views: {
			'content@app': {
				template: require('./users.list.html'),
				controller: UsersListController,
				controllerAs: 'ctrl',
			},
		},
		dynamic: true,
		params: {
			nonce: 0,
			scroll:         {value: 0, inherit:false, dynamic:true},
			searchString:   {type:'query', inherit:false},
			sortBy:         {type:'query', inherit:false},
			sortOrder:      {type:'query', inherit:false},
			status:         {type:'query', inherit:false},
			roles:          {type:'query', inherit:false},
			studentColleges:{type:'query', inherit:false, array:true},
			adminColleges:  {type:'query', inherit:false, array:true},
		},
	});


	let userSingleHtml = require('./user.single.html');

	$stateRegistry.register({
		name: 'app.users.add',
		title: 'Add User',
		url: '/add',
		access: {[PERMISSIONS.CREATE_USERS]: true},
		views: {
			'content@app': {
				template: userSingleHtml,
				controller: UserSingleController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'data',
				deps: [],
				useFactory: ()=>null,
		}, {
				provide: 'parentStateParams',
				deps: [],
				useFactory: ()=>({}),
		}],
	});

	$stateRegistry.register({
		name: 'app.users.edit',
		title: ['data', data=>'User #'+ data._id],
		url: '/{id:int}',
		access: {[PERMISSIONS.UPDATE_USERS]: [PERMISSION_LEVELS.ALL]},
		views: {
			'content@app': {
				template: userSingleHtml,
				controller: UserSingleController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'data',
				deps: ['api', '$state', '$stateParams', '$timeout'],
				useFactory: (api, $state, $stateParams, $timeout)=>api.get('users/'+ $stateParams.id)
					.then((res)=>res.data, (err)=>{
						if ( api.isApiError(err) ) {
							let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
							$timeout(()=>$state.go(state, {error: err}, {location:false}), 13);
						}
					}),
		}, {
				provide: 'parentStateParams',
				deps: [],
				useFactory: ()=>({}),
		}],
	});

	$stateRegistry.register({
		name: 'app.profile',
		title: 'Profile',
		url: 'profile',
		auth: {[PERMISSIONS.UPDATE_USERS]: [PERMISSION_LEVELS.MYSELF]},
		views: {
			'content@app': {
				template: require('./profile.html'),
				controller: ProfileController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'data',
				deps: ['api', 'authorization', '$state', '$timeout'],
				useFactory: (api, authorization, $state, $timeout)=>api.get('users/'+ authorization.userId)
					.then((res)=>res.data, (err)=>{
						if ( api.isApiError(err) ) {
							let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
							$timeout(()=>$state.go(state, {error: err}, {location:false}), 13);
						}
					}),
		}],
	});

}];
