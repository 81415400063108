<form name="loginForm" ng-submit="loginForm.$valid && ctrl.submit()" novalidate aria-label="login">
	<div layout="column" layout-align="center stretch" ng-disabled="ctrl.isBusy">
		<md-card class="animate-fade" md-theme="::'danger'" ng-if="ctrl.errorMsg">
			<md-card-content>{{ ctrl.errorMsg }}</md-card-content>
		</md-card>
		<md-input-container class="md-input-has-placeholder">
			<label>Email</label>
			<input type="email" name="email" autocomplete="email" ng-model="ctrl.email" ng-pattern="/^.+@.+\..+$/" required>
			<div ng-messages="loginForm.email.$error" role="alert">
				<div ng-message="required">Enter your e-mail address</div>
				<div ng-message="pattern">Must be a valid e-mail address</div>
			</div>
		</md-input-container>
		<md-input-container class="md-input-has-placeholder">
			<label>Password</label>
			<input type="password" name="password" autocomplete="password" ng-model="ctrl.password" required>
			<div ng-messages="loginForm.password.$error" role="alert">
				<div ng-message-exp="['required']">This is required</div>
			</div>
		</md-input-container>
	
		<div class="layout-row layout-align-space-between-center">
			<a ui-sref="guest.forgotpass" class="md-body-1 margin-2x" ng-disabled="ctrl.isBusy">forgot password</a>
			<md-button type="submit" class="md-raised md-primary" ng-disabled="ctrl.isBusy">
				<span ng-hide="ctrl.isBusy">Login</span>
				<center ng-show="ctrl.isBusy"><md-progress-circular md-mode="indeterminate" md-diameter="20px"></md-progress-circular></center>
			</md-button>
		</div>
	</div>
</form>