<md-dialog aria-label="Cohort Associated User">
	<form name="form" ng-cloak ng-submit="form.$valid && ctrl.$mdDialog.hide(ctrl.user)">
		<md-dialog-content class="margin-4x no-scroll">
			<h3 class="md-title">Cohort Associated User</h3>
			<md-input-container class="md-block md-input-has-placeholder">
				<label>User</label>
				<md-select name="roles" ng-model="ctrl.user" ng-model-options="{trackBy: '$value'}" ng-disabled="ctrl.isBusy" required md-autofocus>
					<md-option ng-value="null">- - none - -</md-option>
					<md-option ng-repeat="item in ctrl.mapping.coaches" ng-value="item._id">{{ item.first_name +' '+ item.last_name }}</md-option>
				</md-select>
				<div ng-messages="form.value.$error" role="alert">
					<div ng-message="required">Required</div>
					<div ng-message="pattern">{{ ctrl.validation.error_message || 'Must match student ID policy' }}</div>
				</div>
			</md-input-container>
		</md-dialog-content>
		<md-dialog-actions layout="row">
			<md-button type="reset" class="black-50" ng-click="ctrl.$mdDialog.cancel()">Cancel</md-button>
			<md-button type="submit" class="md-primary">Save</md-button>
		</md-dialog-actions>
	</form>
</md-dialog>