import angular from 'angular'
import $ from 'jquery'
import {Helper, ApiError, SETTINGS, CONSTANTS} from '../../common'
import {Calendar} from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import BaseSingleController from '../base.single';

import '@fullcalendar/core/main.css';


const NOTIF_TYPES = CONSTANTS.NOTIFICATION_TYPES;

const COLORS = {
	DEFAULT: {light_color: '#A62732', dark_color: '#dcc4ef'},
	[NOTIF_TYPES.TIP_THEME]: {light_color: '#fff', dark_color: '#F26622'},
}

const RESOURCETYPE = {
	BG_IMG: 'tip_background_image',
};

export let BGCOLORS = [
	{value: '#519CA7'},
	{value: '#29A0A1'},
	{value: '#00A39C'},
	{value: '#2AA575'},
	{value: '#7A99AC'},
];

const OFFSET_MAX = 1000;

const HTML_TEXT = CONSTANTS.CONTENT_TYPE.HTML;
const PLAIN_TEXT = CONSTANTS.CONTENT_TYPE.TEXT;

function dataListSorter(a,b){
	a = Helper.parseISO(a.flutter?.start_timestamp || a.flutter?.end_timestamp || a.date_to_send).valueOf();
	b = Helper.parseISO(b.flutter?.start_timestamp || b.flutter?.end_timestamp || b.date_to_send).valueOf();
	return a - b;
}

export default class WorkspacesCalendarController extends BaseSingleController {
	static get $inject(){return [
		'$stateParams',
		'api',
		'apiMap',
		'eventsData',
		'tipThemesData',
		'batch',
		'$q',
		'$timeout',
		'$interval',
		'$window',
		'$mdDialog',
		'$mdSidenav',
		'recipient',
		'authorization',
	].concat(BaseSingleController.$inject)}

	init(){
		this.mapping = {
			notification_types: Helper.superMap(angular.extend({tip_theme:'Tip Theme'}, this.MAPPINGS_JSON.notifications.type_key), {type:'type'}),
			bgColors: this.MAPPINGS_JSON.content?.tip?.background?.colors || BGCOLORS,
			types: {
				[NOTIF_TYPES.TIP_THEME]: 'Tip Theme',
				[NOTIF_TYPES.EVENT]: 'Event',
			},
			approval_status: {
				'Approved': 'approved',
				'Hold': 'held',
				'Unreviewed': null,
			},
			priorities: {
				'100': 'Critical',
				'90': 'High',
				'50': 'Medium',
				'20': 'Low',
			},
			
		};

		this.viewFormat = {date:'MM/DD/YYYY', datetime:'MM/DD/YYYY hh:mm A', time:'hh:mm A'};
		this.modelFormat = {date:'YYYY-MM-DD', datetime:'YYYY-MM-DD HH:mm'};
		this.dateSeparator = ' to ';
		this.dateTimeExample = moment().format(this.viewFormat.datetime);
		this.dateExample = moment().format(this.viewFormat.date);

		this._dataList = [];
		this.dataList = [];
		this.dataMap = Object.create(null);


		this.filters = {
			notification_types: [],
			approval_statuses: [],
			flutter_categories: [],
		};

		this.debouncedSave = Helper.debounce(this.save, 2000);
		this.__autoSaveId = null;
		this.__autoSaveIntervalId = null;
		this.__saveErrors = Object.create(null);
		// let intervalID = this.$interval(()=>this.$scope.$applyAsync(), 59*1000);
		
		this.colorCategory.value = true;

		this._destructors.push(
			()=>{
				// this.clearAutoSaved();
				this.calendar?.destroy();
				this.__autoSaveId && this.$interval.cancel(this.__autoSaveId);
				this.__autoSaveIntervalId && this.$interval.cancel(this.__autoSaveIntervalId);
			},
			this.$scope.$watch('form.$dirty', (val)=>{
				if ( val && this.selected )
					this._enableAutosave(this.selected);
			}),
			this.$scope.$watch('form.$valid', (val, old)=>{
				if ( val && !old && this.selected )
					this._enableAutosave(this.selected);
			}),
			// this.$scope.$watch('tipForm.$dirty', (v)=>{
			// 	if ( !v ) return;
			// 	// this.$scope.tipForm?.$setPristine();
			// 	if ( this.selected ) {
			// 		delete this.lastSaved;
			// 		this.debouncedSave(this.selected);
			// 	}
			// }),
		);


		super.init();
	}
	_loadDependencies(){
		return this.$q.all([
			super._loadDependencies(),
			this.apiMap.getActiveColleges().then(data=>this.mapping.mycolleges = data),
			this.apiMap.getCohortLevels().then(data=>this.mapping.levels = data),
			//this.apiMap.getCohorts().then(data=>this.mapping.allCohorts = data),
			this.apiMap.getCategories().then(data=>this.mapping.flutterCategories = data),
			this.apiMap.getTags().then(data=>this.mapping.flutterTags = data),
			this.getCollegeCohorts(),
		])
			.then(()=>this.recipient.prepare())
			.then(()=>{
				this.$scope.$once('deps-loaded', ()=>{
					let events = this.eventsData.map(item=>Object.assign(item, {notification_type:NOTIF_TYPES.EVENT}));
					let tipThemes = this.tipThemesData.map(item=>Object.assign(item, {notification_type:NOTIF_TYPES.TIP_THEME}));
					
					this.dataList = this.process(Helper.deepCopy(events.concat(tipThemes)));
					this.calendarEvents = this.dataList.map(item=>this._convertToCalendarItem(item));
					this._initCalendar(this.calendarEvents);
					this._initSortableTips();

					this.calculateTally();
				});
			});

	}
	getCollegeCohorts(item){
		this.mapping.cohorts = null;
		return this._getCollegeCohorts(item).then(data=>this.mapping.cohorts=data);
	}
	_getCollegeCohorts(item){
		let colleges = item && item.recipients && item.recipients.filter(v=>v.college).map(group=>group.college) || [];
		return this.apiMap.getCohorts({params:{college: colleges, source:'provided_list'}});
	}


	process(list){
		list = list.filter(Helper.uniquePropertyFilter('_id'));

		this.dataList = list = list.map((item)=>this.dataMap[item._id] = this._processItem(item));
		list.sort(dataListSorter);
		this._dataList = list.concat();

		return this.dataList;
	}
	_processItem(item){
		item.recipients = this._processRecipients(item.recipients);

		if ( item.flutter ) {
			let flutter = item.flutter;
			flutter.name = flutter.name || {};
			flutter.location = flutter.location || {label:{}};

			flutter.end_timestamp = this._processTimestamp(flutter.end_timestamp);
			flutter.start_timestamp = this._processTimestamp(flutter.start_timestamp);
		} else {
			item.flutter = {
				end_timestamp: Helper.parseISO(item.date_to_send).format('YYYY-MM-DD HH:mm'), 
				name:{}, 
				location:{label:{}},
			};
		}

		if ( item.text ) {
			if ( item.text.formatted && item.text.content != CONSTANTS.CONTENT_TYPE.HTML ) {
				item.text.content = CONSTANTS.CONTENT_TYPE.HTML;
			} else
			if ( ! item.text.formatted || ! item.text.content ) {
				item.text.content = CONSTANTS.CONTENT_TYPE.TEXT;
				item.text.formatted = item.text.raw;
			}
		}


		// map tips
		if ( item.notification_type == NOTIF_TYPES.TIP_THEME ) {
			item.tips = Object.values(item.tips || {}).sort(Helper.sortBy('day_offset'));

			item.tips.forEach(tip=>{
				this._processItem(tip);
				tip.background = tip.background || {};
				tip.background.$useImage = !! tip.background.url;
				tip.recipients = this._processRecipients(tip.recipients);
				tip.flutter.tags = Object.keys(tip.flutter.tags || {});
			});

			// if ( item.flutter ) {
			// 	let span = moment(item.flutter.end_timestamp).diff(item.flutter.start_timestamp, 'days');
			// 	item.tips.forEach(item=>item._offset = item._offset || Math.round((item.day_offset-1) * OFFSET_MAX / span));
			// 	console.log('process', item.tips.map(v=>v.day_offset +'/'+ v._offset));
			// }
		}

		return item;
	}
	_processTimestamp(str){
		if ( !str ) return;
		let regex = /^(\d{4})-(\d{2})-(\d{2})\s?(?: (\d{1,2}):(\d{2})\s?(am|pm)?)?$/i;
		// localized date
		let m = str.match(regex);
		if ( m ) {
			let result = moment().year(+m[1]).month(+m[2]-1).date(+m[3]).endOf('day');
			if ( m[4] ) { // has time
				result.hour(+m[4] + (m[6] && /pm/.test(m[6]) ? 12 : 0)).minute(+m[5]).second(0);
				return result.format('YYYY-MM-DD HH:mm');
			}
			return result.format('YYYY-MM-DD');
		} else
		// ISO date
		if ( /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/.test(str) ) {
			// do NOT parse direct ISO dates, display values as is
			return moment(str.substr(0,10) +' '+ str.substr(11,5), 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
		}
	}

	_processRecipients(recipients){
		if ( Array.isArray(recipients) ) {
			return recipients;
		} else 
		if ( typeof recipients == 'object' ) {
			return Object.values(recipients).map(expr=>{
				let list = this.recipient.enumerate(expr, {ignoreErrors:true, ignoreInvalids:true});
				let group = {college:[], cohort:[], level:[]};
				list.forEach(item=>{
					if ( item.type == 'college' ) {
						group.college.push(item._id);
					} else if ( item.type == 'cohort' ) {
						group.cohort.push(item.model.name);
					} else if ( item.type == 'level' ) {
						group.level.push(item.model.name);
					}
				});
				group.college = Array.isArray(group.college) && group.college[0] || undefined;
				return group;
			});
		}
		//return [{college:null, cohort:[], level:[]}];
	}

	_addData(data){
		let model = this._processItem(data);
		if ( model.notification_type==NOTIF_TYPES.EVENT )
			this.eventsData.push(data);
		else
			this.tipThemesData.push(data);

		this._dataList.push(model);
		this.dataList.push(model);
		this._sortData();
		this.dataMap[model._id] = model;

		this.calendar.addEvent(this._convertToCalendarItem(model));
	}
	_removeData(id){
		const model = this.dataMap[id];
		if ( !model ) return;

		if ( model.notification_type==NOTIF_TYPES.EVENT ) {
			let item = this.eventsData.find((item)=>item._id == model._id);
			this.eventsData.remove(item);
		} else {
			let item = this.tipThemesData.find((item)=>item._id == model._id);
			this.tipThemesData.remove(item);
		}

		this._dataList.remove(model);
		this.dataList.remove(model);
		delete this.dataMap[model._id];

		this.calendar.getEventById(id).remove();
	}
	_updateData(data){
		const id = data._id;
		const model = this.dataMap[id];
		if ( !model ) return;

		if ( data.notification_type == NOTIF_TYPES.EVENT ) {
			let old = this.eventsData.find((item)=>item._id == id);
			if ( old ) {
				this.eventsData[this.eventsData.indexOf(old)] = data;
			}
		} else {
			let old = this.tipThemesData.find((item)=>item._id == id);
			if ( old ) {
				this.tipThemesData[this.tipThemesData.indexOf(old)] = data;
			}
		}
	}
	_rollbackDataModel(id){
		let oldModel = this.dataMap[id];
		if ( !oldModel ) return;

		let data;
		if ( oldModel.notification_type == NOTIF_TYPES.EVENT ) {
			data = this.eventsData.find((item)=>item._id == id);
		} else {
			data = this.tipThemesData.find((item)=>item._id == id);
		}
		if ( !data ) return;
		
		this.dataList.remove(oldModel);
		this._dataList.remove(oldModel);

		let model = this._processItem(data);
		
		console.log('rollback into', Helper.deepCopy(model));

		this._dataList.push(model);
		this.dataList.push(model);
		this._sortData();
		this.dataMap[id] = model;

		this.calendar.getEventById(id).remove();
		this.calendar.addEvent(this._convertToCalendarItem(model));
		
	}

	_sortData(){
		this._dataList.sort(dataListSorter);
		this.dataList.sort(dataListSorter);
	}

	_initCalendar(events){
		this.calendar = new Calendar($('#calendar').get(0), {
			plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
			header: {left:'', center:'', right:''},
			timeZone: 'UTC',
			contentHeight: 'auto',
			navLinks: false, // can click day/week names to navigate views
			editable: true,
			eventLimit: true, // allow "more" link when too many events2
			unselectAuto: false,
			selectable: true,
			eventResizableFromStart: true,
			// height: 'parent',
			defaultDate: events.length && events[0].start || new Date(),
			events: events,
			// rerenderDelay: 20,
			dateClick: info=>this.calendar.el.focus(),
			eventClick: info=>this.select(info.event.id) && this.calendar.el.focus(),
			eventDrop: info=>this._calendarMove(this.dataMap[info.event.id], info.delta, info.event),
			eventResize: info=>this._calendarResize(this.dataMap[info.event.id], info.startDelta, info.endDelta, info.event),
			eventRender: info=>{ 
				let item = this.dataMap[info.event.id];
				if ( item ) {
					if ( item.flutter && item.flutter.category ) {
						info.el.setAttribute('title', 'Category: '+ this.mapping.flutterCategories.byId[item.flutter.category].name.en_US);
					} else {
						info.el.removeAttribute('title');
					}

					let content = info.el.firstElementChild;
					// let idEl = this.$window.document.createElement('code');
					// idEl.classList = 'md-caption';
					// idEl.innerHTML = `${item._id} `;
					// content.insertBefore(idEl, content.children[0]);
					if ( item.approval_status ) {
						let icon = this.$window.document.createElement('span');
						icon.className = 'fas fa-'+ (item.approval_status == 'approved' ? 'thumbs-up' : 'thumbs-down');
						content.insertBefore(icon, content.children[0]);
					}

					info.el.setAttribute('role', 'button');
					info.el.setAttribute('tabindex', '0');
					info.el.setAttribute('href', '#');
					info.el.className = info.el.className.replace('selected', '');
					if ( item === this.selected ) {
						info.el.className += ' selected';
						this._$selectedEventElement = info.el;
					}
				}
				
			},
			datesRender: info=>{
				this.calendarDate = moment(info.view.currentStart.toISOString().substr(0,10)).startOf('month');
				if ( ! this._$skipCalendarReposition )
					this.calendar.select({start:info.view.currentStart, allDay:true});
				this._$skipCalendarReposition = false;
			},
			eventOrder: (a, b)=>{
				let na = this.dataMap[a.id], 
					nb = this.dataMap[b.id];
				return this.calendarEvents.indexOf(na) - this.calendarEvents.indexOf(nb);
			},
			eventAllow: (loc, evt)=>{
				let notif = this.dataMap[evt.id];
				if ( notif.notification_type === NOTIF_TYPES.TIP_THEME ) {
					let max = this._getMaxDayOffset(notif);
					let span = moment(loc.end).diff(loc.start, 'days'); // no need to +1, already inclusive
					if ( span < max ) return false;
				}
				return true;
			},
			eventDragStart: info=>debouncedSelect(info.event.id, {noCalendarSelect:true}),
			eventResizeStart: info=>debouncedSelect(info.event.id, {noCalendarSelect:true}),
			eventDragStop: info=>this.calendar.select({start:info.event.start, end:info.event.end, allDay:true}),
			eventResizeStop: info=>this.calendar.select({start:info.event.start, end:info.event.end, allDay:true}),
		});
		this.calendar.render();

		let debouncedSelect = Helper.debounce((id, opts)=>this.select(id, opts), 200);

		this.$timeout(()=>{
			this.calendar?.updateSize();
			if ( this.$stateParams.select ) {
				this.select(this.$stateParams.select);
			}
		}, 500);
	}
	_convertToCalendarItem(notif){
		let cEvent = {id: notif._id, allDay: true};

		if ( notif.flutter ) {
			let flutter = notif.flutter;
			cEvent.title = flutter.short_name || flutter.name?.en_US;

			let dates = this._toCalendarDates(flutter.start_timestamp, flutter.end_timestamp || notif.date_to_send);
			cEvent.start = dates.start;
			cEvent.end = dates.end;
		}

		cEvent.title = cEvent.title || notif.text?.raw || '';

		let color = this.colorCategory.value ? this._getItemColor(notif) : COLORS[notif.notification_type] || COLORS.DEFAULT;
		cEvent.textColor = color.light_color;
		cEvent.borderColor = cEvent.backgroundColor = color.dark_color;

		return cEvent;
	}
	_toCalendarDates(start_timestamp, end_timestamp){
		return {
			start: moment(start_timestamp || end_timestamp).startOf('day').toDate(),
			end: start_timestamp && end_timestamp ? moment(end_timestamp).add(1, 'day').startOf('day').toDate() : null,
		}
	}

	colorCategory(){
		this.colorCategory.value = !this.colorCategory.value;
		this.applyFilters();
	}

	_initSortableTips(){
		let offsets;

		this.sortableOpts = {
			axis: 'y',
			handle: '.sort-handle',
			placeholder: 'sort-placeholder',
			tolerance: 'pointer',
			containment: 'parent',
			opacity: 0.8,
			start: (ev, ui)=>{
				offsets = this.selected.tips.map(v=>v.day_offset);
				// console.log('start', offsets);
				ui.placeholder.height(ui.item.height());
			},
			stop: (ev, ui)=>{
				// let span = moment(this.selected.flutter.end_timestamp).diff(this.selected.flutter.start_timestamp, 'days');
				// this.selected.tips.forEach((item, index)=>item.day_offset = Math.round((item._offset = offsets[index]) / OFFSET_MAX * span) +1);
				this.selected.tips.forEach((item, index)=>item.day_offset = offsets[index]);
				// console.log('sorted', span, Helper.deepCopy(this.selected.tips));
				// this.clearAutoSaved();
				// this.autoSave(this.selected);
				this.$scope.form.$setDirty();
			},
		};
	}

	select(id, opts){
		let model = this.dataMap[id];
		if ( ! model ) return;

		if ( this.isSaving ) {
			this._saving.finally(()=>this.select(id, opts));
			return;
		}

		if ( this.selected && this.selected !== model && this.$scope?.form?.$dirty ) {
			if ( !this._validate(this.selected) ) {
				this.$mdDialog.show(
					this.$mdDialog.confirm()
							.title('Unsaved changes')
							.textContent('You have unsaved changes which could be lost if you select another?')
							.ariaLabel('confirm unsaved changes')
							.ok('Discard Changes')
							.cancel('Cancel')
				).then(()=>{
					this._disableAutosave();
					this._rollbackDataModel(this.selected._id);
					delete this.__saveErrors[id];
					this.unselect();
					this.select(id, opts);
				}, ()=>{
					this._validate(this.selected, null, true);
				});
				return;
			}
		}
		if ( this.selected && this.selected !== model ) {
			this._disableAutosave();
			this.unselect();
		}

		this.selected = this.data = model;
		this.selectedIndex = this.dataList.indexOf(model);

		opts = opts || {};

		this._$dateRange = [];
		if ( model.flutter.start_timestamp )
			this._$dateRange.push(model.flutter.start_timestamp);
		if ( model.flutter.end_timestamp )
			this._$dateRange.push(model.flutter.end_timestamp);

		this.getCollegeCohorts(model).catch((err)=>{
			console.error(err);
		});

		model.link = model.link || {};

		if ( ! opts.noCalendarSelect ) {
			let cEvent = this.calendar.getEventById(model._id);
			if ( cEvent ) {
				cEvent.setProp('borderColor', this._getItemColor(model).light_color);
				this._$skipCalendarReposition = true;
				this.calendar.gotoDate(cEvent.start);
				this.calendar.select({start:cEvent.start, end:cEvent.end, allDay:true});
			}
		}

		this.$scope?.$evalAsync(()=>{
			this.$scope?.form?.$setPristine();
			this.$scope?.tipForm?.$setPristine();
		});

		return model;
	}
	unselect(){
		if ( this._$selectedEventElement )
			this._$selectedEventElement.classList.remove('selected');
		if ( this.selected ) {
			let cEvent = this.calendar.getEventById(this.selected._id);
			if ( cEvent )
				cEvent.setProp('borderColor', this._getItemColor(this.selected).dark_color);

			// if ( this.$scope.form.$dirty ) {
			// 	this._disableAutosave();
			// 	this.save(this.selected, null, true);
			// }
		}

		this.data = null;
		this.selected = this._$selectedEventElement = null;
		this.selectedIndex = 0;
		this._$dateRange = undefined;
		this.lastTimeSaved = undefined;
		if ( this.$scope && this.$scope.form ) {
			this.$scope.form.$setPristine();
			this.$scope.form.$setUntouched();
			this.$scope.$evalAsync();
		}
	}
	selectNext(back){
		if ( this.selected ) {
			this.selectedIndex = (this.selectedIndex + (back ? -1 : 1) + this.dataList.length) % this.dataList.length;
		}
		if ( this.dataList.length > 0 )
			return this.select(this.dataList[this.selectedIndex || 0]._id);
		else if ( this.selected )
			this.unselect();
	}
	selectPrev(){
		return this.selectNext(true);
	}
	selectToday(){
		this.calendar.gotoDate(new Date());
		this.calendar.select({start:new Date(), allDay:true});
	}


	get isSaving(){
		return !! this._saving;
	}

	_enableAutosave(){
		let model = this.selected;
		if ( ! model ) return this.$q.reject('autosave none selected');

		if ( this.__autoSaveId == null ) {
			console.log('autosave enabled');
			// use $interval due to e2e testing limitations with $timeout
			this.__autoSaveId = this.$interval(()=>this._autosave(model), 5*1000, 1);
		}
	}
	_disableAutosave(){
		this.__autoSaveId && this.$interval.cancel(this.__autoSaveId);
		this.__autoSaveId = null;
		this.__autoSavePromise = null;
		console.log('autosave disabled');
	}
	async _autosave(model){
		this._lastModelSaved = null;
		if ( (model===this.selected && this._validate(model)) || !this.__saveErrors[model._id] ) {
			try {
				await this._save(model);

				if ( this.lastTimeSaved ) {
					this.__autoSaveIntervalId && this.$interval.cancel(this.__autoSaveIntervalId);
					// just to update the autosave msg
					this.__autoSaveIntervalId = this.$interval(angular.noop, 60*1000);
				}
			} catch(e) {
				console.error('autosave', e);
			}
		} else {
			console.info('fail validate selected');
		}
		this._disableAutosave();
		this.$scope.$applyAsync();
	}

	async submit(status=null){
		let model = this.selected;
		this._lastModelSaved = null;
		if ( this._validate(model, status, true) ) {
			// if ( this._$dateRange )
			// 	this.setDate(model, this._$dateRange, true);
			if ( status ) this.toast.clear();

			let res = await this._save(model, status);

			if ( res ) {
				if ( status )
					this.toast.success(`${this.mapping.types[model.notification_type]} Updated`);

				if ( model == this.selected ) {
					this.$scope?.form?.$setPristine();
					this.$scope?.tipForm?.$setPristine();
					this.$scope.$applyAsync();
				}

				if ( status ) {
					this.calculateTally();
					this.selectNext();
				} else {
					this.select(model._id);
				}
			}

			return this._lastModelSaved;
		}
	}
	
	_validate(model, status=null, autoFocus=false){
		const form = this.$scope?.form;
		if ( ! this.selected || ! form ) 
			return false;
		
		status ??= model.approval_status;

		delete this.__saveErrors[model._id];

		if ( model._id && status ) {
			if ( form.$invalid ) {
				let errors = Object.values(form.$error||{});
				this.__saveErrors[model._id] = errors;
				autoFocus && errors[0][0].$$element.focus();
				errors.forEach(list=>{
					list.forEach(item=>item.$setTouched());
				});
				return false;
			} else
			if ( status === 'approved' && this._hasApprovalErrors(model) ) {
				this.toast.warn(status=='approved' ? 'Invalid fields must be resolved before approval' : 'Cannot save with invalid fields if already approved');
				return false;
			}
		}

		if ( !form.date.$valid ) return false;
		
		return true;
	}
	async _save(model, status=null){
		try {
			console.log('saving', model._id);
			this._lastModelSaved = null;
			this._saving = this.__save(model, status);
			await this._saving;
			this._lastModelSaved = model;
			this.lastTimeSaved = moment();
			console.log('saved', model._id);
		} catch(err) {
			console.log('saved fail', model._id);
			this.api.handleError(err);
		}
		this._saving = undefined;

		return this._lastModelSaved;
	}
	async __save(model, status=null){
		const {payload, files} = this._preparePayload(model, status);

		if ( ! model._id ) {
			this.isBusy = true;
			if ( model.notification_type == NOTIF_TYPES.EVENT )
				await this._createEvent(model, payload);
			else
				await this._createTipTheme(model, payload);
		} else {
			if ( model.notification_type == NOTIF_TYPES.EVENT )
				await this._updateEvent(model, payload, status);
			else
				await this._updateTipTheme(model, payload, status, files);
		}
		this.isBusy = false;

		if ( status ) {
			model.approval_status = status;
			model.date_approval_status_updated = payload.date_approval_status_updated;
		}
	}

	_preparePayload(notif, status=null){
		const payload = Helper.deepCopy(notif._id ? this.dataMap[notif._id] : notif);
		const files = [];

		if ( status ) {
			payload.approval_status = status;
			payload.date_approval_status_updated = Helper.toTimestamp(new Date());
		}
		payload.owner_college_id = notif.owner_college_id || this.batch.college_id;
		payload.ancestry = SETTINGS.apiAncestry;
		payload.publish = false;
		payload.reminder_plan = notif.reminder_plan || undefined;
		payload.recipients = this._prepareRecipients(notif.recipients);
		payload.workspace_id = this.batch._id;
		payload.modified_by_user_id = +this.authorization.userId;
		payload.modified_date = Helper.toTimestamp(new Date());
		
		payload.flutter = payload.flutter || {};
		payload.flutter.name = notif.flutter.name?.en_US ? {en_US: notif.flutter.name.en_US} : undefined;
		payload.flutter.location = notif.flutter.location?.label?.en_US ? {label:{en_US: notif.flutter.location.label.en_US}} : undefined;

		let start = Helper.parseDateTime(notif.flutter.start_timestamp || notif.flutter.end_timestamp);
		let end = notif.flutter.start_timestamp && Helper.parseDateTime(notif.flutter.end_timestamp) || null;
		let globalFormat = notif.flutter.source_timezone=='global' ? 'YYYY-MM-DD[T]HH:mm[:00Z]' : null;
		if ( end ) {
			payload.flutter.start_timestamp = start.format(globalFormat || 'YYYY-MM-DD'+ (start._hasTime ? ' HH:mm' :''));
			payload.flutter.end_timestamp = end.format(globalFormat || 'YYYY-MM-DD'+ (end._hasTime ? ' HH:mm' :''));
		} else {
			payload.flutter.start_timestamp = undefined;
			payload.flutter.end_timestamp = start.format(globalFormat || 'YYYY-MM-DD'+ (start._hasTime ? ' HH:mm' :''));
		}

		payload.date_to_send = Helper.toNoonISO(start);

		payload.flutter.notification_type = notif.flutter.notification_type || notif.notification_type;
		payload.flutter.short_name = notif.flutter.short_name || undefined;
		payload.flutter.source_timezone = notif.flutter.source_timezone || 'college';
		payload.flutter.notes = notif.flutter.notes || undefined;
		payload.flutter.category = notif.flutter.category || undefined;
		payload.link = notif.link?.url && notif.link.text ? {url:notif.link.url, text:notif.link.text} : undefined;
		payload.reminder_plan = notif.reminder_plan || undefined;

		if ( notif.notification_type === NOTIF_TYPES.EVENT) {
			if ( notif.text.content == HTML_TEXT ) {
				payload.text = {
					content: notif.text.content,
					raw: Helper.htmlToText(notif.text.formatted),
					formatted: notif.text.formatted,
				};
			} else {
				payload.text = {
					content: notif.text.content || PLAIN_TEXT,
					raw: notif.text.formatted,
					formatted: undefined,
				};
			}
		} else
		if ( notif.notification_type === NOTIF_TYPES.TIP_THEME ) {
			payload.text = {
				raw: (notif.flutter.name?.en_US || '').replace(/^tip\s*theme:\s*/i, '').trim() || undefined,
				content: PLAIN_TEXT,
			};
			const tipThemeName = payload.text.raw;
			
			payload.tips = notif.tips.reduce((obj, model, i)=>{
				const id = `tip_${i+1}`;
				const tip = Helper.deepCopy(model);
				
				tip.tip_theme = tipThemeName;
				tip.ancestry = model.ancestry || SETTINGS.apiAncestry;
				tip.recipients = this._prepareRecipients(model.recipients);
				tip.call_to_action = model.call_to_action || undefined;
				
				tip.flutter = tip.flutter || {};
				tip.flutter.notification_type = model.flutter.notification_type || 'tips';
				tip.flutter.name = model.flutter.name?.en_US ? {en_US: notif.flutter.name.en_US} : undefined;
				tip.flutter.tags = model.flutter.tags?.length ? model.flutter.tags.reduce((obj, id)=>Object.assign(obj, {[id]: {}}), {}) : undefined;
				tip.flutter.location = model.flutter.location?.label?.en_US ? {label: {en_US: model.flutter.location.label.en_US}} : undefined;
				tip.flutter.category = model.flutter.category || undefined;
				
				tip.text = model.text.content == HTML_TEXT ? {
							content: model.text.content,
							raw: Helper.htmlToText(model.text.formatted),
							formatted: model.text.formatted,
						} : {
							content: model.text.content || PLAIN_TEXT,
							raw: model.text.formatted,
							formatted: undefined,
						};
				tip.attribution = model.attribution || undefined;
				tip.background = model.background?.url || model.background?.color ? {
							url: model.background.$useImage && model.background.url || undefined,
							color: !model.background.$useImage && model.background.color || undefined,
							alt_text: model.background.alt_text || undefined,
						} : undefined;
				tip.link = model.link?.url && model.link.text ? {url:model.link.url, text:model.link.text} : undefined;
				tip.day_offset = +model.day_offset || undefined;
				tip.priority = +model.priority || undefined;

				if ( model.background.$useImage && model.background.file ) {
					let file = model.background.file;
					file.resourceType = RESOURCETYPE.BG_IMG;
					file.metadata = `tip_${i+1}`;
					file.callback = (url, payload)=>{ // remember payload is tiptheme, not tip
						model.background = {url, $useImage:true};
						payload.tips[id].background = {url};
					};
					files.push(file);
				}

				// CON2-744; fix recipient group of tips
				if ( tip.recipients.recipient_group_0 ) {
					tip.recipients.recipient_group_1 = tip.recipients.recipient_group_0;
					delete tip.recipients.recipient_group_0;
				}
				// cleanup
				delete tip._$max_day_offset;

				return Object.assign(obj, {[id]: tip});
			}, {});
		}

		return {payload, files};
	}
	_prepareRecipients(recipients){
		let obj = {};
		(recipients || [{level:[], cohort:[]}]).map(group=>{
			let list = [];
			if ( group.college )
				list.push('college='+ group.college);
			else
				list.push('college='+ this.batch.college_id);
			let cohort = [].concat(group.level||[], group.cohort||[]);
			if ( cohort.length > 0 )
				list.push(cohort.map(s=>'cohort='+s).join(' OR '));

			if ( list.length > 1 )
				return '(('+ list.join(') AND (') +'))';
			if ( list.length === 1 )
				return '('+ list[0] +')';
			return '';
		}).forEach((expr, i)=>obj[`recipient_group_${i+1}`] = expr);
		return obj;
	}
	_hasApprovalErrors(notif){
		if ( notif.notification_type === NOTIF_TYPES.TIP_THEME ) {
			if ( ! notif.tips.length ) {
				this.$mdDialog.show(
					this.$mdDialog.confirm()
							.title('Tip Required')
							.textContent('Add a tip before approving the tip theme.')
							.ariaLabel('alert')
							.ok('Add a Tip')
							.cancel('Ok')
				).then(()=>this.addTip());
				return true;
			}
			let errorTip = notif.tips.find(tip=>!tip.call_to_action || !tip.text || !tip.flutter.category || !tip.day_offset || (tip.link?.text && !tip.link.url) || (tip.link?.url && !tip.link.text) );
			if ( errorTip ) {
				this.editTip(errorTip)
					.then(()=>{
						let errors = Object.values(this.$scope && this.$scope.tipForm && this.$scope.tipForm.$error||{});
						if ( errors.length ) {
							errors[0][0].$$element.focus();
							errors.forEach(list=>{
								list.forEach(item=>item.$setTouched());
							});
						}
						// this.$scope.tipForm.$setTouched()
					});
				return true;
			}
		}

		return false;
	}

	async __create(resource, model, payload) {
		let res = await this.api.post(resource, payload);
		this.toast.success(`New ${this.mapping.types[payload.notification_type]} Added`);
		model._id = res.data._id;

		return (await this.api.get(`${resource}/${model._id}`)).data;
	}
	async __update(resource, model, payload, status) {
		let cfg = {level: status!=model.approval_status ? ApiError.LEVEL.AUTO : ApiError.LEVEL.IGNORED};
		console.log(`${resource}/${model._id}`, payload, cfg);
		await this.api.put(`${resource}/${model._id}`, payload, cfg);
		this._updateData(payload);
	}

	async _createEvent(model, payload){
		let data = await this.__create('events', model, payload);
		this._addData(data);
	}
	async _updateEvent(model, payload, status){
		await this.__update('events', model, payload, status);
	}

	async _createTipTheme(model, payload){
		let data = await this.__create('tipThemes', model, payload);
		this.tipThemesData.push(data);
		this._addData(this._processItem(data));
	}
	async _updateTipTheme(model, payload, status, files=[]){
		await this.__update('tipThemes', model, payload, status);
		
		try {
			await this._upload(files, model._id, payload, `tipThemes/${model._id}`, `tip_id:${model._id}`);
		} catch(err){
			this.toast.warn(err, 0);
		}
	}



	async delete($evt){
		if ( ! this.selected || this.isBusy ) return false;

		const label = this.mapping.types[this.selected.notification_type];

		let success = await super.delete($evt, label);
		if ( ! success ) return success;

		let model = this.selected;
		
		this.promptExit.disable();

		this._removeData(model._id);

		this.unselect();
		this.calculateTally();
		
		this.isBusy = false;
		return success;
	}
	async _delete(){
		if ( this.selected.notification_type==NOTIF_TYPES.EVENT ) {
			await this.api.delete('events/'+this.selected._id);
		} else {
			await this.api.delete('tipThemes/' + this.selected._id);
		}
	}


	setDate(notif, range, skipCalendarUpdate){
		if ( ! notif || ! range ) return;

		if ( range.length > 1 ) {
			if ( range[0] === range[1] ) {
				notif.flutter.end_timestamp = range[0];
				range.splice(1);
				delete notif.flutter.start_timestamp;
			} else {
				notif.flutter.start_timestamp = range[0];
				notif.flutter.end_timestamp = range[1];
			}
		} else {
			delete notif.flutter.start_timestamp;
			notif.flutter.end_timestamp = range[0] || undefined;
		}

		if ( ! skipCalendarUpdate ) {
			let cEvent = this.calendar.getEventById(notif._id);
			if ( cEvent ) {
				let dates = this._toCalendarDates(notif.flutter.start_timestamp, notif.flutter.end_timestamp);

				// immediately move the event on calendar
				cEvent.setDates(dates.start, dates.end, {allDay:true});
				// cEvent.setAllDay( true );
				// cEvent.setStart( dates.start );
				// cEvent.setEnd( dates.end );

				if ( notif === this.selected ) {
					this.calendar.gotoDate(dates.start);
					this.calendar.select(dates.start, dates.end);
				}
			}
		}

		this._sortData();
	}

	propagateDelayedEvent($event){
		setTimeout(()=>{
			$(window.document.elementFromPoint($event.pageX, $event.pageY))
				.trigger('focus')
				.trigger('click');
		}, 100);
	}
	onFileSelect($evt){
		console.log($evt);
	}

	setTitle(notif, value){
		let cEvent = this.calendar.getEventById(notif._id);
		value = notif.flutter && notif.flutter.short_name || notif.flutter && notif.flutter.name && notif.flutter.name.en_US || notif.text && notif.text.raw || '';
		if ( cEvent && cEvent.title != value )
			cEvent.setProp('title', value);
	}
	setCategory(notif, value){
		let color = this._getItemColor(notif);
		let cEvent = this.calendar.getEventById(notif._id);
		if ( cEvent ) {
			cEvent.setProp('textColor', color.light_color);
			cEvent.setProp('borderColor', color.dark_color);
			cEvent.setProp('backgroundColor', color.dark_color);
		}
	}

	isValidDateRange($moments, $model, $view){
		if ( this.selected && this.selected.notification_type == NOTIF_TYPES.TIP_THEME ) {
			let max = this._getMaxDayOffset(this.selected);
			if ( $moments.length === 2 ) {
				let diff = $moments[1].diff($moments[0], 'days') +1; // plus 1 to include start day
				return diff >= max;
			}
		}
		return true;
	}
	_getMaxDayOffset(theme){
		theme = theme || this.selected;
		let max = 1;
		if ( theme && theme.notification_type == NOTIF_TYPES.TIP_THEME ) {
			theme.tips.forEach(tip=>{
				max = Math.max(tip.day_offset || 0, max);
				tip._$max_day_offset = max;
			});
			//theme.tips._$max_day_offset = max;
		}
		return max;
	}


	_calendarMove(notif, delta, cEvent){
		if ( ! notif ) return;
		let flutter = notif.flutter;

		let end = Helper.parseISO(flutter.end_timestamp);
		let start = Helper.parseISO(flutter.start_timestamp) || undefined;

		Object.keys(delta).forEach(k=>end.add(delta[k], k) && start && start.add(delta[k], k));

		flutter.end_timestamp = end.format(end && end._hasTime ? this.modelFormat.datetime : this.modelFormat.date);
		if ( start )
			flutter.start_timestamp = start.format(start && start._hasTime ? this.modelFormat.datetime : this.modelFormat.date);

		return this._calendarAdjust(notif, cEvent);
	}
	_calendarResize(notif, startDelta, endDelta, cEvent){
		if ( ! notif ) return;
		let flutter = notif.flutter;

		let end = Helper.parseISO(flutter.end_timestamp);
		let start = Helper.parseISO(flutter.start_timestamp || flutter.end_timestamp);

		Object.keys(endDelta).forEach(k=>endDelta[k] && end.add(endDelta[k], k));
		flutter.end_timestamp = end.format(end && end._hasTime ? this.modelFormat.datetime : this.modelFormat.date);

		if ( start ) {
			if ( ! flutter.start_timestamp ) delete start.startOf('day')._hasTime;
			Object.keys(startDelta).forEach(k=>startDelta[k] && start.add(startDelta[k], k));
			start = moment.min(start, end);
			flutter.start_timestamp = start.format(start && start._hasTime ? this.modelFormat.datetime : this.modelFormat.date);

			if ( start.isSame(end) || (start.dayOfYear() === end.dayOfYear() && ! start._hasTime) ) {
				delete flutter.start_timestamp;
			}
		}


		return this._calendarAdjust(notif, cEvent);
	}
	_calendarAdjust(notif, cEvent){
		if ( notif && notif._id ) {
			this.select(notif._id, {noCalendarSelect:true});

			// if ( notif.notification_type === NOTIF_TYPES.TIP_THEME ) {
			// 	let span = moment(notif.flutter.end_timestamp).diff(notif.flutter.start_timestamp, 'days');
			// 	notif.tips.forEach(item=>item.day_offset = Math.round(item._offset / OFFSET_MAX * span) +1);
			// 	console.log('adjust', notif.tips.map(v=>v.day_offset));
			// }

			this._enableAutosave(notif);
		}
		this._sortData();
		this.calendar.select({start:cEvent.start, end:cEvent.end, allDay:true});
		setTimeout(()=>this.calendar.select({start:cEvent.start, end:cEvent.end, allDay:true}), 20);
	}


	_getItemColor(model){
		return angular.extend(
			{}, COLORS.DEFAULT,
			model && COLORS[model.notification_type] || {},
			model && model.flutter && model.flutter.category && this.mapping.flutterCategories.byId[model.flutter.category] || {}
		);
	}



	addNewEvent(){
		return this._addNew({
			notification_type: NOTIF_TYPES.EVENT,
			flutter: {
				name: {en_US:''},
				location: {label:{}},
			},
			text: {content: HTML_TEXT, formatted:''},
			reminders: {},
			link: {},
		});
	}
	addNewTipTheme(){
		return this._addNew({
			notification_type: NOTIF_TYPES.TIP_THEME,
			tips: [],
			flutter: {
				hide_from_flutter: true,
				name: {en_US:'Tip Theme'},
			},
			text: {content: PLAIN_TEXT, raw:''},
		});
	}
	_addNew(item){
		let start;
		if ( this.calendar.state.dateSelection && this.calendar.state.dateSelection.range ) {
			start = this.calendar.state.dateSelection.range.start;
		} else if ( this.selected ) {
			start = this.calendar.getEventById(this.selected._id).start;
		} else {
			start = moment(this.calendar.getDate()).startOf('month').toDate();
		}

		Object.assign(item, {
			batch_id: this.batch._id,
			ancestry: SETTINGS.apiAncestry,
			recipients: this.batch.college_id ? [{college: this.batch.college_id}] : [{}],
			created_by_user_id: +this.authorization.userId,
			created_date: Helper.toTimestamp(new Date()),
		});
		Object.assign(item.flutter, {
			end_timestamp: moment(start.toISOString().substr(0,10), 'YYYY-MM-DD').format(this.modelFormat.date),
			source_timezone: 'college',
		});

		console.log('new', item);

		this._save(item)
			.then((model)=>{
				this.calendar.rerenderEvents();
				this.select(model._id);
				setTimeout(()=>$('[name="name"]').focus(), 100);
			})
	}


	applyFilters(){
		let list = this._dataList.filter((item)=>item.notification_type != 'tips');
		if ( this.filters.approval_statuses.length )
			list = list.filter((item)=>this.filters.approval_statuses.includes(item.approval_status || null));
		if ( this.filters.notification_types.length )
			list = list.filter((item)=>this.filters.notification_types.includes(item.notification_type));
		
		if ( this.filters.search ) {
			let search = this.filters.search.toLowerCase().trim();
			list = list.filter(item=>
				String(item._id).indexOf(search) > -1 || 
				(item.text && item.text.raw.toLowerCase().indexOf(search)) > -1 || 
				(item.flutter.name.en_US && item.flutter.name.en_US.toLowerCase().indexOf(search) > -1) || 
				(item.flutter.location && item.flutter.location.label && item.flutter.location.label.en_US && item.flutter.location.label.en_US.toLowerCase().indexOf(search) > -1)
			);
		}
		if ( this.filters.flutter_categories.length )
			list = list.filter(item=>this.filters.flutter_categories.includes(item.flutter.category));
		
		list.sort(dataListSorter);

		this.updateCalendarEvents(list);

		if ( this.selected && ! this.calendar.getEventById(this.selected._id) )
			this.unselect();
		else if ( this.selected )
			this.select(this.selected._id, {noCalendarSelect:true});
	}

	updateCalendarEvents(list){
		if ( ! angular.equals(this.dataList, list) ) {
			this.dataList = list;

			this.calendar.getEventSources().forEach(src=>src.remove());
			
			this.calendarEvents = list.map((item)=>this._convertToCalendarItem(item));
			this.calendar.addEventSource(this.calendarEvents);

			this.calculateTally();
		}
	}

	calculateTally(){
		this.tally = {
			approved: 0,
			held: 0,
			'null': 0,
		};
		let items =  this.dataList.filter(item=>item.notification_type != 'tips');
		items.forEach(item=>this.tally[item.approval_status || 'null']++);
		
		let total = items.length;
		Object.keys(this.tally).forEach(k=>this.tally[k+'_perc'] = Math.round(this.tally[k] / total * 100) || 0);
		return this.tally;
	}


	addTip(){
		if ( this.selected && this.selected.tips ) {
			let tip = {
				notification_type: 'tips',
				background: {$useImage: false},
				flutter: {
					notification_type: 'tips',
					name: {},
					location: {label:{}},
					source_timezone: 'college',
				},
				day_offset: this._getMaxDayOffset(this.selected),
				recipients: this.batch.college_id ? [{college: this.batch.college_id}] : [{}],
				created_by_user_id: +this.authorization.userId,
				created_date: Helper.toTimestamp(new Date()),
				text: {content:HTML_TEXT, formatted:''},
				link: {},
			};

			this.selected.tips.push(tip);
			this.editTip(tip)
				.then(()=>this.selected.approval_status!='approved' ? this._save(this.selected) : null);
		}
	}
	removeTip($index, $ev){
		if ( this.selected ) {
			this.$mdDialog.show(
				this.$mdDialog.confirm()
						.title('Confirm Remove')
						.textContent('Are you sure you want to remove this tip?')
						.ariaLabel('confirm remove')
						.targetEvent($ev)
						.ok('Remove')
						.cancel('Cancel')
			)
			.then(()=>this.selected.tips.splice($index, 1))
			.then(()=>this._save(this.selected));
		}
	}
	editTip(tip){
		this.selectedTip = tip;
		this.getTipCollegeCohort();

		$('#notification-batch-calendar').css('overflow-x', 'hidden');
		this.$mdSidenav('tip-form').onClose(()=>{
			$('#notification-batch-calendar').css('overflow-x', '');
			this.selectedTip = null;
			this._save(this.selected);
		});

		return this.$mdSidenav('tip-form').open().then(()=>{
			this.$scope.tipForm?.$setUntouched();
			this.$scope.tipForm?.$setPristine();
		});
	}
	isEditingTip(tip){
		// return this.selectedTip && (tip===undefined || this.selectedTip._target === tip);
		return this.selectedTip === tip;
	}

	closeEditTip(){
		return this.$mdSidenav('tip-form').close();
	}
	
	getTipCollegeCohort(){
		this.mapping.tipCohorts = null;
		return this._getCollegeCohorts(this.selectedTip).then(data=>this.mapping.tipCohorts=data);
	}

	changeTipDayOffset(){
		if ( this.selected && this.selected.tips ) {
			this.selected.tips.sort(Helper.sortBy('day_offset'));
			
			let max = this._getMaxDayOffset(this.selected);
			let start, end;
			if ( this._$dateRange.$moments ) {
				start = this._$dateRange.$moments[0];
				end = this._$dateRange.$moments[1] || start.clone();
			} else {
				start = Helper.parseISO(this._$dateRange[0]);
				end = this._$dateRange.length > 1 ? Helper.parseISO(this._$dateRange[1]) : start.clone();
			}
			let diff = end.diff(start, 'days') +1;
			if ( diff < max ) {
				this._$dateRange = [this._$dateRange[0], end.add(max - diff, 'days').format(end._hasTime ? this.modelFormat.datetime : this.modelFormat.date)];
				this.setDate(this.selected, this._$dateRange);
				// this.clearAutoSaved();
				// this.autoSave(this.selected);
			}
		}
	}


}