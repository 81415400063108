<form name="form" ng-submit="ctrl.submit(form)" novalidate autocomplete="off" aria-label="login MFA">
  <div layout="column" layout-align="center stretch" ng-disabled="ctrl.isBusy">
    <md-input-container class="margin-0x" class="md-input-has-placeholder">
      <md-select name="type" class="select-mfa" aria-label="select authenticator"
        md-container-class="select-container-mfa" 
        ng-model="ctrl.selected" ng-disabled="ctrl.isBusy || ctrl.factors.length==1"
        ng-change="ctrl.changeSelected()">
        <md-option ng-repeat="item in ctrl.factors" ng-value="::item">
          <i class="font-icon" ng-class="::item.icon" ng-show="::item.icon"></i>
          <img ng-src="{{::item.img}}" ng-show="::item.img" class="brand-icon" alt="{{item.label}}" />
          <span class="label">{{:: item.label }}</span>
        </md-option>
      </md-select>
    </md-input-container>

    <p class="md-body-1 text-center" ng-hide="ctrl.selected.type=='sms' && ctrl.isBusyChallenge">
      {{ ctrl.selected.desc }}
      <br>
    </p>

    <md-card class="animate-fade" md-theme="::'danger'" ng-if="ctrl.errorMsg">
      <md-card-content>{{ ctrl.errorMsg }}</md-card-content>
    </md-card>

    <div class="layout-row">
      <md-input-container class="flex inline md-input-has-placeholder">
        <label>Enter Code</label>
        <input type="text" name="pin_code" required md-no-asterisk 
          autocomplete="off" inputmode="numeric" 
          ng-model="ctrl.pin_code">
        <div ng-messages="form.pin_code.$error" role="alert">
          <div ng-message="required">Required</div>
        </div>
      </md-input-container>
      <md-input-container class="inline">
        <md-button type="submit" class="md-raised md-primary" ng-disabled="ctrl.isBusy">
          <span ng-hide="ctrl.isBusy">Verify</span>
          <center ng-show="ctrl.isBusy"><md-progress-circular md-mode="indeterminate" md-diameter="20px"></md-progress-circular></center>
        </md-button>
      </md-input-container>
    </div>
    
    <div class="layout-row layout-align-space-between-center">
      <a ui-sref="guest.login" class="md-body-1 margin-2x" ng-disabled="ctrl.isBusy">back to login</a>
      <md-button type="button" class="md-primary compact"
        ng-click="ctrl.sendChallenge()"
        ng-if="ctrl.selected.type=='sms'" ng-disabled="ctrl.isBusy || ctrl.isBusyChallenge">
        <span ng-hide="ctrl.isBusyChallenge">Resend Text</span>
        <center ng-show="ctrl.isBusyChallenge"><md-progress-circular md-mode="indeterminate" md-diameter="20px"></md-progress-circular></center>
      </md-button>
    </div>
  </div>
</form>