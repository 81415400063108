<header class="md-whiteframe-2dp flex-none layout-row layout-align-space-between-start layout-wrap">
	<h2 id="pageTitle" class="flex-xs-100 md-headline">
		<a ui-sref="^" ui-sref-opts="ctrl.parentStateParams">Tasks <span class="margin-1x" aria-hidden="true">&#9656;     
		</span></a>{{ ctrl.data ? '' : 'Add New' }}
		<span id="docTitle" ng-show="ctrl.data">{{:: ctrl.data ? 'Task #'+ ctrl.data._id : 'new task'}}</span>
		<span role="img" aria-label="changes not saved" title="changes not saved" ng-show="form.$dirty">*</span>
	</h2>
	<div class="top-buttons flex-xs-100" ng-show="ctrl.data" form-disabled="ctrl.isBusy">
		<md-button id="cloneBtn" aria-labelledby="cloneBtn docTitle"
			ui-sref="app.milestones.add({clone: ctrl.data._id})"
			ng-if=":: mainCtrl.hasPermission(PERMISSIONS.CREATE_TASKS)">
			<span class="fas fa-copy"></span> 
			Clone
		</md-button>
		<md-button class="md-warn" id="deleteBtn" aria-labelledby="deleteBtn docTitle" 
			ng-click="ctrl.delete($event)"
			ng-if=":: mainCtrl.hasPermission(PERMISSIONS.DELETE_TASKS)"
			ng-hide="ctrl.isLocked">
			<span class="far fa-trash-alt"></span> 
			Delete
		</md-button>
	</div>
	
	<div flex="100" ng-include="::'common/lockCard.tpl'"></div>
</header>

<main class="flex">
	<form id="milestone-form" name="form" novalidate 
		class="flex-gt-sm" ng-class="{locked: ctrl.isLocked}" 
		form-disabled="ctrl.isBusy || ctrl.isLocked"
		ng-submit="!ctrl.isBusy && !ctrl.isLocked && ctrl.submit($event, form)"
		ng-model-options="{updateOn:'default blur', debounce:{'default':250, 'blur':0}}">
		<input type="submit" class="hide" tabindex="-1">

		<div class="layout-column layout-gt-md-row layout-align-start-stretch">
			<div class="panel-wrap panel-small flex">
				<div class="panel" md-whiteframe="1">
					<div class="panel-title md-title normal-wg padding-4x" scroll-spy-target="home">Task Details</div>
					
					<div ng-include="'recipientGroup.tpl'"></div>

					<div class="panel-content padding-4x" ng-hide="ctrl.mapping.mycolleges.length == 1">
						<div class="flex-gt-sm-50" content-owner="ctrl.model.owner_college_id"></div>
					</div>

					<div class="panel-content margin-4x" scroll-spy-target="details">
						<md-input-container class="md-block md-input-has-placeholder">
							<label>Title</label>
							<input name="title" valid-lang ng-model="ctrl.model.content_directory.title" ng-change="ctrl.onDetailsChanged()" required>
							<div ng-messages="form.title.$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="lang-missing">{{ form.title.$error['lang-missing'] }}</div>
								<div ng-message="lang-mismatch">{{ form.title.$error['lang-mismatch'] }}</div>
								<div ng-message="lang">Invalid language tag formatting</div>
							</div>
						</md-input-container>
						<md-input-container class="md-block md-input-has-placeholder">
							<label>Description</label>
							<textarea name="description" ng-model="ctrl.model.description.formatted"
								ng-change="ctrl.onDescriptionChange(ctrl.model.description)"
								tinymce plain-text="ctrl.model.description.content==PLAIN_TEXT"
								switch-content-type="true"
								content-type-model="ctrl.model.description.content"
								md-no-resize rows="3" max-rows="8"></textarea>
							
							<div ng-messages="form.description.$error" role="alert">
								<div ng-message="required">Required</div>
							</div>
						</md-input-container>
						<!-- <div class="layout-row layout-wrap">
							
							<span class="flex"></span>
						</div> -->
						
						<div class="layout-row layout-wrap">
							<md-input-container class="flex-xs-80 flex-gt-xs-30 padding-0x md-input-has-placeholder">
								<label>Points</label>
								<input type="number" name="task_points" ng-change="ctrl.calculateTotalPoints();" ng-model="ctrl.model.task_points" step="1" min="0">
							</md-input-container>
							<span class="flex-20"></span>
							<md-input-container class="flex-xs-100 flex-gt-xs-50 padding-0x md-input-has-placeholder" 
								ng-model-options="{debounce: 1000}">
								<label>Release Date</label>
								<md-icon class="far fa-calendar"></md-icon>
								<input type="text" name="release_date" 
									date-picker
									valid-date required
									ng-model="ctrl.model.content_directory.release_date" ng-model-options="{updateOn:'blur'}">
								<div ng-messages="form.release_date.$error" role="alert">
									<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
								</div>
							</md-input-container>
						</div>

						<div class="layout-row layout-wrap">
							<md-input-container class="flex-xs-100 flex-gt-xs-45 padding-0x md-input-has-placeholder">
								<label>Deadline Type</label>
								<md-select name="deadline_type" ng-model="ctrl.model.content_directory.deadline.deadline_type" ng-model-options="{trackBy: '$value'}" 
									ng-change="ctrl.model.content_directory.deadline.deadline_type=='none' ? (ctrl.model.content_directory.deadline.date = null) : (ctrl.model.content_directory.deadline.date = _$prevDeadline || null)"
									ng-disabled="ctrl.isBusy || ctrl.isLocked" required>
									<md-option ng-repeat="item in ctrl.mapping.deadlines" ng-value="item._id">{{item.name}}</md-option>
								</md-select>
								<div class="md-errors-spacer pull-right"></div>
								<div ng-messages="form.deadline_type.$error" role="alert">
									<div ng-message="required">Required</div>
								</div>
							</md-input-container>
							<span class="flex-gt-xs-5"></span>
							<md-input-container class="flex-xs-100 flex-gt-xs-50 padding-0x md-input-has-placeholder" 
								form-disabled="!ctrl.model.content_directory.deadline.deadline_type || ctrl.model.content_directory.deadline.deadline_type=='none'"
								ng-model-options="{debounce: 1000}">
								<label>Deadline</label>
								<md-icon class="far fa-calendar-minus"></md-icon>
								<input type="text" name="deadline"
									date-picker
									valid-date
									ng-required="ctrl.model.content_directory.deadline.deadline_type && ctrl.model.content_directory.deadline.deadline_type!='none'"
									ng-model="ctrl.model.content_directory.deadline.date" ng-model-options="{updateOn:'blur'}"
									ng-change="ctrl.model.content_directory.deadline.date && (_$prevDeadline = ctrl.model.content_directory.deadline.date)">
								<div ng-messages="form.deadline.$error" role="alert">
									<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
								</div>
							</md-input-container>

							<span class="flex"></span>
						</div>

						<!-- <div class="layout-row layout-align-end-start layout-wrap">
							<md-input-container>
								<label>Last Deadline</label>
								<md-icon class="far fa-calendar"></md-icon>
								<input name="last_deadline" class="md-input disabled" ng-value="ctrl.model.last_deadline | amDateFormat:SETTINGS.dateFormat" no-form-disable ng-disabled="::true">
							</md-input-container>
						</div> -->

						<md-input-container class="md-block md-input-has-placeholder">
							<label>Tags</label>
							<md-select name="tags" aria-label="tags" role="listbox" multiple
								ng-model="ctrl.model.content_directory.tags" ng-model-options="{trackBy: '$value'}" 
								ng-disabled="ctrl.isBusy || ctrl.isLocked">
								<md-option ng-repeat="item in ctrl.mapping.flutterTags" ng-value="item._id">{{ item.name.en_US }}</md-option>
							</md-select>
							<div class="md-errors-spacer"></div>
						</md-input-container>

							<div class="layout-row">
								<md-input-container class="md-block flex no-animate right-action-btn md-input-has-placeholder" ng-hide="ctrl.model.content_directory.image_file">
									<label>Image URL</label>
									<input type="url" class="md-input" name="image_url" ng-model="ctrl.model.content_directory.image_url" ng-focus="ctrl.updatePreview('home', true)" ng-change="ctrl.model.content_directory.image_small_url = ctrl.model.content_directory.image_url">
									<md-button class="md-icon-button times md-warn md-hue-1 compact" ng-click="ctrl.model.content_directory.image_url=''" for="image_url" aria-label="Clear value"><i class="fas fa-times"></i></md-icon></md-button>
									<div ng-messages="form.image_url.$error" role="alert">
										<div ng-message="url">Invalid URL format (ie: https://example.com/image.jpeg)</div>
									</div>
								</md-input-container>

								<md-input-container class="md-block flex-nogrow no-animate" ng-hide="ctrl.isLocked">
									<md-button type="file" name="image_url_file" class="md-primary compact"
										ngf-select accept="image/*" ngf-pattern="'image/*'" ng-disabled="ctrl.isBusy"
										ngf-multiple="false" ngf-keep="true"
										ngf-change="$newFiles.length ? (ctrl.model.content_directory.image_file = $newFiles.pop()) : null; ctrl.updatePreview('home', true)">
										<i class="fas fa-file-upload"></i> Select File
									</md-button>
								</md-input-container>

								<md-input-container class="md-block flex right-action-btn no-animate md-input-has-placeholder" ng-show="ctrl.model.content_directory.image_file" ng-cloak>
									<label>Image</label>
									<input type="text" class="md-input" ng-value="ctrl.model.content_directory.image_file.name" disabled="disabled">
									<md-button class="md-icon-button times compact md-warn md-hue-1" aria-label="remove file" ng-click="ctrl.model.content_directory.image_file = undefined; ctrl.updatePreview('home', true)">
										<md-icon class="fas fa-times"></md-icon>
									</md-button>
								</md-input-container>
							</div>

							<md-input-container class="md-block md-input-has-placeholder" ng-show="ctrl.model.content_directory.image_url || ctrl.model.content_directory.image_file">
								<label>Image Alt Text</label>
								<input type="text" class="black" name="image_url_alt_text" minlength="5" ng-model="ctrl.model.content_directory.image_alt_text" ng-required="ctrl.model.content_directory.image_file || ctrl.model.content_directory.image_url">
								<div class="md-errors-spacer"></div>
								<div ng-messages="form.image_url_alt_text.$error" role="alert">
										<div ng-message="required">Required</div>
										<div ng-message="minlength">Minimum 5 characters</div>
								</div>
							</md-input-container>
		
						 
							<md-input-container class="md-block md-input-has-placeholder" ng-model-options="{debounce:{'default':500, 'blur':0}, '*':'inherit'}">
								<label>Background Color</label>
								<md-select name="cd_color" md-container-class="task-color-select" class="color-select"
									ng-model="ctrl.background_color_label" 
									ng-change="ctrl.onChange(ctrl.background_color_label)">
									<md-option md-option-empty ng-value="'none'">None</md-option>
									<md-option ng-repeat="color in ctrl.mapping.bgColors track by $index" ng-value="color.label">
										<span class="color-hex">{{ color.value }}</span> <span class="color" ng-style="{'background-color': color.value}"></span> {{ color.label }}
									</md-option>
								</md-select>
								<div class="md-errors-spacer"></div>
							</md-input-container>
							

							<div class="layout-row">
								<md-input-container class="md-block flex right-action-btn no-animate md-input-has-placeholder" ng-hide="ctrl.model.video.file">
									<label>Video URL</label>
									<input type="url" class="md-input" name="video_url" ng-model="ctrl.model.video.url" ng-change="ctrl.updatePreview('details', true)">
									<md-button class="md-icon-button md-warn md-hue-1 compact" ng-click="ctrl.model.video.url=undefined" for="video_url" aria-label="Clear value"><i class="fas fa-times"></i></md-icon></md-button>
									<div ng-messages="form.video_url.$error" role="alert">
										<div ng-message="url">Invalid URL format (ie: https://example.com/image.jpeg)</div>
									</div>
								</md-input-container>

								<md-input-container class="md-block flex-nogrow no-animate md-input-has-placeholder" ng-hide="ctrl.isLocked">
									<md-button type="file" name="video_url_file" class="md-primary compact"
										ngf-select accept="video/*" ngf-pattern="'video/*'" ng-disabled="ctrl.isBusy"
										ngf-multiple="false" ngf-keep="true"
										ngf-change="$newFiles.length ? (ctrl.model.video.file = $newFiles.pop()) : null; ctrl.updatePreview('details', true)">
										<i class="fas fa-file-upload"></i> Select File
									</md-button>
								</md-input-container>

								<md-input-container class="md-block flex right-action-btn no-animate md-input-has-placeholder" ng-show="ctrl.model.video.file" ng-cloak>
									<label>Video</label>
									<input type="text" class="md-input" ng-value="ctrl.model.video.file.name" disabled="disabled">
									<md-button class="md-icon-button times compact md-warn md-hue-1" aria-label="remove file" ng-click="ctrl.model.video.file = undefined; ctrl.updatePreview('details', true)">
										<md-icon class="fas fa-times"></md-icon>
									</md-button>
								</md-input-container>
							</div>
							<div class="layout-row">
								<md-input-container class="md-block flex right-action-btn no-animate md-input-has-placeholder" ng-hide="ctrl.model.video_with_narration.file">
									<label>Video with Narration URL</label>
									<input type="url" class="md-input" name="video_url_with_narration" ng-model="ctrl.model.video.url_with_narration" ng-change="ctrl.updatePreview('details', true)">
									<md-button class="md-icon-button md-warn md-hue-1 compact" ng-click="ctrl.model.video.url_with_narration=undefined" for="video_url" aria-label="Clear value"><i class="fas fa-times"></i></md-icon></md-button>
									<div ng-messages="form.video_url_with_narration.$error" role="alert">
										<div ng-message="url">Invalid URL format (ie: https://example.com/image.jpeg)</div>
									</div>
								</md-input-container>

								<md-input-container class="md-block flex-nogrow no-animate md-input-has-placeholder" ng-hide="ctrl.isLocked">
									<md-button type="file" name="video_with_narration_url_file" class="md-primary compact"
										ngf-select accept="video/*" ngf-pattern="'video/*'" ng-disabled="ctrl.isBusy"
										ngf-multiple="false" ngf-keep="true"
										ngf-change="$newFiles.length ? (ctrl.model.video_with_narration.file = $newFiles.pop()) : null; ctrl.updatePreview('details', true)">
										<i class="fas fa-file-upload"></i> Select File
									</md-button>
								</md-input-container>

								<md-input-container class="md-block flex right-action-btn no-animate md-input-has-placeholder" ng-show="ctrl.model.video_with_narration.file" ng-cloak>
									<label>Video</label>
									<input type="text" class="md-input" ng-value="ctrl.model.video_with_narration.file.name" disabled="disabled">
									<md-button class="md-icon-button times compact md-warn md-hue-1" aria-label="remove file" ng-click="ctrl.model.video_with_narration.file = undefined; ctrl.updatePreview('details', true)">
										<md-icon class="fas fa-times"></md-icon>
									</md-button>              
								</md-input-container>
							</div>
							<div class="layout-row">
								<md-input-container class="md-block flex right-action-btn no-animate md-input-has-placeholder" ng-hide="ctrl.model.video.thumbnail_file">
									<label>Video Thumbnail URL</label>
									<input type="url" class="md-input" name="video_thumbnail_url" ng-model="ctrl.model.video.thumbnail_url" ng-change="ctrl.updatePreview('details', true)">
									<md-button class="md-icon-button times md-warn md-hue-1 compact" ng-click="ctrl.model.video.thumbnail_url=undefined" for="video_thumbnail_url" aria-label="Clear value"><i class="fas fa-times"></i></md-icon></md-button>
									<div ng-messages="form.video_thumbnail_url.$error" role="alert">
										<div ng-message="url">Invalid URL format (ie: https://example.com/image.jpeg)</div>
									</div>
								</md-input-container>

								<md-input-container class="md-block md-input-has-placeholder flex-nogrow no-animate" ng-hide="ctrl.isLocked">
									<md-button type="file" name="video_thumbnail_url_file" class="md-primary compact"
										ngf-select accept="image/*" ngf-pattern="'image/*'" ng-disabled="ctrl.isBusy"
										ngf-multiple="false" ngf-keep="true"
										ngf-change="$newFiles.length ? (ctrl.model.video.thumbnail_file = $newFiles.pop()) : null; ctrl.updatePreview('details', true)">
										<i class="fas fa-file-upload"></i> Select File
									</md-button>
								</md-input-container>

								<md-input-container class="md-block flex right-action-btn no-animate md-input-has-placeholder" ng-show="ctrl.model.video.thumbnail_file" ng-cloak>
									<label>Video Thumbnail</label>
									<input type="text" class="md-input" ng-value="ctrl.model.video.thumbnail_file.name" ng-disabled="true">
									<md-button class="md-icon-button times compact md-warn md-hue-1" aria-label="remove file" ng-click="ctrl.model.video.thumbnail_file = undefined; ctrl.updatePreview('details', true)">
										<md-icon class="fas fa-times"></md-icon>
									</md-button>
								</md-input-container>
							</div>
							<div ng-show="ctrl.model.video.thumbnail_file || ctrl.model.video.thumbnail_url" layout-row>
								<md-input-container class="md-block md-input-has-placeholder">
									<label>Video Thumbnail Alt Text</label>
									<input type="text" class="black" name="video_alt_text" minlength="5" ng-model="ctrl.model.video.thumbnail_alt_text" ng-required="ctrl.model.video.thumbnail_file || ctrl.model.video.thumbnail_url">
									<div class="md-errors-spacer"></div>
									<div ng-messages="form.video_alt_text.$error" role="alert">
											<div ng-message="required">Required</div>
											<div ng-message="minlength">Minimum 5 characters</div>
									</div>
								</md-input-container>
							</div>
					</div>
				</div>

				<div class="panel" scroll-spy-target="step" md-whiteframe="1">
					<div class="panel-title padding-4x clearfix">
						<md-input-container class="pull-right margin-0x" ng-if="ctrl.model.steps.length > 0">
							<md-checkbox class="margin-0x" aria-label="Can be earned immediately" ng-model="ctrl.model.atomic">Allow students to earn all steps with one click</md-checkbox>
						</md-input-container>
						<span class="md-title normal-wg">Steps</span>
					</div>
					<div class="panel-content data-content" ng-if="ctrl.model.steps.length > 0">
						<div class="milestone-step" ng-repeat="step in ctrl.model.steps track by $index" ng-class="{open: step._$open}">
							<div toggle-expand="!step._$open">
								<section class="expandable">
									<div class="data-step layout-row" ng-click="ctrl.openStep(step, $index)">
										<div class="data-step-id padding-4x text-right layout-column" flex="15">
											<span class="md-title normal-wg black-50 md-block">
												<small class="md-caption black-33 pull-left">#{{ $index+1 }}&nbsp;&nbsp;&nbsp;</small>
												<span ng-show="step.points != undefined">{{ step.points +'pt'+ (step.points > 1 ? 's' : '') }}</span>
												<span ng-show="step.points == undefined">—</span>
											</span>
											<i class="padding-1x"></i>
											<span class="md-body-1 black-50">{{ step.deadline.date | amDateFormat:SETTINGS.dateFormat }}</span>
											<!-- <span class="md-body-1 black-50">{{ !step.deadline ? 'No Deadline' : ''}}</span> -->
											<div>
												<span class="md-caption black-33 small-tag margin-0x no-animate" ng-hide="step.validation=='qr'">
													{{ ctrl.mapping.validations.byId[step.validation].name || step.validation }}
												</span>
												<a class="md-caption black-33 small-tag margin-0x no-animate" 
													title="Manage QR Codes"
													ng-show="step.validation=='qr'" 
													ui-sref="app.milestones.edit.stepqr({id: ctrl.data._id, stepid: step._id})" 
													ng-click="$event.stopPropagation()">
													{{ ctrl.mapping.validations.byId.qr.name }}
												</a>
											</div>
										</div>
										<div class="padding-4x" flex>
											<div class="pull-right">
												<md-button class="md-icon-button" 
													aria-label="edit step" title="edit step"
													ng-click="ctrl.openStep(step, $index); $event.stopPropagation();">
													<span class="far fa-edit"></span>
												</md-button>
												<md-button class="md-icon-button md-warn md-hue-1" 
													aria-label="remove step" title="remove step"
													ng-click="ctrl.removeStep($index, $event); $event.stopPropagation();"
													ng-show="! ctrl.isLocked">
													<span class="far fa-trash-alt"></span>
												</md-button>
											</div>
											<h4 class="md-subhead black-100 margin-0x md-truncate" ng-bind-html="step._$title"></h4>
											<p class="md-body-1 black-50 margin-0x md-truncate">
												<ng-bind-html ng-bind-html="step.description.raw"></ng-bind-html>
											</p>
										</div>
									</div>
								</section>
							</div>
							<fieldset class="panel-small" ng-form="step-{{ $index }}" toggle-expand="step._$open" ng-init="$stepIndex = $index">
								<legend class="hide">Step Details</legend>
								<section class="expandable" scroll-spy-target="step-{{ $index }}">
									<div class="padding-4x">
										<div class="layout-row">
											<h5 class="md-subhead normal-wg margin-2x flex">{{ $stepIndex > -1 ? 'Edit Step #'+ ($stepIndex+1) : 'Add New Step' }}{{ form['step-'+$stepIndex].$pristine ? '' : '*' }}</h5>
											<md-button class="md-icon-button flex-none" 
												aria-label="save step" title="save step"
												ng-click="ctrl.validateStep(form['step-'+$stepIndex]) && ctrl.saveStep(step)">
												<span class="fas fa-save"></span>
											</md-button>
											<md-button class="md-icon-button md-warn md-hue-1 flex-none" 
												aria-label="remove step" title="remove step"
												ng-click="ctrl.removeStep($index, $event)" 
												ng-show="! ctrl.isLocked">
												<span class="far fa-trash-alt"></span>
											</md-button>
										</div>
										<md-input-container class="md-block md-input-has-placeholder">
											<label>Title</label>
											<input type="text" name="step_title" required
												valid-lang 
												ng-model="step.title"
												ng-change="step._$title = ctrl.toSafeLangHtml(step.title)">
											<div ng-messages="form['step-'+$stepIndex]['step_title'].$error" role="alert">
												<div ng-message="required">Required</div>
												<div ng-message="lang-missing">{{ form['step-'+$stepIndex]['step_title'].$error['lang-missing'] }}</div>
												<div ng-message="lang-mismatch">{{ form['step-'+$stepIndex]['step_title'].$error['lang-mismatch'] }}</div>
												<div ng-message="lang">Invalid language tag formatting</div>
											</div>
										</md-input-container>

										<md-input-container class="padding-0x flex-xs-100 flex-gt-xs-25 md-input-has-placeholder">
											<label>Points</label>
											<input type="number" name="step_points" ng-model="step.points" ng-change="ctrl.calculateTotalPoints()" step="1">
											<div ng-messages="form['step-'+$stepIndex]['step_points'].$error" role="alert">
												<div ng-message="required">Required</div>
											</div>
										</md-input-container>

										<span class="flex"></span>

										<md-input-container class="md-block md-input-has-placeholder">
											<label>Description</label>
											<textarea name="step_description" 
												ng-model="step.description.formatted"
												ng-change="ctrl.onDescriptionChange(step.description)"
												tinymce plain-text="step.description.content==PLAIN_TEXT" 
												switch-content-type="true"
												content-type-model="step.description.content"
												ng-disabled="ctrl.isBusy || ctrl.isLocked"
												md-no-resize rows="3" max-rows="8" required></textarea>
											
		
											<div ng-messages="form['step-'+$stepIndex]['step_description'].$error" role="alert">
												<div ng-message="required">Required</div>
											</div>
										</md-input-container>

										<div class="layout-row layout-wrap">
											<md-input-container class="flex-xs-100 flex-gt-xs-40 padding-0x">
												<label>Deadline Type</label>
												<md-select name="step_deadline_type" ng-model="step.deadline.deadline_type" ng-model-options="{trackBy: '$value'}" 
													ng-change="step.deadline_type=='none' ? (step.deadline.date = null) : (step.deadline.date = _$prevStepDeadline || null)"
													ng-disabled="ctrl.isBusy || ctrl.isLocked" required>
													<md-option ng-repeat="item in ctrl.mapping.deadlines" ng-value="item._id">{{item.name}}</md-option>
												</md-select>
												<div class="md-errors-spacer pull-right"></div>
												<div ng-messages="form['step-'+$stepIndex].step_deadline_type.$error" role="alert">
													<div ng-message="required">Required</div>
												</div>                
											</md-input-container>
											<span class="padding-1x"></span>
											<md-input-container class="padding-0x flex-xs-100 flex-gt-xs-50 md-input-has-placeholder" 
												form-disabled="!step.deadline.deadline_type || step.deadline.deadline_type=='none'"
												ng-model-options="{debounce: 1000}">
												<label>Deadline</label>
												<md-icon class="far fa-calendar-minus"></md-icon>
												<input type="text" name="step_deadline"
													date-picker
													valid-date ng-model="step.deadline.date" 
													ng-required="step.deadline.deadline_type && step.deadline.deadline_type!='none'"
													ng-model-options="{updateOn:'blur'}"
													ng-change="step.deadline.date && (_$prevStepDeadline = step.deadline.date)">
												<div ng-messages="form['step-'+$stepIndex].step_deadline.$error" role="alert">
													<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
												</div>
											</md-input-container>

											<span class="flex"></span>
										</div>
										<br>

										<div scroll-spy-target="step-{{ $index }}-validation">
											<md-input-container class="md-block md-input-has-placeholder">
												<label>Validation</label>
												<md-select name="step_validation" ng-model="step.validation" ng-model-options="{trackBy: '$value'}" ng-change="ctrl.updateStepValidationField(step); ctrl.updatePreview('step-'+ $index +'-validation');" ng- ng-disabled="ctrl.isBusy || ctrl.isLocked" required>
													<md-option ng-repeat="item in ctrl.mapping.validations" ng-value="item._id">{{item.name}}</md-option>
												</md-select>
												<div class="md-errors-spacer pull-right"></div>
												<div ng-messages="form['step-'+$stepIndex]['step_validation'].$error" role="alert">
													<div ng-message="required">Required</div>
												</div>
											</md-input-container>
											<md-input-container class="md-block md-input-has-placeholder" ng-hide="step.validation=='question'">
												<label>Validation Instructions</label>
												<textarea name="step_validation_instructions" 
													valid-lang 
													ng-model="step.validation_instructions" 
													ng-change="step._$validation_instructions = ctrl.toSafeLangHtml(step.validation_instructions)"
													rows="2" max-rows="3"></textarea>
												<div ng-messages="form['step-'+$stepIndex]['step_validation_instructions'].$error" role="alert">
													<div ng-message="lang-missing">{{ form['step-'+$stepIndex]['step_validation_instructions'].$error['lang-missing'] }}</div>
													<div ng-message="lang-mismatch">{{ form['step-'+$stepIndex]['step_validation_instructions'].$error['lang-mismatch'] }}</div>
													<div ng-message="lang">Invalid language tag formatting</div>
												</div>
											</md-input-container> 
										</div>
										
										<div toggle-expand="step.validation=='question'" scroll-spy-target="step-{{ $index }}-validation">
											<section class="expandable" ng-style="::{height: step.validation=='question' ? 'auto' : '0px'}">
												<md-input-container class="md-block md-input-has-placeholder" ng-repeat-start="question in step.questions track by $index">
													<textarea name="step_question" placeholder="Question" 
														valid-lang 
														ng-model="question.question" 
														ng-change="question._$question = ctrl.toSafeLangHtml(question.question)"
														ng-required="step.validation=='question'"
														rows="2" max-rows="4"></textarea>
													<div ng-messages="form['step-'+$stepIndex]['step_question'].$error" role="alert">
														<div ng-message="required">Required</div>
														<div ng-message="nocorrect">Must have atleast one correct answer</div>
														<div ng-message="lang-missing">{{ form['step-'+$stepIndex]['step_question'].$error['lang-missing'] }}</div>
														<div ng-message="lang-mismatch">{{ form['step-'+$stepIndex]['step_question'].$error['lang-mismatch'] }}</div>
														<div ng-message="lang">Invalid language tag formatting</div>
													</div>
												</md-input-container>
												<div class="indent" ng-repeat-end>
													<div ng-repeat="answer in question.answers track by $index" layout layout-align="center center">
														<div>
															<md-checkbox name="step_answer_check_{{$index}}"
																ng-model="answer.correct" 
																aria-label="Is correct answer" title="Is Correct Answer"
																ng-change="ctrl.updateStepQuestionAnswer(question, form['step-'+$stepIndex]['step_question'])">
															</md-checkbox>
														</div>
														
														
														<md-input-container class="md-input-has-placeholder" flex>
															<input type="text" name="step_answer_{{$index}}" 
																placeholder="Answer #{{ $index+1 }}" 
																valid-lang
																ng-model="answer.text" 
																ng-change="answer._$text = ctrl.toSafeLangHtml(answer.text)"
																ng-required="step.validation=='question'">
															<div ng-messages="form['step-'+$stepIndex]['step_answer_'+$index].$error" role="alert">
																<div ng-message="required">Required</div>
																<div ng-message="lang-missing">{{ form['step-'+$stepIndex]['step_answer_'+$index].$error['lang-missing'] }}</div>
																<div ng-message="lang-mismatch">{{ form['step-'+$stepIndex]['step_answer_'+$index].$error['lang-mismatch'] }}</div>
																<div ng-message="lang">Invalid language tag formatting</div>
															</div>
														</md-input-container>
														<md-button class="md-icon-button md-warn md-hue-1 margin-0x trash" 
															aria-label="remove answer" title="remove answer"
															ng-click="question.answers.splice($index, 1)" 
															ng-show="question.answers.length > 2 && !ctrl.isLocked">
															<span  class="far fa-trash-alt"></span>
														</md-button>
													</div>
													<div class="text-right" ng-hide="ctrl.isLocked">
														<md-button type="button" aria-label="add answer" class="md-raised" ng-click="ctrl.addStepQuestionAnswer(step, question)">ADD ANSWER</md-button>
													</div>
												</div>
											</section>
										</div>
									
										
										<div class="compact">
											<div class="layout-row layout-align-start-center no-animate" ng-repeat="link in step.links track by $index">
												<md-input-container class="md-input-has-placeholder" flex>
													<input type="text" name="step_link_{{$index}}" 
														placeholder="Link #{{ $index+1 }}"
														valid-lang 
														ng-model="link.text" 
														ng-change="link._$text = ctrl.toSafeLangHtml(link.text)"
														ng-required="link.url">
													<div ng-messages="form['step-'+$stepIndex]['step_link_'+$index].$error" role="alert">
														<div ng-message="required">Required</div>
														<div ng-message="lang-missing">{{ form['step-'+$stepIndex]['step_link_'+$index].$error['lang-missing'] }}</div>
														<div ng-message="lang-mismatch">{{ form['step-'+$stepIndex]['step_link_'+$index].$error['lang-mismatch'] }}</div>
														<div ng-message="lang">Invalid language tag formatting</div>
													</div>
												</md-input-container>
												<md-input-container class="md-input-has-placeholder" flex>
													<input type="url" name="step_url_{{$index}}" ng-model="link.url" ng-required="link.text" placeholder="URL #{{ $index+1 }}">
													<div ng-messages="form['step-'+$stepIndex]['step_url_'+$index].$error" role="alert">
														<div ng-message="required">Required</div>
														<div ng-message="url">Use a valid url format, ex: <em>https://example.com</em></div>
													</div>
												</md-input-container>
												<md-button class="md-icon-button md-warn md-hue-1 margin-0x trash flex-none" 
													aria-label="Remove link" title="Remove Link"
													ng-click="step.links.splice($index, 1)" 
													ng-show="!ctrl.isLocked">
													<span class="far fa-trash-alt"></span>
												</md-button>
											</div>
										</div>
										<div class="text-center" ng-hide="ctrl.isLocked">
											<md-button type="button" class="md-primary" ng-click="ctrl.addStepLink(step)">Add Link</md-button>
											<br>
										</div>
										<hr>
		
										<fieldset class="compact" scroll-spy-target="step-{{ $index }}-congrats">
											<legend class="hide">Step Completion Content</legend>
											<div class="panel-title padding-4x">
												<span class="md-title normal-wg">Step Completion Content</span><br>
												<small class="md-caption black-33">Message that appears after the student has completed this step</small>
											</div>
											<div class="panel-content margin-4x">
												<md-input-container class="md-block md-input-has-placeholder">
													<label>Completion Headline</label>
													<input type="text" valid-lang class="md-input" name="step_congrats_title" ng-model="step.congrats.message" 
														ng-required="step.congrats.subtitle || step.congrats.image_url || step.congrats.image_file" ng-change="ctrl.updatePreview('step-'+ $index +'-congrats')">
													<div ng-messages="form['step-'+$stepIndex].step_congrats_title.$error" role="alert">
														<div ng-message="required">Required</div>
														<div ng-message="lang-missing">{{ form['step-'+$stepIndex].step_congrats_title.$error['lang-missing'] }}</div>
														<div ng-message="lang-mismatch">{{ form['step-'+$stepIndex].step_congrats_title.$error['lang-mismatch'] }}</div>
														<div ng-message="lang">Invalid language tag formatting</div>
													</div>
												</md-input-container>
												<md-input-container class="md-block md-input-has-placeholder">
													<label>Completion Subtitle</label>
													<textarea name="step_congrats_subtitle" valid-lang ng-model="step.congrats.subtitle" rows="2" ng-change="ctrl.updatePreview('step-'+ $index + '-congrats')"></textarea>
													<div ng-messages="form['step-'+$stepIndex].step_congrats_subtitle.$error" role="alert">
														<div ng-message="lang-missing">{{ form['step-'+$stepIndex].step_congrats_subtitle.$error['lang-missing'] }}</div>
														<div ng-message="lang-mismatch">{{ form['step-'+$stepIndex].step_congrats_subtitle.$error['lang-mismatch'] }}</div>
														<div ng-message="lang">Invalid language tag formatting</div>
													</div>
												</md-input-container>
		
												<div class="layout-row">
													<md-input-container class="md-block flex right-action-btn no-animate md-input-has-placeholder" ng-hide="step.congrats.image_file">
														<label>Completion Image URL</label>
														<input type="url" class="md-input" name="step_congrats_image_url" ng-model="step.congrats.image_url">
														<md-button class="md-icon-button times md-warn md-hue-1 compact" ng-click="step.congrats.image_url=undefined" aria-label="Clear value"><i class="fas fa-times"></i></md-icon></md-button>
													</md-input-container>
													
													<md-input-container class="md-block flex-nogrow" ng-hide="ctrl.isLocked">
														<md-button type="file" name="step_congrats_image" class="compact md-primary"
															ngf-select accept="image/*" ngf-pattern="'image/*'" ng-disabled="ctrl.isBusy"
															ngf-multiple="false" ngf-keep="true"
															ngf-change="$newFiles.length ? (step.congrats.image_file = $newFiles.pop()) : null">
															<i class="fas fa-file-upload"></i> Select File
														</md-button>
													</md-input-container>
		
													<md-input-container class="md-block flex right-action-btn no-animate md-input-has-placeholder" ng-show="step.congrats.image_file">
														<label>Completion Image</label>
														<input type="text" class="md-input" ng-value="step.congrats.image_file.name" ng-disabled="true">
														<md-button class="md-icon-button times compact md-warn md-hue-1" aria-label="remove file" ng-click="step.congrats.image_file = undefined">
															<md-icon class="fas fa-times"></md-icon>
														</md-button>
													</md-input-container>
												</div>
												<div layout-row>
													<md-input-container class="md-block md-input-has-placeholder" ng-show="step.congrats.image_url || step.congrats.image_file.name">
														<label>Completion Image Alt Text</label>
														<input type="text" class="md-input" name="step_congrats_image_alt_text" minlength="5" ng-model="step.congrats.image_alt_text" ng-value="step.congrats.image_alt_text" ng-required="step.congrats.image_file || step.congrats.image_url">
														<div class="md-errors-spacer"></div>
														<div ng-messages="form['step-'+$stepIndex].step_congrats_image_alt_text.$error" role="alert">
																<div ng-message="required">Required</div>
																<div ng-message="minlength">Minimum 5 characters</div>
														</div>
													</md-input-container>
												</div>
											</div>
										</fieldset>
		
										<div class="text-center" ng-hide="ctrl.isBusy || ctrl.isLocked">
											<md-button type="button" class="md-raised margin-0x" ng-click="ctrl.validateStep(form['step-'+$stepIndex]) && ctrl.saveStep(step)">
												<i class="fas fa-save"></i>
												Save Step #{{ $stepIndex +1 }}
											</md-button>
										</div>
									</div>
								</section>
							</fieldset>
						</div>
					</div>
					<div class="panel-content padding-2x text-center" ng-hide="ctrl.isLocked">
						<md-button class="md-raised md-primary" ng-click="ctrl.openStep()" ng-disabled="ctrl.model.steps.length >= 9">
							<span class="fas fa-plus-circle"></span> Add Step
						</md-button>
						<small class="black-50" ng-show="ctrl.model.steps.length >= 9"><br>Reached maximum number of steps</small>
					</div>
				</div>
				
				<div class="panel" scroll-spy-target="congrats" md-whiteframe="1">
					<div class="panel-title padding-4x">
						<span class="md-title normal-wg">Task Completion Content</span><br>
						<small class="md-caption black-33">Message that appears after the student has completed the task</small>
					</div>
					<div class="panel-content margin-4x">
						<md-input-container class="md-block md-input-has-placeholder">
							<label>Completion Headline</label>
							<input type="text" valid-lang class="md-input" name="congrats_title" ng-model="ctrl.model.congrats.message" ng-change="ctrl.onDetailsChanged()"required>
							<div ng-messages="form.congrats_title.$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="lang-missing">{{ form.congrats_title.$error['lang-missing'] }}</div>
								<div ng-message="lang-mismatch">{{ form.congrats_title.$error['lang-mismatch'] }}</div>
								<div ng-message="lang">Invalid language tag formatting</div>
							</div>
						</md-input-container>
						<md-input-container class="md-block md-input-has-placeholder">
							<label>Completion Subtitle</label>
							<textarea name="congrats_subtitle" valid-lang ng-model="ctrl.model.congrats.subtitle" rows="2" ng-change="ctrl.onDetailsChanged()"></textarea>
							<div ng-messages="form.congrats_subtitle.$error" role="alert">
								<div ng-message="lang-missing">{{ form.congrats_subtitle.$error['lang-missing'] }}</div>
								<div ng-message="lang-mismatch">{{ form.congrats_subtitle.$error['lang-mismatch'] }}</div>
								<div ng-message="lang">Invalid language tag formatting</div>
							</div>
						</md-input-container>
						
						<div class="layout-row">
							<md-input-container class="md-block flex right-action-btn no-animate md-input-has-placeholder" ng-hide="ctrl.model.congrats.image_file">
								<label>Completion Image URL</label>
								<input type="url" class="md-input" name="congrats_image_url" ng-model="ctrl.model.congrats.image_url">
								<md-button class="md-icon-button md-warn md-hue-1 compact" ng-click="ctrl.model.congrats.image_url=undefined" for="congrats_image_url" aria-label="Clear value"><i class="fas fa-times"></i></md-icon></md-button>
							</md-input-container>

							<md-input-container class="md-block flex-nogrow" ng-hide="ctrl.isLocked">
								<md-button type="file" name="congrats_image_url_file" class="md-primary compact"
									ngf-select accept="image/*" ngf-pattern="'image/*'" ng-disabled="ctrl.isBusy"
									ngf-multiple="false" ngf-keep="true"
									ngf-change="$newFiles.length ? (ctrl.model.congrats.image_file = $newFiles.pop()) : null">
									<i class="fas fa-file-upload"></i> Select File
								</md-button>
							</md-input-container>

							<md-input-container class="md-block flex right-action-btn no-animate md-input-has-placeholder" ng-show="ctrl.model.congrats.image_file" ng-cloak>
								<label>Completion Image</label>
								<input type="text" class="md-input" ng-value="ctrl.model.congrats.image_file.name" ng-disabled="true">
								<md-button class="md-icon-button compact md-warn md-hue-1" aria-label="remove file" ng-click="ctrl.model.congrats.image_file = undefined; ctrl.updatePreview('details', true)">
									<md-icon class="fas fa-times"></md-icon>
								</md-button>
							</md-input-container>
						</div>
						<div layout-row>
							<md-input-container class="md-block flex md-input-has-placeholder" ng-show="ctrl.model.congrats.image_file.name || ctrl.model.congrats.image_url">
								<label>Completion Image Alt Text</label>
								<input type="text" class="md-input" minlength="5" name="congrats_alt_text" ng-model="ctrl.model.congrats.image_alt_text" ng-required="ctrl.model.congrats.image_url || ctrl.model.congrats.image_file">
								<div class="md-errors-spacer"></div>
								<div ng-messages="form.congrats_alt_text.$error" role="alert">
									<div ng-message="required">Required</div>
									<div ng-message="minlength">Minimum 5 characters</div>
								</div>
							</md-input-container>
						</div>
					</div>
				</div>


				<div class="panel milestone-notif" scroll-spy-target="notifications" md-whiteframe="1">
					<div class="panel-title padding-4x clearfix">
						<span class="md-title normal-wg">Notifications</span>
					</div>
					<div class="panel-content data-content" ng-repeat="notif in ctrl.model.notifications track by $index">
						<fieldset class="panel-small compact" ng-form="notif-{{ $index }}" form-disabled="notif._locked || ctrl.isLocked">
							<legend class="hide">Notifications</legend>
							<div class="padding-4x layout-row layout-wrap">
								<md-input-container class="md-block flex-xs-90 flex-gt-xs-25 md-input-has-placeholder" flex-order="1" 
									ng-model-options="{debounce: 1000}">
									<label>Date</label>
									<input type="text" name="date_to_send"
										date-picker
										valid-date required
										ng-model="notif.date_to_send" ng-model-options="{updateOn:'blur'}">
									<div ng-messages="form['notif-'+$index].date_to_send.$error" role="alert">
										<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
									</div>
								</md-input-container>
								<md-input-container class="md-block flex-xs-100 flex-gt-xs margin-0x md-input-has-placeholder" flex-order="3">
									<label>Message {{ notif._locked ? 'Sent': '' }}</label>
									<textarea name="text" ng-model="notif.text" rows="2" md-no-resize="true" required></textarea>
									<div ng-messages="form['notif-'+$index].text.$error" role="alert">
										<div ng-message="required">Required</div>
									</div>
								</md-input-container>
								<md-input-container class="flex-xs-10 flex-gt-xs-nogrow margin-0x md-input-has-placeholder" flex-order-xs="2" flex-order-gt-xs="4">
									<md-button class="md-icon-button md-warn md-hue-1" 
										aria-label="remove notification" title="remove notification"
										ng-click="ctrl.removeNotif($index, $event)"
										ng-show="! ctrl.isLocked && !notif._locked">
										<span class="far fa-trash-alt"></span>
									</md-button>
								</md-input-container>
							</div>
						</fieldset>
					</div>
					<div class="panel-content padding-2x text-center" ng-hide="ctrl.isLocked">
						<md-button class="md-raised md-primary" ng-click="ctrl.addNotif()">
							<span class="fas fa-plus-circle"></span> Add Notification
						</md-button>
					</div>
				</div>
			</div>

			<div class="panel-wrap flex-nogrow" ng-show="ctrl.model.content_directory.title">
				<div id="task-preview" class="panel" md-whiteframe="1">
					<div class="panel-title padding-4x">
						<span class="md-title normal-wg">Preview</span>
					</div>
					<div class="panel-content mobile-preview no-select layout-column">
						<div class="mobile-header flex-nogrow" ng-class="ctrl.preview.screen">
							<span class="icon" ng-class="{burger: ctrl.preview.screen=='home'}" 
								ng-click="ctrl.preview.screen!='home' && ctrl.updatePreview(ctrl.preview.screen=='step' ? 'details' : ctrl.preview.screen=='validation' ? 'step-'+ctrl.preview.stepIndex : 'home')"
								aria-label="{{ ctrl.preview.screen=='step' ? 'Back button' : '' }}"
								></span>
							<span class="text text-header text-lowercase">
								library
							</span>
							
						</div>
						<div class="mobile-content task-content flex fade-in" ng-show="ctrl.preview.screen == 'home'">
							<div class="home-list">
								<div class="title-container layout-row layout-align-start-stretch" 
									role="button" lang="en" aria-labelledby="task_title task_meta"
									ng-style="{'background-color':ctrl.model.content_directory.background_color || ctrl.DEFAULT_BGCOLOR}" 
									ng-class="{'has-video': ctrl.model.video_url || ctrl.model.video_file}"
									ng-click="ctrl.updatePreview('details')">
									<div class="title-wrap flex-55 layout-column layout-wrap">
										<span class="task-category" ng-show="ctrl.model.content_directory.background_color">
											{{ctrl.getLabel()}}
										</span>
										<h3 class="task-title flex-nogrow" id="task_title"
											ng-class="{'has-step': ctrl.model.steps.length}" 
											aria-label="{{ ctrl.preview.title_clean }}"
											ng-bind-html="ctrl.preview.title"></h3>
										<div class="steps-length" id="task_meta">
											<span class="flex-nogrow" ng-show="(ctrl.model.video.url || ctrl.model.video.file)">
												<span class="fas fa-play fa-2xs"></span>
											</span>
											<span class="flex-nogrow" ng-show="ctrl.model.steps.length > 0">
												{{ ctrl.model.steps.length > 1 ? ctrl.model.steps.length + ' STEPS' : ctrl.model.steps.length + ' STEP' }}
											</span>
											<span class="hide-visual" ng-if="(ctrl.model.video.url || ctrl.model.video.file)">has video</span>
										</div>
									</div>
									<div class="thumbnail-wrap flex-45 layout-column layout-align-end-end">
										<img 
											ng-if="ctrl.model.content_directory.image_file || ctrl.model.content_directory.image_url"
											ngf-src="ctrl.model.content_directory.image_file || ctrl.model.content_directory.image_url" 
											alt="{{ ctrl.model.content_directory.image_alt_text }}" />
									</div>
								</div>
								<div class="container item-actions last-container padding-0x" ng-style="{'background-color':ctrl._$secondaryColor}">
									<div layout="row">
										<div class="padding-1x recipient-college" flex="80" md-truncate>
											{{ ctrl.model.owner_college_id != BEYOND12_ID ? ctrl.mapping.mycolleges.byId[ctrl.model.owner_college_id].name : '' }}
										</div>
										<div class="icons" flex="20">
											<span class="share-icon">
												<img ng-src="{{ ctrl.IMG_SHARE }}" alt="share" aria-hidden="true"/>
											</span>
											<span>
												<img class="save-icon" alt="save" ng-src="{{ ctrl.IMG_SAVE }}" aria-hidden="true"/>
											</span>
										</div>
									</div>
								</div>

								<div class="title-container layout-row layout-align-start-stretch" 
									style="background-color: #3A3F42">
									<div class="title-wrap flex-55 layout-column layout-wrap has-video">
										<h3 class="task-title flex-nogrow" aria-hidden="true">Other Task</h3>
										<i flex></i>
										<span class="flex-nogrow"><i class="fas fa-play" title="video task"></i></span>
									</div>

									<div class="thumbnail-wrap flex-45 layout-column layout-align-end-end">
										<img ngf-src=":: ctrl.IMG_HOM" alt="other task image" />
									</div>
								</div>
								<div class="container item-actions last-container padding-0x">
									<div layout="row">
										<div class="padding-1x recipient-college" flex="66" aria-hidden="true">
											Sample College
										</div>
										<div class="icons" flex="33">
											<span class="share-icon">
												<img ng-src="{{ ctrl.IMG_SHARE }}" alt="share" aria-hidden="true"/>
											</span>
											<span>
												<img class="save-icon" ng-src="{{ ctrl.IMG_SAVE }}" alt="save" aria-hidden="true"/>
											</span>
										</div>
									</div>
								</div>


								<div class="title-container layout-row layout-align-start-stretch" 
									style="background-color: #3A3F42">
									<div class="title-wrap flex-55 layout-column layout-wrap">

										<h3 class="task-title flex-nogrow has-step" aria-hidden="true">Another Task</h3>
										<span class="flex-nogrow steps-length" aria-hidden="true">4 STEPS</span>
										<i flex></i>
									</div>
									<div class="thumbnail-wrap flex-45 layout-column layout-align-end-end">
										<img ngf-src=":: ctrl.IMG_DIR" alt="another task image" />
									</div>
								</div>
								<div class="container item-actions last-container padding-0x">
									<div layout="row">
										<div class="padding-1x recipient-college" flex="66" aria-hidden="true">
											Sample College
										</div>
										<div class="icons" flex="33">
												<img ng-src="{{ ctrl.IMG_SHARE }}" alt="share" aria-hidden="true"/>
											</span>
											<span>
												<img class="save-icon" ng-src="{{ ctrl.IMG_SAVE }}" alt="save" aria-hidden="true"/>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						
					
						<div class="mobile-content task-content flex fade-in" ng-show="!ctrl.preview.screen">
							<div class="micro-task" ng-if="!ctrl.model.video.url && !ctrl.model.video.file">
								<div class="title-container title-container layout-row layout-align-start-stretch" 
									ng-style="{'background-color':ctrl.model.show_in_content_directory.content_directory.background_color.value || ctrl.DEFAULT_BGCOLOR}">
									<div class="title-wrap flex-55"> 
										<h3 class="task-title" ng-bind-html="ctrl.preview.title"></h3>
									</div>
									<div class="thumbnail-wrap flex-45 layout-column layout-align-end-end">
										<img 
											ng-if="ctrl.model.content_directory.image_file || ctrl.model.content_directory.image_url"
											ngf-src="ctrl.model.content_directory.image_file || ctrl.model.content_directory.image_url" 
											alt="{{ ctrl.model.content_directory.image_alt_text }}" />
									</div>
								</div>
							</div>

							<div class="video-task" ng-if="ctrl.model.video.url || ctrl.model.video.file">
								<div class="video-task-title" ng-style="{'background-color':ctrl.model.content_directory.background_color || ctrl.DEFAULT_BGCOLOR}">
									<h3 class="task-title" ng-bind-html="ctrl.preview.title"></h3>
								</div>
								<div class="video-thumb layout layout-align-center-center" ng-style="{'background-image': 'url(\''+ ctrl.model.video.thumbnail_url +'\')'}" ng-if="!ctrl.model.video.thumbnail_file">
									<span class="play"><i class="fas fa-play"></i></span>
								</div>
								<div class="video-thumb layout layout-align-center-center" ngf-background="ctrl.model.video.thumbnail_file" ng-if="ctrl.model.video.thumbnail_file">
									<span class="play"><i class="fas fa-play"></i></span>
								</div>
							</div>
							<div class="container item-actions padding-0x" 
								ng-class="{'last-container': !ctrl.model.description.formatted}" 
								ng-style="{'background-color':ctrl._$secondaryColor}">
								<div layout="row">
									<div class="padding-1x recipient-college" flex="80" md-truncate>
										{{ ctrl.model.owner_college_id != BEYOND12_ID ? ctrl.mapping.mycolleges.byId[ctrl.model.owner_college_id].name : '' }}
									</div>
									<div class="icons" flex="20">
										<span class="share-icon">
											<img ng-src="{{ ctrl.IMG_SHARE }}" alt="share" aria-hidden="true"/>
										</span>
										<span>
											<img class="save-icon" ng-src="{{ ctrl.IMG_SAVE }}" alt="save" aria-hidden="true"/>
										</span>
									</div>
								</div>
							</div>
							<div class="container last-container padding-0x flex" 
								ng-show="ctrl.model.description.formatted" 
								ng-style="{'background-color':ctrl._$secondaryColor}">
								<div class="task-description"
									ng-bind-html="ctrl.model.description.html"
									ng-class="ctrl.model.description.content=='text/html' ? 'html html-description' : 'plain'"></div>
							</div>
			

							<div class="steps no-animate" ng-show="ctrl.model.steps.length > 0">
								<div class="step-item layout-row" ng-repeat="step in ctrl.model.steps track by $index" ng-click="ctrl.updatePreview('step-'+ $index)">
									<div class="number flex-nogrow"><p>{{ $index+1 }}</p></div>
									<div class="step-title" ng-bind-html="step._$title" flex></div>
									<div class="step-deadline" layout="column" ng-hide="step.deadline.deadline_type=='none' || step.deadline.date==undefined">
										<span class="text-center">BEST BY</span>
										<span>{{ step._$previewDeadline | date:'shortDate' }}</span>
									</div>
									<span class="gg-arrow-right flex-nogrow"></span>
								</div>
							</div>
							<div class="steps-footer layout-row" ng-show="ctrl.model.steps">
								<div flex="66">
									<span class="step-tags" ng-repeat="id in ctrl.model.content_directory.tags">{{ ctrl.mapping.flutterTags.byId[id].name.en_US }}{{$last ? '' : ($index==ctrl.mapping.flutterTags.byId[id].name.en_US.length-2) ? ' and ' : ' / '}}</span>
									&nbsp;
								</div>
								<div flex="33">
									<span class="step-points pull-right">{{ ctrl.model.points > 0 ? ctrl.model.points + " POINTS" : "" }} </span>
								</div>
							</div>

							<div class="text-center">
								<button type="button" class="done padding-2x" ng-show="ctrl.model.steps.length == 0 || ctrl.model.atomic" ng-click="ctrl.updatePreview('congrats')">all done!</button>
							</div>
							<br>
						</div>


						<div class="mobile-content task-content flex fade-in" ng-show="ctrl.preview.screen == 'step'">
							<div class="micro-task">
								<div class="title-container layout-row layout-align-start-stretch" 
									ng-style="{'background-color':ctrl.model.content_directory.background_color || ctrl.DEFAULT_BGCOLOR}">
									<div class="title-wrap flex-55 layout-align-start-center">
										<h3 class="task-title" ng-bind-html="ctrl.preview.title"></h3>
									</div>
									<div class="thumbnail-wrap flex-45 layout-column layout-align-end-end">
										<img 
											ng-if="ctrl.model.content_directory.image_file || ctrl.model.content_directory.image_url"
											ngf-src="ctrl.model.content_directory.image_file || ctrl.model.content_directory.image_url" 
											alt="{{ ctrl.model.content_directory.image_alt_text }}" />
									</div>
								</div>
							</div>
							<div class="container item-actions padding-0x" 
								ng-style="{'background-color':ctrl._$secondaryColor}">
								<div layout="row">
									<div class="padding-1x recipient-college" flex="80" md-truncate>
										{{ ctrl.model.owner_college_id != BEYOND12_ID ? ctrl.mapping.mycolleges.byId[ctrl.model.owner_college_id].name : '' }}
									</div>
									<div class="icons" flex="20">
										<span class="share-icon">
											<img ng-src="{{ ctrl.IMG_SHARE }}" alt="share" aria-hidden="true"/>
										</span>
										<span>
											<img class="save-icon" ng-src="{{ ctrl.IMG_SAVE }}" alt="save" aria-hidden="true"/>
										</span>
									</div>
								</div>
							</div>

							<div class="container padding-2x" 
								ng-style="{'background-color':ctrl._$secondaryColor}">
								<div class="step-item layout-row">
									<div class="number flex-nogrow"><p>{{ ctrl.preview.stepIndex + 1 }}</p></div>
									<div class="step-title flex" ng-bind-html="ctrl.preview.step._$title" flex></div>
									<div class="step-deadline flex-nogrow" layout="column" 
										ng-hide="ctrl.preview.step.deadline.deadline_type=='none' || ctrl.preview.step.deadline.date==undefined">
										<span class="text-center" >BEST BY</span>
										<span>{{ ctrl.preview.step._$previewDeadline | date:'shortDate' }}</span>
									</div>
								</div>
							</div>
							<div class="container last-container" 
								ng-style="{'background-color':ctrl._$secondaryColor}" 
								ng-show="ctrl.preview.step.description.formatted">
								<div class="task-description" 
									ng-bind-html="ctrl.preview.step.description.html"
									ng-class="ctrl.preview.step.description.content=='text/html' ? 'html html-description' : 'plain'">
								</div>
							</div>

							<div class="steps-footer layout-row" ng-show="ctrl.model.steps">
								<div flex="66">
									<span class="step-tags" ng-repeat="id in ctrl.model.content_directory.tags">{{ ctrl.mapping.flutterTags.byId[id].name.en_US }}{{$last ? '' : ($index==ctrl.mapping.flutterTags.byId[id].name.en_US.length-2) ? ' and ' : ' / '}}</span>
									&nbsp;
								</div>
								<div flex="33">
									<span class="step-points pull-right">{{ ctrl.model.points > 0 ? ctrl.model.points + " POINTS" : "" }} </span>
								</div>
							</div>

							<div class="text-center">
								<button type="button" class="done padding-2x" 
									ng-click="ctrl.updatePreview('step-'+ ctrl.preview.stepIndex +'-done')">all done!</button>
							</div>
							<br>
						</div>

						<div class="step-validation-overlay fade-in" ng-show="ctrl.preview.showValidation">
							<div class="step-validation">
								<h2 class="steps step-validation-step step-validation-title margin-4x" ng-bind-html="ctrl.model.content_directory._$title"></h2>
								<div class="step layout-row padding-3x">
									<div class="number flex-nogrow"><p>{{ ctrl.preview.stepIndex + 1 }}</p></div>
									<div class="step-title" ng-bind-html="ctrl.preview.step._$title" flex></div>

								</div>
								<div class="step-validation-question" 
									ng-show="ctrl.preview.step.validation == 'question'">
									<p class="question no-animate" id="preview-step-question"
										ng-class="{'alpha-25': !ctrl.preview.step.questions[0].question}" 
										ng-bind-html="ctrl.preview.step.questions[0]._$question"></p>
									<div class="step-answer" layout="column">
										<md-radio-group ng-model="ctrl.preview.stepAnswer" aria-labelledby="preview-step-question">
											<md-radio-button ng-value="answer.text" ng-repeat="answer in ctrl.preview.step.questions[0].answers">
												<span ng-bind-html="answer._$text"></span>
											</md-radio-button>
										</md-radio-group>
									</div>
								</div>
								<div class="date-selection-title" 
									ng-show="ctrl.preview.step.validation == 'date_selection'" 
									ng-bind-html="ctrl.preview.step._$validationInstructions"></div>
								<div class="step-validation-date-selection" 
									ng-show="ctrl.preview.step.validation == 'date_selection'">
									<div class="date-selection-date">
										<span>mm/dd/yyyy</span>
									</div>
								</div>
								<div class="step-validation-button margin-0x flex align-content-center">
									<button type="button" class="done" ng-click="ctrl.updatePreview('step-'+ ctrl.preview.stepIndex +'-congrats')">
										continue
									</button>
								</div>
							</div>
						</div>

						<div class="task-congrats-overlay flex fade-in" ng-show="ctrl.preview.showCongrats">
							<img ng-src="{{ ctrl.preview.step ? ctrl.preview.step.congrats.image_url : ctrl.model.congrats.image_url }}" 
									ng-show="ctrl.preview.step ? ctrl.preview.step.congrats.image_url : ctrl.model.congrats.image_url"
									ng-if="!(ctrl.preview.step ? ctrl.preview.step.congrats.image_file : ctrl.model.congrats.image_file)" 
									alt="congrats"
									aria-label="{{ ctrl.preview.step ? ctrl.preview.step.congrats.image_alt_text : ctrl.model.congrats.image_alt_text }}">
							<img ngf-src="ctrl.preview.step ? ctrl.preview.step.congrats.image_file : ctrl.model.congrats.image_file"
								ng-if="ctrl.preview.step ? ctrl.preview.step.congrats.image_file : ctrl.model.congrats.image_file"
								alt="congrats">
							<div class="task-congrats">
								<div class="headline">
									<h3 ng-class="{'alpha-25': !(ctrl.preview.congratsMsg)}">
										<span ng-bind-html="ctrl.preview.congratsMsg"></span>
										{{ (ctrl.preview.congratsMsg) ? '' : 'Completion Headline' }}
									</h3>
								</div>
								<p class="margin-0x" ng-show="ctrl.model.points">you earned {{ ctrl.preview.step.points || ctrl.model.points }} points</p>
								
								<p class="text" ng-bind-html="ctrl.preview.congratsSubtitle"></p>

								<button type="button" ng-click="ctrl.updatePreview(ctrl.preview.step ? 'details' : 'home')">keep going</button>
							</div>
						</div>
						<div id="mobile-footer" class="mobile-footer flex-nogrow layout-row">
							<div class="flex-20" aria-hidden="true"></div>
							<div class="flex-20 active" aria-hidden="true"></div>
							<div class="flex-20" aria-hidden="true"></div>
							<div class="flex-20" aria-hidden="true"></div>
							<div class="flex-20" aria-hidden="true"></div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</form>
</main>

<div ng-show="ctrl.isBusy" class="busy-overlay fade-in fade-out" layout layout-align="center center">
	<md-progress-circular md-mode="indeterminate"></md-progress-circular>
</div>

<footer class="text-right" md-whiteframe="2" ng-hide="ctrl.isLocked">
	<md-button type="button" class="md-raised md-warn" ng-disabled="ctrl.isBusy" ng-click="ctrl.submitForm('form')">
		<span class="far fa-save"></span> Save
	</md-button>
</footer>