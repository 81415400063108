import angular from 'angular';
import moment from 'moment';
import { DependencyInjected, ApiError, Helper } from '../../common'
import cronstrue from 'cronstrue'
import './sfpanel.scss'

const SYNC_STATUS_RUNNING = 'running';
const SYNC_STATUS_IDLE = 'idle';
const SYNC_MANUAL_RUN_ALREADY = 'already_running';
const SCHED_STATUS_RUNNING = 'running';
const SCHED_STATUS_PAUSED = 'paused';


export default class SFPanelController extends DependencyInjected {
	static get $inject(){return [
		'$scope',
		'$q',
		'$state',
		'$transitions',
		'$timeout',
		'$interval',
		'$mdDialog',
		'api',
		'toast',
	]}

	init(){
		this.mapping = this.mapping || {};

		this.isBusy = true;
		this.isLocked = this.ready = false;
		this.formatRelativeDate = Helper.formatRelativeDate;

		const stateName = this.$state.current.name;
		this._destructors.push(
			// busy state when trying to transition
			this.$transitions.onBefore({from: stateName}, transition=>{
				if ( transition.to().name != stateName )
					this.isBusy = true;
			}),
			// aborted, invalid or error
			this.$transitions.onError({from: stateName}, transition=>{
				if ( [3,4,6].includes(transition.error().type) )
					this.isBusy = !this.ready;
			}),

			()=>{
				if ( this._intvl ) {
					this.$interval.cancel(this._intvl);
					this._intvl = null;
				}
				if ( this._nxtRunId ) {
					this.$timeout.cancel(this._nxtRunId);
					this._nxtRunId = null;
				}
			},
		);


		this.$q.when(this._loadDependencies())
			.then(()=>{
				this.isBusy = false;
				this.ready = true;
				this.$scope?.$broadcast('deps-loaded');
				this.$scope?.$parent?.$emit('deps-loaded');
			})
			.then(()=>{
				this.$scope?.$broadcast('data-ready');
				this.$scope?.$parent?.$emit('data-ready');
			});
	}
	_loadDependencies(){
		return this.$q.when(this.refresh());
	}


	refresh(busy=true){
		this.isBusy = busy;
		if ( this._intvl ) {
			this.$interval.cancel(this._intvl);
			this._intvl = null;
		}

		return this.api.get('salesforceSync/status', {myTokenAdministers: true}, {level: busy ? ApiError.LEVEL.DEPENDENCY : ApiError.LEVEL.IGNORED})
			.then(res=>{
				this.rawData = res.data;
				this.data = this._process(Helper.deepCopy(res.data));

				// do a digest cycle every min to update humanized dates
				this._intvl = this.$interval(angular.noop, 60*1000);
			})
			.finally(()=>this.isBusy = false);
	}
	_process(data){
		data.scheduled = data.schedule_status == SCHED_STATUS_RUNNING;
		data.next_run = Helper.toMomentOrNull(data.next_run);
		data.current_run_start_timestamp = Helper.toMomentOrNull(data.current_run_start_timestamp);
		data.last_run_start_timestamp = Helper.toMomentOrNull(data.last_run_start_timestamp);
		data.last_run_end_timestamp = Helper.toMomentOrNull(data.last_run_end_timestamp);
		
		data.time_of_request = Helper.toMomentOrNull(data.time_of_request); // manual sync

		if ( data.last_run_end_timestamp && data.last_run_start_timestamp ) {
			let dur = moment.duration(data.last_run_end_timestamp.diff(data.last_run_start_timestamp));
			data.last_duration = (dur.hours() > 0 ? `${dur.hours()}h ` : '') + `${dur.minutes()}m ${dur.seconds()}s`;
		}
		if ( data.next_run?.isValid() ) {
			this._nxtRunId = this.$timeout(()=>{
				this.refresh(false);
				this._nxtRunId = null;
				console.log('auto refreshed after next run')
			}, data.next_run.valueOf() - Date.now());
		}


		try {
			data.cron_desc = cronstrue.toString(data.cron_expression, {throwExceptionOnParseError: true});
		} catch(e) {
			console.error('cron expr', e);
		}
		return data;
	}

	pauseSchedule($ev){
		const confirm = this.$mdDialog.confirm()
				.title('Automatic Scheduled Sync')
				.textContent('Are you sure you want to DISABLE automatic scheduled sync?')
				.ariaLabel('Disable Automatic Scheduled Sync')
				.targetEvent($ev)
				.ok('Ok')
				.cancel('Cancel');
		return this.$mdDialog.show(confirm).then(()=>this._pauseSchedule());
	}
	_pauseSchedule(){
		return this.refresh()
			.then(()=>{
				if ( this.data.schedule_status == SCHED_STATUS_PAUSED ) {
					this.toast.warn('Automatic Scheduled Sync is already disabled');
					return;
				}
				this.isBusy = true;
				return this.api.post('salesforceSync/schedule/pause', {})
					.then(()=>{
						this.toast.success('Automatic Scheduled Sync is now disabled');
						return this.refresh();
					})
					.finally(()=>this.isBusy = false);
			});
	}
	resumeSchedule($ev){
		const confirm = this.$mdDialog.confirm()
				.title('Automatic Scheduled Sync')
				.textContent('Are you sure you want to ENABLE automatic scheduled sync?')
				.ariaLabel('Enable Automatic Schedule Sync')
				.targetEvent($ev)
				.ok('Ok')
				.cancel('Cancel');
		return this.$mdDialog.show(confirm).then(()=>this._resumeSchedule());
	}
	_resumeSchedule(){
		return this.refresh()
			.then(()=>{
				if ( this.data.schedule_status == SCHED_STATUS_RUNNING ) {
					this.toast.warn('Automatic Scheduled sync is already enabled');
					return;
				}
				this.isBusy = true;
				return this.api.post('salesforceSync/schedule/resume', {})
					.then(()=>{
						this.toast.success('Automatic Scheduled Sync is now enabled');
						return this.refresh();
					})
					.finally(()=>this.isBusy = false);
			});
	}


	runManualSync($ev){
		const confirm = this.$mdDialog.confirm()
				.title('Run Manual Sync')
				.textContent('Are you sure you want to run a salesforce sync right now?')
				.ariaLabel('Run Manual Sync')
				.targetEvent($ev)
				.ok('Ok')
				.cancel('Cancel');
		return this.$mdDialog.show(confirm).then(()=>this._runManualSync());
	}
	_runManualSync(){
		this.isBusy = true;
		return this.api.post('salesforceSync/run', {})
			.then(res=>{
				if ( res.status == 202 && res.data?.status == SYNC_MANUAL_RUN_ALREADY ) {
					this.toast.success('Sync is already running');
				} else {
					this.toast.success('Sync is now running');
				}
				
				return this.refresh();
			})
			.finally(()=>this.isBusy = false);
	}

}