import {TEMPLATES as COMMON_TEMPLATES} from '../common/templates';

export const TEMPLATE_FILES = Object.freeze({
  EVENTS_ITEM:          {name: 'events.item.tpl', file: require('./events/events.item.tpl.html')},
  EVENT_FORM_DETAIL:    {name: 'event.form.detail.tpl', file: require('./events/event.form.detail.tpl.html')},
  EVENT_FORM_REMINDERS: {name: 'event.form.reminders.tpl', file: require('./events/event.form.reminders.tpl.html')},
  TIP_FORM_DETAIL:      {name: 'tip.form.detail.tpl', file: require('./tips/tip.form.detail.tpl.html')},
  TIP_FORM_REMINDERS:   {name: 'tip.form.reminders.tpl', file: require('./events/event.form.reminders.tpl.html')},
  TASK_ITEM:            {name: 'milestones.item.tpl', file: require('./milestones/milestones.item.tpl.html')},
  RECIPIENT_GROUP:      {name: 'recipientGroup.tpl', file: require('./recipientGroup.tpl.html')},
  MONTHLY_REPORT:       {name: 'report.monthly.tpl', file: require('./analytics/report.monthly.tpl.html')},
});

let templates = {COMMON:{}};
for( const [key, item] of Object.entries(TEMPLATE_FILES) ) {
  templates[key] = item.name;
}
for( const [key, value] of Object.entries(COMMON_TEMPLATES) ) {
  templates.COMMON[key] = value;
}

export const TEMPLATES = Object.freeze(templates);
export default TEMPLATES;
