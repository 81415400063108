import CohortsListController from './cohorts.list.controller'
import CohortSingleController from './cohort.single.controller'
import CohortStudentsController from './cohort.students.controller'
import {Helper, PERMISSIONS, PERMISSION_LEVELS} from '../../common'
import './cohorts.scss'


export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {
	const $stateRegistry = $uiRouterProvider.stateRegistry;

	$stateRegistry.register({
		name: 'app.cohorts',
		title: 'Cohorts',
		url: 'cohorts?sortBy&sortOrder&searchString',
		access: {[PERMISSIONS.READ_COHORTS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOACHED, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT]},
		views: {
			'content@app': {
				template: require('./cohorts.list.html'),
				controller: CohortsListController,
				controllerAs: 'ctrl',
			},
		},
		dynamic: true,
		params: {
			nonce: 0,
			scroll:       {value: 0, inherit:false, dynamic:true},
			sortBy:       {type:'query', inherit:false},
			sortOrder:    {type:'query', inherit:false},
			searchString: {type:'query', inherit:false},
		},
	});

	$stateRegistry.register({
		name: 'app.cohorts.add',
		title: 'Add Cohort',
		url: '/add',
		access: {[PERMISSIONS.CREATE_COHORTS]: true},
		views: {
			'content@app': {
				template: require('./cohort.single.html'),
				controller: CohortSingleController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'data',
				deps: [],
				useFactory: ()=>null,
		}, {
				provide: 'parentStateParams',
				deps: [],
				useFactory: ()=>({}),
		}],
	});

	$stateRegistry.register({
		name: 'app.cohorts.edit',
		title: ['data', data=>'Cohort: '+ data.description||data.name],
		url: '/{id:int}',
		access: {[PERMISSIONS.UPDATE_COHORTS]: true},
		views: {
			'content@app': {
				template: require('./cohort.single.html'),
				controller: CohortSingleController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'parentStateParams',
				deps: ['$state'],
				useFactory: ($state)=>$state.current.name=='app.cohorts' ? Helper.deepCopy($state.params) : {},
			},{
				provide: 'data',
				deps: ['api', 'apiMap', '$state', '$stateParams', '$timeout'],
				useFactory: (api, apiMap, $state, $stateParams, $timeout)=>
					$stateParams.id > -1 && apiMap.getCohortTypes()
						.then((cohortTypes)=>api.get('cohorts/'+ $stateParams.id)
							.then((res)=>{
								let data = res.data;
								let cohortType = cohortTypes.byName[data.cohort_type];
								if ( ! cohortType || cohortType.source !== 'provided_list' ) {
									let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
									return $state.go(state, {error: new Error('Page not found')}, {location:false});
								}
								return data;
							})
							.catch((err)=>{
								if ( api.isApiError(err) ) {
									let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
									return $state.go(state, {error: err}, {location:false});
								}
								throw err;
							})
						),
		}],
	});

	$stateRegistry.register({
		name: 'app.cohorts.edit.students',
		title: ['data', data=>`Manage Student IDs — ${data.description||data.name}`],
		url: '/manage-student-ids?searchString',
		auth: [PERMISSIONS.READ_COHORT_COLLEGE_EXTERNAL_IDS, PERMISSIONS.UPDATE_COHORT_COLLEGE_EXTERNAL_IDS, PERMISSIONS.DELETE_COHORT_COLLEGE_EXTERNAL_IDS],
		access: {
			[PERMISSIONS.READ_COHORT_COLLEGE_EXTERNAL_IDS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOACHED, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT],
			[PERMISSIONS.UPDATE_COHORT_COLLEGE_EXTERNAL_IDS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOACHED, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT],
			[PERMISSIONS.DELETE_COHORT_COLLEGE_EXTERNAL_IDS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOACHED, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT],
		},
		views: {
			'content@app': {
				template: require('./cohort.students.html'),
				controller: CohortStudentsController,
				controllerAs: 'ctrl',
			},
		},
		dynamic: true,
		params: {
			nonce: 0,
			scroll: {value: 0, inherit:false},
			searchString: {type:'query', inherit:false},
			action: {value:undefined, inherit:false},
		},
	});

	$stateRegistry.register({
		name: 'app.cohorts.edit.students.addone',
		access: {[PERMISSIONS.CREATE_COHORT_COLLEGE_EXTERNAL_IDS]: true},
	});
	$stateRegistry.register({
		name: 'app.cohorts.edit.students.addbatch',
		access: {
			[PERMISSIONS.UPDATE_COHORT_COLLEGE_EXTERNAL_IDS]: true,
			[PERMISSIONS.DELETE_COHORT_COLLEGE_EXTERNAL_IDS]: true,
		},
	});
}];
