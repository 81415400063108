<form name="forgotForm" ng-submit="forgotForm.$valid && ctrl.submit()" novalidate autocomplete="off" aria-labelledby="h2">
	<h2 id="h2" class="md-display-1">Forgot Password</h2>
	<div layout="column" layout-align="center stretch" ng-disabled="ctrl.isBusy">
		<md-input-container ng-hide="ctrl.success" class="md-input-has-placeholder">
			<label>Email</label>
			<input type="email" name="email" autocomplete="email" md-autofocus ng-model="ctrl.email" ng-pattern="/^.+@.+\..+$/" required>
			<div ng-messages="forgotForm.email.$error" role="alert">
				<div ng-message="required">Enter your e-mail address</div>
				<div ng-message="pattern">Must be a valid e-mail address</div>
			</div>
		</md-input-container>
		<p class="md-padding" ng-show="ctrl.success">Please check your email for the reset link we've sent</p>
		<div class="layout-row layout-align-space-between-center">
			<a ui-sref="guest.login" class="md-body-1 margin-2x" ng-disabled="ctrl.isBusy">back to login</a>
			<md-button type="submit" class="md-raised md-primary" ng-disabled="ctrl.isBusy" ng-hide="ctrl.success">
				<span ng-hide="ctrl.isBusy">Submit</span>
				<center ng-show="ctrl.isBusy"><md-progress-circular md-mode="indeterminate" md-diameter="20px"></md-progress-circular></center>
			</md-button>
		</div>
	</div>
</form>