import {DependencyInjected, ApiError, MESSAGES, PERMISSIONS} from '../common'

export default class LoginController extends DependencyInjected {
	
	static get $inject(){return [
		'api',
		'toast',
		'errorPrompt',
		'$scope',
		'$location',
		'$state',
		'$timeout',
		'MAPPINGS_JSON',
	]}

	init(){
		this.api.clearCache();
	}

	async submit(){
		this.toast.clear();
		this.isBusy = true;
		this.errorMsg = undefined;

		const payload = {email:this.email, password:this.password};
		const cfg = {level: ApiError.LEVEL.MANUAL};

		try {
			const res = await this.api.post('authentication/webLogin', payload, cfg);

			if ( res.data.permissions?.includes(PERMISSIONS.VERIFY_MFA) ) {
				const factors = res.data?.mfa?.factors || {};
				const params = {
					id: res.data._id,
					factors: Object.keys(factors).map((type)=>({type, providers: Object.keys(factors[type])})),
				};
				this.$state.go('.mfa', params);
			} else {
				this.toast.success('Login successful');
				this.$timeout(()=>this.$location.url(this.$state.params.redirect || '/'), 1000);
			}
		} catch (err) {
			if ( this.api.isApiError(err) ) {
				if ( /^4\d\d$/.test(err.response.status) ) {
					const code = err.response.data?.error_code || Object.values(err.response.data?.errors || {})[0]?.code;
					this.errorMsg = code && (this.MAPPINGS_JSON.authentication?.error?.[code] || MESSAGES.LOGIN.CODE[code]) || MESSAGES.API.LOGIN_INVALID;
				} else
				if ( ! err.isManual() ) {
					this.errorPrompt.show(err);
				} else {
					this.errorMsg = /^(5\d\d|\-1)$/.test(err.response.status) ? MESSAGES.API.SERVER_ERROR : err.message;
				}
			}
			this.isBusy = false;
		}
		this.$scope.$applyAsync();
	}
}
