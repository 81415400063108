import $ from 'jquery'
import moxie from 'mOxie'
import Papa from 'papaparse'
import {Helper, ApiError, SETTINGS, CONSTANTS} from '../../common'
import BaseSingleController from '../base.single'

const FIELDMAP = require('./fieldmap.json')
const CONTENT = CONSTANTS.CONTENT_TYPE;

let getFile = window.FileReader ? (file)=>file.getSource() : (file)=>file;
let FileReader = window.FileReader || moxie.file.FileReader;

const ROW_HEAD = {
	recipient_groups: 'Recipient Group',
	content_owner: 'College Owner',
	id: 'ID',
	title: 'Title',
	description: 'Description',
	description_html: 'Formatted Description',
	description_content_type: 'Description Content Type',
	atomic: 'Atomic',
	release_date: 'Release Date',
	deadline: 'Deadline',
	deadline_type: 'Deadline Type',
	congrats_old: 'Congratulations Message',
	congrats: 'Completion Headline',
	congrats_subtitle: 'Completion Subtitle',
	congrats_image_url: 'Completion Image URL',
	congrats_image_alt_text: 'Completion Image Alt Text',
	task_points: 'Points',
	video_url: 'Video URL',
	video_url_with_narration: 'Video with Narration URL',
	video_thumbnail_url: 'Video Thumbnail URL',
	video_thumbnail_alt_text: 'Video Thumbnail Alt Text',
	image_url: 'Image URL',
	image_alt_text: 'Image Alt Text',
	background_color: 'Background Color',
	tags: 'Tags',
	step_id: 'Step ID',
	step_title: 'Step Title',
	step_description: 'Step Description',
	step_description_html: 'Step Formatted Description',
	step_description_content_type: 'Step Description Content Type',
	step_congrats_old: 'Step Congratulations Message',
	step_congrats: 'Step Completion Headline',
	step_congrats_subtitle: 'Step Completion Subtitle',
	step_congrats_image_url: 'Step Completion Image URL',
	step_congrats_image_alt_text: 'Step Completion Image Alt Text',
	step_points: 'Step Points',
	step_deadline: 'Step Deadline',
	step_deadline_type: 'Step Deadline Type',
	step_validation: 'Step Validation',
	step_validation_instructions: 'Step Validation Instructions',
	step_question: 'Step Question',
	step_answers: 'Step Answers',
	step_correct_answers: 'Step Correct Answers',
	step_links: 'Step Links',
	notif_id: 'Notification ID',
	notif_text: 'Text',
	notif_date_to_send: 'Date to Send',
};
const COL_HEAD = {
	title: 'Title',
	description: 'Description',
	description_html: 'Formatted Description',
	description_content_type: 'Description Content Type',
	content_owner: 'College Owner',
	recipients: 'Recipients',
	release_date: 'Release Date',
	deadline_type: 'Deadline Type',
	deadline: 'Deadline',
	congrats: 'Completion Headline',
	congrats_subtitle: 'Completion Subtitle',
	congrats_image_url: 'Completion Image URL',
	congrats_image_alt_text: 'Completion Image Alt Text',
	task_points: 'Points',
	video_url: 'Video URL',
	video_url_with_narration: 'Video with Narration URL',
	video_thumbnail_url: 'Video Thumbnail URL',
	video_thumbnail_alt_text: 'Video Thumbnail Alt Text',
	image_url: 'Image URL',
	image_small_url: 'Small Image URL',
	image_alt_text: 'Image Alt Text',
	background_color: 'Background Color',
	tags: 'Tags',
	task_points: 'Points',
	atomic: 'Atomic',
	step_id: 'STEPS',
	step_title: 'STEP TITLE',
	step_description: 'Description',
	step_description_html: 'Formatted Description',
	step_description_content_type: 'Description Content Type',
	step_congrats: 'Completion Headline',
	step_congrats_subtitle: 'Completion Subtitle',
	step_congrats_image_url: 'Completion Image URL',
	step_congrats_image_alt_text: 'Completion Image Alt Text',
	step_points: 'Points',
	step_deadline_type: 'Deadline Type',
	step_deadline: 'Deadline',
	step_validation: 'Validation',
	step_question: 'Question',
	step_answers: 'Answers',
	step_answers_value: 'Correct Answers',
	step_links: 'Links',
	notif_id: 'NOTIFICATIONS',
	notif_text: 'Text',
	notif_date_to_send: 'Date to Send',
};

const CSV_TEMPLATES = [
	{
		label: 'Milestone-single.csv',
		url: require('./csv-templates/template_milestone_single.csv'),
		filename: 'template_milestone_single.csv',
	},
	{
		label: 'Milestone-multiple.csv',
		url: require('./csv-templates/template_milestones_multiple.csv'),
		filename: 'template_milestones_multiple.csv',
	},
];

export default class TaskUploadController extends BaseSingleController {
	static get $inject(){return [
		'api',
		'apiMap',
		'toast',
		'errorPrompt',
		'recipient',
		'authorization',
		'currentUser',
		'MAPPINGS_JSON',
		'$q',
		'$mdDialog',
		'$scope',
		'$window',
		'$timeout',
		'BEYOND12_ID',
	].concat(BaseSingleController.$inject).filter(Helper.uniqueFilter)}

	init(){
		this.mapping = {
			validations: Helper.superMap({
					qr: this.MAPPINGS_JSON.tasks.validation.qr,
					date_selection: this.MAPPINGS_JSON.tasks.validation.date_selection,
					question: this.MAPPINGS_JSON.tasks.validation.question,
					none: this.MAPPINGS_JSON.tasks.validation.none,
				}, {type:'validation'}),
			deadlines: Helper.superMap({
				none: this.MAPPINGS_JSON.tasks.deadline.none,
				soft: this.MAPPINGS_JSON.tasks.deadline.soft,
				hard: this.MAPPINGS_JSON.tasks.deadline.hard,
			}, {type:'deadline'}),
		};
		this.templates = Helper.deepCopy(CSV_TEMPLATES);

		$(this.$window)
			.on('resize.tasksBatch', Helper.debounce(()=>{
					let h = Math.max($('#milestone-batch-form main').height() -48, 300);
					$('#dropzone, #csv-preview').height(h);

					let preview = $('#csv-preview');
					preview.css({height:'auto'});

					h = Math.max(Math.min(h, preview.height()), 300);
					preview.height(h);
				}, 200))
			.trigger('resize.tasksBatch');

		this._destructors.push(()=>$(this.$window).off('resize.tasksBatch'));
		this.$scope.$once('data-ready', ()=>$(this.$window).trigger('resize.tasksBatch'));


		super.init();
	}
	_loadDependencies(){
		return this.$q.all([
			super._loadDependencies(),
			this.apiMap.getColleges().then((data)=>this.mapping.mycolleges=data),
			this.apiMap.getAllDistricts().then((data)=>this.mapping.districts=data),
			this.apiMap.getAllCohorts().then((data)=>this.mapping.cohorts=data),
			this.apiMap.getTags().then(data=>this.mapping.flutterTags = data),
			this.recipient.prepare(),
		])
			.then(()=>this._initFileParser());
	}

	_initFileParser(){
		var fileInput = new moxie.file.FileInput({
			browse_button: $('#fileInput').get(0),
			accept: [{title: 'CSV file', extensions: 'csv'}]
		});
		fileInput.onchange = (e)=>this.$scope.$evalAsync(()=>{
			let file = e.target.files[0];
			if ( ! /.csv$/.test(file.name) ) {
				let err = new Error('Invalid file type. Only CSV file types are allowed');
				err.name = 'Filetype Error';
				this.errorPrompt.show(err, null, {noDebug:true, expected:true, noRefresh:true});
				return;
			}
			this.clear();
			this.file = file;
			reader.readAsText(getFile(file));
			this.isBusy = true;
		});

		let $zone = $('#milestone-batch-form .dropzone')
			.on('drag dragstart dragend dragover dragenter dragleave drop', (e)=>{e.stopPropagation();e.preventDefault();})
			.on('dragover dragenter', ()=>$zone.addClass('dragover'))
			.on('dragleave dragend drop', ()=>$zone.removeClass('dragover'))
			.on('drop', (e)=>{
				if ( this.isBusy ) return;
				this.clear();
				let file = this.file = e.originalEvent.dataTransfer && e.originalEvent.dataTransfer.files[0] || undefined;
				if ( file && ! /\.(csv|txt)$/.test(file.name) ) {
					let err = new Error('Can only import CSV or TXT files.')
					err.name = 'Invalid file type';
					this.errorPrompt.show(err, null, {noDebug:true, noRefresh:true});
					this.file = undefined;
				} else if ( file ) {
					reader.readAsText(file);
					this.isBusy = true;
				}
				this.$scope.$evalAsync();
			});

		let reader = new FileReader();
		reader.onload = (e)=>this.$scope.$evalAsync(()=>{
			let result = Papa.parse(e.target.result, {skipEmptyLines:true});
			this.promptExit.enable(this.$state.current.name);
			
			if ( result.errors.length ) {
				console.error(result.errors);
				let err = new CSVParseError('Cannot parse CSV file');
				err.name = 'Parse Error';
				err.debug = result.errors.map((e)=>e.code +'_'+ e.type +': '+ e.message +' (line:'+ (e.row+1) +')').join('\n');
				this.errorPrompt.show(err, {expected:true, noRefresh:true});

			} else try {
				this.rawData = result.data;
				console.info('raw', this.rawData);
				if ( this._isMultipleCSV(result.data) ) {
					this.data = this._buildData(this.rawData);
				} else {
					this.data = this._buildOneMilestone(this.rawData);
				}
				console.info('parsed', Helper.deepCopy(this.data));

				if ( this.invalidCount > 0 || this.data.length == 0 ) {
					throw new CSVParseError(this.invalidCount > 0 ? 'Found '+ this.invalidCount +' error(s) from the CSV' : 'No milestones parsed from the CSV');
				}
				this.$timeout(()=>{
					$(this.$window).trigger('resize.tasksBatch');
					this.errors._$open = true;
				}, 100);
			} catch(err){
				if ( err instanceof CSVParseError ) {
					this.errorPrompt.show(err, null, {noDebug:true, expected:true, noRefresh:true});
				} else 
				if ( err instanceof Error ) {
					let e = new CSVParseError('Unable to parse csv file');
					e.debug = err.stack;
					this.errorPrompt.show(e, null, {expected:true, noRefresh:true});
				} else {
					throw err;
				}
			}
			this.isBusy = false;
		});


		this.$scope.$once('data-ready', ()=>fileInput.init());
	}


	submit(){
		if ( this.invalidCount > 0 || this.buildStats.milestones === 0 ) return;
		
		const userid = +this.authorization.userId;
		let payload = {
			ancestry: SETTINGS.apiAncestry,
			created_by_user_id: userid,
			record_count: this.data.length,
			processed_date: Helper.toNoonISO(new Date()),  
			status: 'processed',
			file_name: this.file.name,
			tasks: this.data.map((item)=>{
				item.created_by_user_id = userid;
				item.created_date = Helper.toTimestamp(new Date());
				return item;
			})
		};

		this.api.post('tasks/bulk', payload)
			.then((res)=>{
				this.toast.success('Task Batch Submitted');
				this.clear();
			})
			.catch((err)=>{
				if ( this.api.isApiError(err) ) {
					if ( +err.response.status == 400 ) {
						let errs = Object.values(err.response.data && err.response.data.errors || {});
						errs.forEach(err=>{
							let key = FIELDMAP[err.field_code] && FIELDMAP[err.field_code].csv || err.field_code;
							this.__error(err.code, key, err._csv_index, payload.tasks[err._id]);
						});
						Helper.smoothScrollTo($('#error-table'), null, ()=>this.errors._$expanded = true);
						this.toast.warn('Errors were found on the batch');
						return;
					}
				}
				throw err;
			})
			.finally(()=>{
				this.isBusy = false;
			});
		this.isBusy = true;
	}


	clear(){
		this.file = null;
		this.invalidCount = 0;
		this.errorMessage = null;
		this.data = this.rawData = null;
		this.errors = [];
		this.warnings = [];
		this.buildStats = {milestones: 0, steps: 0, notifs: 0};
		this.promptExit.disable();
	}


	_isMultipleCSV(list){
		let headers = list[0];
		return headers.includes(ROW_HEAD.recipient_groups) && headers.includes(ROW_HEAD.id) && headers.includes(ROW_HEAD.title);
	}

	_buildData(list){
		let data = [];
		let milestone, stepKeys, notifKeys;
		let keys = Object.values(list[0]);
		let keyMap = Object.create(null);
		keys.forEach((k, j)=>keyMap[k] = j);

		for( let i=1; i<list.length; i++ ) {
			let row = list[i];
			// empty
			if ( row.join('').trim().length === 0 ) {
				// will make it expect a new milestone after each empty row
				// milestone = stepKeys = notifKeys = null;
				continue;
			}

			// might be step or notif
			if ( (row[0] || '').trim().length == 0 && i != 1 ) {// row 1 should always be a milestone
				// is a step header
				if ( row.includes(ROW_HEAD.step_id) && row.includes(ROW_HEAD.step_title) ) {
					if ( stepKeys ) // already have step header, but found one again
						this._markWarningCell('Unexpected step header', i);
						// throw new CSVParseError(`Invalid structure, unexpected step header at column ${i+1}`);

					stepKeys = Object.create(null);
					Object.values(row).forEach((k, j)=>stepKeys[k] = j);
					stepKeys.$index = i;
					notifKeys = null;
					continue;
				} else
				// is a notif header
				if ( row.includes(ROW_HEAD.notif_id) && row.includes(ROW_HEAD.notif_date_to_send) ) {
					if ( notifKeys ) // already have notif header, but found one again
						this._markWarningCell('Unexpected notification header', i);
						// throw new CSVParseError(`Invalid structure, unexpected notification header at column ${i+1}`);

					notifKeys = Object.create(null);
					Object.values(row).forEach((k, j)=>notifKeys[k] = j);
					notifKeys.$index = i;
					stepKeys = null;
					continue;
				} else
				// step contents
				if ( stepKeys ) {
					let obj = Object.create(null);
					Object.keys(stepKeys).forEach(k=>k.trim() && (obj[k] = row[stepKeys[k]]));
	
					let id = +obj[ROW_HEAD.step_id];
					if ( id && milestone.steps[id] ) {
						this._markWarningCell('Step ID is not unique, new ID will be generated', i, stepKeys[ROW_HEAD.step_id]);
					}
					while( !id || milestone.steps[id] ) {
						id = ++milestone.last_step_id;
					};
					milestone.steps[id] = this._buildStep(obj, i, stepKeys);
					if ( Object.values(milestone.steps).length > 9 ) {
						this._markInvalidCell('Exceeds maximum number of steps', i, 0);
					}
	
					milestone.last_step_id = +id;
					this.buildStats.steps++;
					continue;
				} else
				// notif contents
				if ( notifKeys ) {
					let obj = Object.create(null);
					Object.keys(notifKeys).forEach(k=>k.trim() && (obj[k] = row[notifKeys[k]]));
	
					let id = +obj[ROW_HEAD.notif_id];
					if ( !isNaN(id) && milestone.notifications[id] ) {
						this._markWarningCell('Notification ID is not unique, new ID will be generated', i, notifKeys[ROW_HEAD.notif_id]);
					}
					while( isNaN(id) || milestone.notifications[id] ) {
						id = ++milestone.last_notif_id;
					};
					milestone.notifications[id] = this._buildNotif(obj, i, notifKeys);
					if ( Object.values(milestone.notifications).length > 9 ) {
						this._markInvalidCell('Exceeds maximum number of notifications', i, 0);
					}
	
					milestone.last_notif_id = +id;
					this.buildStats.notifs++;
					continue;
				}
			}
			// is a milestone otherwise, or try to build a milestone
			let obj = Object.create(null);
			keys.forEach((k, j)=>obj[k] = row[j]);
			milestone = this._buildMilestone(obj, i, keyMap);
			data.push(milestone);
			this.buildStats.milestones++;
			stepKeys = notifKeys = null;
		}
		data.forEach(milestone=>{
			// BTC-14 force milestone w/o step to be atomic
			milestone.atomic = milestone.atomic || !Object.values(milestone.steps).length;
			// BTC-48 only set points (total) if task_points or step points are set
			if ( milestone.task_points != null || Object.values(milestone.steps).find(step=>step.points != null) )
				milestone.points = Object.values(milestone.steps).reduce((total, step)=>total + (step.points || 0), milestone.task_points || 0);
			// BTC-39
			if ( ! milestone.content_directory.deadline.date && ! Object.values(milestone.steps).find(step=>step.deadline) )
				this._markInvalidCell( 'Deadline must be set in either milestone or a step.', milestone._$index, keyMap[ROW_HEAD.deadline] );

			delete milestone._$index;
		});

		return data;
	}
	_buildMilestone(data, index, keyMap){
		let obj = Object.create(null);
		obj._$index = index;
		
		this.__validateRequired('Recipient group', data[ROW_HEAD.recipient_groups], index, keyMap[ROW_HEAD.recipient_groups]);

		let exprs = data[ROW_HEAD.recipient_groups].replace(/^\"(.+)\"$/, '$1').split(/\"\s+OR\s+\"/ig);
		obj.recipients = Object.create(null);
		exprs.forEach((expr, i)=>{
			if ( expr.toLowerCase() === 'all' ) {
				obj.recipients['recipient_group_'+(i+1)] = '';
			} else try {
				obj.recipients['recipient_group_'+(i+1)] = expr.replace(/\s+/, ' ');
				// let res = this.recipient.parse(expr);
				// if ( res instanceof Error || this.recipient.isRecipientError(res) )
				// 	throw res;
			} catch(err) {
				if ( this.recipient.isRecipientError(err) )  {
					var msg = 'Expr#'+ (i+1) +': '+ err.message;
					if ( err.word !== undefined ) {
						msg += ', got "'+ err.word +'"';	
					}
					if ( ! isNaN(err.offset) ) {
						msg += ' at char '+ err.offset;
						if (err.word !== undefined )
							exprs[i] = exprs[i].substr(0, err.offset) +'<span class="highlight">'+ err.word +'</span>'+ exprs[i].substr(err.offset + err.word.length);
					}

					this._markInvalidCell(msg, index, keyMap[ROW_HEAD.recipient_groups]);
				}
				else throw err;
			}
		});
		if ( exprs.length > 1 )
			data[ROW_HEAD.recipient_groups] = '<span class="nowrap">'+ exprs.join('<br> OR <br>') +'</span>';

		this.__validateRecipients(obj.recipients, index, keyMap[ROW_HEAD.recipient_groups]);
		
		obj.ancestry = SETTINGS.apiAncestry;
		obj.owner_college_id = this.__validateOwner(data[ROW_HEAD.content_owner], index, keyMap[ROW_HEAD.content_owner]);

		obj.content_directory = {
			title: this.__validateRequired(ROW_HEAD.title, data[ROW_HEAD.title], index, keyMap[ROW_HEAD.title]),
			tags: this.__validateTags(data[ROW_HEAD.tags], index, keyMap[ROW_HEAD.tags]),
			deadline: {
				date: this.__validateDeadline(data[ROW_HEAD.deadline], obj.deadline, index, keyMap[ROW_HEAD.deadline]),
				deadline_type: this.__validateDeadlineType(data[ROW_HEAD.deadline_type], data[ROW_HEAD.deadline], index, keyMap[ROW_HEAD.deadline_type])
			},
			image_url: this.__validateURL(data[ROW_HEAD.image_url], index, keyMap[ROW_HEAD.image_url]),
			image_small_url: this.__validateURL(data[ROW_HEAD.image_url], index, keyMap[ROW_HEAD.image_url]),
			release_date: this.__validateRequired(ROW_HEAD.release_date, data[ROW_HEAD.release_date], index, keyMap[ROW_HEAD.release_date]) 
			&& this.__validateDate(data[ROW_HEAD.release_date], index, keyMap[ROW_HEAD.release_date], SETTINGS.dateISO),
			background_color: this.__validateHexColor(data[ROW_HEAD.background_color], index, keyMap[ROW_HEAD.background_color])
		}

		if ( obj.content_directory.image_url ) {
			obj.content_directory.image_alt_text = this.__validateLength(data[ROW_HEAD.image_alt_text], index, keyMap[ROW_HEAD.image_alt_text]);
		}

		obj.description = this.__buildDescription(data, index, keyMap, ROW_HEAD.description_content_type, ROW_HEAD.description_html, ROW_HEAD.description);

		obj.congrats = {
			message: this.__validateRequired(ROW_HEAD.congrats, data[ROW_HEAD.congrats] || data[ROW_HEAD.congrats_old], index, keyMap[ROW_HEAD.congrats]),
			subtitle: this.__cleanString(data[ROW_HEAD.congrats_subtitle]),
			image_url: this.__validateURL(data[ROW_HEAD.congrats_image_url], index, keyMap[ROW_HEAD.congrats_image_url]),
		}

		if ( obj.congrats.image_url ) {
			obj.congrats.image_alt_text = this.__validateLength(data[ROW_HEAD.congrats_image_alt_text], index, keyMap[ROW_HEAD.congrats_image_alt_text]);
		}

		obj.video = {
			url: this.__validateURL(data[ROW_HEAD.video_url], index, keyMap[ROW_HEAD.video_url]),
			url_with_narration: this.__validateURL(data[ROW_HEAD.video_url_with_narration], index, keyMap[ROW_HEAD.video_url_with_narration]),
			thumbnail_url: this.__validateURL(data[ROW_HEAD.video_thumbnail_url], index, keyMap[ROW_HEAD.video_thumbnail_url]),
		}

		if ( obj.video.thumbnail_url ) {
			obj.video.thumbnail_alt_text = this.__validateLength(data[ROW_HEAD.video_thumbnail_alt_text], index, keyMap[ROW_HEAD.video_thumbnail_alt_text]);
		}


		// if( !obj.video.url ) {
		// 	delete obj.video;
		// }

		obj.last_deadline = this.__validateDeadline(data[ROW_HEAD.deadline], obj.deadline, index, keyMap[ROW_HEAD.deadline]);
		obj.first_deadline = this.__validateDeadline(data[ROW_HEAD.deadline], obj.deadline, index, keyMap[ROW_HEAD.deadline]);
		

		obj.task_points = this.__validatePoints(data[ROW_HEAD.task_points], index, keyMap[ROW_HEAD.task_points]);

		obj.atomic = this.__validateAtomic(data[ROW_HEAD.atomic], index, keyMap[ROW_HEAD.atomic]) || false;	
		
		obj.steps = {};
		obj.notifications = {};
		obj.last_step_id = 0;
		obj.last_notif_id = 0;
		
		return obj;
	}
	_buildStep(data, index, keyMap){
		let obj = Object.create(null);

		obj.title = this.__validateRequired(ROW_HEAD.step_title, data[ROW_HEAD.step_title], index, keyMap[ROW_HEAD.step_title]);

		obj.description = obj.description = this.__buildDescription(data, index, keyMap, ROW_HEAD.step_description_content_type, ROW_HEAD.step_description_html, ROW_HEAD.step_description);

		obj.congrats = {
			message: this.__validateRequired(ROW_HEAD.step_congrats, data[ROW_HEAD.step_congrats] || data[ROW_HEAD.step_congrats_old], index, keyMap[ROW_HEAD.step_congrats]),
			subtitle: this.__cleanString(data[ROW_HEAD.step_congrats_subtitle]),
			image_url: this.__validateURL(data[ROW_HEAD.step_congrats_image_url], index, keyMap[ROW_HEAD.step_congrats_image_url]),
		}

		if ( obj.congrats.image_url ) {
			obj.congrats.image_alt_text = this.__validateLength(data[ROW_HEAD.step_congrats_image_alt_text], index, keyMap[ROW_HEAD.step_congrats_image_alt_text]);
		}

		obj.deadline = {
			deadline_type: this.__validateDeadlineType(data[ROW_HEAD.step_deadline_type], data[ROW_HEAD.step_deadline] || null,  index, keyMap[ROW_HEAD.step_deadline_type]) || null,
			date: this.__validateDeadline(data[ROW_HEAD.step_deadline], obj.step_deadline_type, index, keyMap[ROW_HEAD.step_deadline]) || null
		}

		//* BTC-117
		obj.points = this.__validatePoints(data[ROW_HEAD.step_points], index, keyMap[ROW_HEAD.step_points]);

		obj.validation = this.__validateValidation(data[ROW_HEAD.step_validation], index, keyMap[ROW_HEAD.step_validation]);

		if ( obj.validation !== 'question' ) {
			obj.validation_instructions = this.__cleanString(data[ROW_HEAD.step_validation_instructions]);

		} else {
			obj.questions = this.__buildQuestions(data, index, keyMap);
		}

		obj.links = this.__buildLinks(data, index, keyMap);

		return obj;
	}
	_buildNotif(data, index, keyMap){
		let obj = Object.create(null);
		obj.text = this.__validateRequired(ROW_HEAD.notif_text, data[ROW_HEAD.notif_text], index, keyMap[ROW_HEAD.notif_text]);
		obj.date_to_send = this.__validateDate(data[ROW_HEAD.notif_date_to_send], index, keyMap[ROW_HEAD.notif_date_to_send]) || null;
		return obj;
	}

	__buildDescription(data, index, keyMap, kContent, kFormatted, kRaw){
		let desc = {content: this.__validateContentType(data[kContent], index, keyMap[kContent])};
		if ( desc.content == CONTENT.HTML ) {
			desc.formatted = this.__validateRequired(kFormatted, data[kFormatted], index, keyMap[kFormatted]);
			if ( data[kRaw] ) {
				this._markWarningCell('Description will be replaced with html-stripped Formatted Description', index, keyMap[kFormatted]);
			}
			desc.raw = Helper.htmlToText(desc.formatted);
		} else {
			desc.raw = this.__validateRequired(kRaw, data[kRaw], index, keyMap[kRaw]);
		}
		return desc;
	}
	__buildQuestions(data, index, keyMap){
		let question = {
			question: (data[ROW_HEAD.step_question] || '').trim(),
			answers: [],
		};
		if ( question.question.length === 0 ) {
			this._markInvalidCell( 'Step question cannot be empty if validation is a question', index, keyMap[ROW_HEAD.step_question] );
		}

		let answers = (data[ROW_HEAD.step_answers] || '').trim().split(/[\n\r]+/);
		let corrects = (data[ROW_HEAD.step_correct_answers] || '').trim().toLowerCase().split(/[\n\r]+/);
		answers.forEach((val)=>{
			if ( val.trim().length === 0 )
				this._markInvalidCell( 'Step answers cannot have an empty value', index, keyMap[ROW_HEAD.step_answers] );
		});
		corrects.forEach((val, i)=>{
			if ( ! /^(true|false)$/i.test(val) ) {
				this._markInvalidCell( 'Step correct answers must be "true" or "false" only & cannot be empty', index, keyMap[ROW_HEAD.step_correct_answers] );
				// corrects[i] = '<span class="highlight">'+ val +'</span>';
			}
		});
		// data[ROW_HEAD.step_answers] = '<span class="nowrap">'+ answers.join('<br>') +'</span>';
		// data[ROW_HEAD.step_correct_answers] = '<span class="nowrap">'+ corrects.join('<br>') +'</span>';

		if ( answers.length !== corrects.length ) {
			this._markInvalidCell( 'Step correct answers ('+ corrects.length +') does not match number of answers ('+ answers.length +')', index, keyMap[answers.length < corrects.length ? ROW_HEAD.step_answers : ROW_HEAD.step_correct_answers] );
		} else 
		if ( corrects.indexOf('true') == -1 ) {
			this._markInvalidCell( 'No correct answer is defined; one must be true', index, keyMap[ROW_HEAD.step_correct_answers] );
		}

		answers.forEach((answer, i)=>{
			var ans = {text: answer};
			if ( corrects[i] === 'true' )
				ans.correct = true;
			question.answers.push(ans);
		});
		return {'1': question};
	}
	__buildLinks(data, index, keyMap){
		var links = (data[ROW_HEAD.step_links] || '').split(/[\n\r]+/);
		if ( (data[ROW_HEAD.step_links]||'').trim() !== '' && links.length > 0 ) {
			const results = [];

			for( var i=0; i<links.length; i+=2 ) {
				var text = links[i], url = links[i+1] || '';
				if ( text.trim().length === 0 )
					this._markInvalidCell( 'Missing a link text at line '+(i+1), index, keyMap[ROW_HEAD.step_links] );
				if ( url.trim().length === 0 )
					this._markInvalidCell( 'Missing a link url text at line '+(i+2), index, keyMap[ROW_HEAD.step_links] );
				else if ( !/^https?\:\/\/.+/.test(url) ) {
					this._markInvalidCell( 'Link url must be a valid url at line '+(i+2), index, keyMap[ROW_HEAD.step_links] );
					// links[i+1] = '<span class="highlight">'+ url +'</span>'
				}
				results.push({
					text: text, 
					url: url.replace(/\s/g, '%20'),
				});
			}
			// data[ROW_HEAD.step_links] = '<span class="nowrap">'+ links.join('<br>') +'</span>';

			if ( links.length % 2 !== 0 )
				this._markInvalidCell( 'Step links expect pairs of link text & url separated by line-breaker, got '+ links.length, index, keyMap[ROW_HEAD.step_links] );
			
			return results;
		}
		return undefined;
	}


	_buildOneMilestone(list){
		let data = Object.create(null),
			rowByKey = Object.create(null),
			obj = Object.create(null);

		let stepPos, notifPos;
		list.forEach((row, index)=>{
			let pos = Object.values(COL_HEAD).findIndex((val, index)=>
				(row[0].toLowerCase() == val.toLowerCase()) &&
				(!stepPos || index > stepPos) && 
				(!notifPos || index > notifPos)
			);
			if ( pos == -1 ) return;

			let key = Object.keys(COL_HEAD)[pos];
			if ( /^(step|notif)_.*$/.test(key) ) {
				data[key] = row.slice(1);

				if ( key == 'step_id' ) stepPos = pos;
				if ( key == 'notif_id' ) notifPos = pos;
			} else {
				data[key] = row[1];
			}
			rowByKey[key] = index;
		});
		console.log('data', data);

		obj.recipients = {recipient_group_1: this.__validateExpr(data.recipients, rowByKey.recipients, 1)};
		obj.ancestry = SETTINGS.apiAncestry;


		obj.recipients = {recipient_group_1: this.__validateExpr(data.recipients, rowByKey.recipients, 1)};
		obj.ancestry = SETTINGS.apiAncestry;
		obj.owner_college_id = this.__validateOwner(data.content_owner, rowByKey.content_owner, 1);


		obj.content_directory = {
			title: this.__validateRequired(COL_HEAD.title, data.title, rowByKey.title, 1),
			tags: this.__validateTags(data.tags, rowByKey.tags, 1),
			deadline: {
				date: this.__validateDeadline(data.deadline, obj.deadline_type, rowByKey.deadline, 1),
				deadline_type: this.__validateDeadlineType(data.deadline_type, data.deadline, rowByKey.deadline_type, 1)
			},
			image_url: this.__validateURL(data.image_url, rowByKey.image_url, 1),
			image_small_url: this.__validateURL(data.image_url, rowByKey.image_url, 1),
			release_date: this.__validateRequired(COL_HEAD.release_date, data.release_date, rowByKey.release_date, 1)
				&& this.__validateDate(data.release_date, rowByKey.release_date, 1, SETTINGS.dateISO),
			background_color: this.__validateHexColor(data.background_color, rowByKey.background_color, 1)			
		}

		if ( obj.content_directory.image_url ) {
			obj.content_directory.image_alt_text = this.__validateLength(data.image_alt_text, rowByKey.image_alt_text, 1);
		}

		let desc = obj.description = {content: this.__validateContentType(data.description_content_type, rowByKey.description_content_type, 1)};
		if ( desc.content == CONTENT.HTML ) {
			desc.formatted = this.__validateRequired(COL_HEAD.description_html, data.description_html, rowByKey.description_html, 1);
			if ( data.description ) {
				this._markWarningCell('Description will be replaced with html-stripped Formatted Description', rowByKey.description_html, 1);
			}
			desc.raw = Helper.htmlToText(desc.formatted);
		} else {
			desc.raw = this.__validateRequired(COL_HEAD.description, data.description, rowByKey.description, 1);
		}

		obj.congrats = {
			message: this.__validateRequired(COL_HEAD.congrats, data.congrats, rowByKey.congrats, 1),
			subtitle: this.__cleanString(data.congrats_subtitle),
			text: this.__cleanString(data.congrats_text),
			image_url: this.__validateURL(data.congrats_image_url, rowByKey.congrats_image_url, 1),
		}

		if ( obj.congrats.image_url ) {
			obj.congrats.image_alt_text = this.__validateLength(data.congrats_image_alt_text, rowByKey.congrats_image_alt_text, 1);
		}

		obj.video = {
			url: this.__validateURL(data.video_url, rowByKey.video_url, 1),
			url_with_narration: this.__validateURL(data.video_url_with_narration, rowByKey.video_url_with_narration, 1),
			thumbnail_url: this.__validateURL(data.video_thumbnail_url, rowByKey.video_thumbnail_url, 1),
		}

		if ( obj.video.thumbnail_url ) {
			obj.video.thumbnail_alt_text = this.__validateLength(data.video_thumbnail_alt_text, rowByKey.video_thumbnail_alt_text, 1);
		}
		
		 // BTC-148
		
		obj.atomic = this.__validateAtomic(data.atomic, rowByKey.atomic, 1);

		obj.first_deadline = this.__validateDeadline(data.deadline, obj.deadline_type, rowByKey.deadline, 1) || null;
		obj.last_deadline = this.__validateDeadline(data.deadline, obj.deadline_type, rowByKey.deadline, 1) || null;

		obj.task_points = this.__validatePoints(data.task_points, rowByKey.task_points, 1) || undefined;

		obj.steps = {};
		obj.last_step_id = 0;


		if ( ! data.step_id || ! data.step_id.length ) {
			obj.atomic = true;

			if ( ! obj.deadline )
				this._markInvalidCell('Deadline must be set in either milestone or a step.', rowByKey.deadline, 1);
		} else {
			let hasDeadline;

			if ( data.step_id.length > 9 ) {
				let i = data.step_id.length - 9;
				while( i-- )
					this._markInvalidCell('Exceeds maximum number of steps', rowByKey.step_id, 10+i);
			}

			data.step_id.forEach((id, index)=>{
				if ( id.length > 0 ) {
					if ( ! /^\d+$/.test(id) )
						this._markInvalidCell('Step ID must be a whole number', rowByKey.step_id, index+1);
					else if ( obj.steps[id] )
						this._markWarningCell('Step ID is not unique, new ID will be generated', rowByKey.step_id, index+1);
					while( obj.steps[id] ) {
						id = ++obj.last_step_id;
					}
					let step = obj.steps[id] = this._buildOneStep(data, index, rowByKey);
					obj.last_step_id = +id;

					hasDeadline = hasDeadline || !!step.deadline;
					this.buildStats.steps++;
				} else
				if ( data.step_title[index] ) {
					this._markWarningCell('Step column ignored due to empty STEP ID', rowByKey.step_id, index+1);
				}
			});
			if ( ! hasDeadline && ! obj.deadline )
				this._markInvalidCell('Deadline must be set in either milestone or a step.', rowByKey.deadline, 1);
		}

		if ( data.notif_id && data.notif_id.length > 0 ) {
			obj.notifications = Object.create(null);
			data.notif_id.forEach((id, index)=>{
				if ( id.length > 0 ) {
					if ( ! /^\d+$/.test(id) )
						this._markInvalidCell('Notification ID must be a whole number', rowByKey.notif_id, index+1);
					else if ( obj.notifications[id] )
						this._markWarningCell('Notification ID is not unique, new ID will be generated', rowByKey.notif_id, index+1);
					while( obj.notifications[id] ) {
						id = ++obj.last_notif_id;
					}
					obj.notifications[id] = this._buildOneNotif(data, index, rowByKey);
					obj.last_notif_id = +id;
					this.buildStats.notifs++;
				} else
				if ( data.notif_text[index] ) {
					this._markWarningCell('Notification column ignored due to empty Notification ID', rowByKey.notif_id, index+1);
				}
			});
		}

		this.buildStats.milestones = 1;

		return [obj];
	}
	_buildOneStep(data, index, rowByKey){
		var obj = Object.create(null);

		obj.title = this.__validateRequired(COL_HEAD.step_title, data.step_title?.[index], rowByKey.step_title, index+1);

		let desc = obj.description = {content: this.__validateContentType(data.step_description_content_type?.[index], rowByKey.step_description_content_type, index+1)};
		if ( desc.content == CONTENT.HTML ) {
			desc.formatted = this.__validateRequired(COL_HEAD.step_description_html, data.step_description_html?.[index], rowByKey.step_description_html, 1);
			if ( data.description ) {
				this._markWarningCell('Description will be replaced with html-stripped Formatted Description', rowsCOL_HEAD.step_description_html, 1);
			}
			desc.raw = Helper.htmlToText(desc.formatted);
		} else {
			desc.raw = this.__validateRequired(COL_HEAD.step_description, data.step_description?.[index], rowByKey.step_description, 1);
		}
		
		let congrats = {
			subtitle: this.__cleanString(data.step_congrats_subtitle?.[index]),
			image_url: this.__validateURL(data.step_congrats_image_url?.[index], rowByKey.step_congrats_image_url, index+1),
		};

		if ( congrats.image_url ) {
			congrats.image_alt_text = this.__validateLength(data.step_congrats_image_alt_text?.[index], rowByKey.step_congrats_image_alt_text, index+1);
		}

		if ( congrats.subtitle ) {
			congrats.message = this.__validateRequired(COL_HEAD.step_congrats, data.step_congrats?.[index], rowByKey.step_congrats, index+1);
			obj.congrats = congrats;
		}

		obj.deadline = {
			deadline_type: this.__validateDeadlineType(data.step_deadline_type?.[index], null, rowByKey.step_deadline_type, index+1),
			date: this.__validateDeadline(data.step_deadline?.[index], obj.step_deadline_type, rowByKey.step_deadline, index+1) || null, 
		}

		// for steps, deadline type is not required
	
		//* BTC-117
		obj.points = this.__validatePoints(data.step_points?.[index], rowByKey.step_points, index+1); 
		obj.validation = this.__validateValidation(data.step_validation?.[index], rowByKey.step_validation, index+1);

		if ( obj.validation != 'question' ) {
			obj.validation_instructions = this.__cleanString(data.step_validation_instructions?.[index]);
		} else {
			obj.questions = this.__buildOneQuestions(data, index, rowByKey);
		}

		obj.links = this.__buildOneLinks(data, index, rowByKey);

		return obj;
	}
	_buildOneNotif(data, index, rowByKey){
		let obj = Object.create(null);
		obj.text = this.__validateRequired(COL_HEAD.notif_text, data.notif_text?.[index], rowByKey.notif_text, index+1);
		if ( this.__validateRequired(COL_HEAD.notif_date_to_send, data.notif_date_to_send?.[index], rowByKey.notif_date_to_send, index+1) )
			obj.date_to_send = this.__validateDate(data.notif_date_to_send?.[index], rowByKey.notif_date_to_send, index+1);
		return obj;
	}

	__buildOneQuestions(data, index, rowByKey){
		const question = {
			question: (data.step_question?.[index] || '').trim(),
			answers: [],
		}
		if ( question.question.length == 0 )
			this._markInvalidCell('Step question cannot be empty if validation is a question', rowByKey.step_question, index+1);

		// parse answers
		let answers = (data.step_answers?.[index] || '').trim().split(/[\n\r]+/);
		let corrects = (data.step_answers_value?.[index] || '').trim().toLowerCase().split(/[\n\r]+/);
		answers.forEach((val)=>{
			if ( val.trim().length === 0 )
				this._markInvalidCell('Step answers cannot have an empty value', rowByKey.step_answers, index+1);
		});
		corrects.forEach((val, i)=>{
			if ( ! /^(true|false)$/i.test(val) ) {
				this._markInvalidCell('Step correct answers must be "true" or "false" only & cannot be empty', rowByKey.step_answers_value, index+1);
				// corrects[i] = '<span class="highlight">'+ val +'</span>';
			}
		});

		answers.forEach(answer=>{
			let ans = {text: answer};
			if ( corrects.includes(answer) )
				ans.correct = true;
			if ( answer )
				question.answers.push(ans);
		});

		return {'1': question};
	}
	__buildOneLinks(data, index, rowByKey){
		let links = data[COL_HEAD.step_links]?.[index] || '';
		let list = links.trim().split(/[\n\r]+/);
		if ( links.length > 0 && list.length > 0 ) {
			const results = [];

			for( var i=0; i<list.length; i+=2 ) {
				var text = list[i], url = list[i+1] || '';
				if ( text.trim().length === 0 )
					this._markInvalidCell( 'Missing a link text at line '+(i+1), rowByKey.step_links, index+1 );
				if ( url.trim().length === 0 )
					this._markInvalidCell( 'Missing a link url text at line '+(i+2), rowByKey.step_links, index+1 );
				else if ( !/^https?\:\/\/.+/.test(url) ) {
					this._markInvalidCell( 'Link url must be a valid url at line '+(i+2), rowByKey.step_links, index+1 );
					// links[i+1] = '<span class="highlight">'+ url +'</span>'
				}
				results.push({
					text: text, 
					url: url.replace(/\s/g, '%20'),
				});
			}
			// data.step_links = '<span class="nowrap">'+ links.join('<br>') +'</span>';

			if ( list.length % 2 !== 0 )
				this._markInvalidCell( 'Step links expect pairs of link text & url separated by line-breaker, got '+ list.length, rowByKey.step_links, index+1 );
			
			return results;
		}
		return undefined;
	}


	_markInvalidCell(msg, r, c){
		let err = new Error(msg);
		err.name = 'Parse Error';
		err.row = r;
		err.col = c;
		err.node = this.getGridId(r, c);
		this.errors.push(err);

		const rowKey = `row_${r}`,
			colKey = `col_${c}`;

		let row = this.errors[rowKey] = this.errors[rowKey] || [];
		let list = row[colKey] = row[colKey] || [];
		if ( ! row.includes(list) ) row.push(list);
		list.push(err);

		return err;
	}
	_markWarningCell(msg, row, col){
		this.warnings.push({
			message: msg,
			row,
			col,
			node: this.getGridId(row, col),
		});
	}

	getGridId(r, c){
		return this.getColumnHeader(c) +(isNaN(r) ? '' : r+1)
	}
	getColumnHeader(value){
		if ( ! isNaN(value) ) {
			let result = String.fromCharCode(65 + (value % 26));
			let over = Math.floor(value / 26); // 26 letters
			if ( over )
				result = this.getColumnHeader(over -1) + result;
			return result;
		}
		return '';
	}


	hasRowErrors(row){
		let k = `row_${row}`;
		return this.errors[k] && this.errors[k].length > 0;
	}
	getRowErrors(row){
		return this.errors[`row_${row}`] || null;
	}

	hasCellErrors(row, col){
		let list = this.getCellErrors(row, col);
		return list && list.length > 0;
	}
	getCellErrors(row, col){
		let rowKey = `row_${row}`;
		if ( this.errors[rowKey] ) {
			let colKey = `col_${col}`;
			return this.errors[rowKey][colKey] || null;
		}
		return null;
	}


	__cleanString(val){
		return (val || '').trim() || undefined;
	}

	__validateRequired(key, val, row, col){
		if ( val !== undefined && (val = (val || '').trim()) )
			return val.trim();
		this._markInvalidCell(key +' is missing or empty', row, col);
	}

	__validateOwner(val, row, col){
		if ( (val = (val || '').trim()) ) {
			if (! /^\d+$/.test(val.trim()) )
				this._markInvalidCell('College Owner must be a valid college ID', row, col);
			if ( ! this.mapping.mycolleges.byId[val] )
				this._markInvalidCell('Invalid content owner, must be a valid college ID', row, col);
			return +val;
		}
		if ( this.mapping.mycolleges.length == 1 )
			return this.mapping.mycolleges[0]._id;
		if ( this.mapping.mycolleges.byId[this.BEYOND12_ID] ) {
			this._markWarningCell('College owner will default to Beyond12', row, col);
			return this.BEYOND12_ID;
		}
		this._markInvalidCell('College owner is missing or empty', row, col);
	}

	__validateRecipients(val, row, col){
		val = JSON.stringify(val);
		if ( val != '{}' )
			return val;
		this._markInvalidCell('Recipient cannot be empty', row, col);
	}
	__validateExpr(val, row, col){
		let parsed = []
		// obj.recipients = Object.create(null);
		if ( typeof val != 'string' ) {
			this._markInvalidCell('Invalid Recipient value', row, col);
		} else if ( val.length === 0 ) {
			this._markInvalidCell('Recipient cannot be empty (use "all" to target all levels or specify level with expression like "cohort=level_new")', row, col);
		} else
		if ( val.toLowerCase() === 'all' ) {
			return '';
		} else try {
			// parsed = this.recipient.parse(val);
			// if ( parsed instanceof Error )
			// 	throw parsed;
			// 	// this._markInvalidCell(`Recipient error: ${parsed.message}`, row, col);
			return val;
		} catch(err) {
			if ( this.recipient.isRecipientError(err) ) {
				let msg = err.message;
				if ( err.word !== undefined )
					msg += ', got "'+ err.word +'"';	
				if ( ! isNaN(err.offset) ) {
					msg += ' at char '+ err.offset;
				}
				this._markInvalidCell(msg, row, col);
			} else {
				this._markInvalidCell(`Err: ${err.message}`, row, col);
			};
		}
	}
	__validateContentType(val, row, col){
		switch( (val || '').trim().toLowerCase() ) {
			case 'plain text':
			case 'text': case 'plain':
			case 'text/plain': return CONTENT.TEXT;
			case 'html': 
			case 'text/html': return CONTENT.HTML;
			case '': case undefined: 
				this._markWarningCell('Content type is not set, will default to "text"', row, col);
				return CONTENT.TEXT;
			default: this._markInvalidCell('Content type must be either "text" or "html"', row, col); break;
		}
	}
	__validatePoints(val, row, col){
		if ( (val = (val || '').trim()) ) {
			if ( /^\d+$/.test(val.trim()) )
				return +val;
			this._markInvalidCell('Points must be a number or blank', row, col);
		}
	}
	__validateAtomic(val, row, col){
		if ( (val = (val || '').trim().toLowerCase()) ) {
			if ( /^(yes|no|true|false)$/.test(val) ) {
				return ['yes', 'true'].includes(val);
			} else {
				this._markWarningCell('Atomic must be either "true" or "false"', row, col);
			}
		}
	}
	__validateDeadlineType(val, deadline, row, col){
		deadline = (deadline || '').trim();
		if ( (val = (val || '').trim().toLowerCase()) ) {
			let res = Helper.mergeObjects(this.mapping.deadlines.map(item=>({[item.name.toLowerCase()]:item._id})))[val];
			if ( res !== undefined ) {
				if ( !(res == 'none' && deadline) )
					return res;
				this._markInvalidCell('Deadline type must be either "soft" or "hard" if there is a deadline', row, col);
			} else {
				this._markInvalidCell('Deadline type must be either "soft", "hard" or "none"', row, col);
			}
		} else if ( deadline ) {
			this._markInvalidCell('Deadline type must be either "soft" or "hard" if there is a deadline', row, col);
		}
	}
	__validateHexColor(val, row, col){
		if ( (val = (val || '').trim()) ) {
			if ( ! /^#[0-9a-f]{6}$/i.test(val) ) {
				this._markInvalidCell('Invalid color format', row, col);
			}
			return val.toUpperCase();
		}
	}
	__validateDeadline(val, type, row, col){
		if ( (val = (val || '').trim().toLowerCase()) ) {
			// BTC-15 support diff date formats
			let d8 = Helper.parseDateTime(val);
			if ( ! d8 ) {
				this._markInvalidCell('Deadline is not a valid format (e.g. Jan 1, 2021)', row, col);
			} else if ( ! d8.isValid() ) {
				this._markInvalidCell('Deadline is not a valid date', row, col);
			} else {
				if ( type && type == 'none' )
					this._markInvalidCell('Deadline should be empty if deadline type is none', row, col);
				if ( d8.format(d8._f).toLowerCase() != val.toLowerCase() ) {
					this._markWarningCell('Date might be incorrect: parsed "'+ d8.format(SETTINGS.dateFormat) +'" from "'+ val +'"', row, col);
				}
				return Helper.toNoonISO(d8);
			}
		} else
		if ( type && type != 'none' ) {
			this._markInvalidCell('Deadline is required if deadline type is set', row, col);
		}
	}
	__validateDate(val, row, col, format=null){
		if ( (val = (val || '').trim().toLowerCase()) ) {
			// BTC-15 support diff date formats
			let d8 = Helper.parseDateTime(val);
			if ( ! d8 ) {
				this._markInvalidCell('Date is not a valid format (e.g. Jan 1, 2021)', row, col);
			} else if ( ! d8.isValid() ) {
				this._markInvalidCell('Date is not a valid date', row, col);
			} else {
				if ( d8.format(d8._f).toLowerCase() != val.toLowerCase() ) {
					this._markWarningCell('Date might be incorrect: parsed "'+ d8.format(SETTINGS.dateFormat) +'" from "'+ val +'"', row, col);
				}
				if ( format ) {
					return d8.format(format);
				} else {
					return Helper.toNoonISO(d8);
				}
			}
		}
	}

	__validateTags(val, row, col){
		if ( (val = (val || '').trim()) ) {
			let tags = {};
			let list = val.split(/\s*,\s*/);

			for( let i=0; i<list.length; i++ ){
				let tag = this.mapping.flutterTags.byName[list[i]];
				if ( tag ) {
					tags[tag._id] = {};
				} else {
					this._markInvalidCell(`Unrecognized tag "${list[i]}"`, row, col);
				}
			}
			return tags;
		}
	}

	__validateValidation(val, row, col){
		let map = Helper.mergeObjects(this.mapping.validations.map(item=>({[item.name.toLowerCase()]:item._id})));
		val = (val || '').trim().toLowerCase();
		if ( map[val] !== undefined )
			return map[val];
		this._markInvalidCell('Step Validation must be either "'+ Object.keys(map).join('", "') +'"', row, col);
	}

	__validateURL(val, row, col){
		if ( (val = (val || '').trim()) ) {
			if ( Helper.isValidURL(val) )
				return val;
			this._markInvalidCell('Invalid URL format', row, col);
		}
	}

	__validateLength(val, row, col) {
		val = (val || '').trim();
		if ( val.length >= 5 ) {
			return val;
		} else {
			this._markInvalidCell('Minimum of 5 characters', row, col); 
		}
	}

}

class CSVParseError {
	constructor(message) {
		this.name = 'CSV Parse Error';
		this.message = message;
	}
}