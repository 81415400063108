import CollegesListController from './colleges.list.controller'
import CollegeSingleController from './college.single.controller'
import {PERMISSIONS, PERMISSION_LEVELS} from '../../common'
import './colleges.scss'


export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {

	const $stateRegistry = $uiRouterProvider.stateRegistry;

	$stateRegistry.register({
		name: 'app.colleges',
		title: 'Colleges',
		url: 'colleges?searchString&sortBy&sortOrder&status&test',
		access: {[PERMISSIONS.READ_COLLEGES]: [PERMISSION_LEVELS.ALL]},
		views: {
			'content@app': {
				template: require('./colleges.list.html'),
				controller: CollegesListController,
				controllerAs: 'ctrl',
			},
		},	
		dynamic: true,
		params: {
			nonce: 0,
			scroll:       {value: 0, inherit:false, dynamic:true},
			searchString: {type:'query', inherit:false},
			status:       {type:'query', inherit:false},
			test:         {type:'query', inherit:false},
			sortBy:       {type:'query', inherit:false},
			sortOrder:    {type:'query', inherit:false},
		},
	});


	let collegeSingleHtml = require('./college.single.html');

	$stateRegistry.register({
		name: 'app.colleges.add',
		title: 'Add College',
		url: '/add',
		access: {[PERMISSIONS.CREATE_COLLEGES]: true},
		views: {
			'content@app': {
				template: collegeSingleHtml,
				controller: CollegeSingleController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'data',
				deps: [],
				useFactory: ()=>null,
		}, {
				provide: 'parentStateParams',
				deps: [],
				useFactory: ()=>({}),
		}],
	});

	$stateRegistry.register({
		name: 'app.colleges.edit',
		title: ['data', data=>'College: '+ data.name],
		url: '/{id:int}',
		access: {[PERMISSIONS.UPDATE_COLLEGES]: true},
		views: {
			'content@app': {
				template: collegeSingleHtml,
				controller: CollegeSingleController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'data',
				deps: ['api', '$state', '$stateParams', '$timeout'],
				useFactory: (api, $state, $stateParams, $timeout)=>api.get('colleges/'+ $stateParams.id)
					.then((res)=>res.data, (err)=>{
						if ( api.isApiError(err) ) {
							let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
							return $state.go(state, {error: err}, {location:false});
						}
						throw err;
					}),
		}, {
				provide: 'parentStateParams',
				deps: [],
				useFactory: ()=>({}),
		}],
	});

}];
