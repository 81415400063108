import angular from 'angular'
import {DependencyInjected, Helper, ApiError} from '../../common'


export default class ReportMonthlyController extends DependencyInjected {
	static get $inject(){return [
		'authentication',
		'api',
		'apiMap',
		'toast',
		'MAPPINGS_JSON',
		'$q',
		'$state',
		'$location',
		'$transitions',
	]}


	init(){
		this.mapping = {
			status: Helper.superMap(this.MAPPINGS_JSON.users.status_key, {type:'status'}),
			roles: Helper.superMap(this.MAPPINGS_JSON.roles.role_key, {type:'roles'}),
		};
		this.apiMap.getColleges()
			.then((data)=>this.mapping.mycolleges = data,()=>{})
			.then(()=>{
				if ( this.mapping.mycolleges.length === 1 ) {
					this.selected = this.mapping.mycolleges[0];
					this.refresh();
				} else
				if ( this.$state.params.college && this.mapping.mycolleges.byId[this.$state.params.college] ) {
					this.selected = this.mapping.mycolleges.byId[this.$state.params.college];
					this.refresh();
				}
				this.isBusy = false;
			});
		this.isBusy = true;

		this.initTables();


		this._destructors.push(
			this.$transitions.onStart({
				from: this.$state.current.name, 
				to: this.$state.current.name,
			}, transition=>{
				let obj = transition.paramsChanged();
				if ( obj.college ) {
					if ( this.mapping.mycolleges.byId[obj.college] && (!this.selected || this.selected._id !== obj.college)) {
						this.selected = this.mapping.mycolleges.byId[obj.college];
						this.refresh();
					}
				} else
				if ( obj.hasOwnProperty('college') ) {
					this.selected = null;
				}
			})
		);
	}

	initTables(){
		this.dateSet = moment().format('MMMM YYYY');

		this.mapping.collegeStudentReport = {
			title: 'Student Report',
			fields: {
				year_and_month: 'Date',
				total_users: 'Total Students',
				goal: 'Goal',
				goal_percentage: 'Goal Percentage',
				new_users: 'New Students Gained',
				total_ios_devices: 'Total IOS',
				total_android_devices: 'Total Android',
				total_level_new: '13 New',
				total_level_continuing: '14 Continuing',
				total_level_prospective: '15 Prospective',
				total_level_unknown: 'Status Not Specified',
				total_notifications_off: 'Students with Notifications Off',
				rate_notifications_off: 'Rate Notifications Off',
			},
			labels: Helper.deepCopy(this.MAPPINGS_JSON.reports.student_report.labels),
		};
		this.mapping.collegeCohortReport = {
			title: 'Cohort Report',
			fields: {
				year_and_month: 'Date',
				students_on_gradguru: '# Students on MyCoach',
				total_students: 'Total # Students',
				rate_students_on_gradguru: 'Rate',
			},
			labels: Helper.deepCopy(this.MAPPINGS_JSON.reports.cohort_report.summary_by_cohorts.labels),
			keys: {
				year_and_month: 'Date',
				total_number_students: 'Total Students',
				total_number_students_with_external_ids: 'Students with IDs Collected',
				percentage_total_number_students_with_external_ids: 'Rate of Student IDs Collected',
			},
			keyLabels: Helper.deepCopy(this.MAPPINGS_JSON.reports.cohort_report.summary.labels),
		};
		this.mapping.collegeMilestoneReport = {
			title: 'Milestone Report',
			fields: {
				title: 'Step Name',
				// number_students_who_have_completed: '',
				// percentage_students_who_have_completed: '% students who have completed', // CON2-637
			},
			// labels: Helper.deepCopy(this.MAPPINGS_JSON.reports.college_milestone_report.summary.labels),
			keys: {
				total_number_students: 'Total Students',
				total_number_students_earned_milestones: '# of Students who have earned one or more milestones',
				percentage_total_number_students_earned_milestones: '% of Students who have earned one or more milestones',
				total_number_students_earned_steps: '# of Students who have earned one or more steps',
				percentage_total_number_students_earned_steps: '% of Students who have earned one or more steps',
				total_number_students_earned_95plus_points: '# of Students with 95+ points',
				percentage_total_number_students_earned_95plus_points: '% of Students with 95+ points',
				gift_cards_requested: 'Gift cards issued',
			},
			milestoneKeys: {
				// title: 'Milestone',
				recipients: 'Recipients',
				number_of_eligible_students: '# of eligible students currently eligible (note: some may earn a step and change level to become ineligible)', // CON2-637
				// number_students_who_have_completed: '# of STUDENTS',
				// percentage_students_who_have_completed: '% students who have completed', // CON2-637
				number_students_who_have_completed_step: '# of students who have completed a step',
				// percentage_students_who_have_not_completed_any_step: 'No Steps Completed', // CON2-637
			},
			stepKeys: {
				title: 'Step Name',
				number_students_who_have_completed: '# of students who have completed',
				// percentage_students_who_have_completed: '% students who have completed', // CON2-637
			},
		};


		this.dataTables = {
			collegeStudentReport: null,
			collegeCohortReport: null,
			collegeMilestoneReport: null,
		};
	}

	refresh(){
		if ( ! this.selected ) return this.$q.reject();

		Object.keys(this.dataTables).forEach((key)=>this.dataTables[key] = null);

		this.$location.search({college: this.selected._id});

		let params = {
			college: this.selected._id,
			myTokenAdministers: this.authentication.isValid(),
		};
		let p, promises = [];

		p = this.api.get('analysis/collegeStudentReport', params).then((res)=>{
			let details = Helper.deepCopy(this.mapping.collegeStudentReport);
			let reportTables = this.dataTables.collegeStudentReport = [];
			delete this.mapping.collegeStudentReport.subtitle;
			
			let hasGoal = false, i = res.data.length;
			while( i-- )
				if ( (hasGoal = hasGoal || !!res.data[i].goal) )
					break;
			if ( ! hasGoal ) {
				delete details.fields.goal;
				delete details.fields.goal_percentage;
			}

			let table = res.data.map(item=>{
				if ( hasGoal && ! this.mapping.collegeStudentReport.subtitle ) {
					if ( moment().startOf('month').isSame(moment(item.year_and_month, 'YYYY/MM')) ) {
						this.mapping.collegeStudentReport.subtitle = 'Goal: '+ item.total_users +' of '+ item.goal +' students reached';
					}
				}
				item.year_and_month = moment(item.year_and_month, 'YYYY/MM').format('MMMM YYYY');
				return Object.keys(details.fields).map(key=>item[key] === null ? 'n/a' : item[key]);
			});
			table.headers = Object.keys(details.fields).map(key=>details.labels[key] || details.fields[key]);
			if ( table.length > 0 )
				reportTables.push(table);
		}, (err)=>{
			if ( err instanceof ApiError && params.myTokenAdministers )
				this.toast.error(err);
			this.dataTables.collegeStudentReport = [];
			throw err;
		});
		promises.push(p);

		p = this.api.get('analysis/collegeCohortReport', params).then((res)=>{
			let details = this.mapping.collegeCohortReport;
			let reportTables = this.dataTables.collegeCohortReport = [];
			
			let summary = Object.keys(res.data.summary)
					.filter(key=>!!details.keys[key])
					.map(key=>[details.keyLabels[key] || details.keys[key], res.data.summary[key] == null ? 'n/a' : res.data.summary[key]]);
			if ( summary.length > 0 )
				reportTables.push(summary);
			
			res.data.summaryByCohorts.forEach(item=>{
				let table = item.monthly_report.map((monthly, index)=>{
					monthly.year_and_month = moment(monthly.year_and_month, 'YYYY/MM').format('MMMM YYYY');
					return Object.keys(details.fields).map(key=>monthly[key] == null ? 'n/a' : monthly[key]);
				});
				table.title = 'COHORT: '+ item.cohort;
				table.headers = Object.keys(details.fields).map(key=>details.labels[key] || details.fields[key]);
				if ( table.length > 0 )
					reportTables.push(table);
			});
		}, (err)=>{
			if ( err instanceof ApiError && params.myTokenAdministers )
				this.toast.error(err);
			this.dataTables.collegeCohortReport = [];
			throw err;
		});
		promises.push(p);

		p = this.api.get('analysis/collegeMilestoneReport', params).then((res)=>{
			let data = res.data;
			let details = this.mapping.collegeMilestoneReport;
			let reportTables = this.dataTables.collegeMilestoneReport = [];
			

			// console.log(reportTables);
			let summary = Object.keys(data.summary)
					.filter(key=>!!details.keys[key])
					.map(key=>[details.keys[key], data.summary[key] == null ? 'n/a' : data.summary[key]]);
			if ( summary.length > 0 )
				reportTables.push(summary);

			if ( data.summary && data.summary.milestones )
				data.summary.milestones.forEach((milestone)=>{
					let table = Object.keys(milestone)
							.filter(key=>!!details.milestoneKeys[key])
							.map(key=>[details.labels.milestones[key] || details.milestoneKeys[key], milestone[key] == null ? 'n/a' : milestone[key]]);
					table.title = 'Milestone: '+ milestone.title;
					let sub = milestone.steps.map(step=>{
						return Object.keys(details.stepKeys).map(key=>step[key] == null ? 'n/a' : step[key]);
					});
					sub.headers = Object.keys(details.stepKeys).map(key=>details.labels.milestones.steps[key] || details.stepKeys[key]);

					table.subtables = [sub];
					if ( table.length > 0 )
						reportTables.push(table);
				});
		}, (err)=>{
			if ( err instanceof ApiError && params.myTokenAdministers )
				this.toast.error(err);
			this.dataTables.collegeMilestoneReport = [];
			throw err;
		});
		promises.push(p);

		return this.$q.all(promises);
	}

	getShareLink(){
		if ( this.selected )
			return this.$state.href('monthlyReport', {name: this.selected.name.replace(/[^\w]+/g, '-').toLowerCase(), id: this.selected._id}, {absolute:true});
	}

}