import angular from 'angular'
import $ from 'jquery'
import {DependencyInjected, Helper, SETTINGS, ApiError} from '../common'


export default class BaseList extends DependencyInjected {
	static get $inject(){return [
		'$q',
		'$sce',
		'$scope',
		'$httpParamSerializer',
		'$state',
		'$stateParams',
		'$window',
		'$timeout',
		'$location',
		'$transitions',
		'authentication',
		'authorization',
		'api',
		'toast',
		'session',
		'$window',
		'$animateCss',
		'MAPPINGS_JSON',
		'BEYOND12_ID',
		'downloader',
	]}

	init(){
		this.isBusy = true;
		this.mapping = this.mapping || {};

		this.query = {
			limit: 30,
			offset: 0,
			defaultSortBy: 'id',
			defaultSortOrder: 'desc',
		};

		this.dataList = [];
		this.filters = [];
		this.defers = {};


		// CON2-501 focus search on page ready
		this._destructors.push(
			this.$scope.$once('deps-loaded', ()=>this.$timeout(()=>$('input[name="search"]').first().focus()))
		);

		this._$baseState = this._$baseState || this.$state.current.name;
		this._destructors.push(
			this.$transitions.onRetain({to:this._$baseState, from:this._$baseState}, Helper.debounce(transition=>{
				if ( this._$skipFilterListener )
					return this._$skipFilterListener = false;

				let obj = transition.paramsChanged();
				if ( Object.keys(obj).length > 0 ) {
					console.log('filter change', obj);

					// if ( this.onUrlParamChange(obj) )
					let hasChanges = this.updateFilterModels !== BaseList.prototype.updateFilterModels ? this.updateFilterModels(obj) : this.onUrlParamChange(obj);
					if ( hasChanges ) this.refresh(true);
				}
			}, 50)),

			// remember scroll position when leaving this state to a child state
			this.$transitions.onFinish({from: this._$baseState, to: `${this._$baseState}.*`}, transition=>{
				if ( transition.to().name != this._$baseState ) {
					this._saveScrollState();
					let data = transition._treeChanges.to.map(obj=>obj.resolvables.map(res=>res.token=='parentStateParams' && res.data).filter(v=>!!v)[0]).filter(v=>!!v)[0];
					if ( data )
						data.scroll = this._lastScrollPos || 0;
				}
			}),

			// busy state when trying to transition
			this.$transitions.onBefore({from: this._$baseState}, transition=>{
				if ( transition.to().name != this._$baseState )
					this.isBusy = true;
			}),
			// aborted, invalid or error
			this.$transitions.onError({from: this._$baseState}, transition=>{
				if ( [3,4,6].includes(transition.error().type) )
					this.isBusy = !this.ready;
			}),
		);

		this._lastScrollPos = 0;
		this.debouncedSaveScrollState = Helper.debounce(()=>{
			if ( this.$state.is(this._$baseState) && !this.$state.transition ) {
				let state = this._saveScrollState();
				if ( state ) {
					var param = {};
					for(let k in this.$state.params) {
						if ( this.$state.params.hasOwnProperty(k) )
							param[k] = state[k] || this.$state.params[k];
					}
					this.$state.transitionTo(this.$state.current, param, {reload:false, location:false, notify:false, inherit:true, supercede:false});
				}
			}
		}, 200);

		
		this.$q.when(this._loadDependencies())
			.then(()=>{
				this.ready = true;
				this._initResizeHandler();
				this.$scope?.$broadcast('deps-loaded');
				this.$scope?.$parent?.$emit('deps-loaded');
			});
	}
	_loadDependencies(){
		return this.$q.resolve();
	}
	_initResizeHandler(){
		const $win = $(this.$window);

		const debouncedRefresh = Helper.debounce(()=>{
			this.dataList = tmpList;
			tmpList = null;
			if ( this.selected )
				this.$timeout(()=>this._resizeSelected(), 200);
			this.$timeout(()=>this.isBusy = false, 220);
			this.$scope?.$evalAsync?.();
		}, 200);

		let lastW = $win.width();
		let tmpList;

		$win.on('resize.list', Helper.debounce(()=>{
			let w = $win.width();
			if ( lastW != w ) {
				lastW = w;
				if ( this.selected ) $('#expanded-overlay').hide();
				this.isBusy = true;
				if ( ! tmpList ) {
					tmpList = this.dataList;
					this.dataList = [];
					this.$scope?.$evalAsync?.();
				}
				debouncedRefresh();
			}
		}, 500));

		this._destructors.push(()=>{
			Object.values(this.defers).forEach(d=>d.resolve('abort'));
			$win.off('resize.list');
		});
	}

	_saveScrollState(){
		let $el = $('.md-virtual-repeat-scroller');
		let state = this.$location.state() || {};
		state.scroll = $el.scrollTop();
		if ( $el.length && state.scroll !== this._lastScrollPos ) {
			this._lastScrollPos = state.scroll;
			this.$window.history.replaceState(state, this.$window.document.title, this.$location.url());
			// console.info(this.$state.current.name, 'replace state', state);
			return state;
		}
	}
	_loadScrollState(){
		// TODO: refactor scroll restore with md-top-index instead
		let value = this.$state.params?.scroll || (this.$location.state() || {}).scroll || 0;
		setTimeout(()=>{
			$('.md-virtual-repeat-scroller').scrollTop(value);
			console.info('set scroll', value);
		}, 500);
	}


	hasPermissionLevel(level){
		return this.authorization.hasStateAccessLevel(this.$state.current.name, level);
	}


	updateFilters(refresh){
		console.warn(`updateFilters() is deprecated on ${this.constructor.name}. Use onFiltersChange() for any subclass references including overrides`);
		this._$skipFilterListener = true;
		this.$timeout(()=>{
			if ( this.ready ) this.$location.search(this.getFilters());
			this.refresh(refresh);
		}, 0);
	}
	onFiltersChange(){
		let filters = this.getFilters();

		if ( ! angular.equals(filters, this.$location.search()) ) {
			this.$location.search(filters);
		}
	}
	removeFilter(item){
		if ( this.filters.includes(item) ) {
			const k = '_'+ item._$filterKey;
			if ( Array.isArray(this.filters[k]) ) {
				this.filters[k].remove(item);
			} else {
				this.filters[k] = null;
			}
			this.onFiltersChange();
		}
	}
	
	updateFilterModels(obj){
		console.warn(`updateFilterModels() is deprecated on ${this.constructor.name}. Use onUrlParamChange() for any subclass references including overrides`);
		return this.onUrlParamChange(obj);
	}
	onUrlParamChange(obj){
		if ( obj.hasOwnProperty('dateFrom') ) {
			if ( obj.dateFrom ) {
				let d1 = moment(obj.dateFrom, 'YYYY-MM-DD');
				this.query.dateFrom = d1.isValid() ? d1 : null;
			} else {
				this.query.dateFrom = null;
			}
		}
		if ( obj.hasOwnProperty('dateTo') ) {
			if ( obj.dateTo ) {
				let d2 = moment(obj.dateTo, 'YYYY-MM-DD');
				this.query.dateTo = d2.isValid() ? d2 : null;
			} else {
				this.query.dateTo = null;
			}
		}
		if ( this.query.dateFrom || this.query.dateTo )
			this.updateDateRange();
		if ( obj.hasOwnProperty('sortBy') )
			this.query.sortBy = this.mapping.sortBy?.byId[obj.sortBy] && obj.sortBy || this.query.defaultSortBy;
		if ( obj.hasOwnProperty('sortOrder') )
			this.query.sortOrder = this.mapping.sortOrder?.byId[obj.sortOrder] && obj.sortOrder || this.query.defaultSortOrder;
		if ( obj.hasOwnProperty('searchString') )
			this.query.search = obj.searchString || '';
		
		let keys = ['dateFrom', 'dateTo', 'sortBy', 'sortOrder', 'searchString'];
		return !! Object.keys(obj).find(val=>keys.includes(val));
	}


	getParams(reset){
		return angular.extend({
				limit: this.query.limit,
				offset: this.query.offset = (reset ? 0 : this.query.offset),
			}, 
			this.getFilters(true),
			{
				withMetadata:true, 
				myTokenAdministers:true,
			});
	}
	getFilters(all){
		this.filters.splice(0);
		Object.keys(this.filters._$keys).forEach(($key)=>{
			let list = this.filters['_'+ $key];
			if ( list ) {
				if ( !Array.isArray(list) ) list = [list];
				list.forEach((item)=>item._$filterKey = $key);
				this.filters.push.apply(this.filters, list);
			}
		});

		const res = {
			searchString: this.query.search || undefined,	
			sortBy: this.query.sortBy || undefined,
			sortOrder: this.query.sortOrder || undefined,
			dateFrom: this.filters._date?.from?.format('YYYY-MM-DD') || undefined,
			dateTo: this.filters._date?.to?.format('YYYY-MM-DD') || undefined,
		};
		if ( !all ) {
			if ( res.sortBy == this.query.defaultSortBy )
				res.sortBy = undefined;
			if ( res.sortOrder == this.query.defaultSortOrder )
				res.sortOrder = undefined;
		}

		return res;
	}


	_refresh(url, params, options){
		if ( this._busyRefresh ) {
			if ( JSON.stringify(this.currentApiParams) == JSON.stringify(params) )
				return this._busyRefresh; // cancel current request, its the same
			return this._busyRefresh = this.$q.when(this._busyRefresh).then(()=>this.__refresh(url, params, options));
		}
		return this.__refresh(url, params, options);
	}
	__refresh(url, params, options){
		let isPartial = params.offset !== 0;
		this.isBusy = ! isPartial;
		this.partialBusy = true;
		this.error = null;

		// TODO: better handling of redundant request rather than just cancelling priors immediately
		if ( this.defers[params.offset] ) this.defers[params.offset].resolve('abort');
		this.defers[params.offset] = this.$q.defer();

		options = angular.extend(Helper.deepCopy(options||{}), {timeout: this.defers[params.offset].promise, level: ApiError.LEVEL.MANUAL});

		if ( ! isPartial ) {
			this.dataList = [];
			// this.itemIndex = 0;
			this._errors = [];
		}
		this.currentApiURI = url;
		this.currentApiParams = Helper.deepCopy(params);

		let promise = this.api.get(url, params, options)
			.then(res=>{
				this.lastApiURI = res.config.url;
				this.lastApiParams = Helper.deepCopy(params);
				if ( ! isPartial ) {
					this.dataList = this.process(res.data.content);
				} else {
					this.process(res.data.content)
						.forEach((item, i)=>this.dataList[i + params.offset] = item);
				}
				this.query.total = res.data.totalCount;
				this.query.offset = res.data.offset;
				return res;
			}, err=>{
				if ( this.api.isApiError(err) && !err.isIgnored() ) {
					err.name = 'Results Unavailable';
					this._errors.push(err);

					if ( ( !isPartial || this._errors.length > 10) && ! this._errors.thrown ) {
						this.error = err;
						// this.toast.error(err);
						this._errors.thrown = true;
					}
				}
			});
		promise.finally(()=>{
				this.$timeout(()=>this.isBusy = this.partialBusy = false, 500);
				delete this.defers[params.offset];
				this.$scope && this.$scope.headerForm && this.$scope.headerForm.$setPristine();
				delete this._busyRefresh;
				delete this.currentApiURI;
				delete this.currentApiParams;
		});
		return this._busyRefresh = promise;
	}

	getItemAtIndex(index){
		if ( this.dataList[index] ) {
			this.debouncedSaveScrollState();
			return this.dataList[index];
		}
		if ( ! this.isBusy && ! this.partialBusy ) {
			let offset = Math.floor(index / this.query.limit) * this.query.limit || 0;
			if ( ! this.defers[offset] && index < this.query.total && ! this._errors.thrown ) {
				this.query.offset = offset;
				this.refresh(false);
			}
		}
	}

	getLength(){
		return this.query.total || 0;
	}


	updateDateRange(refresh){
		let date = this.query.date;
		date.from = this.query.dateFrom || null;
		date.to = this.query.dateTo || null;
		date.name = [
			this.query.dateFrom?.format(SETTINGS.dateFormat) || '',
			this.query.dateTo?.format(SETTINGS.dateFormat) || '',
		].join(' — ');
		if ( date.from || date.to ) {
			this.filters._date = date;
		} else {
			this.filters._date = null;
		}
		this.onFiltersChange();
		// if ( refresh ) this.refresh(true);
	}


	open(item){
		this.selected = item;
		let $window = $(window),
			$item = $('#item-'+ this.selected._id);
		if ( !$item.length ) return;
		let offset = $item.offset(),
			height = $item.height(),
			wheight = $window.height(),
			$main = $item.closest('main'),
			target = $main.offset();
		let style = {
			top: offset.top,
			// bottom: (wheight - offset.top + $window.scrollTop() - height) +'px',
			height: height,
			left: target.left,
			width: $main.width(),
		};

		this.$scope.$evalAsync(()=>{
			$item.css('visible', 'hidden');
			let $overlay = $('#expanded-overlay').css(style);
			setTimeout(()=>{
				$overlay.addClass('animated').css({
					top: target.top,
					height: $main.height(),
				});
				$overlay.find('.action-btn[aria-expanded]').first().focus();
			}, 13);
			$item.closest('.data-list').fadeOut();
		});

		if ( item._$sublist ) {
			this.sublist = item._$sublist;
			return;
		}

		this.sublist = [];
		return this._open(item)
			.then((data)=>this.sublist = item._$sublist = data);
	}
	_open(item){
		console.warn('override process method');
		return;
	}
	close(){
		let $window = $(window),
			$item = $('#item-'+ this.selected._id);
		if ( !$item.length ) return;
		$item.closest('.data-list').show();
		$item.find('.action-btn[aria-expanded]').first().focus();
		let offset = $item.offset(),
			height = $item.height(),
			wheight = $window.height();
		let style = {
			top: offset.top +'px',
			height: height +'px',
		};
		this.$scope.$evalAsync(()=>{
			let $overlay = $('#expanded-overlay').removeClass('animated');
			this.$animateCss(angular.element($overlay[0]), {
					easing: 'ease-out',
					to: style,
					duration: 0.4, // seconds
				}).start().then(()=>{
					$item.css('visible', '');
					this.selected = null;
				});
			$item.closest('.data-list').show();
		});
	}

	_resizeSelected(){
		if ( ! this.selected ) return;
		
		let $item = $('#item-'+ this.selected._id);
		if ( !$item.length ) return this.close();

		let $main = $item.closest('main'),
			target = $main.offset();
		$('#expanded-overlay').show().css({
			top: target.top,
			height: $main.height(),
			left: target.left,
			width: $main.width(),
		});
	}


	process(list){
		console.warn('override process method');
		return list;
	}


	async exportCSV(filename='download.csv'){
		const url = this.lastApiURI +'?'+ 
			this.$httpParamSerializer(angular.extend({}, this.lastApiParams, {
				searchString: this.lastApiParams.searchString || undefined,
				offset: undefined,
				limit: undefined,
				withMetadata: undefined,
				format: 'csv',
			}));
		await this._exportCSV(url, filename);
	}
	async _exportCSV(url, filename) {
		if ( ! this.ready ) return;

		this.isBusy = true;
		this.ready = false;
		try {
			this.toast.success('Preparing export CSV...', 0);
			await this.downloader.get(url, filename);

		} catch(err) {
			if ( this.api.isApiError(err) ) {
				err.name = 'Unable to Export CSV';
				this.toast.error(err);
			} else
			if ( err ) {
				if ( err instanceof Error )
					this.toast.error(err);
				console.error(err);
			}
		}
		this.toast.clear();
		this.ready = true;
		this.isBusy = false;
		this.$scope.$evalAsync();
	}

	toSafeLangHtml(str, tags='span') {
		return this.$sce.trustAsHtml(Helper.toSafeLangHtml(str, tags));
	}
}