import TipsListController from './tips.list.controller'
import TipSingleController from './tip.single.controller'
import {Helper, PERMISSIONS, PERMISSION_LEVELS} from '../../common'
import '../../common/styles/gg-fonts.css'
import '../events/events.scss'


export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {

	const $stateRegistry = $uiRouterProvider.stateRegistry;

	$stateRegistry.register({
		name: 'app.tips',
		title: 'Tips',
		url: 'tips?searchString&sortBy&sortOrder&dateFrom&dateTo&status&types&categories&colleges&districts&cohorts&view&term',
		access: {[PERMISSIONS.READ_TIPS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT]},
		views: {
			'content@app': {
				template: require('./tips.list.html'),
				controller: TipsListController,
				controllerAs: 'ctrl',
			},
		},
		dynamic:true,
		params: {
			nonce: 0,
			scroll:       {value: 0, inherit:false, dynamic:true},
			searchString: {type:'query', inherit:false},
			sortBy:       {type:'query', inherit:false},
			sortOrder:    {type:'query', inherit:false},
			dateFrom:     {type:'query', inherit:false},
			dateTo:       {type:'query', inherit:false},
			status:       {type:'query', inherit:false},
			categories:   {type:'query', inherit:false, array:true},
			colleges:     {type:'query', inherit:false, array:true},
			districts:    {type:'query', inherit:false, array:true},
			terms:    {type:'query', inherit:false, array:true},
			cohorts:      {type:'query', inherit:false, array:true},
			view:	 				{type:'query', inherit:false},
		},
	});

	$stateRegistry.register({
		name: 'app.tips.calendarDetail',
		title: ['data', data=>'Tips Calendar #'+ data._id],
		url: '?id',
		access: {[PERMISSIONS.READ_TIPS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT]},
		views: {
			'detail@app.tips': {
				template: require('./tips.calendar.detail.html'),
				controller: TipSingleController,
				controllerAs: 'ctrl',
			},
		},
		dynamic:true,
		params: {
			id: {type:'query', inherit:false},
		},
		resolve: [{
				provide: 'data',
				deps: ['api', '$state', '$stateParams'],
				useFactory: (api, $state, $stateParams)=>api.get('tips/'+ $stateParams.id)
					.then((res)=>res.data)
					.catch((err)=>{
						if ( api.isApiError(err) ) {
							let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
							return $state.go(state, {error: err}, {location:false});
						}
						throw err;
					}),
				}, {
					provide: 'clone',
					useFactory: ()=>null,	
				}, {
					provide: 'parentStateParams',
					useFactory: ()=>null,
				}
	],
	});

	
	let tipSingleHtml = require('./tip.single.html');

	$stateRegistry.register({
		name: 'app.tips.add',
		title: 'Add Tip',
		url: '/add?clone',
		access: {[PERMISSIONS.CREATE_TIPS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT]},
		views: {
			'content@app': {
				template: tipSingleHtml,
				controller: TipSingleController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'data',
				useFactory: ()=>null,
			}, {
				provide: 'clone',
				deps: ['api', '$state', '$stateParams'],
				useFactory: (api, $state, $stateParams)=>$stateParams.clone && api.get('tips/'+ $stateParams.clone)
					.then(res=>Helper.deepCopy(res.data))
					.catch(err=>{
						if ( api.isApiError(err) ) {
							let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
							return $state.go(state, {error: err}, {location:false});
						}
						throw err;
					}),
			}, {
				provide: 'parentStateParams',
				useFactory: ()=>({}),
		}],
	});

	$stateRegistry.register({
		name: 'app.tips.edit',
		title: ['data', data=>'Tip #'+ data._id],
		url: '/{id:int}',
		access: {[PERMISSIONS.UPDATE_TIPS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT]},
		views: {
			'content@app': {
				template: tipSingleHtml,
				controller: TipSingleController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'data',
				deps: ['api', '$state', '$stateParams'],
				useFactory: (api, $state, $stateParams)=>api.get('tips/'+ $stateParams.id)
					.then((res)=>res.data)
					.catch((err)=>{
						if ( api.isApiError(err) ) {
							let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
							return $state.go(state, {error: err}, {location:false});
						}
						throw err;
					}),
				}, {
					provide: 'clone',
					useFactory: ()=>null,
				}, {
					provide: 'parentStateParams',
					useFactory: ()=>({}),
		}],
	});

}];
