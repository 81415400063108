import WorkspacesListController from './workspaces.list.controller'
import WorkspacePublishController from './workspaces.publish.controller'
import WorkspacesCalendarController from './workspaces.calendar.controller'
import WorkspacesUploadController from './workspaces.upload.controller'
import '../../common/styles/gg-fonts.css'
import './workspaces.scss'
import {PERMISSIONS, PERMISSION_LEVELS} from '../../common'


export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {
	const $stateRegistry = $uiRouterProvider.stateRegistry;

	$stateRegistry.register({
		name: 'app.workspaces',
		title: 'Workspaces',
		url: 'workspaces?status',
		access: {[PERMISSIONS.READ_WORKSPACES]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOACHED, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT]},
		views: {
			'content@app': {
				template: require('./workspaces.list.html'),
				controller: WorkspacesListController,
				controllerAs: 'ctrl',
			},
		},
		dynamic: true,
		params: {
			nonce: 0,
			scroll: {value: 0, inherit:false},
			// sortBy:       {type:'query', inherit:false},
			// sortOrder:    {type:'query', inherit:false},
			status:       {type:'query', inherit:false},
		},
	});

	$stateRegistry.register({
		abstract: true,
		name: 'app.workspaces.edit',
		url: '/{id:int}',
		access: {[PERMISSIONS.UPDATE_WORKSPACES]: true},
		// redirectTo: (trans)=>({state:'^.calendar', params:trans.params()}),
		resolve: [{
				provide: 'data',
				deps: [],
				useFactory: ()=>null,
		},{
			provide: 'eventsData',
			deps: ['api', '$state', '$stateParams'],
			useFactory: (api, $state, $stateParams)=>api.get('events?workspace='+$stateParams.id, {batch:$stateParams.id})
				.then(res=>res.data, err=>{
					if ( api.isApiError(err) ) {
						let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
						return $state.go(state, {error: err}, {location:false});
					}
					return err;
				})
		}, {
			provide: 'tipThemesData',
			deps: ['api', '$state', '$stateParams'],
			useFactory: (api, $state, $stateParams)=>api.get('tipThemes?workspace='+$stateParams.id, {batch:$stateParams.id})
				.then(res=>res.data, err=>{
					if ( api.isApiError(err) ) {
						let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
						return $state.go(state, {error: err}, {location:false});
					}
					return err;
				})
		}, {
			provide: 'batch',
			deps: ['api', '$state', '$stateParams'],
			useFactory: (api, $state, $stateParams)=>api.get('workspaces/'+ $stateParams.id)
				.then(res=>res.data, err=>{
					if ( api.isApiError(err) ) {
						let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
						return $state.go(state, {error: err}, {location:false});
					}
					throw err;
				}),
		}],
	});

	$stateRegistry.register({
		name: 'app.workspaces.edit.calendar',
		title: ['batch', batch=>'Workspace Calendar — '+ batch.file_name],
		url: '/calendar',
		access: {[PERMISSIONS.UPDATE_WORKSPACES]: true},
		views: {
			'content@app': {
				template: require('./workspaces.calendar.html'),
				controller: WorkspacesCalendarController,
				controllerAs: 'ctrl',
			},
		},
		params: {
			select: {dynamic: true, value: null},
		},
	});

	$stateRegistry.register({
		name: 'app.workspaces.edit.preview',
		title: ['batch', batch=>'Workspace Publish Preview — '+ batch.file_name],
		url: '/preview',
		access: {[PERMISSIONS.PUBLISH_WORKSPACES]: true},
		views: {
			'content@app': {
				template: require('./workspaces.publish.html'),
				controller: WorkspacePublishController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
			provide: 'batchStatus',
			deps: ['api', '$state', '$stateParams'],
			useFactory: (api, $state, $stateParams)=>api.get('workspaces/'+ $stateParams.id+'/stats')
				.then(res=>res.data, err=>{
					if ( api.isApiError(err) ) {
						let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
						return $state.go(state, {error: err}, {location:false});
					}
					throw err;
				}),
		}],
	});

	$stateRegistry.register({
		name: 'app.workspaces.add',
		title: 'Add Workspace',
		url: '/add',
		access: {[PERMISSIONS.CREATE_WORKSPACES]: true},
		views: {
			'content@app': {
				template: require('./workspaces.upload.html'),
				controller: WorkspacesUploadController,
				controllerAs: 'ctrl',
			},
		},
	});


}];