<header class="flex-none layout-column layout-gt-xs-row layout-align-gt-xs-space-between start layout-wrap md-whiteframe-1dp">
	<h2 class="md-headline flex">
		Site Map
	</h2>
</header>

<main id="site-map" class="flex" ng-class="{'has-data': ctrl.data}">
	<div class="panel panel-small md-whiteframe-1dp" ng-repeat="item in ctrl.menuList track by $index">
		<div class="panel-title">
      <md-list-item id="{{::item.id}}" class="link"
        ng-href="{{ mainCtrl.getLink(item) }}"
        ng-click="mainCtrl.go(item, $event, 'side')">
        <span id="label_{{::item.id}}">{{ ::item.name }}</span>
      </md-list-item>
		</div>
		<div class="panel-content" ng-if="item.children">
      <md-list class="links padding-0x">
        <md-list-item id="{{::subitem.id}}"
          ng-repeat="subitem in item.children track by $index"
          ng-href="{{ mainCtrl.getLink(subitem) }}"
          ng-class="{active: mainCtrl.$state.includes(subitem.sref)}" 
          ng-click="mainCtrl.go(subitem, $event, 'side')">
          <span>{{ ::subitem.name }}</span>
        </md-list-item>
      </md-list>
		</div>
	</div>
</main>

<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->
