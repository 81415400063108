<header class="md-whiteframe-2dp flex-none layout-row layout-align-space-between-center layout-wrap">
	<h2 class="md-headline">
		<a ui-sref="^" ui-sref-opts="ctrl.parentStateParams">Workspaces <span class="margin-1x" aria-hidden="true">&#9656;
		</span></a>Add New
	</h2>

	<i flex></i>
	<md-button type="reset" class="md-button md-warn md-whiteframe-1dp" 
		ng-click="ctrl.clear()" ng-show="ctrl.file">
		<i class="fas fa-eraser"></i> Clear
	</md-button>
</header>

<form id="notification-flutter-upload-form" name="form" novalidate ng-submit="ctrl.submit()" flex layout="column" form-disabled="ctrl.isBusy">
	<main class="flex can-scroll-y layout-gt-sm-column">
		<div class="panel panel-small flex-gt-sm-100 layout-column md-whiteframe-1dp fade-in" ng-hide="ctrl.file">
			<div class="panel-content flex layout-column">
				<div class="dropzone margin-4x padding-4x md-caption black-66 text-center flex layout-column layout-align-center-center">
					<div class="md-title padding-4x margin-2x" ng-show="ctrl.successCount">
						<a ui-sref="^">{{ ctrl.successCount }} Record(s) Uploaded</a>
					</div>
					<md-button type="button" id="fileInput" class="md-raised md-primary" ng-hide="ctrl.isBusy">
						<i class="far fa-file"></i> Select {{ ctrl.successCount ? 'another' : '' }} File
					</md-button>
					<span class="hide-xs hide-sm" ng-hide="ctrl.isBusy">or drop files here</span>
				</div>
			</div>
		</div>

		<div class="panel flex-gt-sm-none md-whiteframe-1dp fade-in" ng-show="ctrl.file">
			<div class="panel-title padding-4x layout-row layout-align-space-between-center">
				<h4 class="md-title normal-wg margin-0x">{{ ctrl.file.name }}</h4>
			</div>
			<div class="panel-content can-scroll-x can-scroll-y">
				<div class="padding-4x">
					<table class="table table-striped" style="width:100%">
						<tr><td class="text-right" style="width:33%"><b>File Name</b></td>
								<td>{{ ctrl.file.name }}</td></tr>
						<tr><td class="text-right"><b>File Type</b></td>
								<td>{{ ctrl.file.type }}</td></tr>
						<tr><td class="text-right"><b>File Size</b></td>
								<td>{{ ctrl.file.size }} bytes</td></tr>
						<tbody ng-show="1||ctrl.errors.length === 0">
							<tr></tr>
							<tr><td class="text-right"><b>Tag Columns</b></td>
									<td>{{ ctrl.batch.tags || 0 }}</td></tr>
							<tr><td class="text-right"><b>Reminder Columns</b></td>
									<td>{{ ctrl.batch.reminders || 0 }}</td></tr>
							<tr><td class="text-right"><b>Total Events</b></td>
									<td><b>{{ ctrl.batch.count_dates_and_events }}</b></td></tr>
							<tr><td class="text-right"><b>Total Tips</b></td>
									<td><b>{{ ctrl.batch.count_tip_theme_tips }}</b></td></tr>
							<tr ng-if="ctrl.batch.tipThemes"><td class="text-right"><b>Total Tip Themes</b></td>
									<td><b>{{ ctrl.batch.count_tip_theme }}</b> ({{ ctrl.batch.count_tip_theme_tips }} tips)</td></tr>
							<tr><td class="text-right v-mid"><b>Target College</b></td>
									<td>
										<md-input-container class="md-block margin-0x md-no-float" md-no-float>
											<md-select name="college" ng-model="ctrl.batch.college_id" placeholder="— select college —" ng-required="true" md-no-asterisk="true">
												<!-- <md-option ng-value="undefined">&nbsp;<i>— none —</i>&nbsp;</md-option> -->
												<md-option ng-repeat="item in ctrl.mapping.mycolleges" ng-value="item._id">{{ item.name }}</md-option>
											</md-select>
										</md-input-container>
							</td></tr>
						</tbody>
					</table>
				</div>
				<div id="error-table" class="padding-4x can-scroll-x animate-fade" ng-if="ctrl.errors.length > 0" toggle-expand="ctrl.errors._$expanded">
					<md-card md-theme="::'danger'" class="margin-0x" style="border-bottom-left-radius: 0px">
						<md-card-content layout ng-click="ctrl.errors._$expanded = !ctrl.errors._$expanded">
							<span><span class="fas fa-exclamation-triangle"></span>&nbsp;&nbsp;</span>
							<span flex>Found {{ctrl.errors.length}} Error{{ctrl.errors.length>1?'s':''}}</span>
							<span class="fas fa-chevron-up" ng-class="{toggled:!ctrl.errors._$expanded}"></span>
						</md-card-content>
					</md-card>
					<table class="table table-striped md-body-1 expandable" style="width:100%; height:0xp; border-left:3px solid transparent;" 
						md-colors="::{'border-left-color': 'danger-background-800'}">
						<tr>
							<th>Notification ID</th>
							<th style="width:33%">Error Message</th>
							<th>Row</th>
							<th>Column</th>
							<th style="width:33%">Value</th>
						</tr>
						<tr class="text-center" ng-repeat="err in ctrl.errors">
							<td>{{ err.id < 0 ? 'new' : err.id }}</td>
							<td class="text-left">{{ err.message }}</td>
							<td>{{ err.index!=undefined ? err.index+1 : '' }}</td>
							<td>{{ err.key }}</td>
							<td>{{ err.val }}</td>
						</tr>
					</table>
				</div>
				<div class="padding-4x can-scroll-x animate-fade" ng-if="ctrl.warnings.length > 0" toggle-expand="ctrl.warnings._$expanded">
					<md-card md-theme="::'warning'" class="margin-0x" style="border-bottom-left-radius: 0px">
						<md-card-content layout ng-click="ctrl.warnings._$expanded = !ctrl.warnings._$expanded">
							<span><span class="fas fa-exclamation-circle"></span>&nbsp;&nbsp;</span>
							<span flex>Found {{ctrl.warnings.length}} Warning{{ctrl.warnings.length>1?'s':''}}</span>
							<span class="fas fa-chevron-up" ng-class="{toggled:!ctrl.warnings._$expanded}"></span>
						</md-card-content>
					</md-card>
					<table class="table table-striped md-body-1 expandable" style="width:100%; height:0px; border-left:3px solid transparent;" 
						md-colors="::{'border-left-color': 'warning-background-800'}">
						<tr>
							<th>Notification ID</th>
							<th style="width:33%">Warning Message</th>
							<th>Row</th>
							<th>Column</th>
							<th style="width:33%">Value</th>
						</tr>
						<tr class="text-center" ng-repeat="err in ctrl.warnings">
							<td>{{ err.id < 0 ? 'new' : err.id }}</td>
							<td class="text-left">{{ err.message }}</td>
							<td>{{ err.index!=undefined ? err.index+1 : '' }}</td>
							<td>{{ err.key }}</td>
							<td>{{ err.val }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>

		<div id="csv-preview" class="layout-column no-animate" ng-show="ctrl.file && ctrl.previewData">
			<div class="panel flex layout-column" md-whiteframe="1" ng-if="ctrl.file && ctrl.previewData">
				<div class="panel-content flex can-scroll-x can-scroll-y">
					<table class="table data-table compact" style="min-width:100%">
						<thead class="sticky">
							<tr>
								<th class=""></th>
								<th class="text-center" ng-repeat="i in ctrl.previewData.headers track by $index">{{:: ctrl.getColumnHeader($index) }}</th>
							</tr>
							<tr>
								<th class="">1</th>
								<th class="text-center" ng-repeat="key in ctrl.previewData.headers track by $index">{{:: key }}</th>
							</tr>
						</thead>
						<tbody>
						<tr ng-repeat="row in ctrl.previewData track by $index" ng-class="::{'red-border': ctrl.hasRowErrors($index)}">
							<th class="text-center sticky">
								<span class="padding-2x">{{:: $index+2 }}</span>
								<div class="error padding-2x md-caption normal-wg" ng-if=":: ctrl.hasRowErrors($index)">
									<em>{{:: ($rowErrors = ctrl.getRowErrors($index)).length }} error{{ $rowErrors.length > 1 ? 's' : '' }}</em>
									<div ng-repeat="err in ctrl.getCellErrors($index, undefined) track by $index">
										<em>{{:: err.message }}</em>
									</div>
								</div>
							</th>
							<td class="margin-0x" ng-repeat="(col, item) in row track by $index" ng-class="::{'red-border': ctrl.hasCellErrors($parent.$index, col)}">
								<div class="padding-2x" ng-bind-html="::item"></div>
								<div class="error padding-2x md-caption" ng-if=":: ($cellErrors = ctrl.getCellErrors($parent.$index, col)).length > 0">
									<div ng-repeat="err in $cellErrors track by $index">&bull;<em>{{:: err.message }}</em></div>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		
		<div class="panel panel-small flex-gt-sm-none" md-whiteframe="1">
			<div class="panel-title padding-4x">
				<h4 class="md-title normal-wg margin-0x">CSV Templates</h4>
			</div>
			<div class="panel-content">
				<md-list class="links" flex>
					<md-list-item class="md-2-line" ng-repeat="tpl in ctrl.templates" ng-href="{{:: tpl.url }}" download="{{:: tpl.filename }}" target="_blank">
						<div class="md-list-item-text">
							<i class="fas fa-download"></i> {{:: tpl.label }}
						</div>
					</md-list-item>
				</md-list>
			</div>
		</div>
	</main>

	<div ng-show="ctrl.isBusy" class="busy-overlay fade-in fade-out flex layout-row layout-align-center-center">
		<md-progress-circular md-mode="indeterminate"></md-progress-circular>
	</div>

	<footer class="layout-row layout-align-end-start md-whiteframe-2dp" ng-hide="ctrl.isBusy || ctrl.errors.length > 0 || !ctrl.batch.length">
		<md-button type="submit" class="md-raised md-primary">
			<span class="far fa-save"></span> Upload as Draft
		</md-button>
	</footer>
</form>
