<header id="district-list-header" class="md-whiteframe-2dp flex-none flex-order--5">
	<form name="headerForm" novalidate ng-model-options="{updateOn:'default blur', debounce:{'default':1000, 'blur':0}}">
		<div class="layout-gt-sm-row layout-align-gt-sm-space-between stretch">
			<h2 id="pageTitle" class="md-headline flex-auto">Districts</h2>
			<i class="flex"></i>

			<div class="flex-100 flex-gt-sm-50 layout-row">
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SEARCH -->
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERBTN -->

				<md-sidenav class="filters-nav md-sidenav-right layout-column layout-gt-sm-row layout-gt-sm-wrap layout-align-start-stretch" 
					md-component-id="filters-nav" md-is-locked-open="mainCtrl.$mdMedia('gt-sm')" ng-cloak>
					<b class="md-headline hide-gt-sm">Filters &amp; Sort</b>
					
					<div class="make-right layout-xs-column layout-sm-column">
						<span class="flex-gt-md"></span>
						<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SORTS -->
					</div>
				</md-sidenav>
			</div>
		</div>
	</form>
</header>

<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_ERROR -->
<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

<footer class="md-whiteframe-2dp layout-row layout-align-start-center layout-wrap">
	<md-button class="md-icon-button md-mini md-primary" 
		aria-label="Refresh results" title="Refresh results"
		ng-click="ctrl.refresh(true)">
		<span class="fas fa-sync-alt"></span>
	</md-button>
	<div id="footerResults" class="md-body-1">
		{{ ctrl.query.total }} <span class="hide-xs">Results</span>
	</div>
	<span flex></span>
	
	<md-button class="md-primary" 
		aria-label="add new district"
		ui-sref=".add"
		ng-if=":: mainCtrl.hasPermission(PERMISSIONS.CREATE_DISTRICTS)">
		<span class="fas fa-plus"></span> 
    <span class="hide-xs">Add</span> New
	</md-button>
	<md-button id="footerExport" class="md-accent" 
		aria-labelledby="footerExport footerResults"
		ng-click="ctrl.exportCSV('districts.csv')">
		<span class="fas fa-file-export"></span> 
    Export <span class="hide-xs">CSV</span>
	</md-button>
</footer>

<main class="main-list districts-list flex flex-order--1 layout-column"
	aria-labelledby="pageTitle footerResults">
	<md-virtual-repeat-container class="data-list flex no-animate" 
		md-top-index="ctrl.itemIndex" 
		ng-if="ctrl.dataList.length > 0">
		<div id="item-{{ item._id }}" class="data-item district-item layout-column no-animate" ng-class="{loading: !item}" 
			md-virtual-repeat="item in ctrl" md-on-demand="true">
			<div class="layout-row flex" 
				role="group" aria-label="district ID: #{{item._id}}"
				ui-sref="app.districts.edit({id: item._id})" ng-include="::'/main/districts/district.item.tpl'"></div>
		</div>
	</md-virtual-repeat-container>

	<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_EMPTY_RESULTS -->
</main>


<script type="text/ng-template" id="/main/districts/district.item.tpl">
	<div class="flex layout-row layout-wrap">
		<div class="data-id flex-xs-100 flex-sm-100 padding-2x layout-column layout-align-center-start layout-align-gt-sm-start-end">
			<a class="md-caption md-block" 
				aria-label="district ID: #{{item._id}}"
				ui-sref="app.districts.edit({id: item._id})">ID#{{ item._id }}</a>
		</div>
		<div class="data-content flex padding-2x flex layout-column layout-align-start-stretch">
			<h3 class="md-subhead">
				<a class="md-truncate" ui-sref="app.districts.edit({id: item._id})">{{ item.name }}</a>
			</h3>

			<div class="item-description md-truncate">{{ ctrl.mapping.types[item.type] }}</div>
		</div>
		<div class="flex-none layout-row padding-1x">
			<md-button class="go-edit flex md-block layout-row layout-align-center-center" 
				aria-label="open district: {{item.name}}"
				ui-sref="app.districts.edit({id: item._id})">
				&rsaquo;
			</md-button>
		</div>
</script>