import angular from 'angular'
import $ from 'jquery'
import moment from 'moment';
import {Helper, ApiError, SETTINGS, CONSTANTS} from '../../common'
import BaseRecipientSingleController from '../base.recipient.single'

const RESOURCETYPE = {
	IMG: 'task_image',
	IMG_SMALL: 'task_image_small',
	VID: 'task_video',
	VID_WITH_NARRATION: 'task_video_with_narration',
	VID_THUMB: 'task_video_thumbnail',
	CONGRATS_IMG: 'task_congrats_image',
	STEP_CONGRATS_IMG: 'task_step_congrats_image',
};

const FIELDMAP = require('./fieldmap.json');

const IMG_DIR = require('../../common/images/MyC3-0_Nav_Profile_Default.svg');
const IMG_HOM = require('../../common/images/MyC3-0_Nav_Home_Active.svg');
const IMG_SAVE = require('../../common/images/save-icon.svg');
const IMG_SHARE = require('../../common/images/share-icon.svg');
const IMG_FLOW = require('../../common/images/flow-icon.svg');


const HTML_TEXT = CONSTANTS.CONTENT_TYPE.HTML;
const PLAIN_TEXT = CONSTANTS.CONTENT_TYPE.TEXT;

export const BGCOLORS = [
	{value:'#00A39C', label:'academics'},
	{value:'#519CA7', label:'career & network'},
	{value:'#66594D', label:'from Beyond 12'},
	{value:'#7A99AC', label:'meet your coaches'},
	{value:'#29A0A1', label:'wellbeing'},
	{value:'#70797D', label:'how to college'},
	{value:'#2AA575', label:'finances'},
	{value:'#54A84E', label:'transition to college'},
	{value:'#66594D', label:'everything else'},
	{value:'#B98B0E', label:'check in'},
	{value:'#7EAB27', label:'student spotlight'},
];



export default class MilestoneSingleController extends BaseRecipientSingleController {
	static get $inject(){return BaseRecipientSingleController.$inject.concat([
		'clone',
		'parentStateParams',
		'authorization',
		'$mdSidenav',
		'$mdMedia',
		'$timeout',
		'$sce',
		'$window',
		'$document',
	])}

	static get DEFAULTS(){return {
		description: {content: HTML_TEXT},
		congrats: {},
		content_directory: {deadline:{}},
		recipients: [],
		steps: {},
		notifications: {},
		last_step_id: 0,
		last_notif_id: 0,
		video: {},
	}}

	static get STEP_DEFAULTS(){return {
		description: {content: HTML_TEXT},
		deadline: {},
		congrats: {},
		links: [],
	}}


	init(){
		this.mapping = {
			validations: Helper.superMap({
					none: this.MAPPINGS_JSON.tasks.validation.none,
					qr: this.MAPPINGS_JSON.tasks.validation.qr,
					date_selection: this.MAPPINGS_JSON.tasks.validation.date_selection,
					question: this.MAPPINGS_JSON.tasks.validation.question,
				}, {type:'validation'}),
			deadlines: Helper.superMap({
				none: this.MAPPINGS_JSON.tasks.deadline.none,
				soft: this.MAPPINGS_JSON.tasks.deadline.soft,
				hard: this.MAPPINGS_JSON.tasks.deadline.hard,
			}, {type:'deadline'}),
			bgColors: this.MAPPINGS_JSON.content?.directory?.card?.colors || BGCOLORS,
		};
		this.DEFAULT_BGCOLOR = '#29A0A1';
		this.$scope.HTML_TEXT = HTML_TEXT;
		this.$scope.PLAIN_TEXT = PLAIN_TEXT;
		
		this.preview = {auto: true, screen:'home'};
    	this.bgColorsTemp;

		this.IMG_DIR = IMG_DIR;
		this.IMG_HOM = IMG_HOM;
		this.IMG_SHARE = IMG_SHARE;
		this.IMG_SAVE = IMG_SAVE;
		this.IMG_FLOW = IMG_FLOW;
		this.background_color_label;

		this.minDate = moment().add(1, 'd').startOf('day').toDate();
		
		this._destructors.push(
			this.$scope?.$once(CONSTANTS.SCOPE_EVENTS.DATA_READY, ()=>{
				this.onDetailsChanged();
				$('form').on('focus', 'input, textarea', ev=>{
					let value = $(ev.target).parents().filter('[scroll-spy-target]').attr('scroll-spy-target');
					if ( value ) this.updatePreview(value);
				});
			}),

			this.$scope?.$once(CONSTANTS.SCOPE_EVENTS.DEPS_LOADED, ()=>{
				if ( this.clone ) {
					this.model = this.createModel(this.clone);
					['_id', 'batch_id', 'created_by_user_id', 'created_date', 'release_date'].forEach(k=>delete this.model[k]);
					this.promptExit.enable(this.$state.current.name);
					
					// copy clone into data temporarily
					this.data = this.clone;
					// delete data later
					this.$scope.$once(CONSTANTS.SCOPE_EVENTS.DATA_READY, ()=>this.$timeout(()=>delete this.data, 0));

				} else {
					this.model = this.createModel(this.data ?? {});
				}
				this.onDescriptionChange(this.model.description);
				this.model.steps.forEach((step)=>this.onDescriptionChange(step.description));
			}),
		);

		super.init();
	}
	_loadDependencies(){
		return this.apiMap.getTags().then(data=>this.mapping.flutterTags = data)
			.then(()=>super._loadDependencies());
	}


	createModel(data={}){
		const model = angular.extend({}, MilestoneSingleController.DEFAULTS, Helper.deepCopy(data));

		if ( this.clone && model.owner_college_id && !this.mapping.mycolleges.byId[model.owner_college_id] )
			model.owner_college_id = null;
		// BTC-508 default to 1 college administered or only if b12 is one of colleges
		if ( ! model.owner_college_id ) {
			if ( this.mapping.mycolleges.length == 1 ) {
				model.owner_college_id = this.mapping.mycolleges[0]._id;
			} else
			if ( this.mapping.mycolleges.byId[this.BEYOND12_ID] ) {
				model.owner_college_id = this.BEYOND12_ID;
			}
		}

		if ( model.description.formatted && model.description.content != CONSTANTS.CONTENT_TYPE.HTML ) {
			model.description.content = CONSTANTS.CONTENT_TYPE.HTML;
		} else 
		if ( data.description && (! model.description.formatted || ! model.description.content) ) {
			model.description.content = CONSTANTS.CONTENT_TYPE.TEXT;
			model.description.formatted = model.description.raw;
		}
		
		let cd = model.content_directory;
		model.content_directory = {
			title: cd.title,
			release_date: Helper.getValidISODate(cd.release_date) || null,
			deadline: {
				date: Helper.getValidISODate(cd.deadline.date) || null,
				deadline_type: cd.deadline.deadline_type || 'none'
			},
			background_color: cd.background_color || undefined,
			tags: Object.keys(cd.tags || {}),
			image_url: (this.$state.params.uploads || []).find(file=>file.resourceType==RESOURCETYPE.IMG) || cd.image_url || undefined,
			image_small_url: (this.$state.params.uploads || []).find(file=>file.resourceType==RESOURCETYPE.IMG_SMALL) || cd.image_small_url || undefined,
			image_alt_text: cd.image_alt_text || undefined,
		}

		if ( cd.background_color ) {
			this.background_color_label = cd.background_label ? cd.background_label : this.getLabelFromColor(cd.background_color);
			this.updateColor(cd.background_color);
      		this.getColorMapping(cd.background_color);
		}

		model.content_directory._$title = cd.title && Helper.hasLangTags(cd.title) ? this.$sce.trustAsHtml(Helper.convertToLangSpan(cd.title)) : undefined; 
		
		model.last_step_id = model.last_step_id || 0;
		

		model.video = {
			url: (this.$state.params.uploads || []).find(file=>file.resourceType==RESOURCETYPE.VID) || model.video.url || undefined,
			url_with_narration: (this.$state.params.uploads || []).find(file=>file.resourceType==RESOURCETYPE.VID) || model.video.url_with_narration || undefined,
			thumbnail_url: (this.$state.params.uploads || []).find(file=>file.resourceType==RESOURCETYPE.VID_THUMB) || model.video.thumbnail_url || undefined,
			thumbnail_alt_text: model.video.thumbnail_alt_text || undefined,
		}
		
		model.congrats = {
			message: model.congrats.message,
			subtitle: model.congrats.subtitle || undefined,
			image_url: (this.$state.params.uploads || []).find(file=>file.resourceType==RESOURCETYPE.CONGRATS_IMG) || model.congrats.image_url || undefined,
			image_alt_text: model.congrats.image_alt_text || undefined,			
		};

		model.congrats._$message = model.congrats.message && Helper.hasLangTags(model.congrats.message) ? this.$sce.trustAsHtml(Helper.convertToLangSpan(model.congrats.message)) : undefined; 
		model.congrats._$subtitle = model.congrats.subtitle && Helper.hasLangTags(model.congrats.subtitle) ? this.$sce.trustAsHtml(Helper.convertToLangSpan(model.congrats.subtitle)) : undefined; 

		model.steps = Object.keys(model.steps || {}).map((id, index)=>{
			let step = angular.extend({}, MilestoneSingleController.STEP_DEFAULTS, Helper.deepCopy(model.steps[id]));
			step._id = +id;

			step.title = step.title || undefined;
			step._$title = this.toSafeLangHtml(step.title);

			if ( ! model.last_step_id || model.last_step_id < step._id )
				model.last_step_id = step._id;


			step.description = {
				content: step.description.content || HTML_TEXT,
				formatted: step.description.formatted || step.description.raw || undefined,
				raw: step.description.raw || Helper.htmlToText(step.description.formatted)
			}
			
			step.deadline = step.deadline || {};
			step._$previewDeadline = step.deadline.date;
			step.deadline.date = step.deadline.date && moment(step.deadline.date).isValid() ?  Helper.getValidISODate(step.deadline.date) : null;
			step.deadline.deadline_type = step.deadline.deadline_type || 'soft';

			if ( ! model.last_deadline || model.last_deadline < step.deadline.date )
				model.last_deadline = step.deadline.date;

			if ( step.validation === 'question' ) {
				step.questions = Object.values(step.questions || {});
				step.questions.forEach((question)=>{
					question.answers = Object.values(question.answers || {});
					if ( question.answers.length === 0 )
						question.answers.push({});
				});
			}

			step.links = Object.values(step.links || {});

			step.congrats = step.congrats || {};
			step.congrats.message = step.congrats.message || undefined;
			step.congrats.subtitle = step.congrats.subtitle || undefined,
			step.congrats.image_url = (this.$state.params.uploads || []).find(file=>file.stepID==step._id && file.resourceType==RESOURCETYPE.CONGRATS_IMG) || step.congrats.image_url || undefined;
			step.congrats.image_alt_text = step.congrats.image_alt_text || undefined;
			
			step.congrats._$message = step.congrats?.message && Helper.hasLangTags(step.congrats?.message) ? this.$sce.trustAsHtml(Helper.convertToLangSpan(step.congrats?.message)) : undefined;
			step.congrats._$subtitle = step.congrats?.subtitle && Helper.hasLangTags(step.congrats?.subtitle) ? this.$sce.trustAsHtml(Helper.convertToLangSpan(step.congrats?.subtitle)) : undefined;

			return step;
		});

		model.last_notif_id = model.last_notif_id || 0;
		model.notifications = Object.keys(model.notifications || {}).map(id=>{
			let notif = model.notifications[id];
			notif._id = +id;
			notif.date_to_send = notif.date_to_send && moment(notif.date_to_send).isValid() ? moment(notif.date_to_send) : null;
			if ( notif.date_to_send && notif.date_to_send.isSameOrBefore(this.minDate, 'day') && !this.clone ) 
				notif._locked = true; 
			return notif;
		});

		return model;
	}


	async _submit($evt, form){
		await this._validateForm(form);
		await this._moreValidation();

		await this._validateRecipients({
			title: 'Task for everyone?',
			word: 'Make the Task available',
		});

		this.isBusy = true;
		
		const payload = this._preparePayload();
		let result, toastMsg;

		if ( this.data ) {
			result = await this.api.put(`tasks/${this.data._id}`, payload);
			await this._uploadFiles(this.data._id, payload);
			toastMsg = 'Task Updated';
		} else {
			await this.api.post('tasks', payload).then(res=>this.data = res.data);
			result = await this.api.get(`tasks/${this.data._id}`);
			this.data = result.data;
			await this._uploadFiles(result.data._id, this.data);
			toastMsg = 'New Task Saved';
		}
		this.toast.success(toastMsg);

		this.promptExit.disable();
		if ( ['app.milestones.add', 'app.milestones.edit'].includes(this.$state.current.name) ) {
			this.$state.go('^', this.data && this.parentStateParams || {});
		}
		this.$scope?.$emit('milestone.submitted', result.data._id);
	}
	_moreValidation(){
		let hasStepDeadline = !!this.model.steps.find(step=>!!step.deadline.date);

		if ( !hasStepDeadline && (this.model.content_directory.deadline.deadline_type=='none' || !this.model.content_directory.deadline.date) ) {
			let $el = $();
			if ( this.model.content_directory.deadline.deadline_type=='none' || !this.model.content_directory.deadline.deadline_type )
				$el = $el.add('md-select[ng-model="ctrl.model.content_directory.deadline.deadline_type"]');
			else
				$el = $el.add('input[ng-model="ctrl.model.content_directory.deadline.date"]');
			
			if ( $el.length > 0 ) {
				let el = $el.get(0);
				Helper.smoothScrollTo(el, null, ()=>el.focus());
			}
			let msg;
			if ( this.model.steps.length==0 )
				msg = 'Deadline must be set if there are no steps.';
			else
				msg = 'Deadline must be set in either task or a step.';

			return this.$q.when(this.$mdDialog.show(
				this.$mdDialog.alert()
						.title('Deadline required')
						.textContent(msg)
						.ariaLabel('alert')
						.ok('Ok')
			)).then(()=>{
				$el.click();
				throw false
			});
		}
		// if ( this.model.steps.length == 0 ) {
		// 	let alertStr = 'Milestone must have atleast one step.';
		// 	return this.$q.when(this.$mdDialog.show(
		// 		this.$mdDialog.alert()
		// 				.title('Cannot Save')
		// 				.textContent(alertStr)
		// 				.ariaLabel('alert')
		// 				.ok('Ok')
		// 	)).then(()=>{
		// 		this.openStep({}, -1);
		// 		throw false;
		// 	});
		// }

		return this.$q.when(true);
	}
	_preparePayload(){
		let payload = Helper.deepCopy(this.data||{});
		let model = Helper.deepCopy(this.model);

		this._filesToUpload = [];

		// build recipients
		payload.recipients = Helper.mergeObjects(model.recipients.map((group, index)=>{
			return {['recipient_group_'+ (index+1)]: group.expr};
		}));

		payload.ancestry = payload.ancestry || SETTINGS.apiAncestry;
		payload.owner_college_id = model.owner_college_id;

		// payload.content_directory = {};
		let pcd = payload.content_directory = {};
		let mcd = model.content_directory;

		pcd.title = mcd.title;
		pcd.release_date =  mcd.release_date ? moment(mcd.release_date).format('YYYY-MM-DD') : undefined;
		pcd.background_color = mcd.background_color ? mcd.background_color : undefined; 
		pcd.background_label = mcd.background_label ? mcd.background_label : undefined;

		pcd.tags = {};
		(mcd.tags || []).forEach(key=>pcd.tags[key]={});
		pcd.deadline = {};
		pcd.deadline.date = mcd.deadline.date ? Helper.toNoonISO(mcd.deadline.date) : undefined;
		pcd.deadline.deadline_type = mcd.deadline.deadline_type || undefined;

		if ( model.description.content  == CONSTANTS.CONTENT_TYPE.HTML ) {
			payload.description = model.description.formatted ? {
				content: model.description.content,
				raw: Helper.htmlToText(model.description.formatted),
				formatted: model.description.formatted
			} : undefined;
		} else {
			payload.description = model.description.formatted ? {
				content: model.description.content || CONSTANTS.CONTENT_TYPE.TEXT,
				raw: model.description.formatted,
				formatted: undefined
			} : undefined;
		}

		payload.congrats = {
			message: model.congrats.message,
			subtitle: model.congrats.subtitle || undefined
		}
	
		// payload.congrats_text = model.congrats_text || undefined;

		payload.video = {
			url: model.video?.url || undefined,
			url_with_narration: model.video?.url_with_narration || undefined,
			thumbnail_url: model.video?.thumbnail_url || undefined,
			thumbnail_alt_text: model.video?.thumbnail_alt_text || undefined,
		};

		if ( this.model.video.file ) {
			let file = this.model.video.file;
			file.modelPath = 'video.url';
			file.resourceType = RESOURCETYPE.VID;
			this._filesToUpload.push(file);
		}
		if ( this.model.video_with_narration?.file ) {
			let file = this.model.video_with_narration.file;
			file.modelPath = 'video.url_with_narration';
			file.resourceType = RESOURCETYPE.VID;
			this._filesToUpload.push(file);
		}
		if ( this.model.video.thumbnail_file ) {
			let file = this.model.video.thumbnail_file;
			file.modelPath = 'video.thumbnail_url';
			file.resourceType = RESOURCETYPE.VID_THUMB;
			this._filesToUpload.push(file);
		}

		pcd.image_url = mcd.image_url || undefined;
		pcd.image_alt_text = mcd.image_alt_text;
		if ( this.model.content_directory.image_file ) {
			let file = this.model.content_directory.image_file;
			file.modelPath = 'content_directory.image_url';
			file.resourceType = RESOURCETYPE.IMG;
			this._filesToUpload.push(file);
		}
		
		payload.congrats.image_url = model.congrats.image_url || undefined;
		payload.congrats.image_alt_text = model.congrats.image_alt_text || undefined;
		if ( this.model.congrats.image_file ) {
			let file = this.model.congrats.image_file;
			file.modelPath = 'congrats.image_url';
			file.resourceType = RESOURCETYPE.CONGRATS_IMG;
			this._filesToUpload.push(file);
		}
	
		payload.atomic = !! model.atomic || !model.steps.length;
		payload.task_points = /^\d+$/.test(model.task_points) ? +model.task_points : undefined;


		payload.first_deadline = undefined;
		payload.last_deadline = undefined;

		payload.steps = Helper.mergeObjects(model.steps.map((step, index)=>{
			step = Helper.deepCopy(step);

			if ( step.validation === 'question' ) {
				step.questions = Helper.mergeObjects(step.questions.map((question, index)=>({[index+1]: question})));
			} else 
			if ( step.questions ) {
				delete step.questions;
			}

			let desc = model.steps[index].description;
			if ( desc.content  == CONSTANTS.CONTENT_TYPE.HTML ) {
				step.description = {
					content: desc.content,
					raw: Helper.htmlToText(desc.formatted),
					formatted: desc.formatted,
				};
			} else {
				step.description = {
					content: desc.content || CONSTANTS.CONTENT_TYPE.TEXT,
					raw: desc.formatted,
					formatted: undefined,
				};
			}

			step.points = /^\d+$/.test(step.points) ? +step.points : undefined;

			step.deadline = {
				date: step.deadline.date ? Helper.toNoonISO(step.deadline.date) : undefined,
				deadline_type: step.deadline.deadline_type || 'none'
			};

			if ( step.deadline.date ) {
				if ( ! payload.first_deadline || step.deadline.date < payload.first_deadline )
					payload.first_deadline = step.deadline.date;
				if ( ! payload.last_deadline || step.deadline.date > payload.last_deadline )
					payload.last_deadline = step.deadline.date;
			} else {
				// step.deadline_type = 'none';
			}

			step.links = Object.values(step.links).map(link=>{
				if ( ! link.url || ! link.text )
					return null;
				return {url: link.url.replace(/\s/g, '%20'), text: link.text};
			}).filter(Helper.trueishFilter);

			let congrats = model.steps[index].congrats;
			step.congrats = congrats && (congrats.message || congrats.subtitle || congrats.image_url) ? {
				message: congrats.message,
				subtitle: congrats.subtitle || undefined,
				image_url: congrats.image_url || undefined,
				image_alt_text: congrats.image_alt_text || undefined,
			} : undefined;

			
			if ( this.model.steps[index].congrats.image_file ) {
				let file = this.model.steps[index].congrats.image_file;
				file.stepID = step._id;
				file.modelPath = `steps.${step._id}.congrats.image_url`;
				file.metadata = [`step_id:${step._id}`];
				file.resourceType = RESOURCETYPE.STEP_CONGRATS_IMG;
				this._filesToUpload.push(file);
				delete step.congrats.image_file; // step obj is cloned, remove temp file
			}

			if ( step._$previewDeadline ) {
				delete step._$previewDeadline;
			}

			let id = step._id;
			delete step._id;
			Object.keys(step).forEach(key=>{
				if ( /^_\$/.test(key) ) 
					delete step[key];
			});

			return {[id]: step};
		}));
		payload.last_step_id = model.last_step_id;

		payload.points = this.calculateTotalPoints();


		payload.last_notif_id = model.last_notif_id;
		if ( model.notifications.length > 0 )
			payload.notifications = Helper.mergeObjects(model.notifications.map((notif, index)=>{
				let obj = Object.create(null);
				obj.text = notif.text;
				obj.date_to_send = notif.date_to_send ? Helper.toNoonISO(notif.date_to_send) : null;
				return {[notif._id]: obj};
			}));


		if ( this.data ) {
			payload.modified_by_user_id = +this.authorization.userId;
			payload.modified_date = Helper.toTimestamp(new Date());
		} else {
			payload.created_by_user_id = +this.authorization.userId;
			payload.created_date = Helper.toTimestamp(new Date());
		}

		if ( payload.first_deadline == undefined ) {
			payload.first_deadline = pcd.deadline.date;
			payload.last_deadline = pcd.deadline.date;
		}		

		return payload;
	}

	_uploadFiles(id, payload){
		if ( ! this._filesToUpload.length )
			return this.$q.when(true);
		
		let successes = 0, failures = 0;
		let promises = this._filesToUpload.map(file=>{
			let cfg = {
					params: {
						resourceType: file.resourceType, 
						contentType: file.type,
						metadata: (file.metadata || []).concat('task_id:'+id),
					},
					level: ApiError.LEVEL.MANUAL,
				};
			console.info('uploading', file);

			return this.api.upload('upload', {file}, cfg)
				.then(res=>{
					successes++;
					Helper.setCrawlValue(payload, file.modelPath.split('.'), Helper.encodeSafeURL(res.data.url));
					console.info('upload success', file, res.data.url);
				})
				.catch(err=>{
					failures++;
					file.error = err;
					console.info('upload failed', file, err);
					throw err;
				});
		});

		return this.$q.all(promises)
			.then(()=>{
				console.info('upload complete', successes, failures);

				if ( successes > 0 ) { // any success, update resource
					console.info('updating resource');
					return this.api.put(`tasks/${id}`, payload)
						.then(()=>{ if ( failures > 0 ) throw null; })
				} else
				if ( failures > 0 ) {
					throw null;
				}
			})
			.catch(()=>{
				let files = this._filesToUpload.filter(file=>file.error);
				let err = new Error('Task saved but some file(s) failed to upload.');
				err.name = 'Upload Error';
				err.debug = files.map(file=>file.name +' '+ (file.error.debug || (file.error.code +' '+ file.error.message))).join('\n\r');
				
				files.forEach(file=>delete file.error);
				if ( ! this.data ) { // from add page, move to edit page
					this.promptExit.disable();
					this.$state.go('app.tasks.edit', {id, uploads:this.uploads})
						.then(()=>this.errorPrompt.show(err));
					throw null;
				}

				throw err;
			});
	}


	delete($ev){
		this.$mdDialog.show(
			this.$mdDialog.confirm()
					.title('Are you sure you want to delete this task?')
					.ariaLabel('confirm delete')
					.targetEvent($ev)
					.ok('Delete')
					.cancel('Cancel')
		).then(()=>{
			this.isBusy = true;
			return this.api.delete('tasks/'+ this.data._id);
		})
		.then(()=>{
			this.promptExit.disable();
			this.toast.success(`Deleted Task #${this.data._id}`);
			let backParams = this.session.get('milestones') || {};
			this.$state.go('^', backParams);
		})
		.catch(()=>this.isBusy = false);
	}


	onDescriptionChange(desc){
		if ( desc.content == HTML_TEXT )
			desc.html = desc.formatted?.replace(/<a href=\"(.*?)\".*?>/gm, '<a href="$1" target="_blank" title="$1" ref="nofollow">') || '';
		else
			desc.html = Helper.plainLinksToHtml(Helper.encodeHtml(desc.formatted || ''));
		desc.raw = Helper.decodeHtml(Helper.stripTags(Helper.plainLinksToHtml(desc.formatted || '', '$1')));
	}

	switchContentType(obj, key, value, context){
		if ( obj[context] && value == PLAIN_TEXT && obj[key] == HTML_TEXT ) {
			return this.$q.when(this.$mdDialog.show(
				this.$mdDialog.confirm()
						.title('Plain Text Editor')
						.textContent('Existing formatting will be removed when switching to plain text.\n\nContinue?')
						.ariaLabel('alert')
						.ok('Yes')
						.cancel('No')
			)).then(()=>obj[key] = value);
		} else {
			obj[key] = value;
		}
	}


	openStep(step, $index, skipFocus){
		if ( ! step ) {
			step = Helper.deepCopy(MilestoneSingleController.STEP_DEFAULTS);

			if ( this.model.steps.length >= 9 ) {
				return this.$mdDialog.show(
					this.$mdDialog.alert()
							.title('Reached maximum number of steps')
							.textContent('Cannot add another step')
							.ariaLabel('alert')
							.ok('Ok')
				)
			}
			step._id = ++this.model.last_step_id;
			this.model.steps.push(step);
			$index = this.model.steps.length -1;
			this.$scope?.form?.$setDirty();
			return this.$timeout(()=>this.openStep(step, $index), 250);
		}

		if ( step._$open )
			return this.closeStep(step)
				.then(()=>this.openStep(step, $index))
				.finally(()=>$('[scroll-spy]').trigger('scroll'));
		
		step._$open = true;

		let deadline = step.deadline || {};
		deadline.date = deadline.date && moment(deadline.date).isValid() ? moment(deadline.date) : undefined;
		deadline.deadline_type = deadline.deadline_type || 'none';

		if ( ! skipFocus )
		this.$timeout(()=>{try {
			let ctrls = this.$scope?.form?.['step-'+ $index]?.$$controls;
			if ( ctrls && ctrls.length > 0 ) {
				// $(ctrls[0].$$element).focus();
				Helper.smoothScrollTo(ctrls[0].$$element, null, ()=>$(ctrls[0].$$element).focus());
				this.updatePreview('step-'+ $index, true);
			}
		} catch(e){}}, 500)
		.finally(()=>this.$scope && this.$scope.$emit('scroll-spy-update') || $('[scroll-spy]').trigger('scroll'));
	}
	closeStep(step, confirm){
		if ( confirm ) {
			return this.$mdDialog.show(
				this.$mdDialog.confirm()
						.title('Discard step changes?')
						.htmlContent('Are you sure you want to discard your changes to this step?')
						.ariaLabel('confirm')
						.ok('Ok')
						.cancel('Cancel')
			).then(()=>{
				step._$open = false;
				return true;
			}, ()=>false);
		} else {
			if ( step._$open ) {
				step._$open = false;
				return this.$timeout(()=>true, 500)
					.finally(()=>$('[scroll-spy]').trigger('scroll'));
			} else {
				return this.$q.when(true);
			}
		}
	}

	validateStep(stepForm){
		stepForm.$$controls.forEach(ctrl=>{
			ctrl.$setTouched();
			ctrl.$validate();
		});
		let keys = Object.keys(stepForm.$error);
		if ( keys.length > 0 ) {
			let errs = stepForm.$error[keys[0]];
			if ( errs.length > 0 && errs[0].$$element ) {
				// $(errs[0].$$element).focus();
				let el = errs[0].$$element;
				if ( el.offsetParent === null )
					el = el.parentElement;
				Helper.smoothScrollTo(el, null, ()=>$(el).focus());
			}
		}
		// this assumes that validation is not async
		return stepForm.$valid;
	}

	saveStep(step){
		let deadline = step.deadline.date ? Helper.toTimestamp(step.deadline.date) : null;
		if ( step.deadline.date && (! this.model.last_deadline || this.model.last_deadline < deadline) )
			this.model.last_deadline = deadline;
		
		this.closeStep(step);
	}
	removeStep($index, $ev){
		var confirm = this.$mdDialog.confirm()
					.title('Remove Step')
					.textContent('Are you sure you want to remove this step?')
					.ariaLabel('Remove Step')
					.targetEvent($ev)
					.ok('Ok')
					.cancel('Cancel');

		this.$mdDialog.show(confirm).then(()=>{
			this.$scope.form.$setDirty();
			this.closeStep(this.model.steps[$index], false)
				.then(()=>this.model.steps.splice($index, 1));
		}, ()=>{});
		$ev.stopPropagation();
	}

	addStepQuestionAnswer(step, question){
		let list = question.answers;
		list.push({});

		let i = this.model.steps.indexOf(step);
		this.$timeout(()=>$(`fieldset[ng-form="step-${i}"] [name="step_answer_check_${list.length-1}"]`).focus(), 100);
	}

	addStepLink(step){
		step.links.push({});

		let i = this.model.steps.indexOf(step);
		this.$timeout(()=>$(`fieldset[ng-form="step-${i}"] input[name="step_link_${step.links.length-1}"]`).focus(), 100);
	}

	addNotif(){
		let list = this.model.notifications;
		list.push({_id: ++this.model.last_notif_id});
		this.$scope.form.$setDirty();

		this.$timeout(()=>{
			let input = $(`fieldset[ng-form="notif-${list.length -1}"] input[name="date_to_send"]`)
			input.focus();
			Helper.smoothScrollTo(input);
		}, 100);
	}
	removeNotif($index, $ev){
		var confirm = this.$mdDialog.confirm()
					.title('Remove Notification')
					.textContent('Are you sure you want to remove this notification?')
					.ariaLabel('Remove Notification')
					.targetEvent($ev)
					.ok('Ok')
					.cancel('Cancel');

		this.$mdDialog.show(confirm).then(()=>{
			this.$scope.form.$setDirty();
			this.model.notifications.splice($index, 1);
		}, ()=>{});
		$ev.stopPropagation();
	}


	updateStepValidationField(step){
		if ( step.validation === 'question' ) {
			if ( ! step.questions ) {
				let question = {question:'', answers:[{text:'', correct:true}, {text:''}]};
				step.questions = [question];
			}
		} else {
			if ( step.questions )
				delete step.questions;
		}
	}
	updateStepQuestionAnswer(question, $control){
		if ( $control ) { 
			if ( ! $control.$validators.nocorrect )
				$control.$validators.nocorrect = (modelValue, viewValue)=>{
					return !!question.answers.find((answer)=>answer.correct);
				};
			$control.$validate();
		}
	}
	calculateTotalPoints(){
		return this.model.points = this.model.steps.reduce((total,step)=>total+(isNaN(step.points) ? 0 : +step.points), this.model.task_points || 0) || undefined;
	}

	updateColor (color) {
		if ( color ) {
			let [r, g, b] = color.match(/\w\w/g).map(x => parseInt(x, 16));
			return this._$secondaryColor = `rgba(${r},${g},${b},0.25)`;
		} else {
			return this._$secondaryColor = '#D4ECEC';
		}
	}

	reportScroll(value, old){
		console.log('active', value);
		if ( this.preview.auto )
			this.updatePreview(value, true);
	}
	updatePreview(action, auto){
		let stepRegEx = /^step(?:-(\d+))?(-congrats)?/;

		// if ( ! auto )
		// 	this.preview.auto = false;


		switch( action ) {
			case 'details':
				this.preview.screen = null;
				this.preview.step = this.preview.stepIndex = undefined;
				this.preview.showCongrats = false;
				this.preview.showValidation = false;
				auto && setTimeout(()=>$('#task-preview .task-content').animate({scrollTop:0}, 500), 100);
				break;
			case 'home':
				this.preview.screen = 'home';
				this.preview.step = this.preview.stepIndex = undefined;
				this.preview.showCongrats = false;
				this.preview.showValidation = false;
				auto && setTimeout(()=>$('#task-preview .task-content').animate({scrollTop:0}, 500), 100);
				break;
			case 'congrats':
				this.preview.screen = 'congrats';
				this.preview.showCongrats = true;
				this.preview.showValidation = false;
				this.preview.step = this.preview.stepIndex = undefined;
				this.preview.congratsMsg = this.toSafeLangHtml(this.model.congrats?.message);
				this.preview.congratsSubtitle = this.toSafeLangHtml(this.model.congrats?.subtitle);
				break;
			default:
				const stepRegEx = /^step(?:-(\d+))?-?(done|validation|congrats)?/;
				if ( action && stepRegEx.test(action) ) {
					const match = action.match(stepRegEx);
					const index = parseInt(match[1]);
					const step = this.model.steps[index];

					step._$title = this.toSafeLangHtml(step.title);
					if ( step.questions != undefined ) {
						step.questions[0]._$question = this.toSafeLangHtml(step.questions[0].question);
						step.questions[0].answers.map((ans, index) => {
							if ( ans.text && Helper.hasLangTags(ans.text) ) {
								ans._$text = this.toSafeLangHtml(ans.text);
							}
						});
					}
					step._$validationInstructions = this.toSafeLangHtml(step.validation_instructions);
				 
					if ( step && (step._$open || !auto) ) {
						this.preview.screen = 'step';
						this.preview.step = step;
						this.preview.stepIndex = index;
						this.preview.showCongrats = false;

						const hasValidation = !['none','qr'].includes(step.validation);
						if ( (match[2] == 'done' || match[2] == 'validation') && hasValidation ) {
							this.preview.screen = 'step';
							this.preview.showValidation = true;
							this.preview.showCongrats = false;
						} else
						if ( match[2] == 'done' || match[2] == 'congrats' ) {
							if ( hasValidation ) {
								this.preview.screen = 'validation';
								this.preview.showCongrats = true;
								this.preview.showValidation = false;
							} else {
								this.preview.screen = 'step-congrats';
								this.preview.showCongrats = true;
								this.preview.showValidation = false;
							}
							this.preview.congratsMsg = this.toSafeLangHtml(step.congrats?.message);
							this.preview.congratsSubtitle = this.toSafeLangHtml(step.congrats?.subtitle);
						}
					} else {
						this.preview.screen = null;
						this.preview.step = this.preview.stepIndex = undefined;
						auto && setTimeout(()=>$('#task-preview .task-content').animate({scrollTop:$('#task-preview .task-content .steps').get(0).offsetTop}, 500), 100);
					}
				} 
		}
		this.preview.action = action;
	}

	getLabel() {
		return this.background_color_label ? this.background_color_label : 'FROM BEYOND12';
	}
  
	mappingHasColor(color) {
		let mapping = this.mapping.bgColors;
		let obj = mapping.filter((key) => key.value === color);
		if ( obj.length == 0 || obj == undefined ) {
		return false;
		} else {
		return true;
		}
	}

	getColorMapping(color) {
		if ( this.mappingHasColor(color) == false ) {
		let colorObj = {label: null, value: color},
			mapping = [...this.mapping.bgColors];
		let obj = mapping.filter((key) => key.value === color);
		if ( obj.length == 0 ) {
			mapping.push(colorObj);
			console.log(mapping);
			this.bgColorsTemp = mapping;
		}
		} else {
		return;
		}
	}

	// returns the label for the given color
	getLabelFromColor(color) {
		let mapping = this.mapping.bgColors;
		let obj = mapping.find((key) => key.value === color);
		return obj ? obj.label : null;
	}

	// returns the color for the given label
	getColorFromLabel(label) {
		let mapping = this.mapping.bgColors;
		let obj = mapping.find((item) => item.label === label); 
		return obj ? obj.value : null;
	}

	onChange(color_label) {
		this.model.content_directory.background_label = color_label;
		let color = this.getColorFromLabel(color_label);
		this.model.content_directory.background_color = color;
		this.updateColor(color)
	}

	onDetailsChanged() {
		const cd = this.model.content_directory;
		const congrats = this.model.congrats;

		let html = Helper.convertToLangSpan(cd.title);
		this.preview.title = Helper.stripOtherTags(html, 'span', Helper.encodeHtml)
		this.preview.title_clean = Helper.stripTags(html);

		this.preview.congratsMsg = this.toSafeLangHtml(congrats.message);
		this.preview.congratsSubtitle = this.toSafeLangHtml(congrats.subtitle);
		
		// this.model.content_directory._$title = title && Helper.hasLangTags(title) ? this.$sce.trustAsHtml(Helper.convertToLangSpan(title)) : undefined;
		// this.model.congrats._$message = congratsMsg && Helper.hasLangTags(congratsMsg) ? this.$sce.trustAsHtml(Helper.convertToLangSpan(congratsMsg)) : undefined;
		// this.model.congrats._$subtitle = congratsSub && Helper.hasLangTags(congratsSub) ? this.$sce.trustAsHtml(Helper.convertToLangSpan(congratsSub)) : undefined;
	}
}
