import angular from 'angular'
import $ from 'jquery'
import BaseList from '../base.list'
import {Helper} from '../../common'


export default class DistrictsListController extends BaseList {
	static get $inject(){return [
		'api',
		'apiMap',
		'toast',
		'MAPPINGS_JSON',
		'authentication',
		'$stateParams',
		'$window',
	].concat(BaseList.$inject)}

	init(){
		this.mapping = {
			types: Helper.deepCopy(this.MAPPINGS_JSON.colleges.type_key),
			sortBy: Helper.superMap({
				id:'ID', 
				name:'Name',
				type:'Type', 
			}),
			sortOrder: Helper.superMap({asc:'Asc', desc:'Desc'}),
		};


		super.init();


		this.query = angular.extend({
				searchString: '', 
				sortBy: 'id',
				sortOrder: 'desc',
			}, this.query);
	}
	_loadDependencies(){
		this.$scope.$once('deps-loaded', ()=>{
			this.onUrlParamChange(this.$state.params);
			this.refresh(true)
				.then(()=>this._loadScrollState());
		});
		
		return super._loadDependencies();
	}

	refresh(reset){
		let params = angular.extend({
				searchString: this.query.search,
				sortBy: this.query.sortBy,
				sortOrder: this.query.sortOrder,
				limit: this.query.limit,
				offset: this.query.offset = (reset ? 0 : this.query.offset),
			}, {
				withMetadata:true, 
				myTokenAdministers:true
			});

		if ( reset ) this.dataList = [];

		return this._refresh('districts', params)
	}

	process(list){ return list; }


	_open(item){
		this.selected = item;

		if ( item.colleges )
			return this.$q.resolve({data: item.colleges});

		item.isBusy = true;

		return this.api.get('colleges', {districts:[item._id]}, {cache: true})
	}

	showColleges(item){
		if ( item._$pendingColleges ) return;

		if ( item._colleges ) {
			item._$open = !item._$open;

		} else {
			item._$pendingColleges = this.api.get('colleges', {districts:[item._id]}, {cache: true})
				.then((res)=>{
					item._colleges = res.data;
					item._$open = true;
				}, (err)=>{
					if ( this.api.isApiError(err) ) {
						err.name = 'College List Unavailable';
						this.toast.error(err);
					}
				})
				.finally(()=>{
					delete item._$pendingColleges;
				});
		}
	}

}