Recipient Group,College Owner,ID,Atomic,Title,Release Date,Deadline,Deadline Type,Description,Formatted Description,Description Content Type,Completion Headline,Completion Subtitle,Completion Image URL,Completion Image Alt Text,Video URL,Video with Narration URL,Video Thumbnail URL,Video Thumbnail Alt Text,Image URL,Image Alt Text,Background Color,Tags,Points
"""(college=130)""",166,1,FALSE,"Get your Associate of Arts, Transfer Degree",2022-06-01,,None,,<p>The Associate of Arts degree is designed for students who plan to transfer to a four-year college or university for a baccalaureate degree.</p><p>See what requirements you need to fulfill below.</p>,HTML,Congratulations on earning your Associate of Arts Degree!,This is great!,https://www.freepnglogos.com/uploads/globe-png/blue-globe-transparent-sporetesting-39.png,Globe Image,https://www.youtube.com/watch?v=gNU9hJObq-c,,https://www.designfreelogoonline.com/wp-content/uploads/2018/04/000970-3D-earth-globe-logo-maker-04.png,Globe Image,,,#00A39C,,,
,,Step ID,Step Title,Step Description,Step Formatted Description,Step Description Content Type,Step Deadline,Step Deadline Type,Step Validation,Step Validation Instructions,Step Question,Step Answers,Step Correct Answers,Step Links,Step Completion Headline,Step Completion Subtitle,Step Completion Image URL,Step Completion Image Alt Text,Step Points,
,,1,English (3-6 units),Must include at last ENG 102 to ENG 114. You can also add a class below.,,text,2023-01-31,Soft,None,,,,,,Congratulations!,You did it!,,10,
,,2,Fine Arts (3 units),Take at least one Fine Arts class. You can also add the class below.,,text,2023-01-31,Hard,None,,,,,,Keep it up!,,,,10,
,,3,Humanities (3 units),Take at least one Humanities class. You can also add the class below.,,text,2023-01-31,Soft,None,,,,,,Nice!,,,,10,
,,4,Science (Lab Required) (6 units),Take at least one Science class WITH lab. You can add the classes below.,,text,2023-01-31,Hard,None,,,,,,Excellent!,,,,10,
,,5,Social Science (3 units),Take at least one social science class. You can add the class below.,,text,2023-01-31,Soft,None,,,,,,Wonderful!,,,,10,
,,6,Diversity [3],"You can also use these classes to satisfy General Education, Degree/Emphasis, or Elective requirement.  Add the class below.",,text,2023-01-31,Soft,None,,,,,,Great!,,,,,
,,7,Foreign Language (0-14 units),"Options to Complete the Foreign Language Requirement:
Complete a fourth-semester transferable college course in a foreign language.
Complete a fourth-semester transferable college course in American Sign Language (AM).
Demonstrate proficiency through placement examination or other means. Students interested in taking a placement examination should contact the TMCC testing center at 775-673-8241 for information.
Complete four years of high school foreign language.",,text,2023-01-31,Soft,None,,,,,,Almost there!,Keep on going!,,,,
,,8,U.S. and Nevada Constitutions (3 units),Take at least one of these. Add the class below.,,text,2023-01-31,Hard,None,,,,,,Just one more!,,,,,
,,9,ELECTIVES: (22-36 units),"If you know your major, print the NSHE Transfer Agreements online.  You can add your electives below.",,text,2023-01-31,Soft,None,,,,,,Congrats! You're done!,,,,10,
,,,,,,,,,,,,,,,,,,,
,,Notification ID,Text,Date to Send,,,,,,,,,,,,,,,
,,1,Get ready!,2023-01-01,,,,,,,,,,,,,,,
,,2,Be prepared,2023-01-05,,,,,,,,,,,,,,,
,,3,Be awesome,2023-01-10,,,,,,,,,,,,,,,
,,,,,,,,,,,,,,,,,,,
"""all""",166,2,FALSE,EOPS/CARE,2022-10-01,2023-02-07,Hard,The Extended Opportunity and Services program is for students who are economically and educationally disadvantaged. Here you can find a variety of student services including financial aid help and education counseling.,,text,Woohoo - you've officially completed your EOPS milestone and are on the road for success. Good job!,This is great!,https://www.freepnglogos.com/uploads/globe-png/blue-globe-transparent-sporetesting-39.png,Globe Image,https://storage.googleapis.com/mycoach-content/badges/1000014/video/07eba932-623a-421b-b885-abaf645a510e.mp4,,https://purepng.com/public/uploads/large/globe-w6x.png,Globe Image,https://freepngimg.com/thumb/globe/40583-4-earth-globe-free-clipart-hq.png,Globe Image,#2AA575,,20,
,,Step ID,Step Title,Step Description,Step Formatted Description,Step Description Content Type,Step Deadline,Step Deadline Type,Step Validation,Step Validation Instructions,Step Question,Step Answers,Step Correct Answers,Step Links,Step Completion Headline,Step Completion Subtitle,Step Completion Image URL,Step Completion Image Alt Text,Step Points,
,,1,Attend an EOPS Information Session,Come by and learn more about the EOPS / CARE program! Here you can find out everything you need to know about eligibility and speak to an EOPS counselor.,,text,2023-02-02,Hard,None,,,,,,Well done!,,ttps://freepngimg.com/thumb/globe/40583-4-earth-globe-free-clipart-hq.png,Globe Image,10,
,,2,Complete your first EOPS appointment,Schedule an appointment with EOPS to lock down this first meeting and get your progress report going!,,text,2023-02-03,Soft,None,,,,,,Keep it up!,,,,10,
,,3,Update your progress report,Create or finalize your progress report with EOPS. Here you can ensure you're staying on track to succeed!,,text,2023-02-04,Hard,None,,,,,,Good job!,,,,5,
,,4,Attend second EOPS appointment,Complete your second appointment to update your Ed Plan and review your progress this semester.,,text,2023-02-05,Soft,None,,,,,,Congratulations,,,,10,
,,5,Attend third EOPS appointment,This it the last appointment you need to go to. This will help finalize your path at EVC and make sure you are on track!,,text,2023-02-06,hard,None,,,,,,Congrats!,,,,10,
,,,,,,,,,,,,,,,,,,,
"""((college=130) AND (cohort=level_prospective))""",166,3,FALSE,Become a Husky!,2022-11-15,2023-01-01,soft,Enrolling at ELAC is your first step of your college career! Follow these 4 steps to become an official Husky!,,text,You stepped into success!,You did it! You are now a student at ELAC and are an official college student!,https://www.freepnglogos.com/uploads/globe-png/blue-globe-transparent-sporetesting-39.png,Globe Image,https://storage.googleapis.com/mycoach-content/badges/1000014/video/07eba932-623a-421b-b885-abaf645a510e.mp4,,https://freepngimg.com/thumb/globe/5-2-globe-png-image-thumb.png,Globe Image,,,#B261F4,,,
,,,,,,,,,,,,,,,,,,,
"""((college=130) AND (cohort=level_continuing))""",166,4,FALSE,Get Closer to a Job,2022-11-01,2023-12-02,soft,Get ready to be the top candidate in your job search by creating a resume and practicing interviews,,text,Congratulations! Now you're ready to GET THAT JOB you want!,This is great!,https://www.freepnglogos.com/uploads/globe-png/blue-globe-transparent-sporetesting-39.png,Globe Image,https://www.youtube.com/watch?v=gNU9hJObq-c,,https://www.pngkey.com/png/full/136-1369833_one-of-the-best-ways-to-build-awareness.png,Globe Image,,,#C181F6,,,
,,Step ID,Step Title,Step Description,Step Formatted Description,Step Description Content Type,Step Deadline,Step Deadline Type,Step Validation,Step Validation Instructions,Step Question,Step Answers,Step Correct Answers,Step Links,Step Completion Headline,Step Completion Subtitle,Step Completion Image URL,Step Completion Image Alt Text,Step Points,
,,1,Attend a job skills workshop to Get Closer to a Job,"An abundance of resources are available. You can get help with navigating occupations, internships, job search skills, interviewing skills, resume help and more.",,text,,None,None,,,,,,Congrats!,,,,5,
,,2,Create your resume to Get Closer to a Job,Go to a walk-in workshop if you need assistance creating your resume.,,text,2023-04-01,Hard,None,,,,,,Good job!,,,,5,
,,3,Make an appointment to practice interviews to Get Closer to a Job,Meet with your counselor to practice interview skills and learn what potential employers are looking for.,,text,2023-04-02,Soft,None,,,,,,Well done!,,,,5,
,,,,,,,,,,,,,,,,,,,
"""((college=130) AND (cohort=level_new))""",166,5,TRUE,New Student Success,2022-08-01,2023-10-01,Hard,,"<p><b>Follow these three steps to get your footing at LACC!</b> Completing these 4 steps and get your Ed Plan, and the classes you need.</p>",HTML,Congratulations! You've officially set yourself up for success at Timbuktu!,This is great!,https://www.freepnglogos.com/uploads/globe-png/blue-globe-transparent-sporetesting-39.png,Globe Image,https://www.youtube.com/watch?v=gNU9hJObq-c,,https://purepng.com/public/uploads/large/globe-w6x.png,Globe Image,https://freepngimg.com/thumb/globe/40493-1-earth-globe-photos-png-image-high-quality-thumb.png,Globe Image,,,,
,,Step ID,Step Title,Step Description,Step Formatted Description,Step Description Content Type,Step Deadline,Step Deadline Type,Step Validation,Step Validation Instructions,Step Question,Step Answers,Step Correct Answers,Step Links,Step Completion Headline,Step Completion Subtitle,Step Completion Image URL,Step Completion Image Alt Text,Step Points,
,,1,Take an Assessment,Take a look at the sample tests and then complete the real thing at the Assessment Office. This will help plan your course load at school.,,text,,,None,,,,,,Congratulations!,You did it!,,,5,
,,2,Complete your Orientation,"Complete your orientation online so you can best understand campus policies, resources, and registration details.",,text,,,None,,,,,,Congrats!,,,,10,
,,3,See a Counselor,This involves first semester planning. You can do this in person at the counseling office.,,text,,,None,,,,,,Good job!,,,,10,
,,,,,,,,,,,,,,,,,,,
all,166,,,Enroll in Math 101,2023-01-01,,None,Enroll in Math 101,,text,You've just completed a KEY step to transfer! CONGRATULATIONS!!!!,,,,,,,,,20
,,Step ID,Step Title,Step Description,Step Formatted Description,Step Description Content Type,Step Deadline,Step Deadline Type,Step Validation,Step Validation Instructions,Step Question,Step Answers,Step Correct Answers,Step Links,Step Completion Headline,Step Completion Subtitle,Step Completion Image URL,Step Completion Image Alt Text,Step Points,
,,1,Complete Online Orientation,,<p>All first-time college students must complete a freshman online orientation. Orientation will help you learn about all the services and educational programs at your college; provides you with information on registration; and help you prepare for the assessment placement exam.</p>,HTML,2023-10-30,soft,Question,,"From the online orientation video, what is the most important lesson you learned?","don't skip class!
don't miss your deadlines!
all of the above!","false
false
true","Complete your Online Orientation Now
http://www.norcocollege.edu/services/admissions/pages/orientation.aspx
google
https://google.com
facebook
https://facebook.com","Good job, you got online oriented!",,,,20,
,,2,SCHEDULE your appointment at the Assessment Center,,<p>This test helps Norco place you in the right level Math and English.</p><p>It's super important you study -- students who place into college-level english and math courses finish school faster!</p>,HTML,2023-10-30,soft,Date Selection,,,,,,Good job!,,,,10,
,,3,STUDY FOR YOUR ASSESSMENT TEST!,Students who place into college-level English and Math finish school faster!!,,plain text,2023-10-30,soft,Date Selection,,,,,,Good job!,,,,10,
,,4,Schedule an appointment with a counselor,,"<p><b>DON?T TAKE THE WRONG CLASSES!!</b></p><p>Creating an educational plan will help you identify the best way to earn a certificate, degree, or transfer!  It will help you figure out what classes you need to take, and when they are offered.</p>",HTML,2023-11-05,soft,Date Selection,This is an instruction message.,,,,,Good job!,,,,20,
,,5,Print out a sample Ed Plan,Print out a sample Ed Plan,,Text,2023-11-05,soft,Date Selection,,,,,,Good job!,,,,10,
,,6,Meet with your counselor,Meet with your counselor,,plain text,2023-11-05,soft,Date Selection,When did you meet your counselor,,,,,Good job!,,,,20,
