import DistrictsListController from './districts.list.controller'
import DistrictSingleController from './district.single.controller'
import {PERMISSIONS, PERMISSION_LEVELS} from '../../common'
import './districts.scss'


export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {

	const $stateRegistry = $uiRouterProvider.stateRegistry;

	$stateRegistry.register({
		name: 'app.districts',
		title: 'Districts',
		url: 'districts?searchString&sortBy&sortOrder',
		access: {[PERMISSIONS.READ_DISTRICTS]: [PERMISSION_LEVELS.ALL]},
		views: {
			'content@app': {
				template: require('./districts.list.html'),
				controller: DistrictsListController,
				controllerAs: 'ctrl',
			},
		},
		dynamic: true,
		params: {
			nonce: 0,
			scroll:       {value: 0, inherit:false, dynamic:true},
			searchString: {type:'query', inherit:false},
			sortBy:       {type:'query', inherit:false},
			sortOrder:    {type:'query', inherit:false},
		},
	});


	let districtSingleHtml = require('./district.single.html');

	$stateRegistry.register({
		name: 'app.districts.add',
		title: 'Add District',
		url: '/add',
		access: {[PERMISSIONS.CREATE_DISTRICTS]: true},
		views: {
			'content@app': {
				template: districtSingleHtml,
				controller: DistrictSingleController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'data',
				deps: [],
				useFactory: ()=>null,
		}, {
				provide: 'parentStateParams',
				deps: [],
				useFactory: ()=>({}),
		}],
	});

	$stateRegistry.register({
		name: 'app.districts.edit',
		title: ['data', data=>'District: '+ data.name],
		url: '/{id:int}',
		access: {[PERMISSIONS.UPDATE_DISTRICTS]: true},
		views: {
			'content@app': {
				template: districtSingleHtml,
				controller: DistrictSingleController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'data',
				deps: ['api', '$state', '$stateParams', '$timeout'],
				useFactory: (api, $state, $stateParams, $timeout)=>api.get('districts/'+ $stateParams.id)
					.then((res)=>res.data, (err)=>{
						if ( api.isApiError(err) ) {
							let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
							return $state.go(state, {error: err}, {location:false});
						}
						throw err;
					}),
		}, {
				provide: 'parentStateParams',
				deps: [],
				useFactory: ()=>({}),
		}],
	});

}];
