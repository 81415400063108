<header id="flows-list-header" class="md-whiteframe-2dp flex-none flex-order--5">
	<form name="headerForm" novalidate ng-model-options="{updateOn:'default blur', debounce:{'default':500, 'blur':0}}">
		<div class="layout-row layout-align-start-center layout-align-gt-sm-space-between-stretch layout-wrap">
			<h2 id="pageTitle" class="md-headline flex">Flows</h2>

			<div class="flex-100 flex-gt-sm-50 layout-row">

				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SEARCH -->
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERBTN -->
			</div>
		</div>
		
		<div class="filter-group md-chips md-removable">
			<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERCHIPS -->
			
			<div class="filters-nav-wrap flex">
				<md-sidenav class="filters-nav md-sidenav-right flex layout-column layout-gt-sm-row layout-gt-sm-wrap layout-align-start-stretch" 

					role="group" aria-labelledby="rightSideNav"
					md-component-id="filters-nav" md-is-locked-open="mainCtrl.$mdMedia('gt-sm')" ng-cloak>
					<b id="rightSideNav" class="md-headline hide-gt-sm">Filters &amp; Sort</b>

					<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERS -->

					<div class="make-right layout-xs-column layout-sm-column layout-gt-sm-row layout-wrap">

						<span class="flex-gt-md"></span>
						<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERDATES -->
						<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SORTS -->
					</div>
					
				</md-sidenav>
			</div>
		</div>
	</form>
</header>

<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_ERROR -->
<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

<footer class="md-whiteframe-2dp layout-row layout-align-start-center layout-wrap">
	<md-button class="md-icon-button md-mini md-primary" 
		aria-label="Refresh results" title="Refresh results"
		ng-click="ctrl.refresh(true)">
		<span class="fas fa-sync-alt"></span>
	</md-button>
	<div id="footerResults" class="md-body-1" ng-hide="ctrl.query.view=='calendar'">
		{{ ctrl.query.total }} <span class="hide-xs">Results</span>
	</div>
	<span flex></span>
	
	<md-button class="md-primary" 
		aria-label="add new flow"
		ui-sref=".add" 
		ng-if=":: mainCtrl.hasPermission(PERMISSIONS.CREATE_FLOW_CONFIGURATIONS)">
		<span class="fas fa-plus"></span> 
		<span class="hide-xs">Add</span> New
	</md-button>
	<!-- <md-button class="md-warn" ng-click="ctrl.exportCSV()" aria-label="export csv">
		<i class="fas fa-file-export"></i> Export <span class="hide-xs">CSV</span>
	</md-button> -->
</footer>

<main class="main-list flows-list flex flex-order--1 layout-column" aria-labelledby="pageTitle footerResults">
	<md-virtual-repeat-container class="data-list flex no-animate"
		md-top-index="ctrl.itemIndex" ng-if="ctrl.dataList.length > 0">
		<div id="item-{{ item._id }}" class="data-item flow-item layout-column no-animate" ng-class="{loading: !item}" 
			md-virtual-repeat="item in ctrl" md-on-demand="true">
			<div class="layout-row flex" 
				role="group" aria-label="flow id: #{{item._id}}"
				ui-sref="app.flows.edit({id: item._id})" ng-include="::'/main/flows/flow.item.tpl'"></div>
		</div>
	</md-virtual-repeat-container>
	<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_EMPTY_RESULTS -->
</main>


<script type="text/ng-template" id="/main/flows/flow.item.tpl">

	<div class="flex layout-row layout-wrap" ng-class="item.publish?'published':'unpublished'">
		<div class="data-id flex-xs-100 flex-sm-100 layout-row layout-align-space-between-center layout-wrap layout-gt-sm-column layout-align-gt-sm-space-between-end padding-2x">
			<div class="date">
				<span ng-show="item.release_day || item.deadline_day" class="no-wrap" title="Content directory {{ item.release_day ? 'release' : 'deadline' }}">
					<i class="fas" ng-class="item.release_day ? 'fa-calendar-day' : item.deadline_day ? 'fa-stopwatch' : null" role="img" title="{{ item.release_day ? 'release' : 'deadline' }}"></i> 
					{{ item.release_day || item.deadline_day }}
				</span>
				<span ng-show="item.date_full" title="Scheduled">
					<i class="black-50 fas fa-calendar-alt" role="img" title="Scheduled"></i>
					<span class="no-wrap">{{ item.date_day }}</span>
					<span class="no-wrap">{{ item.date_time }}</span>
				</span>
				<span ng-show="item.fire_on_registration" title="{{ item.fire_on_registration }}">
					<i class="fas fa-bolt" role="img" title="{{ item.fire_on_registration }}"></i> On Student Register
				</span>
			</div>
			<!-- <div hide-xs hide-sm flex-gt-sm></div> -->

			<a class="md-caption"
				aria-label="flow ID #{{item._id}}"
				ui-sref="app.flows.edit({id: item._id})">ID#{{ item._id }}</a>
			<!-- <div hide-xs hide-sm flex-gt-sm></div>	 -->
		
			<div class="flex-xs-100">

				<span class="small-tag md-caption type" ng-class="item.type">{{ ctrl.mapping.flowTypes.byType[item.type].name }}</span>
				<span class="small-tag md-caption status" ng-class="item.publish? 'published':'unpublished'">
					<i class="fas" ng-class="item.publish ? 'fa-check' : 'fa-times'" aria-hidden="true"></i> {{ ctrl.mapping.publish.byId[''+item.publish].name }}
				</span>
			</div>
			<!-- <div hide-xs hide-sm flex-gt-sm></div> -->

			<div class="flex-xs-100 flex-sm-100 item-owner md-caption text-right md-truncate">
				<span ng-show="item.created_by_user_id || item.modified_by_user_id">
					{{ item.modified_by_user_id ? 'Updated' : 'Created' }} by

					<span title="{{ ctrl.mapping.users.byId[item.modified_by_user_id || item.created_by_user_id].user_profile.first_name || item.modified_by_user_id || item.created_by_user_id }}">
						{{ ctrl.mapping.users.byId[item.modified_by_user_id || item.created_by_user_id].user_profile.first_name || item.modified_by_user_id || item.created_by_user_id }}

					</span>
					<br class="hide-xs hide-sm" aria-hidden="true">
				</span>
				<span class="no-wrap">{{ (item.modified_date || item.created_date) | amDateFormat:SETTINGS.dateTimeFormat }}</span>

			
			</div>
		</div>

		<div class="data-content flex layout-column layout-align-space-between-stretch padding-2x">
			<h3 class="md-subhead">
				<!-- <em class="md-caption small-tag black-50" ng-show="item.publish === false">draft</em> -->
				<a class="md-truncate" ui-sref="app.flows.edit({id: item._id})" ng-if="item.name.en_US" ng-bind-html="item.name.en_US"></a>
				<em class="black-25" ng-if="item && !item.name.en_US">[Untitled flow #{{ item._id }}]</em>
			</h3>

			<div class="item-description md-truncate" ng-bind-html="item._description"></div>

			
			<span flex></span>
			<div class="layout-row layout-align-space-between-start">
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_ITEM_CHIPS -->
			</div>
		</div>
		<div class="layout-row padding-1x">
			<md-button class="go-edit flex md-block layout-row layout-align-center-center" 
				aria-label="open flow: {{item.name.en_US || ('Untitled flow id '+ item._id)}}"
				ui-sref="app.flows.edit({id: item._id})">
				&rsaquo;
			</md-button>
		</div>
	</div>
</script>