<div class="recipient-group" ng-repeat="group in ctrl.model.recipients track by $index">
	<div class="panel-content margin-4x">
		<div class="layout-row">
			<h3 class="flex md-subhead normal-wg margin-0x">Recipients</h3>
			<div class="flex-nogrow">
				<md-checkbox class="margin-0x hide-disabled-unchecked" aria-label="Advanced Recipient Mode"
					ng-model="group.isRawMode" ng-change="ctrl.onSwitchRecipientMode($event, group)">
					Advanced
				</md-checkbox>
			</div>
		</div>

		<div toggle-expand="group.isRawMode" on-expand="$broadcast('md-resize-textarea')">
			<section class="expandable">
				<div class="md-padding padding-sides-0x ng-animate fade-in fade-out" form-disabled="!group.isRawMode">
					<md-input-container class="md-block"
						ng-model-options="{'*':'$inherit', debounce:{'default':2000, 'blur':0}}">
						<label class="hide">Expression</label>
						<textarea name="{{ 'raw_expr_'+ $index }}" spellcheck="false" aria-label="Recipient Group"
							md-no-resize rows="2" ng-model="group.expr" ng-change="ctrl.onChangeRecipientGroup(group)"
							ng-required="group.isRawMode" valid-recipient recipient-error="group._invalid"></textarea>
						<!-- <raw-recipient ng-model="group.expr" name="{{ 'raw_expr_'+ $index }}" spellcheck="false"></raw-recipient> -->
						<div ng-messages="form['raw_expr_'+ $index].$error" role="alert">
							<div ng-message="required">Required</div>
							<div ng-message="expr">{{ group._invalid || 'Invalid expression' }}</div>
						</div>
					</md-input-container>
				</div>
			</section>
		</div>

		<div toggle-expand="!group.isRawMode">
			<section class="expandable">
				<div flex="100">
					<md-chip-wrap class="md-chips md-removable">
						<md-chip class="no-animate" ng-repeat="item in group.list track by $id(item)"
							ng-class="item.type">
							<div class="md-chip-op" ng-class="ctrl.OP_TO_WORD[item.op]" ng-hide="item.locked">
								<button type="button" ng-click="ctrl.toggleChipOp(item, group)"
									aria-label="toggle {{item.model.title || item.model.name}}">
									<span class="fas" ng-class="'fa-'+ ctrl.OP_TO_WORD[item.op]"></span>
								</button>
							</div>
							<div class="md-chip-content">
								<span ng-bind="item.model.title || item.model.name"></span>
							</div>
							<div class="md-chip-remove-container" ng-hide="item.locked">
								<button type="button" class="md-chip-remove" ng-click="ctrl.removeChip(item, group)"
									aria-label="remove {{item.model.title || item.model.name}}">
									<span class="far fa-times-circle"></span>
								</button>
							</div>
						</md-chip>
					</md-chip-wrap>
					<div layout="column" layout-align="stretch start" ng-hide="ctrl.isLocked">
						<div class="select-wrap" ng-repeat="(type, obj) in ctrl.mapping.recipientTypes"
							ng-if=":: !obj.hide">
							<md-input-container>
								<label class="recipient-label">{{:: obj.label}}</label>
								<md-select data-md-container-class="select-with-search" multiple md-no-asterisk
									md-on-close="ctrl.clearSearchTerm()" placeholder="{{:: obj.label}}"
									ng-model="group.map[type]" ng-disabled="ctrl.isBusy || ctrl.isLocked"
									ng-model-options="{trackBy: '$value._id', inherit:'*'}"
									ng-change="ctrl.syncRecipientGroup(group)"
									ng-init="ctrl._$recipientsSearch[obj.key] = ctrl.querySearch(ctrl._$search, obj.key)">
									<md-select-header>
										<input type="search" class="header-search" autocomplete="on"
											ng-keydown="$event.stopImmediatePropagation()"
											ng-click="$event.stopImmediatePropagation()" aria-label="{{:: obj.label}}"
											ng-model="ctrl._$search"
											ng-change="ctrl._$recipientsSearch[obj.key] = ctrl.querySearch(ctrl._$search, obj.key)">
									</md-select-header>
									<md-option ng-value="value" ng-repeat="value in ctrl._$recipientsSearch[obj.key]">{{
										value.title || value.name }}</md-option>
								</md-select>
							</md-input-container>
						</div>
					</div>
					<md-input-container class="md-inline-form md-block text-right ng-animate fade-in fade-out"
						ng-show="group.map.cohort.length + group.map.level.length > 1">
						<md-radio-group class="flex-nogrow" ng-model="group.isAllCohort"
							ng-change="ctrl.toggleRecipientAll(group)">
							<md-radio-button ng-value="false" class="margin-4x">Any Cohort</md-radio-button>
							<md-radio-button ng-value="true" class="margin-4x">All Cohorts</md-radio-button>
						</md-radio-group>
					</md-input-container>
					<div class="summary md-body-1 black-50">
						<p>I'm one of the recipients.</p>
						<p ng-bind-html="group.summary"></p>
					</div>
				</div>
			</section>
		</div>
	</div>
</div>