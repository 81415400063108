import angular from 'angular'
import difference from 'lodash/difference'
import mapValues from 'lodash/mapValues'
import { Helper, TERMS, OP_TO_WORD, CONSTANTS, PERMISSION_LEVELS } from '../common'
import BaseSingleController from './base.single'

const SCOPE_EVENTS = CONSTANTS.SCOPE_EVENTS;

export default class BaseRecipientSingleController extends BaseSingleController {
	static get $inject() {
		return BaseSingleController.$inject.concat([
			'recipient',
			'authorization',
			'$mdDialog',
			'$timeout',
		]).filter(Helper.uniqueFilter)
	}

	init() {
		this.mapping.myterms = Helper.superMap(angular.extend(TERMS, this.MAPPINGS_JSON.colleges.terms));
		this.mapping.myterms.single = true;
		this.mapping.recipientTypes = {
			'district': { sort: 1, label: 'District', key: 'mydistricts' },
			'college': { sort: 2, label: 'College', key: 'mycolleges' },
			'term': { sort: 3, label: 'Term', key: 'myterms' },
			'cohort': { sort: 4, label: 'Cohort', key: 'mycohorts' },
			'level': { sort: 5, label: 'Level', key: 'mylevels' },
		};

		this.OP_TO_WORD = OP_TO_WORD;

		this.$scope.$once(SCOPE_EVENTS.DATA_READY, () => {
			if (this.data) {
				this.createModelRecipients(this.model, this.data.recipients);
				this._$recipientsSearch = this.createInitialQuery();
			} else {
				this.addRecipientGroup();
			}

			this.$timeout(() => {
				this.$scope?.$broadcast(SCOPE_EVENTS.RECIPIENT_INIT, this.model.recipients);
				this.$scope?.$parent?.$emit(SCOPE_EVENTS.RECIPIENT_INIT, this.model.recipients);
				console.log('emit', SCOPE_EVENTS.RECIPIENT_INIT);
				this.model?.recipients?.forEach(group => this.onChangeRecipientGroup(group));
			}, 5);
		});

		this._destructors.push(
			this.$scope.$parent.$once(CONSTANTS.SCOPE_EVENTS.RECIPIENT_UPDATE, (ev, index, group) => {
				if (!this.model.owner_college_id && group.map.college?.length > 0)
					this.model.owner_college_id = group.map.college[0]._id;
			}),
		);

		super.init();
	}

	_loadDependencies() {
		return this.$q.all([
			super._loadDependencies(),
			this.apiMap.getActiveColleges().then(data => this.mapping.mycolleges = data),
			this.apiMap.getDistricts().then(data => this.mapping.mydistricts = data), // college admin does not show districts
			this.apiMap.getProvidedCohorts().then(data => this.mapping.mycohorts = data),
			this.apiMap.getCohortTypes().then(data => this.mapping.cohortTypes = data),
			this.apiMap.getAllDistricts().then(data => this.mapping.districts = data),
			this.apiMap.getAllProvidedCohorts().then(data => this.mapping.cohorts = data),
			this.apiMap.getAllCohorts().then(data => this.mapping.allcohorts = data),
			this.apiMap.getCohortLevels().then(data => this.mapping.mylevels = data),
		]).then(() => {
			if (!this.hasPermissionLevel(PERMISSION_LEVELS.ALL)) {
				this.mapping.recipientTypes.district.hide = true;
			}

			if (this.mapping.mycolleges.length === 1) {
				this.mapping.recipientTypes.college.hide = true;
				this._defaultTag = this.mapping.mycolleges[0].name;
			}

			return this.recipient.prepare()
				.then(() => this.$scope?.$emit(SCOPE_EVENTS.RECIPIENT_READY));
		});
	}

	hasPermissionLevel(level) {
		return this.authorization.hasStateAccessLevel(this.$state.current.name, level);
	}

	createModelRecipients(model, recipients) {
		model.recipients = Object.values(recipients || {})
			.map(expr => this._parseRecipient(expr))
			.filter((v) => !!v);
		model.recipients.forEach((group) => this.syncRecipientGroup(group));
	}

	_parseRecipient(expr) {
		const ALL_ACCESS = this.hasPermissionLevel(PERMISSION_LEVELS.ALL);
		const result = this.recipient.parse(expr, { ignoreErrors: true, ignoreInvalids: true })

		const list = this.recipient.enumerate();
		const group = {
			list: list.concat(), // copy
			expr: expr,
			isRawMode: this.recipient.isAdvanced(),
			map: mapValues(this.mapping.recipientTypes, () => []), // empty array for each key
		};
		group.isAllCohort = this.recipient.isAllCohort();
		group.summary = this.recipient.summarize(list, group.isAllCohort);

		if (!group.isRawMode) {
			group.list.forEach(item => {
				// use our mapping object instead from recipients svc
				item.model = this.mapping[this.mapping.recipientTypes[item.type]?.key]?.byId?.[item._id];

				if (!item.model)
					return console.debug('missing model', item);

				group.map[item.type].push(item.model);

				if (item.type == 'college' && this.mapping.mycolleges.length == 1) {
					item.locked = true;
				}

				// college admin are not allowed to edit those with district on recipient expr					
				if (item.type == 'district' && !ALL_ACCESS) {
					if (!this.isLocked) {
						this.isLocked = true;
						this.lockReason = lockmsg || 'This may target students outside your college. Please contact MyCoach for more information.';
					}
				}
			});
		}
		return group;
	}

	addRecipientGroup() {
		this.model.recipients.push(this.syncRecipientGroup(this._parseRecipient('')));
	}

	removeRecipientGroup($index, $ev) {
		let confirm = this.$mdDialog.confirm()
			.title('Remove Recipient Group')
			.textContent('Are you sure you want to remove this recipient group?')
			.ariaLabel('Remove Recipient Group')
			.targetEvent($ev)
			.ok('Ok')
			.cancel('Cancel');

		this.$mdDialog.show(confirm).then(() => {
			let group = this.model.recipients[$index];
			this.model.recipients.splice($index, 1);
			this.$scope.$broadcast(SCOPE_EVENTS.RECIPIENT_UPDATE, $index, group);
			this.$scope.$parent?.$emit(SCOPE_EVENTS.RECIPIENT_UPDATE, $index, group);
		}, () => { });
	}

	syncRecipientGroup(group) {
		let buffer = [];
		Object.keys(group.map).forEach(type =>
			group.map[type].forEach(model => {
				let obj = group.list.find(obj => obj._id == model._id && obj.type == type);
				if (!obj) {
					group.list.push(obj = {
						_id: model._id,
						model: model,
						type: type,
						op: '=', // default to equals
					});
				}
				buffer.push(obj);
			})
		);
		// remove what is not in group list
		let diff = difference(group.list, buffer);
		diff.forEach(obj => Helper.arrayFindSplice(group.list, obj));
		return this._syncRecipientGroup(group);
	}
	_syncRecipientGroup(group) {
		if (this.mapping.mycolleges.length == 1 && !group.list.find((item) => item.type == 'college')) {
			let model = this.mapping.mycolleges[0];
			group.list.push({
				_id: model._id,
				model: model,
				type: 'college',
				op: '=',
				locked: true,
			});
		}

		let last = group.expr;
		if (!group.isRawMode) {
			group.expr = this.recipient.stringify(group.list, group.isAllCohort);
			group.summary = this.recipient.summarize(group.list, group.isAllCohort);
			console.log(group.expr);

			group.list.sort((a, b) => {
				const types = this.mapping.recipientTypes;
				if (a.locked) return -1;
				if (b.locked) return 1;
				return types[a.type].sort - types[b.type].sort;
			});
		}

		if (last != group.expr) {
			this.$scope.form?.$setDirty();
			this.$scope.$broadcast(SCOPE_EVENTS.RECIPIENT_UPDATE, this.model.recipients.indexOf(group), group);
			this.$scope.$parent?.$emit(SCOPE_EVENTS.RECIPIENT_UPDATE, this.model.recipients.indexOf(group), group);
		}
		return group;
	}

	onChangeRecipientGroup(group) {
		if (group.isRawMode) // only for advanced, we do not use group.list in adv
			group.list = this.recipient.enumerate(group.expr || '()', { ignoreInvalids: true, ignoreErrors: true });
		this.$scope.$broadcast(SCOPE_EVENTS.RECIPIENT_UPDATE, this.model.recipients.indexOf(group), group);
		this.$scope.$parent?.$emit(SCOPE_EVENTS.RECIPIENT_UPDATE, this.model.recipients.indexOf(group), group);
	};

	createInitialQuery() {
		return {
			'mydistricts': this.mapping.mydistricts,
			'mycolleges': this.mapping.mycolleges,
			'myterms': this.mapping.myterms,
			'mycohorts': this.mapping.mycohorts,
			'mylevels': this.mapping.mylevels,
		};
	}

	// returns matching values for the given key, if the key exactly matches a value it is stored in queryResult
	querySearch(query, key) {
		var result = [];
		if (query) {
			this.mapping[key].forEach(value => {
				if (value.name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
					result.push(value)
				}
			})
		} else {
			return this.mapping[key]
		}
		return result
	};

	clearSearchTerm() {
		console.log(this._$search);
		this._$search = '';
	}

	toggleRecipientAll(group) {
		this._syncRecipientGroup(group);
	}

	toggleChipOp(item, group) {
		item.op = item.op == '=' ? '!=' : '=';
		this._syncRecipientGroup(group);
	}

	removeChip(item, group) {
		const list = group.map[item.type];
		if (Array.isArray(list)) {
			let model = list.find(model => model._id == item.model._id);
			Helper.arrayFindSplice(list, model);
		} else
			if (list == item.model) {
				group.map[item.type] = [];
			}

		Helper.arrayFindSplice(group.list, item);

		this._syncRecipientGroup(group);
	}

	_validateRecipients(opts = {}) {
		const ALL_ACCESS = this.hasPermissionLevel(PERMISSION_LEVELS.ALL);
		let promise = this.$q.resolve(true); // use this to chain promises

		this.model.recipients.forEach((group, index) => {
			// TODO: validation for advanced mode
			if (group.isRawMode) return;

			let list = group.list.concat(); // clone list so we don't affect ui

			if (this.mapping.mycolleges.length === 1) {
				let college = this.mapping.mycolleges[0];
				list.push({
					_id: college._id,
					model: college,
					type: 'college',
					op: '=',
				});
			}

			let colleges = [], districts = [], cohorts = [], terms = [];
			list.forEach(item => {
				switch (item.type) {
					case 'college':
						colleges.push(Helper.encodeHtml(item.model.name));
						break;
					case 'district':
						districts.push(Helper.encodeHtml(item.model.name));
						break;
					case 'cohort':
					case 'level':
						cohorts.push(Helper.encodeHtml(item.model.title || item.model.description || item.model.name));
						break;
					case 'term':
						terms.push(Helper.encodeHtml(item.model.name));
						break;
				}
			});

			if (ALL_ACCESS) {
				if (colleges.length + districts.length + terms.length === 0) {
					let confirmStr = `Recipient Group #${index + 1} does not have any college, district or term set.<br><br>${opts.word || 'Have this'} to everyone`;
					if (cohorts.length > 0) {
						let op = group.isAllCohort ? ' and ' : ' or ';
						if (cohorts.length > 2)
							confirmStr += ' in ' + cohorts.slice(0, -1).join(', ') + op + cohorts[cohorts.length - 1];
						else
							confirmStr += ' in ' + cohorts.join(op);
					}
					promise = promise.then(() => this.$mdDialog.show(
						this.$mdDialog.confirm()
							.title(opts.title || 'For everyone?')
							.htmlContent(confirmStr + '?')
							.ariaLabel('confirm')
							.ok('Ok')
							.cancel('Cancel')
					)).catch(() => { throw 'recipient all cancelled'; });
				}

			} else
				if (colleges.length === 0) {
					let alertStr = `Recipient Group #${index + 1} must have at least one college selected`;
					promise = promise.then(() => this.$mdDialog.show(
						this.$mdDialog.alert()
							.title('Cannot Save')
							.textContent(alertStr)
							.ariaLabel('alert')
							.ok('Ok')
					)).then(() => { throw 'no college cannot save ok'; });
				}
		});

		return promise;
	}

	onSwitchRecipientMode($ev, group) {
		if (group.isRawMode) {
			Object.keys(group.map).forEach(type => group.map[type]?.splice(0)); // clear dropdowns
			group.list = [];
			return;
		}
		if (!group.expr) {
			let idx = this.model.recipients.indexOf(group);
			this.model.recipients[idx] = this._parseRecipient('');
			return;
		}

		// if (0 && !this.recipient.isAdvanced(group.expr)) {
		// 	let p = this.model.recipients.indexOf(group);
		// 	let nGroup = this.model.recipients[p] = this._parseRecipient(group.expr);
		// 	this.$scope.$emit(SCOPE_EVENTS.RECIPIENT_UPDATE, p, nGroup);

		// } else {
		// temporary while asking
		group.isRawMode = true;

		let confirm = this.$mdDialog.confirm()
			.title('Basic Recipient')
			.textContent('Switching to Basic Recipient would lose the current advanced recipient expression.\n\nContinue?')
			.ariaLabel('Switch to Basic Recipient')
			.targetEvent($ev)
			.ok('Ok')
			.cancel('Cancel');

		this.$mdDialog.show(confirm).then(() => {
			Object.keys(group.map).forEach(type => group.map[type]?.splice(0)); // clear dropdowns
			group.list = [];
			group.isRawMode = false;
			group.isAllCohort = false;
			this._syncRecipientGroup(group);
		}, angular.noop);
	}
}