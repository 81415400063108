import angular from 'angular'
import BaseList from '../base.list'
import {ApiError, Helper, PERMISSIONS} from '../../common'


export default class CohortsListController extends BaseList {
	static get $inject(){return [
		'$q',
		'authorization',
		'apiMap',
		'MAPPINGS_JSON',
	].concat(BaseList.$inject)}

	init(){
		this.mapping = {
			sortBy: Helper.superMap({
				id:'ID', 
				description:'Name', 
				cohort_type:'College',
			}),
			sortOrder: Helper.superMap({asc:'Asc', desc:'Desc'}),
		};

		super.init();


		this.$showActions = this.authorization.hasPermission(PERMISSIONS.READ_COHORT_COLLEGE_EXTERNAL_IDS) && this.authorization.hasPermission(PERMISSIONS.UPDATE_COHORT_COLLEGE_EXTERNAL_IDS);

		this.filters._$keys = {
			'mycolleges': 'College',
		};
		this.query = angular.extend({
				sortBy: 'id',
				sortOrder: 'desc',
			}, this.query);
	}
	_loadDependencies(){
		return this.$q.all([
			this.apiMap.getColleges().then((data)=>this.mapping.mycolleges = Helper.superList(Helper.deepCopy(data))),
			this.apiMap.getCohortTypes().then((data)=>this.mapping.cohortTypes = data),
	    this.apiMap.getUsersCollegeAdminUp().then((data)=>this.mapping.users = data),
	    this.apiMap.getUsersCoach().then((data)=>this.mapping.coaches = data),
		]).then(()=>{
			this.mapping.mycolleges.single = true;
			
			this.$scope.$once('deps-loaded', ()=>{
				this.onUrlParamChange(this.$state.params);
				this.refresh(true)
					.then(()=>this._loadScrollState());
			});
			
			return super._loadDependencies();
		});
	}


	getParams(reset){
		return angular.extend({}, super.getParams(reset), {source:'provided_list'});
	}

	refresh(reset){
		if ( reset ) {
			this.dataList = [];
			this.itemIndex = 0;
			this._errors = [];
		}

		return this._refresh('cohorts', this.getParams(reset));
	}

	process(list){
		list.forEach((item)=>{
			const params = {
				cohort: item.name, 
				withMetadata: true,
				offset: 0, 
				limit: 1,
			};

			item.description = item.description || this.MAPPINGS_JSON.cohorts.name_key[item.name] || item.name;
			item._chips = [];

			const cohortType = this.mapping.cohortTypes.byName[item.cohort_type];
			if ( cohortType?.college_relation?.college_id ) {
				params.college = cohortType.college_relation.college_id;

				const college = Helper.deepCopy(this.mapping.mycolleges.byId[cohortType.college_relation.college_id] || null);
				if ( college ) {
					college._$class = 'college';
					college.ariaLabel = 'Associated college: '+ (college.title || college.name || college._id);
					item._chips.push(college);
				}
			}

			if ( item.owner_user_id && APP_BEYOND12_MODE ) {
				let user = item.owner_user = Helper.deepCopy(this.mapping.coaches.byId[item.owner_user_id] || null);
				if ( user ) {
					user.title = user.user_profile.first_name +' '+ user.user_profile.last_name;
					user._$icon = 'fas fa-user-tie';
					user._$class = 'user';
					user.ariaLabel = 'Owner: '+ user.title;
					item._chips.push(user);
				}
			}

			if ( this.$showActions ) {
				this.api.get('cohortCollegeExternalIds', params, {level:ApiError.LEVEL.IGNORED})
					.then(res=>item._$students = +res.data.totalCount);
			}
		});

		return list;
	}

}