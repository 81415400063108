import angular from 'angular'
import $ from 'jquery'
import BaseList from '../base.list'
import {Helper} from '../../common'


export default class CollegesListController extends BaseList {
	static get $inject(){return [
		'$q',
		'api',
		'apiMap',
		'toast',
		'MAPPINGS_JSON',
		'authentication',
		'$stateParams',
		'$window',
	].concat(BaseList.$inject)}


	init(){
		this.mapping = {
			types: Helper.deepCopy(this.MAPPINGS_JSON.colleges.type_key),
			status: Helper.superMap(angular.extend({
				active: 'Active',
				inactive: 'Inactive',
				deleted: 'Deleted',
			}, this.MAPPINGS_JSON.colleges.status_key), {type:'status'}, (list)=>list.single=true),
			test: Helper.superMap({
				'true': 'Test Only',
				'false': 'Non-Test Only',
			}, {type:'test'}, (list)=>list.single=true),
			sortBy: Helper.superMap({
				id:'ID', 
				name:'Name',
				type:'Type', 
			}),
			sortOrder: Helper.superMap({asc:'Asc', desc:'Desc'}),
		};


		super.init();


		this.filters._$keys = {
			'status': 'Status',
			'test': 'Test',
		};
	}
	_loadDependencies(){
		return this.apiMap.getAllDistricts().then(data=>this.mapping.districts = data)
			.then(()=>{
				this.$scope.$once('deps-loaded', ()=>{
					this.onUrlParamChange(this.$state.params);
					this.refresh(true)
						.then(()=>this._loadScrollState());
				});
			});
	}
	getFilters(all){
		return angular.extend(super.getFilters(all), {
			status: this.filters._status && this.filters._status._id || undefined,
			test: this.filters._test && this.filters._test._id || undefined,
		});
	}
	onUrlParamChange(obj){
		if ( obj.hasOwnProperty('status') )
			this.filters._status = this.mapping.status.byId[obj.status] || null;
		if ( obj.hasOwnProperty('test') )
			this.filters._test = this.mapping.test.byId[obj.test] || null;

		let keys = ['status', 'test'];
		return super.onUrlParamChange(obj) || !! Object.keys(obj).find(val=>keys.includes(val));
	}
	refresh(reset){
		if ( reset ) {
			this.dataList = [];
			this.itemIndex = 0;
			this._errors = [];
		}

		return this._refresh('colleges', this.getParams(reset));
	}

	process(list){
		list.forEach((item)=>{
			if ( item.website )
				item._websiteName = Helper.getLinkDomain(item.website);
			(item.campuses||[]).forEach((campus)=>{
				if ( campus.website )
					campus._websiteName = Helper.getLinkDomain(campus.website);
				if ( campus.latitude )
					campus._latitudeStr = (campus.latitude < 0 ? 'S' : 'N') + Math.abs(campus.latitude);
				if ( campus.longitude )
					campus._longitudeStr = (campus.longitude < 0 ? 'W' : 'E') + Math.abs(campus.longitude);
			});
		});

		return list;
	}


	_open(item){
		return this.$q.resolve({data: item.campuses || []});
	}

}