<form name="resetForm" ng-submit="resetForm.$valid && ctrl.submit()" novalidate autocomplete="off" aria-labelledby="h2">
	<h2 id="h2" class="md-display-1">Reset Password</h2>
	<div layout="column" layout-align="center stretch" ng-disabled="ctrl.isBusy">
		<div flex ng-hide="ctrl.success || !ctrl.validToken" layout="column" layout-align="center stretch">
			<md-input-container class="md-input-has-placeholder">
				<label>New Password</label>
				<input type="password" name="password1" ng-model="ctrl.password1" required>
				<div ng-messages="resetForm.password1.$error" role="alert">
					<div ng-message="required">This is required</div>
				</div>
			</md-input-container>
			<md-input-container class="md-input-has-placeholder">
				<label>Confirm Password</label>
				<input type="password" name="password2" ng-model="ctrl.password2" is-equal="{{ctrl.password1}}" required>
				<div ng-messages="resetForm.password2.$error" role="alert">
					<div ng-message="required">This is required</div>
					<div ng-message="isEqual">Passwords must match</div>
				</div>
			</md-input-container>
		</div>
		<p class="md-padding" ng-show="ctrl.success && ctrl.validToken">Your password has been updated. You can now login using your new password.</p>
		<p class="md-padding" ng-hide="ctrl.validToken">Your link is no longer valid or has already expired.</p>
		<div layout="row">
				<a href="https://gradguru.org/start" target="_blank" class="md-primary md-button md-ink-ripple ng-hide" ng-disabled="ctrl.isBusy" ng-show="ctrl.success && ctrl.fromApp" href="/login" tabindex="0">
					Open GradGuru App
				</a>
				<a ui-sref="guest.login" class="margin-2x" ng-disabled="ctrl.isBusy" ng-show="(ctrl.success || !ctrl.validToken) && ctrl.fromConsole && !ctrl.fromApp">
					back to Login
				</a>
				<span flex></span>
				<md-button type="submit" class="md-raised md-primary" ng-disabled="ctrl.isBusy" ng-hide="ctrl.success || !ctrl.validToken">
					<span ng-hide="ctrl.isBusy">Submit</span>
					<center ng-show="ctrl.isBusy"><md-progress-circular md-mode="indeterminate" md-diameter="20px"></md-progress-circular></center>
				</md-button>
		</div>
	</div>
</form>