import angular from 'angular'
import { Helper } from '../../common'
import BaseList from '../base.list'


export default class UsersListController extends BaseList {
	static get $inject(){return [
		'api',
		'apiMap',
		'toast',
		'MAPPINGS_JSON',
		'authentication',
		'$stateParams',
		'$window',
	].concat(BaseList.$inject)}

	init(){
		delete this.MAPPINGS_JSON.roles.role_key['mfa_required_user'];
		
		this.mapping = {
			_$items: {},
			status: Helper.superMap(this.MAPPINGS_JSON.users.status_key, {type:'status'}, (list)=>list.single=true),
			// CON2-767; add coach role until mappings.json does
			roles: Helper.superMap(this.MAPPINGS_JSON.roles.role_key, {type:'roles'}, (list)=>list.single=true),
			typeNames: {
				status: {name:'Status', icon:''},
				roles: {name:'Roles', icon:''},
				attends: {name:'Student Attends', icon:'fas fa-graduation-cap'},
				admins: {name:'Admin of College', icon:''},
			},
			sortBy: Helper.superMap({
				id:'ID', 
				name:'Name', 
			}),
			sortOrder: Helper.superMap({asc:'Asc', desc:'Desc'}),
			roleIcons: {
				student: 'far fa-user',
				college_admin: 'fas fa-user-graduate',
				content_admin: 'fas fa-user-tie',
				superuser: 'fas fa-user-cog',
				legacy: 'far fa-user-circle',
				coach: 'fas fa-chalkboard-teacher',
				coaching_corps_coach: 'fas fa-chalkboard-teacher',
				facebook: 'fab fa-facebook',
				bot: 'fas fa-robot',
			},
		};


		super.init();


		this.filters._$keys = {};
		angular.forEach(this.mapping.typeNames, (item, key)=>{
			this.filters._$keys[key] = item.name;
		});
	}
	_loadDependencies(){
		return this.$q.all([
			this.apiMap.getColleges().then(data=>{
				this.mapping.mycolleges = data;
				this.mapping.attends = Helper.superList(Helper.deepCopy(data));
				this.mapping.admins = Helper.superList(Helper.deepCopy(data));
			}),
			this.apiMap.getCohortCoaches().then(data=>this.mapping.coachCohorts = data),
			this.apiMap.getCohortCorpsCoaches().then(data=>this.mapping.coachCorpsCohorts = data),
		])
			.then(()=>{
				this.onUrlParamChange(this.$state.params);
				this.refresh(true)
					.then(()=>this._loadScrollState());
			});
	}


	getFilters(all){
		return angular.extend(super.getFilters(all), {
			roles: this.filters._roles ? this.filters._roles._id : undefined,
			status: this.filters._status ? this.filters._status._id : undefined,
			studentColleges: (this.filters._attends || []).map((item)=>item._id),
			adminColleges: (this.filters._admins || []).map((item)=>item._id),
		});
	}

	onFiltersChange(refresh){
		if ( this.filters._attends.length > 0 ) {
			this.filters._admins.splice(0);
		} else
		if ( this.filters._admins.length > 0 ) {
			this.filters._attends.splice(0);
		}

		super.onFiltersChange(refresh);
	}
	onUrlParamChange(obj){
		if ( obj.hasOwnProperty('status') )
			this.filters._status = this.mapping.status.byId[obj.status];
		if ( obj.hasOwnProperty('roles') )
			this.filters._roles = this.mapping.roles.byId[obj.roles];
		if ( obj.hasOwnProperty('studentColleges') )
			this.filters._attends = (obj.studentColleges || [])
				.map(id=>this.mapping.attends.byId[id])
				.filter(Helper.trueishFilter);
		if ( obj.hasOwnProperty('adminColleges') )
			this.filters._admins = (obj.adminColleges || [])
				.map(id=>this.mapping.admins.byId[id])
				.filter(Helper.trueishFilter);
		
		const keys = ['status', 'roles', 'studentColleges', 'adminColleges', 'sortBy', 'sortOrder', 'searchString'];
		return super.onUrlParamChange(obj) || !! Object.keys(obj).find(v=>keys.includes(v));
	}


	
	refresh(reset){
		if ( reset ) {
			this.dataList = [];
			this.itemIndex = 0;
			this._errors = [];
		}
		return this._refresh('users', this.getParams(reset));
	}

	process(list){
		list.forEach((user)=>{
			angular.forEach(user.roles, (obj, role)=>{
				let list = user['_colleges_'+ role] = user['_colleges_'+ role] || [];
				angular.forEach(obj.colleges, (val, id)=>{
					list.push( +id );
				});
			});
			user.full_name = [user.user_profile?.first_name, user.user_profile?.last_name].filter(v=>!!v).join(' ') || undefined;

			user.coachCohorts = this.mapping.coachCohorts.filter(cohort=>+cohort.owner_user_id == +user._id);
			this.mapping.coachCorpsCohorts.forEach(cohort=>{
				if ( +cohort.owner_user_id == +user._id )
					user.coachCohorts.push(cohort);
			});
		});

		return list;
	}

}