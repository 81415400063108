import angular from 'angular'
import {Helper, ApiError, MESSAGES} from '../../common'
import BaseSingleController from '../base.single'


export default class ProfileController extends BaseSingleController {
	static get $inject(){return [
		'$mdDialog',
		'$timeout',
	].concat(BaseSingleController.$inject)}


	init(){
		this.mapping = {
			roles: Helper.superMap(this.MAPPINGS_JSON.roles.role_key),
			weekDays: {monday:'Monday', tuesday:'Tuesday', wednesday:'Wednesday', thursday:'Thursday', friday:'Friday', saturday:'Saturday', sunday:'Sunday'},
		};

		this._$mobileRegex = /^(\+\d{1,3})?(\d{10})\b/;
		this.isBusy = false;
		this.pwModel = {};

		super.init();
	}
	_loadDependencies(){
		return this.$q.all([
			super._loadDependencies(), 
			this.apiMap.getColleges().then(data=>this.mapping.mycolleges = data)
		])
			.then(()=>{
				this.createModel(angular.extend({roles:{}, user_profile:{available_hours:{}}}, this.data));
				this.permissions = [];
				angular.forEach(this.model.roles, (item, key)=>this.permissions.push({
					id: key,
					colleges: Object.keys(item.colleges || {}).map(id=>this.mapping.mycolleges.byId[id]).filter(item=>!!item),
				}));
			});
	}


	createModel(data){
		let model = this.model = Helper.deepCopy(data);
		
		if ( (model.roles.coach || model.roles.coaching_corps_coach) && model.user_profile.available_hours ) {
			Object.values(model.user_profile.available_hours).forEach(list=>{
				list.forEach((item, k)=>list[k] = [item.start, item.end]);
			});
		}
		if ( model.chat ) {
			model.chatname = model.chat.username;
		} else {
			model.chatname = undefined;
		}
		if ( model.user_profile.bio?.content?.en_US ) {
			model.user_profile.bio = model.user_profile.bio.content.en_US;
		}

		if ( this.$scope.form && this.$scope.form.$error ) {
			Object.values(this.$scope.form.$error).forEach(list=>list.forEach($ctrl=>$ctrl.$processModelValue()));
			this.$scope.form.$setPristine();
		}
		if ( model.mobile_number ) {
			let m = model.mobile_number.match(this._$mobileRegex);
			if ( m && m[1] == '+1' ) // remove +1 country code
				model.mobile_number = m[2];
		}
	}

	submit(form, $event){
		if ( this.isBusy || this.isLocked || form.$pending || Helper.focusFormError(form) ) return;

		this.$q.when(true)
			.then(()=>this._checkCoachChatname($event, form))
			.then(()=>{
				let payload = this._preparePayload();
				this.isBusy = true;
				return this.api.put('users/'+ this.data._id, payload, {level: ApiError.LEVEL.MANUAL})
					.then(res=>{
						this.toast.success('Profile Updated');
						this.data = payload;
						this.createModel(payload);
						this._$open = false;
						return res;
					}, err=>{
						if ( ! this.api.isApiError(err) ) throw err;
		
						if ( err.response.data && err.response.data.error_code && MESSAGES.USERS.CODE[err.response.data.error_code] )
							switch( +err.response.data.error_code ) {
								case 102: 
									form.email.$setValidity('duplicateEmail', false);
									Helper.focusFormError(form);
									return;
								case 106:
									form.email.$setValidity('invalidEmail', false);
									Helper.focusFormError(form);
									return;
							}
						this.errorPrompt.show(err, $event);
					})
					.finally(()=>this.isBusy = false);
			});
	}

	_checkCoachChatname($evt, form){
		if ( (!this.data || !this.data.chat) && !this.model.chatname && (this.model.roles.coach || this.model.roles.coaching_corps_coach) ) {
			let $el = form.chat.$$element;

			return this.$mdDialog.show(
				this.$mdDialog.confirm()
						.title('Warning')
						.textContent('If you do not specify a chat display name, you cannot use chat. Continue?')
						.ariaLabel('Warning')
						.targetEvent($evt)
						.ok('Ok')
						.cancel('Cancel')
			)
			.catch(e=>{
				$el[0].focus();
				try { $el[0].scrollIntoView && $el[0].scrollIntoView({behavior:'smooth', block:'center'});
				} catch(e){}
				throw e;
			})
		}
		return true;
	}

	_preparePayload(){
		let payload = Helper.deepCopy(this.data);
		let model = this.model;

		payload.user_profile.first_name = model.user_profile.first_name;
		payload.user_profile.last_name = model.user_profile.last_name;
		payload.user_profile.photo_url = model.user_profile.photo_url || undefined;
		payload.user_profile.photo_alt_text = model.user_profile.photo_alt_text || undefined;

		payload.email = model.email;

		payload.mobile_number = (model.mobile_number || '').trim() || undefined;
		if ( payload.mobile_number ) {
			let m = payload.mobile_number.match(this._$mobileRegex);
			if ( m ) {
				if ( ! m[1] ) // no country code
					m[1] = '+1';
				payload.mobile_number = m[1] + m[2];
			}
		}

		if ( payload.roles.coach || payload.roles.coaching_corps_coach ) {
			// payload.salesforce = payload.roles.coach && model.salesforce?.id ? {id: model.salesforce.id} : undefined;
			payload.user_profile.timezone = model.user_profile.timezone;
			payload.user_profile.icon_url = model.user_profile.icon_url || undefined;
			payload.user_profile.icon_alt_text = model.user_profile.icon_alt_text || undefined;
			payload.user_profile.calendly_link = model.user_profile.calendly_link || undefined;
			payload.user_profile.facebook_url = model.user_profile.facebook_url || undefined;
			payload.user_profile.instagram_url = model.user_profile.instagram_url || undefined;
			payload.user_profile.twitter_url = model.user_profile.twitter_url || undefined;
			
			let hrs = Helper.deepCopy(model.user_profile.available_hours);
			payload.user_profile.available_hours = {};
			Object.keys(hrs).forEach(day=>{
				let li = (hrs[day] || []).map(pair=>{
					if ( pair && pair.length ) {
						if ( pair[1] == '00:00' )
							pair[1] = '24:00';
						return {start:pair[0], end:pair[1]};
					}
				}).filter(v=>!!v);
				if ( li.length > 0 )
					payload.user_profile.available_hours[day] = li;
			});
		} else {
			payload.user_profile.timezone = payload.user_profile.available_hours = undefined;
			payload.user_profile.calendly_link = payload.user_profile.instagram_url = payload.user_profile.facebook_url = payload.user_profile.twitter_url = undefined;
		}
		if ( ! payload.chat ) {
			model.chatname = (model.chatname || '').trim();
			if ( model.chatname )
				payload.chat = {username: model.chatname.toLowerCase()};
		}
		if ( model.user_profile.bio ) {
			payload.user_profile.bio = {
				content: {en_US: model.user_profile.bio},
				type: 'text/plain',
			};
		} else {
			payload.user_profile.bio = undefined;
		}

		return payload;
	}


	submitPw(){
		const payload = {
			old_password: this.pwModel.password1,
			new_password: this.pwModel.password3,
		};

		this.api.put('authentication/'+ this.data._id +'/password', payload, {level: ApiError.LEVEL.MANUAL})
			.then(res=>{
				this.toast.success('Password Updated');
				this.pwModel = {};
				this.$scope.formPw.$setUntouched();
				this.$scope.formPw.$setPristine();
			})
			.catch(err=>{
				if ( this.api.isApiError(err) ) {
					if ( err.response.status == 400 ) {
						this.toast.warn('Change password failed, please check if current password is correct');
						this.$timeout(()=>$('input[name="password1"]').focus(), 100, false);
					} else {
						err.name = 'Unable to Update Password';
						this.toast.error(err);
					}
				}
			})
			.finally(()=>{
				this.isBusy = false;
			});
		this.isBusy = true;
	}


	checkToggled(day, $value){
		if ( $value ) {
			this.model.user_profile.available_hours[day] = this.model.user_profile.available_hours[day] || [['10:00:00', '18:00:00']];
		} else {
			delete this.model.user_profile.available_hours[day];
		}
	}


	confirmChatname(value){
		return this.api.get('users/chatNames/check', {chatName:value.toLowerCase()}, {level:ApiError.IGNORED})
			.then(res=>res.data.taken ? this.$q.reject('taken') : true );
	}
	cleanMobileNumber($model){
		let value = String($model.$viewValue || '');
		if ( /[\(\)\-\s]/.test(value) )
			this.$timeout(()=>{
				$model.$setViewValue(value.replace(/[\(\)\-\s]/g, ''));
				$model.$render();
			}, 500);
	}


}