<header class="md-whiteframe-2dp flex-none layout-row layout-align-space-between-center layout-wrap">
	<h1 id="pageTitle" class="flex-xs-100 md-headline">
		<a ui-sref="^" ui-sref-opts="ctrl.parentStateParams">Tasks <span class="margin-1x" aria-hidden="true">&#9656;
    </span></a>Add by Batch
	</h1>

	<i flex></i>
	<md-button type="reset" class="md-button md-warn md-whiteframe-1dp" 
		ng-click="ctrl.clear()" ng-show="ctrl.file">
		<span class="fas fa-eraser"></span> Clear
	</md-button>
</header>

<form id="milestone-batch-form" name="form" novalidate ng-submit="ctrl.submit()" flex layout="column" form-disabled="ctrl.isBusy">
	<main class="flex can-scroll-y">
		<div id="dropzone" class="layout-column fade-in" ng-hide="ctrl.file">
			<div class="panel panel-small flex layout-column md-whiteframe-1dp">
				<div class="panel-content flex layout-column">
					<div class="dropzone margin-4x padding-4x md-caption black-66 text-center flex layout-column layout-align-center-center">
						<div class="md-title padding-4x margin-2x" ng-show="ctrl.successCount">
							<a ui-sref="^.batches">{{ ctrl.successCount }} Record(s) Uploaded</a>
						</div>
						<md-button type="button" id="fileInput" class="md-raised md-primary" ng-hide="ctrl.isBusy">
							<i class="far fa-file"></i> Select {{ ctrl.successCount ? 'another' : '' }} File
						</md-button>
						<span class="hide-xs hide-sm" ng-hide="ctrl.isBusy">or drop files here</span>
					</div>
				</div>
			</div>
		</div>

		<div class="panel fade-in" md-whiteframe="1" ng-show="ctrl.file">
			<div class="panel-title padding-4x layout-row layout-align-space-between-center">
				<h4 class="md-title normal-wg margin-0x">{{ ctrl.file.name }}</h4>
			</div>
			<div class="panel-content can-scroll-x can-scroll-y">
				<div class="padding-4x">
					<table class="table table-striped" style="width:100%">
						<tr><th style="width:33%">File Name</th>
								<td>{{ ctrl.file.name }}</td></tr>
						<tr><th>File Type</th>
								<td>{{ ctrl.file.type }}</td></tr>
						<tr><th>File Size</th>
								<td>{{ ctrl.file.size }} bytes</td></tr>
						<tbody ng-hide="ctrl.invalidCount">
							<tr></tr>
							<tr><th>Total Tasks</th>
									<td>{{ ctrl.buildStats.milestones || 0 }}</td></tr>
							<tr><th>Total Steps</th>
									<td>{{ ctrl.buildStats.steps || 0 }}</td></tr>
							<tr><th>Total Notifications</th>
									<td>{{ ctrl.buildStats.notifs }}</td></tr>
						</tbody>
					</table>
				</div>
				<div id="error-table" class="padding-4x can-scroll-x animate-fade" ng-if="ctrl.errors.length > 0" toggle-expand="ctrl.errors._$open">
					<md-card md-theme="::'danger'" class="margin-0x" style="border-bottom-left-radius: 0px">
						<md-card-content layout ng-click="ctrl.errors._$open = !ctrl.errors._$open">
							<span><span class="fas fa-exclamation-triangle"></span>&nbsp;&nbsp;</span>
							<span flex>Found {{ctrl.errors.length}} Error{{ctrl.errors.length>1?'s':''}}</span>
							<span class="fas fa-chevron-up" ng-class="{toggled:!ctrl.errors._$open}"></span>
						</md-card-content>
					</md-card>
					<table class="table table-striped md-body-1 expandable" style="width:100%; height:0px; border-left:3px solid transparent;" 
						md-colors="::{'border-left-color': 'danger-background-800'}">
						<tr>
							<th>Row/Column</th>
							<th style="width:100%">Error Message</th>
						</tr>
						<tr class="text-center" ng-repeat="err in ctrl.errors">
							<td>{{ err.node }}</td>
							<td class="text-left">{{ err.message }}</td>
						</tr>
					</table>
				</div>
				<div class="padding-4x can-scroll-x animate-fade" ng-if="ctrl.warnings.length > 0" toggle-expand="ctrl.warnings._$open">
					<md-card md-theme="::'warning'" class="margin-0x" style="border-bottom-left-radius: 0px">
						<md-card-content layout ng-click="ctrl.warnings._$open = !ctrl.warnings._$open">
							<span><span class="fas fa-exclamation-circle"></span>&nbsp;&nbsp;</span>
							<span flex>Found {{ctrl.warnings.length}} Warning{{ctrl.warnings.length>1?'s':''}}</span>
							<span class="fas fa-chevron-up" ng-class="{toggled:!ctrl.warnings._$open}"></span>
						</md-card-content>
					</md-card>
					<table class="table table-striped md-body-1 expandable" style="width:100%; height:0px; border-left:3px solid transparent;" 
						md-colors="::{'border-left-color': 'warning-background-800'}">
						<tr>
							<th>Row/Column</th>
							<th style="width:100%">Warning Message</th>
						</tr>
						<tr class="text-center" ng-repeat="err in ctrl.warnings">
							<td>{{ err.node }}</td>
							<td class="text-left">{{ err.message }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>

		<div id="csv-preview" class="layout-column fade-in" ng-show="ctrl.file && ctrl.rawData">
			<div class="panel flex layout-column" md-whiteframe="1" ng-if="ctrl.file && ctrl.rawData">
				<div class="panel-content flex can-scroll-x can-scroll-y">
					<table class="table data-table compact" style="min-width:100%">
						<thead>
							<tr>
								<th class=""></th>
								<th class="text-center sticky" ng-repeat="item in ctrl.rawData[0] track by $index">{{ ctrl.getColumnHeader($index) }}</th>
							</tr>
						</thead>
						<tbody>
						<tr ng-repeat="row in ctrl.rawData track by $index" ng-class="::{'red-border': ctrl.hasRowErrors($index)}">
							<th class="text-center sticky">
								<span class="padding-2x">{{:: $index+1 }}</span>
								<div class="error padding-2x md-caption normal-wg" ng-if=":: ctrl.hasRowErrors($index)">
									<em>{{:: ($rowErrors = ctrl.getRowErrors($index)).length }} error{{ $rowErrors.length > 1 ? 's' : '' }}</em>
									<div ng-repeat="err in ctrl.getCellErrors($index, undefined) track by $index">
										<em>{{:: err.message }}</em>
									</div>
								</div>
							</th>
							<td class="margin-0x" ng-repeat="(key, item) in row track by $index" ng-class="::{'red-border': ctrl.hasCellErrors($parent.$index, key)}">
								<div class="padding-2x" ng-bind=":: item"></div>
								<div class="error padding-2x md-caption" ng-if=":: ($cellErrors = ctrl.getCellErrors($parent.$index, key)).length > 0">
									<div ng-repeat="err in $cellErrors track by $index">&bull;<em>{{:: err.message }}</em></div>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div class="panel panel-small" md-whiteframe="1" ng-hide="ctrl.file">
			<div class="panel-title padding-4x">
				<h4 class="md-headline margin-0x">CSV Templates</h4>
			</div>
			<div class="panel-content">
				<md-list class="links" flex>
					<md-list-item class="md-2-line" ng-repeat="tpl in ctrl.templates" ng-href="{{:: tpl.url }}" download="{{:: tpl.filename }}" target="_blank">
						<div class="md-list-item-text">
							<i class="fas fa-download"></i> {{ tpl.label }}
						</div>
					</md-list-item>
				</md-list>
			</div>
		</div>
	</main>

	<div ng-show="ctrl.isBusy" class="busy-overlay fade-in fade-out" layout layout-align="center center">
		<md-progress-circular md-mode="indeterminate"></md-progress-circular>
	</div>

	<footer md-whiteframe="2" layout="row" layout-align="end start" ng-hide="ctrl.isBusy || !ctrl.file">
		<md-button type="submit" class="md-raised md-primary" ng-disabled="!!ctrl.errors.length || !ctrl.buildStats.milestones">
			<span class="far fa-save"></span> Submit
		</md-button>
	</footer>
</form>
