import $ from 'jquery'
import {DependencyInjected, Helper, ApiError, SETTINGS, CONSTANTS} from '../common'

const SCOPE_EVENTS = CONSTANTS.SCOPE_EVENTS;


export default class BaseSingleController extends DependencyInjected {
	static get $inject(){return [
    'data', // everything that extends this should have it resolve on routing
		'toast',
		'promptExit',
		'errorPrompt',
		'session',
		'authorization',
		'$q',
		'$sce',
		'$state',
		'$scope',
		'$transitions',
		'$parse',
		'$timeout',
		'api',
		'apiMap',
		'MAPPINGS_JSON',
		'BEYOND12_ID',
	]}


	init(){
		this.$scope.ctrl = this;
		this.mapping = this.mapping || {};

		this.isBusy = true;
		this.isLocked = this.ready = false;

		this.dateTimeExample = moment().format(SETTINGS.dateTimeFormat);
		this.dateExample = moment().format(SETTINGS.dateFormat);

		const stateName = this.$state.current.name;
		this._destructors.push(
			// busy state when trying to transition
			this.$transitions.onBefore({from: stateName}, transition=>{
				if ( transition.to().name != stateName )
					this.isBusy = true;
			}),
			// aborted, invalid or error
			this.$transitions.onError({from: stateName}, transition=>{
				if ( [3,4,6].includes(transition.error().type) )
					this.isBusy = !this.ready;
			}),
			// mark form as changed
			this.$scope.$once(SCOPE_EVENTS.PAGE_READY, ()=>{
				this.$scope?.form?.$setPristine();
				const once = this.$scope?.$watch('form.$dirty', (value, old)=>{
					if ( value ) {
						this.promptExit.enable(this.$state.current.name);
						once();
					}
				});
				this._destructors.push(once);
			}),

			this.$scope.$on(SCOPE_EVENTS.ADD_PAGE_DEPENDENCY, (ev, promise)=>{
				if ( this.isBusy ) {
					this._pageDependencies.push(promise);
				} else {
					console.warn(SCOPE_EVENTS.ADD_PAGE_DEPENDENCY, 'event after page already ready');
				}
				ev.stopPropagation();
			}),
		);

		this._pageDependencies = [];

		this.$q.when(this._loadDependencies())
			.then(()=>{
				this.$scope?.$broadcast(SCOPE_EVENTS.DEPS_LOADED, this);
				this.$scope?.$parent?.$emit(SCOPE_EVENTS.DEPS_LOADED, this);
			})
			.then(()=>{
				this.$scope?.$broadcast(SCOPE_EVENTS.DATA_READY, this);
				this.$scope?.$parent?.$emit(SCOPE_EVENTS.DATA_READY, this);
			})
			// buffer for other services/directives/etc to get their dependencies in
			.then(()=>this.$timeout(angular.noop, 10))
			.then(()=>this._pageDependencies.length ? this.$q.all(this._pageDependencies) : this.$q.resolve())
			.then(()=>{
				this.isBusy = false;
				this.ready = true;
				this.$scope?.$broadcast(SCOPE_EVENTS.PAGE_READY, this);
				this.$scope?.$parent?.$emit(SCOPE_EVENTS.PAGE_READY, this);
			});
	}
	_loadDependencies(){
		return this.$q.resolve();
	}

	createModel(data){
		let model = Helper.deepCopy(data);

		return model;
	}

	async submit($evt, form){
		this._defer = this.$q.defer();
		let res;
		try {
			res = await this._submit($evt, form);
		} catch(err) {
			this.api.handleError(err, 'Unable to Save');
		}
		this.isBusy = false;
		this._defer.resolve(res);
		this._defer = null;
		return res;
	}

	async _submit($evt, form){
		await this._validateForm(form);

		console.error('override _submit method');
	}

  _validateForm(form){
		if ( this.hasPending(form) ) {
			return this.$q.reject('has pending');
		}

		let promise = this.$q.resolve(true);  // use this to chain promises

		if ( Object.keys(form.$error || {}).length > 0 ) {
			let fields = [];

			// recurse on sub forms
			const recurseTouch = ctrl=>{
				Object.values(ctrl.$error).forEach(errs=>{
					if ( Array.isArray(errs) ) {
						errs.forEach(ctrl=>recurseTouch(ctrl));
					} else
					if ( ctrl.$$element.attribute?.['ng-form']?.value ) {
						recurseTouch(form[ctrl.$$element.attribute?.['ng-form']?.value]);

					} else {
						!fields.includes(ctrl.$$element[0]) ? fields.push(ctrl.$$element[0]) : null;
						ctrl.$setTouched && ctrl.$setTouched();
					}
				});
			};
			recurseTouch(form);
			promise = promise.then(()=>this.$q.reject(true));
			
			const sendToast = ()=>this.toast.warn((fields.length > 1 ? 'Some' : 'One') +' of the fields are required or invalid');
			
			if ( fields.length > 0 ) {
				let $elements = $(fields);
				if ( $elements.filter(':visible').length ) {
					let $el = $elements.filter(':visible')
						.sort((a, b)=>$(a).offset().top - $(b).offset().top)
						.first();
					Helper.smoothScrollTo($el, null, ()=>{$el.focus(); sendToast();});
				} else
				if ( $elements.filter(':hidden').length ) {
					let $el = $elements.filter(':hidden').first();
					let $parent = $el.parents().filter('[toggle-expand]');
					if ( $parent.length ) {
						Helper.smoothScrollTo(
							$parent.trigger('expand')
								.one('expanded', ()=>Helper.smoothScrollTo($el, null, ()=>{$el.focus(); sendToast();}))
						);
					} else {
						Helper.smoothScrollTo(
							$elements.parents().filter(':visible')
								.sort((a, b)=>$(a).offset().top - $(b).offset().top)
								.first(),
							null, ()=>sendToast()
						);
						console.log('hidden', $el.get(0));
					}
				}
			}
		}

		return promise;
	}


	async _upload(files, id, payload, url, meta=[]){
		if ( ! files?.length ) return;

		const RESOURCE = this.constructor.RESOURCE;
		
		let successes = 0, failures = 0;
		let promises = files.map(async (file)=>{
			const cfg = {
					params: {
						resourceType: file.resourceType, 
						contentType: file.type,
						metadata: [file.metadata, meta].flat().filter(v=>!!v),
					},
					level: ApiError.LEVEL.MANUAL,
				};
			console.info('uploading', file);

			try {
				let res = await this.api.upload('upload', {file}, cfg);
				successes++;
				file.modelPath && Helper.setCrawlValue(payload, file.modelPath.split('.'), Helper.encodeSafeURL(res.data.url));
				file.callback?.(Helper.encodeSafeURL(res.data.url), payload);
				console.info('upload success', file, res.data.url);
			} catch(err) {
				failures++;
				file.error = err;
				console.info('upload failed', file, err);
				throw err;
			};
		});

		try {
			await this.$q.all(promises);
			console.info('upload complete', successes, failures);

			if ( successes > 0 ) { // any success, update resource
				console.info('updating', url);
				await this.api.put(url, payload);
				console.info('updated', url);
			}

			if ( failures > 0 ) // throw for any error
				throw null;
		} catch(e) {
			files = files.filter(file=>file.error);
			let err = new Error(RESOURCE?.msgs?.SAVE_UPLOAD_FAIL || 'Resource saved but some file(s) failed to upload.');
			err.name = 'Upload Error';
			err.debug = files.map(file=>file.name +' '+ (file.error.debug || (file.error.code +' '+ file.error.message))).join('\n\r');
			files.forEach(file=>delete file.error);

			throw err;
		};
	}


	async delete($evt, label){
		if ( this.isBusy ) return false;

		try {
			await this.$mdDialog.show(
				this.$mdDialog.confirm()
						.title(`Confirm Delete`)
						.textContent(`Are you sure you want to delete this ${label.toLowerCase()}?`)
						.ariaLabel('confirm delete')
						.targetEvent($evt)
						.ok('Delete')
						.cancel('Cancel')
			);
		} catch(e) {
			return false;
		}

		this.isBusy = true;

		try {
			await this._delete($evt, label);

		} catch(err) {
			this.api.handleError(err, 'Unable to Delete');
			return false;
		}

		this.isBusy = false;
		this.toast.success(`${label} Deleted`);
		return true;
	}
	async _delete(){
		console.error('override _delete method');
	}



	hasPending(form){
		return Object.values(form.$pending || {}).length > 0;
	}

	submitForm(formName){
		try {
			$(`form[name='${formName}']`)//?.[0].dispatchEvent(new Event('submit'));
				.on('submit', e=>e.preventDefault())
				.trigger('submit');	
		} catch(e) {
			console.error('submit fail retry', e);

			const fn = this.$parse($(`form[name='${formName}']`).attr('ng-submit'));
			fn(this.$scope, {$event: $.event.fix(new Event('submit'))});
		}
		return this.$q.when(this._defer?.promise);
	}

	get isBusy(){ return this._isBusy; }
	set isBusy(value){
		if ( this._isBusy != value ) {
			this._isBusy = value;
			this.$scope?.$broadcast(value ? SCOPE_EVENTS.BUSY : SCOPE_EVENTS.NOT_BUSY, this);
		}
	}


	toSafeLangHtml(str, tags='span') {
		return this.$sce.trustAsHtml(Helper.toSafeLangHtml(str, tags));
	}
}