<header md-whiteframe="2" layout="column" layout-gt-xs="row" layout-align-gt-xs="space-between start">
	<h2 class="md-headline">My Profile</h2>
</header>

<main class="flex can-scroll-y">
	<div class="panel panel-small" md-whiteframe="1">
		<div class="panel-title">
			<h2 class="padding-4x md-title normal-wg">Permissions</h2>
		</div>
		<div class="panel-content padding-4x">
			<div ng-repeat="permission in ctrl.permissions track by $index">
				<h3 class="margin-0x md-subhead normal-wg">{{ ctrl.mapping.roles.byId[permission.id].name || permission.id }}</h3>
				<em class="md-body-1 black-66" ng-if="permission.id == 'superuser'">All Access</em>
				<em class="md-body-1 black-66" ng-if="permission.id == 'content_admin'">All Content Access</em>
				<div class="md-chips" ng-if="!['superuser','content_admin'].includes(permission.id)">
					<md-chip ng-repeat="chip in permission.colleges track by $index">
						{{ chip.name }}
					</md-chip>
				</div>
			</div>
		</div>
	</div>

	<form name="form" class="panel panel-small" md-whiteframe="1" ng-submit="ctrl.submit(form, $event)" aria-labelledby="h2a">
		<div class="panel-title layout-row layout-align-space-between-center">
			<h2 id="h2a" class="padding-4x md-title normal-wg">Personal Information</h2>
			<div class="panel-content margin-2x" form-disabled="ctrl.isBusy">
				<md-button class="md-primary md-raised compact" ng-hide="ctrl._$open" ng-click="ctrl._$open=true">Edit</md-button>
			</div>
		</div>
		<div class="panel-content padding-4x" layout-xs="column" layout-gt-xs="row" layout-wrap form-disabled="ctrl.isBusy || !ctrl._$open">
			<md-input-container class="md-block flex-gt-xs-100">
				<label>Email</label>
				<input type="email" class="black" name="email" autocomplete="email" ng-model="ctrl.model.email"
					ng-change="
						form.email.$error.duplicateEmail ? form.email.$setValidity('duplicateEmail', true) : null; 
						form.email.$error.invalidEmail ? form.email.$setValidity('invalidEmail', true) : null;
					"
					data-lpignore="true"
					required>
				<div ng-messages="form.email.$error" role="alert">
					<div ng-message="required">Required</div>
					<div ng-message="email">Invalid email format</div>
					<div ng-message="duplicateEmail">{{:: MESSAGES.USERS.CODE[102] }}</div>
					<div ng-message="invalidEmail">{{:: MESSAGES.USERS.CODE[106] }}</div>
				</div>
			</md-input-container>
			<md-input-container class="md-block flex-gt-xs-50 md-input-has-placeholder">
				<label>First Name</label>
				<input type="text" class="black" name="first_name" ng-model="ctrl.model.user_profile.first_name" data-lpignore="true" required md-autofocus>
				<div ng-messages="form.first_name.$error" role="alert">
					<div ng-message="required">Required</div>
				</div>
			</md-input-container>
			<md-input-container class="md-block flex-gt-xs-50 md-input-has-placeholder">
				<label>Last Name</label>
				<input type="text" class="black" name="last_name" ng-model="ctrl.model.user_profile.last_name" data-lpignore="true" required>
				<div ng-messages="form.last_name.$error" role="alert">
					<div ng-message="required">Required</div>
				</div>
			</md-input-container>
			<md-input-container class="md-block flex-gt-xs-60 md-input-has-placeholder">
				<label>Chat Username</label>
				<input type="text" name="chat" ng-if="!ctrl.model.chat" 
					style="text-transform: lowercase"
					ng-model="ctrl.model.chatname" ng-model-options="{updateOn: 'default blur', debounce: {'default': 250, 'blur': 0}}"
					ng-focus="form.chat.$setTouched()"
					ng-pattern="/^[a-z0-9_\\\-=/\\.]+$/i" maxlength="100" 
					data-lpignore="true"
					async-validate="ctrl.confirmChatname($view)">
				<input type="text" class="black" ng-if="ctrl.model.chat" ng-value="ctrl.model.chatname" ng-disabled="::true">
				<md-icon ng-show="form.chat.$viewValue" aria-label="Validating chat username">
					<i class="fas" ng-class="{'fa-sync-alt rotating':form.chat.$pending, 'fa-check green': !form.chat.$pending && form.chat.$valid}"></i>
				</md-icon>
				<div ng-messages="form.chat.$error" role="alert">
					<div ng-message="pattern">Special characters & spaces are not allowed</div>
					<div ng-message="maxlength">Cannot exceed 100 characters</div>
					<div ng-message="asyncValidate">Already in use</div>
				</div>
			</md-input-container>

			<md-input-container class="md-block flex-gt-xs-60 md-icon-float md-input-has-placeholder">
				<label>Profile Photo URL</label>
				<md-icon><i class="fas fa-portrait"></i></md-icon>
				<input type="url" name="photo_url" class="black" ng-model="ctrl.model.user_profile.photo_url" data-lpignore="true" ng-required="ctrl.model.user_profile.photo_alt_text">
				<div class="md-errors-spacer"></div>
				<div ng-messages="form.photo_url.$error" role="alert">
					<div ng-message="required">Required</div>
					<div ng-message="url">Use a valid url format, ex: <em>https://example.com/myphoto.jpg</em></div>
				</div>
			</md-input-container>
			<md-input-container class="md-block flex-gt-xs-60 md-input-has-placeholder">
				<label>Profile Photo Alt Text</label>
				<input type="text" name="photo_alt_text" class="black" minlength="5" ng-model="ctrl.model.user_profile.photo_alt_text" ng-required="ctrl.model.user_profile.photo_url">
        <div class="md-errors-spacer"></div>
        <div ng-messages="form.photo_alt_text.$error" role="alert">
					<div ng-message="required">Required</div>
					<div ng-message="minlength">Minimum 5 characters</div>
				</div>
			</md-input-container>

			<fieldset class="compact flex-100" ng-if="ctrl.model.roles && (ctrl.model.roles.coach || ctrl.model.roles.coaching_corps_coach)">
				<md-input-container class="md-block flex-gt-xs-60 md-input-has-placeholder">
					<label>Icon URL</label>
					<input type="url" name="icon_url" class="black" ng-model="ctrl.model.user_profile.icon_url">
					<div class="md-errors-spacer"></div>
					<div ng-messages="form.icon_url.$error" role="alert">
						<div ng-message="required">Required</div>
						<div ng-message="url">Use a valid url format, ex: <em>https://example.com/myicon.png</em></div>
					</div>
				</md-input-container>
				<md-input-container class="md-block">
					<label>Long Bio Text</label>
					<textarea class="black" name="profile" ng-model="ctrl.model.user_profile.bio" valid-lang rows="6"></textarea>
					<div ng-messages="form.profile.$error" role="alert">
						<div ng-message="required">Required</div>
						<div ng-message="lang-missing">{{ form.profile.$error['lang-missing'] }}</div>
						<div ng-message="lang-mismatch">{{ form.profile.$error['lang-mismatch'] }}</div>
						<div ng-message="lang">Invalid language tag formatting</div>
					</div>
				</md-input-container>
	
				<div class="padding-2x">
					<legend>Available Hours</legend>
					<div class="layout-column indent">
						<div class="layout-row layout-align-start-start" ng-repeat="(day, label) in ctrl.mapping.weekDays" style="min-height:46px">
							<md-checkbox class="black" style="min-width:120px"
								ng-model="_$available_hours[day]" 
								ng-change="ctrl.checkToggled(day, _$available_hours[day])"
								ng-checked="!!ctrl.model.user_profile.available_hours[day]">
									{{ label }}
							</md-checkbox>
							<div class="layout-row layout-wrap">
								<div ng-repeat="timeRange in ctrl.model.user_profile.available_hours[day] track by $index">
									<md-input-container class="picker-date no-label flex-none flex-offset-5 padding-0x all-day" ng-model-options="{updateOn:'default blur', debounce:{'default':1000,'blur':0}}">
										<input type="text" class="black" name="range_{{day}}_{{$index}}"
											aria-label="{{label +' time range'}}"
											valid-date-range time-only="true"
											view-format="{datetime:'h:mmA', time:'h:mmA'}" model-format="{datetime:'HH:mm:ss'}" separator="' - '"
											validate="$modelValue.length == 2"
											ng-required="$index == 0"
											ng-model="ctrl.model.user_profile.available_hours[day][$index]" ng-model-options="{updateOn:'blur'}">
										<div ng-messages="form['range_'+ day +'_'+ $index].$error" role="alert">
											<div ng-message="required">Required</div>
											<div ng-message="format">Invalid Format (eg 8:15am - 2:30pm)</div>
											<div ng-message="parse">Invalid Time (eg 8:15am - 2:30pm)</div>
											<div ng-message="date">Invalid Time</div>
											<div ng-message="order">Invalid Time Order</div>
											<div ng-message="validate">Must be a time range (eg 8:15am - 2:30pm)</div>
										</div>
									</md-input-container>
								</div>
								<!-- <md-button class="md-icon-button md-accent" aria-label="Add time" ng-click="ctrl.model.user_profile.available_hours[day].push(null)" ng-show="ctrl.model.user_profile.available_hours[day] && ctrl._$open && ctrl.model.user_profile.available_hours[day][$index]">
									<i class="fas fa-plus"></i>
								</md-button>
								<md-button class="md-icon-button md-accent" aria-label="Remove time" ng-click="ctrl.model.user_profile.available_hours[day].splice($index,1)" ng-show="ctrl.model.user_profile.available_hours[day].length > 0 && !ctrl.model.user_profile.available_hours[day][$index]">
									<i class="fas fa-times"></i>
								</md-button> -->
								<md-button class="md-icon-button md-accent"
									aria-label="{{'add time range for '+ label}}"
									ng-show="ctrl.model.user_profile.available_hours[day][0]"
									ng-click="ctrl.model.user_profile.available_hours[day].length > 1 && !ctrl.model.user_profile.available_hours[day][ctrl.model.user_profile.available_hours[day].length-1] ? ctrl.model.user_profile.available_hours[day].pop() : ctrl.model.user_profile.available_hours[day].push(null)">
									<i class="fas fa-plus rotate-animate" ng-class="{'rotate-45': ctrl.model.user_profile.available_hours[day].length > 1 && !ctrl.model.user_profile.available_hours[day][ctrl.model.user_profile.available_hours[day].length-1]}"></i>
								</md-button>
							</div>
						</div>
						
					</div>
				</div>

				<md-autocomplete class="md-block" md-input-name="timezone" md-selected-item="ctrl.model.user_profile.timezone"
					timezone-field
					md-floating-label="Timezone" md-require-match md-min-length="0" md-no-cache="true"
					placeholder="Ex. America/Los_Angeles" ng-disabled="ctrl.isBusy || !ctrl._$open" required>
					<div ng-messages="form.timezone.$error" ng-if="form.timezone.$touched">
						<div ng-message="required">Required</div>
						<div ng-message="md-require-match">Invalid timezone</div>
					</div>
				</md-autocomplete>

				<md-input-container class="md-block md-input-has-placeholder" ng-model-options="{updateOn:'default blur', debounce:{'default':200,'blur':0}}">
					<label>Mobile Number</label>
					<input type="text" class="black" name="mobile_number" 
						autocomplete="tel"
						ng-model="ctrl.model.user_profile.mobile_number" ng-pattern="ctrl._$mobileRegex"
						ng-keyup="ctrl.cleanMobileNumber(form.mobile_number)"
						ng-paste="ctrl.cleanMobileNumber(form.mobile_number)">
					<div ng-messages="form.mobile_number.$error" role="alert">
						<div ng-message="pattern">Invalid format (e.g. 1234567890 or +52123456789)</div>
					</div>
				</md-input-container>

				<md-input-container class="md-block md-icon-float md-input-has-placeholder">
					<label>Calendly Link</label>
					<md-icon><i class="far fa-calendar"></i></md-icon>
					<input type="url" name="calendly_link" class="black" ng-model="ctrl.model.user_profile.calendly_link">
					<div class="md-errors-spacer"></div>
					<div ng-messages="form.calendly_link.$error" role="alert">
						<div ng-message="required">Required</div>
						<div ng-message="url">Use a valid url format, ex: <em>https://calend.ly</em></div>
					</div>
				</md-input-container>
				<md-input-container class="md-block md-icon-float md-input-has-placeholder">
					<label>Instagram Profile</label>
					<md-icon><i class="fab fa-instagram"></i></md-icon>
					<input type="text" name="instagram_url" class="black" ng-model="ctrl.model.user_profile.instagram_url" ng-pattern="/^https:\/\/(www.)?instagram.com\/[^/]+?\b/">
					<div class="md-errors-spacer"></div>
					<div ng-messages="form.instagram_url.$error" role="alert">
						<div ng-message="required">Required</div>
						<div ng-message="pattern">Use a valid instagram link, ex: <em>https://www.instagram.com/<b>johndoe</b></em></div>
					</div>
				</md-input-container>
				<md-input-container class="md-block md-icon-float md-input-has-placeholder">
					<label>Facebook Profile</label>
					<md-icon><i class="fab fa-facebook"></i></md-icon>
					<input type="text" name="facebook_url" class="black" ng-model="ctrl.model.user_profile.facebook_url" ng-pattern="/^https:\/\/(www.)?facebook.com\/[^/]+?\b/">
					<div class="md-errors-spacer"></div>
					<div ng-messages="form.facebook_url.$error" role="alert">
						<div ng-message="required">Required</div>
						<div ng-message="pattern">Use a valid facebook link, ex: <em>https://www.facebook.com/<b>johndoe</b></em></div>
					</div>
				</md-input-container>
				<md-input-container class="md-block md-icon-float md-input-has-placeholder">
					<label>Twitter Profile</label>
					<md-icon><i class="fab fa-twitter"></i></md-icon>
					<input type="text" name="twitter_url" class="black" ng-model="ctrl.model.user_profile.twitter_url" ng-pattern="/^https:\/\/(www.)?twitter.com\/[^/]+?\b/">
					<div class="md-errors-spacer"></div>
					<div ng-messages="form.twitter_url.$error" role="alert">
						<div ng-message="required">Required</div>
						<div ng-message="pattern">Use a valid twitter link, ex: <em>https://www.twitter.com/<b>johndoe</b></em></div>
					</div>
				</md-input-container>
			</fieldset>


		</div>
		<div class="panel-content margin-2x" layout layout-align="end start">
			<div form-disabled="ctrl.isBusy || !ctrl._$open">
				<md-button type="submit" class="md-primary md-raised">Save Changes</md-button>
				<md-button type="button" class="md-raised" ng-show="ctrl._$open" ng-click="ctrl._$open=false; ctrl.reset();">Cancel</md-button>
			</div>
			<md-button class="md-primary md-raised" ng-hide="ctrl._$open" ng-click="ctrl._$open=true">Edit</md-button>
		</div>
	</form>

	<form name="formPw" class="panel panel-small" md-whiteframe="1" ng-submit="formPw.$valid && ctrl.submitPw()" aria-labelledby="h2b">
		<div class="panel-title">
			<h2 id="h2b" class="padding-4x md-title normal-wg">Change Password</h2>
		</div>
		<div class="panel-content margin-4x" layout="column">
			<md-input-container class="md-block md-input-has-placeholder" flex-gt-xs="100">
				<label>Current Password</label>
				<input type="password" name="password1" ng-model="ctrl.pwModel.password1" ng-disabled="ctrl.isBusy" data-lpignore="true" required>
				<div ng-messages="formPw.password1.$error" role="alert">
					<div ng-message="required">Required</div>
				</div>
			</md-input-container>
			<md-input-container class="md-block md-input-has-placeholder" flex-gt-xs="100">
				<label>New Password</label>
				<input type="password" name="password2" ng-model="ctrl.pwModel.password2" ng-disabled="ctrl.isBusy" required>
				<div ng-messages="formPw.password2.$error" role="alert">
					<div ng-message="required">Required</div>
				</div>
			</md-input-container>
			<md-input-container class="md-block md-input-has-placeholder" flex-gt-xs="100">
				<label>Confirm New Password</label>
				<input type="password" name="password3" ng-model="ctrl.pwModel.password3" is-equal="{{ctrl.pwModel.password2}}" ng-disabled="ctrl.isBusy" required>
				<div ng-messages="formPw.password3.$error" role="alert">
					<div ng-message="required">Required</div>
					<div ng-message="isEqual">New passwords must match</div>
				</div>
			</md-input-container>
		<div class="panel-content margin-2x" layout layout-align="end start">
			<md-button type="submit" class="md-primary md-raised" ng-disabled="ctrl.isBusy || formPw.$touched || formPw.$invalid">Submit</md-button>
		</div>
		</div>
	</form>
</main>

<div ng-show="ctrl.isBusy" class="busy-overlay fade-in fade-out" layout layout-align="center center">
	<md-progress-circular md-mode="indeterminate"></md-progress-circular>
</div>
