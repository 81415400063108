
			<div class="panel-title md-title normal-wg padding-4x">Additional Notifications</div>
			<div class="panel-content">
				<div class="text-center md-caption black-25 padding-4x" ng-if="ctrl.model.reminders.length == 0">
					<em>&mdash; no additional notifications &mdash;</em>
				</div>
				<table class="table" ng-if="ctrl.model.reminders.length > 0">
					<thead>
					<tr class="md-caption black-33 text-left">
						<th class="date">Date</th>
						<th class="message">Message</th>
						<th class="question">Is a Question</th>
						<th class="icon"><span class="hide">Action</span></th>
					</tr>
					</thead>
					<tbody>
					<tr class="animate-fade" ng-repeat="reminder in ctrl.model.reminders track by $index" form-disabled="ctrl.data && reminder._$locked">
						<td>
							<md-input-container class="md-icon-right padding-0x" md-no-float="true">
								<input type="text" name="reminder_{{$index}}_date_to_send" 
									required aria-label="Date"
									date-picker on-picker-update="ctrl.heatmap.updatePicker($picker)"
									valid-date ng-model="reminder.date_to_send" 
									ng-model-options="{updateOn:'blur'}">
								<!-- <button type="button" class="md-datepicker-triangle-button md-icon-button md-button"
									aria-label="open calendar"
									moment-picker ng-model="reminder.date_to_send"
									render="ctrl.updateHeatMap('reminder_'+ $index +'_date_to_send', $selected, $view)">
									<div class="md-datepicker-expand-triangle"></div>
								</button> -->
								<div ng-messages="form['reminder_'+$index+'_date_to_send'].$error" role="alert">
									<div ng-message="required">Required</div>
									<div ng-message="format">Invalid Format (eg Jan 1, 2019)</div>
									<div ng-message="parse">Invalid Date (eg Jan 1, 2019)</div>
									<div ng-message="date">Invalid Date Value</div>
								</div>
							</md-input-container>
						</td>
						<td>
							<md-input-container md-no-float="true">
								<textarea type="text" name="reminder_{{$index}}_text" rows="3" required
									aria-label="Message"
                  valid-lang
									ng-model="reminder.text"></textarea>
								<div ng-messages="form['reminder_'+$index+'_text'].$error" role="alert">
									<div ng-message="required">Required</div>
                  <div ng-message="lang-missing">{{ form['reminder_'+$index+'_text'].$error['lang-missing'] }}</div>
                  <div ng-message="lang-mismatch">{{ form['reminder_'+$index+'_text'].$error['lang-mismatch'] }}</div>
                  <div ng-message="lang">Invalid language tag formatting</div>
								</div>
							</md-input-container>
						</td>
						<td>
							<div class="padding-2x">
								<md-checkbox ng-model="reminder.question" aria-label="is question">Yes</md-checkbox>
							</div>
						</td>
						<td>
							<md-button class="md-icon-button md-warn md-hue-1 margin-0x" 
								ng-click="ctrl.removeReminder($index, $event)" 
								aria-label="delete reminder" title="delete reminder"
								ng-show="!ctrl.isLocked">
								<span class="fas fa-times"></span>
							</md-button>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<div class="panel-title padding-2x text-center" ng-hide="ctrl.isLocked">
				<md-button class="md-raised md-primary" 
					aria-label="add new" 
					aria-describedby="popup_reminder"
					ng-click="ctrl.addReminder()">
					<span class="fas fa-plus-circle"></span> Add New
				</md-button>
				<pop-helper id="popup_reminder" pop-align="bottom-right" ng-cloak>By default, MyCoach will send a reminder the day of the event, but you can include additional reminders. MyCoach Tip: Set a reminder for one week and also one day before the event is set to occur.</pop-helper>
			</div>