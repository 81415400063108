import MainController from './main.controller'
import {ApiError, Helper} from '../common'

export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {
	const $stateRegistry = $uiRouterProvider.stateRegistry;

	$stateRegistry.register({
		name: 'app',
		url: '/',
		views: {
			'root@': {
				controller: MainController,
				controllerAs: 'mainCtrl',
				template: require('./main.html'),
			}
		},
		redirectTo: (trans)=>{
			const auth = trans.injector().get('authorization')
			if ( auth.isValid() ) return 'app.events';
		},
		resolve: [{
				provide: 'currentUser',
				deps: ['api', '$state', 'authentication', 'authorization', '$timeout'],
				useFactory: async (api, $state, authentication, authorization, $timeout)=>{
					if ( ! authorization.isValid() ) return;
					try {
						const cfg = {cache: true, level: ApiError.LEVEL.MANUAL};
						const user = (await api.get('users/'+ authorization.userId, {}, cfg)).data;

						const roles = (await api.get('roles', {}, cfg)).data;
						authorization.initRolePermissions(roles);

						return user;
					} catch(err) {
						if ( api.isApiError(err) ) {
							if ( /^4\d\d$/.test(err.response.status) ) {
								authentication.setToken(null);
								return $state.go('guest.login');
							} else {
								return $state.go('error.500', {error: err}, {location:false});
							}
						}
						throw err;
					}
				},
		},{
				provide: 'roleDefaultRedirect',
				deps: ['$state', 'authorization', 'currentUser'], // include currentUser to wait for initRolePermissions
				useFactory: ($state, authorization)=>{
					// BTC-508 redirect to profile if no access to events
					if ( authorization.isValid() && $state.transition.to().name == 'app.events' && ! authorization.hasStateAccess('app.events') )
						return $state.go('app.profile');
				},
		}],
	});

	$stateRegistry.register({
		name: 'app.logout',
		url: 'logout',
		resolve: [{
			provide: 'logout',
			deps: ['authentication', '$state', 'toast'],
			useFactory: (authentication, $state, toast)=>{
				authentication.setToken(null);
				$state.go('guest.login').then(()=>
					setTimeout(()=>toast.success('Logout Successful'), 200)
				);
			},
		}]
	});

	$stateRegistry.register({
		name: 'app.404',
		title: 'Page Not Found',
		params: {
			error: null,
		},
		views: {
			'content@app': {
				template: '<div error-card="ctrl.error" layout layout-align="center center" flex></div>',
				controller: ['$stateParams', function($stateParams){
					this.error = new ApiError('Please report to administrator if this page should be here.');
					this.error.title = '404 Page Not Found';
				}],
				controllerAs: 'ctrl',
			},
		},
		onEnter: ['$transition$', '$state$', ($transition$, $state$)=>{
			let authorization = $transition$.injector().get('authorization');
			// not logged in, use guest 404 page
			if ( ! authorization.isValid() )
				return $transition$.router.stateService.target('guest.404', null, {location:false});
		}],
	});

	$stateRegistry.register({
		name: 'app.403',
		title: 'No Permission',
		params: {
			error: null,
		},
		views: {
			'content@app': {
				template: '<div error-card="ctrl.error" layout layout-align="center center" flex></div>',
				controller: ['$stateParams', function($stateParams){
					this.error = new ApiError('It seems you do not have appropriate permissions to access this page. Please report to administrator if you should be able to.');
					this.error.title = '403 No Permission';
				}],
				controllerAs: 'ctrl',
			},
		},
	});
	$stateRegistry.register({
		name: 'app.500',
		title: 'Page Unavailable',
		params: {
			error: null,
		},
		views: {
			'content@app': {
				template: '<div error-card="ctrl.error" layout layout-align="center center" flex></div>',
				controller: ['$stateParams', function($stateParams){(this.error = $stateParams.error).title = 'Page Unavailable'}],
				controllerAs: 'ctrl',
			},
		},
	});

}];
