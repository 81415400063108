
					<div class="panel-title md-title normal-wg padding-4x">Tip Details</div>
					<div ng-include="'recipientGroup.tpl'"></div>

				<div class="panel-content padding-4x" ng-hide="ctrl.mapping.mycolleges.length == 1">
					<div class="flex-gt-sm-65" content-owner="ctrl.model.owner_college_id"></div>
				</div>
	
				<div class="panel-content">
					<div class="layout-row layout-wrap margin-4x" on-form-focus="ctrl.updatePreview('home', true)">
						<md-input-container class="flex-100 md-input-has-placeholder" ng-hide="ctrl.isLocked && !ctrl.model.flutter._$range">
							<input type="text" name="name" 
								valid-lang
								placeholder="Title"
								ng-model="ctrl.model.flutter.name.en_US">
							<div ng-messages="form.name.$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="lang-missing">{{ form.name.$error['lang-missing'] }}</div>
								<div ng-message="lang-mismatch">{{ form.name.$error['lang-mismatch'] }}</div>
								<div ng-message="lang">Invalid language tag formatting</div>
							</div>
						</md-input-container>

						<md-input-container class="flex-100 md-input-has-placeholder">
								<textarea name="call_to_action" rows="1" max-rows="6"
									placeholder="Call to Action"
									valid-lang
									ng-model="ctrl.model.call_to_action"
									ng-required="ctrl.model._$enable_flutter && !ctrl.model.flutter.hide_from_flutter"
									ng-change="ctrl.onDetailsChanged()"
									></textarea>
								<div ng-messages="form.call_to_action.$error" role="alert">
									<div ng-message="required">Required</div>
									<div ng-message="lang-missing">{{ form.call_to_action.$error['lang-missing'] }}</div>
									<div ng-message="lang-mismatch">{{ form.call_to_action.$error['lang-mismatch'] }}</div>
									<div ng-message="lang">Invalid language tag formatting</div>
								</div>
						</md-input-container>

						<md-input-container class="flex-100 md-input-has-placeholder">
							<input name="attribution" 
								valid-lang
								placeholder="Attribution" 
								ng-model="ctrl.model.attribution" 
								ng-change="ctrl.onDetailsChanged()"
								/>
								<div ng-messages="form.attribution.$error" role="alert">
									<div ng-message="lang-missing">{{ form.attribution.$error['lang-missing'] }}</div>
									<div ng-message="lang-mismatch">{{ form.attribution.$error['lang-mismatch'] }}</div>
									<div ng-message="lang">Invalid language tag formatting</div>
								</div>
						</md-input-container>

						
						<md-radio-group class="flex-gt-xs-95 flex-offset-gt-xs-5 no-form-focus" 
							aria-label="Background"
							ng-model="ctrl.model.background.$useImage">
							<div class="layout-row">
								<md-input-container class="flex-nogrow">
									<md-radio-button class="margin-0x" ng-value="true" aria-label="image"></md-radio-button>
								</md-input-container>
								<div class="flex layout-column disabled-opacity-50 cursor-normal" 
									role="button" tabindex="-1"
									form-disabled="!ctrl.model.background.$useImage"
									ng-click="ctrl.model.background.$useImage ? null : ctrl.propagateDelayedEvent($event, ctrl.model.background.$useImage=true)"
								>
									<div class="layout-row flex">
										<md-input-container class="flex no-animate right-action-btn md-input-has-placeholder" 
											ng-hide="ctrl.model.background.file" 
											form-disabled="ctrl.model.background.file">
											<input type="url" class="md-input" name="background_url" 
												placeholder="Background Image URL"
												ng-model="ctrl.model.background.url">
											<md-button class="md-icon-button times md-warn md-hue-1 compact" 
												aria-label="Clear value"
												ng-click="ctrl.model.background.url=''">
												<md-icon class="fas fa-times"></md-icon>
											</md-button>
											<div ng-messages="form.background_url.$error" role="alert">
												<div ng-message="url">Invalid URL format (ie: https://example.com/image.jpeg)</div>
											</div>
										</md-input-container>
	
										<md-input-container class="flex right-action-btn no-animate md-input-has-placeholder"
											ng-show="ctrl.model.background.file" 
											form-disabled="!ctrl.model.background.file">
											<input type="text" class="md-input disabled" 
												placeholder="Background Image"
												ng-value="ctrl.model.background.file.name" 
												ng-disabled="true">
											<md-button class="md-icon-button times compact md-warn md-hue-1" 
												aria-label="remove file" 
												ng-click="ctrl.model.background.file = undefined">
												<md-icon class="fas fa-times"></md-icon>
											</md-button>
										</md-input-container>
	
										<md-input-container class="flex-nogrow no-animate" 
											ng-hide="ctrl.isLocked">
											<md-button type="file" name="background_url_file" class="md-primary compact"
												ngf-select accept="image/*" ngf-pattern="'image/*'"
												ngf-multiple="false" ngf-keep="true"
												ngf-change="$newFiles.length ? (ctrl.model.background.file = $newFiles.pop()) : null">
												<i class="fas fa-file-upload" aria-hidden="true"></i> Select File
											</md-button>
										</md-input-container>
									</div>
									<div class="layout-row flex">
										<md-input-container class="flex md-input-has-placeholder" ng-show="ctrl.model.background.url || ctrl.model.background.file">
											<label>Background Image Alt Text</label>
											<input type="text" name="background_url_alt_text" ng-model="ctrl.model.background.alt_text" minlength="5" ng-required="ctrl.model.background.url">
											<div class="md-errors-spacer"></div>
											<div ng-messages="form.background_url_alt_text.$error" role="alert">
												<div ng-message="required">Required</div>
												<div ng-message="minlength">Minimum 5 characters</div>
											</div>
										</md-input-container>
									</div>
								</div>
							</div>

							<div class="layout-row">
								<md-input-container class="flex-nogrow">
									<md-radio-button class="margin-0x" ng-value="false" aria-label="color"></md-radio-button>
								</md-input-container>

								<md-input-container class="flex disabled-opacity-50 cursor-normal md-input-has-placeholder" 
									role="button" tabindex="-1"
									form-disabled="ctrl.model.background.$useImage"
									ng-click="ctrl.model.background.$useImage ? ctrl.propagateDelayedEvent($event, ctrl.model.background.$useImage=false) : null"
								>
									<label>Background Color</label>
									<md-select name="background_color"
										ng-model="ctrl.model.background.color"
										ng-change="ctrl.model.background.color=='none' ? (ctrl.model.background.color=undefined) : null">
										<md-option md-option-empty ng-value="'none'">None</md-option>
										<md-option ng-repeat="color in ctrl.mapping.bgColors track by $index" ng-value="color.value">
											<span class="color" ng-style="{'background-color': color.value}"></span> {{ color.value }}
										</md-option>
									</md-select>
									<div class="md-errors-spacer"></div>
								</md-input-container>
							
							</div>
						</md-radio-group>

					</div>
				</div>

				<div class="panel-content">
					<div class="layout-row layout-wrap margin-4x">
						<md-input-container class="flex-100 pop-helper-right md-input-has-placeholder">
							<pop-helper id="popup_text" ng-cloak>Here, provide the details for the content you are uploading. Tip: We suggest not going over 140 characters.</pop-helper>
							<textarea name="text" 
								placeholder="Tip Details"
								ng-model="ctrl.model.text.formatted"
								tinymce switch-content-type="true" content-type-model="ctrl.model.text.content" 
								ng-disabled="ctrl.isBusy || ctrl.isLocked" 
								ng-change="ctrl.onDetailsChanged(); ctrl.updatePreview('details')"
								ng-focus="ctrl.updatePreview('details', true)"
								md-no-resize rows="2" max-rows="8" required></textarea>
							<div ng-messages="form.text.$error" role="alert">
								<div ng-message="required">Required</div>
							</div>
						</md-input-container>

						<md-input-container class="md-icon-right flex-100 flex-gt-xs-50 flex-gt-md-30 padding-0x md-input-has-placeholder">
							<input type="text" name="date_to_send" required
								placeholder="Date to Send"
								valid-date
								date-picker on-picker-update="ctrl.heatmap.updatePicker($picker)"
								ng-model="ctrl.model.date_to_send" ng-model-options="{updateOn:'blur'}">
							<div ng-messages="form.date_to_send.$error" role="alert">
								<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
							</div>
						</md-input-container>

						<div class="flex-100" form-disabled="!ctrl.model._$enable_flutter">
							<md-input-container class="md-block pop-helper-right md-input-has-placeholder">
								<pop-helper id="popup_location" ng-cloak>Here, you can include the in-person meeting place or input "virtual" to indicate an online event.</pop-helper>
								<input type="text" name="location" 
									valid-lang
									placeholder="Location"
									aria-describedby="popup_location"
									ng-model="ctrl.model.flutter.location.label.en_US">
									<div ng-messages="form.location.$error" role="alert">
										<div ng-message="lang-missing">{{ form.location.$error['lang-missing'] }}</div>
										<div ng-message="lang-mismatch">{{ form.location.$error['lang-mismatch'] }}</div>
										<div ng-message="lang">Invalid language tag formatting</div>
										
									</div>
							</md-input-container>
						</div>

						<md-input-container flex="100" flex-gt-xs="50" class="pop-helper-right md-input-has-placeholder">
							<pop-helper id="popup_linktext" ng-cloak>Here, include language that encourages the student to click on the link. Tip: Don't use words that mention the senses (see, hear, etc.) to avoid ableism.</pop-helper>
							<input type="text" name="link_text" 
								valid-lang
								placeholder="Link Text"
								aria-describedby="popup_linktext"
								ng-focus="ctrl.updatePreview('details', true)"
								ng-model="ctrl.model.link.text" ng-required="ctrl.model.link.url"
								ng-change="ctrl.onDetailsChanged()">
							<div ng-messages="form.link_text.$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="lang-missing">{{ form.link_text.$error['lang-missing'] }}</div>
								<div ng-message="lang-mismatch">{{ form.link_text.$error['lang-mismatch'] }}</div>
								<div ng-message="lang">Invalid language tag formatting</div>
								
							</div>
						</md-input-container>
						<md-input-container flex="100" flex-gt-xs="50" class="pop-helper-right md-input-has-placeholder">
							<pop-helper id="popup_linkurl" ng-cloak>Paste the link to where you want students to go for more information.</pop-helper>
							<input type="url" name="link_url" 
								placeholder="Link URL"
								aria-describedby="popup_linkurl"
								ng-focus="ctrl.updatePreview('details', true)"
								ng-model="ctrl.model.link.url" ng-required="ctrl.model.link.text">
							<div ng-messages="form.link_url.$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="url">Use a valid url format, ex: <em>https://example.com</em></div>
							</div>
						</md-input-container>
						

						<div class="layout-row layout-wrap flex-100" form-disabled="!ctrl.model._$enable_flutter">
							<div class="flex-100 flex-gt-xs-50 layout-column">
								<span class="flex-100 fake-label">Academic Term</span>
								<!-- <md-input-container class="flex-100 md-input-has-value" ng-class="{'md-input-invalid': (form.term_year.$touched || form.term_season.$touched) && (form.term_season.$invalid || form.term_year.$invalid)}">
									<label aria-hidden="true">Academic Term</label>
								</md-input-container> -->
								<div class="flex-100 layout-row">
									<md-input-container class="flex-none no-label" md-no-float style="max-width: 100px">
										<md-icon class="far fa-calendar"></md-icon>
										<input type="text" name="term_year"
											inputmode="numeric" pattern="^\d{4}$"
											aria-label="academic term year"
											ng-model="ctrl.model.flutter.term.year"
											ng-required="ctrl.model.flutter.term.season" md-no-asterisk
											ng-focus="form.term_year.$touched=true">
										<div ng-messages="form.term_year.$error" role="alert">
											<div ng-message="required">Required</div>
											<div ng-message="pattern">Invalid year</div>
										</div>
									</md-input-container>
									<md-input-container class="flex-none no-label">
										<md-select name="term_season"
											aria-label="academic term season"
											ng-model="ctrl.model.flutter.term.season" ng-model-options="{trackBy: '$value'}"
											ng-disabled="ctrl.isBusy || ctrl.isLocked || !ctrl.model._$enable_flutter" 
											ng-required="ctrl.model.flutter.term.year" md-truncate md-no-float md-no-asterisk
											ng-change="ctrl.model.flutter.term.season===null ? (ctrl.model.flutter.term.season=undefined) : null"
											ng-focus="form.term_year.$touched=true">
											<md-option ng-value="null"><i>- none -</i></md-option>
											<md-option ng-repeat="item in ctrl.mapping.seasons" ng-value="item._id">{{item.name}}</md-option>
										</md-select>
										<div class="md-errors-spacer"></div>
										<div ng-messages="form.term_season.$error" role="alert">
											<div ng-message="required">Required</div>
										</div>
									</md-input-container>
									
									<md-button class="flex-none md-icon-button md-warn md-hue-1 margin-0x" 
										aria-label="Clear Term" title="Clear Term"
										ng-click="ctrl.model.flutter.term={}" 
										ng-show="!ctrl.isLocked && (ctrl.model.flutter.term.year || ctrl.model.flutter.term.season)">
										<span class="fas fa-times"></span>
									</md-button>
								</div>
							</div>
						</div>

						<md-input-container class="flex-100 flex-gt-xs-33 md-input-has-placeholder">
							<label>Category</label>
							<md-select name="category" aria-label="category"
								ng-model="ctrl.model.flutter.category" ng-model-options="{trackBy: '$value'}" 
								ng-change="ctrl.updateCalendarValues(); ctrl.syncDates()"
								ng-required="ctrl.model._$enable_flutter && !ctrl.model.flutter.hide_from_flutter"
								ng-disabled="!ctrl.model._$enable_flutter || ctrl.isBusy || ctrl.isLocked">
								<md-option ng-repeat="item in ctrl.mapping.flutterCategories" ng-value="item._id">{{ item.name.en_US }}</md-option>
							</md-select>
							<div class="md-errors-spacer"></div>
							<div ng-messages="form.category.$error" role="alert">
								<div ng-message="required">Required</div>
							</div>
						</md-input-container>
						<md-input-container class="flex-100 flex-gt-xs-66 md-input-has-placeholder">
							<label>Tags</label>
							<md-select name="tags" aria-label="tags" multiple
								ng-model="ctrl.model.flutter.tags" ng-model-options="{trackBy: '$value'}" 
								ng-change="ctrl.updateCalendarValues(); ctrl.syncDates()"
								ng-disabled="!ctrl.model._$enable_flutter || ctrl.isBusy || ctrl.isLocked">
								<md-option ng-repeat="item in ctrl.mapping.flutterTags" ng-value="item._id">{{ item.name.en_US }}</md-option>
							</md-select>
							<div class="md-errors-spacer"></div>
						</md-input-container>
					</div>
				</div>

					<div class="panel-content" toggle-expand="ctrl._$open || form.link_text.$invalid || form.link.url.$invalid">
						<div class="more-options padding-4x" ng-click="ctrl._$open = ! ctrl._$open || form.link_text.$invalid || form.link.url.$invalid">
							<span class="fas fa-angle-down pull-right" ng-class="{toggled: ctrl._$open}" aria-label="more options"></span>
							More Options
						</div>
						<section class="expandable">
							<div class="margin-4x layout-row layout-wrap">
								<md-input-container class="flex-100 md-input-has-placeholder" form-disabled="!ctrl.model._$enable_flutter">
									<input type="text" name="short_name" maxlength="6"
										placeholder="Short Name for Calendar"
										ng-model="ctrl.model.flutter.short_name">
									<div ng-messages="form.short_name.$error" role="alert">
										<div ng-message="maxlength">Must be 6 characters or less</div>
									</div>
								</md-input-container>
								<md-input-container class="md-input-has-placeholder" flex="100">
									<label>Old Category</label>
									<md-select name="categories" md-truncate multiple
										ng-model="ctrl.model.categories" ng-model-options="{trackBy: '$value'}" 
										ng-disabled="ctrl.isBusy || ctrl.isLocked">
										<md-option ng-repeat="item in ctrl.mapping.categories" ng-value="item._id">{{item.name}}</md-option>
									</md-select>
									<div class="md-errors-spacer"></div>
								</md-input-container>
								<md-input-container flex="100" class="md-input-has-placeholder">
									<textarea name="source" rows="4"
										placeholder="Source"
										ng-model="ctrl.model.source"></textarea>
								</md-input-container>
								<md-input-container flex="100" class="md-input-has-placeholder">
									<textarea name="research" rows="4"
										placeholder="Research"
										ng-model="ctrl.model.research"></textarea>
								</md-input-container>
							</div>
						</section>
					</div>