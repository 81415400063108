import angular from 'angular'
import $ from 'jquery'
import BaseList from '../base.list'
import {Helper} from '../../common'

const INCH_TO_POINTS = 71.999999999999;
const INCH_TO_MM = 25.4;
const MM_TO_POINTS = 2.8346438836889;


let paperSizes = {
	Legal:{mm:[216,356],points:[612,1009],inches:[8.5,14]},
	Letter:{mm:[216,279],points:[612,791],inches:[8.5,11]},
	Tabloid:{mm:[279,432],points:[791,1225],inches:[11,17]},
	Ledger:{mm:[432,279],points:[1225,791],inches:[17,11]},
	Executive:{mm:[184.1,266.7],points:[522,756],inches:[7.25,10.55]},

	A4:{mm:[210,297],points:[595,842],inches:[8.3,11.7]},
	A3:{mm:[297,420],points:[842,1191],inches:[11.7,16.5]},
	A2:{mm:[420,594],points:[1191,1684],inches:[16.5,23.4]},
	A1:{mm:[594,841],points:[1684,2384],inches:[23.39,33.11]},
	A0:{mm:[841,1189],points:[2384,3370],inches:[33.11,46.81]},

	'Arch A':{mm:[229,305],points:[649,865],inches:[9,12]},
	'Arch B':{mm:[305,457],points:[865,1295],inches:[12,18]},
	'Arch C':{mm:[457,610],points:[1295,1729],inches:[18,24]},
	'Arch D':{mm:[610,914],points:[1729,2591],inches:[24,36]},
	'Arch E':{mm:[914,1219],points:[2591,3455],inches:[36,48]},

	B0:{mm:[1414,1000],points:[4008,2835],inches:[55.7,39.4]},
	B1:{mm:[1000,707],points:[2835,2004],inches:[39.4,27.8]},
	B2:{mm:[707,500],points:[2004,1417],inches:[27.8,19.7]},
	B3:{mm:[500,353],points:[1417,1001],inches:[19.7,13.9]},
	B4:{mm:[353,250],points:[1001,709],inches:[13.9,9.8]},
	B5:{mm:[250,176],points:[709,499],inches:[9.8,6.9]},
	B6:{mm:[176,125],points:[499,354],inches:[6.9,4.9]},
	B7:{mm:[125,88],points:[354,249],inches:[4.9,3.5]},
	B8:{mm:[88,62],points:[249,176],inches:[3.5,2.4]},
	B9:{mm:[62,44],points:[176,125],inches:[2.4,1.7]},
	B10:{mm:[44,31],points:[125,88],inches:[1.7,1.2]},

	Custom:{},
};


export default class MilestoneQRListController extends BaseList {
	static get $inject(){return [
		'data',
		'stepid',
		'api',
		'apiMap',
		'toast',
		'$q',
		'$mdDialog',
		'$mdSidenav',
		'$window',
		'$httpParamSerializer',
		'MAPPINGS_JSON',
	].concat(BaseList.$inject)}

	init(){
		this.mapping = {
			paperUnits: ['mm', 'inches', 'points'],
			qrUses: {
				'Multiple': true,
				'Single': false,
			},
			sortBy: Helper.superMap({
				_id: 'Batch Id', 
				quantity: 'Quantity',
				created_date: 'Date Created',
				created_by: 'Author',
			}),
			sortOrder: Helper.superMap({asc:'Asc', desc:'Desc'}),
		};
		this.mapping.paperSizes = [];
		angular.forEach(Helper.deepCopy(paperSizes), (data, name)=>{
			data.name = name;
			this.mapping.paperSizes.push(data);
			this.mapping.paperSizes[name] = data;
		});


		this.$q.all([
			this.apiMap.getQRDesign().then((data)=>this.mapping.designs = data),
			this.apiMap.getUsersCollegeAdminUp().then((data)=>this.mapping.users = data),
		]).then(()=>{
			this.pdfData.design = this.mapping.designs[0].name;
			this.changePaperSize(this.pdfData.paperSize = 'Letter', this.pdfData.paperUnit = 'mm');
			this.refresh();
		});
		this.isBusy = true;

		super.init();

		this.query = angular.extend({
				sortBy: '_id',
				sortOrder: 'desc',
			}, this.query);
		this.pdfData = {};
		this.qrData = {multi_use: false, quantity: 1};
	}

	refresh(reset){
		let params = angular.extend({
				sortBy: this.query.sortBy,
				sortOrder: this.query.sortOrder,
				limit: this.query.limit,
				offset: this.query.offset = (reset ? 0 : this.query.offset),
			}, {
				badgeId: this.data._id,
				stepId: this.stepid,
				withMetadata:true, 
				myTokenAdministers:true,
			});

		if ( reset ) this.dataList = [];
		return this._refresh('qr_batches', params);
	}

	process(list){
		return list;
	}


	createQR(){
		let payload = {
			badge_id: this.data._id,
			step_id: this.stepid,
			multi_use: this.qrData.multi_use,
			quantity: this.qrData.quantity,
		}
		this.api.post('qr_batches', payload)
			.then(()=>{
				this.toast.success('QR Batch Created');
				this.refresh(true);
				this.$mdSidenav('qr-form').close();
			})
			.catch((err)=>{
				if ( this.api.isApiError(err) ) {
					err.name = 'Request Failed';
					this.toast.error(err);
				}
			})
			.finally(()=>this.isBusy = false);

		this.isBusy = true;
	}


	makeLive(id, $ev){
		this.$mdDialog.show(
			this.$mdDialog.confirm()
					.title('QR Batch Live')
					.textContent('Are you sure you want to make this batch live?')
					.ariaLabel('QR Batch Live')
					.targetEvent($ev)
					.ok('Ok')
					.cancel('Cancel')
		).then(()=>{
			this.isBusy = true;
			let payload = {id: id, set: [{'$.is_live':true}], delete: ['$.fake_path']};
			return this.api.post('qr_batches/update', payload)
		})
		.then(()=>{
			this.toast.success('QR Batch #'+ id +' is now Live');
			return this.refresh(true);
		})
		.catch((err)=>{
			if ( this.api.isApiError(err) ) {
				err.name = 'Request Failed';
				this.toast.error(err);
			}
		})
		.finally(()=>this.isBusy = false);
	}


	changePaperSize(name, unit){
		let preset = this.mapping.paperSizes[name];
		if ( preset && name !== 'Custom' ) {
			this.pdfData.paperWidth = preset[unit][0];
			this.pdfData.paperHeight = preset[unit][1];
			return true;
		}
		return false;
	}
	changePaperUnit(){
		if ( ! this.changePaperSize(this.pdfData.paperSize, this.pdfData.paperUnit) ) {
			var m = 1; // get conversion multiplier
			switch( this.pdfData._oldunit ) {// old unit
				case 'mm':
					if ( this.pdfData.paperUnit === 'inches' )
						m = 1 / INCH_TO_MM;
					else if ( this.pdfData.paperUnit === 'points' )
						m = MM_TO_POINTS;
					break;
				case 'inches':
					if ( this.pdfData.paperUnit === 'mm' )
						m = INCH_TO_MM;
					else if ( this.pdfData.paperUnit === 'points' )
						m = INCH_TO_POINTS;
					break;
				case 'points':
					if ( this.pdfData.paperUnit === 'mm' )
						m = 1 / MM_TO_POINTS;
					else if ( this.pdfData.paperUnit === 'inches' )
						m = 1 / INCH_TO_POINTS;
					break;
			}
			// convert our dimensions
			this.pdfData.paperWidth = Math.floor(this.pdfData.paperWidth * m * 100) / 100;
			this.pdfData.paperHeight = Math.floor(this.pdfData.paperHeight * m * 100) / 100;
		}
		this.pdfData._oldunit = this.pdfData.paperUnit;
	}
	changePaperDimension(width, height){
		if ( this.pdfData.paperSize != 'Custom' ) {
			let preset = this.mapping.paperSizes[this.pdfData.paperSize];
			let unit = this.pdfData.paperUnit;
			if ( preset[unit][0] !== width || preset[unit][1] !== height )
				this.pdfData.paperSize = 'Custom';
		}
	}

	getPDF(){
		let params = {
			format: 'pdf',
			pageSize: this.pdfData.paperSize.toLowerCase(),
			pageWidth: this.pdfData.paperWidth,
			pageHeight: this.pdfData.paperHeight,
			pageMetrics: this.pdfData.paperUnit,
			design: this.pdfData.design,
			token: this.authentication.getToken().replace('Bearer ', ''),
		};

		// for custom sizes
		if ( this.pdfData.paperSize == 'Custom' )
			delete params.pageSize;

		if ( params.pageMetrics === 'mm' ) {
			params.pageMetrics = 'points';
			params.pageWidth = Math.ceil(params.paperWidth * MM_TO_POINTS);
			params.pageHeight = Math.ceil(params.paperHeight * MM_TO_POINTS);
		} else
		if ( params.pageMetrics === 'points' ) {
			params.pageWidth = Math.ceil(params.pageWidth);
			params.pageHeight = Math.ceil(params.pageWidth);
		}

		var url = this.$window.API_URI +'qr_batches/'+ this.batch._id +'?'+ this.$httpParamSerializer(params);
		this.$window.open(url, '_blank');
		this.closePDF();
	}


}