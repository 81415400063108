import { filter } from '@uirouter/core';
import angular from 'angular'
import {Helper, ApiError, SETTINGS, MESSAGES} from '../../common'
import BaseSingleController from '../base.single'


export const B12_COHORT = 'beyond12_cohort';
export const B12_COACH = 'beyond12_coaches';
export const B12_COACH_CORPS = 'beyond12_coaching_corps_coaches';

//
let anchorImg = require('../../common/images/point.gif');


export default class CohortSingleController extends BaseSingleController {
	static get $inject(){return BaseSingleController.$inject.concat([
		'parentStateParams',
	])}


	init(){
    const cohortNameKeys = this.MAPPINGS_JSON?.cohort_types?.name_key;

		this.mapping = {
      types: {
        'beyond12_cohort': cohortNameKeys?.[B12_COHORT] || 'Non-Coach',
        'beyond12_coaches': cohortNameKeys?.[B12_COACH_CORPS] || 'Coach',
        'beyond12_coaching_corps_coaches': cohortNameKeys?.[B12_COACH_CORPS] || 'Coaching Corps Coach',
      },
		};
		
    super.init();
	}
	_loadDependencies(){
		return this.$q.all([
      this.apiMap.getCohortCoaches().then(data=>this.mapping.coachCohorts=data),
      this.apiMap.getCohortCorpsCoaches().then(data=>this.mapping.corpsCohorts=data),
			this.apiMap.getCohortTypes().then(data=>this.mapping.cohortTypes=data),
			this.apiMap.getUsersNonStudents().then(data=>this.mapping.users=data.sort(Helper.sortByPath('user_profile.first_name'))),
			this.apiMap.getActiveUsersCoach().then(data=>this.mapping.coaches=data.sort(Helper.sortByPath('user_profile.first_name'))),
		])
		.then(()=>{
      this.model = this.createModel(this.data || {});

      this._$assigned = {
        coach: this.mapping.coachCohorts.map(cohort=>cohort._id!=this.data?._id && +cohort.owner_user_id).filter(v=>v).filter(Helper.uniqueFilter),
        coaching_corps_coach: this.mapping.corpsCohorts.map(cohort=>cohort._id!=this.data?._id && +cohort.owner_user_id).filter(v=>v).filter(Helper.uniqueFilter),
      };

      this._$coaches = this.findCoach();

			if ( this.$scope.form?.description ) {
				this._$descriptionDups = [];
				this.$scope.form.description.$validators.duplicate = (modelValue, viewValue)=>!viewValue || !this._$descriptionDups.includes(viewValue.toLowerCase());
				this._$salesforceTagDups = [];
				this.$scope.form.salesforce_tag.$validators.duplicate = (modelValue, viewValue)=>!viewValue || !this._$salesforceTagDups.includes(viewValue.toLowerCase());
			}
		})
		.then(()=>super._loadDependencies());
	}

	createModel(data){
		let model = Helper.deepCopy(data);

    model.description = model.description || this.MAPPINGS_JSON.cohorts.name_key[data.name] || data.name;

    if ( model.cohort_type && ! [B12_COHORT,B12_COACH,B12_COACH_CORPS].includes(model.cohort_type) ) {
      this.isLocked = true;
      this.lockReason = 'Non-Beyond12 cohorts are disabled for editing.';
      this.mapping.types[model.cohort_type] = model.cohort_type;
    }

		return model;
	}

	submit($evt, form){
		return this.$q.when(this._validateForm(form))
      .then(()=>this._preparePayload())
			.then(payload=>{
				this.isBusy = true;

				if ( this.data ) {
					return this.api.put('cohorts/'+ this.data._id, payload, {level: ApiError.LEVEL.MANUAL})
						.then(res=>{
							this.toast.success('Changes Saved');
							return res;
						});
				} else {
					return this.api.post('cohorts', payload, {level: ApiError.LEVEL.MANUAL})
						.then(res=>{
							this.toast.success('New Cohort Saved');
							return res;
						});
				}
			})
			.then(res=>{
				this.promptExit.disable();
				this.$state.go('^', this.parentStateParams);
      }, err=>{
				if ( this.api.isApiError(err) ) {
					if ( err.response?.data?.error_message ) {
						switch( err.response.data.error_code ) {
							case 422:
								this._$descriptionDups.push(err.response.config.data.description.toLowerCase());
								this.$scope.$evalAsync(()=>this.$scope.form.description.$validate());
								this.toast.warn(err.response.data.error_message);
								break;
							case 421:
								this._$salesforceTagDups.push(err.response.config.data.salesforce_tag.toLowerCase());
								this.$scope.$evalAsync(()=>this.$scope.form.salesforce_tag.$validate());
								this.toast.warn(err.response.data.error_message);
								break;
							default:
								let e = new Error(err.response.data.error_message);
								e.title = 'Unable to Save';
								this.errorPrompt.show(e, null, {noDebug:true, noRefresh:true, expected:true});
								break;
						}
					} else {
						err.title = 'Unable to Save';
						this.toast.error(err);
					}
				} else if ( err instanceof Error ) {
					this.errorPrompt.show(err);
					console.error(err);
				} else {
					console.error(err);
				}
			}).finally(()=>this.isBusy = false);
	}

	_preparePayload(){
		const payload = Helper.deepCopy(this.data||{});
		const model = Helper.deepCopy(this.model);

    // if ( ! this.data ) {
    //   this.updateNameID();
    //   payload.name = this.model.name;
    // }

		payload.cohort_type = model.cohort_type;
		payload.description = model.description;

    payload.salesforce_tag = model.cohort_type == B12_COHORT && model.salesforce_tag || undefined;
    payload.owner_user_id = [B12_COACH, B12_COACH_CORPS].includes(model.cohort_type) && +model.owner_user_id || undefined;

		// if ( this.data ) {
		// 	payload.modified_by = +this.authorization.userId;
		// 	payload.modified_date = Helper.toTimestamp(new Date());
		// } else {
		// 	payload.created_by = +this.authorization.userId;
		// 	payload.created_date = Helper.toTimestamp(new Date());
		// }

		return payload;
	}


  typeChanged(){
    this._$coaches = this.findCoach(this.findCoach.search='');

    const model = this.model;
    if ( model.owner_user_id ) {
      let user = this.mapping.users.byId[model.owner_user_id];
      if ( user && ((model.cohort_type == B12_COACH && user.roles.coach) || (model.cohort_type == B12_COACH_CORPS && user.roles.coaching_corps_coach)) ) {
        return true;
      }
      model.owner_user_id = null;
    }
  }
  updateNameID(){
    const model = this.model;
    if ( ! this.data && model.description ) {
      model.name = model.description.replace(/[^a-z0-9]+/gim, '_').replace(/(^_|_$)/gim, '').toLowerCase();
    }
  }

  findCoach(str=''){
    const model = this.model;
    str = str.toLowerCase();

    return this.mapping.coaches
      .filter(user=>(model.cohort_type == B12_COACH && user.roles.coach && !this._$assigned.coach.includes(+user._id)) || (model.cohort_type == B12_COACH_CORPS && user.roles.coaching_corps_coach && !this._$assigned.coaching_corps_coach.includes(+user._id)))
      .filter(user=>!str || (user.first_name + user.last_name).toLowerCase().indexOf(str) > -1);
  }

}

