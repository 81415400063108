import angular from 'angular'
import $ from 'jquery'
import {DependencyInjected, Helper} from '../../common'
import {menuData} from '../main.controller'


const MSG_REGEXP = /^(.*) ((\w+) with id (\d+))(.*)/;

export default class SiteMapController extends DependencyInjected {
	static get $inject(){return [
		'$q',
		'$state',
		'$uiRouter',
		'$injector',
		'authorization',
	]}

	
	init(){
		let copy = Helper.deepCopy(menuData);
		copy.unshift({
			name: 'My Profile',
			sref: 'app.profile',
			children: [{name: 'Logout', sref: 'app.logout'}],
		});

		this.menuList = this._prepareMenuItems(copy);
	}
	
	_prepareMenuItems(list){
		return list
			.filter((v)=>this._checkItemPermission(v))
			.map((v,i)=>this._mapItemChildren(v,i));
	}

	_checkItemPermission(item){
		let reg = this.$uiRouter.stateRegistry.get(item.sref);
		return !reg?.access || this.authorization.hasPermissionAndLevel(reg.access);
	}

	_mapItemChildren(item, index){
		item.id = item.sref.replaceAll('.', '_');
		if ( item.children ) {
			item.children = this._prepareMenuItems(item.children);
			item.children.forEach((subitem)=>subitem._$parent = item);
		}
		return item;
	}

	go(item, $ev){
		this.$state.go(item.sref, angular.merge({nonce: Math.random(), scroll: -1}, item.srefparams||{}), angular.merge({inherit: false}, item.srefopts||{}));
		$ev.preventDefault();
		$ev.stopPropagation();
	}

	getLink(item){
		return this.$state.href(item.sref, angular.merge({}, item.srefparams||{}), {inherit: false});
	}

}