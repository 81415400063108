import AnalyticsController from './analytics.controller'
import OverviewController from './overview.controller'
import NotificationsPerDateController from './notificationsPerDate.controller'
import NotificationsPerCategoryController from './notificationsPerCategory.controller'
import NotificationsPerCollegeController from './notificationsPerCollege.controller'
import StudentsPerDateController from './studentsPerDate.controller'
import StudentsPerMotivationController from './studentsPerMotivation.controller'
import StudentsPerLevelController from './studentsPerLevel.controller'
import StudentsPerCollegeController from './studentsPerCollege.controller'
import ReportMonthlyController from './report.monthly.controller'
import QuestionsController from './questions.controller'
import {PERMISSIONS, PERMISSION_LEVELS} from '../../common'
import './analytics.scss'


export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {

	const $stateRegistry = $uiRouterProvider.stateRegistry;

	$stateRegistry.register({
		name: 'app.analytics',
		title: 'Analytics Overview',
		url: 'analytics',
		access: {
			[PERMISSIONS.ANALYZE_NOTIFICATIONS_PER_TYPE]: true, 
			[PERMISSIONS.ANALYZE_STUDENTS_PER_ACTIVE_STATUS]: true, 
			[PERMISSIONS.ANALYZE_STUDENTS_PER_DEVICE_OS]: true,
		},
		views: {
			'content@app': {
				template: require('./analytics.html'),
				controller: AnalyticsController,
				controllerAs: 'mainCtrl',
			},
			'main@app.analytics': {
				template: require('./overview.html'),
				controller: OverviewController,
				controllerAs: 'ctrl',
			}
		},
		params: {
			nonce: 0,
		},
	});

	$stateRegistry.register({
		name: 'app.analytics.questionsReport',
		title: 'Questions Results',
		url: '/questions-report?college',
		access: {[PERMISSIONS.ANALYZE_ANSWERS_PER_COLLEGE_QUESTION]: true},
		views: {
			'content@app': {
				template: require('./questions.html'),
				controller: QuestionsController,
				controllerAs: 'ctrl',
			}
		},
		params: {
			college: {inherit:false, dynamic:true},
		},
	});

	$stateRegistry.register({
		name: 'app.analytics.monthlyReport',
		title: ['$stateParams', 'api', ($stateParams, api)=>{
			if ( $stateParams.college )
				return api.get('colleges/'+ $stateParams.college).then(res=>res.data.name +' — Monthly Report');
			return 'Monthly Report';
		}],
		url: '/monthly-report?college',
		access: {
			[PERMISSIONS.ANALYZE_COLLEGE_STUDENT_REPORT]: true, 
			[PERMISSIONS.ANALYZE_COLLEGE_COHORT_REPORT]: true, 
			[PERMISSIONS.ANALYZE_COLLEGE_MILESTONE_REPORT]: true,
		},
		views: {
			'content@app': {
				template: require('./report.monthly.html'),
				controller: ReportMonthlyController,
				controllerAs: 'ctrl',
			},
		},
		params: {
			college: {inherit:false, dynamic:true},
		}
	});



	let mainTemplate = require('./template.main.html');

	$stateRegistry.register({
		name: 'app.analytics.notificationsPerDate',
		title: 'Notifications Per Date',
		url: '/notifications/per-date',
		access: {[PERMISSIONS.ANALYZE_NOTIFICATIONS_HEAT_MAP]: true},
		views: {
			'main@app.analytics': {
				template: mainTemplate,
				controller: NotificationsPerDateController,
				controllerAs: 'ctrl',
			},
		},
	});

	$stateRegistry.register({
		name: 'app.analytics.notificationsPerCategory',
		title: 'Notifications Per Category',
		url: '/notifications/per-category',
		access: {[PERMISSIONS.ANALYZE_NOTIFICATIONS_PER_CATEGORY]: true},
		views: {
			'main@app.analytics': {
				template: mainTemplate,
				controller: NotificationsPerCategoryController,
				controllerAs: 'ctrl',
			},
		},
	});

	$stateRegistry.register({
		name: 'app.analytics.notificationsPerCollege',
		title: 'Notifications Per College',
		url: '/notifications/per-college',
		access: {[PERMISSIONS.ANALYZE_NOTIFICATIONS_PER_COLLEGE]: true},
		views: {
			'main@app.analytics': {
				template: mainTemplate,
				controller: NotificationsPerCollegeController,
				controllerAs: 'ctrl',
			},
		},
	});

	$stateRegistry.register({
		name: 'app.analytics.studentsPerDate',
		title: 'Students Per Date',
		url: '/students/per-date',
		access: {[PERMISSIONS.ANALYZE_NEW_STUDENTS_PER_DATE]: true},
		views: {
			'main@app.analytics': {
				template: mainTemplate,
				controller: StudentsPerDateController,
				controllerAs: 'ctrl',
			},
		},
	});

	$stateRegistry.register({
		name: 'app.analytics.studentsPerMotivation',
		title: 'Students Per Motivation',
		url: '/students/per-motivation',
		access: {[PERMISSIONS.ANALYZE_STUDENTS_PER_MOTIVATION]: true},
		views: {
			'main@app.analytics': {
				template: mainTemplate,
				controller: StudentsPerMotivationController,
				controllerAs: 'ctrl',
			},
		},
	});

	$stateRegistry.register({
		name: 'app.analytics.studentsPerLevel',
		title: 'Students Per Level',
		url: '/students/per-level',
		access: {[PERMISSIONS.ANALYZE_STUDENTS_PER_COLLEGE_LEVEL]: true},
		views: {
			'main@app.analytics': {
				template: mainTemplate,
				controller: StudentsPerLevelController,
				controllerAs: 'ctrl',
			},
		},
	});

	$stateRegistry.register({
		name: 'app.analytics.studentsPerCollege',
		title: 'Students Per College',
		url: '/students/per-college',
		access: {[PERMISSIONS.ANALYZE_STUDENTS_PER_COLLEGE]: true},
		views: {
			'main@app.analytics': {
				template: mainTemplate,
				controller: StudentsPerCollegeController,
				controllerAs: 'ctrl',
			},
		},
	});

}];
