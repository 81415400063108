import angular from 'angular'
import $, { removeData } from 'jquery'
import BaseList from '../base.list'
import {DependencyInjected, Helper, OP_TO_WORD, PERMISSIONS} from '../../common'



export default class WorkspacesListController extends BaseList {
	static get $inject(){return [
		'$q',
		'$animateCss',
		'api',
		'apiMap',
		'MAPPINGS_JSON',
		'recipient',
		'authorization',
		'toast',
		'errorPrompt',
		'$mdDialog',
	].concat(BaseList.$inject)}

	init(){
		this.mapping = {
			fileStatus: Helper.superMap(angular.extend({
				unpublished: 'Unpublished', 
				published: 'Published', 
				processed: 'Published', 
				partially_published: 'Partially Published', 
				content_moved: 'Content Moved'
			}, this.MAPPINGS_JSON.file_process.status), {type:'file_status'}),
			status: Helper.superMap(this.MAPPINGS_JSON.notification.status, {type:'status'}),
			types: Helper.superMap(angular.extend({tip_theme:'Tip Theme', events:'Events', tips:'Tips'}, this.MAPPINGS_JSON.notifications.type_key), {type:'type'}),
			categories: Helper.superMap(this.MAPPINGS_JSON.notifications.category_key, {type:'category'}),
			approvalStatus: Helper.superMap({
				approved: 'Approved', 
				held: 'Hold',
				'undefined': 'Unreviewed',
			}),
			sortBy: Helper.superMap({
				file_name: 'File Name', 
				upload_date: 'Upload Date',
				admin_user_id: 'Author',
				record_count: 'Record Count',
			}),
			sortOrder: Helper.superMap({asc:'Asc', desc:'Desc'}),
		};

		this.hideReminders = true;
		this._isMergeAllowed = this.authorization.hasPermission(PERMISSIONS.MERGE_WORKSPACES);

		super.init();
		
		this.filters._$keys = {
			'status': 'Status',
		};
	}
	_loadDependencies(){
		return this.$q.all([
			this.apiMap.getActiveColleges().then((data)=>this.mapping.mycolleges = data),
			this.apiMap.getColleges().then(data=>this.mapping.mycolleges = data),
			this.apiMap.getAllDistricts().then(data=>this.mapping.districts = data),
			this.apiMap.getAllProvidedCohorts().then(data=>this.mapping.cohorts = data),
			this.apiMap.getAllCohorts().then(data=>this.mapping.allcohorts = data),
			this.apiMap.getCohortLevels().then(data=>this.mapping.mylevels = data),
			this.apiMap.getUsersCollegeAdminUp().then(data=>this.mapping.users = data),
		])
			.then(()=>this.recipient.prepare())
			.then(()=>{
				this.onUrlParamChange(this.$state.params);
				this.refresh(true)
					.then(()=>this._loadScrollState())
			});
	}


	onUrlParamChange(obj){
		// if ( obj.hasOwnProperty('sortBy') )
		// 	this.query.sortBy = obj.sortBy || 'processed_date';
		// if ( obj.hasOwnProperty('sortOrder') )
		// 	this.query.sortOrder = obj.sortOrder || 'desc';
		if ( obj.hasOwnProperty('status') )
			this.filters._status = (obj.status || []);//.map(id=>this.mapping.fileStatus.byId[id]);
		
		let keys = ['status'];
		return !! Object.keys(obj).find(val=>keys.includes(val));
	}

	getParams(reset){
		return angular.extend(
			{
				sortBy: 'processed_date',
				sortOrder: 'desc',
			}, 
			super.getParams(reset)
		);
	}
	getFilters(all){
		return {
			status: (this.filters._status || []),//.map((item)=>item._id),
			// sortBy: this.query.sortBy || undefined,
			// sortOrder: this.query.sortOrder || undefined,
		};
	}

	refresh(reset){
		if ( reset ) {
			this.dataList = [];
			this.itemIndex = 0;
			this._errors = [];
		}
		return this._refresh('workspaces', this.getParams(reset));
	}

	process(list){
		let ids = list.filter(item=>['unpublished', 'partially_published'].includes(item.status)).map(item=>item._id);

		if ( ids.length > 0 ) {
			const isFirstCheck = this.dataList.length == 0;

			this.api.get('analysis/approvalStatus', {batchIds: ids})
				.then(res=>{
					Object.keys(res.data).forEach(id=>{
						let data = res.data[id];
						data.num_approved_perc = Math.round(data.num_approved / data.total * 100);
						data.num_held_perc = Math.round(data.num_held / data.total * 100);
						data.num_unapproved_perc = Math.round(data.num_unapproved / data.total * 100);

						let item = list.find(item=>item._id == id);
						if ( item ) {
							item.approvalStatus = data;
						}
					});
				}, err=>{
					if ( isFirstCheck )
						this.toast.warn('Batch approval data unavailable');
				});
		}

		return list;
	}

	_processList(list, type){
		let missingUsers = [];

		list.forEach((item)=>{
			let groups = Object.keys(item.recipients || {}),
				exprs = Object.values(item.recipients || {}),
				chips = item._chips = [];
			exprs.forEach((expr, i)=>{
				const list = this.recipient.enumerate(expr, {ignoreInvalids:true, ignoreErrors:true});
				list.forEach(chip=>chip.opWord = OP_TO_WORD[chip.op]);
				Array.prototype.splice.apply(chips, [0,0].concat(list));
			});

			item._$type = type;
			if ( type != 'tip_theme' )
				item._$href = this.$state.href(`app.${type}.edit`, {id: item._id});

			item.reminders = Object.keys(item.reminders || {}).map((key)=>{
				item.reminders[key]._id = parseInt(key);
				return item.reminders[key];
			});

			if ( item.created_by_user_id && ! this.mapping.users.byId[item.created_by_user_id] && ! missingUsers.includes(item.created_by_user_id) )
				missingUsers.push( item.created_by_user_id );
			if ( item.modified_by_user_id && ! this.mapping.users.byId[item.modified_by_user_id] && ! missingUsers.includes(item.modified_by_user_id) )
				missingUsers.push( item.modified_by_user_id );

			if ( item.notification_type == 'tip_theme' )
				item._$noEdit = true;
		});

		missingUsers.forEach((id)=>this.api.get('users/'+ id, null, {cache:true})
			.then((res)=>{
				if ( ! this.mapping.users.byId[id] )
					this.mapping.users.push(this.mapping.users.byId[id] = res.data);
			}, angular.noop));

		return list;
	}


	_open(item){
		const defer = this.$q.defer();

		this.selected = item;
		item.isBusy = true;

		if ( item.notifications ) {
			this.$timeout(()=>defer.resolve(item.isBusy = false), 800);	
			return defer.promise;

		} else {
			item.notifications = [];
			const params = {
				workspace: item._id,
				// myTokenAdministers: true,
				// publish: true,
				sortBy: 'date_to_send',
				sortOrder: 'desc',
			};

			this.api.get('events', params).then(res=>item.notifications.push(...this._processList(res.data, 'events')))
				.then(()=>this.api.get('tips', params).then(res=>item.notifications.push(...this._processList(res.data, 'tips'))))
				.then(()=>this.api.get('tipThemes', params).then(res=>item.notifications.push(...this._processList(res.data, 'tip_theme'))))
				.then(()=>{
					console.log('$q.all', arguments);
					item.notifications.sort(Helper.sortByPath('date_to_send'));
					if ( item.status === 'processed' ) {
						item.notifications.forEach(item=>item.publish = true);
					}
					return this.$timeout(()=>defer.resolve(item.isBusy = false), 800);
				})
				.catch(err=>{
					if ( this.api.isApiError(err) && ! err.isIgnored() ) {
						err.message = 'Resource unavailable';
						this.toast.error(err);
						item.error = err;
					}
					throw err;
				})
				.finally(()=>item.isBusy = false);
		}

		return defer.promise;
	}

	rename(item, $ev){
		return this.$mdDialog.show({
      controller: RenameController,
			controllerAs: 'rename',
      templateUrl: '/workspace.rename.tpl',
      parent: $('body'),
      targetEvent: $ev,
      clickOutsideToClose: false,
			scope: this.$scope.$new(false),
			locals: {batch: item},
			bindToController: true,
		});
	}

	

	publish(item, $ev){
			this.$mdDialog.show(
				this.$mdDialog.confirm()
						.title('Publish Workspace')
						.textContent(`Are you sure you want to publish "${item.file_name}"?`)
						.ariaLabel('confirm publish')
						.targetEvent($ev)
						.ok('Publish')
						.cancel('Cancel')
			).then(()=>{
				this.isBusy = true;
				return this.api.post('workspaces/'+item._id+'/publish');
			})
			.then(()=>{
				this.toast.success(`Workspace "${item.file_name}" Published`);
				this.refresh(true);
			})
			.finally(()=>this.isBusy = false);
	}

	delete(item, $ev){
		this.$mdDialog.show(
			this.$mdDialog.confirm()
					.title('Delete Notification Batch')
					.textContent(`Are you sure you want to delete "${item.file_name}"?`)
					.ariaLabel('confirm delete')
					.targetEvent($ev)
					.ok('Delete')
					.cancel('Cancel')
		).then(()=>{
			this.isBusy = true;
			return this.api.delete('workspaces/'+item._id+'/bulk');
		})
		.then(()=>{
			this.toast.success(`Workspace "${item.file_name}" Deleted`);
			this.refresh(true);
		})
		.finally(()=>this.isBusy = false);
	}

	async export(item, $ev){
		await this._exportCSV(`workspaces/${item._id}?format=csv`, item.file_name);
	}

	get isMergeAllowed(){
		return this._isMergeAllowed;	
	}
	enterMergeMode(){
		if ( this.isMergeAllowed ) {
			this.selected && this.close();
			this.mergeMode = true;
			this.mergeSelected = [];
		}
	}
	exitMergeMode(){
		this.mergeMode = false;
		this.mergeSelected = [];
	}

	canMerge(item){
		return item && ['unpublished', 'published', 'partially_published'].includes(item.status) && item.record_count > 0;
	}
	selectForMerge(item, value){
		if ( !this.isMergeAllowed || !this.canMerge(item) )
			return;

		if ( value === undefined ) { // if value is not set, use item flag
			value = !! item._selected;
		} else {
			item._selected = !! value;
		}

		if ( value ) {
			if ( ! this.mergeSelected.includes(item) )
				this.mergeSelected.push(item);
		} else {
			if ( this.mergeSelected.includes(item) )
				this.mergeSelected.splice(this.mergeSelected.indexOf(item), 1);
		}
	}


	startMerge($ev){
		if ( ! this.isMergeAllowed ) return;
		if ( this.mergeSelected.length < 2 ) {
			return this.$mdDialog.show(
				this.$mdDialog.alert()
					.title('Batch Merge')
					.htmlContent('Select 2 or more batches to merge.')
					.ariaLabel('alert')
					.targetEvent($ev)
					.ok('Ok')
			);
		}
		
		const selected = this.mergeSelected;
		const name = selected.map(item=>item.file_name).join(' and ');
		const colleges = selected.map(item=>this.mapping.mycolleges.byId[item.college_id]).filter((value, index, self)=>self.indexOf(value) === index);

		return this.$mdDialog.show({
			controller: MergeController,
			controllerAs: 'merge',
			templateUrl: '/notifications.batch.merge.tpl',
			parent: $('body'),
			targetEvent: $ev,
			clickOutsideToClose: false,
			scope: this.$scope.$new(false),
			locals: {name, selected, colleges},
			bindToController: true,
      // fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
   		 })
		.then(isDone=>{
			this.exitMergeMode();
			this.refresh(true);
    	});
	}
}

class RenameController extends DependencyInjected {
	static get $inject(){return [
		'$scope',
		'$mdDialog',
		'api',
		'toast',
		'batch',
	]}

	init(){
		this.name = this.batch.file_name;
	}


	cancel() {
		this.$mdDialog.cancel();
	}
	confirm(form) {
		if ( form.$valid ) {
			let payload = Helper.deepCopy(this.batch);
			payload.file_name = this.name;

			this.busy = true;
			
			return this.api.put('workspaces/'+ this.batch._id, payload)
				.then(()=>{
					this.$mdDialog.hide(this.batch.file_name = this.name);
				}, ()=>{
					this.busy = false;
				})
		}
	}
}

class MergeController extends DependencyInjected {
	static get $inject(){return [
		'$scope',
		'$mdDialog',
		'api',
		'toast',
		'name',
		'selected',
		'colleges',
	]}

	init(){
		if ( this.colleges.length == 1 )
			this.college = this.colleges[0];
		
		this.total = this.selected.map(item=>item.record_count).reduce((total, value)=>total + value, 0);
	}

	cancel() {
		this.$mdDialog.cancel();
	}
	confirm(form) {
		if ( form.$valid ) {
			let ids = this.selected.map(item=>+item._id);
			ids.splice(ids.indexOf(+this.target._id), 1);

			const payload = {
				merge_to_id: +this.target._id,
				merge_from_ids: ids,
				new_college_id: +this.college._id || undefined,
				new_file_name: this.name || undefined,
			};

			console.log(payload);

			this.busy = true;
			return this.api.post('workspaces/merge', payload)
				.then(res=>{
					this.toast.success('Merge Successful');
					this.$mdDialog.hide(true);
				}, err=>{
					console.error('merge failed', err);
					this.busy = false;
				});
		} else {
			Object.values(form.$error || {}).forEach(list=>list.forEach($item=>{
				$item.$setTouched && $item.$setTouched();
			}));
		}
	}
}

