<div class="item-tpl flex layout-row layout-wrap" ng-class="{published: item.publish || item.publish === undefined}">
	<div class="data-id flex-xs-100 flex-sm-100 layout-row layout-align-space-between-center layout-wrap layout-gt-sm-column layout-align-gt-sm-space-between-end padding-2x">
		<div class="date">{{ item.date_to_send | amDateFormat:SETTINGS.dateFormat }}</div>
		<div>
			<small class="small-tag md-caption no-animate status" ng-class="item.status.toLowerCase()">
				<i ng-class="{'fas fa-check':item.status=='sent', 'far fa-envelope':item.status=='Sending', 'far fa-clock':item.status=='not_sent'}" role="img" aria-hidden="true"></i>
				{{ ctrl.mapping.status.byId[item.status].name || item.status }}
			</small>
			<small class="small-tag md-caption no-animate approval" ng-class="item.approval_status.toLowerCase()">
				<i ng-class="{'fas fa-thumbs-up':item.approval_status=='approved', 'fas fa-thumbs-down':item.approval_status=='held'}" ng-hide="item.publish_status=='published'" role="img" aria-hidden="true"></i>
				{{ ctrl.mapping.fileStatus.byId[item.publish_status].name || ctrl.mapping.approvalStatus.byId[item.approval_status].name || item.approval_status }}
			</small>
			</div>
			<div hide-xs hide-sm flex-gt-sm></div>
		<div>
			<a id="id_item-{{item._id}}" class="md-caption md-block no-animate"
				aria-label="{{::ctrl._$resource}} ID #{{item._id}}"
				ng-if="item._$href" ng-href="{{item._$href}}">ID#{{ item._id }}</a>
			<span class="md-caption md-block no-animate" ng-if="!item._$href">ID#{{ item._id }}</span>
		</div>
		<div hide-xs hide-sm flex-gt-sm></div>

		<div class="md-caption item-owner text-right md-truncate">
			<span class="no-animate" ng-show="item.created_by_user_id || item.modified_by_user_id">
				{{ item.modified_by_user_id ? 'Updated' : (item.batch_id ? 'CSV' : 'Created') }}
				by
				<span title="{{ ctrl.mapping.users.byId[item.modified_by_user_id || item.created_by_user_id].user_profile.first_name || item.modified_by_user_id || item.created_by_user_id }}">
					{{ ctrl.mapping.users.byId[item.modified_by_user_id || item.created_by_user_id].user_profile.first_name || item.modified_by_user_id || item.created_by_user_id }}
				</span>
				<br class="hide-xs hide-sm" aria-hidden="true">
			</span>
			<span class="no-wrap">{{ (item.modified_date || item.created_date || item.date_created) | amDateFormat:SETTINGS.dateTimeFormat }}</span>
		</div>
	</div>


	<div class="data-content flex layout-column layout-align-space-between-stretch padding-2x" ng-class="ctrl._$resource">
		<h3 id="title_item-{{item._id}}" class="md-subhead">
			<a ng-if="item._$href" ng-href="{{item._$href}}" class="md-truncate no-animate" ng-bind-html="item.call_to_action || item.text.raw"></a>
			<span ng-if="!item._$href" class="no-animate" ng-bind-html="item.call_to_action || item.text.raw"></span>
			<em class="md-caption black-25 no-animate" ng-show="!item.call_to_action && !item.text.raw && !item.publish">(no content yet)</em>
		</h3>
		<div class="item-description md-truncate black-66 no-animate" ng-show="item.call_to_action">{{ item.text.raw }}</div>


		<span flex></span>
		<div class="layout-row layout-align-space-between-start">
			<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_ITEM_CHIPS -->

			<md-button class="action-btn md-button flex-nogrow flex-gt-md-initial layout-row layout-align-start-start black-50 margin-0x"
				ng-class="{
					'summary-3': item.reminders._summaries.length > 3, 
					'summary-1': item.reminders._summaries.length == 1,
					'summary-0': item.reminders._summaries.length == 0,
				}"
				aria-label="{{ctrl.selected ? 'hide' : 'show '+item.reminders.length}} reminders"
				aria-expanded="{{ ctrl.selected == item }}"
				ng-show="!ctrl.hideReminders" 
				ng-click="ctrl.selected !== item ? ctrl.open(item) : ctrl.close(); $event.stopPropagation();"
				ng-disabled="!item.reminders.length">
				<span class="layout-row" ng-hide="ctrl.selected==item">
					<span class="flex-none">
						<i class="far" ng-class="{'fa-bell-slash': !item.reminders.length, 'fa-bell': item.reminders.length}"></i>
						{{ item.reminders.length }}
						<span class="hide-xs hide-sm">Reminder{{ item.reminders.length!=1 ? 's':'' }}</span>
						&nbsp;
					</span>
					<em class="summary hide-xs hide-sm hide-md" md-truncate ng-bind-html="item.reminders._summary"></em>
				</span>
				<span class="flex-none" ng-show="ctrl.selected==item">Hide Reminders</span>
				<i flex></i>
				<span class="flex-none" aria-hidden="true">&nbsp;<i class="fas" ng-class="{'fa-angle-down': ctrl.selected != item, 'fa-angle-up': ctrl.selected == item}"></i></span>
			</md-button>
		</div>
	</div>
	
	<div class="flex-none layout-row padding-1x" ng-if="item._$href">
		<md-button class="go-edit flex md-block layout-row layout-align-center-center"
			ng-href="{{item._$href}}" 
			aria-label="open {{::ctrl._$resource}}: {{item.call_to_action || item.text.raw}}">
			&rsaquo;
		</md-button>
	</div>
</div>