<div class="can-scroll-y">
 <section id="monthly-report">
	<header><a href="/" class="logo"><img src="./images/logo.png"></a></header>
	<h2 class="text-center md-display-1">
		<span ng-hide="ctrl.logo">{{ ctrl.name }}</span>
		<img src="{{ ctrl.logo }}" ng-show="ctrl.logo" alt="{{ ctrl.name }}" style="max-width: 400px; max-height: 200px">
	</h2>

	<div class="panel" md-whiteframe="1" ng-repeat="(key, reportTables) in ctrl.dataTables track by $index">
		<div class="panel-title padding-4x">
			<h3 class="md-title margin-0x">
				{{ ctrl.mapping[key].title }} ({{ ctrl.dateSet }})
				<small class="normal-wg" ng-show="ctrl.mapping[key].subtitle"><br>{{ ctrl.mapping[key].subtitle }}</small>
			</h3>
		</div>
		<div class="panel-content" ng-show="reportTables && reportTables.length > 0">
			<div class="margin-4x can-scroll-x can-scroll-y" ng-repeat="table in reportTables track by $index" ng-include="::'report.monthly.tpl'"></div>
		</div>
		<div class="padding-4x layout layout-align-center-center" ng-show="!reportTables">
			<md-progress-circular md-mode="indeterminate"></md-progress-circular>
		</div>
		<div class="padding-4x black-25 md-caption text-center" ng-show="reportTables && reportTables.length === 0">
			<em>No data available</em>
		</div>
	</div>

	<div class="text-center hide-print">
		<a ng-href="{{ ctrl.getConsoleLink() }}">View Report in Console</a>
	</div>
	<br>
	<p class="md-caption partial text-center">MyCoachApp Administration Console<br>All rights reserved {{ moment() | amDateFormat:'YYYY' }}</p>
	<br>
 </section>
</div>