import angular from 'angular'
import moment from 'moment'
import Highcharts from 'highcharts'
import {DependencyInjected, Helper, ApiError} from '../../common'
import {barChart} from './charts'


export default class NotificationsPerCollegeController extends DependencyInjected {
	static get $inject(){return [
		'$scope',
		'$q',
		'api',
		'authorization',
	]}

	init(){
		this.$scope.$parent.ctrl = this;
		this.main = this.$scope.mainCtrl;
		this.mapping = this.main.mapping;

		this.title = 'Notifications per College';
		this.subject = 'Notifications';
		this.icon = 'far fa-bell';
		this.iconColor = '';
		this.hideDateRange = true;
		this.hideFilters = true;
		this.hideHeadersGroup = true;

		this.data = {};

		delete this.main.filters._$keys.date; // remove so it doesn't show on filter chips
		this.main.filters._date = null;//this.main.dateRange;

		this.main._pending.then(()=>{
			this.$scope.$emit('data-ready');
			this.ready = true;
			this.refresh();
		});

		this._destructors.push(()=>{
			if ( this._defer ) this._defer.resolve('abort');
		});
	}

	refresh(){
		let mainCtrl = this.$scope.mainCtrl;
		let params = angular.extend({
		}, {
			myTokenAdministers: true,
		});

		if ( this._defer ) this._defer.resolve('abort');
		this._defer = this.$q.defer();
		this.dataTable = null;
		mainCtrl.isBusy = true;

		return this.api.get('analysis/notificationsPerCollege', params, {timeout: this._defer.promise})
			.then((res)=>{
				let data = [], categories = [];
				if ( this.main.filters._mycolleges ) {
					data.push(0);
					categories.push(this.main.filters._mycolleges.name);
				} else {
					this.mapping.mycolleges.forEach(college=>{
						data.push(0);
						categories.push(college.name);
					});
				}
				res.data.forEach((item)=>{
					let p = categories.indexOf(item.name);
					if ( p > -1 ) data[p] = item.count;
				});

				let chartOptions = {
					title:{text:''},
					chart:{height: Math.max(400, categories.length*50)},
					legend:{enabled: true},
					tooltip: {pointFormat: '{series.name}: {point.y}'},
					series:[{
						name: 'Notifications',
						data: data,
					}]
				};

				let theChart = barChart('the-chart', [], categories, chartOptions);
				this.dataTable = theChart.getDataRows().slice(1);
				this.dataTable.headers = ['College', 'Notifications'];
			})
			.catch((err)=>{
				if ( this.api.isApiError(err) ) {
					if ( ! err.isIgnored() )
						mainCtrl.error = err;
				} else {
					console.error(err);
				}
			})
			.finally(()=>{
				this._defer = undefined;
				mainCtrl.isBusy = false;
			});
	}

}

