<div class="flex layout-row layout-wrap">
	<div class="data-id flex-xs-100 flex-sm-100 layout-row layout-align-space-between-center layout-wrap layout-gt-sm-column layout-align-gt-sm-space-between-end padding-2x">
		<div class="date">
			<i class="far fa-calendar-minus" role="img" title="deadline"></i>
			{{ item.last_deadline | amDateFormat:SETTINGS.dateFormat }}
		</div>
		<div hide-xs hide-sm flex-gt-sm></div>

		<strong class="md-title normal-wg md-block">{{ item.points ? item.points +'pt'+ (item.points>1 ? 's':'') : '' }}</strong>
		<div hide-xs hide-sm flex-gt-sm></div>

		<a id="id_item-{{item._id}}" class="md-caption md-block no-animate"
			aria-label="task ID #{{item._id}}"
			ui-sref="app.milestones.edit({id: item._id})">ID#{{ item._id }}<br></a>
		<div hide-xs hide-sm flex-gt-sm></div>

		<div class="item-owner md-caption text-right md-truncate">
			<span ng-show="item.created_by_user_id || item.modified_by_user_id">
				{{ item.modified_by_user_id ? 'Updated' : (item.batch_id ? 'CSV' : 'Created') }}
				by
				<span title="{{ ctrl.mapping.users.byId[item.modified_by_user_id || item.created_by_user_id].user_profile.first_name || item.modified_by_user_id || item.created_by_user_id }}">
					{{ ctrl.mapping.users.byId[item.modified_by_user_id || item.created_by_user_id].user_profile.first_name.length > 20 ? (item.modified_by_user_id || item.created_by_user_id) : ctrl.mapping.users.byId[item.modified_by_user_id || item.created_by_user_id].user_profile.first_name }}
				</span>
				<br class="hide-xs hide-sm" aria-hidden="true">
			</span>
			<span class="no-wrap">{{ (item.modified_date || item.created_date || item.date_created) | amDateFormat:SETTINGS.dateTimeFormat }}</span>
		</div>
	</div>

	<div class="data-content flex layout-column layout-align-space-between-stretch padding-2x">
		<h3 id="title_item-{{item._id}}" class="md-subhead">
      <a ui-sref="app.milestones.edit({id: item._id})" class="md-truncate" ng-bind-html="item.title"></a>
		</h3>


		<div class="item-description md-truncate">
			<ng-bind-html class="description" ng-bind-html="item.description.raw"></ng-bind-html>
		</div>

		<span flex></span>
	
		<div class="layout-row layout-align-space-between-start">
			<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_ITEM_CHIPS -->

			<md-button class="action-btn md-button flex-none black-75" 
				aria-label="clone task: {{ item.content_directory.title || item.title }}"
				ui-sref="app.milestones.add({clone: item._id})" 
				ng-click="$event.stopPropagation()">
				<i class="fas fa-copy" aria-hidden="true"></i> Clone
			</md-button>
			<md-button class="action-btn md-button flex-none black-75" 
				aria-label="{{ctrl.selected===item ? 'hide' : 'show'}} task steps"
				aria-expanded="{{ !!ctrl.selected }}"
				ng-show="!ctrl.hideSteps" 
				ng-disabled="!item.steps.length" 
				ng-click="ctrl.selected !== item ? ctrl.open(item) : ctrl.close(); $event.stopPropagation();">
				<i class="fas fa-shoe-prints rotate-270"></i> {{ item.steps.length }}
				<span class="hide-xs hide-sm hide-md">Step{{ item.steps.length>1 ? 's':'' }}</span>
				<span aria-hidden="true">&nbsp;&nbsp;<i class="fas" ng-class="{'fa-angle-down': ctrl.selected != item, 'fa-angle-up': ctrl.selected == item}"></i></span>
			</md-button>
		</div>
	</div>
	
	<div class="flex-none layout-row padding-1x">
		<md-button class="go-edit flex md-block layout-row layout-align-center-center" 
			aria-label="open task: {{ item.content_directory.title || item.title }}"
			ui-sref="app.milestones.edit({id: item._id})">
			&rsaquo;
		</md-button>
	</div>
</div>