import {MESSAGES, SETTINGS, CONSTANTS, Helper, PERMISSIONS} from '../../common'
import EventSingleController from '../events/event.single.controller'


const RESOURCETYPE = {
	BG_IMG: 'tip_background_image',
};

export const BGCOLORS = [
	{value: '#519CA7'},
	{value: '#29A0A1'},
	{value: '#00A39C'},
	{value: '#2AA575'},
	{value: '#7A99AC'},
];


export default class TipSingleController extends EventSingleController {
	static get $inject(){return EventSingleController.$inject}

	static get DEFAULTS(){return {
		ancestry: SETTINGS.apiAncestry,
		recipients: [],
		flutter: {},
		background: {$useImage:true},
		text: {content: CONSTANTS.CONTENT_TYPE.HTML}
	}}
	static RESOURCE = Object.freeze({
		type: 'tips',
		single: 'tip',
		api: 'tips',
		msgs: MESSAGES.TIPS,
		exitRoutes: ['app.tips.add', 'app.tips.edit'],
		scopeSubmitEmit: 'tip.submitted',
	})

	init(){
		super.init();

		this.mapping.bgColors = this.MAPPINGS_JSON.content?.tip?.background?.colors || BGCOLORS,
		this.DEFAULT_BGCOLOR = BGCOLORS[0].value;

		this._destructors.push(
			this.$scope.$once(CONSTANTS.SCOPE_EVENTS.DATA_READY, ()=>{
				// BT-68; allow editing of sent tips
				if ( !this.clone && this.data?.status == 'sent' && this.isLocked && this.authorization.hasPermission(PERMISSIONS.UPDATE_TIPS_OVERRIDE) ) {
					this.isLocked = false;
					this.lockReason = null;
				}
			}),
		);
	}


	createModel(data){
		let model = super.createModel(data);

		model.background.$useImage = !! model.background.url;

    model._$callToAction = model.call_to_action && Helper.hasLangTags(model.call_to_action) ? this.$sce.trustAsHtml(Helper.convertToLangSpan(model.call_to_action)) : undefined;
    model._$attribution = model.attribution && Helper.hasLangTags(model.attribution) ? this.$sce.trustAsHtml(Helper.convertToLangSpan(model.attribution)) : undefined;
    model._$linkText = model.link?.text && Helper.hasLangTags(model.link.text) ? this.$sce.trustAsHtml(Helper.convertToLangSpan(model.link.text)) : undefined;

		delete model.flutter?.end_timestamp;
		delete model.flutter?.start_timestamp;

		// BT-68; enable editing of tip; past dates are valid
		if ( this.authorization.hasPermission(PERMISSIONS.UPDATE_TIPS_OVERRIDE) ) {
			model.reminders.forEach((reminder)=>reminder._$locked = false);
		}

		return model;
	}

	async _submit($evt, form){
		const RESOURCE = this.constructor.RESOURCE;
		
		await this._validateForm(form);
		await this._validateRecipients({title: RESOURCE.msgs.SUBMIT_ALL_TITLE, word: RESOURCE.msgs.SUBMIT_ALL_DESC});

		this.isBusy = true;
		
		const payload = this._preparePayload();
		let result, id, data;
		
		if ( this.data ) {
			id = this.data._id;
			result = await this.api.put(`tips/${id}`, payload);
			data = payload;
		} else {
			result = await this.api.post(RESOURCE.api, payload);
			id = result.data._id;
			data = (await this.api.get(`tips/${id}`)).data;
		}
		try {
			await this._upload(this._filesToUpload, id, data, `tips/${id}`, `tip_id:${id}`);

		} catch(err) {
			if ( ! this.data ) { // from add page, move to edit page
				this.promptExit.disable();
				this.$state.go('^.edit', {id, uploads:this.uploads})
					.then(()=>this.errorPrompt.show(err));
				return;
			}
		}

		this.toast.success(this.data ? RESOURCE.msgs.UPDATE_OK : RESOURCE.msgs.SAVE_OK);

		this.promptExit.disable();
		if ( RESOURCE.exitRoutes.includes(this.$state.current.name) ) {
			this.$state.go('^', this.data && this.parentStateParams || {});
		}
		this.$scope?.$emit(RESOURCE.scopeSubmitEmit, result.data._id);

		return result;
	}
	_preparePayload(){
		const payload = super._preparePayload();
		const model = this.model;

		this._filesToUpload = [];

		payload.call_to_action = model.call_to_action || undefined;
		payload.attribution = model.attribution || undefined;

		payload.background = model.background.url || model.background.color ? {
			url: model.background.$useImage && model.background.url || undefined,
			alt_text: model.background.alt_text || undefined,
			color: !model.background.$useImage && model.background.color || undefined,
		} : undefined;
		if ( this.model.background.file ) {
			let file = this.model.background.file;
			file.resourceType = RESOURCETYPE.BG_IMG;
			file.callback = (url, payload)=>{
				payload.background = {url};
				this.model.background = {url};
			};
			this._filesToUpload.push(file);
		}

		delete payload.flutter.end_timestamp;
		delete payload.flutter.start_timestamp;

		return payload;
	}


	propagateDelayedEvent($event){
		setTimeout(()=>{
			$(window.document.elementFromPoint($event.pageX, $event.pageY))
				.trigger('focus')
				.trigger('click');
		}, 100);
	}

	onDetailsChanged(){
		super.onDetailsChanged();

		const text = this.model.text;

		if ( text.content == CONSTANTS.CONTENT_TYPE.HTML ) {
			text.html = text.formatted;
		} else {
			text.html = Helper.newlinesToHtml(Helper.plainLinksToHtml(Helper.encodeHtml(text.formatted || text.raw)));
		}

    this.preview.call_to_action = this.toSafeLangHtml(this.model.call_to_action);
    this.preview.attribution = this.toSafeLangHtml(this.model.attribution);
    this.preview.linkText = this.toSafeLangHtml(this.model.link?.text);
	}


}