import angular from 'angular'
import {DependencyInjected, Helper, MESSAGES, PERMISSIONS} from '../common'

import './main.scss'


export default class MainController extends DependencyInjected {
	static get $inject(){return [
		'$injector',
		'$scope',
		'$state',
		'$mdMedia',
		'$mdSidenav',
		'$q',
		'$uiRouter',
		'$timeout',
		'toast',
		'authentication',
		'authorization',
		'currentUser',
		'promptExit',
	]}


	init(){
		const $stateRegistry = this.$uiRouter.stateRegistry;

		const checkPermission = (item)=>{
			let reg = $stateRegistry.get(item.sref);
			return !reg?.access || this.authorization.hasPermissionAndLevel(reg.access);
		};

		const mapMenu = (item, index)=>{
			item._$hidden = true;
			this.$q.when(!item.resolve || this.$injector.invoke(menuData[index].resolve, this, this))
				.then(()=>{
					if ( item.children ) {
						item.children = item.children.filter(checkPermission).map(mapMenu);
						item.children.forEach(subitem=>subitem._$parent = item);
					}
					item._$hidden = false;
				}, ()=>{});
			// item._$open = item._$open || (this.$state.includes(item.sref) && !this.$state.is(item.sref));
			item.id = item.sref.replaceAll('.', '_');
			return item;
		};
		this.menuList = Helper.deepCopy(menuData).filter(checkPermission).map(mapMenu);

		this.$scope.MESSAGES = MESSAGES;
		this.$scope.PERMISSIONS = PERMISSIONS;

		this.$scope.$evalAsync(()=>this.menuList.forEach(item=>this.toggleSubmenu(item, item._$open || this.$state.includes(item.sref) || this.$state.is(item.sref))));

		this.$timeout(()=>{
			let target = $('#side md-list-item.active');
			if ( target.length == 0 ) target = $('#side md-list-item.selected');
			if ( target.length > 0 ) Helper.smoothScrollTo(target);
		}, 250);
	}

	go(item, $ev){
		this.$state.go(item.sref, angular.merge({nonce: Math.random(), scroll: -1}, item.srefparams||{}), angular.merge({inherit: false}, item.srefopts||{}))
			.then(()=>{
				this.$mdSidenav('side').close();
				let parent = item;
				while ( parent._$parent )
					parent = parent._$parent;
				this.toggleSubmenu(parent, true);
			}, ()=>{});
		$ev.preventDefault();
		$ev.stopPropagation();
	}

	toggleSubmenu(item, value){
		item._$open = arguments[1] !== undefined ? !!arguments[1] : !item._$open;
		if ( item._$open ) {
			this.menuList.forEach(other=>{
				if ( other!==item ) other._$open = false;
			});
		}
	}

	isActive(item){
		return this.$state.includes(item.sref);
	}
	getLink(item){
		return this.$state.href(item.sref, angular.merge({}, item.srefparams||{}), {inherit: false});
	}


	logout(){
		let fn = ()=>{
			this.$state.go('app.logout');
		};

		if ( this.promptExit.enabled ) {
			this.promptExit._checkStop().then(()=>fn());
		} else {
			fn();
		}
	}

	toggleSide(navID){
		this.$mdSidenav(navID).toggle()
			.then(()=>{});
	}

	hasPermission(value){
		return this.authorization.hasPermission(value);
	}
	
}


export const menuData = [
	{
		name: 'Events',
		iconClass: 'tipsevents_icon01',
		sref: 'app.events',
		srefopts: {reload:'app.events', inherit:false},
		children: [
			{
				name: 'Add New',
				label: 'add new event',
				iconClass: 'fas fa-plus',
				sref: 'app.events.add',
				srefopts: {reload:'app.events.add', inherit:false},
			},
		],
	},
	{
		name: 'Tips',
		iconClass: 'fas fa-lightbulb',
		sref: 'app.tips',
		srefopts: {reload:'app.tips', inherit:false},
		children: [
			{
				name: 'Add New',
				label: 'add new tip',
				iconClass: 'fas fa-plus',
				sref: 'app.tips.add',
				srefopts: {reload:'app.tips.add', inherit:false},
			}
		],
	},
	{
		name: 'Workspaces',
		iconClass: 'fas fa-clipboard-list',
		sref: 'app.workspaces',
		srefopts: {reload:'app.workspaces', inherit:false},
		children: [
			{
				name: 'Add New',
				label: 'add new workspace',
				iconClass: 'fas fa-plus',
				sref: 'app.workspaces.add',
				srefopts: {reload:'app.tips.add', inherit:false},
			}
		],
	},
	{
		name: 'Tasks',
		iconClass: 'fas fa-star',
		sref: 'app.milestones',
		children: [
			{
				name: 'Add New',
				label: 'add new task',
				iconClass: 'fas fa-plus',
				sref: 'app.milestones.add',
				srefopts: {reload:'app.milestones.add', inherit:false},
			},
			{
				name: 'Add by Batch',
				label: 'add by batch tasks',
				iconClass: 'fas fa-plus-square',
				sref: 'app.milestones.addbatch',
			},
			{
				name: 'View Batches',
				label: 'view batches tasks',
				iconClass: 'far fa-list-alt',
				sref: 'app.milestones.batches',
			},
		],
	},
	{
		name: 'Flows',
		iconClass: 'fas fa-project-diagram',
		sref: 'app.flows',
		children: [
			{
				name: 'Add New',
				label: 'add new flow',
				iconClass: 'fas fa-plus',
				sref: 'app.flows.edit',
				srefparams: {id: 'add', nonce: Math.random()},
				srefopts: {reload:true, inherit:false},
			},
		],
		divider: true,
	},
	{
		name: 'Cohorts',
		iconClass: 'fas fa-users',
		sref: 'app.cohorts',
		children: [
			{
				name: 'Add New',
				label: 'add new cohort',
				iconClass: 'fas fa-plus',
				sref: 'app.cohorts.add',
				srefopts: {reload:true, inherit:false},
			},
		],
		divider: true,
	},
	{
		name: 'Colleges',
		iconClass: 'fas fa-school',
		sref: 'app.colleges',
		children: [
			{
				name: 'Add New',
				label: 'add new college',
				iconClass: 'fas fa-plus',
				sref: 'app.colleges.add',
				srefopts: {reload:'app.colleges.add', inherit:false},
			},
		],
	},
	{
		name: 'Districts',
		iconClass: 'fas fa-map',
		sref: 'app.districts',
		children: [
			{
				name: 'Add New',
				label: 'add new district',
				iconClass: 'fas fa-plus',
				sref: 'app.districts.add',
				srefopts: {reload:'app.districts.add', inherit:false},
			},
		],
	},
	{
		name: 'Users',
		iconClass: 'fas fa-users-cog',
		sref: 'app.users',
		children: [
			{
				name: 'Add New',
				label: 'add new user',
				iconClass: 'fas fa-plus',
				sref: 'app.users.add',
				srefopts: {reload:'app.users.add', inherit:false},
			},
		],
		divider: true,
	},
	{
		name: 'Activity Log',
		iconClass: 'fas fa-file-alt',
		sref: 'app.activitylog',
		children: [
			{
				name: 'Salesforce Control Panel',
				desc: 'Salesforce Control Panel',
				iconClass: 'fab fa-salesforce',
				sref: 'app.activitylog.sfpanel',
			},
		],
	},
	{
		name: 'Activity Log',
		iconClass: 'fas fa-file-alt',
		sref: 'app.contentadminactivitylog',
	},
	{
		name: 'Analytics',
		iconClass: 'fas fa-chart-line',
		sref: 'app.analytics',
		children: [
			{
				name: 'Notifications per Date',
				desc: 'Number of sent & unsent notifications.',
				iconClass: 'far fa-calendar',
				sref: 'app.analytics.notificationsPerDate',
				srefopts: {reload:true},
			},
			{
				name: 'Notifications per College',
				desc: 'Notifications distributed per college.',
				iconClass: 'fas fa-chalkboard',
				sref: 'app.analytics.notificationsPerCollege',
				srefopts: {reload:true},
			},
			{
				name: 'Notifications per Category',
				desc: 'Distribution of what categories are most used for notifications.',
				iconClass: 'far fa-object-group',
				sref: 'app.analytics.notificationsPerCategory',
				srefopts: {reload:true},
			},
			{
				name: 'New Students per Date',
				desc: 'Trend of new students that have started using MyCoach.',
				iconClass: 'fas fa-user-clock',
				sref: 'app.analytics.studentsPerDate',
				srefopts: {reload:true},
			},
			{
				name: 'Students per College',
				desc: 'Total number of students using MyCoach by college.',
				iconClass: 'fas fa-users',
				sref: 'app.analytics.studentsPerCollege',
				srefopts: {reload:true},
			},
			{
				name: 'Students per Motivation',
				desc: 'Distribution of motivations on why the students study.',
				iconClass: 'fas fa-user-check',
				sref: 'app.analytics.studentsPerMotivation',
				srefopts: {reload:true},
			},
			{
				name: 'Students per Level',
				desc: 'Determine how many students are new, continuing or prospective.',
				iconClass: 'fas fa-user-tag',
				sref: 'app.analytics.studentsPerLevel',
				srefopts: {reload:true},
			},
			{
				name: 'Questions Results',
				desc: 'Onboarding questions results',
				iconClass: 'fas fa-chart-pie',
				sref: 'app.analytics.questionsReport',
				srefopts: {reload:true},
			},
			{
				name: 'Monthly Report',
				desc: 'Monthly Report for colleges',
				iconClass: 'fas fa-file-invoice',
				sref: 'app.analytics.monthlyReport',
				srefopts: {reload:true},
			},
		],
	},
	{
		name: 'Site Map',
		iconClass: 'fas fa-sitemap',
		sref: 'app.sitemap',
	},
];
