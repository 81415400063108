import angular from 'angular'
import $ from 'jquery'
import moxie from 'mOxie'
import Papa from 'papaparse'
import {DependencyInjected, Helper, ApiError, SETTINGS, MESSAGES, CONSTANTS} from '../../common'


let getFile = window.FileReader ? (file)=>file.getSource() : (file)=>file;
let FileReader = window.FileReader || moxie.file.FileReader;

const FIELDMAP = require('./fieldmap.json');
const MSGS = MESSAGES.NOTIFICATIONS.UPLOAD;

const CSV_TEMPLATES = [
	{
		label: 'Workspace Template (csv)',
		url: require('./csv-templates/template_workspace.csv'),
		filename: 'workspace_template.csv',
	},
	// {
	// 	label: 'Notifications with Flutter and Tip Themes (csv)',
	// 	url: require('./csv-templates/template_notifications_flutter_tiptheme_events.csv'),
	// 	filename: 'template_notifications_flutter_tiptheme_events.csv',
	// },
];

export default class WorkspacesUploadController extends DependencyInjected {
	static get $inject(){return [
		'api',
		'apiMap',
		'toast',
		'errorPrompt',
		'recipient',
		'authorization',
		'currentUser',
		'$q',
		'$mdDialog',
		'$scope',
		'$timeout',
		'$state',
		'$transitions',
		'$window',
		'promptExit',
		'MAPPINGS_JSON',
	]}

	static get CSV_HEADERS(){return [
		'notification_type',
		'text',
		'text_content_type',
		'recipients',
		'date_to_send',
		// 'short_name',
		'name',
		// 'location',
		'new_category',
		'term',
		'source_timezone',
		'start_timestamp',
		'priority'
	]};
	static get CSV_HEADERS_OPTIONAL(){return [
		'notification_id',
		'new_notification_type',
		'categories',
		'tip_theme',
		'call_to_action',
		'reminder_plan',
		'ref_id',
		'hide_from_flutter',
		'end_timestamp',
		'end_name',
		'batch_status',
		'batch_notes', 
		'attribution',
		'background_image',
		'background_image_alt_text',
		'background_color'
	]};



	init(){
		this.mapping = {
			categories: Helper.superMap(this.MAPPINGS_JSON.notifications.category_key, {type:'category'}),
			batchStatus: Helper.superMap({
				// csv_value: json_value
				'hold': 'held',
				'held': 'held',
				'approved': 'approved',
			}),
		};
		
		this.$q.all([
			this.apiMap.getColleges().then(data=>this.mapping.mycolleges=data),
			this.apiMap.getActiveColleges().then(data=>this.mapping.mycolleges=data),
			this.apiMap.getAllDistricts().then(data=>this.mapping.districts=data),
			this.apiMap.getAllCohorts().then(data=>this.mapping.cohorts=data),
			this.apiMap.getCategories().then(data=>this.mapping.flutterCategories=data),
			this.apiMap.getTags().then(data=>this.mapping.flutterTags=data),
			this.recipient.prepare(),
		]).then(()=>{
			fileInput.init();
			this.isBusy = false;
		});
		this.isBusy = true;

		this.templates = Helper.deepCopy(CSV_TEMPLATES);

		const fileInput = new moxie.file.FileInput({
			browse_button: $('#fileInput').get(0),
			accept: [{title: 'CSV file', extensions: 'csv'}, {title: 'Plain Text file', extensions: 'txt'}]
		});
		fileInput.onchange = (e)=>this.$scope.$evalAsync(()=>{
			this.clear();
			let file = this.file = e.target.files[0];
			this.isBusy = true;
			reader.readAsText(getFile(file));
		});

		const $zone = $('#notification-flutter-upload-form .dropzone')
			.on('drag dragstart dragend dragover dragenter dragleave drop', (e)=>{e.stopPropagation();e.preventDefault();})
			.on('dragover dragenter', ()=>$zone.addClass('dragover'))
			.on('dragleave dragend drop', ()=>$zone.removeClass('dragover'))
			.on('drop', (e)=>{
				if ( this.isBusy ) return;
				this.clear();
				let file = this.file = e.originalEvent.dataTransfer && e.originalEvent.dataTransfer.files[0] || undefined;
				if ( file && ! /\.(csv|txt)$/.test(file.name) ) {
					let err = new Error('Can only import CSV or TXT files.')
					err.name = 'Invalid file type';
					this.errorPrompt.show(err, null, {noDebug:true, noRefresh:true});
					this.file = undefined;
				} else if ( file ) {
					this.isBusy = true;
					reader.readAsText(file);
				}
				this.$scope.$evalAsync();
			});

		const reader = new FileReader();
		reader.onload = (e)=>this.$scope.$evalAsync(()=>this._parse(e));

		

		$(this.$window)
			.on('resize.addWorkspace', Helper.debounce(()=>{
					let h = Math.max($('#notification-flutter-upload-form main').height() -48, 300);
					$('#dropzone, #csv-preview').height(h);

					let preview = $('#csv-preview');
					preview.css({height:'auto'});

					h = Math.max(Math.min(h, preview.height()), 300);
					preview.height(h);
				}, 200))
			.trigger('resize.addWorkspace');

		this._destructors.push(
			this.$transitions.onExit({from: this.$state.current.name}, transition=>this.toast.clear(), {invokeLimit: 1,}),
			()=>$zone.off('drag dragstart dragend dragover dragenter dragleave drop'),
			()=>$(this.$window).off('resize.addWorkspace'),
			this.$scope.$once('data-ready', ()=>$(this.$window).trigger('resize.addWorkspace')),
		);
	}



	async submit(){
		if ( ! this.batch.college_id ) {
			this.toast.warn('Please select a target college');
			Helper.smoothScrollTo($('[ng-model="ctrl.batch.college_id"]'), $('main'));
			return;
		}

		if ( this.batch.length === 0 || this.errors.length > 0 || Object.values(this.$scope.form.$error).length > 0 )
			return;

		this.isBusy = true;

		const payload = this._preparePayload();

		try {
			await this.api.post('workspaces/bulk', payload, {level: ApiError.LEVEL.MANUAL});
		
			let count = this.batch.length;
			this.clear();
			this.toast.success(+(this.successCount = count) +' record(s) uploaded as draft');
		} catch(err) {
			if ( this.api.isApiError(err) && +err.response.status == 400 ) {
				let errors = Object.values(err.response.data?.errors || {});
				errors.forEach(err=>{
					let key = FIELDMAP[err.field_code] && FIELDMAP[err.field_code].csv || err.field_code;
					this._addError({message: err.message, index: err._csv_index, key, val: payload.events[err._id]});
				});
				Helper.smoothScrollTo($('#error-table'), null, ()=>this.errors._$expanded = true);
				this.toast.warn('Errors are errors on the CSV');
			} else {
				this.api.handleError(err);
			}
		}
		this.isBusy = false;
	}
	_preparePayload(){
		const userId = +this.authorization.userId;
		const payload = {
				ancestry: SETTINGS.apiAncestry,
				record_count: this.batch.length,
				status: 'unpublished',
				processed_date: Helper.toTimestamp(new Date()), 
				created_by_user_id: userId,
				file_name: this.file.name,
				events: [],
				tip_themes: [],
				// tips: [],
				college_id: this.batch.college_id,
			},
			now = Helper.toTimestamp(new Date());
		this.batch.forEach(item=>{
			item = Helper.deepCopy(item);
			item.owner_college_id = this.batch.college_id;

			if ( item._id > 0 ) {
				item.modified_by_user_id = userId;
				item.modified_date = now;
			} else {
				item.created_by_user_id = userId;
				item.created_date = now;
			}

			if ( item.notification_type == 'dates_and_events' ) {
				payload.events.push(item)
			} else if ( item.notification_type == 'tip_theme' ) {
				payload.tip_themes.push(item)
			// } else if ( item.notification_type == 'tips' ) {
			// 	payload.tips.push(item)
			}
			
			delete item._id;

			if ( item.approval_status )
				item.date_approval_status_updated = now;
		});
		return payload;
	}


	clear(){
		this.file = this.previewData = undefined;
		this.errors = [];
		this.errors.byCell = {};
		this.errors.byRow = {};
		this.warnings = [];
		this.batch = [];
		this.toast.clear();
		this.promptExit.disable();
	}

	getColumnHeader(value){
		if ( ! isNaN(value) ) {
			let result = String.fromCharCode(65 + (value % 26));
			let over = Math.floor(value / 26); // 26 letters
			if ( over )
				result = this.getColumnHeader(over -1) + result;
			return result;
		}
		return '';
	}


	_parse(e){
		try {
			this.__parse(e.target.result);
		} catch(err){
			if ( err instanceof Error ) {
				this.errors.push(err);
				this.errorPrompt.show(err);
			}
			console.error(err);
		}
		this.promptExit.enable(this.$state.current.name);

		this.errors.sort(Helper.sortBy('row'));
		this.errors._$expanded = false;
		this.warnings.sort(Helper.sortBy('row'));
		this.warnings._$expanded = false;

		this.$timeout(()=>{
			this.$timeout(()=>{
				this.errors._$expanded = this.errors.length > 0;
				this.warnings._$expanded = this.warnings.length > 0;
				$(this.$window).trigger('resize.addWorkspace');
			}, 500);
			this.isBusy = false;
		}, 200);
	}
	__parse(data){
		const result = Papa.parse(data, {skipEmptyLines:true});
		if ( result.errors.length ) {
			let err = new Error('Cannot parse CSV file');
			err.name = 'Parse Error';
			err.debug = result.errors.map(e=>e.message);
			throw err;
		}
		if ( result.data.length <= 1 ) {
			return this.errors.push({message: MSGS.UNABLE_TO_PARSE_ROW});
		}
		
		this.previewData = result.data.slice(1).map(row=>row.map(value=>Helper.encodeHtml(value)));

		// check for required header keys
		const keys = this.previewData.headers = Object.values(result.data[0]);
		this.constructor.CSV_HEADERS.forEach(key=>{
			if ( ! keys.includes(key) ) this.errors.push({message: '`'+ key +'` column is missing'});
		});
		if ( !keys.find(v=>['type', 'notification_type'].includes(v)) )
			this.errors.push({message: '`notification_type` column is missing'});

		if ( this.errors.length > 0 ) return;


		// build the batch
		const batch = this.batch = result.data.slice(1)
			.map((row)=>{
				let obj = {};
				row.forEach((value, index)=>obj[keys[index]] = String(value).trim());
				return obj;
			})
			.map((row, index)=>this._buildNotification(row, index)).filter(v=>!!v);

		if ( batch.length === 0 ) {
			return this.errors.push({message: MSGS.UNABLE_TO_PARSE_ROW});
		}

		this._tallyBatch(batch);

		console.log('bulk', batch);
	}
	_tallyBatch(batch){
		let list;
		batch.forEach((row, index)=>{
			batch.reminders = Math.max(batch.reminders||0, Object.keys(row.reminders || {}).length);
			batch.tags = Math.max(batch.tags||0, row.flutter && Object.keys(row.flutter.tags || {}).length || 0);

			if ( row.tip_theme ) {
				let map = batch.tipThemes = batch.tipThemes || {};
				let key = row.tip_theme;

				// CON2-717: detect if row index is not sequencing
				if ( ! list || list.key != key ) // last list is not same key, get previous or create new list from map
					list = map[key] = map[key] || [];

				if ( list.length && index - list[list.length-1]._csv_idx > 1 ) {
					this.warnings.push({message:MSGS.TIP_THEME_ROW_APART, id:row._id, row:index, key:'tip_theme', val:row.tip_theme});
					list = map[index +'-'+ key] = [];
				}

				list.key = key; // this key is the actual row's tip theme, not always same as map key
				list.push(row);
			} else {
				row.publish = false;
			}
		});

		if ( batch.tipThemes ) this._parseTipThemes();

		batch.count_tips = 0;
		batch.count_dates_and_events = 0;
		batch.count_tip_theme = 0;
		batch.forEach(row=>batch['count_'+ row.notification_type]++);
	}


	_parseTipThemes(){
		this.batch.count_tip_theme_tips = 0;

		let i = this.batch.length;
		while( i-- ) {
			if ( this.batch[i].tip_theme )
				this.batch.splice(i, 1);
			else
				this.batch[i].publish = false;
		}

		Object.values(this.batch.tipThemes).forEach(list=>{
			list.sort((a,b)=>a._csv_idx - b._csv_idx);

			let theme = {
				ancestry: SETTINGS.apiAncestry,
				_id: -1,
				notification_type: 'tip_theme',
				text: {
					raw: 'Tip Theme: '+ list[0].tip_theme,
					content: 'text/plain',
				},
				publish: false,
				owner_college_id: this.batch.college_id,
				approval_status: list[0].approval_status || undefined,
				tips: {},
				flutter: {
					name: {en_US: 'Tip Theme: '+ list[0].tip_theme},
					hide_from_flutter: true,
					notification_type: 'tip_theme',
					start_timestamp: moment().add(10, 'Y'),
					end_timestamp: moment(),
					notes: list[0].flutter && list[0].flutter.notes || undefined,
				},
				_csv_idx: list[0]._csv_idx,
			};
			// get next negative id
			this.batch.forEach(item=>Number.isInteger(item._id) ? theme._id = Math.min(theme._id, item._id -1) : null);

			// CON2-716; tip themes should all have same status & notes
			list.forEach(tip=>{
				if ( theme.approval_status !== tip.approval_status )
					this.errors.push({message: MSGS.TIP_THEME_DIFF_BATCH_STATUS, id:tip._id, row:tip._csv_idx, key:'batch_status', val:tip.approval_status});
				if ( theme.flutter.notes !== (tip.flutter && tip.flutter.notes) )
					this.errors.push({message: MSGS.TIP_THEME_DIFF_BATCH_NOTES, id:tip._id, row:tip._csv_idx, key:'batch_notes', val:tip.flutter.notes});
			});

			// CON2-717: detect if row index is not sequencing
			// this is now redundant after CON2-710, group isolate for non-sequencing
			let lastIdx;
			list.forEach(tip=>{
				if ( lastIdx && tip._csv_idx - lastIdx > 2 ) {
					this.warnings.push({message: MSGS.TIP_THEME_ROW_APART, id:tip._id, row:tip._csv_idx, key:'tip_theme', val:tip.tip_theme});
				}
				lastIdx = tip._csv_idx;
			});

			// determin min-max of dates
			list.forEach(tip=>{
				let start = moment(tip.flutter && (tip.flutter.start_timestamp || tip.flutter.end_timestamp) || tip.date_to_send);
				theme.flutter.start_timestamp = moment.min(start, theme.flutter.start_timestamp);
				let end = moment(tip.flutter && tip.flutter.end_timestamp || tip.date_to_send);
				theme.flutter.end_timestamp = moment.max(end, theme.flutter.end_timestamp);

				theme.flutter.source_timezone = tip.source_timezone || 'college';
				this.batch.count_tip_theme_tips++;
			});

			// compute day_offset
			let span = theme.flutter.end_timestamp.diff(theme.flutter.start_timestamp, 'days');
			list.forEach(tip=>{
				tip.day_offset = moment(tip.flutter && (tip.flutter.start_timestamp || tip.flutter.end_timestamp) || tip.date_to_send).diff(theme.flutter.start_timestamp, 'days') +1;
				// tip._offset = Math.round((tip.day_offset-1) / span * 1000);
			});
			// sort by day_offset
			list.sort((a,b)=>a.day_offset - b.day_offset);
			list.forEach((tip, i)=>theme.tips['tip_'+ (i+1)] = tip);

			// CON2-717: detect if row date is more than 7 days apart from others
			let lastOffset;
			list.forEach(tip=>{
				if ( lastOffset && tip.day_offset - lastOffset > 7 ) {
					this.warnings.push({message: MSGS.TIP_THEME_ROW_DATE_APART, id:tip._id, row:tip._csv_idx,
						key: tip.flutter.start_timestamp ? 'start_timestamp' : tip.flutter.end_timestamp ? 'end_timestamp' : 'date_to_send',
						val: tip.flutter.start_timestamp || tip.flutter.end_timestamp || tip.date_to_send,
					});
				}
				lastOffset = tip.day_offset;
			});

			// clean up // remove some fields
			list.forEach(tip=>{
				delete tip._id;
				delete tip.date_to_send;
				delete tip.approval_status;
				delete tip.flutter.start_timestamp;
				delete tip.flutter.end_timestamp;
				delete tip.flutter.notes;
			});

			if ( /^\d{4}-\d{2}-\d{2}$/.test(theme.flutter.start_timestamp) && theme.flutter.source_timezone != 'college' )
				theme.flutter.start_timestamp = theme.flutter.start_timestamp +'T12:00:00Z';
			else if ( theme.flutter.source_timezone == 'college' )
				theme.flutter.start_timestamp = moment(theme.flutter.start_timestamp).format('YYYY-MM-DD');
			else
				theme.flutter.start_timestamp = moment(theme.flutter.start_timestamp).format('YYYY-MM-DD[T]HH:mm:[00Z]');

			if ( /^\d{4}-\d{2}-\d{2}$/.test(theme.flutter.end_timestamp) && theme.flutter.source_timezone != 'college' )
				theme.flutter.end_timestamp = theme.flutter.end_timestamp +'T12:00:00Z';
			else if ( theme.flutter.source_timezone == 'college' )
				theme.flutter.end_timestamp = moment(theme.flutter.end_timestamp).format('YYYY-MM-DD');
			else
				theme.flutter.end_timestamp = moment(theme.flutter.end_timestamp).format('YYYY-MM-DD[T]HH:mm:[00Z]');
			
			theme.date_to_send = theme.flutter.start_timestamp || theme.flutter.end_timestamp;
			if ( /^\d{4}-\d{2}-\d{2}$/.test(theme.date_to_send) )
				theme.date_to_send += 'T12:00:00Z';


			this.batch.push(theme);
		});
	}

	_buildNotification(row, index){
		const isNewNotif = !row.notification_id && !row.id;
		// if ( isNewNotif && !row.text ) return null;
		if ( !isNewNotif && ! /^\d+$/.test(row.notification_id || row.id) ) return null;

		const notif = Object.create(null);
		const id = notif._id = row.notification_id || (-1 * (index+1));	
		notif._csv_index = index;
		notif.ancestry = SETTINGS.apiAncestry;

		if ( row.text_content_type ) {
			if ( ['html','text/html'].includes(row.text_content_type.toLowerCase()) ) {
				row.text_content_type = CONSTANTS.CONTENT_TYPE.HTML;
				row.text_formatted = row.text;
				row.text = Helper.htmlToText(row.text);

			} else if ( ['plain text','plain','text','text/plain'].includes(row.text_content_type.toLowerCase()) ) {
				row.text_content_type = CONSTANTS.CONTENT_TYPE.TEXT;
				if ( row.text_formatted ) {
					this._addWarning({message: MSGS.INVALID_IGNORE_FORMATTED, id, index, key:'text_formatted', val:row.text_formatted});
					row.text_formatted = undefined;
				}
			} else {
				this._addError({message: MSGS.INVALID_CONTENT_TYPE, id, index, key:'text_content_type', val:row.text_content_type});
			}
		}

		if ( row.text ) {
			notif.text = {
				raw: row.text,
				content: row.text_content_type || CONSTANTS.CONTENT_TYPE.TEXT,
				formatted: row.text_formatted || undefined,
			};
			if ( notif.text.raw.length > 140 )
				this._addWarning({message: MSGS.CHAR_LIMIT, id, index, key:'text', val:row.text});
		} else if ( isNewNotif ) this._addError({message: MSGS.MISSING_VALUE, id, index, key:'text', val:row.text});

		row.notification_type = row.notification_type || row.type;
		if ( row.notification_type ) {
			if ( /^(dates_and_events|tips)$/.test(row.notification_type) ) {
				notif.notification_type = row.notification_type;
			} else this._addError({message: MSGS.INVALID_TYPE, id, index, key:'notification_type', val:row.notification_type});
		} else if ( isNewNotif ) this._addError({message: MSGS.MISSING_VALUE, id, index, key:'notification_type', val:row.notification_type});

		if ( notif.notification_type === 'tips' ) {
			if ( row.call_to_action ) notif.call_to_action = row.call_to_action;
			else if ( isNewNotif ) this._addError({message: MSGS.MISSING_VALUE, id, index, key:'call_to_action', val:row.call_to_action});
		}

		if ( row.date_to_send ) {
			if ( /^\d{4}-\d{2}-\d{2}$/.test(row.date_to_send) && moment(row.date_to_send, 'YYYY-MM-DD').isValid() ) {
				notif.date_to_send = row.date_to_send +'T12:00:00Z';
				/*if ( row.date_to_send < moment().format('YYYY-MM-DD') )
					this._addError({message: 'Cannot be in  past', id, row:index, key:'date_to_send', val:row.date_to_send});
				else if ( row.date_to_send > moment().add(10, 'y').format('YYYY-MM-DD') )
					this._addWarning({message: 'Date is set e t10yrs in the future', id:id, row:index, key:'date_to_send', val:row.date_to_send});*/
			} else {
				this._addError({message: MSGS.INVALID_DATE_FORMAT, id, index, key:'date_to_send', val:row.date_to_send});
			}
		} else if ( isNewNotif ) this._addError({message: MSGS.MISSING_VALUE, id, index, key:'date_to_send', val:row.date_to_send});

		// CON2-725 support reminder_plan
		if ( row.notification_type === 'dates_and_events' && row.reminder_plan ) {
			if ( /^(0|-\d+)(,\s*(0|-\d+))*$/.test(row.reminder_plan.trim()) ) {
				notif.reminder_plan = row.reminder_plan.trim();
			} else
				this._addError({message: MSGS.INVALID_REMINDER_PLAN, id, index, key:'reminder_plan', val:row.reminder_plan});
		}

		if ( row.link_url || row.link_text ) {
			if ( ! row.link_url ) {
				this._addError({message: MSGS.MISSING_VALUE, id, index, key:'link_url', val:row.link_url})
			} else 
			if ( /^https?:\/\/\w+/.test(row.link_url) ) {
				notif.link = {};
				notif.link.url = row.link_url.replace(/\s/g, '%20');
			} else {
				this._addError({message: MSGS.INVALID_URL, id, index, key:'link_url', val:row.link_url})
			}
			
			if ( ! row.link_text )
				this._addError({message: MSGS.MISSING_VALUE, id, index, key:'link_text', val:row.link_text})
			else
				notif.link = {};
				notif.link.text = row.link_text;
				notif.link.url = row.link_url;
				//notif.link_text = row.link_text;
		}
		
		notif.background = {};
		let imageRegex = /^https?:\/\/\w+/;
		let colorRegex = /^#([A-F0-9]{3,4}|[A-F0-9]{6}|[A-F0-9]{8})$/i;
		if ( row.background_image ) {
			notif.background.url = row.background_image;
			notif.background.alt_text = row.background_image_alt_text; 
			let validImage = row.background_image.match(imageRegex);
			if ( ! validImage )
				this._addWarning({message: MSGS.INVALID_URL, id, index, key:'background_image', val:row.background_image});
		}

		if ( row.background_color ) {
			let validColor = row.background_color.match(colorRegex);
			if ( validColor ) {
				if ( notif.background.image )
					this._addError({message: MSGS.WARN_BG_COLOR, id, index, key:'background_color', val:row.background_color});
				notif.background.color = row.background_color;
			} else {
				this._addError({message:MSGS.INVALID_VALUE, id, index, key:'background_color', val:row.background_color});
			} 
		}
		notif.background = notif.background.url || notif.background.color ? notif.background : undefined;


		if ( row.attribution ) notif.attribution = row.attribution;
		if ( row.source ) notif.source = row.source;
		if ( row.research ) notif.research = row.research;


		if ( row.categories ) {
			let categories = {};
			row.categories.split(/\s*,\s*/).forEach(val=>{
				val = val.trim();
				let cat = this.mapping.categories.find(cat=>cat.name.toLowerCase() === val.toLowerCase());
				if ( cat ) categories[cat._id] = {};
				else this._addError({message: MSGS.INVALID_VALUE, id, index, key:'categories', val:row.categories});
			});
			if ( Object.keys(categories).length > 0 )
				notif.categories = categories;
		}

		if ( row.recipients ) try {
			if ( row.recipients.toLowerCase() === 'all' ) {
				notif.recipients = {recipient_group_1: ''}
			} else {
				// let res = this.recipient.parse(row.recipients);
				// if ( res.error.length ) throw res.error[0];
				// if ( ! res.list.find(item=>item.type=='college' && item.op=='=') )
				// 	this._addWarning({message: MSGS.NO_RECIPIENT_COLLEGE, id, index, key:'recipients', val:row.recipients});
				notif.recipients = {recipient_group_1: row.recipients};
			}
		} catch(e) {
			this._addError({message: e.message, id, index, key:'recipients', val:row.recipients});
		} else {
			notif.recipients = {recipient_group_1: ''};
			this._addWarning({message: MSGS.MISSING_VALUE, id, index, key:'recipients', val:row.recipients});
		}


		// reminders
		let reminders = {}, maxID = 0;
		(()=>{
			let reminders = [], idx = 1;
			while( row[`reminder_${idx}_text`] || row[`reminder_${idx}_date_to_send`] || row[`reminder_${idx}_question`] ) {
				let k, reminder = {_id: parseInt(row[`reminder_${idx}_id`]) || 0};
				reminders.push(reminder);
				maxID = Math.max(maxID, reminder._id);

				if ( row[k = `reminder_${idx}_text`] ) {
					reminder.text = row[k];
				} else
					this._addError({message: MSGS.MISSING_VALUE, id, index, key:k, val:row[k]});

				if ( row[k = `reminder_${idx}_date_to_send`] ) {
					if ( /^\d{4}-\d{2}-\d{2}$/.test(row[k]) && moment(row[k], 'YYYY-MM-DD').isValid() ) {
						reminder.date_to_send = row[k] +'T12:00:00Z';
						if ( row[k] < moment().format('YYYY-MM-DD') )
							this._addError({message: MSGS.INVALID_DATE_PAST, id, index, key:k, val:row[k]});
						else if ( row[k] > moment().add(10, 'y').format('YYYY-MM-DD') )
							this._addWarning({message: MSGS.WARN_DATE_TOO_FUTURE, id, index, key:k, val:row[k]});
					} else
						this._addError({message: MSGS.INVALID_DATE_FORMAT, id, index, key:k, val:row[k]});
				} else
					this._addError({message: MSGS.MISSING_VALUE, id, index, key:k, val:row[k]});

				if ( row.hasOwnProperty(k = `reminder_${idx}_question`) ) {
					if ( /^(yes|no)$/i.test(row[k]) || row[k]==='' ) {
						reminder.question = (row[k] || '').toLowerCase()==='yes';
					} else
						this._addError({message: MSGS.INVALID_YES_NO, id, index, key:k, val:row[k]});
				} else
					this._addError({message: MSGS.MISSING_VALUE, id, index, key:k, val:row[k]});
				++idx;
			}
			return reminders;
		})().forEach(item=>{
			reminders[item._id || ++maxID] = item;
			delete item._id;
		});
		if ( Object.keys(reminders).length > 0 )
			notif.reminders = reminders;


		// CON2-699
		if ( row.batch_status ) {
			let key = row.batch_status.trim().toLowerCase();
			if ( this.mapping.batchStatus.byId[key] ) {
				notif.approval_status = this.mapping.batchStatus.byId[key].name;
			} else {
				this._addError({message: MSGS.INVALID_VALUE, id, index, key:'batch_status', val:row.batch_status});
			}
		}


		// flutter data
		let flutter = notif.flutter = Object.create(null);
		let strict = row.notification_type != 'tips'; // CON2-543; optional flutter data for tips

		if ( row.hide_from_flutter!==undefined && row.hide_from_flutter.toLowerCase()==='yes' )
			flutter.hide_from_flutter = true;
		else if ( row.hide_from_flutter && ! /^(yes|no)$/i.test(row.hide_from_flutter) )
			this._addWarning({message: MSGS.INVALID_YES_NO, id, index, key:'hide_from_flutter', val:row.hide_from_flutter});


		// CON2-632; tip themes
		if ( row.tip_theme ) {
			notif.tip_theme = row.tip_theme.trim().toUpperCase();
		} else {
			// CON2-731; tip theme required for tips
			if ( notif.notification_type == 'tips' )
				this._addError({message: MSGS.TIP_NO_TIP_THEME, id, index, key:'tip_theme', val:row.tip_theme});
		}



		if ( row.new_notification_type ) {
			flutter.notification_type = row.new_notification_type;

			// BTC-27 notification_type and new_notification_type do not match
			if ( notif.notification_type != flutter.notification_type ) {
				if ( id > -1 ) // has id to edit, have warning
					this._addWarning({message: MSGS.WARN_TYPE_CHANGE, id, index, key:'new_notification_type', val:row.new_notification_type});
				else // prompt error if new notification
					this._addError({message: MSGS.INVALID_TYPE_CHANGE, id, index, key:'new_notification_type', val:row.new_notification_type});
			}
		} else
		if ( 0&& strict && ! flutter.hide_from_flutter )
			this._addError({message: MSGS.MISSING_VALUE, id, index, key:'new_notification_type', val:row.new_notification_type});

		if ( row.short_name ) {
			if ( row.short_name.length <= 6 )
				flutter.short_name = row.short_name;
			else
				this._addError({message: MSGS.SHORT_CHAR_LIMIT, id, index, key:'short_name', val:row.short_name});
		}// else this._addError({message: MSGS.MISSING_VALUE, id, index, key:'short_name', val:row.short_name});

		if ( row.name ) flutter.name = {en_US:row.name};
		else if ( strict && ! flutter.hide_from_flutter )
			this._addError({message: MSGS.MISSING_VALUE, id, index, key:'name', val:row.name});

		if ( row.end_name ) flutter.end_name = {en_US:row.end_name};

		if ( row.location ) flutter.location = {label:{en_US:row.location}};
		// else this._addError({message: MSGS.MISSING_VALUE, id, index, key:'location', val:row.location});

		if ( row.ref_id ) {
			if ( /^\d+$/.test(row.ref_id) ) flutter.ref_id = row.ref_id;
			else this._addError({message: MSGS.INVALID_ID, id, index, key:'ref_id', val:row.ref_id});
		}

		if ( row.new_category ) {
			let val = row.new_category.trim();
			let cat = this.mapping.flutterCategories.find(cat=>cat.name.en_US.toLowerCase() === val.toLowerCase());
			if ( cat ) {
				flutter.category = cat._id;
			} else {
				this._addError({message: MSGS.INVALID_VALUE, id, index, key:'new_category', val:row.new_category});
			}
		} else
		if ( strict && ! flutter.hide_from_flutter )
			this._addError({message: MSGS.MISSING_VALUE, id, index, key:'new_category', val:row.new_category});

		let tags = {};
		(()=>{
			let tags = [], idx = 1, k;
			while( (k = 'tag_'+ idx) in row ) {
				let tag = row[k] && this.mapping.flutterTags.find(tag=>tag.name.en_US.toLowerCase() === row[k].toLowerCase());
				if ( tag ) tags.push(tag._id);
				idx++;
			}
			return tags;
		})().forEach(k=>tags[k] = {});
		if ( Object.keys(tags).length > 0 )
			flutter.tags = tags;

		// else this._addError({message: MSGS.MISSING_VALUE, id, index, key:'new_categories', val:row.new_categories});

		if ( row.term ) {
			if ( /^\d{4}-(WINTER|SPRING|SUMMER|FALL)$/.test(row.term) && moment(row.term.substr(0,4), 'YYYY').isValid() )
				flutter.term = row.term;
			else
				this._addError({message: MSGS.INVALID_TERM, id, index, key:'term', val:row.term});
		} else {
			// this._addError({message: MSGS.MISSING_VALUE, id, index, key:'term', val:row.term});
		}

		if ( /^\d+$/.test(row.priority) )
			flutter.priority = +row.priority;

		if ( row.source_timezone ) {
			if ( /^(college|global)$/i.test(row.source_timezone) )
				flutter.source_timezone = row.source_timezone.toLowerCase();
			else
				this._addError({message: MSGS.INVALID_SOURCE_TZ, id, index, key:'source_timezone', val:row.source_timezone});
		} else
		if ( strict && ! flutter.hide_from_flutter ) {
			this._addError({message: MSGS.MISSING_VALUE, id, index, key:'source_timezone', val:row.source_timezone});
		} else {
			this._addWarning({message: MSGS.MISSING_SOURCE_TZ_DEFAULT, id, index, key:'source_timezone', val:row.source_timezone});
			flutter.source_timezone = 'college';
		}


		let globalTZ = flutter.source_timezone == 'global';
		let regex = /^(\d{4}-\d{2}-\d{2})\s?(?: (\d{1,2}:\d{2})\s?(am|pm)?)?$/i;
		if ( row.start_timestamp ) {
			let m = row.start_timestamp.match(regex);
			if ( m ) {
				if ( ! m[2] ) // has no time
					flutter.start_timestamp = moment(m[1], 'YYYY-MM-DD').format(globalTZ ? 'YYYY-MM-DD[T00:00:00Z]' : 'YYYY-MM-DD');
					// flutter.start_timestamp = Helper.toTimestamp(moment.tz(row.start_timestamp +' 00:00', 'YYYY-MM-DD HH:mm', globalTZ ? 'GMT' : SETTINGS.timezone));
				else if ( m[3] ) // has ampm
					flutter.start_timestamp = moment(`${m[1]} ${m[2]}${m[3]}`, 'YYYY-MM-DD h:mma').format(globalTZ ? 'YYYY-MM-DD[T]HH:mm:[00Z]' : 'YYYY-MM-DD HH:mm');
					// flutter.start_timestamp = Helper.toTimestamp(moment.tz(row.start_timestamp, 'YYYY-MM-DD h:mma', globalTZ ? 'GMT' : SETTINGS.timezone));
				else // 24hr
					flutter.start_timestamp = moment(`${m[1]} ${m[2]}`, 'YYYY-MM-DD H:mm').format(globalTZ ? 'YYYY-MM-DD[T]HH:mm:[00Z]' : 'YYYY-MM-DD HH:mm');
					// flutter.start_timestamp = Helper.toTimestamp(moment.tz(row.start_timestamp, 'YYYY-MM-DD H:mm', globalTZ ? 'GMT' : SETTINGS.timezone));
			} else {
				this._addError({message: MSGS.INVALID_DATETIME_FORMAT, id, index, key:'start_timestamp', val:row.start_timestamp});
			}
		}
		if ( row.end_timestamp ) {
			let m = row.end_timestamp.match(regex);
			if ( m ) {
				if ( ! m[2] ) // has no time
					flutter.end_timestamp = moment(m[1], 'YYYY-MM-DD').format(globalTZ ? 'YYYY-MM-DD[T00:00:00Z]' : 'YYYY-MM-DD');
					// flutter.end_timestamp = Helper.toTimestamp(moment.tz(row.end_timestamp +' 23:59', 'YYYY-MM-DD HH:mm', globalTZ ? 'GMT' : SETTINGS.timezone));
				else if ( m[3] ) // has ampm
					flutter.end_timestamp = moment(`${m[1]} ${m[2]}${m[3]}`, 'YYYY-MM-DD h:mma').format(globalTZ ? 'YYYY-MM-DD[T]HH:mm:[00Z]' : 'YYYY-MM-DD HH:mm');
					// flutter.end_timestamp = Helper.toTimestamp(moment.tz(row.end_timestamp, 'YYYY-MM-DD h:mma', globalTZ ? 'GMT' : SETTINGS.timezone));
				else // 24hr
					flutter.end_timestamp = moment(`${m[1]} ${m[2]}`, 'YYYY-MM-DD H:mm').format(globalTZ ? 'YYYY-MM-DD[T]HH:mm:[00Z]' : 'YYYY-MM-DD HH:mm');
					// flutter.end_timestamp = Helper.toTimestamp(moment.tz(row.end_timestamp, 'YYYY-MM-DD H:mm', globalTZ ? 'GMT' : SETTINGS.timezone));
				
				if ( flutter.start_timestamp && flutter.start_timestamp > flutter.end_timestamp )
					this._addError({message: MSGS.INVALID_END_DATE_ORDER, id, index, key:'end_timestamp', val:row.end_timestamp});
			} else {
				this._addError({message: MSGS.INVALID_DATETIME_FORMAT, id, index, key:'end_timestamp', val:row.end_timestamp});
			}
		} else if ( strict )
			this._addError({message: MSGS.MISSING_VALUE, id, index, key:'end_timestamp', val:row.end_timestamp});

		// CON2-699 fix batch notes
		if ( row.batch_notes ) flutter.notes = row.batch_notes;

		notif.flutter = flutter;

		return notif;
	}


	_addWarning(item){
		this.warnings.push(item);
	}
	_addError(item){
		this.errors.push(item);
		(this.errors.byCell[item.index +'~'+ item.key] = this.errors.byCell[item.index +'~'+ item.key] || []).push(item);
		(this.errors.byRow[item.index] = this.errors.byRow[item.index] || []).push(item);
	}

	hasRowErrors(index){
		return this.errors.length && !! this.errors.byRow[index]?.length;
	}
	getRowErrors(index){
		return this.errors.length && this.errors.byRow[index] || [];
	}
	hasCellErrors(index, col){
		let key = this.previewData.headers[col];
		return this.errors.length && !! this.errors.byCell[index +'~'+ key]?.length;
	}
	getCellErrors(index, col){
		let key = this.previewData.headers[col];
		return this.errors.length && this.errors.byCell[index +'~'+ key] || [];
	}

}