import MilestonesListController from './milestones.list.controller'
import MilestonesBatchListController from './milestones.batch.list.controller'
import MilestoneQRListController from './milestone.qr.list.controller'
import MilestoneSingleController from './milestone.single.controller'
import MilestoneUploadController from './milestone.upload.controller'
import {Helper, PERMISSIONS, PERMISSION_LEVELS} from '../../common'
import './milestones.scss'


export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {

	const $stateRegistry = $uiRouterProvider.stateRegistry;

	$stateRegistry.register({
		name: 'app.milestones',
		title: 'Tasks',
		url: 'tasks?searchString&sortBy&sortOrder&dateFrom&dateTo&colleges&districts&cohorts&terms',
		access: {[PERMISSIONS.READ_TASKS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT]},
		views: {
			'content@app': {
				template: require('./milestones.list.html'),
				controller: MilestonesListController,
				controllerAs: 'ctrl',
			},
		},
		dynamic: true,
		params: {
			nonce: 0,
			scroll:       {value: 0, inherit:false, dynamic:true},
			searchString: {type:'query', inherit:false},
			sortBy:       {type:'query', inherit:false},
			sortOrder:    {type:'query', inherit:false},
			dateFrom:     {type:'query', inherit:false},
			dateTo:       {type:'query', inherit:false},
			colleges:     {type:'query', inherit:false, array:true},
			districts:    {type:'query', inherit:false, array:true},
			cohorts:      {type:'query', inherit:false, array:true},
			terms:         {type:'query', inherit:false, array:true},
		},
	});

	$stateRegistry.register({
		name: 'app.milestones.batches',
		title: 'Tasks Batches',
		url: '/batches?sortBy&sortOrder',
		access: {[PERMISSIONS.READ_TASKS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT]},
		views: {
			'content@app': {
				template: require('./milestones.batch.list.html'),
				controller: MilestonesBatchListController,
				controllerAs: 'ctrl',
			},
		},
		dynamic: true,
		params: {
			nonce: 0,
			scroll:       {value: 0, inherit:false, dynamic:true},
			sortBy:       {type:'query', inherit:false},
			sortOrder:    {type:'query', inherit:false},
		},
	});

	$stateRegistry.register({
		name: 'app.milestones.addbatch',
		title: 'Add Task Batch',
		url: '/add-batch',
		access: {[PERMISSIONS.CREATE_TASKS_BATCHES]: true},
		views: {
			'content@app': {
				template: require('./milestone.upload.html'),
				controller: MilestoneUploadController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
			provide: 'data',
			deps: [],
			useFactory: ()=>null,
		}]
	});


	let milestoneSingleHtml = require('./milestone.single.html');

	$stateRegistry.register({
		name: 'app.milestones.add',
		title: 'Add Task',
		url: '/add?clone',
		access: {[PERMISSIONS.CREATE_TASKS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT]},
		views: {
			'content@app': {
				template: milestoneSingleHtml,
				controller: MilestoneSingleController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'data',
				deps: [],
				useFactory: ()=>null,
			}, {
				provide: 'clone',
				deps: ['api', '$state', '$stateParams'],
				useFactory: (api, $state, $stateParams)=>!$stateParams.clone ? null : api.get('tasks/'+ $stateParams.clone)
					.then(res=>Helper.deepCopy(res.data))
					.catch(err=>{
						if ( api.isApiError(err) ) {
							let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
							return $state.go(state, {error: err}, {location:false});
						}
						throw err;
					}),
			}, {
				provide: 'parentStateParams',
				deps: [],
				useFactory: ()=>({}),
		}],
		params: {
			clone: {type:'query', inherit:false},
		},
	});

	$stateRegistry.register({
		name: 'app.milestones.edit',
		title: ['data', data=>'Task #'+ data._id],
		url: '/{id:int}',
		access: {[PERMISSIONS.UPDATE_TASKS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT]},
		views: {
			'content@app': {
				template: milestoneSingleHtml,
				controller: MilestoneSingleController,
				controllerAs: 'ctrl',
			},
		},
		params: {
			uploads: {dynamic: true, type: 'any', },
		},
		resolve: [{
				provide: 'data',
				deps: ['api', '$state', '$stateParams', '$timeout'],
				useFactory: (api, $state, $stateParams, $timeout)=>api.get('tasks/'+ $stateParams.id)
					.then((res)=>res.data, (err)=>{
						if ( api.isApiError(err) ) {
							let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
							return $state.go(state, {error: err}, {location:false});
						}
						throw err;
					}),
			}, {
				provide: 'clone',
				deps: [],
				useFactory: ()=>null,
			}, {
				provide: 'parentStateParams',
				deps: [],
				useFactory: ()=>({}),
		}],
	});


	$stateRegistry.register({
		name: 'app.milestones.edit.stepqr',
		title: ['data', 'stepid', (data, stepid)=>'Manage QR Codes — Step '+ stepid +' — Task #'+ data._id],
		url: '/step/{stepid:int}/manage-qr',
		views: {
			'content@app': {
				template: require('./milestone.qr.list.html'),
				controller: MilestoneQRListController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'stepid',
				deps: ['data', '$state', '$stateParams', '$timeout'],
				useFactory: (data, $state, $stateParams, $timeout)=>{
					if ( ! Object.keys(data.steps).includes(''+ $stateParams.stepid) )
						return $state.go('app.404', null, {location:false});
					return $stateParams.stepid;
				},
		}],
	});

}];
