import angular from 'angular'
import $ from 'jquery'
import {Helper, MESSAGES, SETTINGS, CONSTANTS} from '../../common'
import BaseRecipientSingleController from '../base.recipient.single'

const SCOPE_EVENTS = CONSTANTS.SCOPE_EVENTS;


export default class EventSingleController extends BaseRecipientSingleController {
	static get $inject(){return [
		'$timeout',
		'clone',
		'heatmap',
		'parentStateParams',
    '$sce',
	].concat(BaseRecipientSingleController.$inject).filter(Helper.uniqueFilter)}

	static get DEFAULTS(){return {
		ancestry: SETTINGS.apiAncestry,
		recipients: [],
		flutter: {},
		text: {content: CONSTANTS.CONTENT_TYPE.HTML},
	}}
	static RESOURCE = Object.freeze({
		type: 'dates_and_events',
		single: 'event',
		api: 'events',
		msgs: MESSAGES.EVENTS,
		exitRoutes: ['app.events.add', 'app.events.edit'],
		scopeSubmitEmit: 'event.submitted',
	})


	init(){
		this.mapping = {
			status: Helper.superMap(this.MAPPINGS_JSON.notification.status, {type:'status'}),
			types: Helper.superMap(this.MAPPINGS_JSON.notifications.type_key, {type:'type'}),
			categories: Helper.superMap(this.MAPPINGS_JSON.notifications.category_key, {type:'category'}),
			flutterTypes: Helper.superMap({
				'span': 'Span',
				'gathering': 'Gathering',
				'deadline': 'Deadline',
				'all-day': 'All Day',
			}, {type:'flutterType'}),
			seasons: Helper.superMap({
				'WINTER': 'WINTER',
				'SPRING': 'SPRING',
				'SUMMER': 'SUMMER',
				'FALL': 'FALL',
			}, {type:'flutterType'}),
			tzSource: Helper.superMap({
				'college': 'College',
				'global': 'Global',
			}, {type:'tzSource'}),
			priorities: {
				'100': 'Critical',
				'90': 'High',
				'50': 'Medium',
				'20': 'Low',
			},
		};
		// todo: BT-68; check if this var is used else where; remove if safe
		// this._min_date = new Date();
		this.preview = {screen:'home'};

		// this might be hacky, but fixes calendar edit
		if ( this.$scope.$parent?.ctrl && this.$scope.$parent?.ctrl !== this ) {
			this.$scope.$parent.editCtrl = this;
		}

		this._destructors.push(
			()=>{
				this.heatmap.clear();
			},

			this.$scope.$on(SCOPE_EVENTS.RECIPIENT_UPDATE, ($e, i, group)=>{
				!this.isLocked && this.heatmap.setExpr(group.expr);
			}),

			this.$scope.$once(SCOPE_EVENTS.DEPS_LOADED, ()=>{
				if ( this.clone ) {
					this.model = this.createModel(this.clone);
					['_id', 'status', 'workspace_id', 'created_by_user_id', 'created_date', 'modified_by_user_id', 'modified_date'].forEach(k=>delete this.model[k]);
					this.promptExit.enable(this.$state.current.name);
					
					// we copy clone into data temporarily
					this.data = this.clone;
					// delete data later
					this.$scope?.$once(SCOPE_EVENTS.DATA_READY, ()=>{
						delete this.data;
						this.$timeout(()=>this.$scope.form.$$controls.forEach(field=>field.$validate()));
					});
				} else {
					this.model = this.createModel(this.data ?? {});

					if ( this.data?.status == 'sent' ) {
						this.isLocked = true;
						this.lockReason = this.constructor.RESOURCE.msgs.FORM_LOCKED;
					}
				}
				this.onDetailsChanged();
			}),

			this.$scope.$once(SCOPE_EVENTS.DATA_READY, ()=>{
				// fix de sync of notif list ctrl data-ready event & this ctrl data-ready
				if ( this.$scope.$parent.ctrl && this.$scope.$parent.ctrl.mapping )
					this.mapping = angular.extend({}, this.$scope.$parent.ctrl.mapping, this.mapping);
	
				this.updateCalendarValues();
				this.syncDates();
				this.heatmap.updateDate(this.model.date_to_send);
			}),
		);

		super.init();
	}
	_loadDependencies(){
		return this.$q.all([
			super._loadDependencies(),
			this.apiMap.getCategories().then(data=>this.mapping.flutterCategories = data),
			this.apiMap.getTags().then(data=>this.mapping.flutterTags = data),
		]);
	}

	createModel(data){
		const model = angular.extend({}, this.constructor.DEFAULTS, Helper.deepCopy(data));

		if ( this.clone && model.owner_college_id && !this.mapping.mycolleges.byId[model.owner_college_id] )
			model.owner_college_id = null;
		// BTC-508 default to 1 college administered or only if b12 is one of colleges
		if ( ! model.owner_college_id ) {
			if ( this.mapping.mycolleges.length == 1 ) {
				model.owner_college_id = this.mapping.mycolleges[0]._id;
			} else
			if ( this.mapping.mycolleges.byId[this.BEYOND12_ID] ) {
				model.owner_college_id = this.BEYOND12_ID;
			}
		}
		
		if ( model.text.formatted && model.text.content != CONSTANTS.CONTENT_TYPE.HTML ) {
			model.text.content = CONSTANTS.CONTENT_TYPE.HTML;
		} else
		if ( data.text && (! model.text.formatted || ! model.text.content) ) {
			model.text.content = CONSTANTS.CONTENT_TYPE.TEXT;
			model.text.formatted = model.text.raw;
		}
		
		model.date_to_send = model.date_to_send && moment(model.date_to_send).isValid() ? moment(model.date_to_send) : null;

		model.recipients = [];

		model.reminders = Object.values(model.reminders || {}).map((item)=>{
			item.date_to_send = item.date_to_send && moment(item.date_to_send).isValid() ? moment(item.date_to_send) : null;
			item._$locked = !item.date_to_send.isAfter(moment());
			return item;
		});

    if ( model.link != undefined && Helper.hasLangTags(model.link.text) ) {
      model._$linkText = this.$sce.trustAsHtml(Helper.convertToLangSpan(model.link.text));
    }

		model.categories = Object.keys(model.categories || {});


			model._$enable_flutter = !! model.flutter;

			let flutter = model.flutter = model.flutter || {};

			flutter.notification_type = this.constructor.RESOURCE.type;
			flutter.name = angular.merge({en_US:''}, flutter.name);
			flutter.end_name = angular.merge({en_US:''}, flutter.end_name);
			flutter.location = flutter.location || {};
			flutter.location.label = angular.merge({en_US:''}, flutter.location.label);
			
			flutter.hide_from_flutter = !! flutter.hide_from_flutter;

			flutter.tags = Object.keys(flutter.tags || {});

			
			flutter._$allday = true;
			flutter._$range = !!(flutter.start_timestamp && flutter.end_timestamp);

      if ( Helper.hasLangTags(flutter.name.en_US) ) {
        flutter._$name = this.$sce.trustAsHtml(Helper.convertToLangSpan(flutter.name.en_US));
      }

      if ( Helper.hasLangTags(flutter.location.label.en_US) ) {
        flutter._$location = this.$sce.trustAsHtml(Helper.convertToLangSpan(flutter.location.label.en_US));
      }

			let regex = /^(\d{4})-(\d{2})-(\d{2})\s?(?: (\d{1,2}):(\d{2})\s?(am|pm)?)?$/i;
			if ( flutter.end_timestamp ) {
				let m = flutter.end_timestamp.match(regex);
				if ( m ) {
					flutter.end_timestamp = moment().year(+m[1]).month(+m[2]-1).date(+m[3]).endOf('day');
					if ( m[4] ) { // has time
						flutter.end_timestamp.hour(+m[4] + (m[6] && /pm/.test(m[6]) ? 12 : 0)).minute(+m[5]).second(0);
						flutter._$allday = false;
					}
				} else
				if ( /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/.test(flutter.end_timestamp) ) { // ISO date
					// do NOT parse direct ISO dates, display values as is
					flutter.end_timestamp = moment(flutter.end_timestamp.substr(0,10) +' '+ flutter.end_timestamp.substr(11,5), 'YYYY-MM-DD HH:mm');
					flutter._$allday = false; // ISO dates have time, cannot be all day
				}
				// flutter.end_time = flutter.end_timestamp.toDate();
			}
			if ( flutter.start_timestamp ) {
				let m = flutter.start_timestamp.match(regex);
				if ( m ) {
					flutter.start_timestamp = moment().year(+m[1]).month(+m[2]-1).date(+m[3]).startOf('day');
					if ( m[4] ) {// has time
						flutter.start_timestamp.hour(+m[4] + (m[6] && /pm/.test(m[6]) ? 12 : 0)).minute(+m[5]).second(0);
						flutter._$allday = false;
					}
				} else
				if ( /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/.test(flutter.start_timestamp) ) { // ISO date
					// do NOT parse direct ISO dates, display values as is
					flutter.start_timestamp = moment(flutter.start_timestamp.substr(0,10) +' '+ flutter.start_timestamp.substr(11,5), 'YYYY-MM-DD HH:mm');
					flutter._$allday = false; // ISO dates have time, cannot be all day
				}
				// flutter.start_time = flutter.start_timestamp.toDate();
			} else if ( flutter.end_timestamp ) {
				flutter.start_timestamp = flutter.end_timestamp;
				// flutter.start_time = flutter.start_timestamp.toDate();
				flutter.end_timestamp = flutter.end_time = undefined;
			}
			if ( flutter.end_timestamp && flutter.start_timestamp )
				flutter._$sameDay = flutter.end_timestamp.diff(flutter.start_timestamp, 'days') === 0;
			
			flutter.source_timezone = flutter.source_timezone || 'college';

			let m = (flutter.term || '').match(/^(\d{4})\-(\w+)$/);
			flutter.term = m ? {year: m[1], season: m[2]} : {year: null};

			flutter.priority = isNaN(flutter.priority) ? 90 : +flutter.priority;

			if ( ! this.$scope.form.priority ) { // no priority field, round off
				if ( flutter.priority > 95 ) flutter.priority = 100;
				else if ( flutter.priority > 70 ) flutter.priority = 90;
				else if ( flutter.priority > 35 ) flutter.priority = 50;
				else flutter.priority = 20;
			}

		if ( flutter.end_timestamp || flutter.start_timestamp )
			this.syncDates(model);

		return model;
	}

	addReminder(){
		let list = this.model.reminders;
		list.push({
			date_to_send: null,
			text: '',
			question: false,
		});
		this.$scope.form.$setDirty();

		this.$timeout(()=>{
			let input = $(`input[name="reminder_${list.length -1}_date_to_send"]`)
			input.focus();
			Helper.smoothScrollTo(input);
		}, 100);
	}
	removeReminder($index, $ev){
		var confirm = this.$mdDialog.confirm()
					.title('Remove Additional Notification')
					.textContent('Are you sure you want to remove this additional notification?')
					.ariaLabel('Remove Additional Notification')
					.targetEvent($ev)
					.ok('Ok')
					.cancel('Cancel');

		this.$mdDialog.show(confirm).then(()=>{
			this.model.reminders.splice($index, 1);
			this.$scope.form.$setDirty();
		}, ()=>{});
	}


	async _submit($evt, form){
		const RESOURCE = this.constructor.RESOURCE;
		
		await this._validateForm(form);
		await this._validateRecipients({title: RESOURCE.msgs.SUBMIT_ALL_TITLE, word: RESOURCE.msgs.SUBMIT_ALL_DESC});

		this.isBusy = true;
		
		const payload = this._preparePayload();
		let result, toastMsg;
			
		if ( this.data ) {
			result = await this.api.put(RESOURCE.api +'/'+ this.data._id, payload);
			toastMsg = RESOURCE.msgs.UPDATE_OK;
		} else {
			result = await this.api.post(RESOURCE.api, payload);
			toastMsg = RESOURCE.msgs.SAVE_OK;
		}
		this.toast.success(toastMsg);

		this.promptExit.disable();
		if ( RESOURCE.exitRoutes.includes(this.$state.current.name) ) {
			this.$state.go('^', this.data && this.parentStateParams || {});
		}
		this.$scope?.$emit(RESOURCE.scopeSubmitEmit, result.data._id);

		return this.result;
	}
	_preparePayload(){
		const RESOURCE = this.constructor.RESOURCE;
		const payload = Helper.deepCopy(this.data || {flutter:{}});
		const model = Helper.deepCopy(this.model);

		// build recipients // get from actual model, not the cloned
		payload.recipients = {};
		(this.model.recipients || []).forEach((group, index)=>{
			payload.recipients['recipient_group_'+ (index+1)] = group.expr;
		});

		payload.owner_college_id = +model.owner_college_id;

		// clean up
		if ( payload.type ) delete payload.type; // CON2-304
		
		if ( model.text.content == CONSTANTS.CONTENT_TYPE.HTML ) {
			payload.text = {
				content: model.text.content,
				raw: Helper.htmlToText(model.text.formatted),
				formatted: model.text.formatted,
			};
		} else {
			payload.text = {
				content: model.text.content || CONSTANTS.CONTENT_TYPE.TEXT,
				raw: model.text.formatted,
				formatted: undefined,
			};
		}

		// payload.notification_type = model.notification_type;
		payload.date_to_send = Helper.toNoonISO(model.date_to_send);
		payload.link = model.link ? {
			url: model.link.url ? model.link.url.replace(/\s/g, '%20') : undefined,
			text: model.link.text || undefined
		} : undefined;
		payload.source = model.source;
		payload.research = model.research;

		// build categories
		if ( model.categories && model.categories.length > 0 ) {
			payload.categories = {};
			model.categories.forEach((value)=>{
				payload.categories[value] = {};
			});
		}

		// build reminders
		payload.reminders = model.reminders.length > 0
			? model.reminders.reduce((obj, item, index)=>({...obj, [index+1]:{
					text: item.text,
					question: !!item.question,
					date_to_send: Helper.toNoonISO(item.date_to_send),
				}}), {})
			: undefined;

		if ( this.data ) {
			payload.modified_by_user_id = +this.authorization.userId;
			payload.modified_date = Helper.toTimestamp(new Date());
		} else {
			payload.created_by_user_id = +this.authorization.userId;
			payload.created_date = Helper.toTimestamp(new Date());
		}


		if ( model._$enable_flutter ) {
			let flutter = payload.flutter = Helper.deepCopy(payload.flutter || {});
			// flutter.notification_type = 'dates_and_events';

			flutter.name = model.flutter.name.en_US ? model.flutter.name : undefined;
			flutter.end_name = model.flutter.end_name.en_US ? model.flutter.end_name : undefined;
			flutter.location = model.flutter.location.label.en_US ? model.flutter.location : undefined;

			flutter.short_name = model.flutter.short_name || undefined;
			flutter.hide_from_flutter = !! model.flutter.hide_from_flutter;
			flutter.source_timezone = model.flutter.source_timezone || undefined;

			flutter.ref_id = model.flutter.ref_id || undefined;
			if ( ! flutter.hide_from_flutter ) {
				delete flutter.ref_id;
			}

			let dateFormat = flutter.source_timezone=='global' ? 'YYYY-MM-DD[T]HH:mm[:00Z]' : 'YYYY-MM-DD'+ (model.flutter._$allday ? '' : ' HH:mm');
			if ( model.flutter._$range ) {
				flutter.start_timestamp = moment(model.flutter.start_timestamp).format(dateFormat);
				flutter.end_timestamp = moment(model.flutter.end_timestamp).format(dateFormat);
			} else {
				// not range, start is placed into end
				if ( model.flutter.start_timestamp ) {
					flutter.end_timestamp = moment(model.flutter.start_timestamp).format(dateFormat);
				}
				flutter.start_timestamp = undefined;
			}

			flutter.tags = model.flutter.tags.length > 0 
				? model.flutter.tags.reduce((obj, key)=>({...obj, [key]:{}}), {})
				: undefined;
			
			flutter.category = model.flutter.category;

			if ( model.flutter.term && model.flutter.term.year && model.flutter.term.season )
				flutter.term = model.flutter.term.year +'-'+ model.flutter.term.season;
			else
				flutter.term = undefined;

			flutter.priority = model.flutter.priority;
		} else {
			delete payload.flutter;
		}

		payload.publish = payload.publish !== undefined ? payload.publish : true;
		payload.ancestry = payload.ancestry || SETTINGS.apiAncestry;

		return payload;
	}

	async delete($ev){
		const RESOURCE = this.constructor.RESOURCE;

		try {
			await this.$mdDialog.show(
				this.$mdDialog.confirm()
						.title(`Are you sure you want to delete this ${RESOURCE.single}?`)
						.ariaLabel('confirm delete')
						.targetEvent($ev)
						.ok('Delete')
						.cancel('Cancel')
			);
			this.isBusy = true;
			
			await this.api.delete(RESOURCE.api +'/'+ this.data._id);
				
			this.promptExit.disable();
			this.toast.success(`Deleted ${RESOURCE.single} #${this.data._id}`);
			this.$state.go('^', this.parentStateParams || {});
		} catch(e){
			this.isBusy = false;
		}
	}



	onDetailsChanged(){
		const text = this.model.text;
  
    if ( text.content == CONSTANTS.CONTENT_TYPE.HTML ) {
			text.html = text.formatted?.replace(/<a href=\"(.*?)\".*?>/gm, '<a href="$1" target="_blank" title="$1" ref="nofollow">');
		} else {
			text.html = Helper.plainLinksToHtml(Helper.encodeHtml(text.formatted || text.raw));
		}

    this.preview.name = this.$sce.trustAsHtml(Helper.toSafeLangHtml(this.model.flutter?.name?.en_US));
    this.preview.location = this.$sce.trustAsHtml(Helper.toSafeLangHtml(this.model.flutter.location?.label?.en_US));
    this.preview.linkText = this.$sce.trustAsHtml(Helper.toSafeLangHtml(this.model.link?.text));
	}


	syncDates(model){
		model = model || this.model;

		const start = model.flutter.start_timestamp;
		const end = model.flutter.end_timestamp;
		const preview = this.preview;

		if ( start && (! model.date_to_send || ! this.$scope.form.date_to_send) )
			model.date_to_send = start.clone();

		if ( start ) {
			if ( end )
				preview.isSameDay = end.diff(start, 'days')==0;

			// model.flutter._$format_start = (model.flutter._$range?'':'dddd, ') + 'MMM D, YYYY' + (model.flutter._$allday ?'': ' h:mm a');
			
			preview.day = '';

			if ( end && ! preview.isSameDay ) {
				preview.day += start.format('dddd, MMMM D');
				
				if ( start.format('YYYYMM') == end.format('YYYYMM') ) {
					preview.day += ' &ndash; '+ end.format('D, YYYY');
				} else if ( start.format('YYYY') == end.format('YYYY') ) {
					preview.day += ' &ndash; '+ end.format('MMMM D, YYYY');
				} else {
					preview.day += ', '+ start.format('YYYY') +' &ndash; '+ end.format('MMMM D, YYYY');
				}
			} else {
				preview.day += start.format('dddd, MMMM D, YYYY');
			}

			if ( ! model.flutter._$allday ) {
				if ( end && preview.isSameDay ) {
					preview.time = start.format('h:mm');
					let ampm = start.format('a');
					if ( ampm != end.format('a') ) { // diff am/pm
						preview.time += ' '+ ampm;
					}
					preview.time += ' &ndash; '+ end.format('h:mm a');
				} else {
					preview.time = start.format('h:mm a');
					if ( end ) {
						preview.time += ' &ndash; '+ end.format('h:mm a');
					}
				}
			} else {
				delete preview.time;
			}
		}
		
		// this is ugly, do NOT
		this.$timeout(()=>{
			$('#calendar-start-mark, #calendar-end-mark').hide();
			if ( start ) {
				let startStr = start.format('YYYY-MM-DD');
				let $cells = $('#calendar-picker .moment-picker-specific-views tbody td')
					.removeClass('dot-range dot-range-start dot-range-end');

				if ( this.model.flutter._$range && end && end.diff(start, 'days') > 0 ) {
					let endStr = end.format('YYYY-MM-DD');
					$cells
						.filter((i, cell)=>$(cell).attr('data') >= startStr && $(cell).attr('data') <= endStr)
						.addClass('dot-range');
					$cells.filter(`[data='${startStr}']`)
						.addClass('dot-range-start');
					$cells.filter(`[data='${endStr}']`)
						.addClass('dot-range-end');
				} else {
					let $cell = $cells.filter(`[data='${startStr}']`);
					let pos = $cell?.position();
					pos && $('#calendar-start-mark').show().css(pos);
				}
			}
		}, 250);
	}


	focus(selector){
		this.$timeout(()=>{
			$(selector).filter(':visible').focus();
		}, 100);
	}

	updateCalendarValues(){
		let flutter = this.model.flutter;

		flutter._$iconStart = flutter._$iconEnd = '#E906';
		flutter._$iconColor = '#519CA7';
		flutter._$iconColor2 = '#519CA7';

		// flutter.tags.forEach(id=>{
		// 	let tag = this.mapping.flutterTags.byId[id];
		// 	if ( tag ) {
		// 		flutter._$iconStart = tag.icon_start || flutter._$iconStart;
		// 		flutter._$iconEnd = tag.icon_end || flutter._$iconEnd;
		// 		flutter._$iconColor = tag.dark_color || flutter._$iconColor;
		// 		flutter._$iconColor2 = tag.light_color || flutter._$iconColor2;
		// 	}
		// });
		// let cat = this.mapping.flutterCategories.byId[flutter.category];
		// if ( cat ) {
		// 	flutter._$iconStart = cat.icon_start || flutter._$iconStart;
		// 	flutter._$iconEnd = cat.icon_end || flutter._$iconEnd;
		// 	flutter._$iconColor = cat.dark_color || flutter._$iconColor;
		// 	flutter._$iconColor2 = cat.light_color || flutter._$iconColor2;
		// }

		// flutter._$iconStart = '&#x'+ flutter._$iconStart.replace('#','');
		// flutter._$iconEnd = '&#x'+ flutter._$iconEnd.replace('#','');
	}
	updateCalendarData(key, $selected, $view){
		// this applies YYYY-MM-DD into data attribute
		if ( $selected === 'month' ) {
			$view.rows.forEach(row=>row.forEach(item=>item.data = `${item.year}-${Helper.addPadding(item.month+1, '00')}-${Helper.addPadding(item.date, '00')}`));
			$view.headers = moment.weekdays().map((d, i)=>moment().locale($view.$scope.locale).startOf('week').add(i, 'day').format('ddd'));

			$view.$scope?.$evalAsync(()=>{
				$view.$scope?.container?.find('[tabindex]').attr('tabindex', '-1');
			});
		}
	}

	/*changeFlutterStart(value, old){
		if ( ! old ) {
			this.model.flutter.start_timestamp.startOf('day');
			if ( this.model.flutter.type !== 'all-day' )
				this.model.flutter.start_timestamp.hours(8);
		}
	}*/


	renderDecade($selected, $view){
		if ( $selected === 'decade' ) {
			// replace render fn with our own computation
			$view.render = _renderOverride;
			$view.$scope.view.unit = ()=>$selected == 'decade' ? 12 : 1;
		}
	}


	updatePreview(value, auto){
		console.log('update preview', value);
		if ( value === 'home' ) {
			this.preview.screen = 'home';
		} else
		if ( value == 'details' ) {
			this.preview.screen = null;
		}
		this.preview.value = value;
		auto && setTimeout(()=>$('#task-preview .task-content').animate({scrollTop:0}, 500), 100);
	}

}


function _renderOverride(){
	let offset = moment().year() % 12;

	let year = this.$scope.view.moment.clone(),
		firstYear = Math.floor((year.year() -offset) / 12) * 12 +offset;

	this.rows = {};
	year.year(firstYear);
	for (let y = 0; y < 12; y++) {
		let index = Math.floor(y / this.perLine),
			selectable = this.$scope.limits.isSelectable(year, 'year');

		if (!this.rows[index]) this.rows[index] = [];
		this.rows[index].push({
			index: year.year(),
			label: year.format(this.provider.yearsFormat),
			year: year.year(),
			class: [
				this.$scope.keyboard && year.isSame(this.$scope.view.moment, 'year') ? 'highlighted' : '',
				!selectable ? 'disabled' : moment(this.$ctrl.$modelValue).isValid() && year.isSame(this.$ctrl.$modelValue, 'year') ? 'selected' : ''
			].join(' ').trim(),
			selectable: selectable
		});
		year.add(1, 'years');
	}
	// return title
	return [year.subtract(1, 'years').format('YYYY'), year.subtract(11, 'years').format('YYYY')].reverse().join(' - ');
}