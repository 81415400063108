<header class="md-whiteframe-2dp flex-none layout-row layout-align-space-between-start layout-wrap">
	<h2 id="pageTitle" class="flex-xs-100 md-headline">
		<a ui-sref="^" ui-sref-opts="ctrl.parentStateParams">Events <span class="margin-1x" aria-hidden="true">&#9656;
    </span></a><span class="margin-1x" aria-hidden="true">
		</span>{{ ctrl.data ? '' : 'Add New' }}
		<span id="docTitle" ng-show="ctrl.data">{{:: ctrl.data ? 'Event #'+ ctrl.data._id : 'new event'}}</span>
		<span role="img" aria-label="changes not saved" title="changes not saved" ng-show="form.$dirty">*</span>
	</h2>
	<div class="top-buttons flex-xs-100" ng-show="ctrl.data" form-disabled="ctrl.isBusy">
		<md-button id="cloneBtn" aria-labelledby="cloneBtn docTitle"
			ui-sref="app.events.add({clone: ctrl.data._id})"
			ng-if=":: mainCtrl.hasPermission(PERMISSIONS.CREATE_EVENTS)">
			<span class="fas fa-copy"></span> 
			Clone
		</md-button>
		<md-button class="md-warn" id="deleteBtn" aria-labelledby="deleteBtn docTitle" 
			ng-click="ctrl.delete($event)"
			ng-if=":: mainCtrl.hasPermission(PERMISSIONS.DELETE_EVENTS)"
			ng-hide="ctrl.isLocked">
			<span class="far fa-trash-alt"></span> 
      Delete
		</md-button>
	</div>
	
	<div flex="100" ng-include="::'common/lockCard.tpl'"></div>
</header>

<main class="flex">
	<form id="notification-form" name="form" novalidate 
		class="flex-gt-sm" ng-class="{locked: ctrl.isLocked}" 
		aria-labelledby="pageTitle"
		form-disabled="ctrl.isBusy || ctrl.isLocked"
		ng-submit="!ctrl.isBusy && !ctrl.isLocked && ctrl.submit($event, form)" 
		ng-model-options="{updateOn:'default blur', debounce:{'default':250, 'blur':0}}">
		<input type="submit" class="hide" tabindex="-1">

		<div class="panel-wrap layout-gt-md-row layout-align-start-stretch">
			<div class="form-details panel-wrap panel-small flex">
				<div class="panel md-whiteframe-1dp" role="group" aria-label="Event Details" ng-include="'event.form.detail.tpl'"></div>
			</div>

			<div class="panel-wrap flex-none layout-column layout-align-start-center">
				<div class="panel-wrap previews layout-column layout-align-gt-md-start-stretch">
					<div class="panel calendar-preview" md-whiteframe="1" 
						ng-show="ctrl.model._$enable_flutter && !ctrl.model.flutter.hide_from_flutter && ctrl.model.flutter.start_timestamp">
						<div class="panel-title md-title normal-wg padding-4x">Mobile Calendar Preview</div>
						<div class="panel-content mobile-preview layout-column cursor-normal no-select">
							<div class="mobile-header collapse flex-nogrow"></div>

							<div class="mobile-content flex layout-column">
								<div class="calendar flex-nogrow" id="calendar-picker" role="img" aria-label="calendar">
									<div moment-picker ng-model="ctrl.model.flutter.start_timestamp"
										tabindex="{{-1}}"
										render="ctrl.updateCalendarData('calendar-preview', $selected, $view)"
										inline="true" min-view="month" disable="true"></div>
									<small id="calendar-start-mark" ng-style="{color: ctrl.model.flutter._$range && ctrl.model.flutter.end_timestamp.diff(ctrl.model.flutter.start_timestamp, 'days') > 0 ? ctrl.model.flutter._$iconColor2 : ctrl.model.flutter._$iconColor}">
										<i class="fas fa-circle"></i>
										{{ ctrl.model.flutter.short_name }}
									</small>
									<small id="calendar-end-mark" ng-style="{color: ctrl.model.flutter._$iconColor2}" ng-show="ctrl.model.flutter._$range && ctrl.model.flutter.end_timestamp.diff(ctrl.model.flutter.start_timestamp, 'days') > 0">
										<i class="fas fa-circle"></i>
										{{ ctrl.model.flutter.short_name }}
									</small>
								</div>
								<div class="calendar-divider">
									<span class="arrow-up">
										<i class="gg-arrow-up"></i>
									</span>
								</div>
								<div class="calendar-list flex layout-column">
									<div class="month" aria-label="{{ ctrl.model.flutter.start_timestamp | date:'MMMM' }}">{{ ctrl.model.flutter.start_timestamp | amDateFormat:'MMM' }}</div>
									<div class="item layout-row">
										<div class="item-date flex-none layout-row layout-align-center-center">
											<span>{{ ctrl.model.flutter.start_timestamp | amDateFormat:'D' }}<span
												ng-show="ctrl.model.flutter._$range && ctrl.model.flutter.end_timestamp.diff(ctrl.model.flutter.start_timestamp, 'days') > 0">-{{ ctrl.model.flutter.end_timestamp | amDateFormat:'D' }}</span></span>
										</div>
										<div class="item-content flex layout-row layout-align-start-center layout-wrap" ng-class="{'has-owner': ctrl.model.owner_college_id!=BEYOND12_ID}">
											<div class="title" ng-class="{'italic black-25': !ctrl.model.flutter.name.en_US}" ng-hide="ctrl.model.flutter._$name">
												<span ng-bind-html="ctrl.preview.name"></span>
												{{ ctrl.preview.name ? '' : '{Event Title}' }}
											</div>
                      <div class="title" ng-class="{'italic black-25': !ctrl.model.flutter.name.en_US}" ng-show="ctrl.model.flutter._$name" ng-bind-html="ctrl.model.flutter._$name"></div>
											<div class="owner flex-100">
												{{ ctrl.mapping.mycolleges.byId[ctrl.model.owner_college_id].name }}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="mobile-footer flex-nogrow layout-row">
								<div class="flex-20" aria-hidden="true"></div>
								<div class="flex-20" aria-hidden="true"></div>
								<div class="flex-20 active" aria-hidden="true"></div>
								<div class="flex-20" aria-hidden="true"></div>
								<div class="flex-20" aria-hidden="true"></div>
							</div>
						</div>
					</div>
				
					<div class="panel event-preview" md-whiteframe="1" 
						ng-show="ctrl.model.flutter.name.en_US || ctrl.model.text.html">
						<div class="panel-title md-title normal-wg padding-4x">Mobile Event Preview</div>
						<div class="panel-content mobile-preview flex layout-column cursor-normal no-select">
							<div class="mobile-header flex-nogrow">
								<span class="icon"></span>
								<span class="text">event</span>
							</div>

							<div class="mobile-content flex event">
								<section class="title">
									<h4 ng-class="{'italic black-25': !ctrl.preview.name}">
										<span ng-bind-html="ctrl.preview.name"></span>
										{{ ctrl.preview.name ? '' : '{Event Title}' }}
									</h4>
									<p>
										<span class="text-lowercase" ng-bind-html="ctrl.preview.day" aria-label="{{ ctrl.preview.day }}"></span>
										<span ng-bind-html="ctrl.preview.time" aria-label="{{ ctrl.preview.time }}"></span><br>
                    <span class="no-animate" ng-bind-html="ctrl.preview.location"></span>
								</section>
								<section class="meta layout-row layout-align-end">
									<span class="college flex text-uppercase md-truncate" ng-hide="ctrl.model.owner_college_id == BEYOND12_ID">
										{{ ctrl.mapping.mycolleges.byId[ctrl.model.owner_college_id].name }}
									</span>
									<span class="share flex-none"></span>
									<span class="add flex-none"><i class="fas fa-plus"></i></span>
								</section>
								<section class="description">
									<div class="text-preview"
										ng-bind-html="ctrl.model.text.html" 
										ng-show="ctrl.model.text.html" 
										ng-class="ctrl.model.text.content=='text/html' ? 'html' : 'plain'"></div>
									<em class="black-25" ng-hide="ctrl.model.text.html">{Event Details}</em>
								</section>

								<div class="button-preview" ng-show="ctrl.model.link.text">
									<a ng-href="{{ctrl.model.link.url}}" title="{{ctrl.model.link.url}}" 
										target="_blank" rel="nofollow"><span ng-bind-html="ctrl.preview.linkText"></span></a>
                  </a>
								</div>
							</div>

							<div class="mobile-footer flex-nogrow layout-row">
								<div class="flex-20" aria-hidden="true"></div>
								<div class="flex-20" aria-hidden="true"></div>
								<div class="flex-20 active" aria-hidden="true"></div>
								<div class="flex-20" aria-hidden="true"></div>
								<div class="flex-20" aria-hidden="true"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="form-reminders panel-wrap">
			<div class="panel panel-small md-whiteframe-1dp" role="group" aria-label="Reminders" ng-include="'event.form.reminders.tpl'"></div>
		</div>
		
	</form>
</main>

<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

<footer class="text-right" md-whiteframe="2" ng-hide="ctrl.isLocked">
	<md-button type="button" class="md-raised md-warn" id="saveBtn" aria-labelledby="saveBtn docTitle"
		ng-disabled="ctrl.isBusy" ng-click="ctrl.submitForm('form')">
		<span class="far fa-save" aria-hidden="true"></span> Save
	</md-button>
</footer>
