<header id="milestones-list-header" class="md-whiteframe-2dp flex-none">
	<form name="headerForm" novalidate ng-model-options="{updateOn:'default blur', debounce:{'default':500, 'blur':0}}">
		<div layout="column" layout-gt-md="row" layout-align="space-between stretch">
			<h2 class="md-headline">
				<a ui-sref="app.milestones">Tasks</a> &#9656;
				<a ui-sref="^">Task #{{ctrl.data._id}}</a> &#9656;
				Step #{{ ctrl.stepid }} &#9656;
				Manage QR Code
			</h2>
			<div layout layout-align-xs="end start" layout-align="end start" layout-wrap>
				<md-input-container>
					<label>Sort</label>
					<md-select ng-model="ctrl.query.sortBy" ng-change="ctrl.refresh(true)">
						<md-option ng-repeat="item in ctrl.mapping.sortBy" ng-value="item._id"><span ng-bind-html="item.name"></span></md-option>
					</md-select>
				</md-input-container>
				<md-input-container>
					<label>Order</label>
					<md-select ng-model="ctrl.query.sortOrder" ng-change="ctrl.refresh(true)">
						<md-option ng-repeat="item in ctrl.mapping.sortOrder" ng-value="item._id"><span ng-bind-html="item.name"></span></md-option>
					</md-select>
				</md-input-container>
			</div>
		</div>
	</form>
</header>

<main class="main-list milestones-qr-list flex layout-column">
	<md-virtual-repeat-container class="data-list flex" md-top-index="ctrl.itemIndex" ng-show="ctrl.dataList.length > 0">
		<div id="item-{{ item._id }}" class="data-item milestone-qr-item layout-column" ng-class="{loading: !item}" md-virtual-repeat="item in ctrl" md-on-demand="true">
			<div class="layout-row layout-wrap flex" ng-include="'/milestones.qr.item.tpl'"></div>
		</div>
	</md-virtual-repeat-container>

	<div class="flex layout-row layout-align-center-center" ng-hide="ctrl.dataList.length > 0 || ctrl.isBusy || ctrl.partialBusy">
		<p class="text-center alpha-50 md-caption"><em>No results found <span ng-show="ctrl.query.search">for &quot;{{ ctrl.query.search }}&quot;</span></em></p>
	</div>
</main>


<div ng-if="ctrl.error" class="no-animate" error-card="ctrl.error" error-card-retry="ctrl.refresh()" ng-cloak></div>
<div ng-show="ctrl.isBusy" class="busy-overlay fade-in fade-out" layout layout-align="center center">
	<md-progress-circular md-mode="indeterminate"></md-progress-circular>
</div>

<footer class="md-whiteframe-2dp padding-2x layout-row layout-align-start-center layout-wrap">
	<md-button class="md-icon-button md-mini md-primary" ng-click="ctrl.refresh(true)">
		<i class="fas fa-sync-alt"></i>
	</md-button>
	<p class="md-body-1">{{ ctrl.query.total }} Results</p>
	<span flex></span>

	<md-button class="md-primary" ng-click="ctrl.$mdSidenav('qr-form').open()" aria-label="open">
		<i class="fas fa-plus-square"></i>&nbsp;&nbsp;ADD NEW
	</md-button>
</footer>


<md-sidenav id="milestone-qr-pdf-form" class="md-sidenav-right" md-component-id="qr-pdf-form">
	<form name="pdfForm" novalidate class="panel margin-0x" ng-submit="ctrl.getPDF()">
		<div class="panel-title padding-2x layout-row">
			<h4 class="md-title margin-2x flex">Download PDF - QR Batch #{{ctrl.batch._id}}</h4>
			<md-button class="md-icon-button black-33" ng-click="ctrl.$mdSidenav('qr-pdf-form').close()" aria-label="close">
				<span class="fas fa-times"></span>
			</md-button>
		</div>
		<div class="panel-content padding-4x">
			<md-input-container class="md-block">
				<label>Paper Size</label>
				<md-select name="paperSize" ng-model="ctrl.pdfData.paperSize" ng-model-options="{trackBy: '$value'}" ng-change="ctrl.changePaperSize(ctrl.pdfData.paperSize, ctrl.pdfData.paperUnit)">
					<md-option ng-repeat="item in ctrl.mapping.paperSizes" ng-value="item.name">{{item.name}}</md-option>
				</md-select>
				<div class="md-errors-spacer pull-right"></div>
			</md-input-container>
			<div class="layout-row">
				<md-input-container>
					<label>Width</label>
					<input type="number" name="paperWidth" ng-model="ctrl.pdfData.paperWidth" ng-change="ctrl.changePaperDimension(ctrl.pdfData.paperWidth, ctrl.pdfData.paperHeight)" step="0.01">
				</md-input-container>
				<span class="padding-1x"></span>
				<md-input-container>
					<label>Height</label>
					<input type="number" name="paperHeight" ng-model="ctrl.pdfData.paperHeight" ng-change="ctrl.changePaperDimension(ctrl.pdfData.paperWidth, ctrl.pdfData.paperHeight)" step="0.01">
				</md-input-container>
			</div>
			<md-input-container class="md-block">
				<label>Unit</label>
				<md-select name="paperUnit" ng-model="ctrl.pdfData.paperUnit" ng-model-options="{trackBy: '$value'}" ng-change="ctrl.changePaperSize(ctrl.pdfData.paperSize, ctrl.pdfData.paperUnit)">
					<md-option ng-repeat="value in ctrl.mapping.paperUnits" ng-value="value">{{value}}</md-option>
				</md-select>
				<div class="md-errors-spacer pull-right"></div>
			</md-input-container>
			<md-input-container class="md-block">
				<label>Design</label>
				<md-select name="design" ng-model="ctrl.pdfData.design" ng-model-options="{trackBy: '$value'}">
					<md-option ng-repeat="item in ctrl.mapping.designs" ng-value="item.name">{{item.name}}</md-option>
				</md-select>
				<div class="md-errors-spacer pull-right"></div>
			</md-input-container>
		</div>
		<div class="panel-title padding-4x">
			<md-button type="submit" class="md-primary md-raised margin-0x">
				<i class="fas fa-download"></i>&nbsp;&nbsp;DOWNLOAD
			</md-button>
		</div>
	</form>
</md-sidenav>

<md-sidenav id="milestone-qr-form" class="md-sidenav-right" md-component-id="qr-form">
	<form name="pdfForm" novalidate class="panel margin-0x" ng-submit="ctrl.createQR()">
		<div class="panel-title padding-2x layout-row">
			<h4 class="md-title margin-2x flex">Create QR Batch</h4>
			<md-button class="md-icon-button black-33" ng-click="ctrl.$mdSidenav('qr-form').close()" aria-label="close">
				<span class="fas fa-times"></span>
			</md-button>
		</div>
		<div class="panel-content padding-4x">
			<md-input-container class="md-block">
				<label>No. of Uses</label>
				<md-select name="multi_use" ng-model="ctrl.qrData.multi_use" ng-model-options="{trackBy: '$value'}" ng-change="ctrl.qrData.multi_use ? (ctrl.qrData.quantity=1) : null">
					<md-option ng-repeat="(key, value) in ctrl.mapping.qrUses" ng-value="value">{{key}}</md-option>
				</md-select>
				<div class="md-errors-spacer pull-right"></div>
			</md-input-container>
			<md-input-container class="md-block">
				<label>Quantity</label>
				<input type="number" name="quantity" ng-model="ctrl.qrData.quantity" step="1" min="1" ng-disabled="ctrl.qrData.multi_use">
			</md-input-container>
		</div>
		<div class="panel-title padding-4x">
			<md-button type="submit" class="md-primary md-raised margin-0x">
				<i class="fas fa-plus-square"></i>&nbsp;&nbsp;CREATE
			</md-button>
		</div>
	</form>
</md-sidenav>


<script type="text/ng-template" id="/milestones.qr.item.tpl">
		<div class="data-id compact text-right">
			<h3 class="md-headline black-66 margin-0x">{{ item.quantity }}x</h3>
			<em class="md-caption black-33">{{ item.multi_use ? 'Multiple' : 'Single' }} Use</em>
		</div>
		<span class="padding-1x"></span>
		<div class="data-content flex layout-column layout-align-start-stretch">
			<h5 class="md-subhead">Batch #{{ item._id }}</h5>
			<p class="md-body-1 black-25">
				by <span class="black-50">{{ ctrl.mapping.users.byId[item.created_by].first_name || item.created_by }}</span>,
				<span class="black-66">{{ item.created_date | amDateFormat:SETTINGS.dateFormat }}</span>
			</p>
		</div>
		<div class="layout-row layout-align-end-center flex-xs-100">
			<md-button type="button" class="data-action black-50" ng-click="ctrl.makeLive(item._id, $event)" ng-disabled="item.is_live">
				<i class="fas fa-broadcast-tower"></i>&nbsp;
				{{ item.is_live ? 'Is Live' : 'Go Live' }}
			</md-button>
			<md-button type="button" class="data-action black-50" ng-click="ctrl.batch = item; ctrl.$mdSidenav('qr-pdf-form').open();">
				<i class="fas fa-file-pdf"></i>&nbsp;&nbsp;PDF
			</md-button>
		</div>
</script>