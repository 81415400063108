import angular from 'angular'
import {DependencyInjected} from '../common'


export default class ResetPassController extends DependencyInjected {
	
	static get $inject(){return [
		'api',
		'toast',
		'$location',
		'$state',
		'$timeout',
		'validToken',
	]}

	init(){
	  // CON2-270: check if request is from console or not
	  this.fromConsole = (+this.$state.params.console === 1);
	  this.fromApp = !this.fromConsole && this.$state.params.source === 'app';
	}

	submit(){
		this.api.put('authentication/password/reset/token', {password:this.password1, token:this.$state.params.token})
			.then((res)=>{
				this.success = true;
				this.isBusy = false;
				if ( this.fromApp )
					this.$timeout(()=>window.open('https://gradguru.org/start', '_blank'), 3000);
			}, (err)=>{
				if ( this.api.isApiError(err) ) {
					if ( ! err.isIgnored() ) {
						this.toast.error(err);
						if ( err.isWarning() )
							console.warn(err.message);
					}
				}
				this.isBusy = false;
			});
		this.toast.clear();
		this.isBusy = true;
	}
}
