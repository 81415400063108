import {Helper, ApiError, MESSAGES, SETTINGS} from '../../common'
import BaseSingleController from '../base.single'


const LOCK_ROLES = ['bot', 'salesforce'];


export default class UserSingleController extends BaseSingleController {
	static get $inject(){return [
		'$mdDialog',
		'$timeout',
		'api',
	].concat(BaseSingleController.$inject)}


	init(){
		delete this.MAPPINGS_JSON.roles.role_key['mfa_required_user'];

		this.mapping = {
			status: Helper.superMap(this.MAPPINGS_JSON.users.status_key, {type:'status'}),
			// CON2-767; add coach role until mappings.json does
			roles: Helper.superMap(this.MAPPINGS_JSON.roles.role_key, {type:'roles'}),
			// BT-20 BTC-219
			nonStudentStatuses: {None:null, Demo:'demo', Duplicate:'duplicate'},
			weekDays: {monday:'Monday', tuesday:'Tuesday', wednesday:'Wednesday', thursday:'Thursday', friday:'Friday', saturday:'Saturday', sunday:'Sunday'},
		};

		this._$createCohort = true;
		this._$mobileRegex = /^(\+\d{1,3})?(\d{10})\b/;

		super.init();
	}
	_loadDependencies(){
		return this.$q.all([
			this.apiMap.getColleges().then((data)=>this.mapping.mycolleges = data,()=>{}),
			this.apiMap.getCohortCoaches().then(data=>this.mapping.coachCohorts = this.data ? data.filter(cohort=>+cohort.owner_user_id == +this.data._id) : []),
			this.apiMap.getCohortCorpsCoaches().then(data=>this.mapping.corpsCoachCohorts = this.data ? data.filter(cohort=>+cohort.owner_user_id == +this.data._id) : []),
		])
			.then(()=>{
				this.model = this.createModel(angular.extend({},
					{ // default values
						status: 'active',
						roles: {},
						user_profile: {
							available_hours: {},
							timezone: 'US/Pacific',
						},
						salesforce: {},
					}, 
					this.data
				));

			})
			.then(()=>super._loadDependencies());
	}


	createModel(data){
		let model = Helper.deepCopy(data);

		if ( (model.roles.coach || model.roles.coaching_corps_coach) && model.user_profile.available_hours ) {
			Object.values(model.user_profile.available_hours).forEach(list=>{
				list.forEach((item, k)=>list[k] = [item.start, item.end]);
			});
		}
		if ( Object.keys(model.roles).find(role=>LOCK_ROLES.includes(role)) ) {
			this.isLocked = true;
			this.lockReason = 'This user is locked for editing';
		} else {
			// remove roles from UI when not locked
			LOCK_ROLES.forEach(key=>{
				let p = this.mapping.roles.indexOf(this.mapping.roles.byId[key]);
				if ( p > -1 ) this.mapping.roles.splice(p, 1);
			});
		}


		if ( model.chat ) {
			model.chatname = model.chat.username;
		}
		if ( model.user_profile.bio?.content?.en_US ) {
			model.user_profile.bio = model.user_profile.bio.content.en_US;
		}

		if ( model.user_profile.mobile_number ) {
			let m = model.user_profile.mobile_number.match(this._$mobileRegex);
			if ( m && m[1] == '+1' ) // remove +1 country code
				model.user_profile.mobile_number = m[2];
		}

		// convert into array of role objects
		this._roles = model.roles = Object.keys(data.roles).map(id=>this.mapping.roles.byId[id]);

		if ( data._id ) {
			let missing = [];
			if ( data.roles.student )
				model._colleges_student = Object.keys(data.roles.student.colleges || {})
					.map(id=>this.mapping.mycolleges.byId[id] || missing.push(id) && null)
					.filter(v=>!!v);
			if ( data.roles.college_admin )
				model._colleges_college_admin = Object.keys(data.roles.college_admin.colleges || {})
					.map(id=>this.mapping.mycolleges.byId[id] || missing.push(id) && null)
					.filter(v=>!!v);
			
			if ( missing.length > 0 ) {
				let diag = this.$mdDialog.alert()
					.title('Has Deleted College')
					.htmlContent('The following colleges has been deleted & will no longer be associated with the user after saving.<br><br>'+ missing.map(id=>'&bull; college#'+id).join('<br>'))
					.ok('Ok');
				setTimeout(()=>this.$mdDialog.show(diag), 0);
			}
		}

		return model;
	}


	submit($event, form){
		this.$q.when(this._validateForm(form))
			.then(()=>this._checkCoachChatname($event, form))
			.then(()=>{
				let payload = this._preparePayload();
				this.isBusy = true;
				if ( this.data ) {
					return this.api.put('users/'+ this.data._id, payload, {level: ApiError.LEVEL.MANUAL})
						.then(res=>{
							this.toast.success('User Updated');
							return res;
						});
				} else {
					return this.api.post('users', payload, {level: ApiError.LEVEL.MANUAL})
						.then(res=>this.__createCohorts(res.data._id, payload)
							.then(()=>{
								this.toast.success('New User Saved');
								return res;
							})
						);
				}
			})
			.then(res=>{
				this.promptExit.disable();
				this.$state.go('^', this.session.get('users') || {});
			}, err=>{
				if ( ! this.api.isApiError(err) ) throw err;

				if ( err?.response?.data?.error_code && MESSAGES?.USERS?.CODE?.[err.response.data.error_code] )
					switch( +err.response.data.error_code ) {
						case 102: 
							form.email.$setValidity('duplicateEmail', false);
							Helper.focusFormError(form);
							return;
						case 106:
							form.email.$setValidity('invalidEmail', false);
							Helper.focusFormError(form);
							return;
						case 423:
							form.salesforce_id.$setValidity('duplicate', false);
							this.toast.warn(err.response.data.error_message);
							Helper.focusFormError(form);
							return;
					}
				this.errorPrompt.show(err, $event);
			})
			.finally(()=>this.isBusy = false);
	}

	__createCohorts(id, payload){
		let promises = [];
		if ( this._$createCoachCohort && payload.roles.coach ) {
			promises.push(
				this.api.post('cohorts', {
						// name: ['beyond12_coach', id].join('_'),
						description: ['B12 Coach', id, payload.user_profile.first_name, payload.user_profile.last_name].join(' '),
						cohort_type: 'beyond12_coaches',
						owner_user_id: id,
					}, {level: ApiError.LEVEL.MANUAL})
						.catch(err=>{
							err.message = MESSAGES.USERS.NEW_COHORT_FAIL;
							this.toast.error(err);
						})
			);
		}
		if ( this._$createCorpsCohort && payload.roles.coaching_corps_coach ) {
			promises.push(
				this.api.post('cohorts', {
						// name: ['beyond12_coaching_corps_coach', id].join('_'),
						description: ['B12 Coaching Corps Coach', id, payload.user_profile.first_name, payload.user_profile.last_name].join(' '),
						cohort_type: 'beyond12_coaching_corps_coaches',
						owner_user_id: id,
					}, {level: ApiError.LEVEL.MANUAL})
						.catch(err=>{
							err.message = MESSAGES.USERS.NEW_COHORT_FAIL;
							this.toast.error(err);
						})
			);
		}

		if ( promises.length > 0 ) {
			return this.$q.all(promises);
		} else {
			return this.$q.resolve();
		}
	}


	_checkCoachChatname($evt, form){
		if ( (!this.data || !this.data.chat) && !this.model.chatname && (this.model.roles.includes(this.mapping.roles.byId.coach) || this.model.roles.includes(this.mapping.roles.byId.coaching_corps_coach)) ) {			
			let $el = form.chat.$$element;

			return this.$mdDialog.show(
				this.$mdDialog.confirm()
						.title('Warning')
						.textContent('If you do not specify a chat display name, the coach cannot use chat. Continue?')
						.ariaLabel('Warning')
						.targetEvent($evt)
						.ok('Ok')
						.cancel('Cancel')
			)
			.catch(e=>{
				$el[0].focus();
				try { $el[0].scrollIntoView && $el[0].scrollIntoView({behavior:'smooth', block:'center'});
				} catch(e){}
				throw e;
			})
		}
		return true;
	}

	_preparePayload(){
		let payload = Helper.deepCopy(this.data || {});
		let model = this.model;

		payload.status = model.status;
		
		payload.user_profile = payload.user_profile || {};
		payload.user_profile.first_name = model.user_profile.first_name;
		payload.user_profile.last_name = model.user_profile.last_name;
		payload.user_profile.photo_url = model.user_profile.photo_url || undefined;
		payload.user_profile.photo_alt_text = model.user_profile.photo_alt_text || undefined;
		payload.email = model.email;
		payload.username = model.username;
		
		payload.user_profile.mobile_number = (model.user_profile.mobile_number || '').trim() || undefined;
		if ( payload.user_profile.mobile_number ) {
			let m = payload.user_profile.mobile_number.match(this._$mobileRegex);
			if ( m ) {
				if ( ! m[1] ) // no country code
					m[1] = '+1';
				payload.user_profile.mobile_number = m[1] + m[2];
			}
		}
		if ( model.password )
			payload.transient = {plain_password: model.password};
		
		payload.roles = {};

		(model.roles || []).forEach((role)=>{
			let key = role._id;
			payload.roles[key] = Helper.deepCopy(this.data && this.data.roles && this.data.roles[key] || {});
			let oldColleges = payload.roles[key].colleges;
			let colleges = (model['_colleges_'+ key] || []).filter(v=>!!v);

			if ( colleges.length > 0 ) {
				let map = payload.roles[key].colleges = {};
				colleges.forEach(college=>map[college._id] = oldColleges && oldColleges[college._id] || {});
			}
		});

		// BT-20 BTC-219
		if ( payload.roles.student ) {
			payload.non_student_status = model.non_student_status || undefined;
		} else {
			payload.non_student_status = undefined;
		}

		if ( payload.roles.coach || payload.roles.coaching_corps_coach ) {
			payload.salesforce = payload.roles.coach && model.salesforce?.id ? {id: model.salesforce.id} : undefined;
			payload.user_profile.timezone = model.user_profile.timezone;
			payload.user_profile.icon_url = model.user_profile.icon_url || undefined;
			payload.user_profile.icon_alt_text = model.user_profile.icon_alt_text || undefined;
			payload.user_profile.calendly_link = model.user_profile.calendly_link || undefined;
			payload.user_profile.facebook_url = model.user_profile.facebook_url || undefined;
			payload.user_profile.instagram_url = model.user_profile.instagram_url || undefined;
			payload.user_profile.twitter_url = model.user_profile.twitter_url || undefined;


			payload.user_profile.available_hours = {};
			let hrs = Helper.deepCopy(model.user_profile.available_hours || {});
			Object.keys(hrs).forEach(day=>{
				let li = (hrs[day] || []).map(pair=>{
					if ( pair && pair.length ) {
						if ( pair[1] == '00:00' )
							pair[1] = '24:00';
						return {start:pair[0], end:pair[1]};
					}
				}).filter(v=>!!v);
				if ( li.length > 0 )
					payload.user_profile.available_hours[day] = li;
			});
		} else {
			payload.amazon = undefined;
			payload.salesforce = undefined;
			payload.user_profile.timezone = payload.user_profile.available_hours = undefined;
			payload.user_profile.calendly_link = payload.user_profile.instagram_url = payload.user_profile.facebook_url = payload.user_profile.twitter_url = undefined;
		}
		if ( ! payload.chat ) {
			model.chatname = (model.chatname || '').trim();
			if ( model.chatname )
				payload.chat = {username: model.chatname.toLowerCase()};
		}
		if ( model.user_profile.bio ) {
			payload.user_profile.bio = {
				content: {en_US: model.user_profile.bio},
				type: 'text/plain',
			};
		} else {
			payload.user_profile.bio = undefined;
		}

		// set ancestry if unset
		payload.ancestry = payload.ancestry || SETTINGS.apiAncestry;

		return payload;
	}

	delete($ev){
		if ( this.isLocked ) return;

		this.$mdDialog.show(
			this.$mdDialog.confirm()
          .title('Are you sure you want to delete this user?')
          .ariaLabel('confirm delete')
          .targetEvent($ev)
          .ok('Delete')
          .cancel('Cancel')
		).then(()=>{
			this.isBusy = true;
			return this.api.delete('users/'+ this.data._id)
				.finally(()=>this.isBusy = false);
		})
		.then(()=>{
			this.promptExit.disable();
			this.toast.success(`Deleted User #${this.data._id}`);
			this.$state.go('^', this.session.get('users') || {});
		});
	}

	change(val){
		console.log(val, this);
	}

	checkToggled(day, $value){
		this.model.user_profile.available_hours = this.model.user_profile.available_hours || {};

		if ( $value ) {
			this.model.user_profile.available_hours[day] = this.model.user_profile.available_hours[day] || [['10:00:00', '18:00:00']];
		} else {
			delete this.model.user_profile.available_hours[day];
		}

	}


	verifyChatname(value){
		return this.api.get('users/chatNames/check', {chatName: value.toLowerCase()}, {level:ApiError.IGNORED})
			.then(res=>res.data.taken ? this.$q.reject('taken') : true );
	}
	cleanMobileNumber($model){
		let value = String($model.$viewValue || '');
		if ( /[\(\)\-\s]/.test(value) )
			this.$timeout(()=>{
				$model.$setViewValue(value.replace(/[\(\)\-\s]/g, ''));
				$model.$render();
			}, 500);
	}

	changeRoles(){
		let roles = this.model.roles || [];
		let coach = this.mapping.roles.byId['coach'];
		let coachCorp = this.mapping.roles.byId['coaching_corps_coach'];

		if ( roles.includes(coach) && roles.includes(coachCorp) ) {
			if ( roles.indexOf(coach) > roles.indexOf(coachCorp) )
				roles.splice(roles.indexOf(coachCorp), 1);
			else
				roles.splice(roles.indexOf(coach), 1);
		}
	}

	hasRole(id){
		return this.model?.roles && this.model.roles.includes(this.mapping?.roles?.byId?.[id]);
	}

}
