<header class="md-whiteframe-2dp flex-none" layout="column" layout-gt-lg="row" layout-align-gt-lg="space-between start">
	<h2 class="md-headline">
		Questions Report {{ ctrl.selected ? (' - '+ ctrl.selected.name) : '' }}
	</h2>
	
	<div layout layout-align="end start" ng-show="ctrl.selected">
		<i flex></i>
		<md-input-container class="md-input-has-value" ng-show="ctrl.mapping.mycolleges.length > 1">
			<label>College</label>
			<md-select name="college" ng-model="ctrl.selected" ng-change="ctrl.refresh()" ng-disabled="ctrl.isBusy" aria-label="college">
				<md-option ng-repeat="item in ctrl.mapping.mycolleges" ng-value="item">{{item.name}}</md-option>
			</md-select>
		</md-input-container>
	</div>
</header>

<form id="report-questions" name="form" ng-submit="form.$valid && ctrl.submit()" flex-gt-sm layout="column" form-disabled="ctrl.isBusy">

	<main class="flex layout-column layout-align-center-center" ng-hide="ctrl.selected">
		<em class="black-50 md-body-1">Select a college to show it's questions report</em>
		<md-input-container md-no-float="true">
			<label>Select a College</label>
			<md-select name="college" ng-model="ctrl.selected" ng-change="ctrl.refresh()" ng-disabled="ctrl.isBusy" aria-label="college">
				<md-option ng-repeat="item in ctrl.mapping.mycolleges" ng-value="item">{{item.name}}</md-option>
			</md-select>
		</md-input-container>
	</main>

	<main class="flex can-scroll-y" ng-show="ctrl.selected && ctrl.chartCount > 0">
		<div class="panel" md-whiteframe="1" ng-repeat="(key, data) in ctrl.results track by $index">
			<div class="panel-content" ng-show="data && data.totalAnswers > 0">
				<md-tabs md-dynamic-height="true">
					<md-tab label="Chart View" layout="column" layout-align="stretch start">
						<div class="chart-container md-padding" flex layout layout-align="center center">
							<div class="the-chart" id="{{ key }}" role="img" aria-label="answers chart"></div>
						</div>
					</md-tab>
					<md-tab label="Table View">
						<div class="md-padding-2 text-center">
							<table class="table data-table">
								<tr class="black-33">
									<th class="text-left">Answer</th>
									<th class="text-right">Count</th>
								</tr>
								<tr ng-repeat="(answer, count) in data.results">
									<td class="text-left">{{ answer }}</td>
									<td class="text-right">{{ count }}</td>
								</tr>
							</table>
						</div>
					</md-tab>
				</md-tabs>
			</div>
			<div class="panel-content layout-row layout-align-center-center" ng-show="data && data.totalAnswers == 0">
				<span class="margin-4x padding-4x">
					<h3 class="md-subhead">{{ data.question }}</h3>
					<em class="flex-none black-50 text-center">No answers yet</em>
				</span>
			</div>
			<div class="padding-4x layout-row layout-align-center-center" ng-show="!data">
				<md-progress-circular md-mode="indeterminate"></md-progress-circular>
			</div>
		</div>
	</main>

	<main class="flex layout layout-align-center-center" md-whiteframe="1" ng-show="ctrl.chartCount === 0">
		<em class="flex-none black-50 text-center">No results available for<br>{{ctrl.selected.name}}</em>
	</main>

	<div class="busy-overlay fade" layout layout-align="center center" ng-show="ctrl.isBusy">
		<md-progress-circular md-mode="indeterminate"></md-progress-circular>
	</div>
</form>
