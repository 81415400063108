import angular from 'angular'
import {Helper, ApiError} from '../../common'
import ReportMonthlyController from '../../main/analytics/report.monthly.controller'


export default class MonthlyReportController extends ReportMonthlyController {
	static get $inject(){return ReportMonthlyController.$inject.concat([
		'$scope',
		'$state',
		'toast',
		'api',
	])}

	init(){
		console.log('$state.params', this.$state.params);

		this.mapping = {
			status: Helper.superMap(this.MAPPINGS_JSON.users.status_key, {type:'status'}),
			roles: Helper.superMap(this.MAPPINGS_JSON.roles.role_key, {type:'roles'}),
		};

		this.api.get('colleges/'+ this.$state.params.id)
			.then((res)=>{
				this.selected = res.data;
				this.name = res.data.name;
				this.logo = res.data.logo_url;
				// this.selected = {_id: this.$state.params.id};
				// this.name = this.$state.params.name.split('-').map(word=>word[0].toUpperCase()+word.substr(1)).join(' ');
				this.initTables();

				return this.refresh()
			})
			.catch(err=>{
				if ( err && err.message ) {
					if ( ! err.response || ! /^4\d\d$/.test(err.response.status) )
						this.toast.error(err);
					this.$state.go('error.404', err);
				}
			});
		this.isBusy = true;
	}

	getConsoleLink(){
		return this.$state.href('app.analytics.monthlyReport', {college: this.$state.params.id}, {absolute:true});
	}

}


