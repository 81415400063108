Title,Become a Monarch!,,,
Description,,,,
Formatted Description,<p>Enrolling at LAVC is your first step of your college career!</p><p>Follow these 3 steps to become an official Monarch!</p>,,,
Description Content Type,html,,,
College Owner,166,,,
Recipients,cohort=level_continuing OR cohort=level_new,,,
Release Date,2023-07-01,,,
Deadline Type,soft,,,
Deadline,2023-10-01,,,
Completion Headline,Good job!,,,
Completion Subtitle,Congratulations on completing the first milestone for becoming an LAVC Student!,,,
Completion Image URL,https://assets.stickpng.com/images/580b57fbd9996e24bc43bbdf.png,,,
Completion Image Alt Text,Puppy,,,
Video URL,https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4,,,
Video with Narration URL,,,,
Video Thumbnail URL,https://www.designfreelogoonline.com/wp-content/uploads/2018/04/000970-3D-earth-globe-logo-maker-04.png,,,
Video Thumbnail Alt Text,Image of a Globe,,,
Image URL,https://www.designfreelogoonline.com/wp-content/uploads/2018/04/000970-3D-earth-globe-logo-maker-04.png,,,
Image Alt Text,Image of a Globe,,,
Background Color,#504C48,,,
Tags,,,,
Atomic,FALSE,,,
Points,10,,,
,,,,
STEPS,1,2,3,
STEP TITLE,Fill out the application,Meet with a counselor,Register for classes,
Description,Submit your application to LAVC and hear back from them shortly about your student ID and general student information.,Visit your counselor to go over classes of interest to you. Then create an education plan with your counselor to ensure you're on track to your goals.,Search through courses available in the upcoming semester with your education plan in mind and register for these classes.,
Formatted Description,,,,
Description Content Type,text,text,text,
Deadline Type,soft,soft,soft,
Deadline,2023-09-01,2023-09-15,2023-09-30,
Validation,none,question,date selection,
Validation Instructions,,,When did you register?,
Question,,"From the online orientation video, what is the most important lesson you learned?",,
Answers,,"don't skip class!
don't miss your deadlines!
all of the above!",,
Correct Answers,,"false
false
true",,
Links,,"Complete your Online Orientation Now
http://www.norcocollege.edu/services/admissions/pages/orientation.aspx
google
https://google.com
facebook
https://facebook.com",,
Completion Headline,Congratulations!,Congratulations!,Congratulations!,
Completion Subtitle,Good work!,Keep going!, You did it!,
Completion Image URL,,,https://assets.stickpng.com/images/580b57fbd9996e24bc43bbdf.png,
Completion Image Alt Text,,,Image of a puppy,
Points,5,10,15,
,,,,
NOTIFICATIONS,1,2,3,
Text,Enroll at LAVC,Meet with a counselor,Register for classes,
Date to Send,2023-06-27,2023-07-21,2023-07-27,