import EventsListController from './events.list.controller'
import EventSingleController from './event.single.controller'
import {Helper, PERMISSIONS, PERMISSION_LEVELS} from '../../common'
import '../../common/styles/gg-fonts.css'
import './events.scss'


export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {

	const $stateRegistry = $uiRouterProvider.stateRegistry;

	$stateRegistry.register({
		name: 'app.events',
		title: 'Events',
		url: 'events?searchString&sortBy&sortOrder&dateFrom&dateTo&status&types&categories&colleges&districts&cohorts&view&term',
		access: {[PERMISSIONS.READ_EVENTS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT]},
		views: {
			'content@app': {
				template: require('./events.list.html'),
				controller: EventsListController,
				controllerAs: 'ctrl',
			},
		},
		dynamic:true,
		params: {
			nonce: 0,
			scroll:       {value: 0, inherit:false, dynamic:true},
			searchString: {type:'query', inherit:false},
			sortBy:       {type:'query', inherit:false},
			sortOrder:    {type:'query', inherit:false},
			dateFrom:     {type:'query', inherit:false},
			dateTo:       {type:'query', inherit:false},
			status:       {type:'query', inherit:false},
			categories:   {type:'query', inherit:false, array:true},
			colleges:     {type:'query', inherit:false, array:true},
			districts:    {type:'query', inherit:false, array:true},
			terms:    		{type:'query', inherit:false, array:true},
			cohorts:      {type:'query', inherit:false, array:true},
			view:	 				{type:'query', inherit:false},
		},
	});


	$stateRegistry.register({
		name: 'app.events.calendarDetail',
		title: ['data', data=>'Events Calendar #'+ data._id],
		url: '?id',
		access: {[PERMISSIONS.READ_EVENTS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT]},
		views: {
			'detail@app.events': {
				template: require('./events.calendar.detail.html'),
				controller: EventSingleController,
				controllerAs: 'ctrl',
			},
		},
		dynamic:true,
		params: {
			id: {type:'query', inherit:false},
		},
		resolve: [{
				provide: 'clone',
				useFactory: ()=>null,
			}, {
				provide: 'data',
				deps: ['api', '$state', '$stateParams'],
				useFactory: (api, $state, $stateParams)=>$stateParams.id && api.get('events/'+ $stateParams.id)
					.then((res)=>res.data)
					.catch((err)=>{
						if ( api.isApiError(err) ) {
							let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
							return $state.go(state, {error: err}, {location:false});
						}
						throw err;
					}),
			}, {
				provide: 'parentStateParams',
				useFactory: ()=>null,
			}
		],
	});

	let eventSingleHtml = require('./event.single.html');

	$stateRegistry.register({
		name: 'app.events.add',
		title: 'Add Event',
		url: '/add?clone',
		access: {[PERMISSIONS.CREATE_EVENTS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT]},
		views: {
			'content@app': {
				template: eventSingleHtml,
				controller: EventSingleController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'data',
				useFactory: ()=>null,
			}, {
				provide: 'clone',
				deps: ['api', '$state', '$stateParams'],
				useFactory: (api, $state, $stateParams)=>$stateParams.clone && api.get('events/'+ $stateParams.clone)
					.then(res=>Helper.deepCopy(res.data))
					.catch(err=>{
						if ( api.isApiError(err) ) {
							let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
							return $state.go(state, {error: err}, {location:false});
						}
						throw err;
					}),
			}, {
				provide: 'parentStateParams',
				useFactory: ()=>({}),
		}],
		params: {
			clone: {type:'query', inherit:false},
		},
	});

	$stateRegistry.register({
		name: 'app.events.edit',
		title: ['data', data=>'Event #'+ data._id],
		url: '/{id:int}',
		access: {[PERMISSIONS.UPDATE_EVENTS]: [PERMISSION_LEVELS.ALL, PERMISSION_LEVELS.MYCOLLEGE, PERMISSION_LEVELS.MYDISTRICT]},
		views: {
			'content@app': {
				template: eventSingleHtml,
				controller: EventSingleController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'data',
				deps: ['api', '$state', '$stateParams'],
				useFactory: (api, $state, $stateParams)=>api.get('events/'+ $stateParams.id)
					.then((res)=>res.data)
					.catch((err)=>{
						if ( api.isApiError(err) ) {
							let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
							return $state.go(state, {error: err}, {location:false});
						}
						throw err;
					}),
				}, {
					provide: 'clone',
					useFactory: ()=>null,
				}, {
					provide: 'parentStateParams',
					useFactory: ()=>({}),
		}],
	});


	$stateRegistry.register({
		name: 'app.notifications',
		redirectTo: (trans)=>({state:'app.events', params:trans.params()}),
		url: 'notifications?searchString&sortBy&sortOrder&dateFrom&dateTo&status&types&categories&colleges&districts&cohorts&view&term',
	});
}];
