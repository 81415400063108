<header id="milestones-list-header" class="md-whiteframe-2dp flex-none flex-order--5">
	<form name="headerForm" novalidate ng-model-options="{updateOn:'default blur', debounce:{'default':500, 'blur':0}}">
		<div class="layout-column layout-gt-sm-row layout-align-gt-sm-space-between-stretch layout-wrap">
			<h2 id="pageTitle" class="md-headline">
				Workspaces
				<span ng-show="ctrl.mergeMode">— Merge</span>
			</h2>
			
			<div class="layout layout-align-end-start max-width-100">
				<md-input-container class="md-input-has-placeholder max-width-100" 
					ng-model-options="{updateOn:'default blur', debounce:{'default':250, 'blur':0}}">
					<label>Status</label>
					<md-select name="status" multiple
						ng-model="ctrl.filters._status" 
						ng-change="ctrl.onFiltersChange()"
						md-on-close="headerForm.status.$dirty && ctrl.onFiltersChange()">
						<md-option ng-repeat="item in ctrl.mapping.fileStatus" ng-value="item._id">{{ item.name }}</md-option>
					</md-select>
				</md-input-container>
			</div>
		</div>
	</form>
</header>

<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_ERROR -->
<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

<footer class="md-whiteframe-2dp layout-align-start-center layout-row layout-wrap">
	<md-button class="md-icon-button md-mini md-primary" 
		aria-label="Refresh results" title="Refresh results"
		ng-click="ctrl.refresh(true)">
		<span class="fas fa-sync-alt" aria-hidden="true"></span>
	</md-button>
	<div id="footerResults" class="md-body-1" ng-hide="ctrl.mergeMode">
		{{ ctrl.query.total }} Results
	</div>
	<span flex></span>

	<div class="flex-nogrow" ng-show="!ctrl.mergeMode">
		<md-button class="md-primary" 
			aria-label="add new workspace"
			ui-sref=".add"
			ng-if=":: mainCtrl.hasPermission(PERMISSIONS.CREATE_WORKSPACE)">
			<span class="fas fa-plus"></span> 
			<span class="hide-xs">Add</span> New
		</md-button>
		<md-button class="md-primary"
			ng-click="ctrl.enterMergeMode()" 
			ng-if="::ctrl.isMergeAllowed">
			<span class="fas fa-object-group"></span>
			Merge <span class="hide-xs">Batches</span>
		</md-button>
		<!-- <md-button class="md-accent" ng-click="ctrl.selected ? ctrl.export(ctrl.selected, $event) : ctrl.exportCSV()">
			<i class="fas fa-file-export"></i> Export <span class="hide-xs">CSV</span>
		</md-button> -->
	</div>
	<div class="flex-nogrow" ng-show="ctrl.mergeMode">
		<md-button class="md-primary" 
			aria-label="cancel merge mode"
			ng-click="ctrl.exitMergeMode()">Cancel</md-button>
		<md-button class="md-warn md-raised" 
			ng-click="ctrl.startMerge($event)">
			<span class="fas fa-object-group"></span>
			Merge Selected {{ctrl.mergeSelected.length ? '('+ctrl.mergeSelected.length+')' : ''}}
		</md-button>
	</div>
</footer>

<main class="main-list workspaces-list flex flex-order--1 layout-column"
	aria-labelledby="pageTitle footerResults">
	<md-virtual-repeat-container class="data-list flex no-animate" ng-class="{'merging': ctrl.mergeMode}"
		md-top-index="ctrl.itemIndex" 
		ng-if="ctrl.dataList.length > 0">
		<div id="item-{{ item._id }}" class="data-item notification-batch-item no-animate" ng-class="{loading: !item}" 
			md-virtual-repeat="item in ctrl" md-on-demand="true">
			<ng-include 
				role="group" aria-labelledby="workspace_{{item._id}}"
				src="'/workspaces.item.tpl'"></ng-include>
		</div>
	</md-virtual-repeat-container>

	<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_EMPTY_RESULTS -->

	<section id="expanded-overlay" class="main-list notifications-list layout-column" 
		role="group" aria-label="Workspace ID #{{item._id}} showing Events & Tips"
		ng-show="!!ctrl.selected && !ctrl.isBusy" ng-cloak>
		<div class="data-list flex-none md-whiteframe-1dp" 
			ng-if="ctrl.selected" 
			ng-init="item = ctrl.selected">
			<div class="data-item notification-batch-item layout-column">
				<div class="flex layout-column" 
					role="group" aria-labelledby="workspace_{{item._id}}"
					ng-include="'/workspaces.item.tpl'"></div>
			</div>
			<!-- <md-toolbar class="md-info-theme md-accent md-hue-3 md-whiteframe-1dp">
				<div class="md-toolbar-tools layout">
					<h4 class="flex md-title padding-2x normal-wg">Notifications</h4>
				</div>
			</md-toolbar> -->
		</div>
		
		<div class="data-list flex layout-column indent-2x">
			<md-virtual-repeat-container class="data-list flex no-animate" 
				ng-if="ctrl.selected.notifications.length && !ctrl.selected.isBusy">
				<div class="data-item notification-item layout-column no-animate" ng-class="{loading: !item}" 
					md-virtual-repeat="item in ctrl.selected.notifications">
					<div class="flex-100 layout-column" 
						role="group" aria-label="{{item._$type +' ID#'+ item._id}}"
						ng-include="'events.item.tpl'"></div>
				</div>
			</md-virtual-repeat-container>

			<div class="flex layout-row layout-align-center-center no-animate" ng-hide="ctrl.selected.notifications.length>0 || ctrl.selected.isBusy" ng-cloak>
				<p class="text-center alpha-50 md-caption"><em>No results found <span ng-show="ctrl.query.search">for &quot;{{ ctrl.query.search }}&quot;</span></em></p>
			</div>

			<div ng-show="ctrl.selected.isBusy" class="fade-in fade-out flex layout-row layout-align-center-center">
				<md-progress-circular md-mode="indeterminate" md-diameter="40px"></md-progress-circular>
			</div>
		</div>
	</section>
</main>

<script type="text/ng-template" id="/workspace.rename.tpl">
	<md-dialog id="rename-batch" aria-label="Rename Batch" form-disabled="rename.busy">
		<md-dialog-content class="md-dialog-content">
			<h2 class="md-title">Rename Batch</h2>
			<br>
			<form name="form">
				<md-input-container class="md-block md-input-has-placeholder">
					<label>New Name</label>
					<input ng-model="rename.name" name="name" required/>
					<div ng-messages="form.name.$error" role="alert">
						<div ng-message="required">Required</div>
					</div>
				</md-input-container>
			</form>
		</md-dialog-content>
		<md-dialog-actions>
			<md-button ng-click="rename.cancel()" class="md-primary">Cancel</md-button>
			<md-button ng-click="rename.confirm(form)" class="md-primary md-hue-2">
				<span ng-hide="rename.busy">Rename</span>
				<center ng-if="rename.busy"><md-progress-circular md-mode="indeterminate" md-diameter="20px"></md-progress-circular></center>
			</md-button>
		</md-dialog-actions>
	</md-dialog>
</script>



<script type="text/ng-template" id="/workspaces.item.tpl">
		<div class="item-wrap flex layout-row layout-wrap layout-align-center-center layout-align-gt-sm-start-stretch" 
			ng-class="{'selected': item._selected}" 
			style="height:100%; padding-bottom:1px" 
			ng-click="ctrl.mergeMode && ctrl.selectForMerge(item, !item._selected)" 
			form-disabled="ctrl.mergeMode && !ctrl.canMerge(item)"
			role="{{ ctrl.mergeMode ? 'checkbox' : 'group' }}"
			aria-checked="{{ item._selected }}"
			aria-labelledby="workspace_{{item._id}}"
			tabindex="{{ ctrl.mergeMode && ctrl.canMerge(item) ? '0' : '-1' }}"
		>
			<div class="item-checkbox flex-none padding-4x" ng-show="ctrl.mergeMode">
				<md-checkbox class="md-primary" aria-label="select batch"
					ng-model="item._selected" 
					ng-change="ctrl.selectForMerge(item)" 
					ng-click="$event.stopPropagation()" tabindex="-1"></md-checkbox>
			</div>
			<div class="item-meta flex-xs flex-sm flex-gt-sm-none layout-row layout-align-space-between-start layout-gt-sm-column layout-align-gt-sm-space-between-stretch layout-wrap padding-4x md-truncate">
				<b class="md-headline margin-0x">
					{{ item.record_count }}
					<i class="md-caption">records</i>
				</b>
				<div class="status-wrap">
					<small class="small-tag" ng-class="item.status">{{ ctrl.mapping.fileStatus.byId[item.status].name || item.status }}</small>
				</div>
				<div class="user flex flex-gt-sm-none md-caption item-owner layout-column">
					<span class="no-wrap md-truncate">
						<i class="black-25">by</i>
						<span title="{{ ctrl.mapping.users.byId[item.created_by_user_id].user_profile.first_name || '#'+item.created_by_user_id }}">
							{{ ctrl.mapping.users.byId[item.created_by_user_id].user_profile.first_name || '#'+item.created_by_user_id }}
						</span>
					</span>
					<span>{{ item.processed_date | amDateFormat:SETTINGS.dateTimeFormat }}</span>
				</div>
			</div>
			<!-- <div class="flex-100 hide-gt-sm" style="height:1px"></div> -->
			<div class="item-details flex-xs-100 flex-sm-100 flex-gt-sm layout-column layout-align-start-stretch padding-4x">
				<h3 class="md-subhead flex-nogrow margin-0x md-truncate" id="workspace_{{item._id}}">{{ item.file_name }}</h3>
				<!-- <div class="flex layout-column layout-align-space-between-stretch layout-gt-md-row"> -->
					<div class="flex-nogrow layout-row layout-wrap">
						<div class="flex-100 flex-gt-sm md-body-1 black-75" ng-hide="!item.approvalStatus || item.status=='content_moved' || item.status=='deleted'">
							<span class="no-wrap" ng-show="item.approvalStatus.latest_approval_date">Approvals Until {{ item.approvalStatus.latest_approval_date | amDateFormat:SETTINGS.dateFormat }}<br></span>
							<span class="no-wrap">Approved: {{ item.approvalStatus.num_approved_perc }}% <i class="black-50">({{ item.approvalStatus.num_approved }})</i> &nbsp;</span>
							<span class="no-wrap">Hold: {{ item.approvalStatus.num_held_perc }}% <i class="black-50">({{ item.approvalStatus.num_held }})</i> &nbsp;</span>
							<span class="no-wrap">Unreviewed: {{ item.approvalStatus.num_unapproved_perc }}% <i class="black-50">({{ item.approvalStatus.num_unapproved }})</i></span>
						</div>
					</div>
					
					<i flex></i>
					<div class="flex-nogrow ctrl-group text-right layout-gt-md-row layout-align-gt-md-end-end" ng-hide="ctrl.mergeMode">
						<md-button class="data-action flex-none compact delete" 
							ng-show="!['deleted','content_moved'].includes(item.status)"
							ng-click="ctrl.delete(item, $event)" 
							aria-label="Delete {{item.file_name}}">
							<span class="far fa-trash-alt"></span>
							Delete
						</md-button>
						<md-button class="data-action flex-nogrow compact"
							ng-show="['published','unpublished','partially_published'].includes(item.status)"
							ng-click="ctrl.rename(item, $event)" 
							aria-label="Rename {{item.file_name}}">
							<span class="far fa-keyboard"></span>
							Rename
						</md-button>
						<md-button class="data-action flex-none compact md-primary" 
							ng-show="ctrl.authorization.hasStateAccess('app.workspaces.edit.preview') && ['unpublished','partially_published'].includes(item.status)" 
							ui-sref="app.workspaces.edit.preview({id: item._id})"
							aria-label="Publish {{item.file_name}}">
							<span class="far fa-paper-plane"></span>
							Publish
						</md-button>
						<md-button class="data-action flex-none compact" 
							ng-show="['unpublished','partially_published'].includes(item.status)" 
							ui-sref="app.workspaces.edit.calendar({id: item._id})"
							aria-label="Open {{item.file_name}} in calendar">
							<i class="far fa-calendar-alt"></i>
							Calendar
						</md-button>
						<md-button class="data-action flex-none compact md-accent" 
							ng-show="!['deleted','content_moved'].includes(item.status)"
							ng-click="ctrl.export(item, $event)" 
							aria-label="Export {{item.file_name}}">
							<i class="fas fa-file-export export-workspace-btn"></i>
							Export</span>
						</md-button>
						<md-button class="data-action flex-none compact"
							ng-show="!['deleted','content_moved'].includes(item.status) && item.record_count"
							ng-click="item===ctrl.selected ? ctrl.close() : ctrl.open(item)" 
							aria-label="{{item===ctrl.selected ? 'Expand' : 'Collapse'}} {{item.file_name}}">
							{{ item===ctrl.selected ? 'Hide' : 'View' }}
							&nbsp;
							<span class="fas" ng-class="item===ctrl.selected ? 'fa-times-circle' : 'fa-angle-down'"></span>
						</md-button>
					</div>

				<!-- </div> -->
			</div>
		</div>
</script>

<script type="text/ng-template" id="/notifications.batch.merge.tpl">
	<md-dialog id="merge-options" aria-label="Merge Options" form-disabled="merge.busy">
		<md-dialog-content class="md-dialog-content">
			<h2 class="md-title">Merge Options - {{ merge.selected.length }} Batches ({{ merge.total }} Records)</h2>
			<br>
			<form name="form">
				<md-input-container class="md-block md-input-has-placeholder">
					<label>Target Batch</label>
					<md-select ng-model="merge.target" name="target" required>
						<md-option ng-repeat="item in merge.selected track by $index" ng-value="item">{{ item.file_name }}</md-option>
					</md-select>
					<div class="md-errors-spacer"></div>
					<div ng-messages="form.target.$error" role="alert">
						<div ng-message="required">Required</div>
					</div>
				</md-input-container>
				<md-input-container class="md-block md-input-has-placeholder">
					<label>New Batch Name</label>
					<!-- <input ng-model="merge.name" name="name" required mg-autofocus /> -->
					<textarea ng-model="merge.name" name="name" required></textarea>
					<div ng-messages="form.name.$error" role="alert">
						<div ng-message="required">Required</div>
					</div>
				</md-input-container>
				<md-input-container class="md-block md-input-has-placeholder" form-disabled="merge.colleges.length == 1">
					<label>Target College</label>
					<md-select ng-model="merge.college" name="college" required>
						<md-option ng-repeat="item in merge.colleges track by $index" ng-value="item">{{ item.name }}</md-option>
					</md-select>
					<div class="md-errors-spacer"></div>
					<div ng-messages="form.college.$error" role="alert">
						<div ng-message="required">Required</div>
					</div>
				</md-input-container>
			</form>
		</md-dialog-content>
		<md-dialog-actions>
			<md-button ng-click="merge.cancel()" class="md-primary">Cancel</md-button>
			<md-button ng-click="merge.confirm(form)" class="md-primary md-hue-2">
				<span ng-hide="merge.busy">Merge</span>
				<center ng-if="merge.busy"><md-progress-circular md-mode="indeterminate" md-diameter="20px"></md-progress-circular></center>
			</md-button>
			
		</md-dialog-actions>
	</md-dialog>
</script>

