import angular from 'angular'
import Highcharts from 'highcharts'

require('highcharts/highcharts-3d')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);


export function pieChart(id, data, options){
	options = angular.merge({
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie',
		},
		title: {text: ''},
		tooltip: {pointFormat: '<b>{point.percentage:.1f}% ({point.y:.0f})</b>'},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				depth: 35,
				dataLabels: {enabled: false},
				showInLegend: true
			}
		},
		legend: {
			align: 'right',
			verticalAlign: 'top',
			layout: 'vertical',
			x: 0,
			y: 30,
			itemMarginTop: 5,
			itemMarginBottom: 5,
		},
		series: [{
			type: 'pie',
			name: 'a',
			data: data
		}]
	}, options||{});

	return Highcharts.chart(id, options);
};
export default pieChart;

export function areaTimeChart(id, data, options){
	options = angular.merge({
		chart: {zoomType: 'x'},
		title: {text: ''},
		/*subtitle: {
			text: document.ontouchstart === undefined ?
								'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
		},*/
		xAxis: {type: 'datetime'},
		yAxis: {title: {text: ''}, tickInterval:1},
		legend: {enabled: false},
		tooltip: {pointFormat: '{point.y}'},
		plotOptions: {
			area: {
				marker: {
					radius: 2
				},
				lineWidth: 1,
				states: {
					hover: {
						lineWidth: 1
					}
				},
				threshold: 0
			}
		},
		series: [{
			type: 'area',
			name: '',
			data: data
		}]
	}, options||{});
	return Highcharts.chart(id, options);
};

export function barChart(id, data, categories, options){
	options = angular.merge({
		chart: {type: 'bar'},
		title: {text: ''},
		xAxis: {
			categories: categories,
			title: {text: null}
		},
		yAxis: {title: {text: ''}, tickInterval:null},
		tooltip: {pointFormat: '{point.y}'},
		plotOptions: {
			bar: {
				dataLabels: {
					enabled: true
				}
			}
		},
		legend: {enabled: false},
		series: [{
				name: '',
				data: data
		}]
	}, options||{});
	return Highcharts.chart(id, options);
};


Highcharts.theme = {
	colors: ['#7cb5ec', '#f7a35c', '#90ee7e', '#7798BF', '#aaeeee', '#ff0066',
		'#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
	chart: {
		backgroundColor: null,
		style: {
			 // fontFamily: 'Dosis, sans-serif'
		}
	},
	credits: {
		enabled: false,
	},
	title: {
		style: {
			 fontSize: '16px',
			 fontWeight: 'bold',
			 textTransform: 'uppercase'
		}
	},
	tooltip: {
		borderWidth: 0,
		backgroundColor: 'rgba(219,219,216,0.8)',
		shadow: false
	},
	legend: {
		itemStyle: {
			 fontWeight: 'normal',
			 fontSize: '13px',
		}
	},
	xAxis: {
		gridLineWidth: 1,
		labels: {
			 style: {
					fontSize: '12px'
			 }
		}
	},
	yAxis: {
		minorTickInterval: 'auto',
		title: {
			 style: {
					textTransform: 'uppercase'
			 }
		},
		labels: {
			 style: {
					fontSize: '12px'
			 }
		}
	},
	plotOptions: {
		candlestick: {
			 lineColor: '#404048'
		}
	},
	exporting: {
		enabled:false,
		buttons: {
			contextButton: {
				menuItems: [
					'printChart',
					'separator',
					'downloadPNG',
					'downloadJPEG',
					'downloadPDF',
					'downloadSVG',
					'separator',
					'downloadCSV',
					'downloadXLS',
					// 'viewData',
					// 'openInCloud',
				],
			},
		},
	},

	// General
	background2: '#F0F0EA'

};

// Apply the theme
Highcharts.setOptions(Highcharts.theme);
