<header id="notifications-batch-header" class="md-whiteframe-2dp flex-none">
	<form name="headerForm" novalidate ng-model-options="{updateOn:'default blur', debounce:{'default':500, 'blur':0}}">
		<div layout="column" layout-gt-xs="row" layout-align="space-between stretch">
			<h2 class="md-headline">Workspace Calendar — {{ ctrl.batch.file_name }}</h2>
			<div class="hide" layout layout-align-xs="end start" layout-align="end start" layout-wrap>
			</div>
		</div>
	</form>
	<div class="layout-gt-md-row layout-align-gt-md-center-center" ng-show="ctrl.batch.status == 'unpublished'">
		<md-card class="flex" md-theme="::'info'">
			<md-card-content layout>
				<span><span class="fas fa-info-circle"></span>&nbsp;&nbsp;</span>
				<span flex>DRAFT FORM — NOT VISIBLE UNTIL PUBLISHED</span>
			</md-card-content>
		</md-card>
	</div>
</header>

<main id="notification-batch-calendar" class="main-list flex-gt-sm layout-gt-md-row">
	<div id="calendar-panel" class="panel flex-gt-md layout-gt-md-column md-whiteframe-1dp">
		<div class="panel-content padding-2x text-center flex-gt-md-none">
				<md-button type="button" class="compact" ng-click="ctrl.filters.approval_statuses=['approved']; ctrl.applyFilters();">
					Approved: {{ctrl.tally.approved_perc}}% <i class="black-75">({{ctrl.tally.approved}})</i>
				</md-button>
				<md-button type="button" class="compact" ng-click="ctrl.filters.approval_statuses=['held']; ctrl.applyFilters();">
					Hold: {{ctrl.tally.held_perc}}% <i class="black-75">({{ctrl.tally.held}})</i>
				</md-button>
				<md-button type="button" class="compact" ng-click="ctrl.filters.approval_statuses=[null]; ctrl.applyFilters();">
					Unreviewed: {{ctrl.tally.null_perc}}% <i class="black-75">({{ctrl.tally.null}})</i>
				</md-button>
		</div>
		<form class="panel-content padding-2x compact no-error-space flex-gt-md-none layout-row layout-align-end-start layout-wrap">
				<md-input-container class="search flex md-input-has-placeholder" style="min-width: 150px" 
					ng-model-options="{updateOn:'default blur', debounce:{'default':500, 'blur':0}}">
					<label>Search</label>
					<md-icon class="fas fa-search black-50"></md-icon>
					<input name="search" ng-model="ctrl.filters.search" type="text"
						ng-change="ctrl.applyFilters()"
						ng-keypress="$event.keyCode==13 && ctrl.applyFilters()">
				</md-input-container>
				<div class="flex-none layout-row layout-wrap layout-align-end-start" style="max-width:100%">
					<md-input-container class="flex-none padding-2x">
						<span class="black-66">Filter:</span>
					</md-input-container>
					<md-input-container class="md-input-has-placeholder" style="min-width: 150px">
						<label>Approval Status</label>
						<md-select name="approval_status" multiple
							ng-change="ctrl.applyFilters()"
							ng-model="ctrl.filters.approval_statuses"
							ng-model-options="{trackBy: '$value'}">
							<md-option ng-repeat="(name, value) in ctrl.mapping.approval_status" ng-value="value">{{ name }}</md-option>
						</md-select>
					</md-input-container>
					<!-- <md-input-container>
						<label>Type</label>
						<md-select name="notification_type" multiple
							ng-change="ctrl.applyFilters()"
							ng-model="ctrl.filters.notification_types"
							ng-model-options="{trackBy: '$value'}">
							<md-option ng-repeat="(value, name) in ctrl.mapping.types" ng-value="value">{{ name }}</md-option>
						</md-select>
					</md-input-container> -->
					<md-input-container class="md-input-has-placeholder" style="min-width: 150px">
						<label>Category</label>
						<md-select name="category" multiple
							ng-change="ctrl.applyFilters()"
							ng-model="ctrl.filters.flutter_categories"
							ng-model-options="{trackBy: '$value'}">
							<md-option ng-repeat="$cat in ctrl.mapping.flutterCategories" ng-value="$cat._id">{{ $cat.name.en_US }}</md-option>
						</md-select>
					</md-input-container>
				</div>
				<div class="flex-none layout-row">
					<md-input-container class="flex-none btn-group md-raised" form-disabled="!ctrl.dataList.length">
						<md-button type="button" class="md-primary md-raised md-hue-2 compact margin-0x" ng-click="ctrl.selectPrev()" aria-label="Select previous event">
							<i class="fas fa-angle-left"></i>&nbsp;&nbsp;Prev</md-button>
						<md-button type="button" class="md-primary md-raised md-hue-2 compact margin-0x" ng-click="ctrl.selectNext()" aria-label="Select next event">
							Next Event&nbsp;&nbsp;<i class="fas fa-angle-right"></i></md-button>
					</md-input-container>
					<md-input-container class="flex-none padding-2x">
						<span class="black-66">{{ ctrl.dataList.length ? ctrl.selectedIndex +1 : 0 }}/{{ ctrl.dataList.length }}</span>
					</md-input-container>
					<md-input-container class="flex-none padding-0x">
						<md-menu md-position-mode="target-right target" >
							<md-button aria-label="Calendar Options" class="md-icon-button" ng-click="$mdMenu.open($event)">
								<i class="fas fa-cog"></i>
							</md-button>
							<md-menu-content width="4" >
								<md-menu-item>
									<md-button ng-click="ctrl.colorCategory()">
										<div class="layout-row">
											<p class="flex">Category Colors</p>
											<span class="flex-none"><i class="fas" ng-class="{'fa-check': ctrl.colorCategory.value, 'fa-times': !ctrl.colorCategory.value}"></i></span>
										</div>
									</md-button>
								</md-menu-item>
							</md-menu-content>
						</md-menu>
					</md-input-container>
				</div>
		</form>
		<div class="panel-content padding-2x flex-gt-md-none layout-row layout-align-start-center">
			<div class="btn-group md-raised flex-none">
				<md-button type="button" class="md-primary md-raised compact" ng-click="ctrl.calendar.prev()" aria-label="Go to previous month">
					<i class="fas fa-angle-left"></i></md-button>
				<md-button type="button" class="md-primary md-raised compact" ng-click="ctrl.calendar.next()" aria-label="Go to next month">
					<i class="fas fa-angle-right"></i></md-button>
			</div>
			<div class="flex-none">
				<md-button type="button" class="md-primary md-raised" ng-click="ctrl.selectToday()" aria-label="Go to present day">Today</md-button>
			</div>
			<i flex></i>
			<h3 class="flex-none md-headline margin-2x">{{ ctrl.calendarDate | amDateFormat: 'MMMM YYYY' }}</h3>
		</div>
		<div class="panel-content margin-2x can-scroll-y flex-gt-md layout-gt-md-column">
			<div id="calendar" class="flex"></div>
		</div>
	</div>

	<div class="panel-wrap flex-gt-md-40 layout-gt-md-column">
		<div class="panel flex-gt-md layout-column layout-align-center-center md-whiteframe-1dp" ng-if="!ctrl.selected" style="min-height:600px">
			<em class="md-caption black-25">select an event to edit</em>
		</div>

		<form id="edit-form" name="form" class="compact panel flex-gt-md md-whiteframe-1dp can-scroll-y" 
			ng-show="ctrl.selected" 
			ng-model-options="{updateOn:'default blur', debounce:{'default':300, 'blur':0}}"
			form-disabled="ctrl.isBusy || ctrl.selected.publish_status=='published' || ctrl.selected.publish">
			<h3 class="indent md-title">
				{{ ctrl.mapping.notification_types.byId[ctrl.selected.notification_type].name || ctrl.selected.notification_type }}
				{{ form.$pristine ? '' : '*' }}
			</h3>
			<md-divider></md-divider>
			<div class="padding-4x" ng-if="ctrl.selected">
				<md-input-container class="md-block md-input-has-placeholder">
					<label>Title</label>
					<textarea name="name" required md-no-resize="true"
						valid-lang
						ng-model="ctrl.selected.flutter.name.en_US" ng-change="ctrl.setTitle(ctrl.selected, $value)"
						ng-model-options="{updateOn:'default blur', debounce:{'default':200,'blur':0}}"></textarea>
					<div ng-messages="form.name.$error" role="alert">
						<div ng-message="required">Required</div>
						<div ng-message="lang-missing">{{ form.name.$error['lang-missing'] }}</div>
						<div ng-message="lang-mismatch">{{ form.name.$error['lang-mismatch'] }}</div>
						<div ng-message="lang">Invalid language tag formatting</div>
					</div>
				</md-input-container>

				<div ng-if="ctrl.selected && ctrl.selected.notification_type!='tip_theme'" ng-model-options="{debounce:{'default':1000,'blur':0}, '*': '$inherit'}">
					<div class="layout-row layout-wrap" ng-repeat="group in ctrl.selected.recipients">
						<md-input-container class="md-input-has-placeholder flex-100 flex-gt-xs-33 flex-lg-100">
							<label>College</label>
							<md-select name="college"
                aria-label="college"
								ng-model="group.college" ng-model-options="{trackBy: '$value'}"
								ng-change="ctrl.getCollegeCohorts()"
								ng-disabled="!ctrl.selected || (!form.college.$dirty && ctrl.batch.college_id && +group.college == +ctrl.batch.college_id) || ctrl.selected.publish_status=='published' || ctrl.selected.publish">
								<md-option ng-if="group.college && !ctrl.mapping.mycolleges.byId[group.college]" ng-value="group.college">*{{ ctrl.mapping.mycolleges.byId[group.college].name }}</md-option>
								<md-option ng-repeat="item in ctrl.mapping.mycolleges" ng-value="item._id">{{ item.name }}</md-option>
							</md-select>
						</md-input-container>
						<md-input-container class="md-input-has-placeholder flex-100 flex-gt-xs-33 flex-lg-100">
							<label>Levels</label>
							<md-select multiple
                aria-label="levels"
								ng-model="group.level" ng-model-options="{trackBy: '$value'}"
								ng-disabled="!ctrl.selected || ctrl.selected.publish_status=='published' || ctrl.selected.publish">
								<md-option ng-repeat="item in ctrl.mapping.levels" ng-value="item.name">{{ item.title }}</md-option>
							</md-select>
						</md-input-container>
						<md-input-container class="md-input-has-placeholder flex-100 flex-gt-xs-33 flex-lg-100">
							<label>Cohorts</label>
							<md-select multiple 
								ng-model="group.cohort" ng-model-options="{trackBy: '$value'}" 
								aria-label="cohorts"
								ng-disabled="!ctrl.selected || !group.college || !ctrl.mapping.cohorts.length || ctrl.selected.publish_status=='published' || ctrl.selected.publish">
								<md-option ng-repeat="item in ctrl.mapping.cohorts" ng-value="item.name">{{ item.title }}</md-option>
							</md-select>
							<div class="md-errors-spacer"></div>
						</md-input-container>
					</div>
				</div>

				<md-input-container class="md-block md-input-has-placeholder">
					<label>Date / Date Range</label>
					<!-- <input type="text" ng-model="ctrl.selected._$date" ng-model-options="{updateOn:'default blur', debounce:{'default':600,'blur':0}}" ng-change="ctrl.updateDate()"> -->
					<input type="text" name="date" ng-model="ctrl._$dateRange" required
						ng-model-options="{updateOn:'default blur', debounce:{'*': 2000, blur:100}}" ng-change="ctrl.setDate(ctrl.selected, ctrl._$dateRange)"
						valid-date-range separator="ctrl.dateSeparator" view-format="ctrl.viewFormat" model-format="ctrl.modelFormat"
						validate="ctrl.isValidDateRange($modelValue.$moments, $modelValue, $viewValue)">
					<div ng-messages="form.date.$error" role="alert">
						<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
						<div ng-message="order">Start date cannot be after end date</div>
						<div ng-message="validate">Range cannot be less than {{ ctrl.selected.tips._$max_day_offset }} days, a tip is set on day {{ ctrl.selected.tips._$max_day_offset }}</div>
					</div>
				</md-input-container>

				<md-input-container class="md-block md-input-has-placeholder">
					<label>Notes</label>
					<textarea name="notes" 
						ng-model="ctrl.selected.flutter.notes" 
						md-no-resize="true">
					</textarea>
				</md-input-container>

				<div ng-if="ctrl.selected.notification_type=='dates_and_events'" form-disabled="!ctrl.selected || ctrl.selected.publish_status=='published' || ctrl.selected.publish">
					<md-input-container class="md-block md-input-has-placeholder">
						<label>Event Short Name (Displayed in Calendar)</label>
						<input type="text" name="short_name" ng-model="ctrl.selected.flutter.short_name" ng-change="ctrl.setTitle(ctrl.selected, $value)" ng-maxlength="6">
						<div ng-messages="form.short_name.$error" role="alert">
							<div ng-message="maxlength">Cannot exceed 6 characters</div>
						</div>
					</md-input-container>

					<md-input-container class="md-block md-input-has-placeholder">
						<label>Description</label>
						<textarea name="text" rows="3" 
						required md-no-resize="true"
						tinymce plain-text="ctrl.selected.text.content=='plain/text'"
						switch-content-type="true" content-type-model="ctrl.selected.text.content"	
						ng-change="ctrl.onDetailsChanged(); ctrl.setTitle(ctrl.selected, $value);"
						ng-model="ctrl.selected.text.formatted"></textarea>
						<div ng-messages="form.text.$error" role="alert">
							<div ng-message="required">Required</div>
						</div>
					</md-input-container>

					<md-input-container class="md-block md-input-has-placeholder">
						<label>Location</label>
						<input type="text" name="location" 
							valid-lang ng-model="ctrl.selected.flutter.location.label.en_US">
						<div ng-messages="form.location.$error" role="alert">
							<div ng-message="required">Required</div>
							<div ng-message="lang-missing">{{ form.location.$error['lang-missing'] }}</div>
							<div ng-message="lang-mismatch">{{ form.location.$error['lang-mismatch'] }}</div>
							<div ng-message="lang">Invalid language tag formatting</div>
						</div>
					</md-input-container>
					<md-input-container class="md-block md-input-has-placeholder">
						<label>Link Text</label>
						<input name="link_text" type="text" 
							valid-lang
							ng-model="ctrl.selected.link.text" 
							ng-required="ctrl.selected.link.url">
						<div ng-messages="form.link_text.$error" role="alert">
							<div ng-message="required">Required</div>
							<div ng-message="lang-missing">{{ form.link_text.$error['lang-missing'] }}</div>
							<div ng-message="lang-mismatch">{{ form.link_text.$error['lang-mismatch'] }}</div>
							<div ng-message="lang">Invalid language tag formatting</div>
						</div>
					</md-input-container>
					<md-input-container class="md-block md-input-has-placeholder" ng-model-options="{allowInvalid:true, '*': '$inherit'}">
						<label>Link URL</label>
						<input name="link_url" type="url" ng-model="ctrl.selected.link.url" ng-required="ctrl.selected.link.text">
						<div ng-messages="form.link_url.$error" role="alert">
							<div ng-message="required">Required</div>
							<div ng-message="url">Use a valid url format, ex: <em>https://example.com</em></div>
						</div>
					</md-input-container>

					<md-input-container class="md-block md-input-has-placeholder">
						<label>Category</label>
						<md-select name="category" required
              aria-label="category"
							ng-model="ctrl.selected.flutter.category" ng-model-options="{trackBy: '$value'}"
							ng-change="ctrl.setCategory(ctrl.selected, $value)" 
							ng-disabled="!ctrl.selected || ctrl.selected.publish_status=='published' || ctrl.selected.publish">
							<md-option ng-repeat="item in ctrl.mapping.flutterCategories" ng-value="item._id">{{ item.name.en_US }}</md-option>
						</md-select>
						<div class="md-errors-spacer"></div>
						<div ng-messages="form.category.$error" role="alert">
							<div ng-message="required">Required</div>
						</div>
					</md-input-container>
					
					<md-input-container class="md-block md-input-has-placeholder" ng-model-options="{allowInvalid:true, debounce:{'default':500,'blur':0}, '*': '$inherit'}">
						<label>Reminder Plan</label>
						<input name="reminder_plan" type="text" 
							placeholder="{{ ctrl.mapping.flutterCategories.byId[ctrl.selected.flutter.category].reminder_plan }}"
							ng-model="ctrl.selected.reminder_plan" ng-pattern="/^(0|-\d+)(,\s*(0|-\d+))*$/">
						<div class="md-errors-spacer"></div>
						<div ng-messages="form.reminder_plan.$error" role="alert">
							<div ng-message="pattern">Invalid format<br>Must be comma separated negative integers; e.g. &quot;<code>-1, -3, -7</code>&quot;</div>
						</div>
					</md-input-container>
				</div>
			</div>
			
			<div class="layout-column" ng-if="ctrl.selected.notification_type=='tip_theme'">
				<md-divider></md-divider>
				<md-list class="padding-0x flex" ui-sortable="ctrl.sortableOpts" ng-model="ctrl.selected.tips">
					<md-subheader class="md-no-sticky">Tips</md-subheader>
					<md-divider></md-divider>
					<md-list-item class="md-3-line md-long-text" ng-repeat="item in ctrl.selected.tips track by $index" ng-class="{'edit-tip': ctrl.isEditingTip(item)}">
						<div class="md-list-item-text focusable" role="button" ng-click="ctrl.editTip(item)">
							<strong class="md-subhead">
								<span ng-bind-html="item.call_to_action"></span>
								<em class="black-25" ng-show="!item.call_to_action">[ no content yet ]</em>
							</strong>
							<span class="normal-wg">
								{{ item.text.formatted | stripTags }}
								<em class="black-25" ng-show="!item.text">[ no content yet ]</em>
							</span>
							<span class="layout-row layout-wrap" ng-repeat="group in item.recipients">
								<small class="flex-none small-tag recipient college" ng-show="group.college != ctrl.batch.college_id">
									{{ ctrl.mapping.mycolleges.byId[group.college].name }}
								</small>
								<small class="flex-none small-tag recipient level" ng-repeat="id in group.level">
									{{ ctrl.mapping.levels.byName[id].title }}
								</small>
								<small class="flex-none small-tag recipient cohort" ng-repeat="id in group.cohort">
									{{ ctrl.mapping.allCohorts.byName[id].title }}
								</small>
							</span>
							<span class="layout-row layout-wrap">
								<small class="flex-none small-tag">Day {{ item.day_offset || 'not set' }}</small>
								<small class="flex-none small-tag" ng-show="item.flutter.category">
									<i class="far fa-folder"></i> {{ ctrl.mapping.flutterCategories.byId[item.flutter.category].name.en_US || item.flutter.category }}
								</small>
								<small class="flex-none small-tag" ng-repeat="tag in item.flutter.tags">
									<i class="fas fa-tag"></i> {{ ctrl.mapping.flutterTags.byId[tag].name.en_US || tag }}
								</small>
							</span>
						</div>
						<!-- <div class="width-100" ng-if="ctrl.isEditingTip(item)" ng-include="::'/main/notifications/notifications.onboarding.tip.tpl'"></div> -->
						<span class="tip-ctrl sort-handle fas fa-bars"></span>
						<md-button class="md-icon-button md-warn md-hue-1 tip-ctrl tip-delete"
							aria-label="Remove tip"
							ng-click="ctrl.removeTip($index, $event)">
							<span class="far fa-trash-alt"></span>
						</md-button>
					</md-list-item>
					<div class="text-center" ng-hide="ctrl.selected.publish_status=='published' || ctrl.selected.publish">
						<md-button class="md-primary md-raised md-hue-3" ng-click="ctrl.addTip()">
							<i class="fas fa-plus"></i> Add Tip
						</md-button>
					</div>
				</md-list>
			</div>
			
			<div class="padding-2x"></div>
		</form>
	</div>

	<md-sidenav id="tip-form" md-component-id="tip-form" class="md-sidenav-right layout-column layout-align-stretch-start">
		<ng-form name="tipForm" class="compact" ng-model-options="{updateOn:'default blur', debounce:{'default':300,'blur':0}}">
			<ng-include class="flex md-whiteframe-1dp" src="::'/main/notifications/notifications.onboarding.tip.tpl'"></ng-include>
		</ng-form>
	</md-sidenav>
</main>

<div ng-show="ctrl.isBusy" class="busy-overlay fade-in fade-out" layout layout-align="center center">
	<md-progress-circular md-mode="indeterminate"></md-progress-circular>
</div>

<footer id="workspace-calendar-footer" class="md-whiteframe-2dp layout-row layout-align-start-center layout-wrap">
	<div class="flex layout-row padding-2x">
		<p class="flex md-caption black-25 indent">
			<em ng-show="ctrl.lastTimeSaved && !ctrl.isSaving">Last saved {{ ctrl.lastTimeSaved.fromNow() }}</em>
			<em ng-show="ctrl.isSaving">Saving changes...</em>
		</p>
		<md-button class="md-primary" ng-click="ctrl.addNewEvent()">
			<span class="fas fa-plus"></span> Add New Event
		</md-button>
		</p>
		<md-button class="md-primary" ng-click="ctrl.addNewTipTheme()">
			<span class="fas fa-folder-plus"></span> Add New Tip Theme
		</md-button>
	</div>

	<div class="control-panel-smaller flex-none flex-gt-md layout-row layout-align-end-start padding-2x" 
		form-disabled="!ctrl.selected || ctrl.isBusy || ctrl.selected.publish_status=='published' || ctrl.selected.publish">
		<md-button class="md-raised md-primary delete" ng-click="ctrl.delete($event)">
			<span class="far fa-trash-alt"></span> Delete
		</md-button>
		<md-button class="md-raised md-primary hold" ng-click="ctrl.submit('held')">
			<span class="fas fa-thumbs-down"></span>
			<span ng-show="ctrl.selected.approval_status == 'held'">Hold {{ ctrl.selected.date_approval_status_updated | amDateFormat:'YYYY-MM-DD' }}</span>
			<span ng-show="ctrl.selected.approval_status != 'held'">Hold</span>
		</md-button>
		<md-button class="md-raised md-primary approve" ng-click="ctrl.submit('approved')">
			<span class="fas fa-thumbs-up"></span>
			<span ng-show="ctrl.selected.approval_status == 'approved'">Approved {{ ctrl.selected.date_approval_status_updated | amDateFormat:'YYYY-MM-DD' }}</span>
			<span ng-show="ctrl.selected.approval_status != 'approved'">Approve</span>
		</md-button>
	</div>
</footer>


<script type="text/ng-template" id="/main/notifications/notifications.onboarding.tip.tpl">
	<h4 class="margin-4x md-title">Edit Tip {{ tipForm.$pristine ? '' : '*' }}</h4>
	<md-divider class="clearfix"></md-divider>
	<!-- <ng-form name="tipForm" class="compact" ng-model-options="{updateOn:'default blur', debounce:{'default':300,'blur':0}}" form-disabled="ctrl.isBusy || ctrl.selected.publish_status=='published'"> -->
	<fieldset form-disabled="ctrl.isBusy || ctrl.selected.publish_status=='published'">
		<div class="padding-4x">
			<div ng-include="'recipientGroup.tpl'"></div>
			
				<div ng-model-options="{debounce:{'default':1000,'blur':0}, '*': '$inherit'}">
					<div class="layout-row layout-wrap" ng-repeat="group in ctrl.selectedTip.recipients">
						<md-input-container class="md-input-has-placeholder flex-100 flex-gt-xs-33 flex-lg-100">
							<label>College</label>
							<md-select name="college"
                aria-label="college"
								ng-model="group.college" ng-model-options="{trackBy: '$value'}"
								ng-change="ctrl.getTipCollegeCohort()"
								ng-disabled="!ctrl.selectedTip || (!form.college.$dirty && ctrl.batch.college_id && +group.college == +ctrl.batch.college_id) || ctrl.selectedTip.publish_status=='published' || ctrl.selected.publish">
								<md-option ng-if="group.college && !ctrl.mapping.mycolleges.byId[group.college]" ng-value="group.college">*{{ ctrl.mapping.mycolleges.byId[group.college].name }}</md-option>
								<md-option ng-repeat="item in ctrl.mapping.mycolleges" ng-value="item._id">{{ item.name }}</md-option>
							</md-select>
						</md-input-container>
						<md-input-container class="md-input-has-placeholder flex-100 flex-gt-xs-33 flex-lg-100">
							<label>Levels</label>
							<md-select multiple
                aria-label="levels"
								ng-model="group.level" ng-model-options="{trackBy: '$value'}"
								ng-disabled="!ctrl.selectedTip || ctrl.selectedTip.publish_status=='published' || ctrl.selectedTip.publish">
								<md-option ng-repeat="item in ctrl.mapping.levels" ng-value="item.name">{{ item.title }}</md-option>
							</md-select>
						</md-input-container>
						<md-input-container class="md-input-has-placeholder flex-100 flex-gt-xs-33 flex-lg-100">
							<label>Cohorts</label>
							<md-select multiple 
                aria-label="cohorts"
								ng-model="group.cohort" ng-model-options="{trackBy: '$value'}" 
								ng-disabled="!ctrl.selectedTip || !group.college || !ctrl.mapping.tipCohorts.length || ctrl.selectedTip.publish_status=='published' || ctrl.selectedTip.publish">
								<md-option ng-repeat="item in ctrl.mapping.tipCohorts" ng-value="item.name">{{ item.title }}</md-option>
							</md-select>
							<div class="md-errors-spacer"></div>
						</md-input-container>
					</div>
				</div>
			<md-input-container class="md-block md-input-has-placeholder">
				<label>Title</label>
				<input name="name" valid-lang ng-model="ctrl.selectedTip.flutter.name.en_US">
				<div ng-messages="tipForm.name.$error" role="alert">
					<div ng-message="lang-missing">{{ tipForm.name.$error['lang-missing'] }}</div>
					<div ng-message="lang-mismatch">{{ tipForm.name.$error['lang-mismatch'] }}</div>
					<div ng-message="lang">Invalid language tag formatting</div>
				</div>
			</md-input-container>
			<md-input-container class="md-block md-input-has-placeholder">
				<label>Call To Action</label>
				<textarea name="call_to_action" 
					valid-lang
					ng-model="ctrl.selectedTip.call_to_action"
					md-no-resize="true" ng-required="ctrl.selectedTip"></textarea>
				<div ng-messages="tipForm.call_to_action.$error" role="alert">
					<div ng-message="required">Required</div>
					<div ng-message="lang-missing">{{ tipForm.name.$error['lang-missing'] }}</div>
					<div ng-message="lang-mismatch">{{ tipForm.name.$error['lang-mismatch'] }}</div>
					<div ng-message="lang">Invalid language tag formatting</div>
				</div>
			</md-input-container>

			<md-input-container class="md-block md-input-has-placeholder">
				<label>Attribution</label>
				<input name="attribution" 
					valid-lang 
					ng-model="ctrl.selectedTip.attribution" />
				<div ng-messages="tipForm.attribution.$error" role="alert">
					<div ng-message="lang-missing">{{ tipForm.attribution.$error['lang-missing'] }}</div>
					<div ng-message="lang-mismatch">{{ tipForm.attribution.$error['lang-mismatch'] }}</div>
					<div ng-message="lang">Invalid language tag formatting</div>
				</div>
			</md-input-container>

			<md-radio-group class="tip-background flex-95 flex-offset-5 no-form-focus" ng-model="ctrl.selectedTip.background.$useImage">
				<div class="layout-row" form-disabled="!ctrl.selectedTip.background.$useImage">
					<md-input-container>
						<md-radio-button class="flex-nogrow margin-0x" ng-value="true" aria-label="Use background image"></md-radio-button>
					</md-input-container>

					<div class="flex disabled-opacity-50 cursor-normal" 
						tabindex="{{-1}}"
						ng-click="ctrl.selectedTip.background.$useImage ? null : ctrl.propagateDelayedEvent($event, ctrl.selectedTip.background.$useImage=true)">

						<div class="layout-row">
							<md-input-container class="md-input-has-placeholder flex no-animate right-action-btn" 
								ng-hide="ctrl.selectedTip.background.file" 
								form-disabled="ctrl.selectedTip.background.file">
								<label>Background Image URL</label>
								<input type="url" class="md-input" name="background_url" ng-model="ctrl.selectedTip.background.url">
								<md-button class="md-icon-button times md-warn md-hue-1 compact" ng-click="ctrl.selectedTip.background.url=''" for="background_url" aria-label="Clear value">
									<md-icon class="fas fa-times"></md-icon>
								</md-button>
								<div ng-messages="form.background_url.$error" role="alert">
									<div ng-message="url">Invalid URL format (ie: https://example.com/image.jpeg)</div>
								</div>
							</md-input-container>

							<md-input-container class="md-input-has-placeholder flex right-action-btn no-animate" 
								ng-show="ctrl.selectedTip.background.file" ng-cloak 
								form-disabled="!ctrl.selectedTip.background.file">
								<label>Background Image</label>
								<input type="text" name="background_file" class="md-input disabled" ng-value="ctrl.selectedTip.background.file.name" ng-disabled="true">
								<md-button class="md-icon-button times compact md-warn md-hue-1" aria-label="remove file" ng-click="ctrl.selectedTip.background.file = undefined">
									<md-icon class="fas fa-times"></md-icon>
								</md-button>
							</md-input-container>

							<md-input-container class="flex-nogrow no-animate" ng-hide="ctrl.isLocked">
								<md-button type="file" name="background_url_file" class="md-raised md-hue-3 compact"
									accept="image/*" ngf-pattern="'image/*'"
									ng-model="ctrl.selectedTip.background.file"
									ngf-select="ctrl.log('file', $files)"
									ngf-multiple="false"
									ngf-change="ctrl.onFileSelect($event)">
									<i class="fas fa-file-upload"></i> Select
								</md-button>
							</md-input-container>
						</div>
					</div>
				</div>
        <div class="layout-row" ng-show="ctrl.selectedTip.background.file || ctrl.selectedTip.background.url">
          <md-input-container class="md-input-has-placeholder flex alt-text">
            <label>Background Image Alt Text</label>
            <input type="text" name="bg_image_alt_text" ng-model="ctrl.selectedTip.background.alt_text" minlength="5" ng-required="ctrl.selectedTip.background.file || ctrl.selectedTip.background.url">
            <div ng-messages="tipForm.bg_image_alt_text.$error" role="alert">
              <div ng-message="required">Required</div>
              <div ng-message="minlength">Minimum 5 characters</div>
            </div>
          </md-input-container>
        </div>
				
				<div class="layout-row" form-disabled="ctrl.selectedTip.background.$useImage">
					<md-input-container>
						<md-radio-button class="flex-nogrow margin-0x" ng-value="false" aria-label="Use background color"></md-radio-button>
					</md-input-container>

					<md-input-container class="md-input-has-placeholder flex disabled-opacity-50 cursor-normal" 
						tabindex="{{-1}}"
						ng-click="ctrl.selectedTip.background.$useImage ? ctrl.propagateDelayedEvent($event, ctrl.selectedTip.background.$useImage=false) : null">
						<label>Background Color</label>
						<md-select name="background_color"
							ng-model="ctrl.selectedTip.background.color"
							ng-change="ctrl.selectedTip.background.color=='none' ? (ctrl.selectedTip.background.color=undefined) : null">
							<md-option md-option-empty ng-value="'none'">None</md-option>
							<md-option ng-repeat="color in ctrl.mapping.bgColors track by $index" ng-value="color.value">
								<span class="color" ng-style="{'background-color': color.value}"></span> {{ color.value }}
							</md-option>
						</md-select>
						<div class="md-errors-spacer"></div>
					</md-input-container>
				</div>
			</md-radio-group>
			<md-input-container class="md-block md-input-has-placeholder">
				<label>Description</label>
				<textarea tinymce 
				switch-content-type="true"
				content-type-model="ctrl.selectedTip.text.content" name="text" ng-model="ctrl.selectedTip.text.formatted" row="2"
					md-no-resize="true" ng-required="ctrl.selectedTip"></textarea>
				<div ng-messages="tipForm.text.$error" role="alert">
					<div ng-message="required">Required</div>
				</div>
			</md-input-container>
			<md-input-container class="md-block md-input-has-placeholder">
				<label>Location</label>
				<input name="location" valid-lang ng-model="ctrl.selectedTip.flutter.location.label.en_US">
				<div ng-messages="tipForm.location.$error" role="alert">
					<div ng-message="lang-missing">{{ tipForm.location.$error['lang-missing'] }}</div>
					<div ng-message="lang-mismatch">{{ tipForm.location.$error['lang-mismatch'] }}</div>
					<div ng-message="lang">Invalid language tag formatting</div>
				</div>
			</md-input-container>

			<md-input-container class="md-block md-input-has-placeholder">
				<label>Link Text</label>
				<input type="text" name="link_text" 
					valid-lang
					ng-model="ctrl.selectedTip.link.text" 
					ng-required="ctrl.selectedTip && ctrl.selectedTip.link.url">
				<div ng-messages="tipForm.link_text.$error" role="alert">
					<div ng-message="required">Required</div>
					<div ng-message="lang-missing">{{ tipForm.location.$error['lang-missing'] }}</div>
					<div ng-message="lang-mismatch">{{ tipForm.location.$error['lang-mismatch'] }}</div>
					<div ng-message="lang">Invalid language tag formatting</div>
				</div>
			</md-input-container>
			<md-input-container class="md-block md-input-has-placeholder" ng-model-options="{allowInvalid:true, '*': '$inherit'}">
				<label>Link URL</label>
				<input type="url" name="link_url" ng-model="ctrl.selectedTip.link.url" ng-required="ctrl.selectedTip && ctrl.selectedTip.link.text">
				<div ng-messages="tipForm.link_url.$error" role="alert">
					<div ng-message="required">Required</div>
					<div ng-message="url">Use a valid url format, ie: <em>https://example.com</em></div>
				</div>
			</md-input-container>

			<md-input-container class="md-block md-input-has-placeholder">
				<label>Category</label>
				<md-select name="category" aria-label="category"
					ng-model="ctrl.selectedTip.flutter.category" ng-model-options="{trackBy: '$value'}"
					ng-disabled="ctrl.isBusy || ctrl.selected.publish_status=='published'"
					ng-required="ctrl.selectedTip">
					<md-option ng-repeat="item in ctrl.mapping.flutterCategories" ng-value="item._id">{{ item.name.en_US }}</md-option>
				</md-select>
				<div class="md-errors-spacer"></div>
				<div ng-messages="tipForm.category.$error" role="alert">
					<div ng-message="required">Required</div>
				</div>
			</md-input-container>
			<md-input-container class="md-block md-input-has-placeholder">
				<label>Tags</label>
				<md-select name="tags" aria-label="tags" multiple
					ng-model="ctrl.selectedTip.flutter.tags" ng-model-options="{trackBy: '$value'}"
					ng-disabled="ctrl.isBusy || ctrl.selected.publish_status=='published'">
					<md-option ng-repeat="item in ctrl.mapping.flutterTags" ng-value="item._id">{{ item.name.en_US }}</md-option>
				</md-select>
				<div class="md-errors-spacer"></div>
				<div ng-messages="tipForm.tags.$error" role="alert">
					<div ng-message="required">Required</div>
				</div>
			</md-input-container>
			
			<div class="layout-row">
				<md-input-container class="md-input-has-placeholder flex-none" style="width:120px">
					<label>Day</label>
					<input  name="day_offset" type="number" min="1" ng-pattern="/^\d+$/" aria-label="Day"
						ng-model="ctrl.selectedTip.day_offset" ng-model-options="{updateOn:'default blur', debounce:{'default':800,'blur':0}}"
						ng-change="ctrl.changeTipDayOffset($value)" ng-required="ctrl.selectedTip">
					<div ng-messages="tipForm.day_offset.$error" role="alert">
						<div ng-message="required">Required</div>
						<div ng-message="min">Must be 1+</div>
						<div ng-message="pattern">Whole Number only</div>
					</div>
				</md-input-container>
			</div>
		</div>
		<md-divider></md-divider>
		<div class="layout-row margin-4x" ng-hide="ctrl.isBusy || ctrl.selected.publish_status=='published'">
			<md-button class="md-primary md-hue-2 compact" ng-click="ctrl.closeEditTip()">Close</md-button>
		</div>
	</fieldset>
	<!-- </ng-form> -->
</script>