import angular from 'angular'
import $ from 'jquery'
import {Helper, SETTINGS, CONSTANTS, ApiError} from '../../common'
import BaseSingleController from '../base.single';

const NOTIF_TYPES = CONSTANTS.NOTIFICATION_TYPES;
const FIELDMAP = require('./fieldmap.json');

export default class WorkspacePublishController extends BaseSingleController {
	static get $inject(){return [
		'eventsData',
		'tipThemesData',
		'batch',
		'batchStatus',
		'MAPPINGS_JSON',
		'authorization',
		'api',
		'apiMap',
		'toast',
		'$scope',
		'$q',
		'$timeout',
		'$mdDialog',
		'$state',
	].concat(BaseSingleController.$inject)}

	init(){
		this.mapping = {
			status: Helper.superMap(this.MAPPINGS_JSON.notification.status, {type:'status'}),
			types: Helper.superMap(angular.extend({tip_theme:'Tip Theme'}, this.MAPPINGS_JSON.notifications.type_key), {type:'type'}),
			categories: Helper.superMap(this.MAPPINGS_JSON.notifications.category_key, {type:'category'}),
			approval_status: {
				'Approved': 'approved',
				'Follow Up': 'held',
				'Unreviewed': null,
			},
		};
		this._$view = 'date';

		this.query = {
			stackingNotificationWeight: 0.1,
			dateDistanceWeight: 1,
			notificationDayBeforeEventWeight: 0.9,
		};

		this.tipThemesData.forEach(item=>Object.assign(item, {notification_type:NOTIF_TYPES.TIP_THEME}));
		this.eventsData.forEach(item=>Object.assign(item, {notification_type:NOTIF_TYPES.EVENT}));
		
		this.data = this.eventsData.concat(this.tipThemesData);
		this.data.byId = {};
		this.data.forEach(item=>this.data.byId[item._id] = item);

		super.init();
	}
	_loadDependencies(){
		return this.$q.all([
			this.apiMap.getActiveColleges().then(data=>this.mapping.mycolleges = data),
			this.apiMap.getCohortLevels().then(data=>this.mapping.levels = data),
			this.apiMap.getCategories().then(data=>this.mapping.flutterCategories = data),
			this.apiMap.getTags().then(data=>this.mapping.flutterTags = data),
			super._loadDependencies(),
		])
			.then(()=>{
				this.$scope && this.$scope.$root.$emit('data-ready');
				this.ready = true;
				this.hasApprovedUnpublished = this.data.filter(item=>item.approval_status == 'approved' && !(item.publish || item.publish_status=='published')).length;

				return this.submit();
			});
	}

	async submit($evt, form){
		try {
			await this._submit($evt, form);
		} catch(err) {
			console.log(err);
			this._handleError(err);
		}
		this.isBusy = false;
		this.$scope.$evalAsync();
	}

	async _submit($evt, form){
		if ( ! this.hasApprovedUnpublished ) return;

		this.isBusy = true;
		this.results = this.errors = undefined;

		const cfg = {params: Object.assign({preview:true}, this.query), level:ApiError.LEVEL.MANUAL};

		let res = await this.api.post(`workspaces/${this.batch._id}/publish`, {}, cfg);
		this._process(res.data);
	}

	_process(list){
		this.results = list.sort(Helper.sortByPath('date_to_send'))
			.map(item=>this._processItem(item));
		let byDate = {};
		
		this.results.themes = this.data.filter(item=>item.notification_type==NOTIF_TYPES.TIP_THEME)
			.map(theme=>Object.assign(theme, {tips: []}));
		
		this.results.events = this.results.filter(item=>!item.call_to_action)
			.map(evt=>{
				let d = evt.date_to_send.substr(0,10);
				(byDate[d] || (byDate[d] = [])).push(evt);
				
				evt.reminders = Object.values(evt.reminders || {})
					.map(reminder=>{
						reminder._eventID = evt._id;
						reminder.notification_type = 'reminder';
						let d = reminder.date_to_send.substr(0,10);
						(byDate[d] || (byDate[d] = [])).push(reminder);
						return reminder;
					});
				return evt;
			});

		this.results.tips = this.results.filter(item=>!!item.call_to_action)
			.map(tip=>{
				if ( this.data.byId[tip.tip_theme_id] )
					this.data.byId[tip.tip_theme_id].tips.push(tip);
				else
					console.warn('missing', tip.tip_theme_id);
				let d = tip.date_to_send.substr(0,10);
				(byDate[d] || (byDate[d] = [])).push(tip);
				return tip;
			})
			.sort(Helper.sortByPath('tip_theme'));
		
		this.results.themes = this.results.themes.filter(theme=>theme.tips.length > 0);
		
		this.results.byDate = Object.keys(byDate)
			.map(date=>{
				byDate[date].date = date;
				return byDate[date];
			})
			.sort(Helper.sortBy('date'));

		console.log('results', this.results);
	}
	_processItem(item){
		if ( item.date_to_send )
			item.date_to_send_str = moment(item.date_to_send).format(SETTINGS.dateFormat);
		
		if ( !item.tip_theme ) {
			let end = Helper.parseISO(item.flutter.end_timestamp);
			item.date_to_send_str = end.format(end._hasTime ? SETTINGS.dateTimeFormat : SETTINGS.dateFormat);
			if ( item.flutter.start_timestamp ) {
				let start = Helper.parseISO(item.flutter.start_timestamp);
				let format = start._hasTime ? start.diff(end, 'days')===0 ? SETTINGS.timeFormat : SETTINGS.dateTimeFormat : SETTINGS.dateFormat;
				item.date_to_send_str = start.format(format) +' to '+ item.date_to_send;
			}

			item.reminders = Object.values(item.reminders || {}).sort(Helper.sortBy('date_to_send'));
		}

		item.flutter.tags = Object.keys(item.flutter.tags || {}).map(id=>(this.mapping.flutterTags.byId[id] || {name:{en_US:id}}).name.en_US).join(', ');

		if ( item.flutter.category && this.mapping.flutterCategories.byId[item.flutter.category] )
			item.flutter.category = this.mapping.flutterCategories.byId[item.flutter.category].name.en_US;

		return item;
	}

	_handleError(error){
		if ( this.api.isApiError(error) && /^4\d\d$/.test(error.response.status) ) {
			this.errors = [];
			this.errors.byId = {};
			Object.values(error.response.data?.errors || {}).forEach((err)=>{
				if ( err.field_code == 'publish' ) return;
				let item = this.data.byId[err._id] || this.data.find(v=>v._csv_index == err._csv_index) || null;
				let obj = {
					id: item?._id || '',
					field: FIELDMAP[err.field_code]?.edit || FIELDMAP[err.field_code]?.batch || err.field_code,
					message: err.message,
				};
				this.errors.push(obj);
				let list = this.errors.byId[item?._id || ' '] || [];
				list.push(obj);
				list.type = item ? this.MAPPINGS_JSON.notifications?.type_key?.[item.notification_type] || item.notification_type : ' ';
				this.errors.byId[item?._id || ' '] = list;
			});
		} else {
			this.api.handleError(error);
		}
	}


	publish($ev){
		let item = this.batch;
		if ( this.hasApprovedUnpublished ) {
			let content = `Are you sure you want to publish "${item.file_name}"?`;

			if ( this.batchStatus.num_approved != this.batchStatus.total )
				content = `Are you sure you want to partially publish "${item.file_name}"?`;

			this.$mdDialog.show(
				this.$mdDialog.confirm()
						.title('Confirm Publish Notification Batch')
						.htmlContent(content)
						.ariaLabel('confirm publish')
						.targetEvent($ev)
						.ok('Publish')
						.cancel('Cancel')
			)
			// .then(()=>this.submitPreview())
			.then(()=>{
				this.isBusy = true;
				return this.api.post('workspaces/'+ this.batch._id +'/publish', null, {params:Helper.deepCopy(this.query)});
			})
			.then(()=>{
				this.toast.success(`Workspaces Batch "${item.file_name}" Published`);
				this.promptExit.disable();
				this.$state.go('app.workspaces');
			});
		}
	}

	changeView(){
		$('.notifications-preview-list').scrollTop(0).focus();
	}

}