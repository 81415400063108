import FlowsListController from './flows.list.controller'
import FlowSingleController from './flow.single.controller'
import {Helper, PERMISSIONS, PERMISSION_LEVELS} from '../../common'
import './flows.scss'


export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {

	const $stateRegistry = $uiRouterProvider.stateRegistry;

	$stateRegistry.register({
		name: 'app.flows',
		title: 'Flows',
		url: 'flows?searchString&sortBy&sortOrder&dateFrom&dateTo&colleges&districts&cohorts&terms',
		access: {[PERMISSIONS.READ_FLOW_CONFIGURATIONS]: [PERMISSION_LEVELS.ALL]},
		views: {
			'content@app': {
				template: require('./flows.list.html'),
				controller: FlowsListController,
				controllerAs: 'ctrl',
			},
		},
		dynamic: true,
		params: {
			nonce: {value:()=>Math.random(), dynamic:false},
			scroll:       {value:0, inherit:false, dynamic:true},
			searchString: {type:'query', inherit:false},
			sortBy:       {type:'query', inherit:false},
			sortOrder:    {type:'query', inherit:false},
			dateFrom:     {type:'query', inherit:false},
			dateTo:       {type:'query', inherit:false},
			colleges:     {type:'query', inherit:false, array:true},
			districts:    {type:'query', inherit:false, array:true},
			terms:        {type:'query', inherit:false, array:true},
			cohorts:      {type:'query', inherit:false, array:true},
		},
	});


	let flowSingleHtml = require('./flow.single.html');

	$stateRegistry.register({
		name: 'app.flows.add',
		access: {[PERMISSIONS.CREATE_FLOW_CONFIGURATIONS]: true},
		redirectTo: {state:'app.flows.edit', params:{id:'add'}},
	});

	$stateRegistry.register({
		name: 'app.flows.edit',
		title: ['data', data=>data ? `Flow #${data._id}` : 'Add Flow'],
		url: '/{id:any}?clone',
		access: {[PERMISSIONS.UPDATE_FLOW_CONFIGURATIONS]: true},
		views: {
			'content@app': {
				template: flowSingleHtml,
				controller: FlowSingleController,
				controllerAs: 'ctrl',
			},
		},
		params: {
			retain: {dynamic: true, value: false, inherit:false},
			id: {dynamic: true},
			uploads: {dynamic: true, type: 'any'},
			clone: {dynamic:true, value: undefined, inherit:false},
		},
		onRetain: ['$transition$', '$state$', ($transition$, $state$)=>{
			const params = $transition$.params();

			if ( ! params.retain ) {
				const name = $transition$.to().name;
				const copy = Helper.deepCopy(params);

				// $transition$.onStart({}, transition=>{
				// 	transition.abort();
				// 	return transition.router.stateService.transitionTo(name, copy, {reload:true, inherit:false});
				// });
				$transition$.abort();
				return $transition$.router.stateService.transitionTo(name, copy, {reload:true, inherit:false});
			}

			console.log('retain', params, $transition$);
		}],
		resolve: [{
				provide: 'valid',
				deps: ['$state', '$stateParams', '$q'],
				useFactory: ($state, $stateParams, $q)=>/^(\d+|add)$/.test($stateParams.id) ? $q.resolve(true) : $q.reject('invalid id'),
			}, {
				provide: 'data',
				deps: ['api', '$state', '$stateParams', '$timeout'],
				useFactory: (api, $state, $stateParams, $timeout)=>!/^\d+$/.test($stateParams.id) ? null : api.get('flowConfigurations/'+ $stateParams.id)
					.then((res)=>res.data, (err)=>{
						if ( api.isApiError(err) ) {
							let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
							return $state.go(state, {error: err}, {location:false});
						}
						throw err;
					}),
			}, {
				provide: 'clone',
				deps: ['api', '$state', '$stateParams'],
				useFactory: (api, $state, $stateParams)=>$stateParams.id!='add' || !$stateParams.clone ? null : api.get('flowConfigurations/'+ $stateParams.clone)
					.then(res=>Helper.deepCopy(res.data))
					.catch(err=>{
						if ( api.isApiError(err) ) {
							let state = /^40[34]$/.test(err.response.status) ? `app.${err.response.status}` : 'app.500';
							return $state.go(state, {error: err}, {location:false});
						}
						throw err;
					}),
			}, {
				provide: 'parentStateParams',
				deps: [],
				useFactory: ()=>({}),
		}],
	});

}];
