import angular from 'angular'
import $ from 'jquery'
import BaseList from '../base.list'
import {Helper, TERMS, SETTINGS, OP_TO_WORD, ApiError, PERMISSION_LEVELS} from '../../common'
import {Calendar} from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import '@fullcalendar/core/main.css';

const COLORS = {
	DEFAULT: {light_color: '#A62732', dark_color: '#dcc4ef'},
	'tip_theme': {light_color: '#fff', dark_color: '#F26622'},
}

let hasAllAccess = false;

export default class EventsListController extends BaseList {
	static get $inject(){return [
		'$q',
		'api',
		'apiMap',
		'MAPPINGS_JSON',
		'recipient',
		'authorization',
    '$sce',
	].concat(BaseList.$inject).filter(Helper.uniqueFilter)}

	init(){
		this.mapping = {
			status: Helper.superMap(this.MAPPINGS_JSON.notification.status, {type:'status'}, (list)=>list.single=true),
			categories: Helper.superMap(this.MAPPINGS_JSON.notifications.category_key, {type:'category'}),
			date: Helper.superMap({date: 'anytime'}, {_$groupId:'date'}, (list)=>list.single=true),
			sortBy: Helper.superMap({
				id:'ID', 
				text:'Details', 
				date_to_send:'Date to Send',
				created_by_user_id:'Author',
			}),
			sortOrder: Helper.superMap({asc:'Asc', desc:'Desc'}),
			myterms: Helper.superMap(this.MAPPINGS_JSON.colleges?.term_key || TERMS),
		};
		// this._$baseState = state=>['app.notifications', 'app.notifications.calendarDetail'].includes(state.name);
		this._$baseState = 'app.events';
		this._$resource = 'event';
		
		super.init();

		hasAllAccess = this.hasPermissionLevel(PERMISSION_LEVELS.ALL);

		this.filters._$keys = {
			'status': 'Status',
			// 'types': 'Type',
			'categories': 'Category',
			'mycolleges': 'College',
			'mydistricts': 'District',
			'myterms': 'Term',
			'mycohorts': 'Cohort',
			'mylevels': 'Level',
			'date': 'Date Range',
		};

		this.query = angular.extend({
				date: this.mapping.date[0],
				view: 'list',
			}, this.query);


		this._destructors.push(
			this.$scope.$on('mapFilter.removed', ($e, item)=>{
				if ( item === this.query.date )
					this.query.dateFrom = this.query.dateTo = null;
			}),

			this.$transitions.onStart({to:'app.events.calendarDetail'}, transition=>{
				this.isBusy = false;
				this.detailBusy = true;
			}),
			this.$scope.$on('page-ready', ()=>{
				this.detailBusy = false;
			}),

			// calendar view, notification form
			this.$scope.$on('notification.submitted', ($e, id)=>this.refresh(true).then(()=>this.select(id)))
		);
	}
	_loadDependencies(){
		return this.$q.all([
			super._loadDependencies(),
			this.apiMap.getColleges().then((data)=>this.mapping.mycolleges = data),
			this.apiMap.getDistricts().then((data)=>this.mapping.mydistricts = data),
	    this.apiMap.getProvidedCohorts().then((data)=>this.mapping.mycohorts = data),
			this.apiMap.getAllDistricts().then((data)=>this.mapping.districts = data),
	    this.apiMap.getAllProvidedCohorts().then((data)=>this.mapping.cohorts = data),
	    this.apiMap.getAllCohorts().then((data)=>this.mapping.allcohorts = data),
	    this.apiMap.getCohortLevels().then((data)=>this.mapping.mylevels = data),
	    this.apiMap.getPublicUsers().then((data)=>this.mapping.users = data),
			this.apiMap.getCategories().then(data=>this.mapping.flutterCategories = data),
			this.apiMap.getTags().then(data=>this.mapping.flutterTags = data),
		])
			.then(()=>this.recipient.prepare())
			.then(()=>{
				this.onUrlParamChange(this.$state.params);
				this.refresh(true)
					.then(()=>{
						if ( this.$stateParams.view == 'calendar' ) {
							if ( this.$stateParams.id ) {
								// this.select(+this.$stateParams.id);
							}
						} else {
							this._loadScrollState();
						}
					});

				if ( !this.hasPermissionLevel(PERMISSION_LEVELS.ALL) )
					delete this.filters._$keys.mydistricts;
				if ( this.mapping.mycolleges.length === 1 )
					delete this.filters._$keys.mycolleges;
				if ( this.mapping.mycohorts.length === 0 )
					delete this.filters._$keys.mycohorts;
			});
	}



	getParams(reset){
		let filters = this.getFilters(true),
			params = {
				limit: this.query.limit,
				offset: this.query.offset = (reset ? 0 : this.query.offset),
				publish: true,
				withMetadata:true, 
				myTokenAdministers:true,
				view: undefined, // no need to query view
			};

		if ( filters.view === 'calendar' ) {
			// date filter is overridden for calendar view
			let start = this.calendarDate.clone() || moment().startOf('month');

			params.dateFrom = start.subtract(1, 'months').format('YYYY-MM-DD');
			params.dateTo = start.add(2, 'months').endOf('month').format('YYYY-MM-DD');
			params.limit = undefined;
			params.offset = 0;
			params.sortBy = 'date_to_send';
			params.sortOrder = 'asc';
		}

		return angular.extend({}, filters, params);
	}
	getFilters(all){
		let filters = {
			status: this.filters._status && this.filters._status._id || undefined,
			types: this.filters._types && this.filters._types._id || undefined,
			categories: (this.filters._categories || []).map((item)=>item._id),
			colleges: (this.filters._mycolleges || []).map((item)=>item._id),
			districts: (this.filters._mydistricts || []).map((item)=>item._id),
			terms: (this.filters._myterms || []).map((item)=>item._id),
			cohorts: (this.filters._mycohorts || []).concat(this.filters._mylevels || []).map((item)=>item.name),
			view: this.query.view || 'list',
			// id: this.selected && this.selected._id || undefined,
		};

		if ( this.query.view == 'calendar' ) {
			filters.sortBy = filters.sortOrder = undefined;
		} else {
			delete filters.view;
		}

		return angular.extend(super.getFilters(all), filters);
	}
	onUrlParamChange(obj){
		if ( obj.hasOwnProperty('status') )
			this.filters._status = this.mapping.status.byId[obj.status] || null;
		if ( obj.hasOwnProperty('categories') )
			this.filters._categories = (obj.categories || [])
				.map(id=>this.mapping.categories.byId[id])
				.filter(Helper.trueishFilter);
		if ( obj.hasOwnProperty('colleges') )
			this.filters._mycolleges = (obj.colleges || [])
				.map(id=>this.mapping.mycolleges.byId[id])
				.filter(Helper.trueishFilter);
		if ( obj.hasOwnProperty('districts') )
			this.filters._mydistricts = (obj.districts || [])
				.map(id=>this.mapping.mydistricts.byId[id])
				.filter(Helper.trueishFilter);
		if ( obj.hasOwnProperty('terms') )
			this.filters._myterms = (obj.terms || [])
				.map(id=>this.mapping.myterms.byId[id])
				.filter(Helper.trueishFilter);
		if ( obj.hasOwnProperty('cohorts') ) {
			this.filters._mycohorts = [];
			this.filters._mylevels = [];
			(obj.cohorts || []).forEach(name=>{
				let cohort = this.mapping.allcohorts.byName[name];
				if ( cohort ) {
					if ( cohort.cohort_type === 'level' ) {
						cohort = this.mapping.mylevels.byName[name];
						cohort && this.filters._mylevels.push(cohort);
					} else {
						cohort = this.mapping.mycohorts.byName[name];
						cohort && this.filters._mycohorts.push(cohort);
					}
				}
			});
		}

		if ( obj.hasOwnProperty('view') )
			this.switchView(obj.view || 'list');
		
		if ( obj.hasOwnProperty('id') ) {
			let id = obj.id;
			this.calendarCache?.byId[id] ? this.select(id) : this.$timeout(()=>this._busyRefresh && this._busyRefresh.then(()=>this.select(id)), 100);
		}

		const keys = ['status', 'types', 'categories', 'colleges', 'districts', 'cohorts', 'view'];
		return super.onUrlParamChange(obj) || !! Object.keys(obj).find(v=>keys.includes(v));
	}

	refresh(reset){
		if ( reset ) {
			this.dataListList = [];
			this.itemIndex = 0;
			this._errors = [];
		}
		return this._refresh('events', this.getParams(reset));
	}

	__refresh(url, params, options){
		return super.__refresh(url, params, options).then(res=>{
			if ( res && this.query.view == 'calendar' ) {
				if ( params.offset == 0 )
					this.calendarEvents = [];
				
				let filterStr = JSON.stringify(this.getFilters());
				if ( this.calendarCache._$filterStr != filterStr ) {
					this.calendarCache = {byId:{}};
					this.calendarCache._$filterStr = filterStr;
				}
				this.calendarEvents.push(...this._processForCalendar(res.data.content));
				this.calendar.getEventSources().forEach(src=>src.remove());
				this.calendar.addEventSource(this.calendarEvents);

				if ( this.selected ) {
					let cEvent = this.calendar.getEventById(this.selected._id);
					cEvent && cEvent.setProp('borderColor', this._getColor(this.selected).light_color);
				}

				let cache = this.calendarCache && this.calendarCache[moment(this.calendar.view.currentStart).format('YYYY-MM')];
				this.query.total = cache && cache.length || 0;
			}
			return res;
		});
	}

	refreshPartial(params){
		params = angular.extend(this.getParams(), params, {offset:undefined});
		return this._refresh('events', params);
	}

	process(list){
		let missingUsers = [];

		list.byId = Object.create(null);
		list.forEach(item=>{
			list.byId[item._id] = item;

			this.processItem(item);

			if ( item.created_by_user_id && ! this.mapping.users.byId[item.created_by_user_id] && ! missingUsers.includes(item.created_by_user_id) )
				missingUsers.push( item.created_by_user_id );
			if ( item.modified_by_user_id && ! this.mapping.users.byId[item.modified_by_user_id] && ! missingUsers.includes(item.modified_by_user_id) )
				missingUsers.push( item.modified_by_user_id );
		});

		if ( this.query.view === 'list' ) {
			missingUsers.forEach((id)=>this.api.get('users/'+ id, null, {cache:true, level:ApiError.LEVEL.IGNORED})
				.then(res=>{
					if ( ! this.mapping.users.byId[id] )
						this.mapping.users.push(this.mapping.users.byId[id] = res.data);
				}, angular.noop));
		}

		return list;
	}
	processItem(item){
		let exprs = Object.values(item.recipients || {}),
			itemChips = item._chips = [];
		exprs.forEach(expr=>{
			let chips = this.recipient.enumerate(expr, {ignoreInvalids:true, ignoreErrors:true})
				.filter(item=>item.model);
			// if ( this.mapping.mycolleges.length === 1 ) {
			// 	chips = chips.filter(chip=>chip.type!='college');
			if ( this.mapping.mycolleges.length === 1 || !hasAllAccess ) {
				chips = chips.filter(chip=>chip.type!='college' || (!hasAllAccess && chip._id==item.owner_college_id));
			}
			chips.forEach(chip=>{
				chip.opWord = OP_TO_WORD[chip.op];
				chip.ariaLabel = `${chip.op=='!='?'not in ':''}${chip.type}: ${chip.model.title || chip.model.name || chip._id}`;
			});
			Array.prototype.splice.apply(itemChips, [0,0].concat(chips));
		});

		item.text.raw = item.text.formatted ? Helper.htmlToText(item.text.formatted) : item.text.raw || '';
		item._$href = this.$state.href('app.events.edit', {id: item._id});

		item.reminders = Object.keys(item.reminders || {}).map(key=>{
			let rem = item.reminders[key];
			rem._id = parseInt(key);
			rem.text = rem.text ? this.$sce.trustAsHtml(Helper.toSafeLangHtml(rem.text)) : undefined;
			return rem;
		}).sort(Helper.sortBy('date_to_send'));
		item.reminders._summaries = item.reminders.map(item=>item.date_to_send)
			.map(d=>moment(d).format('M/D'));
		item.reminders._summary = '<span>'+ item.reminders._summaries.join(',</span> <span>') +'</span>';

		// CON2-498; have sending status for unsent on same day
		if ( item.status=='not_sent' && moment(item.date_to_send).isSame(Date.now(),'day') ) {
			item.status = 'Sending';
		}
	}

	_open(item){
		return this.$q.resolve({data: item.reminders});
	}


	switchView(view){
		if ( this.query.view == view ) return;
		this.query.view = view;
		this.unselect();

		if ( view == 'calendar' ) {
			if ( ! this.calendar )
				this._initCalendar([]);
			this.$timeout(()=>this.calendar.updateSize(), 100);
		} else
		if ( view == 'list' ) {
			// this.query.dateFrom = this.query.dateTo = null;
		}

		this.onFiltersChange();
	}

	isCalendarView(){
		return this.query.view=='calendar';
	}

	
	_initCalendar(events){
		this.calendarDate = moment(events.length && events[0].start || new Date()).startOf('month');
		this.calendar = new Calendar($('#calendar').get(0), {
			plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
			defaultView: 'dayGridMonth',
			header: {left:'', center:'', right:''},
			timeZone: 'UTC',
			contentHeight: 'auto',
			// height: 'parent',
			navLinks: false, // can click day/week names to navigate views
			eventLimit: false, // allow "more" link when too many events
			unselectAuto: false,
			selectable: true,
			defaultDate: events.length && events[0].start || new Date(),
			events: events,
			rerenderDelay: 20,
			dateClick: info=>this.calendar.el.focus(),
			eventClick: info=>this.select(info.event.id) && this.calendar.el.focus(),
			eventDrop: info=>this._move(this.dataList.byId[info.event.id], info.delta, info.event),
			eventResize: info=>this._resize(this.dataList.byId[info.event.id], info.startDelta, info.endDelta, info.event),
			eventRender: info=>{
				let item = this.dataList && this.dataList.byId && this.dataList.byId[info.event.id];
				if ( item ) {
					if ( item.flutter && item.flutter.category ) {
						info.el.setAttribute('title', 'Category: '+ this.mapping.flutterCategories.byId[item.flutter.category].name.en_US);
					} else {
						info.el.removeAttribute('title');
					}
					
					info.el.setAttribute('role', 'button');
					info.el.setAttribute('tabindex', '0');
					info.el.setAttribute('href', '#');
					info.el.className = info.el.className.replace('selected', '');
					if ( item === this.selected ) {
						info.el.className += ' selected';
						this._$selectedEventElement = info.el;
					}
				}
			},
			datesRender: info=>{
				this.calendarDate = moment(info.view.currentStart.toISOString().substr(0,10)).startOf('month');
				if ( ! this._$skipCalendarReposition )
					this.calendar.select({start:info.view.currentStart, allDay:true});
				this._$skipCalendarReposition = false;

				let start = info.view.activeStart.toISOString().substr(0,7);
				let current = this.calendarDate.format('YYYY-MM');
				let end = info.view.activeEnd.toISOString().substr(0,7);
				console.log('calendar', current);

				// if ( this.calendarDate.dayOfYear() != this.query.dateFrom?.dayOfYear() ) {
				// 	this.query.dateFrom = this.calendarDate.clone();
				// 	this.onFiltersChange();
				// }
				
				// let today = moment();
				// date.add((date.month() - today.month()) % 3, 'months');
				
				let cache = this.calendarCache && this.calendarCache[current];
				this.calendar.getEventSources().forEach(src=>src.remove());
				if ( cache ) {
					this.calendarEvents = [];
					this.calendarEvents.push(...cache);
					this.query.total = cache.length;

					// start of week is not 1st day of month
					if ( start != current ) {
						if ( this.calendarCache[start] ) {
							console.log('calendar prepend', start, this.calendarCache[start]);
							this.calendarEvents.push(...this.calendarCache[start]);
						} else {
							this.refreshPartial({
								dateFrom: moment(start, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'),
								dateTo: moment(start, 'YYYY-MM').endOf('month').format('YYYY-MM-DD'),
							});
							console.log('get before,', start);
						}
					}
					// end of week is not last day of month
					if ( end != current ) {
						if ( this.calendarCache[end] ) {
							console.log('calendar append', end, this.calendarCache[end]);
							this.calendarEvents.push(...this.calendarCache[end]);
						} else {
							this.refreshPartial({
								dateFrom: moment(end, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'),
								dateTo: moment(end, 'YYYY-MM').endOf('month').format('YYYY-MM-DD'),
							});
							console.log('get after,', end);
						}
					}

					this.calendar.addEventSource(this.calendarEvents);
					if ( this.selected ) {
						let cEvent = this.calendar.getEventById(this.selected._id);
						cEvent && cEvent.setProp('borderColor', this._getColor(this.selected).light_color);
					}
					this.$timeout(()=>this.calendar.updateSize(), 50);
				} else {
					this.refresh()
						.then(()=>this.query.total = this.calendarCache[current] ? this.calendarCache[current].length : 0);
				}
				this.$scope.$evalAsync();
			},
			eventOrder: (a, b)=>{
				if ( this.calendarCache ) {
					let na = this.calendarCache.byId[a.id], 
						nb = this.calendarCache.byId[b.id];
					return this.calendarEvents.indexOf(na) - this.calendarEvents.indexOf(nb);
				} else {
					return a - b;
				}
			},
			eventAllow: (loc, evt)=>{
				let notif = this.dataList && this.dataList.byId[evt.id];
				if ( notif && notif.notification_type === 'tip_theme' ) {
					let max = this._getMaxDayOffset(notif);
					let span = moment(loc.end).diff(loc.start, 'days'); // no need to +1, already inclusive
					if ( span < max ) return false;
				}
				return true;
			},
			eventDragStart: info=>debouncedSelect(info.event.id, {noCalendarSelect:true}),
			eventResizeStart: info=>debouncedSelect(info.event.id, {noCalendarSelect:true}),
			eventDragStop: info=>this.calendar.select({start:info.event.start, end:info.event.end, allDay:true}),
			eventResizeStop: info=>this.calendar.select({start:info.event.start, end:info.event.end, allDay:true}),
		});

		this.calendar.render();
		this.calendarCache = {byId:{}};

		let debouncedSelect = Helper.debounce((id, opts)=>this.select(id, opts), 200);
	}

	_processForCalendar(list){
		let cache = {};
		let events = list.map(item=>{
			let k = item.date_to_send.substr(0, 7),
				evt = this._buildCalendarEvent(item);
			(cache[k] = cache[k] || []).push(evt);
			this.calendarCache.byId[item._id] = item;
			return evt;
		});
		Object.keys(cache).forEach(k=>this.calendarCache[k] = cache[k]);
		return events;
	}

	_buildCalendarEvent(notif){
		let cEvent = {id: notif._id, allDay: true};

		if ( notif.flutter ) {
			let flutter = notif.flutter;
			cEvent.title = flutter.short_name || flutter.name && flutter.name.en_US;

			let dates = this._getCalendarDates(flutter.start_timestamp, flutter.end_timestamp || notif.date_to_send);
			cEvent.start = dates.start;
			cEvent.end = dates.end;
		}

		cEvent.title = cEvent.title || notif.text;

		let color = this._getColor(notif);
		cEvent.textColor = color.light_color;
		cEvent.borderColor = cEvent.backgroundColor = color.dark_color;

		return cEvent;
	}

	_getCalendarDates(start_timestamp, end_timestamp){
		return {
			start: Helper.parseDateTime(start_timestamp || end_timestamp).startOf('day').toDate(),
			end: start_timestamp && end_timestamp ? Helper.parseDateTime(end_timestamp).add(1, 'day').startOf('day').toDate() : null,
		}
	}
	_getColor(notif){
		return angular.extend(
			{}, COLORS.DEFAULT,
			notif && COLORS[notif.notification_type] || {},
			notif && notif.flutter && notif.flutter.category && this.mapping.flutterCategories.byId[notif.flutter.category] || {}
		);
	}

	saveCalendarEdit(){
		this.$q.when(this.$scope.editCtrl?.submitForm('form'))
			.then(()=>this.refresh());
	}


	select(id, opts){
		let notif = this.calendarCache && this.calendarCache.byId[id];
		if ( ! notif ) return;

		let prevSelect = this.selected;
		if ( prevSelect !== notif )
			this.unselect();

		opts = opts || {};
		
		this._$skipFilterListener = true;	
		let params = Helper.deepCopy(this.$state.params);
		params.id = id;
		this.$state.go('app.events.calendarDetail', params, {reload: 'app.events.calendarDetail'})
			.catch(()=>this._select(prevSelect, opts)) // if transition rejected, reselect previously selected
		
		this._select(notif, opts);
		this.$scope.$evalAsync();

		return notif;
	}
	unselect(){
		if ( this._$selectedEventElement )
			this._$selectedEventElement.classList.remove('selected');
		if ( this.selected ) {
			let cEvent = this.calendar.getEventById(this.selected._id);
			if ( cEvent )
				cEvent.setProp('borderColor', this._getColor(this.selected).dark_color);
		}

		this.selected = this._$selectedEventElement = null;
		this.selectedIndex = 0;
	}

	_select(notif, opts){
		if ( ! notif ) return;
		this.selected = notif;
		this.selectedIndex = this.dataList.indexOf(notif);

		if ( ! opts.noCalendarSelect ) {
			let cEvent = this.calendar.getEventById(notif._id);
			if ( cEvent ) {
				cEvent.setProp('borderColor', this._getColor(notif).light_color);
				this._$skipCalendarReposition = true;
				this.calendar.gotoDate(cEvent.start);
				this.calendar.select({start:cEvent.start, end:cEvent.end, allDay:true});
			}
		}
	}

}