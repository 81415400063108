
				<div class="panel-title md-title normal-wg padding-4x">Event Details</div>
				<div ng-include="'recipientGroup.tpl'"></div>

				<div class="panel-content padding-4x" ng-hide="ctrl.mapping.mycolleges.length == 1">
					<div class="flex-gt-sm-65" content-owner="ctrl.model.owner_college_id"></div>
				</div>

				<div class="panel-content">
					<div class="layout-row layout-wrap margin-4x">
						<div class="layout-row layout-wrap flex-100" form-disabled="!ctrl.model._$enable_flutter">
			
							<md-input-container class="flex-100 md-input-has-placeholder">
								<input type="text" name="name" 
									placeholder="Event Title"
									valid-lang
									ng-model="ctrl.model.flutter.name.en_US"
									ng-required="ctrl.model._$enable_flutter && !ctrl.model.flutter.hide_from_flutter"
                  ng-change="ctrl.onDetailsChanged()"
                  > 
								<div ng-messages="form.name.$error" role="alert">
									<div ng-message="required">Required</div>
									<div ng-message="lang-missing">{{ form.name.$error['lang-missing'] }}</div>
									<div ng-message="lang-mismatch">{{ form.name.$error['lang-mismatch'] }}</div>
									<div ng-message="lang">Invalid language tag formatting</div>
								</div>
							</md-input-container>

							<div class="flex-100" toggle-expand="ctrl.model.flutter._$range">
								<section class="expandable">
									<md-input-container class="display-block md-input-has-placeholder" style="width:100%">
										<input type="text" name="end_name" 
											placeholder="Closing Event Title"
											ng-model="ctrl.model.flutter.end_name.en_US">
									</md-input-container>
								</section>
							</div>

							<div class="flex-100">
								<div class="picker-wrap layout-row layout-align-space-between-start">
									<md-input-container class="picker-date padding-0x all-day md-input-has-placeholder" 
										ng-model-options="{debounce:1000}">
										<label>{{ ctrl.model.flutter._$range ? 'Start':'Event' }} Date</label>
										<input type="text" name="start_date"
											date-picker on-picker-update="ctrl.heatmap.updatePicker($picker)"
											valid-date include-time="!ctrl.model.flutter._$allday"
											ng-model="ctrl.model.flutter.start_timestamp" ng-model-options="{updateOn:'blur'}"
											ng-change="ctrl.syncDates()"
											ng-required="ctrl.model._$enable_flutter">
										<div ng-messages="form.start_date.$error" role="alert">
											<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
										</div>
									</md-input-container>
									<md-button type="button" class="md-primary flex-none" aria-label="include time" ng-show="ctrl.model.flutter._$allday && !ctrl.isLocked" ng-click="ctrl.model.flutter._$allday=false; form.start_date.$processModelValue(); form.end_date.$processModelValue(); ctrl.syncDates(); ctrl.focus('[name=\'start_date\']')">Include Time</md-button>
									<md-button type="button" class="md-primary flex-none" aria-label="remove time" ng-hide="ctrl.model.flutter._$allday || ctrl.isLocked" ng-click="ctrl.model.flutter._$allday=true; form.start_date.$processModelValue(); form.end_date.$processModelValue(); ctrl.syncDates()">Remove Time</md-button>
									<i flex></i>
								</div>
								<div class="picker-wrap layout-row layout-align-space-between-start" ng-hide="ctrl.isLocked && !ctrl.model.flutter._$range">
									<md-button type="button" class="md-primary flex-none"  aria-label="add end date"
										ng-hide="ctrl.model.flutter._$range || ctrl.isLocked" 
										ng-click="ctrl.model.flutter._$range=true; ctrl.focus('[name=\'end_date\']')">
											Add End Date{{ ctrl.model.flutter._$allday ? '' : '/Time'}}
									</md-button>
									<md-input-container class="picker-date padding-0x all-day md-input-has-placeholder" 
										ng-show="ctrl.model.flutter._$range" ng-model-options="{debounce:1000}">
										<label>End Date</label>
										<input type="text" name="end_date"
											date-picker on-picker-update="ctrl.heatmap.updatePicker($picker)"
											valid-date include-time="!ctrl.model.flutter._$allday" 
											ng-model="ctrl.model.flutter.end_timestamp" ng-model-options="{updateOn:'blur'}"
											ng-change="ctrl.syncDates()"
											ng-required="ctrl.model._$enable_flutter && ctrl.model.flutter._$range">
										<div ng-messages="form.end_date.$error" role="alert">
											<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
										</div>
									</md-input-container>
									<md-button type="button" class="md-primary flex-none"
										aria-label="remove end date"
										ng-show="ctrl.model.flutter._$range && !ctrl.isLocked"
										ng-click="ctrl.model.flutter._$range=false; ctrl.syncDates();">
											Remove End Date{{ ctrl.model.flutter._$allday ? '' : '/Time'}}
									</md-button>
									<i flex></i>
								</div>
							</div>

							<md-input-container class="flex-100 pop-helper-right md-input-has-placeholder">
								<input type="text" name="location" 
									placeholder="Location" 
									valid-lang
									ng-model="ctrl.model.flutter.location.label.en_US" 
									ng-change="ctrl.onDetailsChanged()">
								<pop-helper ng-cloak>Here, you can include the in-person meeting place or input "virtual" to indicate an online event.</pop-helper>

								<div ng-messages="form.location.$error" role="alert">
									<div ng-message="lang-missing">{{ form.location.$error['lang-missing'] }}</div>
									<div ng-message="lang-mismatch">{{ form.location.$error['lang-mismatch'] }}</div>
									<div ng-message="lang">Invalid language tag formatting</div>
								</div>
							</md-input-container>
						</div>

						<md-input-container class="flex-100 pop-helper-right md-input-has-placeholder">
							<pop-helper id="popup_text" ng-cloak>Here, provide the details for the content you are uploading. MyCoach Tip: We suggest not going over 140 characters.</pop-helper>
							<textarea name="text" 
								placeholder="Event Details"
								aria-describedby="popup_text"
								ng-model="ctrl.model.text.formatted"
								tinymce switch-content-type="true" content-type-model="ctrl.model.text.content" 
								ng-disabled="ctrl.isBusy || ctrl.isLocked" 
								ng-change="ctrl.onDetailsChanged()"
								md-no-resize rows="2" max-rows="8" required></textarea>
							<div ng-messages="form.text.$error" role="alert">
								<div ng-message="required">Required</div>
							</div>
						</md-input-container>
					

						<md-input-container class="flex-100 flex-gt-xs-50 pop-helper-right md-input-has-placeholder">
							<pop-helper id="popup_linktext" ng-cloak>Here, include language that encourages the student to click on the link. MyCoach Tip: Don't use words that mention the senses (see, hear, etc.) to avoid ableism.</pop-helper>
							<input type="text" name="link_text" 
								placeholder="Link Text"
								valid-lang
								aria-describedby="popup_linktext"
								ng-model="ctrl.model.link.text" 
								ng-required="ctrl.model.link.url"
                ng-change="ctrl.onDetailsChanged()"
                >
							<div ng-messages="form.link_text.$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="lang-missing">{{ form.link_text.$error['lang-missing'] }}</div>
								<div ng-message="lang-mismatch">{{ form.link_text.$error['lang-mismatch'] }}</div>
								<div ng-message="lang">Invalid language tag formatting</div>
							</div>
						</md-input-container>
						<md-input-container class="flex-100 flex-gt-xs-50 pop-helper-right md-input-has-placeholder">
							<pop-helper id="popup_linkurl" ng-cloak>Paste the link to the event itself (if it's virtual) or a link to where you want students to go for more information.</pop-helper>
							<input type="url" name="link_url" 
								placeholder="Link URL"
								aria-describedby="popup_linkurl"
								ng-model="ctrl.model.link.url" 
								ng-required="ctrl.model.link.text">
							<div ng-messages="form.link_url.$error" role="alert">
								<div ng-message="required">Required</div>
								<div ng-message="url">Use a valid url format, ex: <em>https://example.com</em></div>
							</div>
						</md-input-container>


						<i flex-gt-xs="50"></i>
					

						<div class="layout-row layout-wrap flex-100" form-disabled="!ctrl.model._$enable_flutter">
							<div class="flex-100 flex-gt-xs-50 layout-column">
								<span class="flex-100 fake-label">Academic Term</span>
								<div class="flex-100 layout-row">
									<md-input-container class="flex-none no-label" md-no-float style="max-width: 100px">
										<md-icon class="far fa-calendar"></md-icon>
										<input type="text" name="term_year"
											inputmode="numeric" pattern="^\d{4}$"
											aria-label="academic term year"
											ng-model="ctrl.model.flutter.term.year"
											ng-required="ctrl.model.flutter.term.season" md-no-asterisk
											ng-focus="form.term_season.$touched=true">
										<div ng-messages="form.term_year.$error" role="alert">
											<div ng-message="required">Required</div>
											<div ng-message="pattern">Invalid year</div>
										</div>
									</md-input-container>
									<md-input-container class="flex-none no-label">
										<md-select name="term_season" 
											aria-label="academic term season"
											ng-model="ctrl.model.flutter.term.season" ng-model-options="{trackBy: '$value'}"
											ng-disabled="ctrl.isBusy || ctrl.isLocked || !ctrl.model._$enable_flutter" 
											ng-required="ctrl.model.flutter.term.year" md-truncate md-no-float md-no-asterisk
											ng-change="ctrl.model.flutter.term.season===null ? (ctrl.model.flutter.term.season=undefined) : null"
											ng-focus="form.term_year.$touched=true">
											<md-option ng-value="null"><i>- none -</i></md-option>
											<md-option ng-repeat="item in ctrl.mapping.seasons" ng-value="item._id">{{item.name}}</md-option>
										</md-select>
										<div class="md-errors-spacer"></div>
										<div ng-messages="form.term_season.$error" role="alert">
											<div ng-message="required">Required</div>
										</div>
									</md-input-container>
									
									<md-button class="flex-none md-icon-button md-warn md-hue-1 margin-0x" 
										ng-click="ctrl.model.flutter.term={}" 
										aria-label="clear term" title="clear term"
										ng-show="!ctrl.isLocked && (ctrl.model.flutter.term.year || ctrl.model.flutter.term.season)">
										<span class="fas fa-times"></span>
									</md-button>
								</div>
							</div>
						</div>

						<md-input-container class="flex-100 flex-gt-xs-33 md-input-has-placeholder">
							<label>Category</label>
							<md-select name="category"
								ng-model="ctrl.model.flutter.category" ng-model-options="{trackBy: '$value'}" 
								ng-change="ctrl.updateCalendarValues(); ctrl.syncDates()"
								ng-required="ctrl.model._$enable_flutter && !ctrl.model.flutter.hide_from_flutter"
								ng-disabled="!ctrl.model._$enable_flutter || ctrl.isBusy || ctrl.isLocked">
								<md-option ng-repeat="item in ctrl.mapping.flutterCategories" ng-value="item._id">{{ item.name.en_US }}</md-option>
							</md-select>
							<div class="md-errors-spacer"></div>
							<div ng-messages="form.category.$error" role="alert">
								<div ng-message="required">Required</div>
							</div>
						</md-input-container>
						<md-input-container class="flex-100 flex-gt-xs-66 md-input-has-placeholder">
							<label>Tags</label>
							<md-select name="tags" aria-label="tags" multiple
								ng-model="ctrl.model.flutter.tags" ng-model-options="{trackBy: '$value'}" 
								ng-change="ctrl.updateCalendarValues(); ctrl.syncDates()"
								ng-disabled="!ctrl.model._$enable_flutter || ctrl.isBusy || ctrl.isLocked">
								<md-option ng-repeat="item in ctrl.mapping.flutterTags" ng-value="item._id">{{ item.name.en_US }}</md-option>
							</md-select>
							<div class="md-errors-spacer"></div>
						</md-input-container>
					</div>

					<div class="layout-row layout-wrap margin-4x" ng-if="mainCtrl.authorization.hasRole('superuser')"><!-- BTC-508 retain hard-coded superuser check -->
						<div class="flex-100 layout-row">
							<md-input-container class="flex-100 flex-gt-xs-33">
								<label>*** Priority</label>
								<md-select name="priority"
									ng-model="ctrl.model.flutter.priority"
									ng-disabled="ctrl.isLocked || ctrl.isBusy || !ctrl.model._$enable_flutter">
									<md-option ng-repeat="(value, name) in ctrl.mapping.priorities" ng-value="+value">{{ name }}</md-option>
								</md-select>
								<div class="md-errors-spacer"></div>
								<div ng-messages="form.priority.$error" role="alert">
									<div ng-message="required">Required</div>
								</div>
							</md-input-container>
							<md-input-container class="flex-none" ng-show="!ctrl.mapping.priorities[ctrl.model.flutter.priority]">
								<label>***</label>
								<input type="number" min="0" max="100" required
									aria-label="Priority" style="width:60px" 
									ng-model="ctrl.model.flutter.priority" 
									ng-disabled="!ctrl.model._$enable_flutter || ctrl.isBusy || ctrl.isLocked">
							</md-input-container>
						</div>

						<div class="layout-row layout-wrap flex-100" form-disabled="!ctrl.model._$enable_flutter">
							<md-input-container class="md-icon-right flex-100 flex-gt-xs-45 padding-0x md-input-has-placeholder">
								<input type="text" name="date_to_send" required
									ng-model="ctrl.model.date_to_send"
									valid-date 
									date-picker on-picker-update="ctrl.heatmap.updatePicker($picker)"
									placeholder="*** Date to Send">
								<div ng-messages="form.date_to_send.$error" role="alert">
									<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
								</div>
							</md-input-container>
							<span flex-gt-xs="5"></span>
							<md-input-container flex="100" flex-gt-xs="50">
								<label>*** Source Timezone</label>
								<md-select name="source_timezone" aria-label="timezone source"
									ng-model="ctrl.model.flutter.source_timezone" ng-model-options="{trackBy: '$value'}" 
									ng-change="ctrl.model.flutter.source_timezone=='global' ? (ctrl.model.flutter._$allday=false) : null"
									ng-disabled="ctrl.isLocked || ctrl.isBusy || !ctrl.model._$enable_flutter">
									<md-option ng-repeat="item in ctrl.mapping.tzSource" ng-value="item._id">{{item.name}}</md-option>
								</md-select>
								<div class="md-errors-spacer"></div>
								<div ng-messages="form.source_timezone.$error" role="alert">
									<div ng-message="required">Required</div>
								</div>
							</md-input-container>
						</div>
						
						<!--
						<div class="hide layout-row layout-wrap flex-100">
							<md-input-container class="flex-100 flex-gt-xs-50 indent">
								<md-switch class="margin-0x" ng-model="ctrl.model._$enable_flutter" ng-change="ctrl.model._$enable_flutter ? ctrl.syncDates() : null" aria-label="Enable Flutter Data" ng-hide="ctrl.isLocked">
									*** Enable Flutter Data
								</md-switch>
							</md-input-container>
							<md-input-container class="flex-100 flex-gt-xs-50 indent" ng-hide="ctrl.isLocked" form-disabled="!ctrl.model._$enable_flutter">
								<md-checkbox ng-model="ctrl.model.flutter.hide_from_flutter" ng-change="ctrl.model.hide_from_flutter ? null : ctrl.syncDates()" aria-label="Hide from Flutter">
									*** Hide from Flutter
								</md-checkbox>
							</md-input-container>
							
							<md-input-container class="flex-100 flex-gt-xs-50" ng-if="ctrl.model.flutter.hide_from_flutter" form-disabled="!ctrl.model._$enable_flutter">
								<label>Reference ID</label>
								<input type="text" aria-label="reference ID" name="ref_id" ng-model="ctrl.model.flutter.ref_id" ng-required="ctrl.model.flutter.hide_from_flutter" ng-pattern="/^\d+$/">
								<div ng-messages="form.ref_id.$error" role="alert">
									<div ng-message="required">Required</div>
									<div ng-message="pattern">Invalid ID</div>
								</div>
							</md-input-container>
						</div>
						-->
					</div>
				</div>

					<div class="panel-content" toggle-expand="ctrl._$open || form.link_text.$invalid || form.link_url.$invalid">
						<div class="more-options padding-4x" ng-click="ctrl._$open = ! ctrl._$open || form.link_text.$invalid || form.link_url.$invalid">
							<span class="fas fa-angle-down pull-right" aria-label="more options" ng-class="{toggled: ctrl._$open}"></span>
							More Options
						</div>
						<section class="expandable">
							<div class="margin-4x layout-row layout-wrap">
								<md-input-container flex="100" class="flex-gt-xs-45 md-input-has-placeholder" form-disabled="!ctrl.model._$enable_flutter">
									<input type="text" name="short_name" 
										placeholder="Short Name for Calendar"
										ng-model="ctrl.model.flutter.short_name" maxlength="6">
									<div ng-messages="form.short_name.$error" role="alert">
										<div ng-message="maxlength">Must be 6 characters or less</div>
									</div>
								</md-input-container>
								<span flex-gt-xs="5"></span>
								<md-input-container flex="100" class="md-input-has-placeholder">
									<label>Old Category</label>
									<md-select name="categories" aria-label="old category" ng-model="ctrl.model.categories" ng-model-options="{trackBy: '$value'}" ng-disabled="ctrl.isBusy || ctrl.isLocked" md-truncate multiple>
										<md-option ng-repeat="item in ctrl.mapping.categories" ng-value="item._id">{{item.name}}</md-option>
									</md-select>
									<div class="md-errors-spacer"></div>
								</md-input-container>
								<md-input-container flex="100" class="md-input-has-placeholder">
									<textarea name="source" rows="4"
										placeholder="Source"
										ng-model="ctrl.model.source"></textarea>
								</md-input-container>
								<md-input-container flex="100" class="md-input-has-placeholder">
									<textarea name="research" rows="4"
										placeholder="Research"
										ng-model="ctrl.model.research"></textarea>
								</md-input-container>
							</div>
						</section>
					</div>