<header id="milestones-list-header" class="md-whiteframe-2dp flex-none flex-order--5">
	<form name="headerForm" novalidate ng-model-options="{updateOn:'default blur', debounce:{'default':500, 'blur':0}}">
		<div class="layout-row layout-align-end-end layout-wrap">
			<h1 id="pageTitle" class="flex-xs-100 md-headline">
				<a ui-sref="^" ui-sref-opts="ctrl.parentStateParams">Tasks  <span class="margin-1x" aria-hidden="true">&#9656;
				</span></a>Batches
			</h1>
			<i class="flex"></i>

			<div class="flex-nogrow">
				<md-input-container>
					<label>Sort</label>
					<md-select name="sort" ng-model="ctrl.query.sortBy" ng-change="ctrl.updateFilters(true)">
						<md-option ng-repeat="item in ctrl.mapping.sortBy" ng-value="item._id"><span ng-bind-html="item.name"></span></md-option>
					</md-select>
				</md-input-container>
				<md-input-container>
					<label>Order</label>
					<md-select name="order" ng-model="ctrl.query.sortOrder" ng-change="ctrl.updateFilters(true)">
						<md-option ng-repeat="item in ctrl.mapping.sortOrder" ng-value="item._id"><span ng-bind-html="item.name"></span></md-option>
					</md-select>
				</md-input-container>
			</div>
		</div>
	</form>
</header>

<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_ERROR -->
<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

<footer class="md-whiteframe-2dp layout-align-start-center layout-row layout-wrap">
	<md-button class="md-icon-button md-mini md-primary" ng-click="ctrl.refresh(true)" aria-label="Refresh list">
		<i class="fas fa-sync-alt"></i>
	</md-button>
	<p class="md-body-1" ng-hide="ctrl.selected">{{ ctrl.query.total }} Results</p>
	<p class="md-body-1" ng-hide="!ctrl.selected || ctrl.isBusy">{{ ctrl.selected.milestones.length }} Tasks</p>
	<i flex></i>
	
	<md-button class="md-primary" ui-sref="^.add">
		<i class="fas fa-plus"></i> <span class="hide-xs">Add</span> New
	</md-button>
	<md-button class="md-primary" ui-sref="^.addbatch">
		<i class="fas fa-plus-square"></i> <span class="hide-xs">Add</span> Batch
	</md-button>
	<!-- <md-button class="md-accent" ng-click="ctrl.exportCSV()">
		<i class="fas fa-file-export"></i> Export <span class="hide-xs">CSV</span>
	</md-button> -->
</footer>

<main class="main-list milestones-batch-list flex flex-order--1 layout-column">
	<md-virtual-repeat-container class="data-list flex no-animate" md-top-index="ctrl.itemIndex" ng-if="ctrl.dataList.length > 0">
		<div id="item-{{ item._id }}" class="data-item milestones-batch-item no-animate" ng-class="{loading: !item}" md-virtual-repeat="item in ctrl" md-on-demand="true">
			<div ng-include="'/milestones.batch.tpl'"></div>
		</div>
	</md-virtual-repeat-container>

	<div class="flex layout-row layout-align-center-center" ng-hide="ctrl.dataList.length > 0 || ctrl.isBusy || ctrl.partialBusy" ng-cloak>
		<p class="text-center alpha-50 md-caption"><em>No results found <span ng-show="ctrl.query.search">for &quot;{{ ctrl.query.search }}&quot;</span></em></p>
	</div>

	<section id="expanded-overlay" class="no-animate" ng-show="!!ctrl.selected && !ctrl.isBusy" layout="column" ng-cloak>
		<div class="data-list flex-none" md-whiteframe="1" ng-if="ctrl.selected" ng-init="item = ctrl.selected">
			<div class="data-item milestones-batch-item">
				<div ng-include="'/milestones.batch.tpl'"></div>
			</div>
			<md-toolbar class="md-info-theme md-primary md-hue-1 md-whiteframe-1dp">
				<div class="md-toolbar-tools layout">
					<h4 class="flex md-title padding-2x normal-wg">Tasks</h4>
					<!-- <md-button class="md-icon-button md-warn md-hue-1" aria-label="Hide" ng-click="ctrl.close()">
						<span class="far fa-times-circle md-title"></span>
					</md-button> -->
				</div>
			</md-toolbar>
		</div>
		
		<div class="data-list flex layout-column indent-2x">
			<md-virtual-repeat-container class="data-list flex no-animate" ng-show="ctrl.selected.milestones && !ctrl.selected.isBusy">
				<div class="data-item milestone-item no-animate" ng-class="{loading: !item}" md-virtual-repeat="item in ctrl.selected.milestones">
					<div ng-include="'milestones.item.tpl'"></div>
				</div>
			</md-virtual-repeat-container>

			<div class="flex layout-row layout-align-center-center no-animate" ng-hide="ctrl.selected.milestones.length>0 || ctrl.selected.isBusy" ng-cloak>
				<p class="text-center alpha-50 md-caption"><em>No results found <span ng-show="ctrl.query.search">for &quot;{{ ctrl.query.search }}&quot;</span></em></p>
			</div>

			<div ng-show="ctrl.selected.isBusy" class="fade-in fade-out flex layout-row layout-align-center-center">
				<md-progress-circular md-mode="indeterminate" md-diameter="40px"></md-progress-circular>
			</div>
		</div>
	</section>
</main>

<script type="text/ng-template" id="/milestones.batch.tpl">
		<div class="layout-row layout-wrap flex">
			<div class="padding-4x flex-none text-right">
				<strong class="md-headline black-66 margin-0x">{{ item.record_count }}</strong>
				<em class="md-caption black-33">records</em>
			</div>
			<div class="padding-4x flex layout-column layout-align-start-stretch">
				<h2 class="md-subhead margin-0x">{{ item.file_name }}</h2>
				<p class="md-body-1 margin-0x black-25">
					<em class="small-tag md-caption black-50">{{ ctrl.mapping.fileStatus.byId[item.status].name || item.status }}</em>
					by <span class="black-50">{{ ctrl.mapping.users.byId[item.created_by_user_id].user_profile.first_name || item.created_by_user_id }}</span>,
					<span class="black-66">{{ item.processed_date | amDateFormat:SETTINGS.dateFormat }}</span>
				</p>
			</div>
			<div class="flex-xs-100 flex-gt-xs-none layout-row layout-align-end-center">
				<md-button type="button" class="data-action black-50" ng-click="item===ctrl.selected ? ctrl.close() : ctrl.open(item)">
					<span ng-hide="item===ctrl.selected">View</span>
					<span ng-show="item===ctrl.selected">Hide</span>
					&nbsp;&nbsp;
					<span class="fas" ng-class="item===ctrl.selected ? 'fa-times-circle' : 'fa-angle-down'"></span>
				</md-button>
			</div>
		</div>
</script>