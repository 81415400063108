<header id="cohorts-list-header" class="md-whiteframe-2dp flex-none flex-order--5">
	<form name="headerForm" novalidate ng-model-options="{updateOn:'default blur', debounce:{'default':500, 'blur':0}}">
		<div class="layout-row layout-align-start-center layout-align-gt-sm-space-between-stretch layout-wrap">
			<h2 id="pageTitle" class="md-headline flex-auto">Cohorts</h2>
			<div class="flex-100 flex-gt-sm-50 layout-row">
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SEARCH -->
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERBTN -->

				<md-sidenav class="filters-nav md-sidenav-right layout-column layout-gt-sm-row layout-gt-sm-wrap layout-align-start-stretch" 
					role="group" aria-labelledby="rightSideNav"
					md-component-id="filters-nav" md-is-locked-open="mainCtrl.$mdMedia('gt-sm')" ng-cloak>
					<b id="rightSideNav" class="md-headline hide-gt-sm">Filters &amp; Sort</b>
					
					<div class="make-right layout-xs-column layout-sm-column">
						<span class="flex-gt-md"></span>
						<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SORTS -->
					</div>
				</md-sidenav>
			</div>
		</div>
	</form>
</header>


<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_ERROR -->
<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->


<footer class="md-whiteframe-2dp layout-align-start-center layout-row layout-wrap">
	<md-button class="md-icon-button md-mini md-primary" 
		aria-label="Refresh results" title="Refresh results"
		ng-click="ctrl.refresh(true)">
		<span class="fas fa-sync-alt"></span>
	</md-button>
	<div id="footerResults" class="md-body-1">
		{{ ctrl.query.total }} Results
	</div>
	<span flex></span>
</footer>

<main class="main-list cohorts-list flex flex-order--1 layout-column"
	aria-labelledby="pageTitle footerResults">
	<md-virtual-repeat-container class="data-list flex no-animate" 
		md-top-index="ctrl.itemIndex" 
		ng-if="ctrl.dataList.length > 0">
		<div id="item-{{ item._id }}" class="data-item cohort-item layout-column no-animate" ng-class="{loading: !item}" 
			md-virtual-repeat="item in ctrl" md-on-demand="true">
			<div class="layout-column flex"
				role="group" aria-label="cohort: {{item.description}}"
				ui-sref="app.cohorts.edit({id: item._id})" ng-include="::'/main/cohorts/cohorts.item.tpl'"></div>
		</div>
	</md-virtual-repeat-container>

	<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_EMPTY_RESULTS -->

</main>


<script type="text/ng-template" id="/main/cohorts/cohorts.item.tpl">
	<div class="flex layout-row layout-wrap">
		<div class="flex layout-row">
			<div class="data-content flex padding-4x layout-column layout-align-space-between-stretch">
				<h3 id="title_item-{{item._id}}" class="md-subhead">
					<a class="md-truncate" ui-sref="app.cohorts.edit({id: item._id})">{{ item.description }}</a>
				</h3>
				<div class="layout-row layout-align-start-end">
					<div class="md-chips md-readonly flex" chips-list="item._chips">
						<md-chip class="flex-none" ng-class="chip._$class" 
							role="img" aria-label="{{::chip.ariaLabel}}" title="{{::chip.ariaLabel}}">
							<i ng-class="chip._$icon" aria-hidden="true"></i> {{chip.title || chip.name}}
						</md-chip>
					</div>
					<md-button class="action-btn md-button pull-left black-50 no-underline" 
						aria-label="manage {{item._$students || ''}} student ids of {{item.description}}"
						ui-sref="app.cohorts.edit.students({id: item._id})" 
						ng-if=":: ctrl.$showActions" 
						ng-click="$event.stopImmediatePropagation();">
						<i class="far fa-id-card"></i> Manage {{ item._$students || '' }} Student IDs
					</md-button>
				</div>
			</div>
			<div class="layout-row padding-1x">
				<md-button class="go-edit flex md-block layout-row layout-align-center-center" 
					aria-label="open cohort: {{item.description}}"
					ui-sref="app.cohorts.edit({id: item._id})">
					&rsaquo;
				</md-button>
			</div>
		</div>
	</div>
</script>
