import {Helper, MESSAGES} from '../../common'

export const mxGraph = require('mxgraph')({
  mxBasePath: '/',
  mxImageBasePath: '/assets',
  mxLoadStylesheets: false,
});

(()=>{ // pre styles
  let mxConstants = mxGraph.mxConstants;

  mxConstants.DEFAULT_FONTFAMILY = 'Roboto, "Helvetica Neue", sans-serif';
  mxConstants.DEFAULT_FONTSIZE = '16px';
  mxConstants.DEFAULT_FONTCOLOR = '#FFFFFF';
  mxConstants.HIGHLIGHT_COLOR = '#00A39C';
  mxConstants.VERTEX_SELECTION_COLOR = '#00A39C';
  mxConstants.VERTEX_SELECTION_STROKEWIDTH = 2;
  mxConstants.EDGE_SELECTION_COLOR = '#b2c1f4';
  mxConstants.EDGE_SELECTION_STROKEWIDTH = 2;
  mxConstants.OUTLINE_HIGHLIGHT_COLOR = '#000000';

  mxConstants.HANDLE_FILLCOLOR = '#99ccff';
  mxConstants.HANDLE_STROKECOLOR = '#0088cf';

  mxConstants.HIGHLIGHT_COLOR = null;
  mxConstants.TARGET_HIGHLIGHT_COLOR = null;
  mxConstants.INVALID_CONNECT_TARGET_COLOR = null;

  // mxConstants.STYLE_ENTRY_PERIMETER = 0;
  // mxConstants.STYLE_EXIT_PERIMETER = 0;
})();

const DEFAULT_WIDTH = 200;
const DEFAULT_HEIGHT = 60;
const DEFAULT_ARROW_TEXT = 'answer';

export class Graph extends mxGraph.mxGraph {

  constructor(container, $evalAsyncFn){
    super(container);

    this.$evalAsync = Helper.debounce($evalAsyncFn, 100);

    this.model.nextId = 100;
    this.model.rootChanged = function(root){
      let old = mxGraph.mxGraphModel.prototype.rootChanged.apply(this, root);
      this.nextId = 100;
      return old;
    }

    let cell = this.model.getCell('0');
    delete this.model.cells[cell.getId()];
    cell.setId('canvas'); this.model.cells[cell.getId()] = cell;
    cell = this.model.getCell('1');
    delete this.model.cells[cell.getId()];
    cell.setId('root'); this.model.cells[cell.getId()] = cell;


    // Disables the built-in context menu
    mxGraph.mxEvent.disableContextMenu(container);

    this._initStyles();


    this.setHtmlLabels(false);
    this.setConnectable(true);
    // this.isWrapping = state=>true;
    this.setGridSize(10);
    this.foldingEnabled = false;
    this.edgeLabelsMovable = false;
    // this.setPortsEnabled(false);
    this.resetEdgesOnMove = false;
    this.resetEdgesOnConnect = true;
    this.setAllowDanglingEdges(true);

    this.setTolerance(12);
    

    // Enables rubberband selection
    this.rubberband = new mxGraph.mxRubberband(this);
    // use keyboard shortcuts
    this.keyHandler = new mxGraph.mxKeyHandler(this);
    


    // Gets the default parent for inserting new cells. This
    // is normally the first child of the root (ie. layer 0).
    let parent = this.getDefaultParent();

    // Adds cells to the model in a single step
    this.model.beginUpdate();
    try {
      let value = {
        text: 'Start',
        connectOutLimit: 1,
        connectInLimit: 0,
        class: 'no-quote start-prompt',
      };
      let cell = this.startPrompt = this.createVertex(parent, 'startPrompt', value, 50, 50, this.snap(DEFAULT_WIDTH * 0.75), DEFAULT_HEIGHT, 'prompt;startPrompt');
      cell.prompt = cell.isHtmlLabel = true;
      this.addCell(cell, parent);

    } finally {
      // Updates the display
      this.model.endUpdate();
      this.undoManager.clear();
    }

  }

  init(container){
    super.init(container);

    this.border = 50;

    const debouncedCheck = Helper.debounce(()=>this.checkForErrors(), 200);

    const listener = (sender, evt)=>{
      let edit = evt.getProperty('edit');
      let significant = edit.significant || edit.changes.find(change=>(!change.cell || !change.cell.trivialChange) && ['mxRootChange', 'mxChildChange', 'mxTerminalChange', 'mxValueChange', 'mxGeometryChange', 'mxCellAttributeChange'].includes(change.constructor.name));
      if ( significant ) {
        edit.significant = true;
        this.undoManager.undoableEditHappened(edit);
        debouncedCheck();
      }
    };
    this.model.addListener(mxGraph.mxEvent.UNDO, listener);
    this.view.addListener(mxGraph.mxEvent.UNDO, listener);

    this.undoManager = new mxGraph.mxUndoManager();

    const revalidate = (g, evt)=>{
      let edit = evt.getProperty('edit');
      let cells = [];
      edit.changes.forEach(change=>{
        if ( change.cell && (change.cell.prompt || change.cell.edge) && !cells.includes(change.cell) ) {
          cells.push(change.cell);

          if ( change.cell && change.cell.edge ) {
            if ( change.cell.source && !cells.includes(change.cell.source) ) {
              cells.push(change.cell.source);
            }
          }
        }
        if ( change.terminal && (change.terminal.prompt || change.terminal.edge) && !cells.includes(change.terminal) )
          cells.push(change.terminal);
      });
      cells.forEach(cell=>{
        if ( cell.prompt ) this.validatePrompt(cell);
        else if ( cell.edge ) this.validateArrow(cell);
      });
    };
    this.undoManager.addListener(mxGraph.mxEvent.UNDO, revalidate);
    this.undoManager.addListener(mxGraph.mxEvent.REDO, revalidate);


    // -- start debug trap for rogue arrow --
    this.undoManager.addListener(mxGraph.mxEvent.ADD, (g, evt)=>{
      let edit = evt.getProperty('edit');
      let found;
      this.getChildEdges().forEach(edge=>{
        // if no connection & no terminal points, disregard
        if ( (!edge.source && !edge.geometry.getTerminalPoint(true)) || (!edge.target && !edge.geometry.getTerminalPoint(false)) ) {
          console.error('ROGUE ARROW FOUND', JSON.stringify(this.toJSON(edge)));
          found = true;
        }
      });
      if ( found ) {
        console.error(edit.redone ? 'redo' : 'undo', '['+ edit.changes.map(change=>{
          return change.constructor.name +'{'+ Object.keys(change).map(k=>{
            if ( change[k] instanceof mxGraph.mxCell ) {
              return `${k}:${change[k].getId()}`;
            } else
            if ( change[k] instanceof mxGraph.mxGraphModel ) {
              // return `${k}: ${change[k].cell.getId()}`;
            } else
            if ( change[k] instanceof mxGraph.mxGeometry ) {
              let geom = change[k];
              return `${k}:{x:${geom.x},y:${geom.y}:w:${geom.width},h:${geom.height}}`;
            } else
            if ( typeof change[k] != 'function' ) {
              if ( change[k] && typeof change[k] == 'object' && change[k].constructor.name == 'Object' ) {
                return `${k}:${JSON.stringify(change[k])}`;
              }
              return `${k}:${change[k]}`;
            }
          }).filter(v=>!!v).join(', ') +'}';
        }).join(', ') +']');
      }
    });
    // -- end debug trap for rogue arrow --


    this.model.valueForCellChanged = (cell, value)=>{
      let res = cell.valueChanged(value);
      if ( cell.prompt ) this.validatePrompt(cell);
      return res;
    };


    this.addListener(mxGraph.mxEvent.CELLS_ADDED, (g,e)=>this._onCellsAdded(g,e));
    this.addListener(mxGraph.mxEvent.CELLS_REMOVED, (g,e)=>this._onCellsRemoved(g,e));
    this.addListener(mxGraph.mxEvent.CELL_CONNECTED, (g,e)=>this._onCellConnected(g,e));
    this.addListener(mxGraph.mxEvent.LABEL_CHANGED, (g,e)=>this._onLabelChanged(g,e));
    this.addListener(mxGraph.mxEvent.EDITING_STARTED, (g,e)=>this._onEditingStarted(g,e));
    this.addListener(mxGraph.mxEvent.EDITING_STOPPED, (g,e)=>this._onEditingStopped(g,e));

    this.selectionCellsHandler.addListener(mxGraph.mxEvent.ADD, (g,e)=>this._onSelectionAdded(g,e));
    this.selectionCellsHandler.addListener(mxGraph.mxEvent.REMOVE, (g,e)=>this._onSelectionRemoved(g,e));
  }
  destroy(){
    this.$evalAsync = null;

    super.destroy();
  }

  _initStyles(){
    let mxConstants = mxGraph.mxConstants;
    let ss = this.getStylesheet();

    // Creates the default style for vertices
    let style = [];
    style[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RECTANGLE;
    style[mxConstants.STYLE_PERIMETER] = mxGraph.mxPerimeter.RectanglePerimeter;
    // style[mxConstants.STYLE_PERIMETER_SPACING] = '-4';
    style[mxConstants.STYLE_STROKEWIDTH] = 1;
    style[mxConstants.STYLE_STROKECOLOR] = '#FFFFFF';
    style[mxConstants.STYLE_ROUNDED] = 1;
    style[mxConstants.STYLE_ARCSIZE] = 20;
    style[mxConstants.STYLE_ABSOLUTE_ARCSIZE] = 1;
    style[mxConstants.STYLE_FILLCOLOR] = '#00A39C';
    style[mxConstants.STYLE_FONTCOLOR] = '#FFFFFF';
    style[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_LEFT;
    style[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_TOP;
    style[mxConstants.STYLE_AUTOSIZE] = 1;
    style[mxConstants.STYLE_WHITE_SPACE] = 'wrap';
    // style[mxConstants.STYLE_SPACING] = 20;
    // style[mxConstants.STYLE_SPACING_LEFT] = 10;
    // style[mxConstants.STYLE_SPACING_RIGHT] = 10;
    style.minWidth = '50px';
    ss.putDefaultVertexStyle(style);

    // Creates the default style for edges
    style = [];
    style[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_CONNECTOR;
    style[mxConstants.STYLE_STROKEWIDTH] = 4;
    style[mxConstants.STYLE_STROKECOLOR] = '#707070';
    style[mxConstants.STYLE_FONTCOLOR] = '#FFFFFF';
    style[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_CENTER;
    style[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_MIDDLE;
    style[mxConstants.STYLE_AUTOSIZE] = 1;
    style[mxConstants.STYLE_SPACING] = 12;
    style[mxConstants.STYLE_EDGE] = 'orthogonalEdgeStyle';
    style[mxConstants.STYLE_JETTY_SIZE] = 20;
    style[mxConstants.STYLE_ENDARROW] = mxConstants.ARROW_BLOCK;
    style[mxConstants.STYLE_FIX_DASH] = 1;
    style[mxConstants.STYLE_MOVABLE] = 0;
    ss.putDefaultEdgeStyle(style);


    style = [];
    style[mxConstants.STYLE_STROKECOLOR] = '#707070';
    style[mxConstants.STYLE_STROKEWIDTH] = 2;
    style[mxConstants.STYLE_FILLCOLOR] = 'white';
    style[mxConstants.STYLE_FONTCOLOR] = 'black';
    style.fontStyle = '2';
    style[mxConstants.STYLE_ALIGN] = 'center';
    style[mxConstants.STYLE_EDITABLE] = 0;
    style[mxConstants.STYLE_MOVABLE] = 0;
    style[mxConstants.STYLE_RESIZABLE] = 0;
    ss.putCellStyle('startPrompt', style);
    


    style = [];
    style[mxConstants.STYLE_PERIMETER] = mxGraph.mxPerimeter.EllipsePerimeter;
    // style[mxConstants.STYLE_PERIMETER_SPACING] = '6';
    style[mxConstants.STYLE_STROKEWIDTH] = 0;
    style[mxConstants.STYLE_STROKECOLOR] = 'none';
    style[mxConstants.STYLE_FILLCOLOR] = 'none';
    style[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_CENTER;
    style[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_TOP;
    style[mxConstants.STYLE_EDITABLE] = 0;
    style[mxConstants.STYLE_MOVABLE] = 0;
    style[mxConstants.STYLE_RESIZABLE] = 0;
    style[mxConstants.STYLE_SPACING] = 0;
    ss.putCellStyle('plusButton', style);

    style = [];
    style[mxConstants.STYLE_PERIMETER] = mxGraph.mxPerimeter.RectanglePerimeter;
    // style[mxConstants.STYLE_PERIMETER_SPACING] = '6';
    style[mxConstants.STYLE_STROKEWIDTH] = 0;
    style[mxConstants.STYLE_STROKECOLOR] = 'none';
    style[mxConstants.STYLE_FILLCOLOR] = '#FF7600';
    style[mxConstants.STYLE_FONTCOLOR] = '#FFFFFF';
    style[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_LEFT;
    style[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_MIDDLE;
    style[mxConstants.STYLE_AUTOSIZE] = 1;
    style[mxConstants.STYLE_EDITABLE] = 0;
    style[mxConstants.STYLE_MOVABLE] = 0;
    style[mxConstants.STYLE_RESIZABLE] = 0;
    style[mxConstants.STYLE_ROUNDED] = 0;
    style[mxConstants.STYLE_SPACING] = 0;
    ss.putCellStyle('errMsg', style);
  }

  createHandlers(){
    this.addMouseListener(this._mouse = {
      mouseDown: (g,v)=>this._onMouseDown(g, v),
      mouseMove: (g,v)=>this._onMouseMove(g, v),
      mouseUp: (g,v)=>this._onMouseUp(g, v),
    });

    super.createHandlers();
  }

  createCellEditor(){ return new CellEditor(this); }
  createConnectionHandler(){ return new ConnectionHandler(this); }
  createVertexHandler(state){ return new VertexHandler(state); }
  // createEdgeHandler(state){ return new EdgeHandler(state); }


  isCellLocked(cell){
    return !cell || cell.locked || super.isCellLocked(cell);
  }
  isCellSelectable(cell){
    return this.cellsSelectable && !this.isCellLocked(cell) && (cell.edge || cell.prompt) && cell!==this.startPrompt;
  }
  isCellMovable(cell){
    return this.cellsMovable && !this.isCellLocked(cell) && cell.prompt && cell!==this.startPrompt;
  }
  isCellResizable(cell){
    return this.cellsResizable && !this.isCellLocked(cell) && (cell.edge || cell.prompt) && cell!==this.startPrompt;
  }
  isCellEditable(cell){
    return this.cellsEditable && !this.isCellLocked(cell) && (cell.edge || cell.prompt) && cell!==this.startPrompt && cell.source !==this.startPrompt;
  }
  isCellConnectable(cell){
    return cell && (cell.edge || cell.prompt) && this.model.isConnectable(cell);
  }

  isWrapping(cell){
    return cell && (cell.prompt || cell.edge);//this.model.isEdge(cell);
  }
  getPreferredSizeForCell(cell, textWidth){
    if ( cell && (cell.prompt || cell.edge) ) {
      if ( this.lastEditorBounds ) {
        return new mxGraph.mxRectangle(0, 0, Math.ceil(this.lastEditorBounds.width/this.gridSize)*this.gridSize, Math.ceil(this.lastEditorBounds.height/this.gridSize)*this.gridSize);
      } else if ( cell.prompt && ! cell.value.text ) {
        return new mxGraph.mxRectangle(0, 0, DEFAULT_WIDTH, DEFAULT_HEIGHT);
      }
    // } else 
    // if ( cell && cell.errMsg === true ) {
    //   // let state = this.model.getState(cell);
    //   return new mxGraph.mxRectangle(0, 0, cell.geometry.width, cell.geometry.height);
    //   // return new mxGraph.mxRectangle(0, 0,)
    }

    return super.getPreferredSizeForCell(cell, textWidth);
  }



  createEdge(parent, id, value, source, target, style) {
    if ( source != this.startPrompt && ! value )
      value = {text: DEFAULT_ARROW_TEXT};

    return super.createEdge(parent, id, value, source, target, style);
  }


  isHtmlLabel(cell){
    return cell.isHtmlLabel;
  }
  getLabel(cell){
    if ( cell.isHtmlLabel ) {
      let p = document.createElement('p');
      let html = Helper.plainLinksToHtml(Helper.encodeHtml(cell.value && cell.value.text || ''), '<span class="link">[$1|$2]</span>');
      p.innerHTML = html;
      if ( cell.value.type )
        p.classList.add(cell.value.type);
      if ( cell.value.class )
        p.classList.add.apply(p.classList, cell.value.class.split(/\s+/));
      if ( cell.edge )
        p.classList.add('dflow-edge-label');
      else if ( cell.vertex )
        p.classList.add('dflow-vertex-label');
      
      if ( cell.errMsg === true && cell.value.type == 'error' ) {
        p.innerHTML = '<i class="fas fa-exclamation-triangle"></i> '+ p.innerHTML;
      }

      if ( cell.prompt ) {
        p.style.maxWidth = cell.geometry.width +'px';

        if ( cell.value.resized ) {
          p.style.maxHeight = cell.geometry.height +'px';
        }
      }
      //   p.style.width = cell.geometry.width +'px';
      return p.outerHTML; // we use html string so autosize works
      // return p;
    }
    return cell.value || '';
  };
  getEditingValue(cell){
    return (cell.isHtmlLabel ? cell.value && cell.value.text : cell.value) || '';
  }
  labelChanged(cell, label, evt){
    if ( cell.isHtmlLabel ) {
      // Clones the user object for correct undo and puts
      var value = mxGraph.mxUtils.clone(cell.value);
      value.text = label.trim();
    
      return super.labelChanged(cell, value, evt);
    }
    return super.labelChanged(cell, label, evt);
  };

  cellLabelChanged(cell, value, autoSize){ // override
    this.model.beginUpdate();
    try {
      if ( autoSize && value && ! (value.text || '').trim() && value.resized )
        delete value.resized;
      this.model.setValue(cell, value);
      
      if ( autoSize ) {
        this.cellSizeUpdated(cell, true); // ignore children bounds
      }
    } finally {
      this.model.endUpdate();
    }
  };
  cellResized(cell, bounds, ignoreRelative, recurse){ // override
    if ( cell.prompt ) {
      bounds.width = Math.max(bounds.width, DEFAULT_WIDTH);
      bounds.height = Math.max(bounds.height, DEFAULT_HEIGHT);
    }

    return super.cellResized(cell, bounds, ignoreRelative, recurse);
  }


  getCellEdgesAsSource(cell){ return (cell.edges || []).filter(edge=>edge.source===cell); }
  getCellEdgesAsTarget(cell){ return (cell.edges || []).filter(edge=>edge.target===cell); }

  getAllConnectionConstraints(terminal, isSrc){
    if ( terminal && terminal.shape && terminal.cell && terminal.cell.vertex ) {
      if ( isSrc && terminal.cell.vertex && !this.connectionHandler.isValidSource(terminal.cell) )
        return null;

      if ( terminal.shape.stencil ) {
        if ( terminal.shape.stencil.constraints ) {
          return terminal.shape.stencil.constraints;
        }
      } else
      // no constraints for target
      // if ( isSrc && terminal.shape.constraints ) {
      if ( terminal.shape.constraints ) {
        return terminal.shape.constraints;
      }
    }
    return null;
  };


  addNewPrompt(x, y, source){
    let obj = {type:'prompt', text:''};
    let w = DEFAULT_WIDTH;
    let h = DEFAULT_HEIGHT;//20 + (this.getStylesheet().getDefaultVertexStyle().spacing || 0)*2;
    
    if ( x === undefined && y === undefined ) {
      let selected = this.getSelectionCells();
      if ( selected.length > 0 ) {
        let lowest, val = 0;
        selected.forEach(cell=>{
          let y = cell.geometry.y + cell.geometry.height;
          if ( y > val ) {
            val = y;
            lowest = cell;
          }
        });
        if ( lowest ) {
          x = lowest.geometry.x;
          y = lowest.geometry.y + lowest.geometry.height + 100;
        }
      }
    }
    x = this.snap((+x || this.container.scrollLeft));// +this.gridSize;
    y = this.snap((+y || this.container.scrollTop));// +this.gridSize;
    
    let parent = this.getDefaultParent();

    // ensure that this change is significant
    this.model.currentEdit = this.model.createUndoableEdit(true);
    // Adds cells to the model in a single step
    this.model.beginUpdate();
    let cell;
    try {
      cell = this.createVertex(parent, null, obj, x, y, w, h, 'prompt;');
      cell.prompt = cell.isHtmlLabel = true;
      this.addCell(cell, parent);

      if ( source ) {
        let edge = this.createEdge(parent, null, {text: source!==this.startPrompt ? DEFAULT_ARROW_TEXT: ''});
        this.addEdge(edge, parent);

        let sourceState = this.view.getState(source);
        let dist2, srcCons, tgtCons;

        mxGraph.mxShape.prototype.constraints.forEach(c1=>mxGraph.mxShape.prototype.constraints.forEach(c2=>{
          let dx = (x + w * c1.point.x) - (sourceState.x + sourceState.width * c2.point.x);
          let dy = (y + h * c1.point.y) - (sourceState.y + sourceState.height * c2.point.y);
          let d2 = dx*dx + dy*dy;

          // if ( (c1.point.x == 0 || c1.point.x == 1) && (c2.point.x == 0 || c2.point.x == 1) ) {
          //   d2 += w*w/4 + sourceState.width*sourceState.width/4;
          // }
          // if ( (c1.point.y == 0 || c1.point.y == 1) && (c2.point.y == 0 || c2.point.y == 1) )
          //   d2 += h*h/4 + sourceState.height*sourceState.height/4;

          if ( ! dist2 || d2 < dist2 ) {
            dist2 = d2;
            tgtCons = c1;
            srcCons = c2;
          }
        }));
        this.connectCell(edge, source, true, srcCons || new mxGraph.mxConnectionConstraint(new mxGraph.mxPoint(0.5, 1), true));
        this.connectCell(edge, cell, false, tgtCons || new mxGraph.mxConnectionConstraint(new mxGraph.mxPoint(0.5, 0), true));
      }
      this.validatePrompt(cell);

    } finally {
      // Updates the display
      this.model.endUpdate();
      
      this.clearSelection();
      this.addSelectionCell(cell);
      this.startEditingAtCell(cell);
    }
    this.model.currentEdit = this.model.createUndoableEdit(true);

    return cell;
  }

  validatePrompt(cell, excludeEdge, strict){
    if ( !cell.prompt ) return;

    let error;

    if ( cell === this.startPrompt ) {
      if ( strict && ! (cell.edges && cell.edges[0] && cell.edges[0].target) ) {
        error = MESSAGES.FLOWS.DYNAMIC.NO_START_NODE;
      }
    } else {
      if ( ! cell.value.text && strict ) {
        error = MESSAGES.FLOWS.DYNAMIC.MISSING_TEXT;
      }
      if ( (cell.edges || []).length == 0 || this.getCellEdgesAsTarget(cell).filter(v=>v !== excludeEdge).length == 0 ) {
        error = MESSAGES.FLOWS.DYNAMIC.PROMPT_DISCONNECT;
      }

      if ( (cell.edges || []).length > 0 ) {
        let edges = this.getCellEdgesAsSource(cell).filter(v=>v !== excludeEdge);
        
        if ( !! edges.find(edge=>edge.value && edge.value.text) ) { // has arrow w/ answer
          if ( !! edges.find(edge=>!edge.value || !edge.value.text) ) { // has arrow w/o answer
            error = MESSAGES.FLOWS.DYNAMIC.PROMPT_ONE_OUT;
          } else {
            let list = edges.map(edge=>edge.value && edge.value.text);
            if ( list.filter((value, i, list)=>list.indexOf(value)!=i || list.lastIndexOf(value)!=i).length > 0 ) {
              error = MESSAGES.FLOWS.DYNAMIC.PROMPT_DUPLICATE_ARROW_LABEL;
            }
          }
        } else
        if ( edges.length > 1 ) {
          error = MESSAGES.FLOWS.DYNAMIC.PROMPT_NOLABEL_ARROW;
        }
      }
    }

    this.setCellError(cell, error);
    this.showCellError(cell, error);

    return error;
  };
  validateArrow(cell, strict){
    if ( !cell || !cell.edge ) return;

    let error;

    if ( ! cell.target || ! cell.source ) {
      error = MESSAGES.FLOWS.DYNAMIC.ARROW_DISCONNECT;
    }

    this.setCellError(cell, error);
    this.showCellError(cell, error);

    return error;
  };

  updatePropertyView(cells){
    let commonColor;
    cells.find(cell=>{
      if ( ! commonColor ) {
        commonColor = cell.value.color || null;
      } else
      if ( commonColor != cell.value.color ) {
        commonColor = null;
        return true;
      }
    });
    this._$fillcolor = commonColor;

    let commonLayout;
    cells.find(cell=>{
      if ( ! commonLayout ) {
        commonLayout = cell.value.layout || null;
      } else
      if ( commonLayout != cell.value.layout ) {
        commonLayout = null;
        return true;
      }
    });
    this._$layout = commonLayout;
    
    if ( cells.length > 0 ) {
      let cell = cells[0];
      this._$comments = cell.value.comments || [];
    }

    this.$evalAsync();
  };


  checkForErrors(strict){
    let cells = this.getChildCells(this.getDefaultParent(), true, true)
      .filter(v=>v.prompt || v.edge);

    if ( strict ) {
      cells.forEach(v=>(v.prompt && this.validatePrompt(v, null, true)) || (v.edge && this.validateArrow(v)));
    }

    let found = !! cells.find(v=>!!(v.errMsg && v.errMsg.value.text));
    if ( found != this.hasError )
      this.$evalAsync();
    return this.hasError = found;
  }
  
  setCellError(cell, msg){
    msg = msg || null;
    if ( cell.errMsg && cell.errMsg.value.text != msg ) {
      let newValue = Helper.deepCopy(cell.errMsg.value);
      newValue.text = msg;

      if ( this.model.updateLevel == 0 )
        this.model.currentEdit = this.model.createUndoableEdit(false);
      this.model.beginUpdate();

      try {
        this.model.setValue(cell.errMsg, newValue);
        this.autoSizeCell(cell.errMsg, false);

        if ( cell.edge ) {
          cell.errMsg.geometry.x = 
          cell.errMsg.geometry.y = !cell.target ? 0.8 : -0.8;
        }
      } finally {
        this.model.endUpdate();
      }
    }

    if ( !!msg && ! this.hasError ) {
      this.hasError = true;
      this.$evalAsync();
    }
  }
  showCellError(cell, value){
    value = !!(value===undefined ? cell.errMsg && cell.errMsg.value.text : value);

    if ( cell.errMsg ) {
      if ( this.model.updateLevel == 0 )
        this.model.currentEdit = this.model.createUndoableEdit(false);
      this.model.beginUpdate();
      this.model.setVisible(cell.errMsg, value);
      this.model.endUpdate();
    }

    return value;
  }


  runHighlightFrom(cell, hover=true){
    let list = [];
    const crawl = cell=>{
      if ( cell && (cell.prompt || cell.edge) && !list.includes(cell) ) {
        list.push(cell);
        if ( cell.prompt ) {
          return this.getCellEdgesAsSource(cell).forEach(edge=>crawl(edge));
        } else
        if ( cell.edge && cell.target ) {
          return crawl(cell.target);
        }
      }
    }
    const apply = ()=>{
      if ( list.length > 0 ) {
        let cell = list.shift(),
          style = cell.getStyle() || '',
          newStyle = style;
        if ( cell.prompt ) {
          newStyle = Helper.modifyStyleString(style, {strokeColor: hover ? '#88CC00' : undefined, strokeWidth: hover ? 4 : undefined});
        } else
        if ( cell.edge ) {
          newStyle = Helper.modifyStyleString(style, {strokeColor: hover ? '#88CC00' : undefined});
        }
        if ( style != newStyle )
          // cell.setStyle(newStyle);
          this.setCellStyle(newStyle, [cell]);
      } else {
        clearInterval(this.runHighlightFrom.iid);
        this.runHighlightFrom.iid = null;
      }
      return list.length > 0;
    };

    crawl(cell);
    // apply(); // run first immediately

    if ( this.runHighlightFrom.iid ) {
      clearInterval(this.runHighlightFrom.iid);
      this.runHighlightFrom.iid = null;
    }
    if ( hover ) {
      this.runHighlightFrom.iid = setInterval(()=>{
        this.model.currentEdit = this.model.createUndoableEdit(false);
        this.model.beginUpdate();
        apply();
        this.model.endUpdate();
      }, 25);
    } else {
      setTimeout(()=>{
        this.model.currentEdit = this.model.createUndoableEdit(false);
        this.model.beginUpdate();
        while(apply());
        this.model.endUpdate();
      }, 0);
    }
  }
  cellIsDownStream(source, target){
    let list = [];
    const crawl = cell=>{
      if ( cell.getId() === target.getId() )
        return true;
      if ( cell && (cell.prompt || cell.edge) && !list.includes(cell) ) {
        list.push(cell);
        if ( cell.prompt ) {
          return this.getCellEdgesAsSource(cell).find(edge=>crawl(edge));
        } else
        if ( cell.edge && cell.target ) {
          return crawl(cell.target);
        }
      }
      return false;
    }
    return crawl(source);
  }


  __showElement(el, value=true){
    el.style.visibility = value ? 'visible' : 'hidden';
  }


  _onMouseDown(graph, evt){
    if ( ! evt.isConsumed() && this.isEnabled() ) {
      let state = this.view.getState(evt.getCell());

      if ( this._mouse.cell ) {
        this.runHighlightFrom(this._mouse.cell, false);
        this._mouse.cell = null;
      }

      if ( state && state.cell.vertex ) {
        let cell = state.cell;
        if ( cell.isPlusButton ) {
          this._mouse.state = state;
          this._mouse.isPlusButton = true;
          evt.consume();
        }
      }
    }
  }
  _onMouseMove(graph, evt){
    if ( evt.isConsumed() || !this.isEnabled() ) return;

    if ( this._mouse.state ) {
      if ( this.isMouseDown && this._mouse.isPlusButton ) {
        let state = this._mouse.state;
        let x = evt.getGraphX(),
          y = evt.getGraphY();

        if ( ! this._mouse.isDragging ) {
          // is outside of radius, 16^2=256
          if ( Math.pow(x - state.x - state.width/2, 2) + Math.pow(y - state.y - state.height/2, 2) > 256 ) {
            this._mouse.isDragging = true;
            
            this._mouse.preview = this.graphHandler.createPreviewShape(new mxGraph.mxRectangle(0,0,1,1));
          }
        } else
        if ( this._mouse.preview ) {
          this._mouse.preview.bounds.setRect(x -DEFAULT_WIDTH/2 >>0, y -DEFAULT_HEIGHT/2 >>0, DEFAULT_WIDTH, DEFAULT_HEIGHT);
          this._mouse.preview.redraw();
        }
        evt.consume();
      }
    } else
    if ( ! this.isMouseDown ) {
      let root = this.getDefaultParent(),
        cell = evt.getCell();
      while( cell && cell.parent !== root )
        cell = cell.parent;

      if ( cell !== this._mouse.cell ) {
        if ( this._mouse.cell ) {
          if ( ! cell || ! this.cellIsDownStream(cell, this._mouse.cell) ) {
            this.runHighlightFrom(this._mouse.cell, false);
          }
        }
        if ( cell && (cell.prompt || cell.edge) && ! this.cellEditor.editingCell ) {
          this.runHighlightFrom(cell, true);
        }
        this._mouse.cell = cell;
      }
    }
  }
  _onMouseUp(graph, evt){
    if ( ! evt.isConsumed() && this.isEnabled() && this._mouse.state ) {
      if ( this._mouse.isPlusButton ) {
        let state = this._mouse.state;
        let source = state.cell.parent,
          offset = this.getCellEdgesAsSource(source).length * this.gridSize;
        let x = evt.getGraphX(),
          y = evt.getGraphY();

        // is inside of radius
        if ( Math.pow(x - state.x - state.width/2, 2) + Math.pow(y - state.y - state.height/2, 2) <= 256 ) {
          x = source.geometry.x +offset;
          y = source.geometry.y +source.geometry.height +100 +offset;
        } else {
          x -= DEFAULT_WIDTH/2;
          y -= DEFAULT_HEIGHT/2;
        }
        this.addNewPrompt(x, y, source);
      }
      this._mouse.state = undefined;
      this._mouse.isPlusButton = undefined;
      this._mouse.isDragging = undefined;

      if ( this._mouse.preview ) {
        this._mouse.preview.destroy();
        this._mouse.preview = null;
      }

      evt.consume();
    }
    // if ( this._mouse.state ) {
    //   let source = this._mouse.state.cell.parent,
    //     offset = this.getCellEdgesAsSource(source).length * this.gridSize;
    //   this.addNewPrompt(source.geometry.x +offset, source.geometry.y + source.geometry.height + 100 + offset, source);
    //   this._mouse.state = null;
    //   evt.consume();
    // }
  }


  _onCellsAdded(sender, evt){
    let edges = evt.properties.cells.filter(c=>c.edge);
    if ( edges.length > 0 ) {
      this.orderCells(true, edges);
    }
    edges.forEach(edge=>{
      edge.isHtmlLabel = true;
      
      let errMsg = edge.errMsg = this.createVertex(null, null, {type:'error', text:'', class:'no-quote err-msg'}, -0.8, -0.8, 200, 20, 'errMsg', true);
      errMsg.geometry.offset = new mxGraph.mxPoint(-24, -20);
      errMsg.geometry.relative = true;
      errMsg.isHtmlLabel = true;
      errMsg.errMsg = true;
      errMsg.visible = false;
      errMsg.trivialChange = true;
      this.addCell(errMsg, edge);

      if ( edge.source === this.startPrompt ) {
        edge.value.text = '';
      }

      this.validateArrow(edge);
    });

    evt.properties.cells.filter(c=>c.prompt).forEach(vertex=>{
      let plusBtn = vertex.plusBtn = this.createVertex(null, null, {text:' ', class:'no-quote fas fa-plus add-btn'}, 1, 1, 32, 32, 'plusButton', true);
      plusBtn.geometry.offset = new mxGraph.mxPoint(-42, -16);
      plusBtn.geometry.relative = true;
      plusBtn.isHtmlLabel = true;
      plusBtn.isPlusButton = true;
      plusBtn.trivialChange = true;
      this.addCell(plusBtn, vertex);

      let errMsg = vertex.errMsg = this.createVertex(null, null, {type:'error', text:null, class:'no-quote err-msg'}, 0, 0, 200, 20, 'errMsg', true);
      errMsg.geometry.offset = new mxGraph.mxPoint(-10, -10);
      errMsg.geometry.relative = true;
      errMsg.isHtmlLabel = true;
      errMsg.errMsg = true;
      errMsg.visible = false;
      errMsg.trivialChange = true;
      this.addCell(errMsg, vertex);

      // validate, could be added w/o connection
      this.validatePrompt(vertex, true);
    });
  }
  _onCellsRemoved(sender, evt){
    evt.properties.cells
      .filter(c=>!!c.edge)
      .forEach(edge=>{
        // deleted edge is connected to start prompt
        if ( edge.source === this.startPrompt ) {
          this.model.beginUpdate();
          this.model.setVisible(this.startPrompt.plusBtn, !this.startPrompt.edges.length);
          this.model.endUpdate();
        }

        if ( edge.source && ! evt.properties.cells.includes(edge.source) )
          this.validatePrompt(this.model.getCell(edge.source.getId()));
        if ( edge.target && ! evt.properties.cells.includes(edge.target) )
          this.validatePrompt(this.model.getCell(edge.target.getId()));
      });
  }
  // triggers for connected, modified or disconnected
  _onCellConnected(sender, evt){
    let change = evt.properties;
    if ( (change.terminal && change.terminal.getId() == this.startPrompt.getId()) || (change.previous && change.previous.getId() === this.startPrompt.getId()) ) {
      this.model.beginUpdate();
      this.model.setVisible(this.startPrompt.plusBtn, !this.startPrompt.edges.length);

      if ( change.edge.value.text ) {
        let copy = Helper.deepCopy(change.edge.value);
        copy.text = '';
        this.model.setValue(change.edge, copy);
      }

      this.model.endUpdate();
    } else
    if ( !change.terminal && change.edge.geometry.targetPoint && change.edge.geometry.sourcePoint ) {
      const geom = change.edge.geometry;
      let diffx = geom.targetPoint.x - geom.sourcePoint.x;
      let diffy = geom.targetPoint.y - geom.sourcePoint.y;
      if ( ! diffx && ! diffy ) {
        if ( change.source ) {
          geom.targetPoint.x += this.gridSize * 4;
        } else {
          geom.targetPoint.x -= this.gridSize * 4;
        }
      }
    }

    this.validateArrow(this.model.getCell(change.edge.getId()));
    if ( change.terminal ) {
      this.validatePrompt(this.model.getCell(change.terminal.getId()));
    };
    if ( change.previous ) {
      this.validatePrompt(this.model.getCell(change.previous.getId()));
    }
  }
  _onLabelChanged(sender, evt){
    let cell = evt.properties.cell;
    if ( cell.prompt ) {
      this.validatePrompt(cell);
    } else
    if ( cell.edge ) {
      if ( cell.source && cell.source.prompt )
        this.validatePrompt(this.model.getCell(cell.source.getId()));
      this.validateArrow(cell);
    }
  }

  _onSelectionAdded(sender, evt){
    this.updatePropertyView(this.getSelectionCells());
  }
  _onSelectionRemoved(sender, evt){
    let state = evt.properties.state;
    // if ( state.cell.prompt )
    //   this.validatePrompt(state.cell);
    
    this.updatePropertyView(this.getSelectionCells());
  }

  _onEditingStarted(sender, evt){
    let cell = this.editingCell = this.cellEditor.editingCell;
    if ( cell ) {
      let state = this.view.getState(cell.prompt ? cell.errMsg : cell);
      if ( state ) {
        cell.prompt && state.shape && state.shape.node && this.__showElement(state.shape.node, false);
        state.text && state.text.node && this.__showElement(state.text.node, false);
      }
    }

    // if ( ! cell.value.resized )
    //   setTimeout(()=>this.cellEditor.textarea.style.height = this.cellEditor.textarea.style.width = '', 1);
    this.lastEditorBounds = null;
  }
  _onEditingStopped(sender, evt){
    let cell = this.editingCell;
    if ( cell ) {
      let state = this.view.getState(cell.prompt ? cell.errMsg : cell);
      if ( state ) {
        state.shape && state.shape.node && this.__showElement(state.shape.node);
        state.text && state.text.node && this.__showElement(state.text.node);
      }
    }
    this.editingCell = undefined;
  }


  export(validate=false){
    let result = {
      nodes: {},
      ui: {},
    };
    let edgeIDs = [];
    let errors = [];

    let procEdge = edge=>{
      let state = this.view.getState(edge);
      if ( ! state || state.invalid ) return null;

      if ( validate && this.validateArrow(edge) && ! errors.includes(edge) ) {
        errors.push(edge);
      }

      // let state = this.view.getState(edge);
      edgeIDs.push(edge.getId());

      return this.toJSON(edge);
    }

    this.getChildVertices()
      .forEach(cell=>{
        if ( cell === this.startPrompt ) {
          if ( cell.edges && cell.edges.length > 0 && cell.edges[0].target ) {
            result.start_node = `node_${cell.edges[0].target.getId()}`;
            result.ui.start_out = procEdge(cell.edges[0]);
          }
          return;
        } else
        if ( cell.prompt ) {
          if ( validate && this.validatePrompt(cell, null, true) && ! errors.includes(cell) ) {
            errors.push(cell);
          }

          let node = result.nodes[`node_${cell.getId()}`] = this.toJSON(cell);
          let outflows = {};

          this.getCellEdgesAsSource(cell)
            .map(edge=>this.view.getState(edge))
            .filter(v=>v)
            .sort((a, b)=>{
              let boxA = a.text.boundingBox,
                boxB = b.text.boundingBox;
              return (boxA.x - boxB.x) || (boxA.y - boxB.y);
            })
            .forEach((state, index)=>{
              let outflow = procEdge(state.cell);
              if ( outflow ) {
                outflow.order = index +1;
                outflows[`outflow_${state.cell.getId()}`] = outflow;
              }
            });

          if ( Object.keys(outflows).length > 0 ) {
            node.outflows = outflows;
          } else
          if ( node.outflows ) {
            delete node.outflow;
          }
        }
      });
    
    // export detached edges as well
    result.ui._detached = this.getChildEdges()
      .filter(cell=>! edgeIDs.includes(cell.getId()))
      .filter(edge=>{
        // if no connection & no terminal points, disregard
        if ( (!edge.source && !edge.geometry.getTerminalPoint(true)) || (!edge.target && !edge.geometry.getTerminalPoint(false)) ) {
          console.error('invalid edge excluded', this.toJSON(edge));
          return false;
        }
        return true;
      })
      .map(edge=>{
        if ( ! errors.includes(edge) )
          errors.push(edge);
        return procEdge(edge);
      });
    if ( result.ui._detached.length == 0 ) delete result._detached;
      
    if ( validate && errors.length > 0 )
      throw errors;

    // var enc = new mxGraph.mxCodec(mxGraph.mxUtils.createXmlDocument());
    // var node = enc.encode(this.getModel());
    // var xml = mxGraph.mxUtils.getXml(node);
    // console.log(xml);

    return Helper.deepCopy(result);
  }

  import(flow){
    delete this.needResave;
    flow = Helper.deepCopy(flow);

    // clear graph
    let cells = this.getChildCells(this.getDefaultParent(), true, true).filter(cell=>(cell.prompt || cell.edge) && this.startPrompt !== cell);
    this.removeCells(cells, false);


    let root = this.getDefaultParent();
    let prompts = [], arrows = [];
    prompts.byKey = {};

    this.getModel().beginUpdate();
    try {
      let outflows = flow.ui && flow.ui._detached || []; // process outflows later

      prompts.byKey.node_startPrompt = this.startPrompt;
      if ( flow.ui && flow.ui.start_out ) {
        flow.ui.start_out.source = this.startPrompt;
        outflows.push(flow.ui.start_out);
      } else
      if ( flow.start_node ) {
        outflows.push({
          endpoint: flow.start_node,
          source: this.startPrompt,
          ui: {},
        });
      }

      Object.keys(flow.nodes || {}).forEach(key=>{
        let node = flow.nodes[key];
        let value = {
          type: 'prompt',
          text: node.message && node.message.content && node.message.content.en_US || '',
          layout: node.layout || undefined,
          color: node.color || undefined,
          comments: node.comments || [],
        };
        node.ui = node.ui || {};
        let x = node.ui.x || 0,
          y = node.ui.y || 0,
          w = node.ui.w || DEFAULT_WIDTH,
          h = node.ui.h || DEFAULT_HEIGHT;

        let style = ['prompt'];
        if ( node.color ) style.push(`fillColor=${node.color}`);

        let id = node.ui.id;
        if ( ! id ) {
          let m = key.match(/^node_(\d+)$/);
          if ( m ) id = m[1];
        }

        let cell = this.createVertex(root, id, value, x, y, w, h, style.join(';'));
        cell.prompt = cell.isHtmlLabel = true;
        // this.addCell(cell, root);

        prompts.push(cell);
        prompts.byKey[key] = cell;

        // process later
        Object.keys(node.outflows || {}).forEach(key=>{
          let outflow = node.outflows[key];
          outflow.ui = outflow.ui || {};
          if ( ! outflow.ui.id ) {
            let m = key.match(/^node_(\d+)$/);
            if ( m ) outflow.ui.id = m[1];
          }
          outflow.source = cell;
          outflows.push(outflow);
        });
      });
      prompts.forEach(cell=>this.addCell(cell, root))
      flow.nodes.node_startPrompt = this.startPrompt;

      outflows = outflows.filter(outflow=>{
        if ( !outflow.ui || !((outflow.ui.source && flow.nodes[outflow.ui.source]) || outflow.ui.sourcePt) || !((outflow.endpoint && flow.nodes[outflow.endpoint]) || outflow.ui.targetPt) ) {
          console.error('invalid outflow excluded', JSON.stringify(outflow));
          this.needResave = true;
          return false;
        }
        if ( ! outflow.source )
          outflow.source = outflow.ui.source && prompts.byKey[outflow.ui.source];
        return true;
      });
      outflows.forEach(outflow=>{
        let value = {
          text: outflow.button && outflow.button.label && outflow.button.label.content && outflow.button.label.content.en_US || undefined,
          comments: outflow.comments || [],
        };
        let edge = this.createEdge(root, outflow.ui && outflow.ui.id || null, value, null, null, outflow.ui.style||'');
        edge = this.addEdge(edge, root, outflow.source||undefined);
        
        if ( outflow.endpoint ) {
          let m = outflow.endpoint.match(/^node_(\d+)$/);
          if ( m ) {
            let target = this.model.getCell(m[1]);
            if ( target )
              this.connectCell(edge, target, false);
          }
        }

        let geom = this.model.getGeometry(edge);
        outflow.ui.sourcePt && geom.setTerminalPoint(new mxGraph.mxPoint(outflow.ui.sourcePt.x, outflow.ui.sourcePt.y), true);
        geom.points = (outflow.ui.points || []).map(p=>p && new mxGraph.mxPoint(p.x, p.y) || undefined);
        outflow.ui.targetPt && geom.setTerminalPoint(new mxGraph.mxPoint(outflow.ui.targetPt.x, outflow.ui.targetPt.y), false);

        this.model.setGeometry(edge, geom);

        arrows.push(edge);
      });

    } finally {
      // Updates the display
      this.getModel().endUpdate();

      setTimeout(()=>{
        prompts.forEach(cell=>this.validatePrompt(cell));
        arrows.forEach(cell=>this.validateArrow(cell));
        this.checkForErrors();
        this.$evalAsync();
      }, 0);

      this.undoManager.clear();
    }
  }


  toJSON(cell){
    if ( cell ) {
      const value = cell.value;

      if ( cell.edge ) {
        return {
          endpoint: cell.target && `node_${cell.target.getId()}` || undefined,
          button: value.text ? {label:{
              content: {en_US: value.text},
              content_type: 'text/x-maliksi-markup',
            }} : undefined,
          ui: {
            id: cell.getId(),
            source: cell.source && `node_${cell.source.getId()}` || undefined,
            points: cell.geometry.points && cell.geometry.points.length > 0 ? cell.geometry.points : undefined,
            sourcePt: cell.geometry.getTerminalPoint(true) || undefined,
            targetPt: cell.geometry.getTerminalPoint(false) || undefined,
            style: cell.style || undefined,
          },
          comments: value.comments || undefined,
        };
      } else
      if ( cell.vertex && cell.prompt ) {
        return {
          message: {
            content: {en_US: value.text},
            content_type: 'text/plain',
          },
          layout: value.layout || undefined,
          color: value.color || undefined,
          ui: {
            id: cell.getId(),
            x: cell.geometry.x >>0,
            y: cell.geometry.y >>0,
            w: cell.geometry.width >>0,
            h: cell.geometry.height >>0,
          },
          comments: value.comments || undefined,
        }
      }
    }
    return null;
  }

}



// Edges have no connection points
mxGraph.mxPolyline.prototype.constraints = null;

// Snaps to fixed points
mxGraph.mxConstraintHandler.prototype.intersects = function(icon, point, source, existingEdge){
  return (!source || existingEdge) || mxGraph.mxUtils.intersects(icon.bounds, point);
};

// Defines the default constraints for all shapes
mxGraph.mxShape.prototype.constraints = [
  new mxGraph.mxConnectionConstraint(new mxGraph.mxPoint(0.25, 0), true, '0.25_0'),
  new mxGraph.mxConnectionConstraint(new mxGraph.mxPoint(0.5, 0), true, '0.5_0'),
  new mxGraph.mxConnectionConstraint(new mxGraph.mxPoint(0.75, 0), true, '0.75_0'),
  new mxGraph.mxConnectionConstraint(new mxGraph.mxPoint(0, 0.25), true, '0_0.25'),
  new mxGraph.mxConnectionConstraint(new mxGraph.mxPoint(0, 0.5), true, '0_0.5'),
  new mxGraph.mxConnectionConstraint(new mxGraph.mxPoint(0, 0.75), true, '0_0.75'),
  new mxGraph.mxConnectionConstraint(new mxGraph.mxPoint(1, 0.25), true, '1_0.25'),
  new mxGraph.mxConnectionConstraint(new mxGraph.mxPoint(1, 0.5), true, '1_0.5'),
  new mxGraph.mxConnectionConstraint(new mxGraph.mxPoint(1, 0.75), true, '1_0.75'),
  new mxGraph.mxConnectionConstraint(new mxGraph.mxPoint(0.25, 1), true, '0.25_1'),
  new mxGraph.mxConnectionConstraint(new mxGraph.mxPoint(0.5, 1), true, '0.5_1'),
  // new mxGraph.mxConnectionConstraint(new mxGraph.mxPoint(0.75, 1), true),
];



export class CellEditor extends mxGraph.mxCellEditor {
  constructor(graph){
    super(graph);

    this.autoSize = false;
    this.blurEnabled = true;

    // this.emptyLabelText = '[type here]';
    this.emptyLabelText = '&nbsp;';
  }

  installListeners(elt){
    super.installListeners(elt);
    mxGraph.mxEvent.addListener(elt, 'paste', evt=>{
      let paste = (evt.clipboardData || window.clipboardData).getData('text');

      const nodes = paste.split(/[\n\r]/g).map(str=>document.createTextNode(str));
      const lastNode = nodes[nodes.length-1];

      // paste = paste.replace(/[\n\r]/g, '<br/>');
      // paste = Helper.stripTags(paste);

      const selection = window.getSelection();
      if ( !selection.rangeCount )
        return false;
      selection.deleteFromDocument();
      let range = selection.getRangeAt(0);
      // let node = document.createTextNode(paste);
      // range.insertNode(node);
      nodes.reverse().forEach((node, i)=>{
        if ( i > 0 )
          range.insertNode(document.createElement('br'));
        if ( node.data )
          range.insertNode(node);
      });

      selection.collapse(lastNode, lastNode.length);

      evt.preventDefault();
    });
  }


  isHideLabel(state){ return false; }

  startEditing(cell, trigger){
    this.autoSize = ! cell.prompt;

    super.startEditing(cell, trigger);

    if ( this.editingCell ) {
      let state = this.graph.view.getState(this.editingCell);
      if ( state && state.text && state.text.node ) {
        state.text.node.classList.add('editing-label');
      }

      this.textarea.classList.add(cell.vertex ? 'vertex' : cell.edge ? 'edge' : null);
      this.textarea.style.backgroundColor = state.style.fillColor;
      
      if ( cell.prompt ) {
        if ( cell.value.resized ) {
          this.textarea.style.maxWidth = state.cellBounds.width +'px';
          // this.textarea.style.maxHeight = state.cellBounds.height +'px';
          this.textarea.style.height = '';
        } else {
          let tmp = document.createElement('div');
          tmp.innerHTML = state.text.value;
          this.graph.container.appendChild(tmp);
          const styles = window.getComputedStyle(tmp.firstChild);
          let lineHeight = styles.lineHeight == 'normal' ? parseFloat(styles.fontSize)*1.2 : parseFloat(styles.lineHeight);
          const isMultiLine = parseFloat(styles.height) > lineHeight + parseFloat(styles.paddingTop) + parseFloat(styles.paddingBottom);
          if ( isMultiLine ) {
            this.textarea.style.maxWidth = (state.text.bounds.width + 2) +'px';
          } else {
            this.textarea.style.minWidth = state.text.bounds.width +'px';
          }
          this.textarea.style.height = '';
          tmp.remove();
        }
      } else {
        this.textarea.style.minWidth = state.text.boundingBox.width +'px';
      }
    }
  }
  stopEditing(cancel){
    if ( this.editingCell ) {
      if ( cancel ) {
        this.graph.lastEditorBounds = null;
      } else {
        this.graph.lastEditorBounds = new mxGraph.mxRectangle(0, 0, Math.max(DEFAULT_WIDTH, this.textarea.clientWidth), Math.max(this.textarea.clientHeight, DEFAULT_HEIGHT));
      }

      let cell = this.editingCell;
      let state = this.graph.view.getState(cell);
      if ( state && state.text && state.text.node ) {
        state.text.node.classList.remove('editing-label');
      }
      this.textarea.classList.remove('vertex');
      this.textarea.classList.remove('edge');
    }
    super.stopEditing(cancel);
  }

  focusLost(){
    // ensure that this change is significant
    this.graph.model.currentEdit = this.graph.model.createUndoableEdit(true);
    this.graph.model.beginUpdate();
    try {
      this.graph.stopEditing(!this.graph.isInvokesStopCellEditing());
    } finally {
      this.graph.model.endUpdate();
    }
  }
}

export class ConnectionHandler extends mxGraph.mxConnectionHandler {
  // can have normal connection
  isConnectableCell(cell){ 
    return false;
    // return this.first && cell && cell.prompt && cell !== this.graph.startPrompt;
  };
  
  // Enables connect preview for the default edge style
  createEdgeState(me){
    let edge = this.graph.createEdge(null, null, null, null, null);
    return new mxGraph.mxCellState(this.graph.view, edge, this.graph.getCellStyle(edge));
  };

  isValidSource(cell, event){
    if ( !this.graph.isMouseDown && cell === this.graph.startPrompt  && this.graph.getCellEdgesAsSource(cell).length > 0 ) {
      return false;
    }
    return cell.prompt && this.graph.isValidSource(cell);
  };
  isValidTarget(cell, event){
    // if ( cell.prompt && cell.value && cell.value.hasOwnProperty('connectInLimit') && this.graph.getCellEdgesAsTarget(cell).length >= cell.value.connectInLimit )
    //   return false;
    return cell.prompt && cell !== this.graph.startPrompt && this.graph.isValidTarget(cell);
  };

  updateEdgeState(pt, constraint){
    if ( pt != null && this.previous != null ) {
      let constraints = this.graph.getAllConnectionConstraints(this.previous, true);
      let dist, nearestConstraint;
      
      if ( constraints ) {
        for (let i = 0; i < constraints.length; i++) {
          let cp = this.graph.getConnectionPoint(this.previous, constraints[i]);
          if ( cp ) {
            let dx = cp.x - pt.x,
              dy = cp.y - pt.y,
              tmp = dx*dx + dy*dy;
        
            if ( ! dist || tmp < dist ) {
              nearestConstraint = constraints[i];
              dist = tmp;
            }
          }
        }
      }
      
      if ( nearestConstraint ) {
        this.sourceConstraint = nearestConstraint;
      }
    }

    super.updateEdgeState(pt, constraint);
  };
}

export class VertexHandler extends mxGraph.mxVertexHandler {
  createSelectionShape(bounds){
    let shape = super.createSelectionShape(bounds);
    shape.redrawShape = ()=>{
      shape.bounds.grow(3);
      mxGraph.mxShape.prototype.redrawShape.call(shape);
      shape.node.firstChild.setAttribute('stroke-dasharray', '4 4');
      shape.node.firstChild.setAttribute('rx', 12);
    }
    return shape;
  }

  resizeCell(cell, dx, dy, index, gridEnabled, constrained, recurse){
    super.resizeCell(cell, dx, dy, index, gridEnabled, constrained, recurse);

    let value = mxGraph.mxUtils.clone(cell.value);
    value.resized = true;
    this.graph.model.beginUpdate();
    this.graph.model.setValue(cell, value);
    this.graph.model.endUpdate();
  }
}

mxGraph.mxEdgeHandler.prototype.virtualBendsEnabled = true;
mxGraph.mxEdgeHandler.prototype.snapToTerminals = true;
mxGraph.mxEdgeHandler.prototype.isCellEnabled = function(cell){
  if ( cell === this.graph.startPrompt && cell.edges && cell.edges.filter(v=>v!==this.state.cell).length > 0 )
    return false;
  return true;
};

export class EdgeHandler extends mxGraph.mxEdgeHandler {
  // createSelectionShape(points){
  //   let shape = super.createSelectionShape(points);
  //   let origRedraw = shape.redraw;
  //   shape.redraw = function(){
  //     origRedraw.call(shape);
  //     // shape.node.firstChild.setAttribute('stroke-dasharray', '4 4');
  //   }
  //   return shape;
  // }
}

export class CellRenderer extends mxGraph.mxCellRenderer {
  // redrawShape(state, force, rendering){
  //   let changed = super.redrawShape(state, force, rendering);
  //   if ( state.cell.prompt ) {
      
  //   }
  
  //   return changed;
  // }
}




