<header class="md-whiteframe-2dp flex-none">
	<form name="headerForm" novalidate ng-model-options="{updateOn:'default blur', debounce:{'default':500, 'blur':0}}">
		<h2 class="md-headline flex-noshrink">Analytics — {{ctrl.title || 'Overview'}}</h2>
		
		<div class="filter-group md-chips md-removable">
			<md-chip class="no-animate mycolleges" ng-repeat="$chip in mainCtrl.filters._mycolleges track by $index" ng-if="!mainCtrl.mapping.mycolleges.single">
				<div class="md-chip-content">
					{{ $chip.title || $chip.name }}
				</div>
				<div class="md-chip-remove-container">
					<button type="button" class="md-chip-remove" ng-click="mainCtrl.filters._mycolleges.remove($chip); ctrl.refresh()" aria-label="remove {{$chip.title || $chip.name}}">
						<span class="far fa-times-circle"></span>
					</button>
				</div>
			</md-chip>
			
			<md-input-container class="filters" md-no-float="true"
				ng-if="mainCtrl.mapping.mycolleges.single"
				ng-show="mainCtrl.filters._$keys.mycolleges && ! mainCtrl.hideFilters">
				<md-select name="mycolleges"
					aria-label="colleges"
					ng-model="mainCtrl.filters._mycolleges" 
					ng-change="ctrl.refresh()"
					md-selected-text="mainCtrl.filters._mycolleges.name || 'Colleges'">
					<md-option ng-repeat="item in mainCtrl.mapping.mycolleges" ng-value="item">{{ item.name }}</md-option>
				</md-select>
			</md-input-container>
			<md-input-container class="filters" md-no-float="true"
				ng-if="!mainCtrl.mapping.mycolleges.single"
				ng-show="mainCtrl.filters._$keys.mycolleges && ! mainCtrl.hideFilters">
				<md-select name="mycolleges" multiple
					aria-label="colleges"
					ng-model="mainCtrl.filters._mycolleges" 
					ng-change="ctrl.refresh()"
					md-selected-text="'Colleges'">
					<md-option ng-repeat="item in mainCtrl.mapping.mycolleges" ng-value="item">{{ item.name }}</md-option>
				</md-select>
			</md-input-container>

			<div class="make-right layout-xs-column layout-sm-column" ng-model-options="{debounce: 1000}" ng-hide="ctrl.hideDateRange">
				<span class="flex-gt-md"></span>

				<md-input-container class="date md-input-has-placeholder flex-xs-50 flex-sm-50">
					<label>From Date</label>
					<input type="text" name="dateFrom" required
						date-picker valid-date 
						ng-model="mainCtrl.dateRange.from"
						ng-change="mainCtrl.dateRange.from && mainCtrl.updateDateRange(true)"
						md-no-asterisk="true">
				</md-input-container>
				<md-input-container class="date md-input-has-placeholder flex-xs-50 flex-sm-50">
					<label>To Date</label>
					<input type="text" name="dateTo" required 
						date-picker valid-date
						ng-model="mainCtrl.dateRange.to"
						ng-change="headerForm.dateTo.$valid && mainCtrl.updateDateRange(true)"
						md-no-asterisk="true">
				</md-input-container>
			</div>
		</div>

</header>

<main id="analytics" class="layout-gt-sm-column flex" ui-view="main" ng-hide="mainCtrl.error"></main>

<div class="fadeInOnly" flex ng-if="mainCtrl.error" error-card="mainCtrl.error" error-card-retry="mainCtrl.filterRefresh()"></div>

<div class="busy-overlay fade layout-row layout-align-center-center flex" ng-show="mainCtrl.isBusy">
	<md-progress-circular md-mode="indeterminate"></md-progress-circular>
</div>