import MonthlyReportController from './monthly-report.controller'

import './monthly-report.scss';


export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {

	const $stateRegistry = $uiRouterProvider.stateRegistry;

	$stateRegistry.register({
		name: 'monthlyReport',
		title: ['$stateParams', $stateParams=>$stateParams.name +' — Monthly Report'],
		url: '/monthly-reports/{name:string}-{id:int}.html',
		views: {
			'root@': {
				template: require('./monthly-report.html'),
				controller: MonthlyReportController,
				controllerAs: 'ctrl',
			},
		},
	});

}];