<header class="md-whiteframe-2dp flex-none layout-row layout-align-space-between-start layout-wrap">
	<h2 id="pageTitle" class="flex-xs-100 md-headline">
		<a ui-sref="^" ui-sref-opts="ctrl.parentStateParams">Flows <span class="margin-1x" aria-hidden="true">&#9656;
		</span></a>{{ ctrl.data ? '' : 'Add New' }}
		<span id="docTitle" ng-show="ctrl.data">{{:: ctrl.data ? 'Flow #'+ ctrl.data._id : 'new flow'}}</span>
		<span role="img" aria-label="changes not saved" title="changes not saved" ng-show="form.$dirty">*</span>
	</h2>
	<div class="top-buttons flex-xs-100" ng-show="ctrl.data" form-disabled="ctrl.isBusy">
		<md-button id="cloneBtn" aria-labelledby="cloneBtn docTitle"
			ui-sref="app.flows.edit({id:'add', clone: ctrl.data._id})"
			ng-if=":: mainCtrl.hasPermission(PERMISSIONS.CREATE_FLOW_CONFIGURATIONS)">
			<span class="fas fa-copy"></span> 
			Clone
		</md-button>
		<md-button class="md-warn" id="deleteBtn" aria-labelledby="deleteBtn docTitle" 
			ng-click="ctrl.delete($event)"
			ng-if=":: mainCtrl.hasPermission(PERMISSIONS.DELETE_FLOW_CONFIGURATIONS)"
			ng-hide="ctrl.isLocked">
			<span class="far fa-trash-alt"></span> 
			Delete
		</md-button>
	</div>

	<div flex="100" ng-include="::'common/lockCard.tpl'"></div>
</header>

<main class="flex">
	<form id="flow-form" name="form" novalidate 
		class="flex-gt-md" ng-class="{locked: ctrl.isLocked, testing: ctrl.chatsim.isRunning}" 
		form-disabled="ctrl.isBusy || ctrl.isLocked"
		ng-submit="!ctrl.isBusy && !ctrl.isLocked && ctrl.submit($event, form)" 
		ng-model-options="{updateOn:'default blur', debounce:{'default':250, 'blur':0}}">
		<input type="submit" class="hide" tabindex="-1">

		<div class="panel-wrap layout-gt-md-row layout-gt-md-wrap">
			<div class="panel-wrap panel-small flex">
				<div class="panel-wrap">
					<div class="panel" md-whiteframe="1">
						<div class="panel-title padding-4x">
							<h3 class="md-title normal-wg margin-0x">Flow Details</h4>
						</div>

						<div ng-include="::'recipientGroup.tpl'"></div>

						<div class="panel-content padding-4x" ng-if="ctrl.mapping.mycolleges.length > 1">
							<div class="flex-gt-sm-65" content-owner="ctrl.model.owner_college_id"></div>
						</div>
	
						<div class="panel-content padding-4x">
							<div>
								<md-input-container class="md-block">
									<md-checkbox ng-model="ctrl.model.show_in_content_directory.show_in_content_directory" ng-show="ctrl.model.show_in_content_directory.show_in_content_directory == false" 
									ng-disabled="ctrl.model.show_in_content_directory.show_in_content_directory == false">Show in Content Directory</md-checkbox>
								</md-input-container>
								
								<section class="expandable" ng-show="ctrl.model.show_in_content_directory.show_in_content_directory" form-disabled="ctrl.data.show_in_content_directory.show_in_content_directory == false">
									<md-input-container class="md-block md-input-has-placeholder">
										<label>Title</label>
										<input type="text" name="cd_title"
											valid-lang
											ng-model="ctrl.model.show_in_content_directory.content_directory.title"
											ng-change="ctrl.onPreviewDetailsChange()"
											ng-required="ctrl.model.show_in_content_directory.show_in_content_directory">
											<div ng-messages="form.cd_title.$error" role="alert">
												<div ng-message="required">Required</div>
												<div ng-message="lang-missing">{{ form.cd_title.$error['lang-missing'] }}</div>
												<div ng-message="lang-mismatch">{{ form.cd_title.$error['lang-mismatch'] }}</div>
												<div ng-message="lang">Invalid language tag formatting</div>
											</div>
									</md-input-container>
									<md-input-container class="md-block md-input-has-placeholder">
										<label>Framing Message</label>
										<textarea name="cd_framing_message" rows="3" md-no-autogrow
											valid-lang
											ng-model="ctrl.model.show_in_content_directory.content_directory.framing_message"
											ng-change="ctrl.onPreviewDetailsChange()"
											ng-required="ctrl.model.show_in_content_directory.show_in_content_directory" 
											ng-disabled="ctrl.data.show_in_content_directory.show_in_content_directory == false">
										</textarea>
										<div ng-messages="form.cd_framing_message.$error" role="alert">
											<div ng-message="required">Required</div>
											<div ng-message="lang-missing">{{ form.cd_framing_message.$error['lang-missing'] }}</div>
											<div ng-message="lang-mismatch">{{ form.cd_framing_message.$error['lang-mismatch'] }}</div>
											<div ng-message="lang">Invalid language tag formatting</div>
										</div>
									</md-input-container>

									<div class="layout-gt-xs-row">
										<md-input-container class="flex-gt-xs-45 padding-0x md-input-has-placeholder" 
											ng-model-options="{debounce: 1000}">
											<label>Release Date</label>
											<md-icon class="far fa-calendar"></md-icon>
											<input type="text" name="cd_release_date"
												ng-required="ctrl.model.show_in_content_directory.show_in_content_directory"
												date-picker
												valid-date include-time="::false"
												ng-model="ctrl.model.show_in_content_directory.content_directory.release_date">
											<div ng-messages="form.cd_release_date.$error" role="alert">
												<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
											</div>
										</md-input-container>
										<i class="flex-gt-xs-10"></i>
										<md-input-container class="flex-gt-xs-45 padding-0x md-input-has-placeholder" 
											ng-model-options="{debounce: 1000}">
											<label>Deadline</label>
											<md-icon class="far fa-calendar"></md-icon>
											<input type="text" name="cd_deadline"
												date-picker
												ng-required="ctrl.model.show_in_content_directory.show_in_content_directory"
												valid-date include-time="::false" 
												ng-model="ctrl.model.show_in_content_directory.content_directory.deadline.date">
											<div ng-messages="form.cd_deadline.$error" role="alert">
												<!-- directive: use-template TEMPLATES.COMMON.VALID_DATE_MSGS -->
											</div>
										</md-input-container>
									</div>

									<md-input-container class="md-block md-input-has-placeholder">
										<label>Tags</label>
										<md-select name="cd_tags" aria-label="tags" role="listbox" multiple
											ng-model="ctrl.model.show_in_content_directory.content_directory.tags" 
											ng-disabled="ctrl.data.show_in_content_directory.show_in_content_directory == false"
											ng-model-options="{trackBy: '$value'}">
											<md-option ng-repeat="item in ctrl.mapping.flutterTags" ng-value="item._id">{{ item.name.en_US || item.name }}</md-option>
										</md-select>
										<div class="md-errors-spacer"></div>
									</md-input-container>

									<div class="layout-row">
										<md-input-container class="md-block flex no-animate right-action-btn md-input-has-placeholder" ng-hide="ctrl.model.show_in_content_directory.content_directory.image_url__file">
											<label>Image URL</label>
											<input type="url" class="md-input" name="cd_image_url" 
												ng-model="ctrl.model.show_in_content_directory.content_directory.image_url" 
												ng-change="ctrl.model.show_in_content_directory.content_directory.image_small_url = ctrl.model.show_in_content_directory.content_directory.image_url"
												ng-disabled="ctrl.data.show_in_content_directory.show_in_content_directory == false">
											<md-button class="md-icon-button md-warn md-hue-1 compact" ng-click="ctrl.model.show_in_content_directory.content_directory.image_url = ctrl.model.show_in_content_directory.content_directory.image_small_url = ''" for="cd_image_url" aria-label="Clear image">
												<span class="fas fa-times"></span>
											</md-button>
											<div ng-messages="form.cd_image_url.$error" role="alert">
												<div ng-message="url">Invalid URL format (ie: https://example.com/image.jpeg)</div>
											</div>
										</md-input-container>
									 
		
										<md-input-container class="md-block flex-nogrow no-animate md-input-has-placeholder" ng-hide="ctrl.isLocked">
											<md-button type="file" name="image_url_file" class="md-primary compact"
												ngf-select accept="image/*" ngf-pattern="'image/*'" 
												ng-disabled="ctrl.isBusy || ctrl.data.show_in_content_directory.show_in_content_directory == false"
												ngf-multiple="false" ngf-keep="true"
												ngf-change="$newFiles.length ? (ctrl.model.show_in_content_directory.content_directory.image_url__file = $newFiles.pop()) : null;">
												<span class="fas fa-file-upload"></span> Select File
											</md-button>
										</md-input-container>
		
										<md-input-container class="md-block flex right-action-btn no-animate md-input-has-placeholder" ng-show="ctrl.model.show_in_content_directory.content_directory.image_url__file" ng-cloak>
											<label>Image</label>
											<input type="text" class="md-input" ng-value="ctrl.model.show_in_content_directory.content_directory.image_url__file.name" ng-disabled="true">
											<md-button class="md-icon-button md-warn md-hue-1 compact" aria-label="remove file" ng-click="ctrl.model.show_in_content_directory.content_directory.image_url__file = undefined;">
												<span class="fas fa-times"></span>
											</md-button>
										</md-input-container>
									</div>
									<md-input-container class="md-block flex no-animate md-input-has-placeholder" ng-show="ctrl.model.show_in_content_directory.content_directory.image_url || ctrl.model.show_in_content_directory.content_directory.image_url__file">
										<label>Image Alt Text</label>
										<input type="text" class="md-input" name="cd_image_alt_text" ng-model="ctrl.model.show_in_content_directory.content_directory.image_alt_text" minlength="5" ng-required="ctrl.model.show_in_content_directory.content_directory.image_url">
										<div ng-messages="form.cd_image_alt_text.$error" role="alert">
											<div ng-message="required">Required</div>
											<div ng-message="minlength">Minimum 5 characters</div>
										</div>
									</md-input-container> 

									<md-input-container class="md-block md-input-has-placeholder">
										<label>Background Color</label>
										<md-select name="cd_color" md-container-class="task-color-select" class="color-select"
											ng-model="ctrl.background_color_label"
											ng-disabled="ctrl.isBusy || ctrl.isLocked || ctrl.model.show_in_content_directory.show_in_content_directory == false"
											ng-change="ctrl.onChange(ctrl.background_color_label)">
											<md-option md-option-empty ng-value="'none'">None</md-option>
											<md-option ng-repeat="color in ctrl.mapping.bgColors track by $index" ng-value="color.label">
												<span class="color-hex">{{ color.value }}</span> <span class="color" ng-style="{'background-color': color.value}"></span> {{ color.label }}
											</md-option>
										</md-select>
										<div class="md-errors-spacer"></div>
									</md-input-container>

								</section>
							</div>
						</div>
						<div class="panel-content padding-4x">
							<md-input-container class="md-block md-input-has-placeholder">
								<label>Name</label>
								<input type="text" name="name" ng-model="ctrl.model.name" required md-autofocus>
								<div ng-messages="form.name.$error" role="alert">
									<div ng-message="required">Required</div>
								</div>
							</md-input-container>
							<div ng-form="subform">
								<md-input-container class="md-block md-input-has-placeholder">
									<label>Type</label>
									<md-select name="type" ng-model="ctrl.model.type" ng-change="ctrl.typeChanged($event)" ng-disabled="ctrl.isBusy || ctrl.isLocked" required>
										<md-option ng-repeat="item in ctrl.mapping.flowTypes" ng-value="item.type">{{ item.name }}</md-option>
									</md-select>
									<div class="md-errors-spacer"></div>
									<div ng-messages="subform.type.$error" role="alert">
										<div ng-message="required">Required</div>
									</div>
								</md-input-container>

								<md-input-container class="md-block md-input-has-placeholder">
									<label>Color</label>
									<md-select name="color" md-container-class="flow-color-select" class="color-select"
										ng-model="ctrl.model.color" ng-disabled="ctrl.isBusy || ctrl.isLocked"
										ng-change="ctrl.model.color=='none' ? (ctrl.model.color=undefined) : null; ctrl.graphUpdateColor(ctrl.model.color)">
										<md-option ng-value="ctrl.model.color" ng-if="ctrl.model.color && !ctrl.mapping.flowColors.includes(ctrl.model.color)">
											{{ ctrl.model.color }} <span class="color" ng-style="{'background-color': ctrl.model.color}"></span>
										</md-option>
										<md-option md-option-empty ng-value="'none'">None</md-option>
										<md-option ng-repeat="color in ctrl.mapping.flowColors" ng-value="color">
											{{ color }} <span class="color" ng-style="{'background-color': color}"></span>
										</md-option>
									</md-select>
									<div class="md-errors-spacer"></div>
									<div ng-messages="form.type.$error" role="alert">
										<div ng-message="required">Required</div>
									</div>
								</md-input-container>

								<md-input-container class="md-block md-input-has-placeholder" ng-hide="!ctrl.model.type || ['alert','quiz','dynamic_flow'].includes(ctrl.model.type)">
									<label>Layout</label>
									<md-select name="layout" ng-model="ctrl.model.layout" ng-disabled="ctrl.isBusy || ctrl.isLocked">
										<md-option ng-repeat="(key, value) in ctrl.mapping.layouts" ng-value="value">{{ key }}</md-option>
									</md-select>
									<div class="md-errors-spacer"></div>
								</md-input-container>
							</div>
						</div>
						<div class="panel-content padding-4x indent fade-in fade-out" form-disabled="ctrl.model.show_in_content_directory.schedule.fire_on_app_open" ng-show="ctrl.model.show_in_content_directory.show_in_content_directory == false">
							<span class="md-caption black-75">Trigger for </span>
							<md-input-container class="md-block indent">
								<md-checkbox ng-model="ctrl.model.show_in_content_directory.schedule.fire_on_student_registration" ng-disabled="!ctrl.model.show_in_content_directory.show_in_content_directory" ng-change="ctrl.updateTrigger()" aria-label="Trigger for Newly Registered Students">
									Newly Registered Students
								</md-checkbox>
							</md-input-container>
							<md-input-container class="md-block indent no-label">
								<md-checkbox ng-model="ctrl.model.show_in_content_directory.schedule.fire_on_student_registration_with_coach" 
								 ng-change="ctrl.updateTrigger()" ng-disabled="!ctrl.model.show_in_content_directory.show_in_content_directory"
								 aria-label="Trigger for Newly Registered Students with Coach">
									Newly Registered Students with Coach
								</md-checkbox>
							</md-input-container>
							<md-input-container class="md-block indent no-label">
								<md-checkbox ng-model="ctrl.model.show_in_content_directory.schedule.fire_on_student_registration_with_coach_with_partner_landing_page" ng-change="ctrl.updateTrigger()" 
								ng-disabled="!ctrl.model.show_in_content_directory.show_in_content_directory" 
								aria-label="Trigger for Newly Registered Students with Coach with Partner Landing Page">
									Newly Registered Students with Coach with Partner Landing Page
								</md-checkbox>
							</md-input-container>
							<md-input-container class="md-block indent no-label">
								<md-checkbox ng-model="ctrl.model.show_in_content_directory.schedule.fire_on_student_registration_with_coach_without_partner_landing_page" ng-change="ctrl.updateTrigger()" 
								ng-disabled="!ctrl.model.show_in_content_directory.show_in_content_directory"
								aria-label="Trigger for Newly Registered Students with Coach without Partner Landing Page">
									Newly Registered Students with Coach without Partner Landing Page
								</md-checkbox>
							</md-input-container>
							<md-input-container class="md-block indent no-label">
								<md-checkbox ng-model="ctrl.model.show_in_content_directory.schedule.fire_on_student_registration_without_coach" 
								ng-disabled="!ctrl.model.show_in_content_directory.show_in_content_directory"
								ng-change="ctrl.updateTrigger()" aria-label="Trigger for Newly Registered Students without Coach">
									Newly Registered Students without Coach
								</md-checkbox>
							</md-input-container>
							<md-input-container class="md-block indent no-label">
								<md-checkbox ng-model="ctrl.model.show_in_content_directory.schedule.fire_on_student_registration_without_coach_with_partner_landing_page" ng-change="ctrl.updateTrigger()" 
								ng-disabled="!ctrl.model.show_in_content_directory.show_in_content_directory"
								aria-label="Trigger for Newly Registered Students without Coach with Partner Landing Page">
									Newly Registered Students without Coach with Partner Landing Page
								</md-checkbox>
							</md-input-container>
							<md-input-container class="md-block indent no-label">
								<md-checkbox ng-model="ctrl.model.show_in_content_directory.schedule.fire_on_student_registration_without_coach_without_partner_landing_page" ng-change="ctrl.updateTrigger()" 
								ng-disabled="!ctrl.model.show_in_content_directory.show_in_content_directory"
								aria-label="Trigger for Newly Registered Students without Coach without Partner Landing Page">
									Newly Registered Students without Coach without Partner Landing Page
								</md-checkbox>
							</md-input-container>
							<!-- <p class="text-center smaller black-50">&mdash;&mdash;	<em>or</em> &mdash;&mdash;</p>
							<md-input-container class="md-block">
								<md-checkbox ng-model="ctrl.model.show_in_content_directory.schedule.fire_on_app_open" ng-change="ctrl.updateTrigger()" aria-label="Trigger on App Open" ng-disabled="ctrl.model.show_in_content_directory.schedule.fire_on_student_registration || ctrl.model.show_in_content_directory.schedule.fire_on_student_registration_with_coach || ctrl.model.show_in_content_directory.schedule.fire_on_student_registration_without_coach">
									Trigger on App Open
								</md-checkbox>
							</md-input-container> -->
							<p class="text-center smaller black-50">&mdash;&mdash;	<em>or</em> &mdash;&mdash;</p>
							<div class="layout-row layout-wrap" form-disabled="ctrl.model.show_in_content_directory.schedule._$fire || !ctrl.model.show_in_content_directory.show_in_content_directory">
								<md-input-container class="flex-xs-100 flex-gt-sm-50 padding-sides-0x md-input-has-placeholder" 
									ng-model-options="{debounce: 1000}">
									<label>Schedule</label>
									<md-icon class="far fa-calendar"></md-icon>
									<input type="text" name="schedule"
										date-picker
										valid-date include-time="::true"
										ng-model="ctrl.model.show_in_content_directory.schedule.start_date"
										ng-required="!ctrl.model.show_in_content_directory.schedule._$fire && ctrl.model.show_in_content_directory.show_in_content_directory == false"
										ng-model-options="{updateOn:'blur'}">
									<div ng-messages="form.schedule.$error" role="alert">
										<div ng-message="format">Invalid Format (eg Jan 1, 2020)</div>
										<div ng-message="parse">Invalid Date (eg Jan 1, 2020)</div>
										<div ng-message="date">Invalid Date Value</div>
									</div>
								</md-input-container>

								<span class="flex-gt-sm-5 show-gt-sm"></span>
								
								<md-input-container class="flex-xs-100 flex-gt-sm-45 md-input-has-placeholder">
									<label>Time Zone</label>
									<md-select
										name="timezone"
										ng-required="!ctrl.model.show_in_content_directory.schedule._$fire && !ctrl.model.show_in_content_directory.show_in_content_directory"
										data-md-container-class="select-with-search"
										ng-model="ctrl.model.show_in_content_directory.schedule.timezone"
										md-on-close="ctrl.model.show_in_content_directory.schedule.timezone && (ctrl._$timezones = ctrl.queryTimezone(ctrl._$tz='', ctrl.model.show_in_content_directory.schedule.timezone))">
										<md-select-header>
											<input type="search" class="header-search" placeholder="Search others" autocomplete="off"
												aria-label="search"
												ng-keydown="$event.stopImmediatePropagation()"
												ng-click="$event.stopImmediatePropagation()"
												ng-model-options="{debounce:{'default':300, 'blur':0}, '*':'$inherit'}"
												ng-model="ctrl._$tz" ng-change="ctrl._$timezones = ctrl.queryTimezone(ctrl._$tz, ctrl.model.show_in_content_directory.schedule.timezone)">
										</md-select-header>
										<md-option ng-value="value"" ng-repeat="value in ctrl._$timezones">{{ value }}</md-option>
										<md-option ng-if="!ctrl._$timezones.length" disabled="disabled"><em>No results</em></md-option-group>
									</md-select>
									<div ng-messages="form.timezone.$error" ng-if="form.timezone.$touched">
										<div ng-message="required">Required</div>
										<div ng-message="md-require-match">Invalid time zone</div>
									</div>
								</md-input-container>

							</div>
						</div>
					</div>

					<section ng-if="ctrl.model.type=='greeting' || ctrl.model.type=='alert'" ng-form="flow" class="panel no-animate" md-whiteframe="1">
						<div class="panel-title padding-4x">
							<h3 class="md-title normal-wg margin-0x">{{ ctrl.model.type | camelCase }}</h3>
						</div>
						<div class="panel-content padding-4x">
							<!-- <h4 class="md-subhead normal-wg margin-0x padding-2x">Greeting Flow</h4> -->
							<md-input-container class="md-block md-input-has-placeholder">
								<label>Message</label>
								<textarea name="message" required rows="4"
									valid-lang
									ng-model="ctrl.model.flow.message"
									ng-change="ctrl.onPreviewDetailsChange()"
									ng-disabled="ctrl.isBusy || ctrl.isLocked"></textarea>
								<div ng-messages="flow.message.$error" role="alert">
									<div ng-message="required">Required</div>
									<div ng-message="lang-missing">{{ flow.message.$error['lang-missing'] }}</div>
									<div ng-message="lang-mismatch">{{ flow.message.$error['lang-mismatch'] }}</div>
									<div ng-message="lang">Invalid language tag formatting</div>
								</div>
							</md-input-container>
						</div>
					</section>

					<div class="panel-wrap no-animate" ng-if="ctrl.model.type=='quiz'" ng-form="flow">
						<section class="panel" md-whiteframe="1">
							<div class="panel-title padding-4x">
								<h3 class="md-title normal-wg margin-0x">Quiz</h3>
							</div>
							<div class="panel-content padding-4x">
								<!-- <h4 class="md-subhead normal-wg margin-0x padding-2x">Greeting Flow</h4> -->
								<md-input-container class="md-block md-input-has-placeholder">
									<label>Introduction Message</label>
									<textarea name="intro" ng-model="ctrl.model.flow.intro"
										valid-lang
										ng-disabled="ctrl.isBusy || ctrl.isLocked"
										rows="3"></textarea>
										<div ng-messages="flow.intro.$error" role="alert">
											<div ng-message="lang-missing">{{ flow.intro.$error['lang-missing'] }}</div>
											<div ng-message="lang-mismatch">{{ flow.intro.$error['lang-mismatch'] }}</div>
											<div ng-message="lang">Invalid language tag formatting</div>
										</div>
								</md-input-container>
							</div>
						</section>

						<section class="panel-wrap">
							<div class="panel compact competencies dim-hover" md-whiteframe="1">
								<div class="panel-title padding-4x">
									<h3 class="md-title normal-wg margin-0x">Competencies</h4>
								</div>
								<div class="panel-content competency padding-4x no-animate no-animate dim-item" ng-repeat="item in ctrl.model.flow.competencies track by $index" ng-class="{open: item._$open}">
									<div toggle-expand="!item._$open" ng-show="item.label">
										<div class="competency-details expandable" tabindex="0" 
											aria-label="competency {{ item.label || ($index+1) }}"
											ng-click="ctrl.openCompetency(item, $index)">
											<div class="layout-row">
												<div class="flex-nogrow thumbnail">
													<div class="url-thumb" ng-show="item.url && !item.file" style="background-image: url('{{ item.url }}');"></div>
													<div class="file-thumb" ng-if="item.file" ngf-background="item.file"></div>
												</div>
												<div class="flex content">
													<h4 class="md-subhead black-100 margin-0x">{{ item.label }}</h4>
													<p class="md-body-1 black-75 margin-0x text-justify">{{ item.description }}</p>
													<p class="md-body-1 black-25 margin-0x" ng-show="item.file">
														<span class="fas fa-file-upload"></span> {{ item.file.name }}
													</p>
												</div>
												<div class="flex-nogrow" ng-hide="ctrl.isLocked">
													<md-button class="md-icon-button" aria-label="show details" title="show details">
														<span class="fas fa-chevron-down"></span>
													</md-button>
													<md-button class="md-icon-button md-warn md-hue-1" 
														aria-label="remove competency" title="remove competency"
														ng-click="ctrl.removeCompetency($index, $event); $event.stopPropagation();"
														ng-show="!ctrl.isLocked && ctrl.model.flow.competencies.length > 1">
														<span class="far fa-trash-alt"></span>
													</md-button>
												</div>
											</div>
										</div>
									</div>
									<fieldset ng-form="competency-{{ $index }}" class="compact" toggle-expand="item._$open">
										<legend class="no-visual">Competency {{ item.label || ($index+1) }}</legend>
										<section class="expandable">
											<div class="layout-row layout-align-start-center">
												<md-input-container class="flex md-input-has-placeholder">
													<label>Name</label>
													<input type="text" name="label" ng-model="item.label" validate="ctrl.isUniqueCompetency(item, $viewValue)" required>
													<div ng-messages="flow['competency-'+ $index].label.$error" role="alert">
														<div ng-message="required">Required</div>
														<div ng-message="validate">Already in use</div>
													</div>
												</md-input-container>
												<div class="flex-nogrow">
													<md-button class="md-icon-button" 
														aria-label="hide details" title="hide details"
														ng-click="ctrl.validateForm(form.flow['competency-'+$index]) ? ctrl.closeCompetency(item) : ctrl.toastInvalidCompetency()">
														<span class="fas fa-chevron-up"></span>
													</md-button>
													<md-button class="md-icon-button md-warn md-hue-1" 
														aria-label="remove competency" title="remove competency"
														ng-click="ctrl.removeCompetency($index, $event); $event.stopPropagation();"
														ng-show="!ctrl.isLocked && ctrl.model.flow.competencies.length > 1">
														<span class="far fa-trash-alt"></span>
													</md-button>
												</div>
											</div>
											<div class="layout-xs-column layout-gt-xs-row">
												<md-input-container class="flex md-input-has-placeholder">
													<label>Description</label>
													<textarea name="description" valid-lang ng-model="item.description"
														ng-disabled="ctrl.isBusy || ctrl.isLocked"
														rows="3"></textarea>
													<div ng-messages="flow['competency-'+ $index].description.$error" role="alert">
														<div ng-message="required">Required</div>
														<div ng-message="lang-missing">{{ flow['competency-'+ $index].description.$error['lang-missing'] }}</div>
														<div ng-message="lang-mismatch">{{ flow['competency-'+ $index].description.$error['lang-mismatch'] }}</div>
														<div ng-message="lang">Invalid language tag formatting</div>
													</div>
												</md-input-container>
												<md-input-container class="flex-nogrow md-input-has-placeholder no-animate" flex-order-gt-xs="-1" ng-show="item.url || item.file">
													<label>Image Preview</label>
													<div class="preview layout-column layout-align-center-center">
														<img ng-if="item.url && !item.file" ng-src="{{ item.url }}">
														<img ng-if="item.file" ngf-src="item.file">
													</div>
												</md-input-container>
											</div>
											<div class="layout-row">
												<md-input-container class="flex right-action-btn no-animate md-input-has-placeholder" ng-show="item.url && !item.file">
													<label>Image URL</label>
													<input type="text" class="md-input" ng-value="item.url" ng-disabled="true" aria-label="File URL">
													<md-button class="md-icon-button md-warn md-hue-1 compact" 
														aria-label="remove image url" 
														ng-click="item.url = undefined; flow.$setDirty()">
														<span class="fas fa-times"></span>
													</md-button>
												</md-input-container>
												<md-input-container class="flex-nogrow md-input-has-placeholder">
													<md-button type="file" name="file" class="md-primary compact"
														ng-hide="ctrl.isLocked"
														ngf-select accept="image/*" ngf-pattern="'image/*'" ng-disabled="ctrl.isBusy"
														ngf-multiple="false" ngf-keep="true"
														ngf-change="$newFiles.length ? (item.file = $file = $newFiles.pop()) : null; flow.$setDirty()">
														<span class="fas fa-file-upload"></span> Select Image
													</md-button>
												</md-input-container>
												<md-input-container class="flex right-action-btn no-animate md-input-has-placeholder" ng-show="item.file" ng-cloak>
													<label>Image</label>
													<input type="text" class="md-input" ng-value="item.file.name" ng-disabled="true" aria-label="File URL">
													<md-button class="md-icon-button md-warn md-hue-1 compact" aria-label="remove image file" ng-click="item.file = undefined; flow.$setDirty()">
														<span class="fas fa-times"></span>
													</md-button>
												</md-input-container>
											</div>
											<md-input-container class="md-block md-input-has-placeholder" ng-show="item.file || item.url" ng-cloak ng-class="{{ item.file ? 'file' : '' }}">
												<label>Image Alt Text</label>
												<input type="text" name="competency_image_alt_text" class="md-input" ng-model="item.image_alt_text" aria-label="Image Alt Text" minlength="5" ng-required="item.url || item.file">
												<div ng-messages="flow['competency-'+ $index].competency_image_alt_text.$error" role="alert">
													<div ng-message="required">Required</div>
													<div ng-message="minlength">Minimum 5 characters</div>
												</div>
											</md-input-container>
											<div class="text-center padding-1x" ng-hide="ctrl.isBusy || ctrl.isLocked">
												<md-button type="button" class="md-primary margin-0x" ng-click="ctrl.validateForm(form.flow['competency-'+$index]) ? ctrl.closeCompetency(item) : ctrl.toastInvalidCompetency()">
													<span class="fas fa-chevron-up"></span>
													Hide details
												</md-button>
											</div>
										</section>
									</fieldset>
								</div>
								
								<div class="panel-content padding-2x text-center dim-item" ng-hide="ctrl.isLocked">
									<md-button class="md-raised md-primary" ng-click="ctrl.openCompetency(); flow.$setDirty()">
										<span class="fas fa-plus-circle"></span> Add Competency
									</md-button>
									<!-- <small class="black-50" ng-show="ctrl.model.steps.length >= 9"><br>Reached maximum number of steps</small> -->
								</div>
							</div>

							<div class="panel compact questions dim-hover" md-whiteframe="1" ui-sortable="ctrl.sortableQuestionsOptions" ng-model="ctrl.model.flow.questions">
								<div class="panel-title padding-4x">
									<h3 class="md-title normal-wg margin-0x">Questions</h3>
								</div>
								<div class="question panel-content padding-4x no-animate dim-item" ng-repeat="question in ctrl.model.flow.questions track by $index" ng-class="{open: question._$open}" ng-init="$qidx = $index">
									<div toggle-expand="!question._$open" ng-show="question.question">
										<section class="expandable">
											<div class="layout-row">
												<div class="flex-nogrow padding-2x" ng-hide="ctrl.isLocked">
													<md-icon class="fas fa-sort question-sort-handle"></md-icon>
												</div>
												<div class="flex flow-question margin-1x" 
													aria-label="question: {{ question.question || ($index+1) }}"
													ng-click="ctrl.openQuestion(question, $qidx)">
													<h4 class="md-subhead black-100 margin-0x" ng-bind-html="question._$question"></h4>
													<p class="md-body-1 black-75 margin-0x" ng-bind-html="ctrl.getAnswerStr(question)"></p>
												</div>
												<div class="flex-nogrow" ng-hide="ctrl.isLocked">
													<md-button class="md-icon-button" 
														aria-label="show details" title="show details"
														ng-click="ctrl.openQuestion(question, $qidx)">
														<span class="fas fa-chevron-down"></span>
													</md-button>
													<md-button class="md-icon-button md-warn md-hue-1" 
														aria-label="remove question" title="remove question"
														ng-click="ctrl.removeQuestion($qidx, $event); $event.stopPropagation();"
														ng-show="! ctrl.isLocked && ctrl.model.flow.questions.length > 1">
														<span class="far fa-trash-alt"></span>
													</md-button>
												</div>
											</div>
										</section>
									</div>

									<fieldset ng-form="question-{{ $qidx }}" class="compact" toggle-expand="question._$open" ng-if="::true">
										<section class="expandable">
											<div class="layout-row layout-align-start-center">
												<md-input-container class="flex md-input-has-placeholder">
													<label>Question #{{ $qidx +1 }}</label>
													<textarea name="question" valid-lang ng-model="question.question" required
														ng-disabled="ctrl.isBusy || ctrl.isLocked"
														rows="3"></textarea>
													<div ng-messages="flow['question-'+ $qidx].question.$error" role="alert">
														<div ng-message="required">Required</div>
														<div ng-message="lang-missing">{{ flow['question-'+ $qidx].question.$error['lang-missing'] }}</div>
														<div ng-message="lang-mismatch">{{ flow['question-'+ $qidx].question.$error['lang-mismatch'] }}</div>
														<div ng-message="lang">Invalid language tag formatting</div>
													</div>
												</md-input-container>
												<div class="flex-nogrow">
													<md-button class="md-icon-button" 
														aria-label="hide details" 
														ng-click="ctrl.validateForm(flow['question-'+ $qidx]) ? ctrl.closeQuestion(question) : ctrl.toastInvalidQuestion()">
														<span class="fas fa-chevron-up"></span>
													</md-button>
													<md-button class="md-icon-button md-warn md-hue-1" 
														aria-label="remove question" title="remove question"
														ng-click="ctrl.removeQuestion($qidx, $event); $event.stopPropagation();"
														ng-show="!ctrl.isLocked && ctrl.model.flow.questions.length > 1">
														<span class="far fa-trash-alt"></span>
													</md-button>
												</div>
											</div>
											<div class="answers" ui-sortable="ctrl.sortableAnswersOptions" ng-model="question.answers">
												<div class="answer" ng-repeat="answer in question.answers track by $index" ng-init="$aidx = $index">
													<div class="layout-row layout-align-start-center">
														<div class="flex-nogrow" ng-hide="ctrl.isLocked || question.answers.length<2">
															<md-icon class="fas fa-sort answer-sort-handle"></md-icon>
														</div>
														<md-input-container class="flex md-input-has-placeholder">
															<label>Answer {{ ctrl.getABC($aidx) }}</label>
															<input type="text" name="answer_{{ $aidx }}" valid-lang ng-model="answer.answer" required>
															<div ng-messages="flow['question-'+ $qidx]['answer_'+ $aidx].$error" role="alert">
																<div ng-message="required">Required</div>
																<div ng-message="lang-missing">{{ flow['question-'+ $qidx]['answer_'+$aidx].$error['lang-missing'] }}</div>
																<div ng-message="lang-mismatch">{{ flow['question-'+ $qidx]['answer_'+$aidx].$error['lang-mismatch'] }}</div>
																<div ng-message="lang">Invalid language tag formatting</div>
															</div>
														</md-input-container>
														<md-button class="flex-nogrow md-icon-button md-warn md-hue-1" 
															aria-label="remove answer" title="remove answer"
															ng-click="ctrl.removeAnswer(question, $aidx, $event); $event.stopPropagation();"
															ng-show="!ctrl.isLocked && question.answers.length > 1">
															<span class="far fa-trash-alt"></span>
														</md-button>
													</div>
													<div class="indent-2x layout-row layout-align-start-center layout-wrap no-animate">
														<span class="flex-nogrow black-75" title="Competency weights">
															<md-icon class="fas fa-weight-hanging"></md-icon>
														</span>
														<md-input-container class="flex-xs-30 flex-gt-xs-20 no-animate md-input-has-placeholder" 
															ng-repeat="compe in ctrl.model.flow.competencies" 
															ng-if="compe._id" ng-init="answer.competency_weight[compe._id] = answer.competency_weight[compe._id] || 0">
															<label>{{ compe.label }} weight</label>
															<input type="number" name="weight_{{ $aidx }}" ng-model="answer.competency_weight[compe._id]" 
																step="0.1" min="0"
																ng-change="answer.competency_weight[compe._id] = +answer.competency_weight[compe._id]">
														</md-input-container>
													</div>
												</div>
											</div>
											<div class="padding-2x text-center" ng-hide="ctrl.isLocked">
												<md-button class="md-raised md-primary" 
													ng-click="ctrl.addAnswer(question); flow.$setDirty()">
													<span class="fas fa-plus"></span> Add Answer
												</md-button>
											</div>
											<div class="text-center padding-1x" ng-hide="ctrl.isBusy || ctrl.isLocked">
												<md-button type="button" class="md-primary margin-0x" 
													ng-click="ctrl.validateForm(flow['question-'+ $qidx]) ? ctrl.closeQuestion(question) : ctrl.toastInvalidQuestion()">
													<span class="fas fa-chevron-up"></span> Hide details
												</md-button>
											</div>
										</section>
									</fieldset>
								</div>
								<div class="panel-content padding-2x text-center dim-item" ng-hide="ctrl.isLocked">
									<md-button class="md-raised md-primary" 
										ng-click="ctrl.openQuestion(); flow.$setDirty()">
										<span class="fas fa-plus-circle"></span> Add Question
									</md-button>
									<!-- <small class="black-50" ng-show="ctrl.model.steps.length >= 9"><br>Reached maximum number of steps</small> -->
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>

			<div class="flex-nogrow panel-wrap no-animate">
				<div id="flow-test-preview" class="panel" md-whiteframe="1" ng-if="ctrl.model.show_in_content_directory.show_in_content_directory">
					<div class="panel-title padding-4x">
						<h3 class="md-title normal-wg">Preview</h3>
					</div>
					<div class="panel-content mobile-preview layout-column">
						<div class="mobile-header flex-nogrow" ng-class="ctrl.preview.screen">
							<span class="icon" ng-class="{burger: ctrl.preview.screen=='home'}"
								ng-click="ctrl.preview.screen!='home' && ctrl.updatePreview('home')"></span>
							<span class="text text-lowercase">library</span>
						</div>

						<div class="mobile-content task-content home flex fade-in" ng-show="ctrl.preview.screen == 'home'">
							<div class="home-list">
								<div class="title-container list-item layout-row layout-align-start-stretch" 
									ng-style="{'background-color':ctrl.model.show_in_content_directory.content_directory.background_color || ctrl.DEFAULT_BGCOLOR}" 
									ng-click="ctrl.updatePreview('details')">
									<div class="title-wrap flex-55 layout-column layout-wrap layout-align-space-between-start">
										<span class="task-category" ng-show="ctrl.model.show_in_content_directory.content_directory.background_color">{{ ctrl.getLabel() }}</span>
										<h3 class="task-title" ng-bind-html="ctrl.preview.title"></h3>
										<img class="flow-icon" ng-src="{{ ctrl.IMG_FLOW }}" alt="flow" />
									</div>
									<div class="thumbnail-wrap flex-45 layout-column layout-align-end-end">
										<img 
											ng-if="ctrl.model.show_in_content_directory.content_directory.image_url__file || ctrl.model.show_in_content_directory.content_directory.image_url"
											ngf-src="ctrl.model.show_in_content_directory.content_directory.image_url__file || ctrl.model.show_in_content_directory.content_directory.image_url" 
											alt="{{ ctrl.model.content_directory.image_alt_text }}" />
									</div>
									
								</div>
								<div class="container item-actions last-container padding-0x" ng-style="{'background-color':ctrl._$secondaryColor}">
									<div layout="row">
										<div class="padding-1x recipient-college flex" md-truncate>
											{{ ctrl.model.owner_college_id != BEYOND12_ID ? ctrl.mapping.mycolleges.byId[ctrl.model.owner_college_id].name : '' }}
										</div>
										<div class="icons flex-nogrow">
											<span class="share-icon">
												<img ng-src="{{ ctrl.IMG_SHARE }}" alt="share" />
											</span>
											<span>
												<img class="save-icon" ng-src="{{ ctrl.IMG_SAVE }}" alt="save" />
											</span>
										</div>
									</div>
								</div>		
				
								<div class="title-container layout-row layout-align-start-stretch" 
									style="background-color: #3A3F42">
									<div class="title-wrap flex-55 layout-column layout-wrap has-video">
										<h3 class="task-title flex-nogrow" aria-hidden="true">Other Task</h3>
										<i flex></i>
										<span class="flex-nogrow"><i class="fas fa-play" title="video task"></i></span>
									</div>

									<div class="thumbnail-wrap flex-45 layout-column layout-align-end-end">
										<img ngf-src=":: ctrl.IMG_HOM" alt="other task image" />
									</div>
								</div>
								<div class="container item-actions last-container padding-0x">
									<div layout="row">
										<div class="padding-1x recipient-college" flex="66" aria-hidden="true">
											Sample College
										</div>
										<div class="icons" flex="33">
											<span class="share-icon">
												<img ng-src="{{ ctrl.IMG_SHARE }}" alt="share" aria-hidden="true"/>
											</span>
											<span>
												<img class="save-icon" ng-src="{{ ctrl.IMG_SAVE }}" alt="save" aria-hidden="true"/>
											</span>
										</div>
									</div>
								</div>

								<div class="title-container layout-row layout-align-start-stretch" 
									style="background-color: #3A3F42">
									<div class="title-wrap flex-55 layout-column layout-wrap">

										<h3 class="task-title flex-nogrow has-step" aria-hidden="true">Another Task</h3>
										<span class="flex-nogrow steps-length" aria-hidden="true">4 STEPS</span>
										<i flex></i>
									</div>
									<div class="thumbnail-wrap flex-45 layout-column layout-align-end-end">
										<img ngf-src=":: ctrl.IMG_DIR" alt="another task image" />
									</div>
								</div>
								<div class="container item-actions last-container padding-0x">
									<div layout="row">
										<div class="padding-1x recipient-college" flex="66" aria-hidden="true">
											Sample College
										</div>
										<div class="icons" flex="33">
												<img ng-src="{{ ctrl.IMG_SHARE }}" alt="share" aria-hidden="true"/>
											</span>
											<span>
												<img class="save-icon" ng-src="{{ ctrl.IMG_SAVE }}" alt="save" aria-hidden="true"/>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="mobile-content task-content details flex-nogrow fade-in" ng-show="!ctrl.preview.screen">
							<div class="micro-task" ng-if="!ctrl.model.video.url && !ctrl.model.video.file">
								<div class="title-container title-container layout-row layout-align-start-stretch" 
									ng-style="{'background-color':ctrl.model.content_directory.background_color || ctrl.DEFAULT_BGCOLOR}">
									<div class="title-wrap flex-55"> 
										<h3 class="task-title" ng-bind-html="ctrl.preview.title"></h3>
										<img class="flow-icon" ng-src="{{ ctrl.IMG_FLOW }}" alt="flow"/>
									</div>
									<div class="thumbnail-wrap flex-45 layout-column layout-align-end-end">
										<img 
											ng-if="ctrl.model.show_in_content_directory.content_directory.image_url__file || ctrl.model.show_in_content_directory.content_directory.image_url"
											ngf-src="ctrl.model.show_in_content_directory.content_directory.image_url__file || ctrl.model.show_in_content_directory.content_directory.image_url" 
											alt="{{ ctrl.model.show_in_content_directory.content_directory.image_alt_text }}" />
									</div>
								</div>
								<div class="list-item-footer padding-0x">
									<div layout="row">
										<div class="padding-1x recipient-college flex" md-truncate>
											{{ ctrl.model.owner_college_id != BEYOND12_ID ? ctrl.mapping.mycolleges.byId[ctrl.model.owner_college_id].name : '' }}
										</div>
										<div class="icons flex-nogrow">
											<span class="share-icon">
												<img ng-src="{{ ctrl.IMG_SHARE }}" alt="share"/>
											</span>
											<span>
												<img class="save-icon" ng-src="{{ ctrl.IMG_SAVE }}" alt="save"/>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="mobile-content task-content chat flex layout-column fade-in" ng-show="!ctrl.preview.screen">
							<div class="chat-msgs cd layout-column flex-100">
								<div class="flex-none task-text">
									<div class="width-100" ng-bind-html="ctrl.preview.framing_message"></div>
								</div>
								<div class="flex-none text-center fade-out" ng-hide="ctrl.chatsim.isRunning && !ctrl.chatsim.wasInterupted">
									<button type="button" class="done" ng-click="ctrl.startChatSim($event)">get started</button>
								</div>
								<i flex></i>
								<div class="msg-wrap flex-noshrink ng-animate chat-height-up" ng-repeat="content in ctrl.chatsim.messages track by $index" ng-show="ctrl.chatsim.isRunning && !ctrl.chatsim.wasInterupted">
									<div class="msg cd layout-column layout-align-center-stretch" ng-class="{fromuser:content.info.$fromUser, lastnode:content.info.$lastnode}">
										<div class="flex-nogrow body" ng-bind-html="::content.htmlBody"></div>
										<img class="flex-nogrow" ng-src="::{{content.url}}" ng-if="::content.info.mimetype.indexOf('image')==0" />
									</div>
									<div class="answers" ng-if=":: content.actions" ng-class="::{done:content.actions._hide}" toggle-expand="!content.actions._hide" duration="100">
										<div class="expandable">
											<md-button type="button" class="msg cd" 
													ng-repeat="action in content.actions track by $index"
													ng-click="ctrl.chatsim.sendReply(content, action)" ng-bind-html="action.body"></md-button>
										</div>
									</div>
								</div>
								<div id="chat-anchor" style="overflow-anchor: auto; height: 1px;"></div>
							</div>
						</div>

						<div class="mobile-footer flex-nogrow layout-row" role="img">
							<div class="flex-20"></div>
							<div class="flex-20 active"></div>
							<div class="flex-20"></div>
							<div class="flex-20"></div>
							<div class="flex-20"></div>
						</div>
					</div>
				</div>
				<div id="flow-test-preview" class="panel" md-whiteframe="1" ng-if="!ctrl.model.show_in_content_directory.show_in_content_directory" ng-show="ctrl.chatsim.isRunning" ng-cloak>
					<div class="panel-title layout-row layout-align-start-center">
						<h3 class="md-title normal-wg margin-4x flex">Test Flow</h3>
						<div class="margin-1x flex-nogrow">
							<md-button type="button" class="md-primary compact" ng-click="ctrl.startChatSim($event)">
								<span class="fas fa-step-backward"></span> Restart
							</md-button>
							<md-button type="button" class="md-icon-button md-warn compact" ng-click="ctrl.chatsim.stop()" aria-label="Stop flow test">
								<span class="fas fa-times"></span>
							</md-button>
						</div>
					</div>
					<div class="panel-content mobile-preview layout-column" style="position: relative;">
						<div class="mobile-header home flex-nogrow" data-time="{{ moment() | amDateFormat:'h:mm' }}"></div>
						<div class="mobile-content flex layout-column">
							<div class="chat-msgs flex-100 layout-column">
								<i flex></i>
								<div class="msg-wrap ng-animate chat-height-up" ng-repeat="content in ctrl.chatsim.messages track by $index">
									<div class="msg layout-column layout-align-center-stretch" ng-class="[content.info.layout, {'wide':!content.$fromUser}]" 
										ng-style="{'background-color': content.info.background || ctrl.model.color || ''}" 
										data-welcome="{{ content.info.layout == 'welcome' ? 'Hi '+ mainCtrl.currentUser.first_name +'!' : '' }}">
										<div class="flex-nogrow body" ng-bind-html="content.htmlBody"></div>
										<img class="flex-nogrow" ng-src="{{content.url}}" ng-if="content.info.mimetype.indexOf('image')==0" />
										<div class="flex-nogrow answers text-right chat-height-up" ng-if="content.actions" ng-class="{done:content.actions._hide}" toggle-expand="!content.actions._hide" duration="300">
											<div class="expandable">
												<span ng-repeat="action in content.actions track by $index">
													<md-button type="button" class="compact" ng-click="ctrl.chatsim.sendReply(content, action)">{{ action.body }}</md-button><br>
												</span>
											</div>
										</div>
									</div>
								</div>

								<div id="chat-anchor" style="overflow-anchor: auto; height: 1px;"></div>
							</div>
						</div>
						<div class="mobile-footer flex-nogrow layout-row">
							<div class="flex-20"></div>
							<div class="flex-20"></div>
							<div class="flex-20 active"></div>
							<div class="flex-20"></div>
							<div class="flex-20"></div>
						</div>

						<div class="overlay layout-row layout-align-center-center" ng-if="ctrl.chatsim.wasInterupted">
							<h3 class="md-headline text-center">{{ ctrl.chatsim.error }}<br>please restart</h3>
						</div>
					</div>
				</div>
			</div>
		</div>

		<section ng-form="flow" class="panel-wrap no-animate" ng-if="ctrl.model.type=='dynamic_flow'">
			<div id="dynamic-flow" class="panel layout-column" md-whiteframe="1">
				<div class="panel-title flex-nogrow padding-4x">
					<h3 class="md-title normal-wg margin-0x">Dynamic Flow</h4>
				</div>
				<div class="panel-content flex layout-gt-xs-row">
					<div id="container" class="flex can-scroll-x can-scroll-y"
						role="img" aria-label="Flowchart of questions and answers"
						style="position:relative; cursor:default;" tabindex="-1"></div>
					<div class="flex-nogrow layout-column ng-animate left-slide-fade-in right-slide-fade-out" style="width: 240px" form-disabled="ctrl.isBusy || ctrl.isLocked" ng-if="ctrl.graph && !ctrl.graph.isSelectionEmpty()" ng-cloak>
						<div class="padding-2x flex-nogrow" form-disabled="!ctrl.graphHasSelected(true, false)">
							<md-input-container class="md-block md-input-has-placeholder">
								<label>Color</label>
								<md-select name="color" md-container-class="flow-color-select"
									ng-model="ctrl.graph._$fillcolor"
									ng-change="ctrl.graph._$fillcolor=='none' ? (ctrl.graph._$fillcolor=undefined) : null; ctrl.graphColorSelected(ctrl.graph._$fillcolor);">
									<md-option ng-value="ctrl.graph._$fillcolor" ng-if="ctrl.graph._$fillcolor && !ctrl.mapping.flowColors.includes(ctrl.graph._$fillcolor)">
										<span class="color" ng-style="{'background-color': ctrl.graph._$fillcolor}"></span> {{ ctrl.graph._$fillcolor }}
									</md-option>
									<md-option md-option-empty ng-value="'none'">Default</md-option>
									<md-option ng-repeat="color in ctrl.mapping.flowColors" ng-value="color">
										<span class="color" ng-style="{'background-color': color}"></span> {{ color }}
									</md-option>
								</md-select>
								<div class="md-errors-spacer"></div>
								<div ng-messages="form.type.$error" role="alert">
									<div ng-message="required">Required</div>
								</div>
							</md-input-container>
							<md-input-container class="md-block md-input-has-placeholder">
								<label>Layout</label>
								<md-select name="layout" ng-model="ctrl.graph._$layout" ng-change="ctrl.graphLayoutSelected(ctrl.graph._$layout)">
									<md-option ng-repeat="(key, value) in ctrl.mapping.layouts" ng-value="value">{{ key }}</md-option>
								</md-select>
								<div class="md-errors-spacer"></div>
							</md-input-container>
						</div>
						<div ng-form="comment-form" class="padding-2x flex layout-column" form-disabled="!ctrl.graphHasSelected(true, true)">
							<label class="md-caption flex-nogrow">Comments</label>
							<div class="dynamic-flow-comments flex margin-2x layout-column layout-align-end">
								<div class="can-scroll-y">
									<div ng-repeat="comment in ctrl.graph._$comments track by $index" title="{{ comment.date | amDateFormat:SETTINGS.dateTimeFormat }}">
										<em class="black-50">{{ ctrl.mapping.users.byId[comment.user_id].user_profile.first_name || comment.user_id }}: </em> {{ comment.comment }}
									</div>
								</div>
							</div>
							<md-input-container class="comment-input md-block flex-nogrow compact no-label md-input-has-placeholder" md-no-float="true">
								<input type="text" id="comment-input" placeholder="Type comment here" ng-keypress="ctrl.graphAddComment($event)">
								<!-- <md-icon class="fas fa-comment" ng-click="ctrl.graphAddComment()"></md-icon> -->
								<md-button class="md-icon-button md-primary" aria-label="Submit Comment" ng-click="ctrl.graphAddComment()">
									<span class="fas fa-comment"></span>
								</md-button>
							</md-input-container>
						</div>
						<hr>
						<div class="padding-2x flex-nogrow text-center">
							<md-button type="button" class="md-raised md-warn" ng-click="ctrl.graphDeleteSelected()" aria-label="Delete Selected">
								<span class="fas fa-trash"></span> Delete Selected
							</md-button>
						</div>
					</div>
				</div>
				<div class="panel-footer flex-nogrow padding-2x">
					<md-button type="button" class="md-raised md-primary md-hue-3" id="add-new-btn" ng-click="ctrl.graphAddPrompt()" draggable="true">
						<span class="fas fa-plus"></span> Add New Prompt
					</md-button>
					<md-button type="button" class="md-icon-button md-primary" 
						aria-label="Undo" title="Undo"
						ng-click="ctrl.graph.undoManager.undo()" 
						ng-disabled="!ctrl.graph.undoManager.canUndo()">
						<span class="fas fa-undo"></span>
					</md-button>
					<md-button type="button" class="md-icon-button md-primary" 
						aria-label="Redo" title="Redo"
						ng-click="ctrl.graph.undoManager.redo()" 
						ng-disabled="!ctrl.graph.undoManager.canRedo()">
						<span class="fas fa-redo"></span>
					</md-button>

					<img id="drag-empty" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="pull-right" alt="" aria-hidden="true"/>
				</div>
			</div>
		</section>
	</form>
</main>

<div ng-show="ctrl.isBusy" class="busy-overlay fade-out" layout layout-align="center center">
	<md-progress-circular md-mode="indeterminate"></md-progress-circular>
</div>

<footer class="text-right layout-row layout-align-end-start" md-whiteframe="2" ng-hide="ctrl.isLocked" form-disabled="ctrl.isBusy || ctrl.isLocked">
	<div class="flex text-right">
		<md-button type="button" class="md-primary" ng-click="ctrl.startChatSim($event)" ng-hide="ctrl.chatsim.isRunning && !ctrl.chatsim.wasInterupted">
			<span class="fas fa-play"></span> Test Flow
		</md-button>
		<md-button type="button" class="md-primary" ng-click="ctrl.chatsim.stop()" ng-show="ctrl.chatsim.isRunning && !ctrl.chatsim.wasInterupted">
			<span class="fas fa-stop"></span> Stop Test
		</md-button>
	</div>
	<div class="flex-nogrow flex-offset-5" ng-show="!ctrl.data.publish">
		<md-button type="button" class="md-primary" ng-click="ctrl.submit($event, form, true)">
			<span class="far fa-save"></span> Save and Keep Editing
		</md-button>
		<md-button type="button" class="md-raised md-warn" ng-click="ctrl.submit($event, form, false)">
			<span class="fas fa-paper-plane"></span> Publish
		</md-button>
	</div>
	<div class="flex-nogrow flex-offset-5" ng-show="ctrl.data.publish">
		<md-button type="button" class="md-primary" ng-click="ctrl.submit($event, form, true)">
			<span class="fas fa-save"></span> Save and Keep Editing
		</md-button>
		<md-button type="button" class="md-raised md-warn" ng-click="ctrl.submit($event, form, false)">
			<span class="fas fa-ban"></span> Unpublish
		</md-button>
	</div>
</footer>
 