<div class="layout-row flex-100">
	<md-sidenav id="side" class="layout-column hide-print"
        md-component-id="side"
        md-is-locked-open="mainCtrl.$mdMedia('gt-sm')"
        md-whiteframe="4">
		<h1 class="margin-0x">
			<a href="./" class="logo" title="Welcome to My Coach®, powered by beyond 12 logo">
				<span class="hide">Welcome to My Coach®, powered by beyond 12</span>
			</a>
		</h1>
		<div class="md-margin">
			<div class="logged-in">Logged in as</div>
			<div layout>
				<span flex class="current-user md-truncate md-headline">{{ mainCtrl.currentUser.user_profile.first_name }}</span>
				<md-menu>
					<md-button class="md-icon-button" aria-label="User Options" ng-click="$mdMenu.open()">
						<span class="fas fa-cog" role="presentation"></span>
					</md-button>
					<md-menu-content width="2">
						<md-menu-item>
							<md-button ui-sref="app.profile">My Profile</md-button>
						</md-menu-item>
						<md-menu-item>
							<md-button ui-sref="app.logout" ng-click="mainCtrl.logout(); $event.preventDefault();">Logout</md-button>
						</md-menu-item>
					</md-menu-content>
				</md-menu>
			</div>
		</div>
		
		<div class="md-margin"></div>
	
		<md-list aria-label="main">
			<div ng-repeat="item in mainCtrl.menuList track by $index" toggle-expand="item._$open" ng-hide="item._$hidden">
				<md-list-item
					id="{{::item.id}}"
					ng-href="{{ mainCtrl.getLink(item) }}"
					ng-class="{selected: mainCtrl.isActive(item)}"
					ng-click="mainCtrl.go(item, $event, 'side')">
					<md-icon ng-class="::item.iconClass" role="img" aria-hidden="true"></md-icon>
					<span id="label_{{::item.id}}" class="nav-item">{{ ::item.name }}</span>
					<md-button class="md-secondary md-icon-button"
						ng-if="::item.children.length > 0"
						ng-click="mainCtrl.toggleSubmenu(item)" 
						role="button" aria-label="{{ item._$open ? 'Collapse' : 'Expand' }} {{::item.name}}" 
						aria-expanded="{{item._$open}}" aria-controls="expand_{{::item.id}}">
							<span class="fas fa-chevron-down" role="img" aria-hidden="true"></span>
					</md-button>
				</md-list-item>
				<div class="expandable" id="expand_{{:: item.id}}" 
					role="group" aria-label="{{:: item.name}}"
					aria-hidden="{{!item._$open}}">
					<md-list-item
						id="{{::subitem.id}}"
						ng-repeat="subitem in item.children track by $index"
						ng-href="{{ mainCtrl.getLink(subitem) }}"
						ng-class="{active: mainCtrl.$state.includes(subitem.sref)}" 
						ng-click="mainCtrl.go(subitem, $event, 'side')">
						<md-icon ng-class="::subitem.iconClass" role="img" aria-hidden="true"></md-icon>
						<span class="nav-item">{{ ::subitem.name }}</span>
					</md-list-item>
				</div>
				<md-divider ng-if="::item.divider"></md-divider>
			</div>
		</md-list>

		<div flex></div>

		<footer>
			<p class="md-caption partial">
				MyCoach Administration Console<br>
				All rights reserved {{:: moment() | amDateFormat:'YYYY' }}<br>
				<small class="version">v{{:: APP_VERSION}}</small>
			</p>
		</footer>
	</md-sidenav>

	<md-button id="menu-button" class="md-icon-button md-primary md-hue-2 hide-gt-sm" aria-label="Show Menu" ng-click=" mainCtrl.$mdSidenav('side').open();">
		<span class="fas fa-bars"></span>
	</md-button>

	<md-content id="main-content" class="flex layout-column" ng-model-options="{updateOn:'default blur', debounce:{'default':200, 'blur':0}}">
		<div id="content" ui-view name="content" class="flex layout-column layout-align-space-between-stretch no-animate" ng-cloak></div>
	</md-content>
</div>