<div class="flex can-scroll-y">
	<div class="headers layout-gt-xs-row md-whiteframe-1dp">
		<div class="headers-group" layout layout-align="start center">
			<div>
				<span class="header-icon md-headline"><span class="far fa-bell"></span></span>
			</div>
			<h3 class="header-label flex">
				<div class="md-display-1" ng-show="ctrl.data.notificationsPerType">
					{{ ctrl.data.totalNotifications || 0 }}
				</div>
				<div class="md-display-1" ng-hide="ctrl.data.notificationsPerType">
					<md-progress-circular md-mode="indeterminate" md-diameter="20"></md-progress-circular>
				</div>
				<sub class="black-50">Total Notifications</sub>
			</h3>
		</div>
		<div class="chart-container-sm" flex layout layout-align="center center">
			<div id="notifications-per-type" ng-show="ctrl.data.notificationsPerType" 
				role="img" aria-label="notification types pie chart"></div>
			<md-progress-circular md-mode="indeterminate" md-diameter="40" ng-hide="ctrl.data.notificationsPerType"></md-progress-circular>
		</div>
	</div>

	<div class="layout-gt-xs-row">
		<div class="panel flex-gt-xs-50" md-whiteframe="1">
			<md-list flex>
				<md-list-item class="md-2-line" ng-repeat="item in ctrl.menuList" href ui-sref="{{item.sref}}">
					<div class="md-list-item-text md-padding">
						<h3>{{ item.name }}</h3>
						<p class="normal-wg">{{ item.desc }}</p>
					</div>
				</md-list-item>
			</md-list>
		</div>

		<div class="panel flex-gt-xs-50 padding-4x" md-whiteframe="1">
			<div class="headers-group" layout>
				<div layout="column" layout-align="center">
					<span class="header-icon orange md-headline"><span class="fas fa-users"></span></span>
				</div>
				<div class="header-label">
					<div class="md-display-1" ng-show="ctrl.data.studentsPerActiveStatus">{{ ctrl.data.totalStudents || 0 }}</div>
					<div class="md-display-1" ng-hide="ctrl.data.studentsPerActiveStatus">
						<md-progress-circular md-mode="indeterminate" md-diameter="20"></md-progress-circular>
					</div>
					<sub class="black-50">Total Students</sub>
				</div>
			</div>

			<div class="chart-container-sm" layout layout-align="center center">
				<div id="students-per-active-status" ng-show="ctrl.data.studentsPerActiveStatus"
					role="img" aria-label="active students pie chart"></div>
				<div ng-hide="ctrl.data.studentsPerActiveStatus">
					<md-progress-circular md-mode="indeterminate" md-diameter="40"></md-progress-circular>
				</div>
			</div>
			<div class="chart-container-sm" layout layout-align="center center">
				<div id="students-per-device-os" ng-show="ctrl.data.studentsPerDeviceOS"
					role="img" aria-label="student devices pie chart"></div>
				<div ng-hide="ctrl.data.studentsPerDeviceOS">
					<md-progress-circular md-mode="indeterminate" md-diameter="40"></md-progress-circular>
				</div>
			</div>
		</div>
	</div>
</div>