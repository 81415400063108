<header class="md-whiteframe-2dp flex-none layout-row layout-align-space-between-start layout-wrap">
	<h2 id="pageTitle" class="flex-xs-100 md-headline">
		<a ui-sref="^" ui-sref-opts="ctrl.parentStateParams">Tips <span class="margin-1x" aria-hidden="true"> &#9656;
		</span></a>{{ ctrl.data ? '' : 'Add New' }}
		<span id="docTitle" ng-show="ctrl.data">{{:: ctrl.data ? 'Tip #'+ ctrl.data._id : 'new tip'}}</span>
		<span role="img" aria-label="changes not saved" title="changes not saved" ng-show="form.$dirty">*</span>
	</h2>
	<div class="top-buttons flex-xs-100" ng-show="ctrl.data" form-disabled="ctrl.isBusy">
		<md-button id="cloneBtn" aria-labelledby="cloneBtn docTitle"
			ui-sref="app.tips.add({clone: ctrl.data._id})"
			ng-if=":: mainCtrl.hasPermission(PERMISSIONS.CREATE_TIPS)">
			<span class="fas fa-copy"></span> 
			Clone
		</md-button>
		<md-button class="md-warn" id="deleteBtn" aria-labelledby="deleteBtn docTitle" 
			ng-click="ctrl.delete($event)"
			ng-if=":: mainCtrl.hasPermission(PERMISSIONS.DELETE_TIPS)"
			ng-hide="ctrl.isLocked">
			<span class="far fa-trash-alt"></span> 
      Delete
		</md-button>
	</div>
	
	<div flex="100" ng-include="::'common/lockCard.tpl'"></div>
</header>

<main class="flex">
	<form id="notification-form" name="form" novalidate 
		class="flex-gt-sm" ng-class="{locked: ctrl.isLocked}" 
		aria-labelledby="pageTitle"
		form-disabled="ctrl.isBusy || ctrl.isLocked"
		ng-submit="!ctrl.isBusy && !ctrl.isLocked && ctrl.submit($event, form)" 
		ng-model-options="{updateOn:'default blur', debounce:{'default':250, 'blur':0}}">
		<input type="submit" class="hide" tabindex="-1">

		<div class="panel-wrap layout-gt-md-row layout-align-start-stretch">
			<div class="form-details panel-wrap panel-small flex">
				<div class="panel" md-whiteframe="1" ng-include="'tip.form.detail.tpl'"></div>
			</div>

			<div class="panel-wrap flex-none layout-column layout-align-start-center">
				<div class="panel-wrap previews layout-column layout-align-gt-md-start-stretch">
					<div class="panel event-preview fade-in" md-whiteframe="1" ng-show="ctrl.model.call_to_action || ctrl.model.attribution">
						<div class="panel-title md-title normal-wg padding-4x">Mobile Preview</div>
						<div class="panel-content mobile-preview flex layout-column cursor-normal no-select">
							<div class="mobile-header home fade-in flex-nogrow" ng-show="ctrl.preview.screen=='home'"></div>
							<div class="mobile-header fade-in flex-nogrow" ng-hide="ctrl.preview.screen=='home'">
								<span class="icon" role="button" aria-label="back" ng-click="ctrl.updatePreview('home',true)"></span>
								<span class="text text-lowercase">tips</span>
							</div>

							<div class="mobile-content flex tip fade-in layout-column" ng-show="ctrl.preview.screen=='home'">
								<div class="tip-container flex-nogrow layout-column"
									ng-style="{'background-color': !ctrl.model.background.$useImage && ctrl.model.background.color || ctrl.DEFAULT_BGCOLOR}"
									ngf-background="(ctrl.model.background.$useImage && (ctrl.model.background.file || ctrl.model.background.url)) || ' '"
									ng-click="ctrl.updatePreview('details', true)">
									<div class="owner flex-nogrow text-uppercase" ng-if="ctrl.model.owner_college_id != BEYOND12_ID">
										{{ ctrl.mapping.mycolleges.byId[ctrl.model.owner_college_id].name }}
									</div>
									<div class="flex">
										<div class="text-block text-preview" 
											ng-bind-html="ctrl.preview.call_to_action" 
											ng-class="[
												ctrl.model.owner_college_id != BEYOND12_ID ? 'has-college' : null,
												ctrl.model.attribution ? 'has-attr' : null,
												ctrl.model.text.content=='text/html' ? 'html' : 'plain',
											]"></div>
                    <div class="attribution text-uppercase" ng-show="ctrl.preview.attribution" ng-bind-html="ctrl.preview.attribution"></div>
									</div>
									<div class="ellipsis flex-nogrow"></div>
								</div>
								<div class="flex" style="overflow-y: auto">
									<div class="section-container whats-next">
										<section>
											<span class="text-uppercase" aria-hidden="true">What's Next</span>
											<span class="pull-right" aria-hidden="true">more</span>
										</section>
										<section class="with-chevron" aria-hidden="true">
											sample task <span class="text-uppercase black-50" aria-hidden="true">STEP 4</span>
										</section>
									</div>
									<div class="section-container events">
										<section>
											<span class="text-uppercase" aria-hidden="true">Upcoming Events</span>
											<span class="pull-right" aria-hidden="true">more</span>
										</section>
										<section class="with-chevron" aria-hidden="true">
											Sample event<br>
											<span class="text-uppercase black-50" aria-hidden="true">Some college name</span>
										</section>
									</div>
									<div class="layout-row">
										<div class="section-container flex-50 chat">
											<section>
												<span class="text-uppercase" aria-hidden="true">Chat</span>
												<span class="pull-right" aria-hidden="true">more</span>
											</section>
											<section class="number-value layout-row">
												<span class="number flex-none" aria-hidden="true">2</span>
												<span aria-hidden="true">new<br>messages</span>
											</section>
										</div>
										<div class="section-container flex-50 rewards">
											<section>
												<span class="text-uppercase" aria-hidden="true">Rewards</span>
												<span class="pull-right" aria-hidden="true">more</span>
											</section>
											<section class="number-value layout-row">
												<span class="number flex-none" aria-hidden="true">10</span>
												<span aria-hidden="true"><br>points</span>
											</section>
										</div>
									</div>
								</div>
							</div>

							<div class="mobile-content flex tip tip-details fade-in layout-column" ng-hide="ctrl.preview.screen=='home'">
								<div class="tip-container tip-details flex-none layout-column"
									ng-style="{'background-color': !ctrl.model.background.$useImage && ctrl.model.background.color || ctrl.DEFAULT_BGCOLOR}"
									ngf-background="(ctrl.model.background.$useImage && (ctrl.model.background.file || ctrl.model.background.url)) || ' '">
									<div class="owner flex-nogrow text-uppercase" ng-if="ctrl.model.owner_college_id != BEYOND12_ID">
										{{ ctrl.mapping.mycolleges.byId[ctrl.model.owner_college_id].name }}
									</div>
									<div class="text-block text-preview" 
											ng-bind-html="ctrl.preview.call_to_action" 
											ng-class="[
												ctrl.model.owner_college_id != BEYOND12_ID ? 'has-college' : null,
												ctrl.model.attribution ? 'has-attr' : null,
												ctrl.model.text.content=='text/html' ? 'html' : 'plain',
											]"></div>
                  <div class="attribution text-uppercase" ng-show="ctrl.preview.attribution" ng-bind-html="ctrl.model._$attribution"></div>
								</div>

								<div class="text-details" ng-bind-html="ctrl.model.text.html"
									ng-class="ctrl.model.text.content=='text/html' ? 'html' : 'plain'"></div>
								
								<div class="button-preview" ng-show="ctrl.model.link.text">
									<a ng-href="{{ctrl.model.link.url}}" title="{{ctrl.model.link.url}}" 
										target="_blank" rel="nofollow">
                    <span ng-bind-html="ctrl.preview.linkText"></span>
                  </a>
								</div>
							</div>

							<div class="mobile-footer flex-nogrow layout-row">
								<span class="flex-20 active" aria-hidden="true"></span>
								<span class="flex-20" aria-hidden="true"></span>
								<span class="flex-20" aria-hidden="true"></span>
								<span class="flex-20" aria-hidden="true"></span>
								<span class="flex-20" aria-hidden="true"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="form-reminders panel-wrap">
			<div class="panel panel-small" md-whiteframe="1" ng-include="'tip.form.reminders.tpl'"></div>
		</div>
		
	</form>
</main>

<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

<footer class="text-right" md-whiteframe="2" ng-hide="ctrl.isLocked">
	<md-button type="button" class="md-raised md-warn" id="saveBtn" aria-labelledby="saveBtn docTitle"
		ng-disabled="ctrl.isBusy" ng-click="ctrl.submitForm('form')">
		<span class="far fa-save" aria-hidden="true"></span> Save
	</md-button>
</footer>
