<div class="headers no-chart padding-2x md-whiteframe-1dp flex-none" ng-hide="ctrl.hideHeadersGroup">
	<div class="headers-group" layout layout-align="start center">
		<div class="flex-none">
			<span class="header-icon md-headline" ng-class=":: ctrl.iconColor"><span ng-class=":: ctrl.icon"></span></span>
		</div>
		<div class="header-label flex">
			<div class="md-display-1">{{ ctrl.totalData || 0 }}</div>
			<sub class="black-50">Total {{:: ctrl.subject }}</sub>
		</div>
	</div>
</div>

<div class="panel md-whiteframe-1dp flex-auto">
	<div class="chart-container md-padding layout-column layout-align-center-center">
		<div id="the-chart" ng-hide="ctrl._defer" role="img" aria-label="{{ctrl.title +' chart'}}"></div>
	</div>
		<!-- <md-tabs flex>
			<md-tab label="Chart View" layout="column" layout-align="stretch start">
				<div class="chart-container md-padding" flex layout layout-align="center center">
					<div id="the-chart" ng-hide="ctrl._defer"></div>
				</div>
			</md-tab>
			<md-tab label="Table View">
				<div class="md-padding-2 text-center">
					<table class="table data-table">
						<tr class="black-33">
							<th class="text-center" ng-repeat="key in ctrl.dataTable.headers track by $index" ng-class="$index%2 ? 'text-right' : 'text-left'">{{ key }}</th>
						</tr>
						<tr ng-repeat="item in ctrl.dataTable">
							<td ng-repeat="key in ctrl.dataTable.headers track by $index" ng-class="$index%2 ? 'text-right' : 'text-left'">{{ item[$index] }}</td>
						</tr>
					</table>
				</div>
			</md-tab>
		</md-tabs> -->
</div>
