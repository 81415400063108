import angular from 'angular'
import uiRoute from '@uirouter/angularjs'
import ngMaterial from 'angular-material'
import ngJwt from 'angular-jwt'

import commonModule from '../common'

import routing from './guest.routing'
import monthlyReportRouting from './monthly-reports/monthly-report.routing'

import LoginController from './login.controller'
import ForgotPassController from './forgotpass.controller'
import ResetPassController from './resetpass.controller'


export default 
	angular.module('app.guestModule', [
		uiRoute, 
		ngMaterial,
		ngJwt,
		commonModule,
	])

	.config(routing)
	// .config(monthlyReportRouting)

	.controller(LoginController.name, LoginController)
	.controller(ForgotPassController.name, ForgotPassController)
	.controller(ResetPassController.name, ResetPassController)

	.run(['$templateCache', ($templateCache)=>{
		$templateCache.put('report.monthly.tpl', require('../main/analytics/report.monthly.tpl.html'));
	}])

	.name;