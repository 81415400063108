<header id="college-list-header" class="md-whiteframe-2dp flex-none flex-order--5">
	<form name="headerForm" novalidate ng-model-options="{updateOn:'default blur', debounce:{'default':1000, 'blur':0}}">
		<div class="layout-row layout-align-start-center layout-align-gt-sm-space-between-stretch layout-wrap">
			<h2 id="pageTitle" class="md-headline flex">Colleges</h2>

			<div class="flex-100 flex-gt-sm-50 layout-row">
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SEARCH -->
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERBTN -->
			</div>
		</div>
		
		<div class="filter-group md-chips md-removable">
			<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERCHIPS -->
			
			<div class="filters-nav-wrap flex">
				<md-sidenav class="filters-nav md-sidenav-right layout-column layout-gt-sm-row layout-gt-sm-wrap layout-align-start-stretch" 
					role="group" aria-labelledby="rightSideNav"
					md-component-id="filters-nav" md-is-locked-open="mainCtrl.$mdMedia('gt-sm')" ng-cloak>
					<b id="rightSideNav" class="md-headline hide-gt-sm">Filters &amp; Sort</b>
					<md-input-container class="filters" md-no-float="true">
						<md-select name="status" 
							aria-label="Filter by status"
							ng-model="ctrl.filters._status"
							md-selected-text="'Status'" 
							ng-change="ctrl.onFiltersChange()">
							<md-option ng-repeat="item in ctrl.mapping.status" ng-value="item">{{ item.name }}</md-option>
						</md-select>
					</md-input-container>
					<md-input-container class="filters" md-no-float="true">
						<md-select name="test" 
							aria-label="Filter by test college"
							ng-model="ctrl.filters._test"
							md-selected-text="'Test'" 
							ng-change="ctrl.onFiltersChange()">
							<md-option ng-repeat="item in ctrl.mapping.test" ng-value="item">{{ item.name }}</md-option>
						</md-select>
					</md-input-container>

					<div class="make-right layout-xs-column layout-sm-column layout-gt-sm-row layout-wrap">
						<span class="flex-gt-md"></span>
						<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SORTS -->
					</div>
				</md-sidenav>
			</div>
		</div>
	</form>
</header>

<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_ERROR -->
<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

<footer class="md-whiteframe-2dp layout-align-start-center layout-row layout-wrap">
	<md-button class="md-icon-button md-mini md-primary" 
		aria-label="Refresh results" title="Refresh results" 
		ng-click="ctrl.refresh(true)">
		<span class="fas fa-sync-alt"></span>
	</md-button>
	<div id="footerResults" class="md-body-1">
		{{ ctrl.query.total }}<span class="hide-xs"> Results</span>
	</div>
	<span flex></span>
	
	<md-button class="md-primary" 
		aria-label="add new college" 
		ui-sref=".add" 
		ng-if=":: mainCtrl.hasPermission(PERMISSIONS.CREATE_COLLEGES)">
		<span class="fas fa-plus"></span>
		<span class="hide-xs">Add</span> New
	</md-button>
	<md-button id="footerExport" class="md-accent" 
		aria-labelledby="footerExport footerResults"
		ng-click="ctrl.exportCSV('colleges.csv')">
		<span class="fas fa-file-export"></span> 
		Export <span class="hide-xs">CSV</span>
	</md-button>
</footer>

<main class="main-list colleges-list flex flex-order--1 layout-column"
	aria-labelledby="pageTitle footerResults">
	<md-virtual-repeat-container class="data-list flex no-animate" 
		md-top-index="ctrl.itemIndex" 
		ng-if="ctrl.dataList.length > 0">
		<div id="item-{{ item._id }}" class="data-item college-item layout-column no-animate" ng-class="{loading: !item}" 
			md-virtual-repeat="item in ctrl" md-on-demand="true">
			<div class="layout-row flex" 
				role="group" aria-label="College id: #{{item._id}}"
				ui-sref="app.colleges.edit({id: item._id})" ng-include="::'/main/colleges/college.item.tpl'"></div>
		</div>
	</md-virtual-repeat-container>

	<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_EMPTY_RESULTS -->

	<section id="expanded-overlay" class="no-animate layout-column"
		role="group" aria-label="College {{ctrl.selected.name}} showing campuses"
		ng-show="!!ctrl.selected && !ctrl.isBusy" ng-cloak>
		<div class="data-list md-whiteframe-1dp"
			ng-if="ctrl.selected" 
			ng-init="item = ctrl.selected">
			<div class="data-item college-item">
				<div class="layout-row" 
					role="group" aria-label="College id: #{{item._id}}"
					ui-sref="app.colleges.edit({id: item._id})" 
					ng-include="::'/main/colleges/college.item.tpl'"></div>
			</div>
			<md-toolbar class="md-accent md-hue-3 md-danger-theme md-whiteframe-1dp">
				<div class="md-toolbar-tools layout">
					<h4 class="flex md-title padding-2x normal-wg">Campuses</h4>
					<md-button class="md-icon-button black-66" aria-label="Hide campuses" ng-click="ctrl.close()">
						<i class="far fa-times-circle md-title" aria-hidden="true"></i>
					</md-button>
				</div>
			</md-toolbar>
		</div>
		<div class="data-list flex layout-column can-scroll-y indent-2x">
			<div class="data-item md-body-1 flex-none" 
				role="group" aria-label="campus #{{$index+1}}"
				ng-repeat="campus in ctrl.selected.campuses track by $index">
				<div class="md-margin md-subhead black-75">{{ campus.name }}</div>
				<div class="md-margin black-50" layout layout-wrap>
					<span class="md-padding-1" flex="100" flex-gt-xs="33" flex-gt-md="20">
						<span class="fas fa-phone"
							role="img" aria-label="phone:"></span>
						<em class="black-25" ng-hide="campus.phone">none</em>
						<span ng-show="campus.phone">{{ campus.phone }}</span>
					</span>
					<span class="md-padding-1" flex="100" flex-gt-xs="66" flex-gt-md="20">
						<span class="fas fa-globe-americas"
							role="img" aria-label="website:"></span>
						<em class="black-25" ng-hide="campus.website">none</em>
						<a ng-show="campus.website" ng-href="{{ campus.website }}" target="_blank" title="{{ campus.website }}">
							{{ campus._websiteName || campus.website }}
						</a>
					</span>
					<span class="md-padding-1" flex="100" flex-gt-xs="33" flex-gt-md="15">
						<span class="fas fa-map-marked-alt" 
							role="img" aria-label="zip code:"></span>
						<em class="black-25" ng-hide="campus.zip_code">none</em>
						<span ng-show="campus.zip_code">{{ campus.zip_code }}</span>
					</span>
					<span class="md-padding-1" flex="100" flex-gt-xs="66" flex-gt-md="45">
						<span class="fas fa-map-marker-alt" 
							role="img" aria-label="map coordinates:"></span>
						<em class="black-25" ng-hide="campus.latitude && campus.longitude">none</em>
						<span ng-show="campus.latitude && campus.longitude">
							{{ campus._latitudeStr }} {{ campus._longitudeStr }}
						</span>
					</span>
				</div>
			</div>
			<div ng-show="ctrl.selected && !ctrl.selected.campuses.length && !ctrl.partialBusy" flex layout layout-align="center center">
				<p class="text-center alpha-50 md-caption"><em>No results found</em></p>
			</div>
		</div>
	</section>
</main>


<script type="text/ng-template" id="/main/colleges/college.item.tpl">
	<div class="flex layout-row layout-wrap">
		<div class="data-id flex-xs-100 flex-sm-100 layout-row layout-align-space-between-center layout-wrap layout-gt-sm-column layout-align-gt-sm-start-end padding-2x">
			<a class="md-caption md-block"
				aria-label="college ID #{{item._id}}"
				ui-sref="app.colleges.edit({id: item._id})">ID#{{ item._id }}</a>
			<div hide-xs hide-sm flex-gt-sm></div>
			<span>
				<span class="small-tag md-caption status" ng-class="item.status">{{ item.status }}</span>
				<span class="small-tag md-caption warn" ng-show="!!item.test">test</span>
			</span>
			<div hide-xs hide-sm flex-gt-sm></div>
		</div>
		<div class="data-content padding-2x flex layout-column layout-align-space-between-stretch">
			<h3 class="md-subhead">
				<a class="md-truncate" ui-sref="app.colleges.edit({id: item._id})">{{ item.name }}</a>
			</h3>
			<div class="item-description md-truncate">
				<!-- <i class="black-33 fas fa-file-invoice"></i>&nbsp;
				<a class="link" ui-sref="app.analytics.monthlyReport({college: item._id})">Monthly Report</a>
				&nbsp;&nbsp;&nbsp;&nbsp; -->
				<i class="black-33 fas fa-globe-americas" 
					role="img" aria-label="website"></i>
				<a class="alpha-75" ng-show="item.website" ng-href="{{ item.website }}" target="_blank" ng-click="$event.stopPropagation()" title="{{item.website}}">
					{{ item._websiteName || item.website }}
				</a>
				<em class="black-25" ng-hide="item.website || !item">no website</em>
			</div>

      <div class="layout-row layout-align-space-between-start">
				<div class="md-chips md-readonly flex" ng-show="item.district_id">
					<md-chip aria-label="district: {{ ctrl.mapping.districts.byId[item.district_id].name || item.district_id }}">
						<i class="far fa-map" aria-hidden="true"></i>&nbsp;
						{{ ctrl.mapping.districts.byId[item.district_id].name || item.district_id }}
					</md-chip>
				</div>

				<span class="flex" ng-show="!item.district_id"></span>
				<md-button class="action-btn md-button pull-left black-50" 
					aria-label="{{(ctrl.selected===item ? 'hide' : 'show') + ' '+ item.campuses.length}} campuses"
					aria-expanded="{{ ctrl.selected == item }}"
					ng-show="!ctrl.hideReminders" 
					ng-disabled="!item.campuses.length" 
					ng-click="ctrl.selected !== item ? ctrl.open(item) : ctrl.close(); $event.stopPropagation();">
					<i class="far fa-building"></i> {{ item.campuses.length }} Campus
					&nbsp;&nbsp;<i class="fas" ng-class="{'fa-angle-down': ctrl.selected != item, 'fa-angle-up': ctrl.selected == item}"></i>
				</md-button>
			</div>
		</div>
		<div class="layout-row padding-1x">
			<md-button class="go-edit flex md-block layout-row layout-align-center-center" 
				aria-label="open college: {{item.name}}"
				ui-sref="app.colleges.edit({id: item._id})">
				&rsaquo;
			</md-button>
		</div>
	</div>
</script>