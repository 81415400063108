<header class="flex-none layout-column layout-gt-xs-row layout-align-gt-xs-space-between start layout-wrap md-whiteframe-2dp">
  <h2 id="pageTitle" class="flex-xs-100 md-headline">
    <a ui-sref="^" ui-sref-opts="ctrl.parentStateParams">Cohorts <span class="margin-1x" aria-hidden="true">&#9656;
    </span></a>{{ ctrl.data ? '' : 'Add New' }}
    <span id="docTitle" ng-show="ctrl.data">{{:: ctrl.data ? ctrl.data.description || ctrl.data.name : 'new cohort'}}</span>
    <span role="img" aria-label="changes not saved" title="changes not saved" ng-show="form.$dirty">*</span>
  </h2>
	<span class="top-buttons flex-none" ng-hide="!ctrl.data">
    <md-button class="md-primary" 
      ui-sref="app.cohorts.edit.students({id:ctrl.data._id})" 
      ng-show="ctrl.data"
      ng-if=":: mainCtrl.hasPermission(PERMISSIONS.READ_COHORT_COLLEGE_EXTERNAL_IDS) && mainCtrl.hasPermission(PERMISSIONS.UPDATE_COHORT_COLLEGE_EXTERNAL_IDS)">
			<span class="far fa-id-card"></span>
      Manage Student IDs
		</md-button>
	</span>

  <div flex="100" ng-include="::'common/lockCard.tpl'"></div>
</header>

<main flex-gt-sm ng-model-options="{updateOn:'default blur', debounce:{'default':250, 'blur':0}}">
	<form id="cohort-form" name="form" novalidate class="flex-gt-md" 
    ng-class="{locked: ctrl.isLocked}" 
    ng-submit="!ctrl.isBusy && !ctrl.isLocked && ctrl.submit($event, form)" 
    form-disabled="ctrl.isBusy || ctrl.isLocked">
    <input type="submit" class="hide" tabindex="-1">

    <div class="panel panel-small" md-whiteframe="1">
      <div class="panel-title md-padding md-title normal-wg">Cohort Details</div>
			<div class="panel-content md-padding">
        <md-input-container class="md-block md-input-has-placeholder" form-disabled="ctrl.data">
          <label>Type</label>
          <md-select name="type" required
            ng-model="ctrl.model.cohort_type"
            ng-disabled="$formDisabled"
            ng-change="ctrl.typeChanged()">
            <md-option ng-repeat="(key, value) in ctrl.mapping.types" ng-value="key">{{value}}</md-option>
          </md-select>
          <div class="md-errors-spacer"></div>
          <div ng-messages="form.type.$error" role="alert">
            <div ng-message="required">Required</div>
          </div>
        </md-input-container>

        <md-input-container class="md-block md-input-has-placeholder">
          <label>Name</label>
          <input name="description" ng-model="ctrl.model.description" required />
          <div ng-messages="form.description.$error" role="alert">
            <div ng-message="required">Required</div>
            <div ng-message="duplicate">Already used</div>
          </div>
        </md-input-container>

        <md-input-container class="md-block md-input-has-placeholder" ng-show="ctrl.data">
          <label>Name ID</label>
          <input name="name" ng-model="ctrl.model.name" ng-disabled="::true" />
        </md-input-container>

        <md-input-container class="md-block md-input-has-placeholder no-error-spacer" ng-show="ctrl.model.cohort_type == 'beyond12_cohort'">
          <label>Salesforce Tag</label>
          <input name="salesforce_tag" ng-model="ctrl.model.salesforce_tag" />
          <div ng-messages="form.salesforce_tag.$error" role="alert">
            <div ng-message="duplicate">Already used</div>
          </div>
          <div class="hint">Optional: if set, this cohort's Student IDs will be managed by Salesforce.</div>
        </md-input-container>

        <md-input-container class="md-block md-input-has-placeholder" ng-if="['beyond12_coaches', 'beyond12_coaching_corps_coaches'].includes(ctrl.model.cohort_type)">
          <label>Associated Coach</label>
          <md-select name="coach" required
            data-md-container-class="select-with-search"
            ng-model="ctrl.model.owner_user_id"
            ng-disabled="$formDisabled"
            md-on-close="ctrl.model.owner_user_id && (ctrl._$coaches = ctrl.findCoach(ctrl.findCoach.search))">
            <md-select-header>
              <i class="black-50 fas fa-search" title="search"></i>
              <input type="search" class="header-search" placeholder="Search" autocomplete="off"
                aria-label="search"
                ng-keydown="$event.stopImmediatePropagation()"
                ng-click="$event.stopImmediatePropagation()"
                ng-model-options="{debounce:{'default':300, 'blur':0}, '*':'$inherit'}"
                ng-model="ctrl.findCoach.search" ng-change="ctrl._$coaches = ctrl.findCoach(ctrl.findCoach.search)">
            </md-select-header>
            <md-option class="no-animate" ng-value="user._id" ng-repeat="user in ctrl._$coaches">
              {{ user.user_profile.first_name +' '+ user.user_profile.last_name }}
            </md-option>
            <md-option class="no-animate" ng-if="!ctrl._$coaches.length" disabled="disabled">
              <small><em>{{ ctrl.findCoach.search ? 'No search results' : 'No coach available' }}</em></small>
            </md-option-group>
          </md-select>
          <div class="md-errors-spacer"></div>
          <div ng-messages="form.coach.$error" ng-if="form.coach.$touched">
            <div ng-message="required">Required</div>
          </div>
        </md-input-container>
      </div>
    </div>
	</form>
</main>

<div ng-show="ctrl.isBusy" class="busy-overlay fade-out" layout layout-align="center center">
	<md-progress-circular md-mode="indeterminate"></md-progress-circular>
</div>

<footer class="text-right layout-row" md-whiteframe="2" ng-hide="ctrl.isLocked" form-disabled="ctrl.isBusy || ctrl.isLocked">
  <i flex></i>
	<div class="flex-nogrow">
		<md-button type="button" class="md-raised md-warn" ng-click="ctrl.submitForm('form')">
			<span class="fas fa-save"></span> Save
		</md-button>
	</div>
</footer>
