import angular from 'angular'
import {DependencyInjected, Helper, ApiError} from '../../common'
import {menuData} from '../../main/main.controller'
import {pieChart} from './charts'


export default class OverviewController extends DependencyInjected {
	static get $inject(){return [
		'$scope',
		'$q',
		'$uiRouter',
		'authorization',
		'api',
		'apiMap',
		'toast',
		'MAPPINGS_JSON',
	]}

	init(){
		this.$scope.$parent.ctrl = this;
		this.main = this.$scope.mainCtrl;
		this.mapping = this.main.mapping;

		this.title = 'Overview';
		this.hideDateRange = true;

		const $stateRegistry = this.$uiRouter.stateRegistry;
		this.menuList = menuData.find((item)=>item.sref === 'app.analytics')?.children?.map(item=>{
			let reg = $stateRegistry.get(item.sref);
			return !reg?.access || this.authorization.hasPermissionAndLevel(reg.access) ? item : null;
		}).filter(v=>!!v);

		this.data = {};

		this.main._pending.then(()=>{
			this.$scope.$emit('data-ready');
			this.ready = true;
			this.refresh();
		});
	}

	refresh(){
		let params = angular.extend({
			// college: this.main.filters._mycolleges && this.main.filters._mycolleges.map(item=>item._id) || null,
			college: this.main.filters._mycolleges && this.main.filters._mycolleges._id || null,
		}, {
			myTokenAdministers: true,
		});
		let chartOptions = {chart:{width:300, height:160}, exporting:{enabled:false}};

		this.data = {};

		return this.api.get('analysis/notificationsPerType', params)
			.then((res)=>{
				this.data.notificationsPerType = res.data;
				let data = res.data.map((item)=>({name: item.name, y: item.count}));
				pieChart('notifications-per-type', data, chartOptions);

				this.data.totalNotifications = 0;
				res.data.forEach((item)=>this.data.totalNotifications += item.count);
			})

			.then(()=>this.api.get('analysis/studentsPerActiveStatus', params))
			.then((res)=>{
				this.data.studentsPerActiveStatus = res.data;
				let data = res.data.map((item)=>({name: item.status, y: item.count}));
				pieChart('students-per-active-status', data, chartOptions);

				this.data.totalStudents = 0;
				res.data.forEach((item)=>this.data.totalStudents += item.count);
			})

			.then(()=>this.api.get('analysis/studentsPerDeviceOS', params))
			.then((res)=>{
				this.data.studentsPerDeviceOS = res.data;
				let data = this.data.studentsPerDeviceOS.map((item)=>({name: item.os, y: item.count}));
				pieChart( 'students-per-device-os', data, chartOptions );
			})

			.catch(err=>console.error(err));
		;
	}

}

