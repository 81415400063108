import angular from 'angular'
import {DependencyInjected} from '../common'
import {ApiError} from '../common/services'


export default class ForgotPassController extends DependencyInjected {
	static get $inject(){return [
		'api',
		'toast',
		'$location',
		'$state',
	]}

	submit(){
		this.api.post('authentication/password/reset', {email:this.email, source:'console'}, {level: ApiError.LEVEL.MANUAL})
			.then((res)=>{
				this.success = true;
				this.isBusy = false;
			}, (err)=>{
				if ( this.api.isApiError(err) ) {
					if ( /^4\d\d$/.test(err.response.status) ) {
						this.toast.warn('Unrecognized email account');
					} else
					if ( ! err.isManual() ) {
						this.toast.error(err);
						if ( err.isWarning() )
							this.toast.warn(err.message);
					}
				}
				this.isBusy = false;
			});
		this.toast.clear();
		this.isBusy = true;
	}
}
