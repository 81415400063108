import angular from 'angular'
import {DependencyInjected, Helper, ApiError, MESSAGES} from '../common'


const SMS_BUFFER = 3000;

const TEMPLATE = {
  totp_okta: {
    type: 'totp',
    provider: 'okta',
    label: 'Okta Verify',
    desc: 'Enter your Okta Verify passcode',
    img: require('../common/images/okta.png'),
  },
  totp_google: {
    type: 'totp',
    provider: 'google',
    label: 'Google Authenticator',
    desc: 'Enter your Google Authenticator passcode',
    img: require('../common/images/googleAuth.png'),
  },
  sms_okta: {
    type: 'sms',
    provider: 'okta',
    label: 'SMS Verification',
    desc: 'A text message with verification code was just sent to your registered number',
    icon: 'fas fa-sms',
  },
}


export default class LoginMFAController extends DependencyInjected {
	
	static get $inject(){return [
		'api',
		'toast',
    'errorPrompt',
		'$scope',
    '$location',
		'$state',
		'$stateParams',
		'$timeout',
		'MAPPINGS_JSON',
	]}

	init(){
    let factors = [];

    this.$stateParams.factors.forEach(factor=>{
      if ( factor.providers?.length > 0 ) {
        factor.providers.forEach(provider=>{
          factors.push(TEMPLATE[`${factor.type}_${provider}`]);
        });
      } else {
        factors.push(TEMPLATE[factor.type]);
      }
    });
    this.factors = factors;
    this.selected = factors[0];

    this._lastSms = 0;

    if ( this.selected.type == 'sms' )
      this.sendChallenge();
	}

  _clear(){
    this.toast.clear();
		this.isBusy = false;
    this.isBusyChallenge = false;
		this.errorMsg = undefined;
  }

  changeSelected(){
    if ( this.selected.type == 'sms' ) {
      this.sendChallenge();
    } else {
      this.errorMsg = undefined;
    }
    this.$timeout(()=>$('input[name="pin_code"]').focus(), 300);
  }

  async sendChallenge(){
    if ( this.isBusyChallenge ) return;

    this._clear();
    this.isBusyChallenge = true;

    try {
      const payload = {
        user_id: this.$stateParams.id,
        type: this.selected.type,
        provider: this.selected.provider || undefined,
      };
      const cfg = {level: ApiError.LEVEL.MANUAL};
  
      await this.api.post('authentication/mfa/challenge', payload, cfg);
      this._lastSms = Date.now();
      this.$timeout(()=>this.isBusyChallenge = false, SMS_BUFFER);
    } catch (err) {
      if ( this.api.isApiError(err) && /^4\d\d$/.test(err.response.status) ) {
        if ( +err.response.status == 401 ) {
          this.toast.show('Session expired');
          return this.$state.go('guest.login');
        }
        const code = err.response.data?.error_code || Object.values(err.response.data?.errors || {})[0]?.code;
        this.$timeout(()=>{
          this.errorMsg = code && (this.MAPPINGS_JSON.authentication?.error?.[code] || MESSAGES.LOGIN.CODE[code]) || MESSAGES.API.UNEXPECTED_ERROR;
          this.isBusyChallenge = false;
        }, 2000);
      } else {
        this.errorPrompt.show(err);
        this.isBusyChallenge = false;
      }
    }
    this.$scope.$applyAsync();
  }

	async submit(form){
    if ( ! this.pin_code )
      this.$timeout(()=>form.pin_code?.$$element?.focus(), 300);
    if ( this.isBusy || !form.$valid ) return;

    this._clear();
    this.isBusy = true;

    try {
      const payload = {
        user_id: this.$stateParams.id,
        type: this.selected.type,
        provider: this.selected.provider || undefined,
        pin_code: this.pin_code,
      };
      const cfg = {level: ApiError.LEVEL.MANUAL};
  
		  await this.api.post('authentication/mfa/verify', payload, cfg); 
      this.toast.success('Login successful');
      this.$timeout(()=>this.$location.url(this.$state.params.redirect || '/'), 1000);
    } catch(err) {
      if ( this.api.isApiError(err) && /^4\d\d$/.test(err.response.status) ) {
        const code = err.response.data?.error_code || Object.values(err.response.data?.errors || {})[0]?.code;
        this.$timeout(()=>{
          this.errorMsg = code && (this.MAPPINGS_JSON.authentication?.error?.[code] || MESSAGES.LOGIN.CODE[code]) || MESSAGES.API.UNEXPECTED_ERROR;
          this.isBusy = false;
        }, 2000);
      } else {
        this.errorPrompt.show(err);
        this.isBusy = false;
      }
    }
    this.$scope.$applyAsync();
	}
}
