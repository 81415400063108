<header class="md-whiteframe-2dp flex-none" layout="column" layout-gt-lg="row" layout-align-gt-lg="space-between start">
	<h2 class="md-headline">
		Monthly Report {{ ctrl.selected ? (' - '+ ctrl.selected.name) : '' }}
	</h2>
	
	<div layout layout-align="end start" ng-show="ctrl.selected">
		<!-- <md-input-container class="flex" ng-show="ctrl.selected">
			<label>Public Link</label>
			<input type="text" click-select class="black-66" ng-value="ctrl.getShareLink()" readonly="readonly">
		</md-input-container> -->
		<md-input-container class="md-input-has-value" ng-show="ctrl.mapping.mycolleges.length > 1">
			<label>College</label>
			<md-select name="college" ng-model="ctrl.selected" ng-change="ctrl.refresh()" ng-disabled="ctrl.isBusy" aria-label="college">
				<md-option ng-repeat="item in ctrl.mapping.mycolleges" ng-value="item">{{item.name}}</md-option>
			</md-select>
		</md-input-container>
	</div>
</header>

<form id="report-monthly" name="form" ng-submit="form.$valid && ctrl.submit()" flex-gt-sm layout="column" form-disabled="ctrl.isBusy">

	<main layout="column" layout-align="center center" ng-hide="ctrl.selected" flex>
		<em class="black-50 md-body-1">Select a college to show it's report</em>
		<md-input-container md-no-float="true">
			<label>Select a College</label>
			<md-select name="colelge" ng-model="ctrl.selected" ng-change="ctrl.refresh()" ng-disabled="ctrl.isBusy" aria-label="college">
				<md-option ng-repeat="item in ctrl.mapping.mycolleges" ng-value="item">{{item.name}}</md-option>
			</md-select>
		</md-input-container>
	</main>

	<main ng-show="ctrl.selected" flex>
		<div class="panel" md-whiteframe="1" ng-repeat="(key, reportTables) in ctrl.dataTables track by $index">
			<div class="panel-title padding-4x">
				<h3 class="md-title margin-0x">
					{{ ctrl.mapping[key].title }} ({{ ctrl.dateSet }})
					<small class="normal-wg" ng-show="ctrl.mapping[key].subtitle"><br>{{ ctrl.mapping[key].subtitle }}</small>
				</h3>
			</div>
			<div class="panel-content" ng-show="reportTables && reportTables.length > 0">
				<div class="margin-4x can-scroll-x can-scroll-y" ng-repeat="table in reportTables track by $index" ng-include="::'report.monthly.tpl'"></div>
			</div>
			<div class="padding-4x layout layout-align-center-center" ng-show="!reportTables">
				<md-progress-circular md-mode="indeterminate"></md-progress-circular>
			</div>
			<div class="padding-4x black-25 md-caption text-center" ng-show="reportTables && reportTables.length === 0">
				<em>No data available</em>
			</div>
		</div>
	</main>

	<div class="busy-overlay fade" layout layout-align="center center" ng-show="ctrl.isBusy">
		<md-progress-circular md-mode="indeterminate"></md-progress-circular>
	</div>
</form>
