import angular from 'angular'
import uiRoute from '@uirouter/angularjs'
import ngMaterial from 'angular-material'
import ngSanitize from 'angular-sanitize'
import ngAnimate from 'angular-animate'
import 'angular-moment-picker'
import 'angular-ui-sortable'
import 'tinymce'

import coreModule from '../common/common.module'
import {TEMPLATES, TEMPLATE_FILES} from './templates'

import routing from './main.routing'
import eventsRouting from './events/events.routing'
import tipsRouting from './tips/tips.routing'
import workspacesRouting from './workspaces/workspaces.routing'
import milestonesRouting from './milestones/milestones.routing'
import cohortsRouting from './cohorts/cohorts.routing'
import flowsRouting from './flows/flows.routing'
import districtsRouting from './districts/districts.routing'
import collegesRouting from './colleges/colleges.routing'
import usersRouting from './users/users.routing'
import analyticsRouting from './analytics/analytics.routing'
import activitylogRouting from './activitylog/activitylog.routing'
import sitemapRouting from './sitemap/sitemap.routing'


export default 
	angular.module('app.mainModule', [
		uiRoute,
		ngMaterial,
		ngSanitize,
		ngAnimate,
		'moment-picker',
		'ui.sortable',
		coreModule,
	])

	.config(routing)
	.config(eventsRouting)
	.config(tipsRouting)
	.config(workspacesRouting)
	.config(milestonesRouting)
	.config(cohortsRouting)
	.config(flowsRouting)
	.config(districtsRouting)
	.config(collegesRouting)
	.config(usersRouting)
	.config(analyticsRouting)
	.config(activitylogRouting)
	.config(sitemapRouting)

	.run(['$templateCache', '$rootScope', ($templateCache, $rootScope)=>{
		for(const item of Object.values(TEMPLATE_FILES)) {
			$templateCache.put(item.name, item.file);
		}
		$rootScope.TEMPLATES = TEMPLATES;
	}])

	.run(['$uiRouter', 'authorization', '$timeout', ($uiRouter, authorization, $timeout)=>{
		$uiRouter.transitionService.onEnter({to:'app.**'}, (transition)=>{
			if ( ! authorization.isValid() ) {
				// let url = transition.to()?.url || undefined;
				let url = $uiRouter.stateService.href(transition.to().name, transition.params());
				return $uiRouter.stateService.target('guest.login', {redirect: url != '/' ? url : undefined});
			}
		});

		$uiRouter.transitionService.onFinish({to:'app.**'}, (transition)=>{	
			let stateDeclare = $uiRouter.stateRegistry.get(transition.to().name);
			if ( stateDeclare?.access && ! authorization.hasPermissionAndLevel(stateDeclare.access) ) {
				console.info('not allowed', stateDeclare.auth);
				$timeout(()=>$uiRouter.stateService.go('app.403', undefined, {location:false}), 0);
			}
		})
	}])

	.name;