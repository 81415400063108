<header class="flex-none layout-column layout-gt-xs-row layout-align-gt-xs-space-between start layout-wrap md-whiteframe-1dp">
	<h2 class="md-headline flex">
		Salesforce Control Panel
	</h2>
	<span class="top-buttons flex-none" ng-cloak>
		<md-button class="md-primary md-hue-2" ng-click="ctrl.refresh()" ng-disabled="ctrl.isBusy">
			<span class="fas fa-sync-alt"></span> Refresh
		</md-button>
	</span>

	<md-card ng-show="ctrl.isLocked && ctrl.lockReason" class="no-animate flex-100" md-theme="::'info'">
		<md-card-content layout>
			<span><span class="fas fa-info-circle"></span>&nbsp;&nbsp;</span>
			<span flex ng-bind-html="ctrl.lockReason"></span>
		</md-card-content>
	</md-card>
</header>

<main id="sf-panel-status" class="flex" ng-class="{'has-data': ctrl.data}">
	<div class="md-whiteframe-1dp top-panel padding-8x">
		<div class="panel-content">
			<div class="grid">
				<strong class="label-wrap md-headline normal-wg">Sync status: </strong>
				<strong class="value-wrap md-headline text-uppercase normal-wg" ng-class="ctrl.data.status=='running'? 'sync-enabled' : ''">{{ ctrl.data.status }}</strong>

				<span></span>
				<span></span>

				<span class="cell label-wrap black-50" ng-show="ctrl.data.current_run_start_timestamp">
					Running since:
				</span>
				<span class="cell value-wrap">
					{{ ctrl.data.current_run_start_timestamp.fromNow() }}
					<span class="black-75 no-wrap">({{ ctrl.formatRelativeDate(ctrl.data.current_run_start_timestamp) }})</span>
				</span>

				<span class="cell label-wrap black-50">Last run: </span>
				<span class="cell value-wrap" ng-show="ctrl.data.last_run_start_timestamp">
					{{ ctrl.data.last_run_start_timestamp.fromNow() }}
					<span class="black-75 no-wrap">({{ ctrl.formatRelativeDate(ctrl.data.last_run_start_timestamp) }})</span>
				</span>

				<span class="cell label-wrap black-50">Automatic schedule: </span>
				<span class="cell value-wrap">
					<span class="text-lowercase" ng-show="ctrl.data.scheduled">
						{{ ctrl.data.cron_desc || 'cron expr ("'+ ctrl.data.cron_expression +'"")' }}
					</span>
					<span class="red text-uppercase" ng-hide="ctrl.data.scheduled">
						disabled
					</span>
				</span>

				<span class="cell label-wrap black-50">Last duration: </span>
				<span class="cell value-wrap">{{ ctrl.data.last_duration }}</span>

				<span class="cell label-wrap black-50">Next scheduled run: </span>
				<span class="cell value-wrap">
					<span ng-show="ctrl.data.scheduled && ctrl.data.next_run">
						{{ ctrl.data.next_run.fromNow() }}
						<span class="black-75 no-wrap">({{ ctrl.formatRelativeDate(ctrl.data.next_run) }})</span>
					</span>
					<span ng-hide="ctrl.data.scheduled && ctrl.data.next_run">
						&mdash; &mdash;
					</span>
				</span>
			</div>
		</div>
	</div>

	<div class="layout-gt-sm-row layout-align-gt-sm-start-start" form-disabled="ctrl.isBusy">
		<div class="panel flex-gt-sm-50 md-whiteframe-1dp">
			<div class="panel-title padding-4x">
				<h2 class="md-title normal-wg">
					Automatic Scheduled Sync: <span class="value-wrap text-uppercase" ng-class="ctrl.data.scheduled ? 'green' : 'red'">{{ ctrl.data.scheduled ? 'enabled' : 'disabled' }}</span>
				</h2>
			</div>
			<div class="panel-content padding-4x">
				<div class="md-body-1 black 66">
					<p>
						Automatic scheduled sync is set to run <span class="value-wrap text-lowercase">{{ ctrl.data.cron_desc || 'cron expr: '+ ctrl.data.cron_expression }}</span>.
					</p>
					<p ng-show="ctrl.data.scheduled">
						The next scheduled sync will run <span class="value-wrap">{{ ctrl.data.next_run.fromNow() }}</span>. Disabling will prevent the next and future scheduled sync to be run.
					</p>
					<p ng-show="!ctrl.data.scheduled">
						Scheduled sync is currently <span class="value-wrap text-uppercase">{{ ctrl.data.scheduled ? 'enabled' : 'disabled' }}</span>. Enabling will schedule the next sync run.
					</p>
				</div>
				<div class="margin-4x text-center">
					<md-button class="md-warn md-hue-2 md-raised wide" ng-show="ctrl.data.scheduled" ng-click="ctrl.pauseSchedule($event)">
						<i class="far fa-times-circle"></i>
						Disable Automatic Schedule
					</md-button>
					<md-button class="md-accent md-hue-1 md-raised wide" ng-show="!ctrl.data.scheduled" ng-click="ctrl.resumeSchedule($event)">
						<i class="far fa-check-circle"></i>
						Enable Automatic Schedule
					</md-button>
				</div>
			</div>
		</div>

		<div class="panel flex-gt-sm-50 md-whiteframe-1dp margin-4x">
			<div class="panel-title padding-4x"><h2 class="md-title normal-wg">Manual Sync</h2></div>
			<div class="panel-content padding-4x">
				<div class="margin-4x text-center" form-disabled="ctrl.data.status=='running'">
					<md-button class="md-warn md-hue-1 md-raised wide" ng-click="ctrl.runManualSync($event)">
						<i class="far fa-play-circle"></i>
						{{ ctrl.data.status=='running' ? 'Sync Running...' : 'Run Sync Now' }}
					</md-button>
				</div>
			</div>
		</div>
	</div>
</main>

<div ng-show="ctrl.isBusy" class="busy-overlay fade-in fade-out" layout layout-align="center center">
	<md-progress-circular md-mode="indeterminate"></md-progress-circular>
</div>

<!-- <footer class="text-right" md-whiteframe="2" ng-hide="ctrl.isLocked">
	<md-button type="button" class="md-raised md-primary" ng-disabled="ctrl.isBusy || form.$pending" ng-click="ctrl.submit(form, $event)">
		<span class="far fa-save"></span> Save {{ ctrl.data ? 'Changes' : 'User' }}
	</md-button>
</footer> -->
