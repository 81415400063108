<header class="md-whiteframe-2dp flex-none layout-row layout-align-space-between-start layout-wrap">
	<h2 id="pageTitle" class="md-headline flex-xs-100">
		<a ui-sref="^" ui-sref-opts="ctrl.parentStateParams">Districts <span class="margin-1x" aria-hidden="true">&#9656;
		</span></a>{{ ctrl.data ? '' : 'Add New' }}
		<span id="docTitle" ng-show="ctrl.data">{{:: ctrl.data ? ctrl.data.name : 'new district'}}</span>
		<span role="img" aria-label="changes not saved" title="changes not saved" ng-show="form.$dirty">*</span>
	</h2>
	<div class="top-buttons flex-xs-100" ng-show="ctrl.data" form-disabled="ctrl.isBusy">
		<md-button class="md-warn" id="deleteBtn" aria-labelledby="deleteBtn docTitle" 
			ng-click="ctrl.delete($event)"
			ng-if=":: mainCtrl.hasPermission(PERMISSIONS.DELETE_DISTRICTS)"
			ng-hide="ctrl.isLocked">
			<span class="far fa-trash-alt"></span> 
      Delete
		</md-button>
	</div>
</header>

<form id="college-form" name="form" novalidate 
	class="flex layout-column"
	form-disabled="ctrl.isBusy"
	ng-submit="!ctrl.isBusy && ctrl.submit($event, form)">
	<main flex>
		<div class="panel panel-small" md-whiteframe="1">
			<fieldset class="md-padding" layout="column" ng-disabled="ctrl.isBusy">
				<legend class="hide">District</legend>
				<md-input-container class="md-input-has-placeholder">
					<label>Name</label>
					<input type="text" name="name" ng-model="ctrl.model.name" required md-autofocus>
					<div ng-messages="form.name.$error" role="alert">
						<div ng-message="required">Required</div>
					</div>
				</md-input-container>
				<md-input-container class="md-block md-input-has-placeholder">
					<label>Type</label>
					<md-select name="type" ng-model="ctrl.model.type" required>
						<md-option ng-repeat="(key, value) in ctrl.mapping.types" ng-value="key">{{value}}</md-option>
					</md-select>
					<div class="md-errors-spacer"></div>
					<div ng-messages="form.type.$error" role="alert">
						<div ng-message="required">Required</div>
					</div>
				</md-input-container>
				<md-input-container class="md-block md-input-has-placeholder">
					<label>Status</label>
					<md-select name="status" ng-model="ctrl.model.status">
						<md-option ng-repeat="(key, value) in ctrl.mapping.status" ng-value="key">{{value}}</md-option>
					</md-select>
					<div class="md-errors-spacer"></div>
				</md-input-container>
			</fieldset>
		</div>
	</main>

	<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

	<footer md-whiteframe="2" layout="row" layout-align="end start">
		<md-button type="submit" class="md-raised md-warn" ng-disabled="ctrl.isBusy">
			<span class="far fa-save"></span> Save
		</md-button>
	</footer>
</form>
