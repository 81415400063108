import {Helper, ApiError, SETTINGS, MESSAGES, CONSTANTS} from '../../common'
import BaseRecipientSingleController from '../base.recipient.single'
import $ from 'jquery'
import {mxGraph, Graph} from './graph.class'
import ChatSimulator from './chatSimulator.class'
import {BGCOLORS} from '../milestones/milestone.single.controller'


const IMG_DIR = require('../../common/images/MyC3-0_Nav_Profile_Default.svg');
const IMG_HOM = require('../../common/images/MyC3-0_Nav_Home_Active.svg');
const IMG_SAVE = require('../../common/images/save-icon.svg');
const IMG_SHARE = require('../../common/images/share-icon.svg');
const IMG_FLOW = require('../../common/images/flow-icon.svg');

const RESOURCETYPE = {
	IMG: 'flow_image',
	QUIZ_COMPE_IMG: 'quiz_competency_image',
};

export default class FlowSingleController extends BaseRecipientSingleController {
	static get $inject(){return BaseRecipientSingleController.$inject.concat([
		'clone',
		'parentStateParams',
		'$mdSidenav',
		'$timeout',
		'$q',
		'$http',
		'$sce',
		'authorization',
		'mimetype',
		'chatMatrix',
		'$location',
		'$window',
	])}

	static get DEFAULTS(){return {
		recipients: [],
		flow:{},
	}}


	init(){
		this.mapping = {
			timezones: moment.tz.names(),
			flowColors: ['#0F38E1', '#4467FF', '#2AA575', '#B261F4', '#6B57FA', '#00A39C'],
			layouts: {'Default': 'default', 'Welcome':'welcome'},
			bgColors: this.MAPPINGS_JSON.content?.directory?.card?.colors || BGCOLORS,
		};
		this.DEFAULT_BGCOLOR = '#2AA575';
		this.IMG_DIR = IMG_DIR;
		this.IMG_HOM = IMG_HOM;
		this.IMG_SAVE = IMG_SAVE;
		this.IMG_SHARE = IMG_SHARE;
		this.IMG_FLOW = IMG_FLOW;
		this.background_color_label;

    	this.bgColorsTemp;
		this.preview = {auto: true, screen:'home'};
		this.chatsim = new ChatSimulator(this.$scope, this.$q, this.chatMatrix, this.toast);
		
		this._destructors.push(
			this.$transitions.onRetain({retained: this.$state.current.name}, transition=>{
				let params = transition.paramsChanged();

				if ( /^\d+$/.test(params.id) && this.data && this.data._id ) {
					// update data token on route resolve to properly update page title on transition success
					transition.addResolvable({
						token: 'data',
						deps: [],
						resolveFn: ()=>this.data,
					}, transition.to().name);
				}

				this.$timeout(()=>this.isBusy = false, 500);
			}),

			this.$scope.$watch('ctrl.model.schedule.start_date', (value, old)=>{
				if ( ! old && value ) {
					value.hour(2).minute(40).second(0).millisecond(0);
				}
			}),

			()=>{
				this.graph?.destroy();
				if ( this.chatMatrix?.isConnected() ) {
					this.chatsim.stop();
					this.chatMatrix.disconnect();
				}
			},

			this.$scope.$once(CONSTANTS.SCOPE_EVENTS.DATA_READY, ()=>{
				this._destructors.push(
					// this might be redundant fr base.single.js, but that is only triggered once. We need it to be always listening
					this.$scope.$watch('form.$pristine', (nval, oval)=>{
						if ( !this.isLocked && ! nval && nval != oval ) {
							this.promptExit.enable(this.$state.current.name);
						}
					}),
					this.$scope.$watch('form.subform.$pristine', (nval, oval)=>{
						if ( ! nval && nval != oval ) {
							this.chatsim.interupt('Flow Changed');
						}
					}),
					this.$scope.$watch('form.flow.$pristine', (nval, oval)=>{
						if ( ! nval && nval != oval ) {
							this.chatsim.interupt('Flow Changed');
						}
					}),
				);
			}),	

			this.$scope.$once(CONSTANTS.SCOPE_EVENTS.DEPS_LOADED, ()=>{
				this._initSortables();

				if ( this.clone ) {
					this.model = this.createModel(this.clone);
					['_id', 'batch_id', 'created_by', 'created_date', 'publish'].forEach(k=>delete this.model[k]);
					this.promptExit.enable(this.$state.current.name);
					
					// we copy clone into data temporarily
					this.data = this.clone;
					// delete data later
					this.$scope.$once(CONSTANTS.SCOPE_EVENTS.DATA_READY, ()=>{
						this.$timeout(()=>delete this.data, 0);
						// mark the form as touched/dirty
						this.$scope.form?.flow && (this.$scope.form.flow.$touched = true) && this.$scope.form.flow.$setDirty();
					});
					// 
				} else {
					this.model = this.createModel(this.data ?? {});

					// if ( this.data )
					// this.$scope.$once(CONSTANTS.SCOPE_EVENTS.PAGE_READY, ()=>this.$scope.form.flow && (this.$scope.form.flow.$touched = true));
				}
				this.onPreviewDetailsChange();
				this.$scope.$once(CONSTANTS.SCOPE_EVENTS.PAGE_READY, ()=>{
					this._$timezones = this.queryTimezone(null, this.model?.show_in_content_directory?.schedule?.timezone);
				});
			}),
		);

		super.init();
	}
	_loadDependencies(){
		return this.$q.all([
			super._loadDependencies(),
			this.apiMap.getFlowTypes().then(data=>{
				data.byType = {};
				data.byEditor = {};
				data.byFlow = {};
				data.forEach(flow=>data.byType[flow.type] = data.byEditor[flow.console_editor] = data.byFlow[flow.chat_flow] = flow);
				this.mapping.flowTypes = data;
			}),
			this.apiMap.getUsersCollegeAdminUp().then(data=>this.mapping.users = data),
			this.apiMap.getTags().then(data=>this.mapping.flutterTags = data),
		]);
	}

	_initSortables(){
		this.sortableQuestionsOptions = {
			'ui-model-items': '.question[ng-repeat]',
			axis: 'y',
			handle: '.question-sort-handle',
			placeholder: 'sort-placeholder question panel-content padding-4x',
			tolerance: 'pointer',
			containment: 'parent',
			forceHelperSize: true,
			forcePlaceholderSize: true,
			update: (evt, ui)=>this.$scope.form && this.$scope.form.flow && this.$scope.form.flow.$setDirty(),
		};
		this.sortableAnswersOptions = {
			'ui-model-items': '.answer[ng-repeat]',
			axis: 'y',
			handle: '.answer-sort-handle',
			placeholder: 'sort-placeholder answer',
			tolerance: 'pointer',
			containment: 'parent',
			forceHelperSize: true,
			forcePlaceholderSize: true,
			update: (evt, ui)=>this.$scope.form && this.$scope.form.flow && this.$scope.form.flow.$setDirty(),
		};
	}

	createModel(data={}){
		const model = angular.extend({}, FlowSingleController.DEFAULTS, Helper.deepCopy(data));

		if ( this.clone && model.owner_college_id && !this.mapping.mycolleges.byId[model.owner_college_id] )
			model.owner_college_id = null;
		// BTC-508 default to 1 college administered or only if b12 is one of colleges
		if ( ! model.owner_college_id ) {
			if ( this.mapping.mycolleges.length == 1 ) {
				model.owner_college_id = this.mapping.mycolleges[0]._id;
			} else
			if ( this.mapping.mycolleges.byId[this.BEYOND12_ID] ) {
				model.owner_college_id = this.BEYOND12_ID;
			}
		}
		
		model.recipients = [];

		model.name = model.name && model.name.en_US || null;
		model.layout = model.layout || 'default';
		
		// old flows can have missing show_in_content_directory, but content directory must be false
		if ( this.data && this.data.show_in_content_directory === undefined ) 
			model.show_in_content_directory = {show_in_content_directory: false};
		
		const showcd = model.show_in_content_directory = model.show_in_content_directory || {show_in_content_directory: true};

		if ( showcd.content_directory ) {
			const cd = showcd.content_directory;
			cd.release_date = Helper.getValidISODate(cd.release_date) || null;
			cd.deadline.date = Helper.getValidISODate(cd.deadline.date) || null;
			cd.image_url = (this.$state.params.uploads || []).find(file=>file.resourceType==RESOURCETYPE.IMG) || cd.image_url || undefined;
			cd.image_alt_text = cd.image_alt_text || undefined;
			cd.tags = Object.keys(cd.tags || {}).map(id=>+id);

			if ( cd.background_color ) {
				this.background_color_label = cd.background_label ? cd.background_label : this.getLabelFromColor(cd.background_color);
				this.getColorMapping(cd.background_color);
				this.updateColor(cd.background_color);
			}
		} else {
			showcd.content_directory = {};
		}

		if ( ! showcd.schedule?.timezone ) {
			showcd.schedule = showcd.schedule || {};
			showcd.schedule.timezone = 'US/Pacific';
		} else
		if ( ! showcd.schedule._$fire ) {
			let d = showcd.schedule.start_date && moment(showcd.schedule.start_date +' '+ showcd.schedule.time, 'YYYY-MM-DD HH:mm');
			showcd.schedule = {
				start_date: d && d.isValid() ? d : null,
				timezone: showcd.schedule.timezone,
			};
		}
		this.updateTrigger(model);

		switch( this.typeChanged._old = model.type ) {
			case 'greeting':
			case 'alert':
				model.flow = {message: model.flow && model.flow.message && model.flow.message.en_US || null};
				break;
			case 'quiz':
				if ( model._id ) {
					model.flow.intro = model.flow.intro && model.flow.intro.en_US;
					model.flow._$intro = model.flow.intro && Helper.hasLangTags(model.flow.intro) ? this.$sce.trustAsHtml(Helper.convertToLangSpan(model.flow.intro)) : undefined;

					model.flow.competencies = Object.keys(model.flow.competencies).map(key=>{
						let item = model.flow.competencies[key];
						item._id = this._getNextCompetencyID();
						item.label = key;
						item.description = item.description && item.description.en_US;
						item.file = (this.$state.params.uploads || []).find(file=>file.resourceType==RESOURCETYPE.QUIZ_COMPE_IMG && file.compeKey==key) || undefined;
						item.image_alt_text = item.image_alt_text || undefined;
						return item;
					});
					model.flow.questions.forEach(item=>{
						item.question = item.question && item.question.en_US;
            item._$question = Helper.toSafeLangHtml(item.question);
						item.answers.forEach(ans=>{
							ans.answer = ans.answer && ans.answer.en_US;
							let pairs = Object.entries(ans.competency_weight);
							ans.competency_weight = {};
							for( let [label, value] of pairs ) {
								let compe = model.flow.competencies.find(v=>v.label==label);
								ans.competency_weight[compe._id] = value;
							}
						});
					});
					this.$scope.$once(CONSTANTS.SCOPE_EVENTS.DATA_READY, ()=>{
						const flowForm = this.$scope.form && this.$scope.form.flow;
						if ( flowForm ) {
							model.flow.competencies.forEach((item, i)=>item._$open = flowForm['competency-'+ i] && flowForm['competency-'+ i].$invalid);
							model.flow.questions.forEach((item, i)=>item._$open = flowForm['question-'+ i] && flowForm['question-'+ i].$invalid);
						}
					});
				}
				break;
			case 'dynamic_flow':
				this.$scope.$once(CONSTANTS.SCOPE_EVENTS.DATA_READY, ()=>this.$timeout(()=>{
					this.resizeDynamicFlow();
					this.initGraph(this.model.flow);
					this.graphUpdateColor(this.model.color || '#00A39C');
				}, 100));
				break;
			default: break;
		}

		return model;
	}

	/**
	 * Call this to submit the form
	 * 
	 * @param {*} $evt - angular event to attach dialog to
	 * @param {*} form - form controller instance
	 * @param {*} isQuick - if false will cause the current publish state to toggle, and if publish becomes true, will redirected the page after. Default true
	 * @returns null
	 * 
	 * BTC-589; refactoring this as there are some resurrectivng bugs with dialogs.
	 * Always double check if any change here will not resurrect BTC-530 & BTC-541 or similar.
	 */
	async submit($evt, form, isQuick=true, isTest=false){
		const isPublish = isQuick ? !!this.model.publish : !this.model.publish;
		console.log('quick', isQuick, 'publish', isPublish);

		try {
			if ( isPublish )
				await this._validateForm(form);
	
			if ( ! isQuick )
				await this.$mdDialog.show(
					this.$mdDialog.confirm()
							.title((isPublish ? 'P' : 'Unp') +'ublish Flow')
							.htmlContent(`Are you sure you want to ${isPublish ? '': 'un'}publish this flow?`)
							.ariaLabel('confirm')
							.targetEvent($evt)
							.ok(isPublish ? 'Publish' : 'Unpublish')
							.cancel('Cancel')
					);

			if ( isPublish )
				await this._validateRecipients({
					title: 'Flow for everyone?',
					word: 'Make the Flow available',
				});
					
			this.isBusy = true;

			await this._submit(form, isQuick, isTest);

			this.isBusy = false;
		} catch(err) {
			this.isBusy = false;
			if ( isTest ) throw err;
		}
	}

	async _submit(form, isQuick=true, isTest=false){
		const isPublish = isQuick ? !!this.model.publish : !this.model.publish;
		const payload = this._preparePayload();

		if ( !isQuick ) payload.publish = ! this.model.publish;

		let res;
		if ( this.data ) {
			res = await this.api.put(`flowConfigurations/${this.data._id}`, payload);
			await this._upload(this._filesToUpload, this.data._id, payload, 'flowConfigurations/'+ this.data._id, 'flow_configuration_id:'+ this.data._id);

			if ( isPublish ) {
				this.toast.success(isQuick ? 'Flow Updated' : 'Flow Published');
			} else {
				this.toast.success(isQuick ? 'Changes Saved' : 'Flow Unpublished');
			}
		} else {
			res = await this.api.post('flowConfigurations', payload);

			// use updated data instead of payload
			let data = (await this.api.get(`flowConfigurations/${res.data._id}`)).data;
			
			try {
				await this._upload(this._filesToUpload, data._id, data, `flowConfigurations/${data._id}`, `flow_configuration_id:${data._id}`);
			} catch(err) {
				// from add page, move to edit page
				this.promptExit.disable();
				this.$state.go('^.edit', {id:res.data._id, uploads:this.uploads})
					.then(()=>this.errorPrompt.show(err));
				return;
			}

			this.toast.success(isPublish ? 'New Flow Published' : 'New Flow Saved');
		}

		form.$setPristine();
		this.promptExit.disable();

		if ( isPublish && !isQuick && !isTest ) {
			this.$state.go('^', this.data && this.parentStateParams || {}, {supercede: false});
			return;
		}
		if ( this.data ) { // update data
			this.data = Helper.deepCopy(res.config.data);
			this.model.publish = this.data.publish;
		} else try {
			let data = (await this.api.get(`flowConfigurations/${res.data._id}`)).data;

			let params = Helper.deepCopy(this.$state.params);
			params.id = data._id;
			params.retain = true;
			params.clone = undefined;

			if ( this.clone ) delete this.clone;
			this.data = data;
			this.$state.go('^.edit', params, {reload: false, notify: false});

		} catch(err) {
			console.error(err, 'will force redirect to edit page instead');
			this.$state.go('^.edit', {id: res.data._id});
			return;
		}
	}

	_validateForm(form){
		return super._validateForm(form)
				.then(()=>{
					if ( this.model.type == 'dynamic_flow' && this.graph && this.graph.checkForErrors(true) ) {
						Helper.smoothScrollTo($('#dynamic-flow'));
						this.toast.warn(MESSAGES.FLOWS.DYNAMIC.HAS_ERRORS);
						throw this.graph.export(true);
					}
				});
	}

	_preparePayload(){
		let payload = Helper.deepCopy(this.data||{});
		let model = Helper.deepCopy(this.model);

		this._filesToUpload = [];

		payload.publish = !! this.model.publish;

		// build recipients
		payload.recipients = Helper.mergeObjects(model.recipients.map((group, index)=>{
			return {['recipient_group_'+ (index+1)]: group.expr};
		}));

		payload.owner_college_id = model.owner_college_id;

		payload.name = {en_US: model.name || ''};
		payload.type = model.type || undefined;
		payload.color = model.color && model.color != 'none' && model.color.toUpperCase() || undefined;
		payload.layout = model.layout || undefined;
		if ( payload.layout == 'default' )
			payload.layout = undefined;


		const showcd = model.show_in_content_directory;
		payload.show_in_content_directory = payload.show_in_content_directory || {};
		payload.show_in_content_directory.show_in_content_directory = !! showcd.show_in_content_directory;
		if ( showcd?.show_in_content_directory ) {
			const cd = payload.show_in_content_directory.content_directory = payload.show_in_content_directory.content_directory || {};
			cd.title = showcd.content_directory?.title || '';
			cd.framing_message = showcd.content_directory?.framing_message || '';
			cd.release_date = showcd.content_directory?.release_date ? moment(showcd.content_directory.release_date).format('YYYY-MM-DD') : undefined;
			cd.deadline = {date: showcd.content_directory?.deadline?.date ? Helper.toNoonISO(showcd.content_directory.deadline.date) : undefined};
			cd.background_color = showcd.content_directory.background_color || undefined; // this is the color value
			cd.background_label = showcd.content_directory.background_label || undefined; // this is the color label

			cd.tags = {};
			(showcd.content_directory.tags || []).forEach(key=>cd.tags[key]={});

			cd.image_url = showcd.content_directory.image_url || undefined;
			cd.image_alt_text = showcd.content_directory.image_alt_text || undefined;
			if ( this.model.show_in_content_directory.content_directory.image_url__file ) {
				cd.image_url = undefined;
				let file = this.model.show_in_content_directory.content_directory.image_url__file;
				file.resourceType = RESOURCETYPE.IMG;
				file.callback = (url, payload)=>{
					payload.show_in_content_directory.content_directory.image_url = url;
					this.model.show_in_content_directory.content_directory.image_url = url;
					this.model.show_in_content_directory.content_directory.image_url__file = undefined;
				};
				this._filesToUpload.push(file);
				console.log(this._filesToUpload)
			}

			delete payload.show_in_content_directory.schedule;
		} else
		if ( showcd ) {
			delete payload.show_in_content_directory.content_directory;
		
			if ( showcd.schedule?._$fire ) {
				payload.show_in_content_directory.schedule = {};
				[
					'fire_on_student_registration',
					'fire_on_student_registration_with_coach',
					'fire_on_student_registration_with_coach_with_partner_landing_page',
					'fire_on_student_registration_with_coach_without_partner_landing_page',
					'fire_on_student_registration_without_coach',
					'fire_on_student_registration_without_coach_with_partner_landing_page',
					'fire_on_student_registration_without_coach_without_partner_landing_page'
				]
					.forEach(key=>payload.show_in_content_directory.schedule[key] = showcd.schedule[key] || undefined);
			} else
			if ( showcd.schedule ) {
				payload.show_in_content_directory.schedule = {
					start_date: this.model.show_in_content_directory.schedule.start_date ? this.model.show_in_content_directory.schedule.start_date.format('YYYY-MM-DD') : undefined,
					time: this.model.show_in_content_directory.schedule.start_date ? this.model.show_in_content_directory.schedule.start_date.format('HH:mm') +':00' : undefined,
					timezone: model.show_in_content_directory.schedule.timezone,
				};
			} else {
				payload.show_in_content_directory.schedule = {};
			}
		}

		switch( model.type ) {
			case 'greeting':
			case 'alert':
				payload.flow = {
					type: 'message',
					message: {en_US: model.flow.message},
				};
				break;
			case 'quiz':
				let questions = this.model.flow.questions.map(item=>({
						question: {en_US: item.question},
						answers: item.answers.map(ans=>{
							let weights = {};
							Object.keys(ans.competency_weight).forEach(key=>{
								let compe = this.model.flow.competencies.find(v=>v._id == key);
								if ( compe && ans.competency_weight[key] > 0 ) // fix id with updated label
									weights[compe.label || ''] = ans.competency_weight[key];
							});
							return {
								answer: {en_US: ans.answer},
								competency_weight: weights,
							}
						}),
					}));

				let competencies = {};
				this.model.flow.competencies.forEach((compe, idx)=>{
					competencies[compe.label || ''] = {
						// label: {en_US: compe.label},
						description: compe.description ? {en_US: compe.description} : undefined,
						url: !compe.file && compe.url || undefined,
						mime_type: !compe.file && compe.url ? compe.mime_type : undefined,
						image_alt_text: compe.url || compe.file ? compe.image_alt_text : undefined,
					};

					if ( compe.file ) {
						let file = compe.file;
						file.compeKey = compe.label;
						file.resourceType = RESOURCETYPE.QUIZ_COMPE_IMG;
						file.metadata = ['competency:'+ Helper.camelCase(compe.label)];
						file.callback = (url, payload)=>{
							payload.flow.competencies[compe.label].url = url;
							payload.flow.competencies[compe.label].mime_type = file.type;
							compe.url = url;
							compe.mime_type = file.type;
							compe.file = undefined;
						};
						this._filesToUpload.push(file);
					}
				});

				payload.flow = {
					type: 'questions',
					competencies: competencies,
					questions: questions,
					intro: this.model.flow.intro ? {en_US: this.model.flow.intro} : undefined,
				};
				break;
			case 'dynamic_flow':
				payload.flow = this.graph && this.graph.export(payload.publish);
				break;
			default: break;
		}

		if ( this.data ) {
			payload.modified_by_user_id = +this.authorization.userId;
			payload.modified_date = Helper.toTimestamp(new Date());
		} else {
			payload.created_by_user_id = +this.authorization.userId;
			payload.created_date = Helper.toTimestamp(new Date());
		}
		payload.ancestry = payload.ancestry || SETTINGS.apiAncestry;
		return payload;
	}



	delete($ev){
		return this.$mdDialog.show(
			this.$mdDialog.confirm()
					.title('Are you sure you want to delete this flow?')
					.ariaLabel('confirm delete')
					.targetEvent($ev)
					.ok('Delete')
					.cancel('Cancel')
		).then(()=>{
			this.isBusy = true;
			return this.api.delete(`flowConfigurations/${this.data._id}`)
				.finally(()=>this.isBusy = false);
		})
		.then(()=>{
			this.promptExit.disable();
			this.toast.success('Flow Deleted');
			this.$state.go('^', this.session.get('flows') || {});
		});
	}


	typeChanged($event){
		let value = this.model.type;
		(()=>{
			if ( this.typeChanged._old && value != this.typeChanged._old && this.$scope.form.flow && this.$scope.form.flow.$touched ) {
				this.model.type = this.typeChanged._old;
				return this.$mdDialog.show(
					this.$mdDialog.confirm()
							.title('Change flow type?')
							.htmlContent('Current flow details would be lost.<br><br>Are you sure you want to change the flow type?')
							.ariaLabel('confirm')
							.targetEvent($event)
							.ok('Ok')
							.cancel('Cancel')
				);
			}
			return this.$q.when(true);
		})()
			.then(()=>{
				// cleanup before switching
				switch( this.typeChanged._old ) {
					case 'dynamic_flow':
						if ( this.graph ) this.graph.destroy();
						this.graph = null;
						break;
					default: break;
				}
				// prep new changes
				switch(this.model.type = this.typeChanged._old = value){
					case 'greeting':
						case 'alert':
						this.model.flow = {message: null};
						break;
					case 'quiz':
						this.model.flow = {competencies: [], questions:[]};
						this.$timeout(()=>{
							this.openCompetency();
							this.openQuestion();
						}, 0);
						break;
					case 'dynamic_flow':
						this.$timeout(()=>{
							this.resizeDynamicFlow();
							this.initGraph();
						}, 100);
						break;
					default: break;
				}
			});
	}
	cdChanged($event){
		if ( this.model.show_in_content_directory.show_in_content_directory ) {
			this.model.show_in_content_directory.content_directory = this.model.show_in_content_directory.content_directory || {};
		}
		if ( this.data?.show_in_content_directory?.show_in_content_directory && ! this.model.show_in_content_directory.show_in_content_directory ) { // just unchecked, originally checked
			this.model.show_in_content_directory.show_in_content_directory = true;
			return this.$mdDialog.show(
				this.$mdDialog.confirm()
						.title('Warning')
						.textContent('Changing this setting may cause some students to see the flow in the wrong place.')
						.ariaLabel('confirm')
						.targetEvent($event)
						.ok('Continue')
						.cancel('Cancel')
			)
			.then(()=>{
				this.model.show_in_content_directory.show_in_content_directory = false;
				this.chatsim.interupt('Flow Changed');
			});
		} else {
			this.chatsim.interupt('Flow Changed');
		}
	}

	queryTimezone(str, value){
		if ( str ) {
			str = str.toLowerCase().replace(/\s+/, '_');
			return this.mapping.timezones.filter(name=>name.toLowerCase().indexOf(str) > -1);
		} else {
			if ( value && !SETTINGS.TZ_DEFAULTS.includes(value) )
				return [value].concat(SETTINGS.TZ_DEFAULTS);
		}
		return SETTINGS.TZ_DEFAULTS;
	}


	openCompetency(item, $index){
		if ( this.isLocked || this.isBusy )
			return item;
		const list = this.model.flow.competencies;
		let i;
		if ( item === undefined ) {
			list.push(item = {_id: this._getNextCompetencyID()});
			i = list.length -1;
		} else {
			i = list.indexOf(item);
		}

		this.$timeout(()=>{
			item._$open = true;
			let $el = this.$scope.$eval(`form.flow["competency-${i}"].label.$$element`);
			if ( $el ) this.$timeout(()=>Helper.smoothScrollTo($el.focus()), 200);
		}, 100);
		return item;
	}
	closeCompetency(item){
		item._$open = false;
	}
	removeCompetency(index, $event){
		if ( this.isLocked || this.isBusy )
			return this.$q.reject(false);
		return this.$mdDialog.show(
			this.$mdDialog.confirm()
					.title('Discard competency?')
					.htmlContent('Are you sure you want to remove this competency?')
					.ariaLabel('confirm')
					.targetEvent($event)
					.ok('Ok')
					.cancel('Cancel')
		).then(()=>{
			if ( index < this.model.flow.competencies.length ) {
				this.model.flow.competencies.splice(index, 1);
				this.$scope.form && this.$scope.form.flow && this.$scope.form.flow.$setDirty();
			}
			return true;
		}, ()=>false);
	}
	isUniqueCompetency($item, $viewValue){
		return this.model.flow.competencies.find(compe=>compe != $item && compe.label && compe.label?.toLowerCase() == $viewValue?.toLowerCase()) == undefined;
	}
	_getNextCompetencyID(){
		if ( ! this._$nextCompeId )
			this._$nextCompeId = 0;
		return ++this._$nextCompeId;
	}


	openQuestion(item, $index){
		if ( this.isLocked || this.isBusy )
			return item;
		const list = this.model.flow.questions;
		let i;
		if ( item === undefined ) {
			list.push(item = {answers:[]});
			i = list.length -1;
			this.addAnswer(item);
		} else {
			i = list.indexOf(item);
		}

		this.$timeout(()=>{
			item._$open = true;
			let $el = this.$scope.$eval(`form.flow['question-${i}'].question.$$element`);
			if ( $el ) this.$timeout(()=>Helper.smoothScrollTo($el.focus()), 200);
		}, 250);
		return item;
	}
	closeQuestion(item){
		item._$open = false;
	}
	removeQuestion(index, $event){
		if ( this.isLocked || this.isBusy )
			return this.$q.reject(false);
		return this.$mdDialog.show(
			this.$mdDialog.confirm()
					.title('Discard question?')
					.htmlContent('Are you sure you want to remove this question?')
					.ariaLabel('confirm')
					.targetEvent($event)
					.ok('Ok')
					.cancel('Cancel')
		).then(()=>{
			if ( index < this.model.flow.questions.length ) {
				this.model.flow.questions.splice(index, 1);
				this.$scope.form && this.$scope.form.flow && this.$scope.form.flow.$setDirty();
			}
			return true;
		}, ()=>false);
	}


	addAnswer(question){
		if ( this.isLocked || this.isBusy )
			return;
		const list = question.answers;
		list.push({competency_weight:{}});
		let i = this.model.flow.questions.indexOf(question);
		let j = list.length -1;

		this.$timeout(()=>{
			let $el = this.$scope.$eval(`form.flow['question-${i}'].answer_${j}.$$element`);
			if ( $el ) Helper.smoothScrollTo($el.focus());
		}, 200);
	}
	removeAnswer(question, index, $event){
		if ( this.isLocked || this.isBusy )
			return this.$q.reject(false);
		return this.$mdDialog.show(
			this.$mdDialog.confirm()
					.title('Discard answer?')
					.htmlContent('Are you sure you want to remove this answer?')
					.ariaLabel('confirm')
					.targetEvent($event)
					.ok('Ok')
					.cancel('Cancel')
		).then(()=>{
			if ( index < question.answers.length ) {
				question.answers.splice(index, 1);
				this.$scope.form && this.$scope.form.flow && this.$scope.form.flow.$setDirty();
			}
			return true;
		}, ()=>false);
	}


	validateForm(form){
		form.$$controls.forEach(ctrl=>{
			ctrl.$setTouched();
			ctrl.$validate();
		});
		let keys = Object.keys(form.$error);
		if ( keys.length > 0 ) {
			let errs = form.$error[keys[0]];
			if ( errs.length > 0 && errs[0].$$element )
				Helper.smoothScrollTo(errs[0].$$element, null, ()=>$(errs[0].$$element).focus());
		}
		// this assumes that validation is not async
		return !form.$pending && form.$valid;
	}
	validateCompetencyFile(file, competency){
		return this.mimetype.identify(file)
			.then(type=>!!(file.mimetype = type))
			.catch(()=>{
				this.$mdDialog.show(
					this.$mdDialog.alert()
							.title('Invalid File')
							.htmlContent('The selected file is either invalid or corrupted.<br><br>Please select another JPEG, PNG or GIF file.')
							.ok('Ok')
				)
				// .finally(()=>this.uploads[key] = undefined);
				this.$timeout(()=>competency.file = undefined, 100);
				return false;
			});
	}


	toastInvalidCompetency(){
		this.toast.warn('Please enter a valid competency before hiding');
	}
	toastInvalidQuestion(){
		this.toast.warn('Please enter a valid question with answers before hiding');
	}


	getABC(n){
		return String.fromCharCode(65+n);
	}

	getAnswerStr(question){
		return question.answers.map((v, i)=>`(${String.fromCharCode(97+i)}) ${Helper.toSafeLangHtml(v.answer)}`).join(', ');
	}


	updateTrigger(model){
		if ( ! model ) model = this.model;

		const sched = model.show_in_content_directory.schedule;

		if ( sched )
		sched._$fire = ( sched.fire_on_student_registration 
			||  sched.fire_on_student_registration_with_coach
			||  sched.fire_on_student_registration_with_coach_with_partner_landing_page
			||  sched.fire_on_student_registration_with_coach_without_partner_landing_page
			||  sched.fire_on_student_registration_without_coach 
			||  sched.fire_on_student_registration_without_coach_with_partner_landing_page
			||  sched.fire_on_student_registration_without_coach_without_partner_landing_page
		)
	}


	initGraph(flow){
		if ( this.graph ) this.graph.destroy();

		const container = $('#container').get(0);

		// Creates the graph inside the given container
		// let graph = this.graph = new mxGraph.mxGraph(container);
		const graph = this.graph = new Graph(container, this.$scope.$evalAsync);

		if ( flow ) graph.import(flow);

		// use keyboard shortcuts
		const keyHandler = graph.keyHandler;// = new mxGraph.mxKeyHandler(graph);
		keyHandler.getFunction = function(evt){
			if ( evt ) return (mxGraph.mxEvent.isControlDown(evt) || (mxGraph.mxClient.IS_MAC && evt.metaKey)) ? this.controlKeys[evt.keyCode] : this.normalKeys[evt.keyCode];
			return null;
		};
		keyHandler.bindKey(46, ()=>setTimeout(()=>this.graphDeleteSelected(),0)); // delete
		keyHandler.bindKey(8, ()=>setTimeout(()=>this.graphDeleteSelected(),0)); // backspace
		keyHandler.bindControlKey(90, ()=>graph.undoManager.canUndo() && graph.undoManager.undo()); // ctrl + z
		keyHandler.bindControlKey(89, ()=>graph.undoManager.canRedo() && graph.undoManager.redo()); // ctrl + y
		keyHandler.bindControlShiftKey(90, ()=>graph.undoManager.canRedo() && graph.undoManager.redo()); // ctrl + shift + z
		

		let dragOffset;
		$('#add-new-btn').on('dragstart', event=>{
			let dataX = event.originalEvent.dataTransfer;
			dataX.setData('text/plain', '#add-new-btn');
			
			// let one = new Image();
			// one.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
			// dataX.setDragImage($('#drag-empty').get(0), event.offsetX, event.offsetY);
			// var img = new Image();
			// img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
			// dataX.setDragImage(img, event.offsetX, event.offsetY);

			dragOffset = {
				x: event.offsetX,
				y: event.offsetY,
			};
		});
		$(container)
			.on('dragover', event=>event.preventDefault())
			.on('drop', event=>{
				if ( event.originalEvent.dataTransfer.getData('text/plain') === '#add-new-btn' ) {
					event.preventDefault();
					this.graphAddPrompt(event.offsetX - dragOffset.x, event.offsetY - dragOffset.y);
					dragOffset = null;
				}
			});
		

		// listen for significant changes on graph
		const flowChanged = Helper.throttle((g, evt)=>{
			if ( this.ready && this.$scope ) {
				this.$scope.form?.$setDirty();
				this.$scope.form?.flow && (this.$scope.form.flow.$touched = true);
				this.$scope.$applyAsync();
			}

			this.chatsim.isRunning && this.chatsim.interupt('Flow Changed');
		}, 1000, true);


		let d = this.$q.defer();
		setTimeout(()=>{
			// emit an event of our own
			this.$scope.$emit('graph-ready');
			d.resolve();

			graph.undoManager.addListener(mxGraph.mxEvent.ADD, flowChanged);
			graph.undoManager.addListener(mxGraph.mxEvent.UNDO, flowChanged);
			graph.undoManager.addListener(mxGraph.mxEvent.REDO, flowChanged);

			if ( graph.needResave ) { // this is when a rogue arrow was removed
				this.$scope?.form?.$setDirty();
				graph.needResave = false;
			}
		}, 500);
		return d.promise;
	}

	graphUpdateColor(color){
		if ( this.graph ) {
			this.graph.getStylesheet().getDefaultVertexStyle().fillColor = color || '#00A39C';
			this.graph.refresh();
		}
	}

	graphAddPrompt(x, y, source){
		if ( this.graph )
			this.graph.addNewPrompt(x, y, source);
	}

	graphHasSelected(prompt, edge){
		return this.graph && this.graph.getSelectionCells().filter(v=>v.prompt==prompt || v.edge==edge).length > 0;
	}

	graphColorSelected(color){
		if ( this.graph ) {
			let graph = this.graph;

			let edit = graph.model.currentEdit = graph.model.createUndoableEdit(true);
			let cells = graph.getSelectionCells().filter(v=>!!v.prompt);
			graph.model.beginUpdate();
			try {
				cells.forEach(cell=>{
					let clone = Helper.deepCopy(cell.value);
					clone.color = color || undefined;
					graph.model.setValue(cell, clone);
					let style = Helper.modifyStyleString(cell.getStyle(), {fillColor: clone.color});
					graph.setCellStyle(style, [cell]);
				});
				let ogNotify = edit.notify;
				edit.notify = ()=>{
					ogNotify();
					graph.updatePropertyView(graph.getSelectionCells());
				};
			} finally {
				graph.model.endUpdate();
			}
		}
	}
	graphLayoutSelected(value){
		if ( this.graph ) {
			let graph = this.graph;

			let edit = graph.model.currentEdit = graph.model.createUndoableEdit(true);
			graph.model.beginUpdate();
			try {
				graph.getSelectionCells().filter(v=>!!v.prompt).forEach(cell=>{
					let clone = Helper.deepCopy(cell.value);
					clone.layout = value;
					graph.model.setValue(cell, clone);
				});
				edit.notify = ()=>graph.updatePropertyView(graph.getSelectionCells());
			} finally {
				graph.model.endUpdate();
			}
		}
	}

	graphAddComment($event){
		if ( (!$event || $event.keyCode==13) && this.graph ) {
			let msg = $('#comment-input').val();

			if ( msg ) {
				this.graph.getSelectionCells().filter(v=>v.prompt || v.edge).forEach(cell=>{
					this.graph._$comments = cell.value.comments = cell.value.comments || [];
					cell.value.comments.push({
						date: Helper.toTimestamp(),
						user_id: `${this.authorization.userId}`, // ensure string
						comment: msg,
					});
				});

				$('#comment-input').val('').removeAttr('value');
				this.$timeout(()=>$('.dynamic-flow-comments > .can-scroll-y > div').last().get(0).scrollIntoView(true), 0);
			}
			if ( $event ) $event.preventDefault();
		}
	}


	graphDeleteSelected(){
		if ( this.graph && ! this.graph.isSelectionEmpty() ) {
			this.$mdDialog.show(
				this.$mdDialog.confirm()
						.title('Delete selected?')
						.htmlContent('Are you sure you want to remove the selected prompts/arrows?')
						.ariaLabel('confirm')
						.ok('Ok')
						.cancel('Cancel')
			).then(()=>{
				const graph = this.graph;
				const model = graph.model;
				
				model.currentEdit = model.createUndoableEdit(true); // ensure that this change is significant
				model.beginUpdate();
				try {
					graph.removeCells(graph.getSelectionCells(), false);
				} finally {
					model.endUpdate();
				}
			});
			this.$scope.$evalAsync();
		}
	}


	resizeDynamicFlow(){
		if ( this.$window.matchMedia("(min-width: 960px)").matches ) {
			$('#dynamic-flow').height(Math.max(Math.min($('main').height(), $('#content').height()), 300));
		}
	}


	async startChatSim($evt){
		try {
			await this._confirmUserChatname($evt);

			if ( ! this.data || ! this.$scope.form.$pristine ) {
				await this._confirmSaveBeforeTest($evt);
			} else {
				await this._validateForm(this.$scope.form);
			}
		} catch(e){
			console.log('chatsim validation fail');
			return;
		}

		this.isBusy = true;

		try {
			const payload = this._preparePayload();
			await this.api.post(`flowConfigurations/preview/${this.data._id}`, payload);

			const roomID = await this.getRoomID();
			await this.chatsim.start(roomID);

			this.model.show_in_content_directory && this.updatePreview('details');

		} catch(err) {
			console.error(err);
			if ( err instanceof Error ) {
				this.errorPrompt.show(err, null, {noRefresh:true});
			}
			this.chatsim.stop();
		};

		this.isBusy = false;

		this.$scope?.$applyAsync();
	}
	async _confirmUserChatname($evt){
		if ( this.$scope.mainCtrl.currentUser?.chat?.username ) return;

		return this.$mdDialog.show(
			this.$mdDialog.confirm()
					.title('Chat Username Required')
					.htmlContent('<p>Your account needs to have a chat username set to be able test a flow.</p><ul><li>Go to <u>My Profile</u> page</li><li>set your chat username</li></ul>')
					.ariaLabel('alert')
					.cancel('Go to Profile Page')
					.ok('Ok')
		)
		.catch(()=>this.$state.go('app.profile'))
		.finally(()=>{throw true}); // do not allow test preview until chat username is set
	}
	async _confirmSaveBeforeTest($evt){
		await this.$mdDialog.show(
			this.$mdDialog.confirm()
					.title('Save to Test Flow')
					.htmlContent('The flow needs to be saved to be able to test & preview. Save now?')
					.ariaLabel('confirm')
					.targetEvent($evt)
					.ok('Save & Test')
					.cancel('Cancel')
		);
		await this._validateForm(this.$scope.form);
		await this.submit($evt, this.$scope.form, true, true);
		if ( ! this.data ) throw true;
	}
	async getRoomID(){
		const res = await this.api.get(`userChatRooms/user/${this.authorization.userId}`, {intent:'preview'}, {cache:false})
		const roomID = res.data?.[0]?.room_id;
		if ( ! roomID ) {
			throw new Error('Missing room ID');
		}
		console.log('roomID', roomID);
		return roomID;
	}
	

	onPreviewDetailsChange(){
		const cd = this.model.show_in_content_directory?.content_directory;
		if ( cd ) {
			this.preview.title = this.toSafeLangHtml(cd.title);
			this.preview.framing_message = this.toSafeLangHtml(Helper.newlinesToHtml(Helper.plainLinksToHtml(cd.framing_message)), 'span,br,a');
		}
	}

	updatePreview(value, auto){
		// if ( ! auto )
		// 	this.preview.auto = false;

		if ( value === 'details' ) {
			this.preview.screen = null;
			this.preview.step = this.preview.stepIndex = undefined;
			auto && setTimeout(()=>$('#task-preview .task-content').animate({scrollTop:0}, 500), 100);
		} else
		if ( value === 'home' ) {
			this.preview.screen = 'home';
			this.preview.step = this.preview.stepIndex = undefined;
			auto && setTimeout(()=>$('#task-preview .task-content').animate({scrollTop:0}, 500), 100);
		}
		this.preview.value = value;
	}

	getLabel() {
		return this.background_color_label ? this.background_color_label : 'FROM BEYOND12';
	}

	mappingHasCategory(category) {
		let mapping = this.mapping.bgColors;
		let obj = mapping.filter((key) => key.value === category);
		if ( obj.length == 0 || obj == undefined ) {
			return false;
		} else {
			return true;
		}
	}

	getColorMapping(color) {
		if ( this.mappingHasCategory(color) == false ) {
		let colorObj = {label: null, value: color},
			mapping = [...this.mapping.bgColors];
		let obj = mapping.filter((key) => key.value === color);
		if ( obj.length == 0 ) {
			mapping.push(colorObj);
			console.log(mapping);
			this.bgColorsTemp = mapping;
		}
		} else {
		return;
		}
	}

	// returns the label for the given color
	getLabelFromColor(color) {
		let mapping = this.mapping.bgColors;
		let obj = mapping.find((key) => key.value === color);
		return obj ? obj.label : null;
	}

	// returns the color for the given label
	getColorFromLabel(label) {
		let mapping = this.mapping.bgColors;
		let obj = mapping.find((item) => item.label === label); 
		return obj ? obj.value : null;
	}

	updateColor (color) {
		if ( color ) {
			let [r, g, b] = color.match(/\w\w/g).map(x => parseInt(x, 16));
			return this._$secondaryColor = `rgba(${r},${g},${b},0.25)`;
		} else {
			return this._$secondaryColor = '#D4ECEC';
		}
	}

	onChange(color_label) {
		this.model.show_in_content_directory.content_directory.background_label = color_label;
		let color = this.getColorFromLabel(color_label);
		this.model.show_in_content_directory.content_directory.background_color = color;
		this.updateColor(color)
	}
}

