import angular from 'angular'
import moment from 'moment'
import {DependencyInjected, Helper, ApiError, SETTINGS} from '../../common'
import {menuData} from '../../main/main.controller'
import {areaTimeChart} from './charts'


export default class NotificationsPerDateController extends DependencyInjected {
	static get $inject(){return [
		'$scope',
		'$q',
		'api',
		'authorization',
	]}

	init(){
		this.$scope.$parent.ctrl = this;
		this.main = this.$scope.mainCtrl;
		this.mapping = this.main.mapping;

		this.title = 'Notifications per Date';
		this.subject = 'Notifications';
		this.icon = 'far fa-bell';
		this.iconColor = '';
		this.hideDateRange = false;
		this.hideFilters = false;

		this.data = {};

		delete this.main.filters._$keys.date; // remove so it doesn't show on filter chips
		this.main.filters._date = null;//this.main.dateRange;

		this.main.dateRange.from = moment().startOf('month');
		this.main.dateRange.to = moment().endOf('month');

		this.main._pending.then(()=>{
			this.mapping.mycolleges.single = false;
			this.hideFilters = this.mapping.mycolleges.length <= 1;
			this.$scope.$emit('data-ready');
			this.ready = true;
			this.refresh();

			let p = this.main.filters.indexOf(this.main.dateRange);
			if ( ~p ) this.main.filters.splice(p);
		});

		this._destructors.push(()=>{
			if ( this._defer ) this._defer.resolve('abort');
		});
	}

	refresh(){
		if ( !this.ready ) return;

		let expr;
		if ( this.mapping.mycolleges.length === 1 ) {
			expr = '((college='+ this.mapping.mycolleges[0]._id +'))';
		} else
		if ( this.main.filters._mycolleges && this.main.filters._mycolleges.length > 0 ) {
			expr = '(('+ this.main.filters._mycolleges.map(item=>'college='+item._id).join(' OR ') +'))';
		}

		const params = {
			from: this.main.dateRange.from?.format('YYYY-MM-DD') || null,
			to: this.main.dateRange.to?.format('YYYY-MM-DD') || null,
			expression: expr || '()',
			myTokenAdministers: true,
		};

		const chartOptions = {
			title:{text:''},
			plotOptions:{
				series:{
					pointStart: moment(this.main.dateRange.from, SETTINGS.dateFormat).valueOf(),
					pointInterval: 24 * 3600 * 1000,
				}
			}
		};

		if ( this._defer ) this._defer.resolve('abort');
		this._defer = this.$q.defer();
		this.dataTable = null;
		this.main.isBusy = true;
		this.main.error = undefined;

		return this.api.get('analysis/notifications/heatMap', params, {timeout: this._defer.promise})
			.then((res)=>{
				this.totalData = 0;
				var data = Object.keys(res.data).map((key)=>{
					let value = res.data[key];
					this.totalData += value;
					// return [moment(key, 'yyyy-MM-dd').utc().startOf('day').valueOf(), value];
					return value;
				});

				let theChart = areaTimeChart( 'the-chart', data, chartOptions );
				this.dataTable = theChart.getDataRows().slice(1).map(function(row){
					return [moment(row[0]).format('yyyy-MM-DD'), row[1]];
				});
				this.dataTable.headers = ['Date', 'Notifications'];
			})
			.catch((err)=>{
				if ( this.api.isApiError(err) ) {
					if ( ! err.isIgnored() )
						this.main.error = err;
				} else {
					console.error(err);
				}
			})
			.finally(()=>{
				this._defer = undefined;
				this.main.isBusy = false;
			});
	}

}

