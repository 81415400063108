'use strict';

import angular from 'angular'
import LoginController from './login.controller'
import LoginMFAController from './loginMfa.controller'
import ForgotPassController from './forgotpass.controller'
import ResetPassController from './resetpass.controller'
import {ApiError} from '../common/services'

import './guest.scss'


export default [
	'$uiRouterProvider',
(
	$uiRouterProvider,
) => {
	$uiRouterProvider.urlService.rules.otherwise({state:'error.404'});
	const $stateRegistry = $uiRouterProvider.stateRegistry;


	$stateRegistry.register({
		name: 'guest',
		views: {'root@': {template: require('./guest.html')}},
		onEnter: ['$transition$', '$state$', ($transition$, $state$)=>{
			let authorization = $transition$.injector().get('authorization');
			if ( authorization.isValid() ) {
				return $transition$.router.stateService.target('app');
			}
			let MAPPINGS_JSON = $transition$.injector().get('MAPPINGS_JSON');
			if ( MAPPINGS_JSON === undefined )
				return $transition$.router.stateService.target('error.500', null, {location:false});
			
			let $rootScope = $transition$.injector().get('$rootScope');
			$rootScope.$once('$viewContentLoaded', ()=>window.setTimeout(()=>$('input').first().focus(), 250));
		}],
		onRetain: ['$transition$', ($transition$)=>{
			let $rootScope = $transition$.injector().get('$rootScope');
			$rootScope.$once('$viewContentLoaded', ()=>window.setTimeout(()=>$('input').first().focus(), 250));
		}],
	});

	$stateRegistry.register({
		name: 'guest.login',
		title: 'Login',
		url: '/login?{redirect:string}',
		views: {
			'content@guest': {
				template: require('./login.html'),
				controller: LoginController,
				controllerAs: 'ctrl',
			},
		},
		onEnter: ['$transition$', ($transition$)=>{
			const auth = $transition$.injector().get('authentication');
			if ( auth.hasToken() ) {
				auth.setToken();
				console.log('token cleared');
			}
		}],
	});

	$stateRegistry.register({
		name: 'guest.login.mfa',
		params: {
			id: {type: 'int'},
			factors: null,
		},
		views: {
			'content@guest': {
				template: require('./loginMfa.html'),
				controller: LoginMFAController,
				controllerAs: 'ctrl',
			},
		},
	});

	$stateRegistry.register({
		name: 'guest.forgotpass',
		title: 'Forgot Password',
		url: '/forgot-password',
		views: {
			'content@guest': {
				template: require('./forgotpass.html'),
				controller: ForgotPassController,
				controllerAs: 'ctrl',
			},
		},
	});

	$stateRegistry.register({
		name: 'guest.resetpass',
		title: 'Reset Password',
		url: '/reset-password/{token:string}?{console:int}&source',
		views: {
			'content@guest': {
				template: require('./resetpass.html'),
				controller: ResetPassController,
				controllerAs: 'ctrl',
			},
		},
		resolve: [{
				provide: 'validToken',
				deps: ['api', '$state', '$stateParams', 'authentication'],
				useFactory: (api, $state, $stateParams, authentication)=>
					api.post('authentication/password/reset/token', {token: $stateParams.token}, {level: ApiError.LEVEL.IGNORED})
						.then(()=>authentication.hasToken() && authentication.setToken(null) || true, ()=>false),
		}],
		params: {
			console: 0,
			source: {value: '', type: 'query', inherit:false},
		},
	});


	$stateRegistry.register({
		name: 'error',
		views: {'root@': {template: require('./guest.html')}},
	});
	$stateRegistry.register({
		name: 'error.404',
		title: 'Page Not Found',
		views: {
			'content@error': {template: '<h2 class="md-display-1">404 PAGE NOT FOUND</h2><p>Please report to administrator if this page should be here.</p>'},
		},
		onEnter: ['$transition$', '$state$', ($transition$, $state$)=>{
			let authorization = $transition$.injector().get('authorization');
			// is logged in, use app 404 page
			if ( authorization.isValid() )
				return $transition$.router.stateService.target('app.404', null, {location:false});
		}],
	});
	$stateRegistry.register({
		name: 'error.500',
		title: 'Page Unavailable',
		views: {
			'content@error': {template: '<h2 class="md-display-1">UNDER MAINTENANCE</h2><p>We are currently undergoing server maintenance. Please try again later.</p><center><md-button class="md-raised" onclick="javascript:location.reload()">RELOAD PAGE</md-button></center>'},
		},
	});

}];
