import angular from 'angular'
import {DependencyInjected, Helper, ApiError} from '../../common'
import Highcharts from 'highcharts'
import {pieChart} from './charts'


export default class QuestionsController extends DependencyInjected {
	static get $inject(){return [
		'$scope',
		'$q',
		'$timeout',
		'$state',
		'$transitions',
		'$location',
		'api',
		'apiMap',
		'toast',
		'MAPPINGS_JSON',
	]}

	init(){
		this.mapping = {};

		this.$q.all([
			this.apiMap.getColleges().then(data=>this.mapping.mycolleges = data),
		])
			.then(()=>{
				this.$scope.$emit('data-ready');
				this.ready = true;
				if ( this.mapping.mycolleges.length === 1 ) {
					this.selected = this.mapping.mycolleges[0];
					return this.refresh();
				} else
				if ( this.$state.params.college && this.mapping.mycolleges.byId[this.$state.params.college] ) {
					this.selected = this.mapping.mycolleges.byId[this.$state.params.college];
					return this.refresh();
				}
			})
			.finally(()=>this.isBusy = false);
		this.isBusy = true;


		this._destructors.push(
			this.$transitions.onStart({
				from: this.$state.current.name, 
				to: this.$state.current.name,
			}, transition=>{
				let obj = transition.paramsChanged();
				if ( obj.college ) {
					if ( this.mapping.mycolleges.byId[obj.college] && (!this.selected || this.selected._id !== obj.college)) {
						this.selected = this.mapping.mycolleges.byId[obj.college];
						this.refresh();
					}
				} else
				if ( obj.hasOwnProperty('college') ) {
					this.selected = null;
				}
			})
		);
	}

	refresh(){
		if ( ! this.selected ) return this.$q.reject();

		this.$location.search({college: this.selected._id});

		let params = {
			college: this.selected._id,
			myTokenAdministers: true,
		};
		let chartOptions = {
			title:{text:''},
			legend: {enabled: false},
			plotOptions: {pie: {
				showInLegend: false,
				dataLabels: {
					enabled: true,
					format: '<b>{point.name}</b>: {point.percentage:.1f}% ({point.y})',
					style: {
						color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
					}
				}
			}},
		};

		this.results = {};
		this.isBusy = true;

		return this.api.get('cohortTypes', {
			college: this.selected._id,
			source: 'signup_ui',
			myTokenAdministers: true,
		}, {
			level: ApiError.LEVEL.DEPENDENCY,
		})
			.then(res=>{
				let pendings = res.data.map(type=>{
					if ( type.gg_managed ) return;
					this.results[type.name] = null;

					return this.api.get('analysis/answersPerCollegeQuestion', {
						college: this.selected._id,
						cohortType: type.name,
						myTokenAdministers: true,
					}, {
						level:ApiError.LEVEL.DEPENDENCY,
					})
						.then(res=>{
							if ( ! res.data.question ) {
								delete this.results[type.name];
								return this.$q.resolve();
							}

							this.results[type.name] = res.data;
							res.data.totalAnswers = 0;
							Object.values(res.data.results).forEach(n => res.data.totalAnswers+=n);

							return this.$timeout(()=>{
								let data = Object.keys(res.data.results).map(key=>({name: key, y: res.data.results[key]}));
								let opts = Helper.deepCopy(chartOptions);
								opts.title.text = res.data.question;

								try { pieChart(type.name, data, opts);
								} catch(e) { console.error(e); }
							}, 100);
						});
				}).filter(v=>!!v);
				this.chartCount = pendings.length;

				if ( this.chartCount === 0 ) {
					this.isBusy = false;
					return this.$q.resolve();
				}

				this.$q.race(pendings)
					.finally(()=>this.isBusy = false);

				return this.$q.all(pendings)
			})
			.catch(err=>console.error(err))
			.finally(()=>this.isBusy = false);
	}

}

