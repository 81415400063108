<header id="notifications-list-header" class="md-whiteframe-2dp flex-none flex-order--5">
	<form name="headerForm" novalidate ng-model-options="{updateOn:'default blur', debounce:{'default':250, 'blur':0}}">
		<div class="layout-row layout-align-start-center layout-align-gt-sm-space-between-stretch layout-wrap">
			<h2 id="pageTitle" class="md-headline flex">Tips</h2>
			<div class="flex-none hide-gt-sm" ng-cloak>
				<md-button class="md-primary compact" aria-label="Calendar View" ng-click="ctrl.switchView('calendar')" ng-show="ctrl.query.view != 'calendar'">
					<span class="far fa-calendar-alt calandar-btn"></span> 
					<span class="icon-title">Calendar View</span>
				</md-button>
				<md-button class="md-primary compact" aria-label="List View" ng-click="ctrl.switchView('list')" ng-show="ctrl.query.view != 'list'">
					<span class="fas fa-list"></span> 
					<span class="icon-title">List View</span>
				</md-button>
			</div>

			<div class="flex-100 flex-gt-sm-50 layout-row">
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SEARCH -->
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERBTN -->
			</div>
		</div>

		<div class="filter-group md-chips md-removable">
			<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERCHIPS -->

			<div class="filters-nav-wrap flex">
				<md-sidenav class="filters-nav md-sidenav-right flex layout-column layout-gt-sm-row layout-gt-sm-wrap layout-align-start-stretch" 
					md-component-id="filters-nav" md-is-locked-open="mainCtrl.$mdMedia('gt-sm')" ng-cloak>
					<b class="md-headline hide-gt-sm">Filters &amp; Sort</b>
					<!-- <md-input-container class="filters" md-no-float>
						<label>Type</label>
						<md-select name="types" arial-label="Filter by type"
							ng-model="ctrl.filters._types" 
							md-on-close="headerForm.types.$dirty && ctrl.updateFilters(true)">
							<md-option ng-repeat="item in ctrl.mapping.types" ng-value="item">{{ item.name }}</md-option>
						</md-select>
					</md-input-container> -->
					<md-input-container class="filters" md-no-float>
						<md-select name="status" aria-label="Filter by status"
							ng-model="ctrl.filters._status" 
							md-selected-text="'Status'"
							md-on-close="headerForm.status.$dirty && ctrl.onFiltersChange(true)">
							<md-option ng-repeat="item in ctrl.mapping.status" ng-value="item">{{ item.name }}</md-option>
						</md-select>
					</md-input-container>
					<md-input-container class="filters" md-no-float>
						<md-select name="categories" multiple
							aria-label="Filter by category"
							ng-model="ctrl.filters._categories" 
							md-on-close="headerForm.categories.$dirty && ctrl.onFiltersChange(true)"
							md-selected-text="'Category'">
							<md-option ng-repeat="item in ctrl.mapping.categories" ng-value="item">{{ item.name }}</md-option>
						</md-select>
					</md-input-container>
					
					<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERS -->

					<div class="make-right layout-xs-column layout-sm-column">
						<span class="flex-gt-md"></span>
						<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERDATES -->
						<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SORTS -->
						<md-input-container class="flex-none hide show-gt-sm" ng-cloak>
							<md-button class="md-primary compact" aria-label="Calendar View" ng-click="ctrl.switchView('calendar')" ng-show="ctrl.query.view != 'calendar'">
								<i class="far fa-calendar-alt calendar-btn"></i> <span class="icon-title"> Calendar View</span>
							</md-button>
							<md-button class="md-primary compact" aria-label="List View" ng-click="ctrl.switchView('list')" ng-show="ctrl.query.view != 'list'">
								<i class="fas fa-list"></i><span class="icon-title"> List View</span>
							</md-button>
						</md-input-container>
					</div>
				</md-sidenav>
			</div>
		</div>
	</form>
</header>

<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_ERROR -->
<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

<footer class="md-whiteframe-2dp layout-row layout-align-start-center layout-wrap">
	<md-button class="md-icon-button md-mini md-primary" 
		aria-label="Refresh results" title="Refresh results"
		ng-click="ctrl.refresh(true)">
		<span class="fas fa-sync-alt" ng-class="{'rotating': ctrl.isBusy || ctrl.partialBusy}"></span>
	</md-button>
	<div id="footerResults" class="md-body-1" ng-hide="ctrl.query.view=='calendar'">
		{{ ctrl.query.total }} <span class="hide-xs">Results</span>
	</div>
	<span flex></span>
	
	<md-button class="md-primary" 
		aria-label="add new {{::ctrl.$_resource}}"
		ui-sref=".add" ui-sref-opts="{inherit:false}"
		ng-if=":: mainCtrl.hasPermission(PERMISSIONS.CREATE_TIPS)">
		<span class="fas fa-plus"></span> 
		<span class="hide-xs">Add</span> New
	</md-button>

	<md-button id="footerExport" class="md-accent"
		aria-labelledby="footerExport footerResults"
		ng-click="ctrl.exportCSV('tips.csv')" 
		ng-show="ctrl.query.view=='list'">
		<span class="fas fa-file-export"></span> 
		Export <span class="hide-xs">CSV</span>
	</md-button>

	<div class="control-panel-smaller flex-none flex-gt-md text-right" ng-show="ctrl.isCalendarView()">
		<md-button type="submit" class="md-raised md-warn" 
			ng-show="editCtrl && ctrl.selected" 
			ng-disabled="editCtrl.isBusy || editCtrl.isLocked || editCtrl.$scope.form.$pristine" 
			ng-click="editCtrl.submitForm('form')">
			<span class="far fa-save"></span>
			Save
		</md-button>
	</div>
</footer>

<main class="main-list notifications-list flex flex-order--1 layout-column no-animate"
	aria-labelledby="pageTitle footerResults"
	ng-show="ctrl.query.view=='list'">
	<md-virtual-repeat-container class="data-list flex no-animate"
		md-top-index="ctrl.itemIndex" 
		ng-if="ctrl.dataList.length > 0">
		<div id="item-{{ item._id }}" class="data-item notification-item layout-column no-animate" ng-class="{loading: !item}" 
			md-virtual-repeat="item in ctrl" md-on-demand="true">
			<div class="layout-column flex"  
				role="group" aria-label="{{ctrl._$resource +' id: #'+ item._id}}"
				ui-sref="app.tips.edit({id: item._id})" ng-include="'events.item.tpl'"></div>
		</div>
	</md-virtual-repeat-container>
	<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_EMPTY_RESULTS -->

	<section id="expanded-overlay" class="no-animate layout-column" 
		role="group" aria-label="{{ ctrl._$resource +' #'+ ctrl.selected._id +' showing reminders' }}"
		ng-show="!!ctrl.selected && !ctrl.isBusy" ng-cloak>
		<div class="data-list no-animate md-whiteframe-1dp" 
			ng-if="ctrl.selected" 
			ng-init="item = ctrl.selected">
			<div class="data-item notification-item layout-column">
				<div class="layout-column flex" 
					role="group" aria-label="{{ctrl._$resource +' ID: #'+ item._id}}"
					ui-sref="app.tips.edit({id: item._id})" 
					ng-include="'events.item.tpl'"></div>
			</div>
			<md-toolbar class="md-info-theme md-primary md-hue-1 md-whiteframe-1dp">
				<div class="md-toolbar-tools layout">
					<h4 class="flex md-title padding-2x normal-wg">Reminders</h4>
					<md-button class="md-icon-button black-66" aria-label="Hide reminders" ng-click="ctrl.close()">
						<span class="far fa-times-circle md-title" aria-hidden="true"></span>
					</md-button>
				</div>
			</md-toolbar>
		</div>
		<div class="data-list flex layout-column can-scroll-y indent-2x">
			<div class="data-item md-body-1 flex-none layout-row no-animate" 
				role="group" aria-label="reminder #{{$index+1}}"
				ng-repeat="reminder in ctrl.selected.reminders track by $index">
				<div class="padding-2x" layout="column">
					<span>{{ reminder.date_to_send | amDateFormat:SETTINGS.dateFormat }}</span>
					<small class="md-caption black-50">{{ reminder.question ? 'Question' : 'Reminder' }}</small>
					<small class="md-caption black-50">ID#{{ ctrl.selected._id * 100 + reminder._id }}</small>
				</div>
				<span class="padding-1x"></span>
				<div class="padding-2x md-body-1 black-66 md-truncate" flex>
					<span class="text-block" ng-bind-html="reminder.text"></span>
				</div>
			</div>
			<div class="no-animate flex layout layout-align-center-center" ng-show="!ctrl.selected.reminders.length && !ctrl.partialBusy">
				<p class="text-center alpha-50 md-caption"><em>No reminders</em></p>
			</div>
		</div>
	</section>
</main>

<main class="main-list notifications-calendar flex-gt-sm flex-order--1 layout-gt-md-row layout-align-gt-md-space-between-start no-animate" ng-show="ctrl.query.view=='calendar'">
	<div id="calendar-panel" class="panel sticky flex-gt-md layout-gt-md-column md-whiteframe-1dp">
		<div class="flex-gt-md-100 layout-gt-md-column margin-4x can-scroll-y">
			<div class="layout-row layout-align-start-center">
				<div class="btn-group md-raised flex-none">
					<md-button type="button" class="md-primary md-raised compact" ng-click="ctrl.calendar.prev()" aria-label="Go to previous month">
						<i class="fas fa-angle-left"></i></md-button>
					<md-button type="button" class="md-primary md-raised compact" ng-click="ctrl.calendar.next()" aria-label="Go to next month">
						<i class="fas fa-angle-right"></i></md-button>
				</div>
				<div class="flex-none">
					<md-button type="button" class="md-primary md-raised compact" ng-click="ctrl.calendar.today()" aria-label="Go to present day">Today</md-button>
				</div>
				<i flex></i>
				<h3 class="flex-none md-headline margin-2x">{{ (ctrl.calendarDate || moment()) | amDateFormat: 'MMMM YYYY' }}</h3>
			</div>
			<div id="calendar" class="panel-content"></div>
		</div>
	</div>
	
	<div id="detail" class="panel-wrap panel-smaller flex-gt-md-none no-animate">
		<div ui-view name="detail" class="no-animate"></div>
		<div class="busy-overlay fadeIn" ng-show="!ctrl.isBusy && (ctrl.detailBusy || editCtrl.isBusy)" ng-cloak>
			<md-progress-circular md-mode="indeterminate"></md-progress-circular>
		</div>
	</div>

</main>
