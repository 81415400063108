<header id="cohorts-list-header" class="md-whiteframe-2dp flex-none flex-order--5">
	<form name="headerForm" novalidate ng-model-options="{updateOn:'default blur', debounce:{'default':500, 'blur':0}}">
		<div class="layout-row layout-align-end-end layout-wrap">
			<h2 id="pageTitle" class="md-headline flex-auto">
				<a ui-sref="app.cohorts">Cohorts</a><span class="margin-2x" aria-hidden="true">&#9656;
				</span><a ui-sref="^">{{ ctrl.data.description || ctrl.data.name }}</a><span class="margin-2x" aria-hidden="true">&#9656;
				</span>Manage Student IDs
			</h2>
			<i class="flex"></i>

			<div class="flex-100 flex-gt-sm-40 layout">
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SEARCH -->
			</div>

			<div class="flex-nogrow">
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SORTS -->

				<md-input-container class="flex-nogrow">
					<md-button class="md-primary compact" aria-label="edit cohort: {{ctrl.data.description}}" ui-sref="app.cohorts.edit({id: ctrl.data._id})">
						<i class="far fa-edit"></i> Edit Cohort
					</md-button>
				</md-input-container>
			</div>
		</div>
	</form>


</header>

<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_ERROR -->
<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

<footer class="md-whiteframe-2dp layout-align-start-center layout-row layout-wrap">
	<md-button class="md-icon-button md-mini md-primary" ng-click="ctrl.refresh(true)" aria-label="Refresh">
		<span class="fas fa-sync-alt"></span>
	</md-button>
	<p class="md-body-1">{{ ctrl.query.total }} Results</p>

	<md-button type="button" class="md-primary inline" ng-click="ctrl.selectAll(!ctrl._$allSelected)" ng-disabled="!ctrl.dataList.length">
		<span class="far" ng-class="ctrl._$allSelected ? 'fa-check-square' : 'fa-square'"></span>
		{{ !ctrl._$allSelected ? 'Select' : 'Deselect' }} All
	</md-button>
	<span flex></span>

	<md-button class="md-primary" ng-click="ctrl.addOne($event)">
		<span class="fas fa-plus"></span> <span class="hide-xs">Add </span> 
		<span ng-hide="ctrl.isSalesforceManaged">New</span> 
		<span ng-show="ctrl.isSalesforceManaged">Demo Student ID</span>
	</md-button>
	<md-button class="md-primary" ng-click="ctrl.batchOpen($event)" ng-hide="ctrl.isSalesforceManaged" ng-cloak>
		<span class="fas fa-plus-square"></span> <span class="hide-xs">Add</span> Batch
	</md-button>
	<md-button class="md-warn md-hue-2" ng-click="ctrl.deleteSelected($event)" ng-show="ctrl.dataList.length && (ctrl._$allSelected || ctrl.tracking.length > 0)">
		<span class="far fa-trash-alt"></span> Delete ({{ ctrl._$allSelected ? ctrl.query.total - ctrl.tracking.length : ctrl.tracking.length }})
	</md-button>
	<md-button class="md-warn" ng-click="ctrl.exportCSV()" ng-hide="ctrl.dataList.length && (ctrl._$allSelected || ctrl.tracking.length > 0)" ng-disabled="!ctrl.dataList.length">
		<span class="fas fa-file-export"></span> Export <span class="hide-xs">CSV</span>
	</md-button>
</footer>

<main class="main-list cohort-students-list flex flex-order--1 layout-column">
	<md-virtual-repeat-container class="data-list flex no-animate" md-top-index="ctrl.itemIndex" ng-show="ctrl.dataList.length > 0">
		<div id="item-{{ item._id }}" class="data-item cohort-student-item layout-column" ng-class="{loading: !item, selected: item._$selected}" md-virtual-repeat="item in ctrl" md-on-demand="true">
			<div class="layout-row flex" ng-include="::'/main/cohorts/cohort.student.item.tpl'" role="listitem"></div>
		</div>
	</md-virtual-repeat-container>

	<div class="flex layout-row layout-align-center-center no-animate" ng-hide="ctrl.dataList.length > 0">
		<p class="text-center alpha-50 md-caption" ng-hide="ctrl.isBusy || ctrl.partialBusy"><em>No results found <span ng-show="ctrl.query.search">for &quot;{{ ctrl.query.search }}&quot;</span></em></p>
	</div>
</main>


<md-sidenav id="cohort-students-batch" class="md-sidenav-right panel margin-0x layout-column" md-component-id="batch-form" md-disable-scroll-target="#cohort-students-batch">
	<div class="panel-title padding-2x layout-row">
		<h2 class="md-title padding-2x flex">Upload Batch</h2>
		<md-button class="md-icon-button black-33" ng-click="ctrl.batchClose()" aria-label="close">
			<span class="fas fa-times"></span>
		</md-button>
	</div>
	<div class="panel-content flex layout-column" ng-show="!ctrl.batchResults.length">
		<div class="dropzone margin-4x padding-4x md-caption black-66 text-center flex layout-column layout-align-center-center">
			<md-button type="button" id="fileInput" class="md-raised md-primary" ng-hide="ctrl.isBusy">
				<i class="far fa-file"></i> Select File
			</md-button>
			<span ng-hide="ctrl.isBusy">or drop files here</span>
			<md-progress-circular ng-show="ctrl.isBusy" md-mode="indeterminate"></md-progress-circular>
		</div>
	</div>
	<div class="panel-content margin-2x layout-column flex" ng-show="ctrl.batchResults.length > 0">
		<h5 class="md-subhead margin-2x">{{ ctrl.batchFile.name }}</h5>
		<md-card md-theme="::'info'" ng-show="ctrl.batchResults.invalids > 0">
			<md-card-content layout>
				<span><span class="fas fa-info-circle"></span>&nbsp;&nbsp;</span>
				<span flex>The IDs marked with <i class="fas fa-times red"></i> below do not match the student ID pattern of {{ ctrl.data_college.name }}. Please fix the file and try again.</span>
			</md-card-content>
		</md-card>
		<div class="dropzone student-id-list md-body-1 margin-2x padding-4x flex can-scroll-y">
			<div class="md-block" ng-repeat="item in ctrl.batchResults track by $index">
				<i class="fas" ng-class="{'fa-check green': item.valid, 'fa-times red': !item.valid, 'fa-exclamation-triangle': item.warn}"></i>
				{{ item.id }}
				<em class="red" ng-show="item.warn">&mdash; {{ item.warn }}</em>
				<br>
			</div>
		</div>
		<md-radio-group ng-model="ctrl.batchResults.mode" class="margin-2x layout-row" ng-disabled="ctrl.isBusy" ng-show="ctrl.dataList.length > 0 && ctrl.batchResults.length > 0 && !ctrl.batchResults.invalids">
			<md-radio-button class="flex-50" value="merge">Merge</md-radio-button>
			<md-radio-button class="flex-50" value="replace">Replace All</md-radio-button>
		</md-radio-group>
	</div>
	<div class="panel-title padding-4x layout-row layout-align-space-between-center" ng-show="ctrl.batchResults.length > 0">
		<md-button type="submit" class="md-primary" ng-click="ctrl.batchSubmit($event)" ng-disabled="!ctrl.batchFile || ctrl.batchResults.invalids || ctrl.isBusy">
			<i class="far fa-save"></i> Submit
		</md-button>
		<md-progress-circular ng-show="ctrl.isBusy" md-mode="indeterminate" md-diameter="20"></md-progress-circular>
		<md-button type="reset" class="black-50" ng-click="ctrl.batchClear()" ng-disabled="!ctrl.batchFile || ctrl.isBusy">
			<i class="fas fa-eraser"></i> Clear
		</md-button>
	</div>
</md-sidenav>

<script type="text/ng-template" id="/main/cohorts/cohort.student.item.tpl">
		<div class="layout-row flex" ng-class="{lock: item._$lock}"
			ng-click="item && !item._$lock ? ctrl.selectOne(item, item._$selected = !item._$selected) : null; $event.stopPropagation();"
			role="checkbox" tabindex="0"
			aria-checked="{{ item._$selected }}"
			aria-labelledby="student_{{item._id}}"
		>
			<div class="margin-2x">
				<md-checkbox class="checkbox margin-0x" aria-label="select student"
					tabindex="-1"
					ng-model="item._$selected" 
					ng-click="$event.stopPropagation()" 
					ng-change="ctrl.selectOne(item, item._$selected)" 
					ng-disabled="item._$lock">
				</md-checkbox>
				<!-- <md-icon class="fas fa-lock padding-1x black-50" ng-show="item._$lock" ng-cloak></md-icon> -->
			</div>
			<div class="md-block padding-2x flex layout-row layout-wrap layout-align-start-center">
				<!-- <span class="md-caption black-33"><small>ID#{{ item._id }}</small></span> -->
				<h3 class="md-subhead margin-0x text-center flex" id="student_{{item._id}}">{{ item.external_student_id }}</h3>
				<span class="flex-xs-100 md-caption black-50 text-right">
					<small>created {{item.created_by_user_id ? 'by' : ''}} </small>{{ item.created_by_user_id ? ctrl.mapping.created_by_user_ids.byId[item.created_by_user_id].first_name || 'user#'+item.created_by_user_id : '' }}
					<br class="hide-xs">
					{{ item.created_date | amDateFormat:SETTINGS.dateTimeFormat }}
				</span>
			</div>
		</div>
</script>
