import angular from 'angular'
import {Helper, ApiError} from '../../common'
import BaseSingleController from '../base.single'


export default class DistrictSingleController extends BaseSingleController {
	static get $inject(){return [
		'parentStateParams',
		'$timeout',
		'$mdDialog',
	].concat(BaseSingleController.$inject)}


	init(){
		this.mapping = {
			types: Helper.deepCopy(this.MAPPINGS_JSON.colleges.type_key),
			status: {
				active: 'Active',
				inactive: 'Inactive',
			},
		};

		this.model = Helper.deepCopy(this.data || {status: 'active', type: 'community_college', ancestry: 'v2'});

		super.init();
	}

	submit($evt, form){
		this._validateForm(form)
			.then(()=>{
				let payload = Helper.deepCopy(this.model);
				if ( this.data ) {
					return this.api.put('districts/'+ this.data._id, payload)
						.then(res=>{
							this.toast.success('District Updated');
							return res;
						});
				} else {
					return this.api.post('districts', payload)
						.then(res=>{
							this.toast.success('New District Saved');
							return res;
						});
				}
			})
			.then(res=>{
				this.promptExit.disable();
				this.$state.go('^', this.data && this.parentStateParams || {});
			}, err=>{
				if ( this.api.isApiError(err) ) {
					err.name = 'Unable to Save';
					this.toast.error(err);
				}
			})
			.finally(()=>this.isBusy = false);
		this.isBusy = true;
	}

	_validateForm(form){
		let promise = this.$q.resolve(true);  // use this to chain promises

		if ( Object.keys(form.$error || {}).length > 0 ) {
			let $first;
			Object.values(form.$error).forEach(list=>list.forEach($item=>{
				if ( ! $first ) $first = $item;
				$item.$setTouched();
			}));
			promise = promise.then(()=>this.$q.reject(true));
			$first && $first.$$element.focus();
		}

		return promise;
	}

	delete($ev){
		this.$mdDialog.show(
			this.$mdDialog.confirm()
          .title('Are you sure you want to delete this district?')
          .ariaLabel('confirm delete')
          .targetEvent($ev)
          .ok('Delete')
          .cancel('Cancel')
		).then(()=>{
			this.isBusy = true;
			return this.api.delete('districts/'+ this.data._id)
				.finally(()=>this.isBusy = false);
		})
		.then(()=>{
			this.toast.success('District Deleted');
			this.$state.go('^', this.session.get('districts') || {});
		});
	}

}