import angular from 'angular'
import $ from 'jquery'
import BaseList from '../base.list'
import {Helper, OP_TO_WORD} from '../../common'


export default class MilestonesBatchListController extends BaseList {
	static get $inject(){return [
		'$q',
		'$animateCss',
		'api',
		'apiMap',
		'MAPPINGS_JSON',
		'recipient',
		'authorization',
		'toast',
		'$mdDialog',
	].concat(BaseList.$inject)}

	init(){
		this.mapping = {
			fileStatus: Helper.superMap(this.MAPPINGS_JSON.file_process.status, {type:'file_status'}),
			status: Helper.superMap(this.MAPPINGS_JSON.notification.status, {type:'status'}),
			types: Helper.superMap(this.MAPPINGS_JSON.notifications.type_key, {type:'type'}),
			categories: Helper.superMap(this.MAPPINGS_JSON.notifications.category_key, {type:'category'}),
			sortBy: Helper.superMap({
				file_name: 'File Name', 
				processed_date: 'Upload Date',
				created_by_user_id: 'Author',
				record_count: 'Record Count',
			}),
			sortOrder: Helper.superMap({asc:'Asc', desc:'Desc'}),
		};

		this.hideSteps = true;

		super.init();

		this.query = angular.extend({
				sortBy: 'processed_date',
				sortOrder: 'desc',
			}, this.query);
	}
	_loadDependencies(){
		return this.$q.all([
			super._loadDependencies(),
			this.apiMap.getColleges().then((data)=>this.mapping.mycolleges = data),
			this.apiMap.getAllDistricts().then((data)=>this.mapping.districts = data),
	    this.apiMap.getAllProvidedCohorts().then((data)=>this.mapping.cohorts = data),
	    this.apiMap.getAllCohorts().then((data)=>this.mapping.allcohorts = data),
	    this.apiMap.getCohortLevels().then((data)=>this.mapping.mylevels = data),
	    this.apiMap.getUsersCollegeAdminUp().then((data)=>this.mapping.users = data),
		])
			.then(()=>this.recipient.prepare())
			.then(()=>{
					this.onUrlParamChange(this.$state.params);
					this.refresh(true)
						.then(()=>this._loadScrollState());
			});
	}


	onUrlParamChange(obj){
		if ( obj.hasOwnProperty('sortBy') )
			this.query.sortBy = obj.sortBy || this.query.sortBy;
		if ( obj.hasOwnProperty('sortOrder') )
			this.query.sortOrder = obj.sortOrder || this.query.sortOrder;
	}
	getFilters(){
		return {
			sortBy: this.query.sortBy || undefined,
			sortOrder: this.query.sortOrder || undefined,
		};
	}


	refresh(reset){
		if ( reset ) {
			this.dataList = [];
			this.itemIndex = 0;
			this._errors = [];
		}
		return this._refresh('tasks/bulk', this.getParams(reset));
	}

	process(list){
		return list;
	}

	processMilestones(list){
		let missingUsers = [];

		list.forEach((item)=>{
			let groups = Object.keys(item.recipients || {}),
				exprs = Object.values(item.recipients || {}),
				chips = item._chips = [];
			exprs.forEach((expr, i)=>{
				const list = this.recipient.enumerate(expr, {ignoreInvalids:true, ignoreErrors:true});
				list.forEach(chip=>chip.opWord = OP_TO_WORD[chip.op]);
				Array.prototype.splice.apply(chips, [0,0].concat(list));
			});

			// BTC-72 clean up description html tags & entities
			item.description.raw = Helper.decodeHtml(Helper.stripTags(item.description.formatted)) || item.description.raw; 
      item.title = Helper.toSafeLangHtml(item.content_directory.title);
			// item.points = 0;
			item.steps = Object.values(item.steps || {}).map((step)=>{
				if ( ! item.last_deadline || step.deadline < item.last_deadline )
					item.last_deadline = step.deadline;
				// item.points += step.points;
				return step;
			});

			if ( item.created_by_user_id && ! this.mapping.users.byId[item.created_by_user_id] && ! missingUsers.includes(item.created_by_user_id) )
				missingUsers.push( item.created_by_user_id );
			if ( item.modified_by_user_id && ! this.mapping.users.byId[item.modified_by_user_id] && ! missingUsers.includes(item.modified_by_user_id) )
				missingUsers.push( item.modified_by_user_id );

		});

		missingUsers.forEach((id)=>this.api.get('users/'+ id, null, {cache:true})
			.then((res)=>{
				if ( ! this.mapping.users.byId[id] )
					this.mapping.users.push(this.mapping.users.byId[id] = res.data);
			}, ()=>{}));

		return list;
	}

	_open(item){
		const defer = this.$q.defer();

		this.selected = item;
		item.isBusy = true;

		if ( item.milestones ) {
			this.$timeout(()=>defer.resolve(item.isBusy=false), 800);
			return defer.promise;
		} else {
			item.milestones = [];
		}
			

		return this.api.get('tasks', {batch: item._id, myTokenAdministers:true})
			.then((res)=>item.milestones = this.processMilestones(res.data))
			.catch((err)=>{
				if ( this.api.isApiError(err) && ! err.isIgnored() ) {
					err.message = 'Resource unavailable';
					this.toast.error(err);
					item.error = err;
				}
				throw err;
			})
			.finally(()=>item.isBusy = false);
	}

	/*open(item){
		this.selected = item;
		let $window = $(window),
			$item = $('#item-'+ this.selected._id),
			offset = $item.offset(),
			height = $item.height(),
			wheight = $window.height();
		let style = {
			top: (offset.top - $window.scrollTop()) +'px',
			bottom: (wheight - offset.top + $window.scrollTop() - height) +'px',
		};

		this.$scope.$evalAsync(()=>{
			$item.css('visible', 'hidden');
			let $overlay = $('#batch-overlay').css(style);
			setTimeout(()=>$overlay.addClass('animated').css({top: '', bottom: ''}), 13);
		});

		if ( item._$milestones ) {
			this.milestones = item._$milestones;
			return;
		}

		this.api.get('badges', {batch: item._id, myTokenAdministers:true})
			.then((res)=>{
				item._$milestones = this.milestones = this.processMilestones(res.data);
			})
			.catch((err)=>{
				if ( this.api.isApiError(err) && ! err.isIgnored() ) {
					err.message = 'Resource unavailable';
					this.toast.error(err);
				}
			})
			.finally(()=>{
				this.partialBusy = false;
			});
		this.milestones = [];
		this.partialBusy = true;
	}*/
	/*close(){
		let $window = $(window),
			$item = $('#item-'+ this.selected._id),
			offset = $item.offset(),
			height = $item.height(),
			wheight = $window.height();
		let style = {
			top: (offset.top - $window.scrollTop()) +'px',
			bottom: (wheight - offset.top + $window.scrollTop() - height) +'px',
		};
		this.$scope.$evalAsync(()=>{
			let $overlay = $('#batch-overlay').removeClass('animated');
			this.$animateCss(angular.element($overlay[0]), {
					easing: 'ease-out',
					to: style,
					duration: 0.4, // seconds
				}).start().then(()=>{
					$item.css('visible', '');
					this.selected = null;
				});
		});
	}*/


	delete(id, $ev){
		this.$mdDialog.show(
			this.$mdDialog.confirm()
					.title('Are you sure you want to delete this milestone batch?')
					.ariaLabel('confirm delete')
					.targetEvent($ev)
					.ok('Delete')
					.cancel('Cancel')
		).then(()=>{
			this.isBusy = true;
			return this.api.delete('tasks/bulk/'+ id);
		})
		.then(()=>{
			this.toast.success('Notification Batch Deleted');
			this.refresh(true);
		})
		.catch(()=>{});
	}




}