import angular from 'angular'
import moment from 'moment'
import Highcharts from 'highcharts'
import {DependencyInjected, Helper, ApiError} from '../../common'
import {menuData} from '../main.controller'
import {pieChart} from './charts'


export default class NotificationsPerCategoryController extends DependencyInjected {
	static get $inject(){return [
		'$scope',
		'$q',
		'api',
		'authorization',
	]}

	init(){
		this.$scope.$parent.ctrl = this;
		this.main = this.$scope.mainCtrl;
		this.mapping = this.main.mapping;

		this.title = 'Notifications per Category';
		this.subject = 'Notifications';
		this.icon = 'far fa-bell';
		this.iconColor = '';
		this.hideDateRange = true;
		this.hideFilters = false;

		this.data = {};

		delete this.main.filters._$keys.date; // remove so it doesn't show on filter chips
		this.main.filters._date = null;//this.main.dateRange;

		this.main._pending.then(()=>{
			this.hideFilters = this.mapping.mycolleges.length <= 1;
			this.$scope.$emit('data-ready');
			this.ready = true;
			this.refresh();
		});

		this._destructors.push(()=>{
			if ( this._defer ) this._defer.resolve('abort');
		});
	}

	refresh(){
		let params = angular.extend({
			// college: this.main.filters._mycolleges && this.main.filters._mycolleges.map(item=>item._id) || null,
			college: this.main.filters._mycolleges && this.main.filters._mycolleges._id || null,
		}, {
			myTokenAdministers: true,
		});

		var chartOptions = {
			title:{text:''},
			legend: {enabled: false},
			plotOptions: {pie: {
				showInLegend: false,
				dataLabels: {
					enabled: true,
					format: '<b>{point.name}</b>: {point.percentage:.1f} %',
					style: {
						color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
					}
				}
			}},
		};

		if ( this._defer ) this._defer.resolve('abort');
		this._defer = this.$q.defer();
		this.dataTable = null;
		this.main.isBusy = true;

		return this.api.get('analysis/notificationsPerCategory', params, {timeout: this._defer.promise})
			.then((res)=>{
				this.totalData = 0;
				let data = res.data.map((item)=>{
					this.totalData += item.count;
					return {name: item.name, y: item.count};
				});

				let theChart = pieChart( 'the-chart', data, chartOptions );
				this.dataTable = theChart.getDataRows().slice(1);
				this.dataTable.headers = ['Category', 'Notifications'];
			})
			.catch((err)=>{
				if ( this.api.isApiError(err) ) {
					if ( ! err.isIgnored() )
						this.main.error = err;
				} else {
					console.error(err);
				}
			})
			.finally(()=>{
				this._defer = undefined;
				this.main.isBusy = false;
			});
	}

}

