import angular from 'angular'
import $ from 'jquery'
import BaseList from '../base.list'
import {Helper, SETTINGS, OP_TO_WORD, PERMISSION_LEVELS} from '../../common'


let hasAllAccess = false;

export default class MilestonesListController extends BaseList {
	static get $inject(){return [
		'$sce',
		'api',
		'apiMap',
		'MAPPINGS_JSON',
		'recipient',
		'authorization',
	].concat(BaseList.$inject)}

	init(){
		this.mapping = {
			date: Helper.superMap({date: 'anytime'}, {_$groupId:'date'}, (list)=>list.single=true),
			sortBy: Helper.superMap({
				id:'ID',
				title:'Title',
				points:'Points',
				deadline:'Deadline',
				created_by:'Author',
			}),
			sortOrder: Helper.superMap({asc:'Asc', desc:'Desc'}),
			validations: Helper.superMap({
					qr: this.MAPPINGS_JSON.tasks.validation.qr,
					date_selection: this.MAPPINGS_JSON.tasks.validation.date_selection,
					question: this.MAPPINGS_JSON.tasks.validation.question,
				}, {type:'validation'}),
			myterms: Helper.superMap(this.MAPPINGS_JSON.colleges.terms),
		};

		this._destructors.push(
			this.$scope.$on('mapFilter.removed', ($e, item)=>{
				if ( item === this.query.date )
					this.query.dateFrom = this.query.dateTo = null;
			})
		);


		super.init();
		
		hasAllAccess = this.hasPermissionLevel(PERMISSION_LEVELS.ALL);

		this.filters._$keys = {
			'mycolleges': 'College',
			'mydistricts': 'District',
			'myterms': 'Term',
			'mylevels': 'Level',
			'date': 'Date Range',
		};

		this.query = angular.extend({
				search: '', 
				sortBy: 'id',
				sortOrder: 'desc',
				date: this.mapping.date[0],
			}, this.query);
	}
	_loadDependencies(){
		return this.$q.all([
			this.apiMap.getColleges().then((data)=>this.mapping.mycolleges = data),
			this.apiMap.getDistricts().then((data)=>this.mapping.mydistricts = data),
			this.apiMap.getColleges().then((data)=>this.mapping.mycolleges = data),
			this.apiMap.getAllDistricts().then((data)=>this.mapping.districts = data),
	    this.apiMap.getCohortLevels().then((data)=>this.mapping.mylevels = data),
	    this.apiMap.getPublicUsers().then((data)=>this.mapping.users = data),
		])
			.then(()=>this.recipient.prepare())
			.then(()=>{
				this.onUrlParamChange(this.$state.params);
				this.refresh(true)
					.then(()=>this._loadScrollState());
				
				if ( !this.hasPermissionLevel(PERMISSION_LEVELS.ALL) )
					delete this.filters._$keys.mydistricts;
				if ( this.mapping.mycolleges.length === 1 )
					delete this.filters._$keys.mycolleges;
			});
	}
	getParams(reset){
		let filters = this.getFilters(true),
			params = {
				limit: this.query.limit,
				offset: this.query.offset = (reset ? 0 : this.query.offset),
				publish: true,
				withMetadata: true,
			};
		return angular.extend({}, filters, params);
	}

	getFilters(all){
		return angular.extend(super.getFilters(all), {
			colleges: (this.filters._mycolleges || []).map((item)=>item._id),
			districts: (this.filters._mydistricts || []).map((item)=>item._id),
			terms: (this.filters._myterms || []).map((item)=>item._id),
			cohorts: (this.filters._mylevels || []).map((item)=>item.name),
		});
	}
	onUrlParamChange(obj){
		if ( obj.hasOwnProperty('colleges') )
			this.filters._mycolleges = (obj.colleges || [])
				.map(id=>this.mapping.mycolleges.byId[id])
				.filter(Helper.trueishFilter);
		if ( obj.hasOwnProperty('districts') )
			this.filters._mydistricts = (obj.districts || [])
				.map(id=>this.mapping.mydistricts.byId[id])
				.filter(Helper.trueishFilter);
		if ( obj.hasOwnProperty('terms') )
			this.filters._myterms = (obj.terms || [])
				.map(id=>this.mapping.myterms.byId[id])
				.filter(Helper.trueishFilter);
		if ( obj.hasOwnProperty('cohorts') )
			this.filters._mylevels = (obj.cohorts || [])
				.map(name=>this.mapping.mylevels.byName[name])
				.filter(Helper.trueishFilter);

		
		if ( obj.hasOwnProperty('sortBy') )
			this.query.sortBy = obj.sortBy || 'id';
		if ( obj.hasOwnProperty('sortOrder') )
			this.query.sortOrder = obj.sortOrder || 'desc';
		
		const keys = ['colleges', 'districts', 'terms', 'cohorts', 'sortBy', 'sortOrder']
		return super.onUrlParamChange(obj) || !! Object.keys(obj).find(v=>keys.includes(v));
	}

	refresh(reset){
		if ( reset ) {
			this.dataList = [];
			this.itemIndex = 0;
			this._errors = [];
		}

		return this._refresh('tasks', this.getParams(reset));
	}

	process(list){
		let missingUsers = [];

		list.forEach((item)=>{
			this._processItem(item);

			if ( item.created_by_user_id && ! this.mapping.users.byId[item.created_by_user_id] && ! missingUsers.includes(item.created_by_user_id) )
				missingUsers.push( item.created_by_user_id );
			if ( item.modified_by_user_id && ! this.mapping.users.byId[item.modified_by_user_id] && ! missingUsers.includes(item.modified_by_user_id) )
				missingUsers.push( item.modified_by_user_id );
		});

		missingUsers.forEach((id)=>this.api.get('users/'+ id, null, {cache:true})
			.then((res)=>{
				if ( ! this.mapping.users.byId[id] )
					this.mapping.users.push(this.mapping.users.byId[id] = res.data);
			}, angular.noop));

		return list;
	}
	_processItem(item){
		let groups = Object.keys(item.recipients),
			exprs = Object.values(item.recipients),
			itemChips = item._chips = [];
		exprs.forEach((expr, i)=>{
			let chips = this.recipient.enumerate(expr, {ignoreInvalids:true, ignoreErrors:true})
				.filter(item=>item.model);
			if ( this.mapping.mycolleges.length === 1 || !hasAllAccess ) {
				chips = chips.filter(chip=>chip.type!='college' || (!hasAllAccess && chip._id==item.owner_college_id));
			}
			chips.forEach(chip=>{
				chip.opWord = OP_TO_WORD[chip.op];
				chip.ariaLabel = `${chip.op=='!='?'not in ':''}${chip.type}: ${chip.model.title || chip.model.name || chip._id}`;
			});
			Array.prototype.splice.apply(itemChips, [0,0].concat(chips));
		});

		item.title = this.toSafeLangHtml(item.content_directory.title);

		if ( item.description && item.description.formatted && !item.description.raw ) {
			item.description.raw = Helper.htmlToText(item.description.formatted);
		}

		item.steps = Object.keys(item.steps || {}).map((id)=>{
			let step = item.steps[id];
			step._id = +id;
			step.title = this.toSafeLangHtml(step.title);

			if ( ! item.last_deadline || step.deadline < item.last_deadline ) {
				item.last_deadline = step.deadline;
			}
			if ( step.description.formatted && !step.description.raw ) {
				step.description.raw = Helper.htmlToText(step.description.formatted);
			}
			return step;
		});
	}


	_open(item){
		return this.$q.resolve({data: item.steps});
	}

}