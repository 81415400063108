<header id="users-list-header" class="md-whiteframe-2dp flex-none flex-order--5">
	<form name="headerForm" novalidate ng-model-options="{updateOn:'default blur', debounce:{'default':500, 'blur':0}}">
		<div class="layout-row layout-align-start-center layout-align-gt-sm-space-between-stretch layout-wrap">
			<h2 id="pageTitle" class="md-headline flex">Users</h2>

			<div class="flex-100 flex-gt-sm-50 layout-row">
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SEARCH -->
				<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERBTN -->
			</div>
		</div>	

		<div class="filter-group md-chips md-removable">
			<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_FILTERCHIPS -->

			<div class="filters-nav-wrap flex">
				<md-sidenav class="filters-nav md-sidenav-right layout-column layout-gt-sm-row layout-gt-sm-wrap layout-align-start-stretch flex" 
					role="group" aria-labelledby="rightSideNav"
					md-component-id="filters-nav" md-is-locked-open="mainCtrl.$mdMedia('gt-sm')" ng-cloak>
					<b id="rightSideNav" class="md-headline hide-gt-sm">Filters &amp; Sort</b>
					<md-input-container class="filters" md-no-float="true">
						<md-select name="status"
							aria-label="status"
							ng-model="ctrl.filters._status" 
							md-on-close="headerForm.status.$dirty && ctrl.onFiltersChange()"
							md-selected-text="'Status'"
              ng-change="ctrl.onFiltersChange()"
              >
							<md-option ng-repeat="item in ctrl.mapping.status" ng-value="item">{{ item.name }}</md-option>
						</md-select>
					</md-input-container>
					<md-input-container class="filters" md-no-float="true">
						<md-select name="roles"
							aria-label="role"
							ng-model="ctrl.filters._roles" 
							md-on-close="headerForm.roles.$dirty && ctrl.onFiltersChange()"
							md-selected-text="'Roles'"
              ng-change="ctrl.onFiltersChange()"
              >
							<md-option ng-repeat="item in ctrl.mapping.roles" ng-value="item">{{ item.name }}</md-option>
						</md-select>
					</md-input-container>
					<md-input-container class="filters" md-no-float="true">
						<md-select name="attend" multiple
							aria-label="student attending"
							ng-model="ctrl.filters._attends"
							ng-disabled="ctrl.filters._admins.length > 0"
							md-on-close="headerForm.attend.$dirty && ctrl.onFiltersChange()"
							md-selected-text="'Student Attending'"
              ng-change="ctrl.onFiltersChange()"
              >
							<md-option ng-repeat="item in ctrl.mapping.attends" ng-value="item">{{ item.name }}</md-option>
						</md-select>
					</md-input-container>
					<md-input-container class="filters" md-no-float="true">
						<md-select name="admin" multiple
							aria-label="college admin"
							ng-model="ctrl.filters._admins"
							ng-disabled="ctrl.filters._attends.length > 0"
							md-on-close="headerForm.admin.$dirty && ctrl.onFiltersChange()"
							md-selected-text="'Admin of College'"
              ng-change="ctrl.onFiltersChange()"
              >
							<md-option ng-repeat="item in ctrl.mapping.admins" ng-value="item">{{ item.name }}</md-option>
						</md-select>
					</md-input-container>

					<div class="make-right layout-xs-column layout-sm-column layout-gt-sm-row layout-wrap">
						<span class="flex-gt-md"></span>
						<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_SORTS -->
					</div>
				</md-sidenav>
			</div>
		</div>
	</form>
</header>

<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_ERROR -->
<!-- directive: use-template TEMPLATES.COMMON.BUSY_OVERLAY -->

<footer class="md-whiteframe-2dp layout-row layout-align-start-center layout-wrap">
	<md-button class="md-icon-button md-mini md-primary" 
		aria-label="Refresh results" title="Refresh results"
		ng-click="ctrl.refresh(true)">
		<i class="fas fa-sync-alt" aria-hidden="true"></i>
	</md-button>
	<div id="footerResults" class="md-body-1">
		{{ ctrl.query.total }} <span class="hide-xs">Results</span>
	</div>
	<span flex></span>
	
	<md-button class="md-primary" 
		aria-label="add new user"
		ui-sref=".add"
		ng-if=":: mainCtrl.hasPermission(PERMISSIONS.CREATE_USERS)">
		<i class="fas fa-plus" aria-hidden="true"></i> 
    <span class="icon-title"><span class="hide-xs">Add</span> New</span>
	</md-button>
	<md-button id="footerExport" class="md-accent" 
		aria-labelledby="footerExport footerResults"
		ng-click="ctrl.exportCSV('users.csv')">
		<i class="fas fa-file-export" aria-hidden="true"></i> 
    <span class="workspace-export">Export <span class="hide-xs">CSV</span></span>
	</md-button>
</footer>

<main class="main-list users-list flex flex-order--1 layout-column"
	aria-labelledby="pageTitle footerResults">
	<md-virtual-repeat-container class="data-list flex no-animate" 
		md-top-index="ctrl.itemIndex" 
		ng-if="ctrl.dataList.length > 0">
		<div id="item-{{ item._id }}" class="data-item user-item layout-column no-animate" ng-class="{loading: !item}" 
			md-virtual-repeat="item in ctrl" md-on-demand="true">
			<div class="layout-row flex" 
				role="group" aria-label="user ID: #{{item._id}}"
				ui-sref="app.users.edit({id: item._id})" ng-include="::'/main/useres/users.item.tpl'"></div>
		</div>
	</md-virtual-repeat-container>

	<!-- directive: use-template TEMPLATES.COMMON.LISTPAGE_EMPTY_RESULTS -->
</main>


<script type="text/ng-template" id="/main/useres/users.item.tpl">
	<div class="flex layout-row layout-wrap">
		<div class="data-id flex-xs-100 flex-sm-100 padding-2x layout-row layout-align-space-between-center layout-gt-sm-column layout-align-gt-sm-start-end">
			<a class="md-caption md-block"
				aria-label="user ID #{{item._id}}"
				ui-sref="app.users.edit({id: item._id})">ID#{{ item._id }}</a>
			<sub class="md-caption black-33" ng-show="item.status && item.status != 'active'">
				<span class="far fa-dot-circle"></span> {{ ctrl.mapping.status.byId[item.status].name || item.status }}
			</sub>
		</div>
		<div class="data-content flex layout-column layout-align-space-between-stretch padding-1x">
			<h3 class="md-subhead md-truncate">
				<a 
					title="{{item.full_name}}"
					ui-sref="app.users.edit({id: item._id})" 
					ng-if="item.full_name">{{ item.full_name }}</a>
				<em class="smaller black-25 no-animate" 
					role="generic" aria-label="no name"
					ng-if="item && !item.full_name">- no name -</em>
			</h3>
			<div class="md-body-1 md-truncate">
				<a class="black-50 no-animate" ng-href="mailto:{{ item.email }}" ng-if="item.email" ng-click="$event.stopPropagation()">{{ item.email }}</a>
			</div>
			<div class="md-chips md-readonly">
				<span class="flex-none no-animate" 
					role="group" aria-label="{{role}} role & colleges"
					ng-repeat="(role, val) in item.roles">
					<md-chip class="flex-none">
						<span ng-class="ctrl.mapping.roleIcons[role] || ctrl.mapping.roleIcons.legacy"></span>
						{{ ctrl.mapping.roles.byId[role].name || role+' user' }}
					</md-chip>
					<md-chip class="flex-none college no-animate" 
						ng-repeat="id in item['_colleges_'+ role] track by $index">
						{{ ctrl.mapping.mycolleges.byId[id].name || '#'+id }}
					</md-chip>
				</span>
				<span role="group" aria-label="cohorts" ng-show="item.coachCohorts.length > 0">
					<a class="no-animate" 
						ng-repeat="cohort in item.coachCohorts track by $index" 
						ui-sref="app.cohorts.edit({id: cohort._id})" 
						ng-click="$event.stopImmediatePropagation()">
						<md-chip class="flex-none cohort">
							{{ cohort.title || cohort.description }}
						</md-chip>
					</a>
				</span>
			</div>
		</div>
		<div class="flex-none layout-row padding-1x">
			<md-button class="go-edit md-block layout-row layout-align-center-center"
				aria-label="open user: {{item.full_name || 'ID#'+item._id}}"
				ui-sref="app.users.edit({id: item._id})">&rsaquo;</md-button>
		</div>
	</div>
</script>