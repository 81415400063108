import angular from 'angular'
import $ from 'jquery'
import BaseList from '../base.list'
import {Helper} from '../../common'


const MSG_REGEXP = /^(.*) ((\w+) with id (\d+))(.*)/;

export default class ActivityLogController extends BaseList {
	static get $inject(){return [
		'$q',
		'authorization',
		'api',
		'apiMap',
	].concat(BaseList.$inject)}

	
	init(){
		this.mapping = {
			types: Helper.superMap(angular.extend({
				user: 'User Events',
				process: 'Salesforce Runs',
				system: 'Salesforce Changes',
			}, this.MAPPINGS_JSON.activity_logs?.type?.name_key || {}), {type:'types'}, (list)=>list.single=false),
			sortBy: Helper.superMap({
				id:'ID', 
				date:'Date', 
				event:'Event',
				user_id:'User',
			}),
			sortOrder: Helper.superMap({asc:'Asc', desc:'Desc'}),
		};
		
		super.init();

		this.filters._types = []; // default value, prevent premature change event on dropdown select
		this.filters._$keys = {
			'types': 'Type',
		};
		this.query = angular.extend({
				search: '', 
				sortBy: '',
				sortOrder: '',
				college: -1,
				types: [],
			}, this.query, {
				defaultSortBy: 'date',
				defaultSortOrder: 'desc',
			});
	}
	_loadDependencies(){
		return this.$q.all([
			this.apiMap.getColleges().then((data)=>this.mapping.mycolleges = data),
			this.apiMap.getPublicUsers().then((data)=>this.mapping.users = data),
		]).then(()=>{
			this.onUrlParamChange(this.$state.params);
			this.refresh(true)
				.then(()=>this._loadScrollState());
		});
	}


	getFilters(all){
		return angular.extend(super.getFilters(all), {
			types: (this.filters._types || []).map((item)=>item._id),
		});
	}
	onUrlParamChange(obj){
		if ( obj.hasOwnProperty('types') && obj.types ) {
			if ( ! Array.isArray(obj.types) )
				obj.types = [obj.types];
			this.filters._types = obj.types
				.map(id=>this.mapping.types.byId[id])
				.filter(Helper.trueishFilter);
		}
		if ( obj.hasOwnProperty('sortBy') )
			this.query.sortBy = this.mapping.sortBy?.byId[obj.sortBy] && obj.sortBy || this.query.defaultSortBy;
		if ( obj.hasOwnProperty('sortOrder') )
			this.query.sortOrder = this.mapping.sortOrder?.byId[obj.sortOrder] && obj.sortOrder || this.query.defaultSortOrder;

		let keys = ['types', 'sortBy', 'sortOrder'];
		return !! Object.keys(obj).find(val=>keys.includes(val));
	}

	getParams(reset){
		return angular.extend({}, super.getParams(reset), {
				roles: ['college_admin', 'content_admin', 'superuser', 'bot'],
				myTokenAdministers: false,
			});
	}


	refresh(reset){
		let params = angular.extend({
			searchString: this.query.search,
			sortBy: this.query.sortBy,
			sortOrder: this.query.sortOrder,
			limit: this.query.limit,
			offset: this.query.offset = (reset ? 0 : this.query.offset),
		}, {
			withMetadata:true, 
			myTokenAdministers:true
		});
		if ( reset ) {
			this.dataList = [];
			this.itemIndex = 0;
			this._errors = [];
		}
		return this._refresh('activityLogs', this.getParams(reset));
	}

	process(list){

		list.forEach(item=>{
			item.date = moment(item.date);

			this.processItemMessage(item);
			this.processItemsUser(item);
		})

		return list;
	}

	processItemMessage(item){
		const m = item.event.match(MSG_REGEXP);

		if ( ! m || m[1].toLowerCase() == 'deleted' )
			return;
		
		let stateName, stateOpts, url;
		switch( m[3] ) {
			case 'badge':
				stateName = 'app.milestones.edit';
				stateOpts = {id: m[4]};
				break;
			case 'flow_configuration':
				stateName = 'app.flows.edit';
				stateOpts = {id: m[4]};
				break;
			case 'cohort_college_external_id':
			 	if ( this.authorization.hasStateAccess('app.cohorts.edit.students') )
					this.api.get(`cohort_college_external_ids/${m[4]}`)
						.then(res=>res.data)
						.then(student=>this.api.get(`cohorts/${student.cohort}`)
							.then(res=>item.event_parsed.url = this.$state.href('app.cohorts.edit.students', {id: res.data._id, searchString: student.external_student_id}))
						)
						.catch(angular.noop);
				break;
			case 'cohort':
			default:
				stateName = `app.${m[3]}s.edit`;
				stateOpts = {id: m[4]};
				break;
		}
		if ( stateName && this.authorization.hasStateAccess(stateName) )
			url = this.$state.href(stateName, stateOpts);

		item.event_parsed = {
			action: m[1],
			url: url,
			object: m[2],
			id: m[4],
			other: m[5],
		};
	}

	processItemsUser(item){
		const user = this.mapping?.users?.byId?.[item.user_id];
		item.user = user;
		item.user_name = user?.user_profile?.first_name || '#'+item.user_id;
		item.user_name_full = user?.user_profile?.first_name +' '+ user?.user_profile?.last_name;


		item._chips = [];

		if ( user?.roles?.college_admin ) {
			Object.keys(user.roles.college_admin.colleges || {}).forEach(id=>{
				const college = this.mapping.mycolleges.byId[id];
				if ( college ) {
					item._chips.push({
						name: college.name,
						sref: 'app.colleges.edit({id:'+ id +'})',
					});
				}
			});
		// } else
		// if ( user?.roles?.superuser || user?.roles?.content_admin ) {
		// 	item._chips.push({name:'All'});
		}
	}

}