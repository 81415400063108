import angular from 'angular'
import moment from 'moment'
import Highcharts from 'highcharts'
import {DependencyInjected, Helper, ApiError} from '../../common'
import {menuData} from '../../main/main.controller'
import {barChart} from './charts'


export default class StudentsPerCollegeController extends DependencyInjected {
	static get $inject(){return [
		'$scope',
		'$q',
		'api',
		'authorization',
	]}

	init(){
		this.$scope.$parent.ctrl = this;
		this.main = this.$scope.mainCtrl;
		this.mapping = this.main.mapping;

		this.title = 'Students per College';
		this.subject = 'Students';
		this.icon = 'fas fa-users';
		this.iconColor = 'orange';
		this.hideDateRange = true;
		this.hideFilters = true;
		this.hideHeadersGroup = true;

		this.data = {};

		delete this.main.filters._$keys.date; // remove so it doesn't show on filter chips
		this.main.filters._date = null;//this.main.dateRange;

		this.main._pending.then(()=>{
			// this.hideFilters = this.mapping.mycolleges.length <= 1;
			this.$scope.$emit('data-ready');
			this.ready = true;
			this.refresh();
		});

		this._destructors.push(()=>{
			if ( this._defer ) this._defer.resolve('abort');
		});
	}

	refresh(){
		let params = angular.extend({
			// college: this.main.filters._mycolleges && this.main.filters._mycolleges.map(item=>item._id) || null,
			college: this.main.filters._mycolleges && this.main.filters._mycolleges._id || null,
		}, {
			myTokenAdministers: true,
		});

		if ( this._defer ) this._defer.resolve('abort');
		this._defer = this.$q.defer();
		this.dataTable = null;
		this.main.isBusy = true;

		return this.api.get('analysis/studentPerCollege', params, {timeout: this._defer.promise})
			.then((res)=>{
				let data = [], categories = [];
				if ( this.main.filters._mycolleges ) {
					data.push(0);
					categories.push(this.main.filters._mycolleges.name);
				} else {
					this.mapping.mycolleges.forEach(college=>{
						data.push(0);
						categories.push(college.name);
					});
				}
				res.data.forEach((item)=>{
					let p = categories.indexOf(item.name);
					if ( p > -1 ) data[p] = item.count;
				});

				var chartOptions = {
					title:{text:''},
					chart:{height: Math.max(400, categories.length*50)},
					legend:{enabled: true},
					tooltip: {pointFormat: '{series.name}: {point.y}'},
					series:[{
						name: 'Students',
						data: data,
					}]
				};

				let theChart = barChart('the-chart', [], categories, chartOptions);
				this.dataTable = theChart.getDataRows().slice(1);
				this.dataTable.headers = ['College', 'Students'];
			})
			.catch((err)=>{
				if ( this.api.isApiError(err) ) {
					if ( ! err.isIgnored() )
						this.main.error = err;
				} else {
					console.error(err);
				}
			})
			.finally(()=>{
				this._defer = undefined;
				this.main.isBusy = false;
			});
	}

}

